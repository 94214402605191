import React from "react";

import { Container, Text } from "./styles";

import list_alt from "../../assets/icons/list_alt.svg";
import description from "../../assets/icons/description.svg";
import list_tab from "../../assets/icons/list_tab.svg";
import heart_put from "../../assets/icons/heart_put.svg";
import edit_info from "../../assets/icons/edit_info.svg";
import person from "../../assets/icons/person.svg";
import new_anamnese from "../../assets/icons/new_anamnese.svg";
import continue_anamnese from "../../assets/icons/continue_anamnese.svg";
import carbon_medical from "../../assets/icons/carbon_medical.svg";
import new_quest_Sf from "../../assets/icons/new_quest_Sf.svg";
import continue_sf from "../../assets/icons/continue_sf.svg";
import esportiva from "../../assets/icons/esportiva.svg";
import estetica from "../../assets/icons/estetica.svg";
import saude from "../../assets/icons/saude.svg";
import reabilitacao from "../../assets/icons/reabilitacao.svg";
import qualidade_vida from "../../assets/icons/qualidade_vida.svg";
import { TypesBtnInfo } from "../../utils/types";

interface BtnInfoProps {
	Icon: string;
	Texto: string;
	funcao?: any;
	disabled?: boolean;
}

const BtnInformacao: React.FC<BtnInfoProps> = ({
	Icon,
	Texto,
	funcao,
	disabled = false,
}) => {
	if (Texto && Texto.length > 40) {
		Texto = `${Texto.substring(0, 40)}...`;
	}

	const imagem = (icon: string) => {
		if (icon === TypesBtnInfo.BTN_FICHA_TECNICA) {
			return list_alt;
		}
		if (icon === TypesBtnInfo.BTN_SAUDE) {
			return saude;
		}
		if (icon === TypesBtnInfo.BTN_REABILITACAO) {
			return reabilitacao;
		}
		if (icon === TypesBtnInfo.BTN_RELATORIO) {
			return description;
		}
		if (icon === TypesBtnInfo.BTN_ANAMNESE) {
			return list_tab;
		}
		if (icon === TypesBtnInfo.BTN_SF) {
			return heart_put;
		}
		if (icon === TypesBtnInfo.BTN_ANOTACOES) {
			return edit_info;
		}
		if (icon === TypesBtnInfo.BTN_AVALIADOR) {
			return person;
		}
		if (icon === TypesBtnInfo.BTN_NEW_ANAMNESE) {
			return new_anamnese;
		}
		if (icon === TypesBtnInfo.BTN_CONTINUE_ANAMNESE) {
			return continue_anamnese;
		}
		if (icon === TypesBtnInfo.BTN_AVALIACAO_FÍSICA) {
			return carbon_medical;
		}
		if (icon === TypesBtnInfo.BTN_NEW_SF) {
			return new_quest_Sf;
		}
		if (icon === TypesBtnInfo.BTN_CONTINUE_SF) {
			return continue_sf;
		}
		if (icon === TypesBtnInfo.BTN_ESPORTIVA) {
			return esportiva;
		}
		if (icon === TypesBtnInfo.BTN_ESTETICA) {
			return estetica;
		}
		if (icon === TypesBtnInfo.BTN_QUALIDADE_DE_VIDA) {
			return qualidade_vida;
		}
	};

	return (
		<Container
			style={{
				opacity: `${disabled ? "0.7" : "1"}`,
				cursor: `${disabled ? "not-allowed" : "pointer"}`,
			}}
			onClick={funcao}
			disabled={disabled}
		>
			<img src={imagem(Icon)}></img>
			<Text>{Texto}</Text>
		</Container>
	);
};

export default BtnInformacao;
