import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import {
	ProtoExercicioProps,
} from "../../../interfaces/protocolo.interface";
import { AppActions } from "../../../store/ducks/app";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import { ExerciciosActions } from "../../../store/ducks/exer";
import {
	TypesAvalOrFunci,
	TypesDivision,
	TypesErrorModal,
} from "../../../utils/types";
import { Item } from "../styles";
import Check from "../../../assets/icons/check.svg";
import Play from "../../../assets/icons/play_arrow.svg";
import { AmplitudeLog } from "../../../utils/amplitude";
import { browserName } from "react-device-detect";
import { ConexaoBluetooth } from "../ConexaoBluetooth";
import { loadStorage } from "../../../utils/base_async_storage";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { P3Desk } from "../../../styles/styles_text";
import { DashBoardActions } from "../../../store/ducks/dashboard";

interface RenderExerProtoProps {
	item: ProtoExercicioProps;
	index: number;
}

export interface ExerProtoPros {
	title: string;
	value: string;
	grupo?: any;
	exercice_id: any;
	id_protocolo_exer: any;
	name: any;
}

const RenderExerProto: React.FC<RenderExerProtoProps> = ({ item }: any) => {
	const dispatch = useDispatch();

	const remove = useSelector((state: any) => state.manage_protocol.remove_play);

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("execucaoProtocolo"));
	}, []);

	const [lista, setLista] = useState<any>([]);
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const exer_protocolo = useSelector(
		(state: any) => state.avaliation.exer_protocolo
	);

	const connect_flag_hand_grip = useSelector(
		(state: any) => state.avaliation.connect_flag_hand_grip
	);
	const result_bluetooth_hand_grip = useSelector(
		(state: any) => state.configs.result_bluetooth_hand_grip
	);

	function getElementsExers(proto: ProtoExercicioProps, type: any) {
		const DEB = type == TypesDivision.DEB ? ["D", "E", "B"] : ["S"];
		const aux_tipo = proto.PROTOCOLO_EXERCICIO_TIPO.split("");

		const arr: any = [];
		aux_tipo.forEach((e: any) => {
			if (DEB.includes(e)) {
				arr.push({
					title:
						e == "D"
							? current_language?.hemisferio_direito
							: e == "E"
							? current_language?.hemisferio_esquerdo
							: e == "B"
							? current_language?.hemisferio_bilateral
							: current_language?.sem_hemisferio,
					value: e,
					exercice_id: proto?.ID_EXERCICIO,
					id_protocolo_exer: proto?.ID_PROTOCOLO_EXERCICIO,
					name: proto?.EXERCICIO_TITULO_PT,
				});
				const idExer = exer_protocolo.filter(
					(i: any) => i[0] == proto?.ID_EXERCICIO && i[1] == e
				);
				if (idExer.length == 0) {
					dispatch(
						AvaliationActions.set_exer_protocolos([proto?.ID_EXERCICIO, e])
					);
				}
			}
		});

		return arr;
	}

	useEffect(() => {
		if (item.PROTOCOLO_EXERCICIO_TIPO != "S") {
			setLista([...getElementsExers(item, TypesDivision.DEB)]);
		} else {
			setLista([...getElementsExers(item, TypesDivision.S)]);
		}
	}, [current_language]);

	const is_selected_avaliacao = useSelector(
		(state: any) => state.app.is_selected_avaliacao
	);

	const connect_flag = useSelector(
		(state: any) => state.avaliation.connect_flag
	);

	const alreadyConnected = useSelector(
		(state: any) => state.avaliation.already_connected
	);

	const modo_demo: any = useSelector((state: any) => state.configs.modo_demo);

	const result_bluetooth = useSelector(
		(state: any) => state.configs.result_bluetooth
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const [Evaluatedpatients, setEvaluatedPatients] = useState<number[]>([]);

	const avaliationInit = (exerc: any) => {
		dispatch(ExerciciosActions.set_id_proto(exerc.exercice_id));
		dispatch(AvaliationActions.set_current_exercicise(exerc));
		if (is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO) {
			dispatch(AvaliationActions.set_in_execution_force_max_status(false));
			dispatch(AppActions.set_checklist_execucao(false));
		} else {
			dispatch(AppActions.set_open_configuracoes_execucao(true));
		}
		dispatch(DashBoardActions.set_current_page("execucao"));
		AmplitudeLog("exercicio_proto_selecionado", {
			body: exerc,
		});
	};

	async function getEvaluatedPatients() {
		let evaluatedPatients: number[] | null = [];

		evaluatedPatients = await loadStorage("evaluated_patients");

		if (evaluatedPatients != undefined || evaluatedPatients != null) {
			setEvaluatedPatients(evaluatedPatients);
		}
	}

	useEffect(() => {
		getEvaluatedPatients();
	}, [patient]);

	function verifyCountEvaluatedPatients(exer: any) {
		if (
			Evaluatedpatients.length < config_servicos?.SERVICO_PACIENTES_AVALIADOS ||
			Evaluatedpatients.includes(patient.ID_USUARIO)
		) {
			verifyConnection(exer);
		} else {
			dispatch(AppActions.set_modal_upgrade(true, true));
		}
	}

	const verifyConnection = (exerc: any) => {
		if (item?.IDS_HARDWARES[0] === "1") {
			if (connect_flag) {
				dispatch(AppActions.set_modal_conecta(false));
				if (alreadyConnected === false) {
					dispatch(AvaliationActions.set_already_connected(true));
					dispatch(
						AppActions.set_modal_visible({
							type: TypesErrorModal.SUCCESS,
							message: current_language?.dinamo_conectado,
						})
					);
				}
				avaliationInit(exerc);
			} else {
				if (!modo_demo) {
					if (
						window.navigator.userAgent.indexOf("Windows NT 10.0") != -1 ||
						window.navigator.userAgent.indexOf("Windows NT 6.2") != -1 ||
						window.navigator.userAgent.indexOf("Windows NT 6.1") != -1 ||
						window.navigator.userAgent.indexOf("Windows NT 6.0") != -1 ||
						window.navigator.userAgent.indexOf("Windows NT 5.1") != -1 ||
						window.navigator.userAgent.indexOf("Windows NT 5.0") != -1 ||
						window.navigator.userAgent.indexOf("Mac") != -1
					) {
						if (browserName == "Chrome") {
							ConexaoBluetooth();
							if (result_bluetooth) {
								dispatch(AppActions.set_modal_conecta(true));
							} else {
								dispatch(AppActions.set_modal_conecta3(true));
							}
						} else {
							dispatch(AppActions.set_modal_conecta2(true));
						}
					} else {
						dispatch(AppActions.set_modal_conecta4(true));
					}
				}
			}
			if (!!modo_demo) avaliationInit(exerc);
		}

		if (item?.IDS_HARDWARES[0] === "2") {
			if (connect_flag_hand_grip) {
				dispatch(AppActions.set_modal_conecta_hand_grip(false));
				if (alreadyConnected === false) {
					dispatch(AvaliationActions.set_already_connected(true));
					dispatch(
						AppActions.set_modal_visible({
							type: TypesErrorModal.SUCCESS,
							message: "conectado",
						})
					);
				}
				avaliationInit(exerc);
			} else {
				if (!modo_demo) {
					if (
						window.navigator.userAgent.indexOf("Windows NT 10.0") != -1 ||
						window.navigator.userAgent.indexOf("Windows NT 6.2") != -1 ||
						window.navigator.userAgent.indexOf("Windows NT 6.1") != -1 ||
						window.navigator.userAgent.indexOf("Windows NT 6.0") != -1 ||
						window.navigator.userAgent.indexOf("Windows NT 5.1") != -1 ||
						window.navigator.userAgent.indexOf("Windows NT 5.0") != -1 ||
						window.navigator.userAgent.indexOf("Mac") != -1
					) {
						if (browserName == "Chrome") {
							ConexaoBluetooth();
							if (result_bluetooth_hand_grip) {
								dispatch(AppActions.set_modal_conecta_hand_grip_passos(true));
							} else {
								dispatch(AppActions.set_modal_conecta_hand_grip(true));
							}
						} else {
							dispatch(AppActions.set_modal_conecta2(true));
						}
					} else {
						dispatch(AppActions.set_modal_conecta4(true));
					}
				}
			}
			if (!!modo_demo) avaliationInit(exerc);
		}
	};

	const progress_exers_proto = useSelector(
		(state: any) => state.avaliation.progress_exers_proto
	);

	useEffect(() => {
		if (connect_flag) {
			dispatch(AppActions.set_modal_conecta(false));
			dispatch(AppActions.set_modal_conecta3(false));
			dispatch(AppActions.set_modal_conecta2(false));
			dispatch(AppActions.set_modal_conecta4(false));
		}
	}, [connect_flag]);

	useEffect(() => {
		if (connect_flag_hand_grip) {
			dispatch(AppActions.set_modal_conecta_hand_grip_passos(false));
			dispatch(AppActions.set_modal_conecta_hand_grip(false));
		}
	}, [connect_flag_hand_grip]);

	return (
		<>
			{
				<Item>
					{remove ? (
						<div></div>
					) : (
						<div className="hori">
							{lista.map((e: ExerProtoPros) => {
								return (
									<div
										className={`item ${
											progress_exers_proto.find(
												(i: any) =>
													i.exercice_id == e.exercice_id && i.value == e.value
											) != undefined
												? "borderVerde"
												: "borderCinza"
										}`}
										key={e.exercice_id}
										onClick={() => verifyCountEvaluatedPatients(e)}
									>
										<P3Desk
											style={{ marginBottom: "0.2rem" }}
											className={`toPlay ${
												progress_exers_proto.find(
													(i: any) =>
														i.exercice_id == e.exercice_id && i.value == e.value
												)
													? "verde"
													: "cinza"
											}`}
										>
											{e.title}
										</P3Desk>
										{progress_exers_proto.find(
											(i: any) =>
												i.exercice_id == e.exercice_id && i.value == e.value
										) != undefined ? (
											<img src={Check} style={{ width: 15, height: 18 }} />
										) : (
											<img
												src={Play}
												style={{ width: 14, height: 16, borderRadius: 3 }}
											/>
										)}
									</div>
								);
							})}
						</div>
					)}
				</Item>
			}
		</>
	);
};
export default RenderExerProto;
