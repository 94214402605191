import React, { ReactComponentElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import usuario from "../../assets/icons/perfil.svg";
import { TextsProps } from "../../database/txt.interface";
import { ServicosProps } from "../../interfaces/config.interface";
import { UserProps } from "../../interfaces/pacientes.interface";
import { AppActions } from "../../store/ducks/app";
import { getPermission } from "../../utils/funcs";
import { TypesLanguage, TypesPermission } from "../../utils/types";
import { ManageProtocolActions } from "../../store/ducks/manage_protocols";
import { Container,Text, MenuHeader, Image, PerfilName, Transistion, ContainerText, ProgressBarContainer, ProgressBar, BackgroundProgress, MenuOption, ContainerMenuOptions, ContainerIcon, Icon, TextOption, ContainerVersion, EvaluatedPatient, LeftBar, Tooltip, TooltipTitle, BatteryContainer, ContentBattery, DinoBatteryText} from "./styles";
import { ConfigsActions } from "../../store/ducks/configs";
import { AmplitudeLog } from "../../utils/amplitude";
import { DashBoardActions } from "../../store/ducks/dashboard";
import { loadStorage } from "../../utils/base_async_storage";
import { Colors } from "../../styles/colors";
import  { ReactComponent as MenuIcon}  from '../../assets/kinologyIcons/svg/menu.svg';
import {  ReactComponent as MeAccount } from '../../assets/kinologyIcons/svg/patients.svg';
import {  ReactComponent as Home }from '../../assets/kinologyIcons/svg/home.svg';
import {  ReactComponent as Tag } from '../../assets/kinologyIcons/svg/Tag.svg';
import {  ReactComponent as Protocol }from '../../assets/kinologyIcons/svg/protocol.svg';
import {  ReactComponent as Settings }from '../../assets/kinologyIcons/svg/Settings.svg';
import {  ReactComponent as Trash } from '../../assets/kinologyIcons/svg/trash.svg';
import {  ReactComponent as Question } from '../../assets/kinologyIcons/svg/question.svg'
import history from "../../routes/history";
import battery100 from "../../assets/icons/battery-100.svg";
import battery75 from "../../assets/icons/battery-75.svg";
import battery50 from "../../assets/icons/battery-50.svg";
import battery25 from "../../assets/icons/battery-25.svg";

interface Imenu {
	setMenu?: string;
}
interface MenuOptionsProps {
	iconDefault: (color: string)=> React.ReactNode,
	title: string,
	action?: ()=> void
}

const Menu: React.FC<Imenu> = (props: any) => {
	const dispatch = useDispatch();
	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const dino_battery = useSelector(
		(state: any) => state.configs.dino_battery
	);
	const version = useSelector((state: any) => state.configs.version);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const user_editation: UserProps = useSelector((state: any)=> state?.auth?.data_user)
	const language = useSelector((state: any) => state.configs.language);

	const handleOpenMenuEditar = (item: boolean) => {
		dispatch(AppActions.set_open_editar_perfil(item));
	};

	const removeButtonClose: boolean = useSelector(
		(state: any) => state.configs.remove_button_close,
	)
	const menuOpen = useSelector((state: any)=> state.app.openMenu)
	
	const [Evaluatedpatients, setEvaluatedPatients] = useState<number>(0)
	const [isHovered, setIsHovered] = useState(false);
	const [textVisible, setTextVisible] = useState(false);
	const [pressedIndex, setPressedIndex] = useState<number | null>(null);
	const [isHoveredBattery, setIsHoveredBattery] = useState(false);
	const [batteryIcon, setBatteryIcon] = useState(battery100);
	

	const selected = useSelector((state: any)=> state.app.set_option_menu)
	const openMenu = useSelector((state: any)=> state.app.openMenu)
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);
	
	const date = new Date().toLocaleDateString('pt-BR');
	let plano = "";
	
	if (
		!!config_servicos?.SERVICO_PLANO_TRIAL_FIM 
		&& new Date(config_servicos?.SERVICO_PLANO_TRIAL_FIM).toLocaleDateString('pt-BR') <= date
	) {
		plano = current_language?.idioma === "Português" 
			&& `${current_language?.menu_lateral_plano} Trial ${config_servicos?.SERVICO_PLANO_NOME_TRIAL} ${new Date(config_servicos?.SERVICO_PLANO_TRIAL_FIM).toLocaleDateString('pt-BR')}`
			|| `${config_servicos?.SERVICO_PLANO_NOME} ${current_language?.menu_lateral_plano}`;
	} else if (new Date(config_servicos?.SERVICO_PLANO_INICIO).toLocaleDateString('pt-BR') <= date) {
		plano = current_language?.idioma === "Português" 
			&& `${current_language?.menu_lateral_plano} ${config_servicos?.SERVICO_PLANO_NOME}`
			|| `${config_servicos?.SERVICO_PLANO_NOME} ${current_language?.menu_lateral_plano}`;
	}

	async function getEvaluatedPatientsInMenu() {
		let evaluatedPatients: number[] | null = []

		evaluatedPatients = await loadStorage('evaluated_patients')

		if(evaluatedPatients != undefined || evaluatedPatients != null ){
			setEvaluatedPatients(evaluatedPatients?.length)
			
		};
		
	};
	getEvaluatedPatientsInMenu()

	useEffect(() => {

		const handleResize = () => {
		  if (window.innerWidth <= 1100) {
			dispatch(AppActions.set_option_menu(false))
		  } 
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	}, []);



	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (menuOpen) {
		timer = setTimeout(() => {
			setTextVisible(true);
		}, 250);
		} else {
			setTextVisible(false);
		}
		return () => clearTimeout(timer);
	}, [menuOpen]);
	
	const MenuOptions: MenuOptionsProps[] = [
		{
			iconDefault: (color: string)=> <Home color={color}/>,
			title: current_language?.menu_lateral_inicio,
			action: ()=>{
				history.push( "/dashboard" )
				dispatch(DashBoardActions.set_current_page("pacientes"))
				dispatch(DashBoardActions.mais_visitados_request())
				AmplitudeLog('inicio')
				const filtros_paginacao_pacientes = {
					inicio: 0,
					fim: 6,
					filtros: [
						{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
						{ tipo: "USUARIO_NIVEL", valor: [3] }
					],
				};
				const filtros_paginacao_avaliadores= {
					inicio: 0,
					fim: 6,
					filtros: [
						{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
						{ tipo: "USUARIO_NIVEL", valor: [1, 2] }
					],
				};

				dispatch(
					DashBoardActions.set_filtros_pacintes_request(filtros_paginacao_pacientes)
				);
				dispatch(AppActions.set_lista_filtro([]));
				dispatch(DashBoardActions.paciente_request());

				dispatch(DashBoardActions.set_current_page_patient(1));
				
				dispatch(
					DashBoardActions.set_filtros_avaliadores_request(filtros_paginacao_avaliadores)
				);
				dispatch(DashBoardActions.avaliadores_request());
				}
		},
		{
			iconDefault:(color: string)=> <MeAccount color={color}/>,
			title: current_language?.menu_lateral_minha_conta,
			action: ()=> {
				dispatch(DashBoardActions.set_current_page("minha_conta"))
				AmplitudeLog('minha_conta')
			}
		},
		{
			iconDefault:(color: string)=> <Tag color={color}/>,
			title: current_language?.menu_lateral_etiquetas,
			action: ()=> {
				dispatch(DashBoardActions.set_current_page("etiquetas"))
				AmplitudeLog('etiquetas')
				history.push( "etiquetas" )
			}
		},
		{
			iconDefault:(color: string)=> <Protocol color={color}/>,
			title: current_language?.menu_lateral_protocolos,
			action: ()=> {
				dispatch(ManageProtocolActions.set_disable_modal_conexao_bluetooth(true))
				AmplitudeLog('protocolos')
				dispatch(DashBoardActions.protocolos_request());
				dispatch(DashBoardActions.exercices_request());
				dispatch(DashBoardActions.set_current_page("protocolos"))
			}
		},
		{
			iconDefault:(color: string)=> <Settings color={color}/>,
			title: current_language?.menu_lateral_configurar,
			action: ()=> {
				dispatch(DashBoardActions.set_current_page("configuracoes"))
				AmplitudeLog('configurar')
			}
		},
		{
			iconDefault:(color: string)=> <Trash color={color}/>,
			title: current_language?.menu_lateral_lixeira,
			action: ()=>{
				const recycle: any = getPermission(
					config_servicos,
					TypesPermission.SERVICO_RECYCLE,
				)
				if(recycle){
					AmplitudeLog('lixeira')
					dispatch(DashBoardActions.set_current_page("gerenciador_excluidos"))
		
				}else{
					dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true))
					dispatch(ConfigsActions.set_close_modal_upgrade_and_goback(true))
				}
			}
		},
		{
			iconDefault:(color: string)=> <Question color={color}/>,
			title: current_language?.menu_lateral_duvidas,
			action: ()=>{
				AmplitudeLog('central_ajuda')
				window.open("https://base.materials2.kinology.com.br/perguntas-frequentes", "_blank")
			}
		},
	]

	useEffect(() => {
		const icon = dino_battery >= 76 && battery100 || 
			dino_battery >= 51 && dino_battery <= 75 &&	battery75 || 
			dino_battery >= 26 && dino_battery <= 50 &&	battery50 ||
			dino_battery >= 1 && dino_battery <= 25 &&	battery25 ||
			battery100;
			setBatteryIcon(icon);
	}, [dino_battery])

	return (
		<Container  menuOpen={menuOpen}>
			
			<MenuHeader>
				<Image
					onClick={()=> dispatch(AppActions.set_option_menu(!menuOpen))}
					menuOpen={menuOpen}
				>
					<MenuIcon  color={menuOpen ? Colors?.white : Colors?.primary_blue_k_main}/>
				</Image>
					<div
						style={{
							display: 'flex',
							width: '100%',
							flexDirection: 'column',
							height: 150,
							marginBottom: '2rem'
						}}
					>
						{textVisible &&
							<>
								<PerfilName visible={menuOpen} >
									{user_editation?.USUARIO_NOME}
								</PerfilName>
								<ContainerText>

									<Text>
										{current_language?.planos+':'+ config_servicos.SERVICO_PLANO_NOME}
									</Text>
									<EvaluatedPatient>
										{`${current_language?.pacientes_avaliados_mes}: ${Evaluatedpatients} /
											${
												config_servicos?.SERVICO_PACIENTES_AVALIADOS >=999999999
												? current_language?.ilimitado
												: config_servicos?.SERVICO_PACIENTES_AVALIADOS
											}`
										}
									</EvaluatedPatient>

								</ContainerText>
								<ProgressBarContainer>
									<ProgressBar>
										<BackgroundProgress 
											fill={`${(
													(
														Evaluatedpatients < config_servicos?.SERVICO_PACIENTES_AVALIADOS 
															? Evaluatedpatients 
															: config_servicos?.SERVICO_PACIENTES_AVALIADOS 
													) / config_servicos?.SERVICO_PACIENTES_AVALIADOS ) * 100 }%`
											}
										/>
									</ProgressBar>
								</ProgressBarContainer>

								{
									!!dino_battery &&
									<BatteryContainer>
										<ContentBattery>
											<img
													src={batteryIcon}
													style={{width: '3rem'}}
													alt="bateria dino"
												/>
												<DinoBatteryText>{`${dino_battery}% | ${current_language?.dino_bateria}`}</DinoBatteryText>
											</ContentBattery>
									</BatteryContainer>
								}
							</>
						}
				</div>
				{MenuOptions?.map((item, index)=> (
					<ContainerMenuOptions  
						key={index}
						onMouseEnter={() => setPressedIndex(index) }
						onMouseLeave={()=> setPressedIndex(null)}
						onClick={() =>{ item?.action?.(), dispatch(AppActions.set_option_menu(false, item?.title))}}
					>
						<MenuOption>
							<LeftBar
								index={pressedIndex}
								HoverIndex={index}
								optionName={item?.title}
								selected={selected}	
							/>
							<ContainerIcon
								index={pressedIndex}
								HoverIndex={index}
								optionName={item?.title}
								selected={selected}									
								style={{
									padding: index == 6 ? '5px' : '0px'
								}}
							>
								{ item?.iconDefault && item?.iconDefault(
										pressedIndex == index  || selected == item?.title 
											?  Colors?.white 
											:  Colors?.primary_blue_k_main
									)
								}
								
							</ContainerIcon>
							{ textVisible && 
								<TextOption menuOpen={menuOpen}>
									{item?.title}
								</TextOption>
							}
						</MenuOption>
						{! menuOpen && pressedIndex == index  &&

							<Tooltip>
								<TooltipTitle>
									{item?.title}
								</TooltipTitle>
							</Tooltip>
						}
					</ContainerMenuOptions>
				))}
				<ContainerVersion menuOpen={menuOpen}>
					v.{version}
				</ContainerVersion>
			</MenuHeader>
		</Container>
	);
};

export default Menu;
