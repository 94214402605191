import styled from "styled-components";

export const Container = styled.div`
	width: 60%;
	margin-top: 1.5rem;
`;

export const DivInput = styled.div`
	width: 100%;
	display: flex;
	flex-direction: collumn;
	align-items: center;
	border-bottom: 2px solid #e0e0e0;
	padding-bottom: 0.3rem;
	margin-right: 10px;
	height: 100%;
`;

export const ButtomSearch = styled.button`
	background-color: transparent;
	border: none;
	width: 20px;
	height: 20px;
`;

export const ButtomSearchPulse = styled.button`
	width: 20px;
	height: 20px;
	display: block;
	border-radius: 50%;
	background: transparent;
	cursor: pointer;
	box-shadow: 0 0 0 #000e4b;
	animation: pulse 2s infinite;
	border: none;

	@-webkit-keyframes pulse {
		0% {
			-webkit-box-shadow: 0 0 0 0 #000e4b;
		}
		70% {
			-webkit-box-shadow: 0 0 0 10px #000e4b;
		}
		100% {
			-webkit-box-shadow: 0 0 0 0 #000e4b;
		}
	}
	@keyframes pulse {
		0% {
			-moz-box-shadow: 0 0 0 0 #000e4b;
			box-shadow: 0 0 0 0 #000e4b;
		}
		70% {
			-moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
			box-shadow: 0 0 0 15px rgba(204, 169, 44, 0);
		}
		100% {
			-moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
			box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
		}
	}
`;

export const Reset = styled.button`
	background-color: transparent;
	border: none;
	width: 20px;
	height: 20px;
`;

export const FilterButton = styled.img`
	width: 20px;
	height: 20px;
	cursor: pointer;
`;

export const InputSearch = styled.input`
	background-color: transparent;
	border: none;
	width: 100%;
	margin-left: 10px;
	font-size: 80%;
`;

export const Icon = styled.img`
	width: 17px;
	height: 17px;
`;