import React, { memo, useEffect } from "react";
import { Content, ContentBody, Radio, RadioCheck } from "./styles";
import { QuestionnaireProps } from "../../../../../interfaces/pacientes.interface";
import {
	TypeQuestionnaireProps,
	TypesQuestionnaire,
} from "../../../../../utils/types";
import { PacAvalActions } from "../../../../../store/ducks/manage_pac_aval";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxButton from "../CheckBoxButton";
import TextInput from "../TextInput";
import Select from "../Select";
import DOMParserReact from "dom-parser-react";
import { Question } from "../../styles";
import InputCheckbox from "../InputCheckbox";
import CorpoEVA from "../CorpoEVA";
import { AppActions } from "../../../../../store/ducks/app";

interface RadioButtonProps {
	item: QuestionnaireProps;
	functionItem?: any;
	type: string;
	hasComplement?: boolean;
}

const RadioButton: React.FC<RadioButtonProps> = ({
	item,
	functionItem,
	type,
	hasComplement = false,
}) => {
	const dispatch = useDispatch();
	const array_respostas = useSelector(
		(state: any) => state.pac_aval.array_respostas
	);
	const array_resposta = useSelector(
		(state: any) => state.pac_aval.array_resposta
	);
	const questionarioAsync: any = useSelector(
		(state: any) => state.pac_aval.questionario_async
	);

	let isComplement = false;

	useEffect(() => {
		dispatch(PacAvalActions.set_checkbox_sf_36(false));
	}, []);

	const handleRadioButton = (item: QuestionnaireProps, id: number) => {
		if (hasComplement) {
			isComplement = id === 0 ? true : false;
		}
		const dataObj = {
			QUESTIONARIO_LABEL: `${item.QUESTIONARIO_LABEL}`,
			QUESTIONARIO_RESPOSTA: [id],
		};
		const new_array = array_respostas.filter(
			(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
		);
		dispatch(PacAvalActions.set_array_respostas([...new_array, dataObj]));
		type !== TypesQuestionnaire.SF_36 &&
			dispatch(
				PacAvalActions.set_array_resposta([
					...array_resposta.filter(
						(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
					),
					dataObj,
				])
			);
		type === TypesQuestionnaire.SF_36 &&
			dispatch(PacAvalActions.set_questionario_respostas([dataObj]));
		type === TypesQuestionnaire.SF_36 && functionItem();
	};

	const excluir_EVA = () => {
		dispatch(AppActions.set_excluir_eva(true));
	};

	const reset = () => {
		handleRadioButton(item, 1);
	};

	function render_radio_button(text: string, id: number) {
		const check = array_respostas?.find(
			(e: any) => e?.QUESTIONARIO_LABEL === item?.QUESTIONARIO_LABEL
		)?.QUESTIONARIO_RESPOSTA;
		if (hasComplement && check != undefined) {
			check[0] == 0 ? (isComplement = true) : (isComplement = false);
		}
		if (
			hasComplement &&
			check != undefined &&
			item.QUESTIONARIO_ORDEM == "11_00"
		) {
			check[0] == 0 ? (isComplement = false) : (isComplement = true);
		}

		const corpo = array_resposta?.find(
			(e: any) => e?.QUESTIONARIO_LABEL === "EVA_CORPO"
		);

		if (check != undefined) {
			dispatch(PacAvalActions.set_checkbox_sf_36(true));
		}

		return (
			<Radio
				key={`checkbox${item.QUESTIONARIO_ORDEM}${id}`}
				questionAsync={questionarioAsync}
			>
				<div className="custom-checkbox">
					<input
						id={`checkbox${item.QUESTIONARIO_ORDEM}${id}`}
						checked={check != undefined ? id == check[0] : false}
						type="checkbox"
						style={{ display: "none" }}
						onChange={() =>
							hasComplement &&
							item.QUESTIONARIO_LABEL === "EVA" &&
							id === 1 &&
							corpo?.QUESTIONARIO_RESPOSTA.length > 0
								? excluir_EVA()
								: handleRadioButton(item, id)
						}
					/>
					<label
						className={
							item.QUESTIONARIO_PERGUNTA_TIPO === "radio-button"
								? "label2"
								: "label"
						}
						htmlFor={`checkbox${item.QUESTIONARIO_ORDEM}${id}`}
					></label>
				</div>
				<label htmlFor={`checkbox${item.QUESTIONARIO_ORDEM}${id}`}>
					<DOMParserReact source={text} />
				</label>
			</Radio>
		);
	}

	function render_complemento(item: QuestionnaireProps) {
		return (
			<>
				{item.QUESTIONARIO_PERGUNTA_TIPO ===
					TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_text &&
				item.QUESTIONARIO_PERGUNTA_LINK !== "S" ? (
					<Question>
						<DOMParserReact source={item.QUESTIONARIO_PERGUNTA} />
					</Question>
				) : (
					item.QUESTIONARIO_PERGUNTA_TIPO !==
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_text &&
					item.QUESTIONARIO_PERGUNTA_TIPO !==
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_checkbox &&
					item.QUESTIONARIO_PERGUNTA_TIPO !==
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
							.button_nota_adicional &&
					item.QUESTIONARIO_PERGUNTA !== null && (
						<Question>
							<DOMParserReact source={item.QUESTIONARIO_PERGUNTA} />
						</Question>
					)
				)}

				{item.QUESTIONARIO_PERGUNTA_TIPO ===
					TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.radio_button && (
					<RadioButton item={item} type={type} />
				)}

				{item.QUESTIONARIO_PERGUNTA_TIPO ===
					TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.checkbox && (
					<CheckBoxButton item={item} />
				)}

				{item.QUESTIONARIO_PERGUNTA_TIPO ===
					TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_text &&
				item.QUESTIONARIO_PERGUNTA_LINK !== "S" ? (
					<>
						<TextInput item={item} />
					</>
				) : (
					item.QUESTIONARIO_PERGUNTA_TIPO ===
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_text && (
						<Content>
							<ContentBody>
								<Question>
									<DOMParserReact source={item.QUESTIONARIO_PERGUNTA} />
								</Question>
								<TextInput item={item} />
							</ContentBody>
							{item?.QUESTIONARIO_PERGUNTA_COMPLEMENTO?.map(
								(e: QuestionnaireProps, i: number) => {
									return (
										<ContentBody key={i}>
											<Question>
												<DOMParserReact source={e.QUESTIONARIO_PERGUNTA} />
											</Question>
											<TextInput item={e} />
										</ContentBody>
									);
								}
							)}
						</Content>
					)
				)}

				{item.QUESTIONARIO_PERGUNTA_TIPO ===
					TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_select && (
					<Select item={item} />
				)}

				{item.QUESTIONARIO_PERGUNTA_TIPO ===
					TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_checkbox && (
					<InputCheckbox item={item} />
				)}

				{item.QUESTIONARIO_PERGUNTA_TIPO ===
					TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_corpo && (
					<CorpoEVA item={item} FUNCAO={reset} />
				)}
			</>
		);
	}

	return (
		<>
			<RadioCheck
				style={{
					flexDirection: `${
						item.QUESTIONARIO_OPCOES_LAYOUT !== "LADO_A_LADO" ? "column" : "row"
					}`,
				}}
			>
				{item.QUESTIONARIO_PERGUNTA_OPCOES.map((obj, i) =>
					render_radio_button(obj, i)
				)}
			</RadioCheck>
			{isComplement &&
				item.QUESTIONARIO_PERGUNTA_COMPLEMENTO?.map((e: QuestionnaireProps) =>
					render_complemento(e)
				)}
		</>
	);
};

export default memo(RadioButton);
