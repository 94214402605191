import styled from "styled-components";
import { Colors } from "../../../../../styles/colors";

interface ButtonProps {
	questionAsync: boolean | undefined;
}

export const Button = styled.div<ButtonProps>`
	${({ questionAsync }) =>
		questionAsync &&
		`
	justify-content: space-between;
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 120%;
`}
	.final {
		margin: 0 1rem;
		width: 15rem;
		height: 3.125rem;
		background-color: ${Colors.primary_blue_k_main};
		color: #fcfcfc;

		border: 4px solid ${Colors.primary_blue_k_main};
		border-radius: 8px;

		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 1.375rem;
		line-height: 120%;

		text-align: center;
	}

	.voltar {
		margin: 0 1rem;
		width: 15rem;
		height: 3.125rem;
		background-color: transparent;
		color: ${Colors.primary_blue_k_main};

		border: 4px solid ${Colors.primary_blue_k_main};
		border-radius: 8px;

		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 1.375rem;
		line-height: 120%;

		text-align: center;
	}
`;
