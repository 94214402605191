import styled from "styled-components";

export const Conteiner = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 1.25rem;
	padding: 1rem 1.5rem 1rem 0;

	.scroll {
		height: 100%;
		padding-bottom: 2rem;
		padding-right: 1rem;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2rem 1rem 2rem 2.5rem;

	.nome {
		font-weight: 500;
		font-size: 1.5rem;

		padding-bottom: 0.5rem;

		color: #404040;
		width: 20rem;
	}

	.bodyHeader {
		width: 100%;

		.hr {
			border-bottom: 1px solid #c4c4c4;
			margin: 0.3rem 0;
		}

		.textHeader {
			font-weight: 400;
			font-size: 1rem;

			color: #8d8d8d;
		}
	}
`;

export const BodyList = styled.div`
	padding-bottom: 3.6rem;
	padding-left: 3.6rem;
	padding-right: 2rem;

	.left {
		display: flex;

		.titleItem {
			font-size: 1.25rem;
			font-weight: 700;

			color: #5c5b5b;
		}
	}

	.item {
		position: relative;

		transition: all 0.3s ease-in-out;

		border-bottom: 1px solid #e0e0e0;

		input {
			opacity: 0;
		}

		ul {
			overflow: hidden;
			transition: all 0.3s ease-in-out;
		}
	}

	.unchecked {
		overflow: hidden;
		max-height: 0;
		transform: all;
	}

	.checked {
		height: auto;
		max-height: 10000px;
		transform: all;
		padding-bottom: 0.4rem;
	}

	.input {
		position: absolute;
	}

	.label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-decoration: none;
		padding: 0.7rem 0;
		cursor: pointer;
		p {
			font-size: 1rem;
			font-weight: 500;

			color: #5c5b5b;
		}

		svg {
			cursor: pointer;
			margin-left: 1rem;
			transition: all 0.3s ease-in-out;
		}
	}

	.giro {
		transform: rotate(90deg);
	}

	.helper {
		border-bottom: 1px solid #b3b3b3;

		margin: 1rem 0;

		cursor: pointer;

		display: flex;
		flex-direction: column;
	}

	.helperTitle {
		color: #404040;
		font-size: 1rem;
		font-weight: 400;

		margin-bottom: 1rem;

		text-decoration: none;
	}

	.helperDescription {
		color: #666666;
		font-size: 0.75rem;
		font-weight: 500;

		margin-bottom: 1rem;

		text-decoration: none;
	}
`;

export const Footer = styled.div`
	display: flex;
	justify-content: end;
	align-items: flex-end;
	margin-top: 1rem;

	.btnAdicionar {
		z-index: 999;
		display: flex;
		flex-direction: row;
		width: 20.75rem;
		height: 4.125rem;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		background-color: white;
		border: none;
		margin-top: 0;
		font-weight: 500;
		box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);
		transition: all 0.3s linear;
	}

	.textoBotao {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		display: flex;
		align-items: center;
		color: #7f7f7f;
		margin-left: 0.9rem;
	}

	.btnAdicionar:hover {
		transform: translateY(-3px);
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
		/* transition: all 0.2s linear; */
	}

	.btnAdicionar:active {
		transform: translateY(-1px);
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
		transition: all 0.2s linear;
	}

	.btn-white {
		background-color: white;
		color: #777;
	}

	.btnAdicionar::after {
		content: "";
		display: inline-block;
		height: 100%;
		width: 100%;
		border-radius: 100px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: all 0.4s;
	}

	.btn-white::after {
		background-color: white;
		transition: all 0.2s linear;
	}

	.btnAdicionar-animated {
		animation: moveInBottom 5s ease-out;
		animation-fill-mode: backwards;
	}

	@keyframes moveInBottom {
		0% {
			opacity: 0;
			transform: translateY(30px);
		}

		100% {
			opacity: 1;
			transform: translateY(0px);
		}
	}
`;
