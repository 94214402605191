const handleMaskWeight = (e: React.FormEvent<HTMLInputElement>) => {
	e.currentTarget.maxLength = 6;
	const inputValue = e.currentTarget.value;

	let weightMask = inputValue.replace(/\D/g, "");
	if (inputValue.length > 4) {
		weightMask = weightMask.replace(/(\d{3})(\d)/, "$1,$2");
		weightMask = weightMask.replace(/(\d{3})(\d)/, "$1$2");
	} else {
		weightMask = weightMask.replace(/(\d{2})(\d)/, "$1,$2");
		weightMask = weightMask.replace(/(\d{2})(\d)/, "$1$2");
	}

	e.currentTarget.value = weightMask;

	return e.currentTarget.value;
};

export default handleMaskWeight;
