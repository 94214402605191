import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalConexoes: React.FC = () => {
	// comunica com redux
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	// função que abre a modal, mais sobre ela em store/ducks/app.ts
	const is_open_conexoes = useSelector(
		(state: any) => state.app.is_open_conexoes
	);

	// função para fechar modal
	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_conexoes(false));
	};

	return (
		<div>
			{/* librarie para abrir o modal */}
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_conexoes}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_conexoes}>
					{/* modal com as infos, feito html e css */}
					<Container>
						<div className="header">
							<span className="title">{current_language?.conexoes}</span>
							<img
								className="close"
								src={buttonClose}
								onClick={ModalCadastroClose}
								alt="Close"
							/>
						</div>
						<div className="body">
							<div className="orientadores">
								<div className="orientadorRow">
									<div className="orientadorItem">
										<div className="orientadorIcon">
											<svg
												className="orientadorConect"
												width="8"
												height="7"
												viewBox="0 0 8 7"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<circle cx="3.93984" cy="3.1" r="3.1" fill="#39E664" />
											</svg>
											<svg
												width="20"
												height="16"
												viewBox="0 0 20 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M0 5.07537L1.80364 6.98889C6.28567 2.2338 13.5543 2.2338 18.0364 6.98889L19.84 5.07537C14.366 -0.732156 5.48305 -0.732156 0 5.07537ZM7.21455 12.7294L9.92 15.5997L12.6255 12.7294C11.1375 11.1412 8.71156 11.1412 7.21455 12.7294ZM3.60727 8.90241L5.41091 10.8159C7.89993 8.17527 11.9401 8.17527 14.4291 10.8159L16.2327 8.90241C12.7517 5.20932 7.09731 5.20932 3.60727 8.90241Z"
													fill="#7F7F7F"
												/>
											</svg>
										</div>
										<span className="orientadorNome">Wi-fi</span>
									</div>

									<div className="orientadorItem">
										<div className="orientadorIcon">
											<svg
												className="orientadorConect"
												width="8"
												height="7"
												viewBox="0 0 8 7"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<circle cx="3.93984" cy="3.1" r="3.1" fill="#39E664" />
											</svg>
											<svg
												width="10"
												height="17"
												viewBox="0 0 10 17"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M9.92 5.32199L5.46342 0.719727H4.68293V6.83727L1.10049 3.13773L0 4.27419L4.36293 8.77973L0 13.2853L1.10049 14.4217L4.68293 10.7222V16.8397H5.46342L9.92 12.2375L6.5639 8.77973L9.92 5.32199ZM6.2439 3.80671L7.71122 5.32199L6.2439 6.83727V3.80671ZM7.71122 12.2375L6.2439 13.7527V10.7222L7.71122 12.2375Z"
													fill="#7F7F7F"
												/>
											</svg>
										</div>
										<span className="orientadorNome">Bluetooth</span>
									</div>
								</div>

								<div className="orientadorRow">
									<div className="orientadorItem">
										<div className="orientadorIcon">
											<svg
												className="orientadorConect"
												width="8"
												height="7"
												viewBox="0 0 8 7"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<circle cx="3.93984" cy="3.1" r="3.1" fill="#39E664" />
											</svg>
											<svg
												width="12"
												height="17"
												viewBox="0 0 12 17"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M6 0C2.68286 0 0 2.6605 0 5.95C0 10.4125 6 17 6 17C6 17 12 10.4125 12 5.95C12 2.6605 9.31714 0 6 0ZM6 8.075C4.81714 8.075 3.85714 7.123 3.85714 5.95C3.85714 4.777 4.81714 3.825 6 3.825C7.18286 3.825 8.14286 4.777 8.14286 5.95C8.14286 7.123 7.18286 8.075 6 8.075Z"
													fill="#8D8D8D"
												/>
											</svg>
										</div>
										<span className="orientadorNome">GPS</span>
									</div>

									<div className="orientadorItem dinamo">
										<div className="orientadorIcon">
											<svg
												className="orientadorConect"
												width="8"
												height="7"
												viewBox="0 0 8 7"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<circle cx="3.93984" cy="3.1" r="3.1" fill="#39E664" />
											</svg>
											<svg
												className="dino"
												width="18"
												height="26"
												viewBox="0 0 18 26"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M15.3276 25.1489H2.03239C0.910341 25.1489 0 24.2386 0 23.1166V11.5157C0 10.3937 0.910341 9.4834 2.03239 9.4834H15.3276C16.4497 9.4834 17.36 10.3937 17.36 11.5157V23.1166C17.36 24.2386 16.4497 25.1489 15.3276 25.1489ZM2.03239 10.055C1.20673 10.055 0.550439 10.7112 0.550439 11.5368V23.1166C0.550439 23.9422 1.20673 24.5985 2.03239 24.5985H15.3276C16.1533 24.5985 16.8096 23.9422 16.8096 23.1166V11.5157C16.8096 10.6901 16.1533 10.0338 15.3276 10.0338L2.03239 10.055Z"
													fill="#7F7F7F"
												/>
												<path
													d="M14.7559 24.0058H2.64629C1.86297 24.0058 1.24902 23.3707 1.24902 22.6086V12.0661C1.24902 11.2829 1.88415 10.6689 2.64629 10.6689H14.7348C15.5181 10.6689 16.132 11.304 16.132 12.0661V22.5874C16.1532 23.3707 15.5181 24.0058 14.7559 24.0058Z"
													fill="#7F7F7F"
												/>
												<path
													d="M9.67502 9.10261H7.74849L7.47327 8.8274V6.43524C7.47327 6.05419 7.76966 5.75781 8.15073 5.75781H9.27278C9.65385 5.75781 9.95024 6.05419 9.95024 6.43524V8.8274L9.67502 9.10261ZM8.04488 8.53103H9.3998V6.41407C9.3998 6.35056 9.35746 6.30822 9.29395 6.30822H8.1719C8.10839 6.30822 8.06605 6.35056 8.06605 6.41407L8.04488 8.53103Z"
													fill="#7F7F7F"
												/>
												<path
													d="M8.3625 8.80664H7.79089V9.75927H8.3625V8.80664Z"
													fill="#7F7F7F"
												/>
												<path
													d="M9.67513 8.80664H9.10352V9.75927H9.67513V8.80664Z"
													fill="#7F7F7F"
												/>
												<path
													d="M8.04476 8.57326H6.73218V6.60449H8.04476V8.57326ZM7.30379 8.02286H7.49432V7.17607H7.30379V8.02286Z"
													fill="#7F7F7F"
												/>
												<path
													d="M10.7125 8.57326H9.3999V6.60449H10.7125V8.57326ZM9.95034 8.02286H10.1409V7.17607H9.95034V8.02286Z"
													fill="#7F7F7F"
												/>
												<path
													d="M11.4533 9.10245H10.5006C10.3524 9.10245 10.2254 8.97543 10.2254 8.82725V5.35543C10.2254 5.20725 10.3524 5.08023 10.5006 5.08023C10.6488 5.08023 10.7758 5.20725 10.7758 5.35543V8.53087H11.1569V6.03286C11.1569 5.96935 11.1781 5.92701 11.1992 5.88467C11.6438 5.20725 11.8344 4.63567 11.919 4.29695C12.0037 3.93707 11.9826 3.7042 11.9826 3.7042V3.68304C11.919 2.39169 11.3474 1.4814 10.2466 0.952158C9.061 0.380579 7.70607 0.486427 6.68988 1.24853C5.90656 1.84128 5.4408 2.70923 5.4408 3.57719C5.4408 4.21228 5.56783 4.76268 5.82188 5.27076C6.01241 5.63064 6.20295 5.82116 6.20295 5.84233C6.26646 5.88467 6.28763 5.96935 6.28763 6.05403V8.55204H6.71105V5.48245C6.71105 5.33426 6.83807 5.20725 6.98627 5.20725C7.13446 5.20725 7.26149 5.33426 7.26149 5.48245V8.80608C7.26149 8.95426 7.13446 9.08128 6.98627 9.08128H5.99124C5.84305 9.08128 5.71602 8.95426 5.71602 8.80608V6.13871C5.48314 5.8635 4.84802 5.01672 4.84802 3.57719C4.84802 2.51871 5.39846 1.4814 6.3088 0.782801C7.49436 -0.106322 9.08217 -0.254509 10.4583 0.422918C11.3474 0.867479 12.4483 1.77777 12.5118 3.6407C12.533 3.76771 12.5753 4.72035 11.6862 6.09637V8.80608C11.7285 8.97543 11.6015 9.10245 11.4533 9.10245Z"
													fill="#7F7F7F"
												/>
												<path
													d="M8.7226 1.33301C7.30416 1.33301 6.13977 2.41266 6.13977 3.83102C6.13977 4.52962 6.4785 5.20704 6.9866 5.69394C7.09245 5.79979 7.26182 5.71511 7.28299 5.56693V5.41874C7.28299 5.3764 7.26182 5.33406 7.24065 5.29172C6.90192 4.91067 6.6267 4.33909 6.6267 3.83102C6.6267 2.68786 7.55821 1.81991 8.7226 1.81991C9.88699 1.81991 10.8185 2.66669 10.8185 3.83102C10.8185 4.29675 10.628 4.74131 10.3527 5.1012C10.2681 5.20704 10.2469 5.31289 10.2469 5.43991V5.52459C10.2469 5.67277 10.4374 5.73628 10.5433 5.63043C11.0302 5.14353 11.3054 4.50845 11.3054 3.80985C11.3054 2.41266 10.141 1.33301 8.7226 1.33301Z"
													fill="#7F7F7F"
												/>
												<path
													d="M12.6815 8.9327C12.6391 8.9327 12.618 8.9327 12.5756 8.91153L11.3901 8.4458L11.6018 7.91656L12.4274 8.2341V6.7734L11.6018 7.15445L11.3689 6.64638L12.5756 6.13831C12.6603 6.09597 12.7662 6.11714 12.8508 6.15948C12.9355 6.20182 12.9779 6.30767 12.9779 6.39235V8.67866C12.9779 8.76334 12.9355 8.84802 12.8508 8.91153C12.7873 8.9327 12.745 8.9327 12.6815 8.9327Z"
													fill="#7F7F7F"
												/>
												<path
													d="M10.554 16.0761C10.4998 15.8485 10.4156 15.7873 10.178 15.7873C9.97611 15.7861 9.77425 15.7861 9.57124 15.7861H8.14672C7.92641 15.7861 7.70725 15.7861 7.48579 15.7873C7.3739 15.7861 7.25856 15.7873 7.14667 15.7873C6.90791 15.7873 6.82601 15.8485 6.77295 16.0761C6.70144 16.3719 6.63108 16.6052 6.56648 16.9045C6.54572 16.9992 6.59878 17.1621 6.69452 17.1621C6.96327 17.1609 7.22395 17.1609 7.48464 17.1609C7.86989 17.1609 8.25053 17.1621 8.64848 17.1621H8.67501C8.98067 17.1621 9.27596 17.1621 9.5724 17.1609C9.92074 17.1609 10.2691 17.1598 10.6313 17.1621C10.7259 17.1621 10.7812 16.9992 10.7605 16.9045C10.6959 16.6052 10.6232 16.3719 10.554 16.0761Z"
													fill="#FFF8F8"
												/>
												<path
													d="M13.2969 15.7364V17.1829H12.8609C12.5575 17.1817 12.2922 17.1817 11.9358 17.1829C11.7501 17.184 11.6728 17.0535 11.6509 16.9564C11.5436 16.4735 11.4836 16.2032 11.3302 15.7919C11.2299 15.5181 11.0407 15.3286 10.7546 15.2431C10.6047 15.1992 10.4778 15.1726 10.2956 15.1715C9.22747 15.1645 8.14668 15.1645 7.07281 15.1703C6.95401 15.1703 6.83635 15.1784 6.71985 15.1992C6.3669 15.2604 6.06123 15.4072 5.94819 15.7734C5.79247 16.2679 5.66559 16.7739 5.63791 17.2949C5.60331 17.9639 5.7521 18.6074 5.94588 19.2428C6.02086 19.49 6.19272 19.6471 6.44302 19.7269C6.67948 19.8031 6.92171 19.8308 7.16855 19.8308C8.22973 19.8308 9.29206 19.8308 10.3532 19.8297C10.5436 19.8297 10.7316 19.795 10.9115 19.7292C11.1434 19.6437 11.2737 19.4946 11.3694 19.2428C11.4329 19.0718 11.4825 18.9089 11.5217 18.7183C11.5425 18.6212 11.5828 18.5727 11.4917 18.5739C11.2852 18.575 11.058 18.5681 10.8389 18.5796C10.7016 18.5866 10.6635 18.6767 10.6635 18.6767C10.4617 19.0914 10.4571 19.2162 10.2275 19.2197H9.62887C8.72802 19.2197 8.25049 19.2185 7.34733 19.2197C7.24814 19.2197 7.20315 19.222 7.03244 19.2197C6.92055 19.2174 6.86404 19.185 6.81444 19.0729C6.73369 18.7391 6.64949 18.5242 6.59528 18.1845C6.57798 18.0725 6.61604 17.9269 6.73716 17.9269C10.5355 17.9234 13.1492 17.9269 13.2899 17.9269L13.2888 17.928H13.298V19.2647C13.298 20.2225 12.5264 21 11.5748 21H5.72327C4.77166 21 4 20.2225 4 19.2647V15.7364C4 14.7775 4.77166 14 5.72327 14H11.5736C12.5252 14 13.2969 14.7775 13.2969 15.7364Z"
													fill="#FFF8F8"
												/>
											</svg>
										</div>
										<span className="orientadorNome">Dinamô</span>
									</div>
								</div>
							</div>
						</div>
						<div className="footer">
							<div className="conectado">
								<svg
									width="8"
									height="7"
									viewBox="0 0 8 7"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<circle cx="3.93984" cy="3.1" r="3.1" fill="#39E664" />
								</svg>
								<span className="legenda">
									{current_language?.conexoes_conectado}
								</span>
							</div>
							<div>
								<svg
									width="8"
									height="7"
									viewBox="0 0 8 7"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<circle cx="3.93984" cy="3.1" r="3.1" fill="#D12600" />
								</svg>
								<span className="legenda">
									{current_language?.conexoes_desconectado}
								</span>
							</div>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalConexoes;
