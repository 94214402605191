import React from 'react';

import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';

import { AppActions } from '../../../store/ducks/app';
import { TextsProps } from '../../../database/txt.interface';

import Success from '../../../assets/kinologyIcons/svg/SetFotoSucesso.svg';
import Close from '../../../assets/icons/iconClose.svg';

import { Container, Image } from './style';
import { Colors } from '../../../styles/colors';

import Text from '../../Texts/Text';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalRegisterSuccess = ()=> {

    const dispatch = useDispatch()
    const classes = useStyles();

    const title: string = useSelector((state: any)=> state.app.title_modal_register_sucess)
    const subtitle: string = useSelector((state: any)=> state.app.subtitle_modal_register_sucess)
    const open_modal_register_success: boolean = useSelector((state: any)=> state.app.open_modal_register_sucess)
    const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open_modal_register_success}
            onClose={()=>dispatch(AppActions.set_Modal_Register_Success(false,'', ''))}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open_modal_register_success}>
                <Container>
                    <img
                        src={Close}
                        onClick={()=> dispatch(AppActions.set_Modal_Register_Success(false,'', ''))}
                        style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            top: 24,
                            right: 28
                        }}
                    />
                    <Image  src={Success}/>
                    <Text 
                        title={title}
                        fontFamily='NotoSans-Bold'
                        fontSize={2}
                        marginTop={24}
                        textColor={Colors.primary_blue_k_main}
                    />
                    <Text 
                        title={subtitle}
                        fontFamily='NotoSans-Regular'
                        fontSize={2}
                        marginTop={22}
                        textColor={Colors.primary_blue_k_main+80}
                    />
                  
                </Container>
            </Fade>
        </Modal>
    )
}
export default ModalRegisterSuccess;
