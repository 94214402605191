import {
	Backdrop,
	Fade,
	Modal,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import buttonClose from "../../../assets/icons/close.svg";
import { ButtonSave, Icon } from "../../../styles/global";
import { TextsProps } from "../../../database/txt.interface";
import {
	Close,
	Container,
	ContainerOptions,
	Title,
} from "../../../styles/styles_modais_padroes";
import styled from "styled-components";
import { P1Desk, P3Desk, S3Desk } from "../../../styles/styles_text";
import BackIcon from "../../../assets/icons/arrow_back.svg";
import DivisaoLados from "../../../assets/icons/divisao_de_lados.svg";
import SemDivisaoLados from "../../../assets/icons/sem_divisao.svg";
import Check from "../../../assets/icons/check.svg";
import ArrowForward from "../../../assets/icons/arrow_forward.svg";
import ArrowForwardDown from "../../../assets/icons/arrow_forward_down.svg";
import { ExerciciosActions } from "../../../store/ducks/exer";
import { AgrupamentosProps } from "../../../interfaces/agrupamento.interface";
import moment from "moment";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { AmplitudeLog } from "../../../utils/amplitude";
import { AppActions } from "../../../store/ducks/app";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalAdicionarExercicio: React.FC = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const is_open_modal_adicionar_exercicio = useSelector(
		(state: any) => state.exercicios.is_open_modal_adicionar_exercicio
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const agrupamentos = useSelector(
		(state: any) => state.dashboard.agrupamentos
	);

	const ModalClose = () => {
		dispatch(PacAvalActions.reset_patient());
		dispatch(ExerciciosActions.set_open_modal_adicionar_exercicio(false));
		setInputValue("");
		set_current_section("nome_exercicio");
	};

	const [current_section, set_current_section] = useState("nome_exercicio");


	//______________Input section__________________
	const [inputValue, setInputValue] = useState("");

	const handleChange = (event: any) => {
		setInputValue(event.target.value);
	};

	function _renderInputNameSection() {
		return (
			<>
				<div
					style={{ height: "100%", display: "flex", flexDirection: "column" }}
				>
					<P1Desk style={{ marginBottom: "2rem" }}>
						Digite abaixo o nome do exercício que deseja adicionar.
					</P1Desk>
					<Label>Nome do exercício*</Label>
					<InputExercise
						placeholder="Digite"
						type="textfdf"
						value={inputValue}
						onChange={handleChange}
					/>
				</div>
				<ButtonSave
					type="submit"
					active={inputValue != "" ? true : false}
					onClick={() =>
						inputValue != "" ? set_current_section("selecao_tipo") : ""
					}
				>
					{current_language?.menu_lateral_botao_avancar}
				</ButtonSave>
			</>
		);
	}

	//_____________Group List________________________

	const [group_selected, set_group_selected]: any = useState(0);

	const _renderTypeExercise = () => {
		return (
			<>
				<Back
					src={BackIcon}
					onClick={() => set_current_section("nome_exercicio")}
				/>
				<P1Desk>
					{current_language?.nome_do_exercicio}: {inputValue}
				</P1Desk>
				<Hr />
				<P1Desk style={{ marginBottom: "1rem" }}>
					Selecione uma opção para este exercício.
				</P1Desk>

				<ContainerOptions style={{ height: "100%" }}>
					<DivisaoSection
						onClick={() => set_group_selected(0)}
						style={
							group_selected === 0
								? { border: "3px solid #00D084" }
								: { border: "1px solid #dedfe3" }
						}
					>
						<TutorialSection
							onClick={() =>
								dispatch(AppActions.set_modal_tutorial(true, muscle_selected))
							}>
							<Icon
								style={{ width: "100%", height: "100%" }}
								src={DivisaoLados}
							/>
						</TutorialSection>
						<TitleAndDescription>
							<S3Desk>{current_language?.exercise_possui_divisao}</S3Desk>
						</TitleAndDescription>
					</DivisaoSection>
					<DivisaoSection
						onClick={() => set_group_selected(1)}
						style={
							group_selected === 1
								? { border: "3px solid #00D084" }
								: { border: "1px solid #dedfe3" }
						}
					>
						<TutorialSection
							onClick={() =>
								dispatch(AppActions.set_modal_tutorial(true, muscle_selected))
							}>
							<Icon
								style={{ width: "100%", height: "100%" }}
								src={SemDivisaoLados}
							/>
						</TutorialSection>
						<TitleAndDescription>
							<S3Desk>{current_language?.exercicios_sem_divisao}</S3Desk>
						</TitleAndDescription>
					</DivisaoSection>
				</ContainerOptions>
				<ButtonSave
					type="submit"
					active={true}
					onClick={() => {
						set_current_section("exercise_list");
					}}
				>
					{current_language?.menu_lateral_botao_avancar}
				</ButtonSave>
			</>
		);
	};

	//___________Muscle list_____________________________

	const [regiao_selecionada, set_regiao_selecionada] = useState(0);
	const [muscle_selected, set_muscle_selected]: any = useState("");

	function _renderMuscleList() {
		return (
			<>
				<Back
					src={BackIcon}
					onClick={() => set_current_section("selecao_tipo")}
				/>
				<P1Desk style={{ marginBottom: "1rem" }}>
					Selecione o músculo que será afetado por esse exercício.
				</P1Desk>
				<ContainerOptions style={{ height: "100%" }}>
					{agrupamentos.map((item: AgrupamentosProps) => (
						<MuscleOption key={item.ID_AGRUPAMENTO}>
							<DivMuscle
								onClick={() =>
									set_regiao_selecionada(item.ID_AGRUPAMENTO)
								}
							>
								<P1Desk>{item.AGRUPAMENTO_DESCRICAO_PT}</P1Desk>
								<Icon
									src={
										regiao_selecionada == item.ID_AGRUPAMENTO
											? ArrowForwardDown
											: ArrowForward
									}
								/>
							</DivMuscle>
							{item.AGRUPAMENTO_MUSCULOS.map((muscle: any) => (
								<DivMuscle
									key={muscle}
									onClick={() => set_muscle_selected(muscle)}
									style={{
										display:
											regiao_selecionada == item.ID_AGRUPAMENTO
												? "flex"
												: "none",
									}}
								>
									<P3Desk style={{ marginLeft: "1rem" }}>
										{muscle.MUSCULO_TITULO_PT}
									</P3Desk>
									{muscle_selected?.MUSCULO_TITULO_PT == muscle?.MUSCULO_TITULO_PT && (
										<Icon src={Check} />
									)}
								</DivMuscle>
							))}
						</MuscleOption>
					))}
				</ContainerOptions>
				<ButtonSave
					type="submit"
					active={muscle_selected != "" ? true : false}
					onClick={() => {
						AddExercise()
					}}
				>
					{current_language?.menu_lateral_botao_salvar}
				</ButtonSave>
			</>
		);
	}


	const AddExercise = () => {
		const NovoExercicio = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			EXERCICIO_TIPO: group_selected,
			ID_AGRUPAMENTO: regiao_selecionada,
			MUSCULO_TITULO: muscle_selected.MUSCULO_TITULO_PT,
			ID_MUSCULO: muscle_selected.ID_MUSCULO,
			EXERCICIO_TITULO: inputValue,
		};

		dispatch(ManageProtocolActions.add_exer_request(NovoExercicio));
		AmplitudeLog('exercicio_adicionado',{body: NovoExercicio})
		ModalClose()

	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open_modal_adicionar_exercicio}
			onClose={ModalClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open_modal_adicionar_exercicio}>
				<Container>
					<Close src={buttonClose} onClick={ModalClose} />
					<Title>{current_language?.adicionar_exercicio}</Title>
					{current_section == "nome_exercicio"
						? _renderInputNameSection()
						: current_section === "selecao_tipo"
						? _renderTypeExercise()
						: _renderMuscleList()}
				</Container>
			</Fade>
		</Modal>
	);
};

const DivisaoSection = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-radius: 18px;
	margin-bottom: 1rem;
	padding-left: 1rem;
	border: 1px solid #dedfe3;
	cursor: pointer;
`;

const TitleAndDescription = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 2rem;
	padding-right: 2rem;
	text-align: center;
`;

const TutorialSection = styled.div`
	display: flex;
`;

const InputExercise = styled.input`
	background-color: transparent;
	border: none;
	width: 100%;
	font-size: 80%;
	border-bottom: 2px solid #e0e0e0;
	font-family: "NotoSans-Regular";
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 24px;
	padding-bottom: 0.3rem;
`;

const Label = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1rem;
	font-weight: 600;
	line-height: 26.4px;
`;

export const Back = styled.img`
	width: 1rem;
	height: 1rem;
	position: absolute;
	left: 2.5rem;
	top: 2.5rem;
	cursor: pointer;
`;

export const Hr = styled.hr`
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	color: #000e4b;
	background-color: #000e4b;
`;

export const MuscleOption = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const DivMuscle = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export default ModalAdicionarExercicio;
