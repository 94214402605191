import React, { useEffect } from "react";
import InputSelectSearch from "./InputSelectSearch";

import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../../../store/ducks/app";
import {
	TypesErrorRequest,
	TypesPermission,
} from "../../../../utils/types";
import { getPermission } from "../../../../utils/funcs";
import { ServicosProps } from "../../../../interfaces/config.interface";
import { RelatorioActions } from "../../../../store/ducks/relatorios";
import { TextsProps } from "../../../../database/txt.interface";
import { AssimetriaPros } from "../../../../interfaces/assimetria.interface";
import { PacientesProps } from "../../../../interfaces/pacientes.interface";
import GraficoEvolucaoAssimetria from "./GraficoEvolucaoAssimetria";

const EvolucaoAssimetria: React.FC = ({}) => {
	const dispatch = useDispatch();
	const config_servicos_array: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const assimetrias: AssimetriaPros[] = useSelector(
		(state: any) => state.dashboard.assimetria
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const assimetria: any = assimetrias.filter(
		(ass) => ass.ID_USUARIO == patient.ID_USUARIO
	);

	const LoadHistoricoAssimetria = () => {
		const objets_to_array = Object.values(assimetria[0]?.LISTAS);
		const arrayUnico = objets_to_array.flat();

		if (arrayUnico.length > 0) {
			!getPermission(
				config_servicos_array,
				TypesPermission.SERVICO_REL_ASSIMETRIA,
				dispatch
			)
				? dispatch(AppActions.set_modal_upgrade_funcionalidade(true))
				: dispatch(RelatorioActions.set_exercicios_historicos(arrayUnico))
		} else if (arrayUnico.length == 0) {
			dispatch(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: current_language?.sem_historico_assimetria_grupamento,
				})
			);
		}
	};

	useEffect(() => {
		LoadHistoricoAssimetria();
	}, []);

	return (
		<>
			<InputSelectSearch />
			<GraficoEvolucaoAssimetria />
		</>
	);
};

export default EvolucaoAssimetria;

