import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import appStore from "../../../assets/appStore.svg";
import googlePlay from "../../../assets/googlePlay.svg";
import logo from "../../../assets/kinologyIcons/svg/logo.svg";
import { TextsProps } from "../../../database/txt.interface";
import history from "../../../routes/history";
import { Conteiner } from "./styles";
import { AuthActions } from "../../../store/ducks/auth";

const AvisoMobile: React.FC = function () {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const updatePhase: boolean = useSelector(
		(state: any)=> state.configs.remove_button_close
	)

	const redirecionar_google = () => {
		window.location.href =
			"https://play.google.com/store/apps/details?id=com.esportese.elastic";
	};

	const redirecionar_apple = () => {
		window.location.href =
			"https://apps.apple.com/br/app/kinology/id1275941432";
	};
	const navigateUpgrade = () => window.open(`https://wa.me/556133479291?text=Ol%C3%A1%2C%20gostaria%20de%20mudar%20o%20meu%20plano%20`, '_blank');
	return (
		<Conteiner>
			<div className="corpo">
			<svg width="150" height="111" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M5.18652 11.7529V7.44621L7.50964 12.4221L9.62925 12.9598L6.13979 8.39131L10.9482 13.2207L14.4742 13.7846L6.94226 8.2238L16.3568 13.8501L23.3333 14L5.67385 7L23.3333 0L16.3568 0.149944L6.94227 5.7762L14.4742 0.215365L10.9482 0.77929L6.13983 5.60865L9.62925 1.0402L7.50964 1.57795L5.18652 6.5538V2.28918L3.75501 2.85206V11.19L5.18652 11.7529Z" fill="#00D084"/>
				<path d="M2.30127 10.4809V3.56124L3.27013 3.06274V10.9796L2.30127 10.4809Z" fill="#00D084"/>
				<path d="M1.09031 4.30465V9.7377L1.81701 10.1946V3.84749L1.09031 4.30465Z" fill="#00D084"/>
				<path d="M5.44555e-06 8.83595L0 5.20615L0.666214 4.6629L0.666217 9.3792L5.44555e-06 8.83595Z" fill="#00D084"/>
			</svg>

				<p>{updatePhase ? current_language?.modal_upgrade_funcionalidade_indisponivel : current_language?.baixe_app_acesso_pelo_site}</p>
				<p>{updatePhase ? current_language?.modal_upgrade_funcionalidade_indisponivel2 : ''}</p>

				{ updatePhase
					?
						<div className="logoutAndUpgrade">
							<button className="button upgrade" onClick={navigateUpgrade}>UPGRADE</button>
							<button className="logout" onClick={()=>dispatch(AuthActions.auth_logout())}>{current_language?.sair_modal}</button>
						</div>
					:
						<div className="botoes">
							<img src={appStore} onClick={redirecionar_apple} />
							<img src={googlePlay} onClick={redirecionar_google} />
						</div>
					
				}
			</div>
		</Conteiner>
	);
};

export default memo(AvisoMobile);
