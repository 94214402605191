
const handleMaskHeight = (e: React.FormEvent<HTMLInputElement>) => {
	e.currentTarget.maxLength = 4;
	const inputValue = e.currentTarget.value;

	let heightMask = inputValue.replace(/\D/g, "");
	heightMask = heightMask.replace(/(\d{1})(\d)/, "$1,$2");
	heightMask = heightMask.replace(/(\d{1})(\d)/, "$1$2");

	e.currentTarget.value = heightMask;

	return e.currentTarget.value;
};

export default handleMaskHeight;
