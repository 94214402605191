import React, { memo } from "react";
import { Nota_add } from "./styles";

interface questionProps {
	item: string,
	funcao?: any,
}

const AdicionarCampo: React.FC<questionProps> = ({ item, funcao }) => {

	return (
		<>
			<Nota_add onClick={() => funcao()}>
				<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M18.75 12H14V7.25C14 7.1125 13.8875 7 13.75 7H12.25C12.1125 7 12 7.1125 12 7.25V12H7.25C7.1125 12 7 12.1125 7 12.25V13.75C7 13.8875 7.1125 14 7.25 14H12V18.75C12 18.8875 12.1125 19 12.25 19H13.75C13.8875 19 14 18.8875 14 18.75V14H18.75C18.8875 14 19 13.8875 19 13.75V12.25C19 12.1125 18.8875 12 18.75 12Z" fill="#000E4B" />
					<path d="M13.5 0C6.04487 0 0 6.04487 0 13.5C0 20.9551 6.04487 27 13.5 27C20.9551 27 27 20.9551 27 13.5C27 6.04487 20.9551 0 13.5 0ZM13.5 24.7098C7.31049 24.7098 2.29018 19.6895 2.29018 13.5C2.29018 7.31049 7.31049 2.29018 13.5 2.29018C19.6895 2.29018 24.7098 7.31049 24.7098 13.5C24.7098 19.6895 19.6895 24.7098 13.5 24.7098Z" fill="#000E4B" />
				</svg>
				<p>{item}</p>
			</Nota_add>
		</>
	)
};

export default memo(AdicionarCampo);