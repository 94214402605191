import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { TextsProps } from "../../../database/txt.interface";
import { PacientesProps, UserProps } from "../../../interfaces/pacientes.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { AppActions } from "../../../store/ducks/app";
import { FinancialActions } from '../../../store/ducks/financial';
import { ContainerGoBack, ContainerInfoPlan, Conteiner, Header, ButtonUpgrade, Item,  TitleItem, Subtitle, Content, } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import history from "../../../routes/history";
import {ReactComponent as ArrowBack} from '../../../assets/kinologyIcons/svg/arrow_back.svg';
import {ReactComponent as Check} from '../../../assets/kinologyIcons/svg/check.svg';
import {ReactComponent as Close} from '../../../assets/kinologyIcons/svg/noneOption.svg';
import { ServiceTypePatient } from "../../../interfaces/planos.interface";
import Text from '../../../components/Texts/Text';
import { Colors } from "../../../styles/colors";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { H3Desk } from "../../../styles/styles_text";

const MeuPlano: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const planos_upsell = useSelector(
		(state: any) => state.financial.planos_upsell
	);
	const config_servicos: ServicosProps = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const avaliadores: PacientesProps[] = useSelector(
		(state: any) => state.dashboard.avaliadores
	);
	const pacientes: PacientesProps[] = useSelector(
		(state: any) => state.dashboard.pacientes
	);
	

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("meuPlano"));
		dispatch(FinancialActions.set_select_item(""));
	}, []);

	const filterPlanos = planos_upsell?.planos?.filter((item: any)=> item?.PLANO_PESO > config_servicos?.SERVICO_PLANO_PESO)
	const date = new Date().toLocaleDateString('pt-BR');
	let plano = "";

	if (
		// !!config_servicos?.SERVICO_PLANO_TRIAL_FIM
		// && 
		new Date(config_servicos?.SERVICO_PLANO_TRIAL_FIM).toLocaleDateString('pt-BR') >= date
	) {
		
		plano = current_language?.idioma === "Português" 
			&& `${current_language?.menu_lateral_plano} Trial ${config_servicos?.SERVICO_PLANO_NOME_TRIAL} ${new Date(config_servicos?.SERVICO_PLANO_TRIAL_FIM).toLocaleDateString('pt-BR')}`
			|| `${config_servicos?.SERVICO_PLANO_NOME} ${current_language?.menu_lateral_plano}`;
	} else if (new Date(config_servicos?.SERVICO_PLANO_INICIO).toLocaleDateString('pt-BR') <= date) {
		plano = current_language?.idioma === "Português" 
		&& `${current_language?.menu_lateral_plano} ${config_servicos?.SERVICO_PLANO_NOME}`
		|| `${config_servicos?.SERVICO_PLANO_NOME} ${current_language?.menu_lateral_plano}`;
	}
	
	const selectContrato = () => {
		AmplitudeLog('upgrade_plano')
		window.open(`https://wa.me/556133479291?text=Ol%C3%A1%2C%20gostaria%20de%20mudar%20o%20meu%20plano%20`, '_blank');
	}

	const ItenPlans = [
		{
			title: current_language?.perfil_paciente_treino , 
			include: config_servicos.SERVICO_FREE_TRAINING ? < Check /> : <Close color={Colors?.tertirary_red_1_dark} />
		},
		{
			title: current_language?.menu_lateral_personalizar_relatorio , 
			include: config_servicos.SERVICO_CSV ? < Check /> : <Close color={Colors?.tertirary_red_1_dark} />
		},
		{
			title: current_language?.perfil_logomarca_relatorio, 
			include:config_servicos.SERVICO_LOGOMARCA ? < Check /> : <Close color={Colors?.tertirary_red_1_dark} />
		},
		{
			title: current_language?.menu_lateral_percentual_rm, 
			include: config_servicos.SERVICO_PERCENTUALRM ? < Check /> : <Close color={Colors?.tertirary_red_1_dark} />
		},
		{
			title: current_language?.menu_lateral_modo_web, 
			include: config_servicos.SERVICO_WEB ? < Check /> : <Close color={Colors?.tertirary_red_1_dark} />
		},
		{
			title: current_language?.perfil_avaliacao_iq , 
			include: config_servicos.SERVICO_AVALIACAO_IQ ? < Check /> : <Close color={Colors?.tertirary_red_1_dark} />
		},
		{
			title: current_language?.pag_inicial_avaliador, 
			include: `${avaliadores.length}/${config_servicos.SERVICO_AVALIADORES >= 999999999 ? current_language?.ilimitado : config_servicos.SERVICO_AVALIADORES}`
		},
		{
			title: current_language?.pag_inicial_pacientes, 
			include: `${config_servicos?.SERVICO_PACIENTES_CADASTRADOS}/${config_servicos?.SERVICO_PACIENTES}`
		},
		...config_servicos?.SERVICO_PACIENTES_TIPO == ServiceTypePatient?.MENSAL  
			?
				[
					{
						title: current_language?.cadastros_mensais, 
						include: `${config_servicos?.SERVICO_PACIENTES_CADASTRADOS}/${config_servicos?.SERVICO_PACIENTES}`
					},
				]
			: [],
		{
			title:current_language?.meu_plano_capacidade , 
			include: `${config_servicos.SERVICO_FORCA} kg`
		},
		{
			title: current_language?.menu_lateral_relatorio_assimetria, 
			include:config_servicos.SERVICO_REL_ASSIMETRIA ? < Check /> : <Close color={Colors?.tertirary_red_1_dark} />
		},
		{
			title: current_language?.menu_lateral_relatorio_avaliacao, 
			include: config_servicos.SERVICO_REL_AVALIACAO ? < Check /> : <Close color={Colors?.tertirary_red_1_dark} />
		},
		{
			title: current_language?.menu_lateral_relatorio_forca_tempo , 
			include: config_servicos.SERVICO_REL_FORCA ? < Check /> : <Close color={Colors?.tertirary_red_1_dark} />
		},
		{
			title: current_language?.anamnese , 
			include: config_servicos.SERVICO_ANAMNESE ? < Check /> : <Close color={Colors?.tertirary_red_1_dark} />
		},
	]

	return (
		<Conteiner>
			<Content>

				<Header>
					<ContainerGoBack 
						onClick={()=> {
							dispatch(DashBoardActions.set_current_page("minha_conta"))
							dispatch(AppActions.set_option_menu(false, current_language?.menu_lateral_minha_conta))
						}}
					>
						<ArrowBack />
						<Text 
							title={current_language.menu_lateral_minha_conta}
							fontFamily="NotoSans-Regular"
							fontSize={0.7}
							fontWeight={400}
							textColor={Colors?.primary_blue_k_main}
							marginLeft={6}
							cursor="pointer"
						/>
					</ContainerGoBack>
					<Text 
						title={current_language.menu_lateral_meu_plano}
						fontFamily="NotoSans-Regular"
						fontSize={1.5}
						fontWeight={700}
						textColor={Colors?.primary_blue_k_main}
						textAlign="left"
						marginTop={15}
					/>
					<ContainerInfoPlan>
							<Text 
								title={plano}
								fontFamily="NotoSans-Bold"
								fontSize={1.3}
								fontWeight={700}
								textColor={Colors?.primary_blue_k_main}
								textAlign="left"
								width={'50%'}
								marginTop={15}
							/>
							<ButtonUpgrade
								onClick={()=> {
									filterPlanos.length > 0 
									? [AmplitudeLog('upgrade_plano'), history.push("/upgrade")]
									: selectContrato()
								}}
							>
								<Text 
									title={current_language?.menu_lateral_upgrade}
									fontFamily="Kanit-Regular"
									fontSize={1.37}
									fontWeight={600}
									textColor={Colors?.neutral_very_light}
									cursor="pointer"
								/>
							</ButtonUpgrade>
					</ContainerInfoPlan>
				</Header>
				{
					ItenPlans?.map((item, index)=> (
						<>
							<Item key={index}>
								<TitleItem>{item?.title}</TitleItem>
								<Subtitle include={item?.title}>
									{item?.include}
								</Subtitle>
							</Item>
						</>
					))
				}
			</Content>
		</Conteiner>
	);
};

export default memo(MeuPlano);
