import React, { memo } from "react";
import { useSelector } from 'react-redux';
import styled from "styled-components";

interface Musculo {
	MUSCULO: number,
	LADO: "E" | "D" | "S",
	VALOR?: number
}

interface questionProps {
	obj: Musculo,
	musculo: string,
	item: any,
	valueSlider: any,
	isActive: any,
	addMarcador: any
}

const colors = [
	'#4CC71F',
	'#66C722',
	'#7EC90D',
	'#BFCB06',
	'#FFCC01',
	'#FFB200',
	'#FE9900',
	'#FC8108',
	'#FB6713',
	'#F94A1A',
	'#F3281E'
]

const RadioButtonCorpo: React.FC<questionProps> = ({ obj, musculo, item, valueSlider, isActive, addMarcador }) => {

	const array_marcadores = useSelector(
		(state: any) => state.pac_aval.array_marcadores
	);

	const exist = (obj: Musculo) => {
		const exist = array_marcadores.find((e: Musculo) => `${e.MUSCULO}${e.LADO}` === `${obj.MUSCULO}${obj.LADO}`);
		return !!exist;
	}
	const valor = valueSlider.find((i: any) => i.MUSCULO === obj.MUSCULO && i.LADO === obj.LADO)
	const top = `${musculo}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[0]}` ? 60 :
		`${musculo}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[1]}` ? 105 :
			`${musculo}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[2]}` ? 142 :
				`${musculo}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[3]}` ? 147 :
					`${musculo}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[4]}` ? 217 :
						`${musculo}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[5]}` ? 289 :
							`${musculo}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[6]}` ? 44 :
								`${musculo}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[7]}` ? 96 :
									`${musculo}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[8]}` ? 126 :
										`${musculo}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[9]}` && 153;

	const left = `${musculo}${obj.LADO}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[0]}E` ? 103 :
		`${musculo}${obj.LADO}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[0]}D` ? 45 :
			`${musculo}${obj.LADO}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[1]}E` ? 115 :
				`${musculo}${obj.LADO}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[1]}D` ? 34 :
					`${musculo}${obj.LADO}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[2]}E` ? 130 :
						`${musculo}${obj.LADO}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[2]}D` ? 18 :
							`${musculo}${obj.LADO}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[3]}E` ? 90 :
								`${musculo}${obj.LADO}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[3]}D` ? 58 :
									`${musculo}${obj.LADO}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[4]}E` ? 88 :
										`${musculo}${obj.LADO}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[4]}D` ? 60 :
											`${musculo}${obj.LADO}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[5]}E` ? 85 :
												`${musculo}${obj.LADO}` === `${item.QUESTIONARIO_PERGUNTA_OPCOES[5]}D` ? 63 : 73;

	const Marcador = styled.div`
			display: flex;
			align-items: flex-start;
			margin-bottom: 1.6rem;
			margin-right: 3rem;
			position: absolute;
			top: ${top}px;
			left: ${left}px;
		
			label {
				cursor: ${!!isActive ? "not-allowed" : "pointer"};
				color: #404040;
			}
		
			.custom-checkbox {
				margin-right: .5rem;
			}
		
			.custom-checkbox input + .label2:before {
				content: "";
				width: 0.532rem;
				height: 0.532rem;
				border-radius: 50%;
				background-color: #EDEDED;
				border: 2px solid #5C5B5B;
				display: inline-block;
				vertical-align: middle;
				cursor: ${!!isActive ? "not-allowed" : "pointer"};
				display: flex;
				align-items: center;
			}
			
			.custom-checkbox input:checked + .label2:before {
				background-color: ${`${!!valor?.VALOR ? colors[valor?.VALOR - 1] : colors[0]}`};
				background-position: center;
				border: 2px solid #5C5B5B;
				cursor: ${!!isActive ? "not-allowed" : "pointer"};
				display: flex;
				align-items: center;
			}
			`;
	
	return (
		<Marcador key={`${obj.MUSCULO}${obj.LADO}`} className={`${musculo}${obj.LADO}, ${obj.VALOR}`}>
			<div className="custom-checkbox">
				<input
					id={`checkbox${obj.MUSCULO}${obj.LADO}`}
					checked={exist(obj)}
					type="checkbox"
					style={{ display: "none" }}
					onChange={() => !isActive ? addMarcador(obj) : ""}
				/>
				<label
					className={"label2"}
					htmlFor={`checkbox${obj.MUSCULO}${obj.LADO}`}
				></label>
			</div>
		</Marcador>
	)
};

export default memo(RadioButtonCorpo);