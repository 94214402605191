import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import user from "../../../assets/icons/user.svg";
import NotVisible from "../../../assets/icons/notvisible.svg";
import Visible from "../../../assets/icons/visible.svg";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { SenhaActions } from "../../../store/ducks/senha";
import { Conteiner, BodyEdite, Aviso, CampoObrigatorio } from "./styles";
import { AssinaturaActions } from "../../../store/ducks/assinatura";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { Redirect } from "react-router-dom";
import { AmplitudeLog } from "../../../utils/amplitude";

const Assinatura: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);
	const user_configs: UserProps = useSelector((state: any) => state.configs);
	console.log(user_configs);
	const img_assinatura: any = useSelector(
		(state: any) => state.assinatura.assinatura
	);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	//Login
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm();

	const [aprovado, setAprovado] = useState(false);
	const [img, setImg] = useState<any>("");
	const [redirect, setRedirect] = useState(false);
	const [aviso, setAviso] = useState(false);
	const [fotoObrigatoria, setFotoObrigatoria] = useState(false)

	const onSubmit = (data: any) => {
		if(img) {
			setAprovado(true);
			const dat_request = {
				CERTIFICACAO_ASSINATURA_BASE64: img,
				CERTIFICACAO_CONSELHO: data.conselho,
				CERTIFICACAO_CONSELHO_NUMERO: data.nConselho,
			};
			AmplitudeLog('edit_assinatura_salva', {
				assinatura: dat_request
			})
			dispatch(AssinaturaActions.edit_assinatura(dat_request));
		} else {
			setFotoObrigatoria(true)
		}
	};
	//console.log(errors);

	function getBase64(event: any) {
		const file = event.target.files[0];
		if (file.size < 10485760) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				console.log(reader.result, "reader.result");
				setImg(reader.result);

				dispatch(AssinaturaActions.set_assinatura(reader.result));
			};
			reader.onerror = function (error) {
				//console.log("Error: ", error);
			};
		} else {
			setAviso(true);
			setTimeout(() => {
				setAviso(false);
			}, 2000);
		}
	}

	function removerFoto() {
		setImg(false);
		dispatch(AssinaturaActions.set_assinatura(" "));
	}

	useEffect(() => {
		if (is_open_toast === true) {
			setAprovado(false);

			setTimeout(() => {
				setRedirect(true);
			}, 2300);
		}
	}, [is_open_toast]);

	return (
		<Conteiner>
			{is_open_toast && (
				<>
					<ToastAlert />
					{redirect && <Redirect to="/perfil" />};
				</>
			)}
			<BodyEdite onSubmit={handleSubmit(onSubmit)}>
				<div className="coluna">
					<h3>{current_language?.adicionar_assinatura}</h3>
				</div>

				<div className="coluna">
					<div className="globalInput">
						<div className="iconIput">
							<img src={user} alt="key" className="icon" />
						</div>
						<div className="condicionamento"></div>
						<select
							id="conselho"
							className="input select2"
							defaultValue=""
							{...register("conselho", {
								required: `${current_language?.login_input_senha}`,
							})}
						>
							<option className="option" value="" selected disabled>
								{current_language?.conselho}
							</option>
							<option className="option" value="3">
								CRM
							</option>
							<option
								className="option"
								value="2"
								selected={
									user_logado.USUARIO_CONDICIONAMENTO === "2" ? true : false
								}
							>
								CREF
							</option>
							<option
								className="option"
								value="1"
								selected={
									user_logado.USUARIO_CONDICIONAMENTO === "1" ? true : false
								}
							>
								CREFITO
							</option>
						</select>
					</div>
					<div className="divhr">
						<hr className={errors.conselho ? "hr hrcor" : "hr"} />
						{errors.conselho && (
							<div className="error">{errors.conselho.message}</div>
						)}
					</div>
				</div>

				<div className="coluna">
					<div className="globalInput">
						<div className="iconIput">
							<img src={user} alt="key" className="icon" />
						</div>
						<input
							type={"text"}
							id="nConselho"
							placeholder={current_language?.n_conselho}
							className="input"
							maxLength={10}
							{...register("nConselho", {
								required: `${current_language?.login_input_senha}`,
								minLength: {
									value: 4,
									message: current_language?.min_4_characters,
								},
							})}
						/>
					</div>
					<div className="divhr">
						<hr className={errors.nConselho ? "hr hrcor" : "hr"} />
						{errors.nConselho && (
							<div className="error">{errors.nConselho.message}</div>
						)}
					</div>
				</div>

				<div className="coluna">
					<div
						className="globalInput"
						style={{
							justifyContent: "flex-start",
						}}
					>
						<div className="iconIput">
							<svg
								className="icon"
								width="45pt"
								height="40pt"
								version="1.1"
								viewBox="0 0 752 752"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g>
									<path
										fill="#8c8e86"
										d="m284.12 337.64 53.043-12.312v132.13c0 7.1055 5.6836 12.785 12.785 12.785h52.566c7.1055 0 12.785-5.6836 12.785-12.785v-132.13l53.043 12.312c15.629 3.7891 27.469-13.262 18.941-26.992l-95.664-151.55c-1.8945-3.3164-4.7344-5.6836-8.0508-7.1055-8.5234-3.3164-17.523-0.47266-21.785 6.6289l-96.137 151.55c-8.9922 14.211 2.8477 31.258 18.473 27.469z"
									/>
									<path
										fill="#8c8e86"
										d="m553.59 522.81h-355.19c-7.1055 0-12.785 5.6836-12.785 12.785v52.094c0 7.1055 5.6836 12.785 12.785 12.785h355.19c7.1055 0 12.785-5.6836 12.785-12.785v-52.094c0.003906-7.1016-5.6797-12.785-12.785-12.785z"
									/>
								</g>
							</svg>
						</div>
						<span> {current_language?.envie_uma_foto_ass}</span>
					</div>
					<div className="caixaCinza">
						<label
							htmlFor="img"
							className="button"
							// onClick={ModalCadastroClose}
						>
							<input
								id="img"
								type="file"
								onChange={getBase64}
								style={{ display: "none" }}
								accept="image/*"
							/>
							{img ? (
								<div className="img-background">
									<img src={img} alt="key" className="bgAssinatura-image" />
								</div>
							) : (
								<div className="bgAssinatura-image2">
									<span>{current_language?.enviar_assinatura}</span>
								</div>
							)}
						</label>
						{img && (
							<svg
								className="excluir"
								onClick={() => removerFoto()}
								viewBox="0 0 14 13"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="30.2563" cy="30.31099" r="3.56392" fill="#FCFCFC" />
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7.02461 0.123047C3.60678 0.123047 0.835938 2.89388 0.835938 6.31172C0.835938 9.72956 3.60678 12.5004 7.02461 12.5004C10.4424 12.5004 13.2133 9.72956 13.2133 6.31172C13.2133 2.89388 10.4424 0.123047 7.02461 0.123047ZM9.11019 5.02166C9.21268 4.91555 9.26939 4.77344 9.2681 4.62593C9.26682 4.47841 9.20765 4.3373 9.10334 4.23299C8.99903 4.12868 8.85792 4.06951 8.71041 4.06823C8.56289 4.06695 8.42078 4.12365 8.31467 4.22614L7.02461 5.51619L5.73455 4.22614C5.68266 4.1724 5.62057 4.12954 5.55193 4.10006C5.48329 4.07057 5.40947 4.05505 5.33477 4.0544C5.26006 4.05375 5.18598 4.06799 5.11684 4.09628C5.0477 4.12456 4.98488 4.16634 4.93205 4.21916C4.87923 4.27199 4.83745 4.3348 4.80917 4.40395C4.78088 4.47309 4.76664 4.54717 4.76729 4.62188C4.76794 4.69658 4.78346 4.7704 4.81295 4.83904C4.84243 4.90768 4.88529 4.96976 4.93903 5.02166L6.22909 6.31172L4.93903 7.60178C4.88529 7.65368 4.84243 7.71576 4.81295 7.7844C4.78346 7.85304 4.76794 7.92686 4.76729 8.00157C4.76664 8.07627 4.78088 8.15035 4.80917 8.21949C4.83745 8.28864 4.87923 8.35145 4.93205 8.40428C4.98488 8.4571 5.0477 8.49888 5.11684 8.52717C5.18598 8.55545 5.26006 8.56969 5.33477 8.56904C5.40947 8.56839 5.48329 8.55287 5.55193 8.52339C5.62057 8.4939 5.68266 8.45104 5.73455 8.3973L7.02461 7.10725L8.31467 8.3973C8.42078 8.49979 8.56289 8.55649 8.71041 8.55521C8.85792 8.55393 8.99903 8.49476 9.10334 8.39045C9.20765 8.28614 9.26682 8.14503 9.2681 7.99752C9.26939 7.85 9.21268 7.70789 9.11019 7.60178L7.82014 6.31172L9.11019 5.02166Z"
									fill="#DB0000"
								/>
							</svg>
						)}
					</div>
					<div className="divhr">
						<hr className={errors.novaSenha ? "hr hrcor" : "hr"} />
						{errors.novaSenha && (
							<div className="error">{errors.novaSenha.message}</div>
						)}
					</div>
					{aviso && <Aviso>*{current_language?.maior_que_10mb}</Aviso>}
					{fotoObrigatoria && <CampoObrigatorio>{current_language?.login_input_senha}</CampoObrigatorio>}
				</div>

				<div className="inf">
					<button 
						type="submit" id="salvar" 
						className="salve"
					>
						{aprovado === true ? (
							<CircularProgress color="inherit" />
						) : (
							current_language?.pagamento_salvar
						)}
					</button>
				</div>
			</BodyEdite>
		</Conteiner>
	);
};

export default Assinatura;
