export const TypesErrorModal = {
	FAIL: "FAIL",
	SUCCESS: "SUCCESS",
};

export const TypesLanguage = {
	PT: "PT",
	EN: "EN",
	ES: "ES",
};

export const TypesErrorRequest = {
	FAIL: "fail",
	SUCCESS: "success",
};

export const TypesModalAviso = {
	NONE: "none",
	SUCCESS: "success",
	INFO: "info",
	WARNING: "warning",
	INTERROGA: "INTERROGA",
};

export const TypesNotifications = {
	FORGOT: "senha",
};

export const TypesDivision = {
	DEB: 0,
	S: 1,
};

export const TypesMedida = {
	KG: "KG",
	KGF: "KGF",
	N: "N",
};

export const TypesPacAval = {
	PACIENTE: "Pacientes",
	AVALIADORES: "Avaliadores",
};

export const TypesAvalOrFunci = {
	FUNCIONAL: "FUNCIONAL",
	AVALIACAO: "AVALIACAO",
};

export const TypesProLivre = {
	PROTOCOLO: "PROTOCOLO",
	LIVRE: "LIVRE",
};

export const TypesRelatorio = {
	ASSIMETRIA: "ASSIMETRIA",
	FORCA_TEMPO: "FORCA_TEMPO",
	EVOLUTION: "EVOLUTION",
};

export const TypesRelatorioCompartilhar = {
	ASSIMETRIA: "assimetria",
	FORCA_TEMPO: "forca_tempo",
	EVOLUTION: "evolucao",
};

export const TypesPeriodo = {
	HOJE: "HOJE",
	TODO: "TODO",
	SEMANA: "SEMANA",
	MES: "MES",
};

export const TypesLados = {
	DIR: "DIR",
	ESQ: "ESQ",
};

export const TypesSuperiorFrontal = {
	OMBRO: "OMBRO",
	PEITO: "PEITO",
	BICEPS: "BICEPS",
	ABDOMEN: "ABDOMEN",
	LATERAL_ABDOMEN: "LATERAL_ABDOMEN",
	MAO: "MAO",
	PESCOCO: "PESCOCO",
	ANTEBRACO: "ANTEBRACO_ANTERIOR",
};

export const TypesSuperiorDorsal = {
	OMBRO_DORSAL: "OMBRO_DORSAL",
	TRAPEZIO: "TRAPEZIO",
	DELTOIDE: "DELTOIDE",
	DORSAL_TRAPEZIO: "DORSAL_TRAPEZIO",
	TRICEPS: "TRICEPS",
	ANTEBRACO: "ANTEBRACO_POSTERIOR",
};

export const TypesSInferiorFrontal = {
	ILIOPSOAS: "ILIOPSOAS",
	CANELA: "CANELA",
	COXA: "COXA",
	COXA_INTERNA: "COXA_INTERNA",
	QUADRIL: "QUADRIL",
	PE: "PE",
};

export const TypesApi = {
	DEV: "ale",
	PROD: "api6",
};

export const TypesSInferiorDORSAL = {
	COXA_TRAZ: "COXA_TRAZ",
	GLUTEO: "GLUTEO",
	PANTURRILHA: "PANTURRILHA",
};

export const TypesCores = {
	POSITIVO: "POSITIVO",
	NEGATIVO: "NEGATIVO",
	NEUTRO: "NEUTRO",
	INTERMEDIARIO: "INTERMEDIARIO",
};

export const TypeAgrupamentoLado = {
	SUPERIOR_FRONTAL: "SUPERIOR_FRONTAL",
	SUPERIOR_DORSAL: "SUPERIOR_DORSAL",
	INFERIOR_FRONTAL: "INFERIOR_FRONTAL",
	INFERIOR_DORSAL: "INFERIOR_DORSAL",
};

export const EXECUCAO_TIPO = {
	FUNCIONAL: "FUNCIONAL",
	AVALIACAO: "AVALIACAO",
};

export const TypesPermission = {
	SERVICO_REL_ASSIMETRIA: "SERVICO_REL_ASSIMETRIA",
	SERVICO_REL_EVOLUCAO: "SERVICO_REL_AVALIACAO",
	SERVICO_REL_FORCA_TEMPO: "SERVICO_REL_FORCA",
	SERVICO_LOGOMARCA: "SERVICO_LOGOMARCA",
	SERVICO_PERCENTUAL_RM: "SERVICO_PERCENTUALRM",
	SERVICO_AVALIACAO_IQ: "SERVICO_AVALIACAO_IQ",
	SERVICO_WEB: "SERVICO_ WEB",
	SERVICO_CSV: "SERVICO_CSV",
	SERVICO_ANOTACOES: "SERVICO_ANOTACOES",
	SERVICO_RECYCLE: "SERVICO_RECYCLE",
	SERVICO_VALOR_REFERENCIA: "SERVICO_VALOR_REFERENCIA",
	SERVICO_COMPARTILHAMENTO_EXTERNO: "SERVICO_COMPARTILHAMENTO_EXTERNO",
	SERVICO_EXERCICIOS_PERSONALIZADOS: "SERVICO_EXERCICIOS_PERSONALIZADOS",
	SERVICO_ANAMNESE: "SERVICO_ANAMNESE",
	SERVICO_REL_MULTIFATORIAL: "SERVICO_REL_MULTIFATORIAL",
	SERVICO_REL_FISIOTERAPEUTICO: "SERVICO_REL_FISIOTERAPEUTICO",
	SERVICO_ANAMNESE_ASSINCRONA: "SERVICO_ANAMNESE_ASSINCRONA",
	SERVICO_ANAMNESE_FISICA: "SERVICO_ANAMNESE_FISICA",
	SERVICO_QUALIDADE_VIDA_ASSINCRONO: "SERVICO_QUALIDADE_VIDA_ASSINCRONO",
	SERVICO_QUALIDADE_VIDA: "SERVICO_QUALIDADE_VIDA",
	SERVICO_PACIENTES: "SERVICO_PACIENTES",
	SERVICO_AVALIADORES: "SERVICO_AVALIADORES",
	SERVICO_PROTOCOLOS_PERSONALIZADOS: "SERVICO_PROTOCOLOS_PERSONALIZADOS",
	SERVICO_ASSINATURA_RELATORIO: "SERVICO_ASSINATURA_RELATORIO",
	SERVICO_MOBILE_AVALIACAO: "SERVICO_MOBILE_AVALIACAO",
	SERVICO_WEB_AVALIACAO: "SERVICO_WEB_AVALIACAO",
};

export const TypesBtnInfo = {
	BTN_FICHA_TECNICA: "list_alt",
	BTN_SAUDE: "saude",
	BTN_ESPORTIVA: "esportiva",
	BTN_ESTETICA: "estetica",
	BTN_REABILITACAO: "reabilitacao",
	BTN_RELATORIO: "description",
	BTN_ANAMNESE: "list_tab",
	BTN_SF: "heart_put",
	BTN_ANOTACOES: "edit_info",
	BTN_AVALIADOR: "person",
	BTN_NEW_ANAMNESE: "new_anamnese",
	BTN_CONTINUE_ANAMNESE: "continue_anamnese",
	BTN_AVALIACAO_FÍSICA: "avaliacao_fisica",
	BTN_NEW_SF: "new_sf",
	BTN_CONTINUE_SF: "continue_sf",
	BTN_QUALIDADE_DE_VIDA: "qualidade_vida",
};

export const TypesMenuInfoProps = {
	BTN_ANAMNESE_TYPE: "anamnese_type",
	BTN_ANAMNESE: "anamnese",
	BTN_FICHA_TECNICA: "ficha_tecnica",
	BTN_RELATORIO: "relatorio",
	BTN_SF: "sf",
	BTN_ANOTACOES: "anotacoes",
	BTN_AVALIADOR: "avaliador",
	BTN_MENU: "menu",
	BTN_NEW_ANAMNESE: "new_anamnese",
	BTN_CONTINUE_ANAMNESE: "continue_anamnese",
	BTN_AVALIACAO_FÍSICA: "avaliacao_fisica",
	BTN_NEW_SF: "new_sf",
	BTN_QUALIDADE_VIDA: "qualidade_vida",
};

export const TypesBtnRelatorio = {
	BTN_EVOLUCAO: "Evolução",
	BTN_FORCA_TEMPO: "Força x Tempo",
	BTN_ASSIMETRIA: "Assimetria",
	BTN_MULTIFATORIAL: "Multifatorial",
	BTN_FISIOTERAPEUTICO: "Fisioterapêutico",
};

export const TypesDataAnamnese = {
	MULTIFATORIAL: "multifatorial",
	FISIOTERAPEUTICO: "fisioterapeutico",
	AVALIACAO: "avalicao",
	QUALIDADE_VIDA: "qualidade_vida",
};

export const TypesQuestionnaire = {
	ANAMNESE: "ANAMNESE",
	ANAMNESE_FISICA: "ANAMNESE_FISICA",
	ANAMNESE_TIPO: "ANAMNESE_TIPO",
	SF_36: "SF_36",
	ANAMNESE_AVALIACAO: "ANAMNESE_AVALIACAO",
	MENU: "MENU",
	ANAMNESE_V7: "ANAMNESE_V7",
	ANAMNESE_CLINICO_V7: "ANAMNESE_CLINICO_V7"
};

export const TypesQuestionnaireAnamneseTipo = {
	SAUDE: "SAUDE",
	REABILITACAO: "REABILITACAO",
	ESTETICO: "ESTETICO",
	ESPORTIVO: "ESPORTIVO",
};

export const TypesQuestionnaireTipos = {
	ANAMNESE_TIPO_SAUDE: "ANAMNESE_TIPO_SAUDE",
	ANAMNESE_TIPO_REABILITACAO: "ANAMNESE_TIPO_REABILITACAO",
	ANAMNESE_TIPO_ESTETICO: "ANAMNESE_TIPO_ESTETICO",
	ANAMNESE_TIPO_ESPORTIVO: "ANAMNESE_TIPO_ESPORTIVO ",
	ANAMNESE_AVALIACAO_SAUDE: "ANAMNESE_AVALIACAO_SAUDE",
	ANAMNESE_AVALIACAO_REABILITACAO: "ANAMNESE_AVALIACAO_REABILITACAO",
	ANAMNESE_AVALIACAO_ESPORTIVO: "ANAMNESE_AVALIACAO_ESPORTIVO",
	ANAMNESE_AVALIACAO_ESTETICO: "ANAMNESE_AVALIACAO_ESTETICO",
};

export const Questionario_pergunta_tipo = {
	radio_button: "radio-button",
	input_text: "input-text",
	checkbox: "checkbox",
	input_select: "input-select",
	input_checkbox: "input-checkbox",
	input_text_disable: "input-text-disable",
	input_text_area: "input-text-area",
	button_nota_adicional: "button-nota-adicional",
	button_upload_arquivo: "button-upload-arquivo",
	input_upload_arquivo: "input-upload-arquivo",
	label: "label",
	input_corpo: "input-corpo",
	fluxo: "fluxo",
	input_termografia_aplicada: "input-termografia-aplicada",
	input_aparencia_como_se_ve: "input-aparencia-como-se-ve",
	input_aparencia_deseja_ser: "input-aparencia-deseja-ser",
};

export const QuestionnaireComplementProps = {
	QUESTIONARIO_ORDEM: "QUESTIONARIO_ORDEM",
	QUESTIONARIO_LABEL: "QUESTIONARIO_LABEL",
	QUESTIONARIO_TITULO: "QUESTIONARIO_TITULO",
	QUESTIONARIO_DESCRICAO: "QUESTIONARIO_DESCRICAO",
	QUESTIONARIO_PERGUNTA: "QUESTIONARIO_PERGUNTA",
	QUESTIONARIO_PERGUNTA_TIPO: Questionario_pergunta_tipo,
	QUESTIONARIO_PERGUNTA_OPCOES: "QUESTIONARIO_PERGUNTA_OPCOES",
	QUESTIONARIO_PERGUNTA_CONDICAO: "QUESTIONARIO_PERGUNTA_CONDICAO",
	QUESTIONARIO_PERGUNTA_LINK: "QUESTIONARIO_PERGUNTA_LINK",
};

export const TypeQuestionnaireProps = {
	QUESTIONARIO_ORDEM: "QUESTIONARIO_ORDEM",
	QUESTIONARIO_LABEL: "QUESTIONARIO_LABEL",
	QUESTIONARIO_TITULO: "QUESTIONARIO_TITULO",
	QUESTIONARIO_DESCRICAO: "QUESTIONARIO_DESCRICAO",
	QUESTIONARIO_PERGUNTA: "QUESTIONARIO_PERGUNTA",
	QUESTIONARIO_PERGUNTA_TIPO: Questionario_pergunta_tipo,
	QUESTIONARIO_PERGUNTA_OPCOES: "QUESTIONARIO_PERGUNTA_OPCOES",
	QUESTIONARIO_PERGUNTA_CONDICAO: "QUESTIONARIO_PERGUNTA_CONDICAO",
	QUESTIONARIO_PERGUNTA_LINK: "QUESTIONARIO_PERGUNTA_LINK",
	QUESTIONARIO_PERGUNTA_COMPLEMENTO: QuestionnaireComplementProps,
};

export const TypeQuestionnaireModoProps = {
	questionario: "questionario",
	telas_final: "telas_final",
	telas_fluxo: "telas_fluxo",
	telas_inicio: "telas_inicio",
};

export const TypeButtonsQuestionnaire = {
	BT_FINALIZAR_SF_36: "BT_FINALIZAR_SF_36",
	BT_FINALIZAR_ANAMNESE_TIPO: "BT_FINALIZAR_ANAMNESE_TIPO",
	BT_FINALIZAR_ANAMNESE_AVALIACAO: "BT_FINALIZAR_ANAMNESE_AVALIACAO",
	BT_VOLTAR_ANAMNESE_AVALIACAO: "BT_VOLTAR_ANAMNESE_AVALIACAO",
	BT_VOLTAR_ANAMNESE_TIPO: "BT_VOLTAR_ANAMNESE_TIPO",
	BT_VOLTAR_SF_36: "BT_VOLTAR_SF_36",

	BT_FINALIZAR_ANAMNESE_V7: "BT_FINALIZAR_ANAMNESE_V7",
	BT_VOLTAR_ANAMNESE_V7: "BT_VOLTAR_ANAMNESE_V7",

	BT_FINALIZAR_ANAMNESE_CLINICO_V7: "BT_FINALIZAR_ANAMNESE_CLINICO_V7",
	BT_VOLTAR_ANAMNESE_CLINICO_V7: "BT_VOLTAR_ANAMNESE_CLINICO_V7"
};

export const TypeErrors = {
	BAD_REQUEST: "Bad request",
	UNAUTHORIZED: "Unauthorized",
	NOT_FOUND: "Not found",
};

export const TypePacotes = {
	ANAMNESE: "ANAMNESE",
};

export type TypeCategoriasAnamnese =
	| "SAUDE"
	| "PADRAO"
	| "REABILITACAO"
	| "ESTETICO"
	| "ESPORTIVO"
	| "ANAMNESE_V7"
	| "SF_36"

export type TypeTypeAnamnese =
	| "ANAMNESE_TIPO"
	| "ANAMNESE_AVALIACAO"
	| "SF_36"
	| "ANAMNESE_V7"
	| "ANAMNESE_CLINICO_V7";
