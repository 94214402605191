import React, { memo, useEffect, useState } from "react";
import { Spinner } from "react-activity";
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import background from "../../../assets/kinologyIcons/png/BackgrounLogin.png";
import Logo from '../../../assets/kinologyIcons/svg/LogoLogin.svg';
import Idioma from "../../../assets/kinologyIcons/svg/idioma.svg";
import Ajuda from "../../../assets/kinologyIcons/svg/ajuda.svg";
import email from "../../../assets/icons/email.svg";
import lockPassword from "../../../assets/icons/lockPassword.svg";
import logoNegativo from "../../../assets/logo_negativo.png";
import { TextsProps } from "../../../database/txt.interface";
import { AuthActions } from "../../../store/ducks/auth";
import { i18n } from "../../../translate/i18n";
import { TypesNotifications } from "../../../utils/types";
import { Conteiner, Content } from "./styles";
import { Background } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import Text from "../../../components/Texts/Text";
import { Colors } from "../../../styles/colors";
import Form from "../../../components/Form";
import InputTextEntryForm from '../../../components/Inputs/inputForm';
import FormSubmiteButton from "../../../components/Buttons/FormSubmitButton";
import history from "../../../routes/history";
import { AppActions } from "../../../store/ducks/app";

const RecuperarSenha: React.FC = () => {
	
	const dispatch = useDispatch();
	const methods = useForm()

	const [ enable, setEnable]= useState('')
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const loading_notification = useSelector(
		(state: any) => state.auth.loading_notification
	);
	useEffect(()=> {
		setEnable(methods.watch('email'))
	},[methods.watch('email')])

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = (data: FieldValues) => {
		dispatch(
			AuthActions.notification_request(TypesNotifications.FORGOT, data.email)
		);
	};

	const handleOpenLinguagem = () => {
		dispatch(AppActions.set_modal_linguagem(true));
	};
	return (
		<Conteiner>
			<Content>
				<img src={Logo} alt="logo" className="logo" />
				<div className="containerTitles">
					<Text
						title={current_language?.login_esqueci_senha_titulo}
						fontFamily="NotoSans-Bold"
						textAlign="left"
						fontSize={2}
						textColor={Colors.primary_blue_k_main}
					/>
					<Text
						title={current_language?.subtitulo_recuperar_senha}
						fontFamily="NotoSans-Regular"
						textAlign="left"
						fontSize={1.5}
						marginTop={15}
						textColor={Colors.primary_blue_k_main}
					/>
				</div>

				<Form methods={methods} onSubmit={(data)=> onSubmit(data)}>
					<InputTextEntryForm
						type="text"
						typeInputName="email"
						title="E-mail*"
						marginTop={41}
						fontFamily="NotoSans-SemiBold"
						leftIcon="EmailIcon"
						placeholder={current_language?.placeholder_digite}

					/>
					<FormSubmiteButton
						type="submit"
						onClick={()=> methods.trigger()}
						disabled={enable == '' ? true : false}
						marginTop={100}
						fontFamily="Kanit-Medium"
						typeButtonComponent="primary"
						activityIndicator={loading_notification}
						title={current_language?.btn_enviar}
					/>
					<Text
						title={current_language?.voltar}
						fontFamily="Kanit-Regular"
						marginTop={36}
						fontSize={1.37}
						cursor="pointer"
						textColor={Colors.primary_blue_k_main}
						onClick={()=>{ history.goBack()}}
					/>
				</Form>
				<div className="global" style={{width: '20%'}}>
					<button
						type="button"
						value="Idioma"
						onClick={handleOpenLinguagem}
						className="inputIA idiomaAjuda"
					>
						<img src={Idioma} alt="logo" className="imgIA" />
						<p className="TextIcon">
							{current_language?.login_idioma}
						</p>
					</button>
					<button
						type="button"
						value="Ajuda"
						onClick={()=> 	window.open("https://base.materials2.kinology.com.br/perguntas-frequentes", "_blank")}
						className="inputIA idiomaAjuda"
					>
						<img src={Ajuda} alt="logo" className="imgIA" />
						<p className="TextIcon">
							{current_language?.login_ajuda}
						</p>
					</button>
				</div>
			</Content>
			<Background>
				<img src={background} alt=""  height={'100%'} width={'100%'}/>
			</Background>
		</Conteiner>
	);
};

export default RecuperarSenha;
