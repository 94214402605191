import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { TextsProps } from "../../database/txt.interface";
import { UserProps } from "../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "../../utils/types";
import { AppActions } from "../ducks/app";
import { CentralAjuda, Types } from "../ducks/central";
import * as Sentry from "@sentry/react";

function* enviar({ payload }: any): any {
	const current_language: TextsProps = yield select(
		(state) => state.configs.current_language
	);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	async function apiCall() {
		try {
			const dat = {
				...payload,
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}ajuda/ajuda_adicionar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.FAIL) {
			if (response.code == -1) {
				message = current_language?.cadastro_falhou;
			}

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			if (response.code == 0) {
				message = current_language?.problema_sugestao_salvo;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
		Sentry.captureMessage("CAIU NO CATCH: ajuda_adicionar.php", "error");
	} finally {
		yield put(CentralAjuda.central_ajuda_enviar_finally());
	}
}

export function* CentralSaga(): any {
	return yield all([takeLatest(Types.CENTRAL_AJUDA_ENVIAR, enviar)]);
}
