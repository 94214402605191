import React, { useState } from "react";
import { Conteiner, Content, FlagLang } from "./stylesRenderSetLang";
import FlagBr from "../../../assets/icons/flagBr.svg";
import FlagUs from "../../../assets/icons/flagUs.svg";
import FlagEs from "../../../assets/icons/flagEs.svg";
import { TypesLanguage } from "../../../utils/types";
import { ConfigsActions } from "../../../store/ducks/configs";
import { useDispatch, useSelector } from "react-redux";

interface DataLanguageProps {
	id: number;
	name: string;
	flag: any;
	lang: string;
}

const dataLanguage: Array<DataLanguageProps> = [
	{
		id: 1,
		name: "Português",
		flag: FlagBr,
		lang: "pt",
	},
	{
		id: 2,
		name: "Inglês",
		flag: FlagUs,
		lang: "en",
	},
	{
		id: 3,
		name: "Espanhol",
		flag: FlagEs,
		lang: "es",
	},
];

const SetLang: any = () => {
	const dispatch = useDispatch();
	const languageRedux = useSelector((state: any) => state.configs.language);
	const [language, setLanguage] = useState<DataLanguageProps>(
		dataLanguage.filter(
			(item: DataLanguageProps) => item.lang === languageRedux.toLowerCase()
		)[0] || dataLanguage[0]
	);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	function saveLang(lang: DataLanguageProps) {
		setLanguage(lang);
		if (lang.lang === "pt") {
			dispatch(ConfigsActions.set_language(TypesLanguage.PT));
		} else if (lang.lang === "en") {
			dispatch(ConfigsActions.set_language(TypesLanguage.EN));
		} else {
			dispatch(ConfigsActions.set_language(TypesLanguage.ES));
		}
		setIsOpen(false);
	}

	const FlagLangRender: any = () => {
		return dataLanguage.map((item: any) => {
			return (
				<FlagLang
					key={item?.id}
					src={item?.flag}
					alt={item?.name}
					style={{
						marginLeft: "5px",
						marginRight: "5px",
					}}
					onClick={() => {
						saveLang(item);
					}}
				/>
			);
		});
	};

	return (
		<Conteiner>
			<Content>
				{isOpen ? (
					<FlagLangRender />
				) : (
					<FlagLang src={language?.flag} onClick={() => setIsOpen(true)} />
				)}
			</Content>
		</Conteiner>
	);
};

export default SetLang;

