import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import add_pac_aval from "./add_pac_aval";
import app from "./app";
import auth from "./auth";
import avaliation from "./avaliation";
import central from "./central";
import configs from "./configs";
import dashboard from "./dashboard";
import exercicios from "./exer";
import financial from "./financial";
import pac_aval from "./manage_pac_aval";
import manage_protocol from "./manage_protocols";
import register from "./register";
import relatorios from "./relatorios";
import tags from "./tags";
import assinatura from "./assinatura";
import atribuir from "./atribuir";
import anotations from "./anotacoes";
import anamnese from "./anamnese";

const createRootReducer = (history: any) =>
	combineReducers({
		router: connectRouter(history),
		app,
		auth,
		configs,
		pac_aval,
		dashboard,
		assinatura,
		register,
		financial,
		add_pac_aval,
		manage_protocol,
		exercicios,
		avaliation,
		relatorios,
		tags,
		central,
		atribuir,
		anotations,
		anamnese
	});

export default createRootReducer;
