import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding-bottom: 3.6rem;
	padding-left: 3.6rem;
	padding-right: 2rem;
	overflow-y:auto;
	overflow-x: hidden;
`;
export const Content2 = styled.div`
	display: flex;
	width: 50%;
	flex-direction: column;
`;
export const ContainerGoBack = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-top: 20px;
`;
export const Header = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`;
export const ContainerOptions = styled.div<{user: number, index: number}>`
	display: flex;
	width: 100%;
	flex-direction: column;
	position: relative;
	margin-top: ${({index})=> index == 7 ? '20px' : '15px' };
	border-top: ${({user, index})=>  user == 5601 && index == 7 ? '1px solid #DEDFE3'  : '0px' }
	
`;

export const ContainerIcon = styled.div<{focusOptionIcon: number | null, HoverIndex: number}>`
	display: flex;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	align-items: center;
	justify-content: center;
	background-color: ${({focusOptionIcon, HoverIndex})=> focusOptionIcon == HoverIndex ? Colors?.primary_blue_k_main : 'transparent'};
`;
export const ContainerArrowIcon = styled(ContainerIcon)`
	width: 30px;
	height: 30px;
	background-color: ${({focusOptionIcon, HoverIndex})=> focusOptionIcon == HoverIndex ? Colors?.primary_blue_k_main : 'transparent'};
`;
export const Option = styled.div<{focusOptionHover: number | null, HoverIndex: number,}>`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding-right: 16px;
	padding-left: 6px;
	margin-top: ${({HoverIndex})=> HoverIndex == 7 ? '25px' : '0px' };

	&:hover {
		background-color: ${({focusOptionHover, HoverIndex})=> focusOptionHover == HoverIndex ? Colors?.neutral_grey_k_10 : 'transparent'};
		border-radius: 16px;
	}
`;
export const ContainerIconName = styled.div`	
	display: flex;
	width: 80%;
	align-items: center;
`;



export const BodyEdite = styled.div`
	height: 80%;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
	.lineItens {
		display: flex;
		flex-direction: column;

		padding-right: 1.6rem;
	}

	.left {
		display: flex;
	}

	.item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #e0e0e0;
		justify-content: space-between;
		text-decoration: none;
		height: 6rem;
		cursor: pointer;
		p {
			margin-left: 1.3rem;

			font-size: 1rem;
			font-weight: 500;

			color: #5c5b5b;
		}

		svg {
			cursor: pointer;
			margin-left: 1rem;
		}
	}

	.item2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-decoration: none;
		height: 6rem;
		cursor: pointer;
		p {
			cursor: pointer;
			margin-left: 1.3rem;

			font-size: 1rem;
			font-weight: 500;

			color: #5c5b5b;
		}

		svg {
			cursor: pointer;
			margin-left: 1rem;
		}
	}
`;

export const Content = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.labelForInput {
		display: flex;
		flex-direction: column;
		cursor: pointer;
	}

	.conteinerImg {
		background-color: #e5e5e5;
		width: 5.3rem;
		height: 5.3rem;
		border-radius: 50%;
		padding: 4.5px;
	}

	.imgForInput {
		width: 4.8rem;
		height: 4.8rem;
		border-radius: 50%;
		object-fit: cover;
	}

	.camera {
		display: flex;
		margin-left: 75px;
		width: 45px;
		height: 45px;
		margin-top: -40px;
	}
`;
