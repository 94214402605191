export const Types = {
	AUTH_REQUEST: "AUTH_REQUEST",
	CANCEL_REQUEST: "CANCEL_REQUEST",
	AUTH_REQUEST_SUCCESS: "AUTH_REQUEST_SUCCESS",

	AUTH_LOGOUT: "AUTH_LOGOUT",

	NOTIFICATION_REQUEST: "NOTIFICATION_REQUEST",
	CANCEL_NOTIFICATION_REQUEST: "CANCEL_NOTIFICATION_REQUEST",
	EDIT_PROFILE_USER:'EDIT_PROFILE_USER',
};

const INITIAL_STATE = {
	user: null,
	loading_auth: false,
	loading_notification: false,
	data_user: []
};

export default function auth(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.AUTH_REQUEST:
			return {
				...state,
				loading_auth: true,
			};
		case Types.AUTH_REQUEST_SUCCESS:
			return {
				...state,
				user: action.payload,
			};
		case Types.CANCEL_REQUEST:
			return {
				...state,
				loading_auth: false,
			};
		case Types.NOTIFICATION_REQUEST:
			return {
				...state,
				loading_notification: true,
			};
		case Types.CANCEL_NOTIFICATION_REQUEST:
			return {
				...state,
				loading_notification: false,
			};
		case Types.EDIT_PROFILE_USER:
			return {
				...state,
				data_user: action.payload
			}
		default:
			return state;
	}
}

export const AuthActions = {
	auth_request: (data: any, isRequest = false) => ({
		type: Types.AUTH_REQUEST,
		payload: { payload: data, isRequest },
	}),
	edit_user_profile: (data: any)=> ({
		type: Types.EDIT_PROFILE_USER,
		payload: data,
	}),
	auth_success: (user: any) => ({
		type: Types.AUTH_REQUEST_SUCCESS,
		payload: user,
	}),
	cancel_request: () => ({
		type: Types.CANCEL_REQUEST,
	}),
	auth_logout: () => ({
		type: Types.AUTH_LOGOUT,
	}),

	notification_request: (type: string, email: string) => ({
		type: Types.NOTIFICATION_REQUEST,
		payload: { type, email },
	}),
	cancel_request_notification: () => ({
		type: Types.CANCEL_NOTIFICATION_REQUEST,
	}),
};
