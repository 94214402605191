import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const DropDownContent = styled.div`
	padding: 0.43rem;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	z-index: 101;
	.exerciceEnumber {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.exerciceName {
			font-family: Roboto;
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			line-height: 14px;
			display: flex;
			align-items: center;
			color: #5c5b5b;
		}

		section {
			width: 1.3rem;
			height: 1.3rem;
			background-color: #4d4d4d;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: Roboto;
			font-style: normal;
			font-weight: bold;
			font-size: 10px;
			line-height: 13px;
		}
	}

	.date {
		font-family: Roboto;
		font-style: normal;
		font-weight: 300;
		font-size: 10px;
		line-height: 12px;
		color: #5c5b5b;
		margin-bottom: 0.75rem;
		margin-left: 70%;
	}

	.dateRight {
		font-family: Roboto;
		font-style: normal;
		font-weight: 300;
		font-size: 10px;
		line-height: 12px;
		color: #5c5b5b;
		margin-bottom: 0.75rem;
	}

	.grafic {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 14px;
		color: ${Colors.tertirary_red_1_dark};
		width: 100%;

		.barras {
			width: 100%;

			.barra1 {
				display: flex;
				flex-direction: row;
				width: 100%;
				height: 0.9rem;
				margin-bottom: 5px;

				.divLado {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					background-color: #ebebeb;
					width: 15px !important;
					padding: 5px;

					.lado {
						color: #8d8d8d;
					}
				}
				.barra {
					width: 100%;
					height: 0.9rem;
					background-color: red;
				}
			}

			.barra2 {
				display: flex;
				flex-direction: row;
				width: 100%;
				height: 0.919rem;

				.divLado {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					background-color: #ebebeb;
					width: 15px !important;
					padding: 5px;

					.lado {
						color: #8d8d8d;
					}
				}
				.barra {
					width: 100%;
					height: 0.9rem;
					background-color: red;
				}
			}
		}

		.divValues {
			display: flex;
			width: 50%;
			flex-direction: row;
			align-items: center;
			height: 2.1rem;
			margin-left: 7px;

			.values {
				display: flex;
				width: 35px;
				flex-direction: column;

				.value {
					margin-top: 4px;
					margin-bottom: 4px;
					font-family: Roboto;
					font-style: normal;
					font-weight: normal;
					font-size: 10px;
					line-height: 12px;
					color: #5c5b5b;
				}

				.ultimo {
					display: flex;
					flex-direction: row;
				}

				.value {
					white-space: nowrap;
				}
			}
		}
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 1rem;

	main {
		position: relative;
		display: inline-block;
	}

	section {
		width: 1.3rem;
		height: 1.3rem;
		background-color: #4d4d4d;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 10px;
		line-height: 13px;
	}

	aside {
		display: none;
		flex-direction: row;
		position: absolute;
		z-index: 1;
		width: 12rem;
		height: 6.2rem;
		background: #ffffff;
		border: 1px solid #b3b3b3;
		box-sizing: border-box;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
		border-radius: 10px;
	}
	.upgrade {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 80rem;
		height: 15rem;
	}
	.buttonUpgrade {
		background: ${Colors.primary_blue_k_main};
		width: 15rem;
		height: 3rem;
		margin-top: 1.5rem;
		border-radius: 1rem;
		align-items: center;
		color: white;
		font-weight: bold;
		border: none;
		transition: all 0.3s ease;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32);
	}

	.preset1 {
		margin-left: -0.5rem;
		margin-top: -1.8rem;
	}
	.preset2 {
		margin-left: -10.2rem;
		margin-top: -1.8rem;
	}

	.bodyAndTable {
		display: flex;
		flex-direction: column;
		width: 100%;

		.ContentTabel {
			display: flex;
			flex-direction: column;
			width: 95%;
		}

		.body {
			width: 30%;
			min-width: 50%;
			display: flex;
			flex-direction: row;


			.btnShare{
				display: flex;
				align-items: center;
				height: 25px;
				width: 130px;
				color: ${Colors.secondary_green_k_medium_1};
				flex-direction: row;
				margin-top:20px;
				margin-left: 10px;
				cursor: pointer;
				font-size: 15px;


				@media only screen and (max-width: 1300px) {
					font-size: 10px;
				}
			}
			.iconHistory {
				margin-right: 5px;
				@media only screen and (max-width: 1300px) {
					height: 13px;
					width: 16px;
				}
			}

			.contentBody {
				position: relative;
				display: flex;
				flex-direction: column;
				width: 20rem;
				height: 27.5rem;
				margin-left: 30px;

				.musculo {
					position: absolute;
					width: 20rem;
				}

				.dots_sup {
					position: absolute;
					z-index: 100;
					width: 20rem;
				}

				.imgBody {
					display: block;
				}

				.imgBodyInf {
					display: block;
					margin-top: -65%;
					position: relative;
					z-index: -100;
				}


				.musculoInf {
					position: absolute;
					margin-top: -65%;
					width: 20rem;
					z-index: -1;
				}

				.dots_inf {
					position: absolute;
					z-index: 100;
					width: 20rem;
					margin-top: -65%;
				}

				.numerosSuperiorFrontal {
					position: absolute;
					display: flex;
					flex-direction: column;
					width: 100%;
					height: 83.5%;
					z-index: 100;

					.row1 {
						display: flex;
						flex-direction: row;
						margin-top: 42%;
						margin-left: 28%;
						.OmbroDireito {
							margin-right: 1.3rem;
						}
						.OmbroEsquerdo {
							margin-left: 0.1rem;
						}
						.PeitoDireito {
							margin-top: 0.5rem;
							margin-right: 1.3rem;
						}
						.PeitoEsquerdo {
							margin-top: 0.5rem;
							margin-right: 1rem;
						}
					}

					.row2 {
						display: flex;
						flex-direction: row;
						margin-top: 1rem;
						margin-left: 26.5%;

						.bicepsDireito {
							margin-right: 6.8rem;
						}
					}

					.row3 {
						display: flex;
						flex-direction: row;
						margin-top: 2rem;
						margin-left: 7rem;
						.abdomenDireito {
							margin-right: 3.4rem;
						}
					}

					.row4 {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						margin-top: 2.5rem;
						.maoEsquerda {
							margin-right: 2.1rem;
						}
						.maoDireita {
							margin-left: 2.2rem;
						}
					}

					.row5 {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						margin-top: -14.4rem;
						.pescocoEsquerda {
							margin-right: 6.6rem;
						}
						.pescocoDireita {
							margin-left: 6.6rem;
						}
					}
					
					.row6 {
						display: flex;
						flex-direction: row;
						margin-top: 7.5rem;
						margin-left: 4.3rem;

						.anteBracoDireito {
							margin-right: 8.8rem;
						}
					}
				}

				.numerosSuperiorDorsal {
					position: absolute;
					display: flex;
					flex-direction: column;
					width: 100%;
					height: 83.5%;
					z-index: 100;
					.row1 {
						display: flex;
						flex-direction: row;
						margin-top: 6.5rem;
						margin-left: 4rem;

						.trapezioDireito {
							margin-right: 9.5rem;
						}
					}

					.row2 {
						display: flex;
						flex-direction: row;
						margin-left: 5.5rem;

						.deltoideEsquerdo {
							margin-right: 6.3rem;
						}
					}

					.row3 {
						display: flex;
						flex-direction: row;
						margin-left: 8rem;
						margin-top: 0.9rem;

						.trapezioDorsalEsquerdo {
							margin-right: 1.4rem;
						}
					}

					.row4 {
						display: flex;
						flex-direction: row;
						margin-left: 5.2rem;

						.tricepsDireito {
							margin-right: 7rem;
						}
					}
					
					.row5 {
						display: flex;
						flex-direction: row;
						margin-top: 1.5rem;
						margin-left: 4.3rem;

						.anteBracoDireito {
							margin-right: 8.8rem;
						}
					}
					.row6 {
						display: flex;
						flex-direction: row;
						margin-top: -7.5rem;
   					margin-left: 5.7rem;

						.ombroDorsalDireito {
							margin-right: 5.9rem;
						}
					}
				}

				.numerosInferiorFrontal {
					position: absolute;
					display: flex;
					flex-direction: column;
					width: 100%;
					height: 83.5%;
					z-index: 100;
					.row1 {
						display: flex;
						flex-direction: row;
						margin-left: 5.5rem;
						margin-top: 2rem;

						.quadrilDireito {
							margin-right: 1.7rem;
						}

						.iliopsoasDireito {
							margin-right: 1.5rem;
							margin-top: -1.3rem;
						}

						.iliopsoasEsquerdo {
							margin-top: -1.3rem;
							margin-left: -1rem;
						}
						.adutorDireito {
							margin-right: 0.5rem;
							margin-top: -1rem;
						}
						.quadrilEsquerdo {
							margin-left: -1.5rem;
						}
						.adutorEsquerdo {
							margin-top: -1rem;
						}
					}

					.row2 {
						display: flex;
						flex-direction: row;
						margin-top: 1rem;
						margin-left: 8.6rem;

						.coxaInternaEsquerdo {
							margin-left: 5px;
						}
					}

					.row3 {
						display: flex;
						flex-direction: row;
						margin-top: 0.7rem;
						margin-left: 7.5rem;

						.coxaDireito {
							margin-right: 2.5rem;
						}
					}

					.row4 {
						display: flex;
						flex-direction: row;
						margin-top: 7rem;
						margin-left: 7rem;

						.canelaDireito {
							margin-right: 3.4rem;
						}
					}
					.row5 {
						display: flex;
						flex-direction: row;
						margin-top: 3.4rem;
						justify-content: space-between;
						.peEsquerdo {
							margin-right: 6.2rem;
						}
						.peDireito {
							margin-left: 6.2rem;
						}
					}
				}

				.numerosInferiorDorsal {
					position: absolute;
					display: flex;
					flex-direction: column;
					width: 100%;
					height: 83.5%;
					margin-left: 7.5rem;
					margin-top: 2.5rem;
					z-index: 100;
					.row1 {
						display: flex;
						flex-direction: row;

						.gluteoEsquerdo {
							margin-right: 2.4rem;
						}
					}

					.row2 {
						display: flex;
						flex-direction: row;
						margin-top: 2rem;

						.coxaEsquerdo {
							margin-right: 2.4rem;
						}
					}

					.row3 {
						display: flex;
						flex-direction: row;
						margin-top: 7.2rem;
						margin-left: 0.3rem;

						.panturrilhaEsquerdo {
							margin-right: 1.8rem;
						}
					}
				}
			}

			.btnArrow {
				height: 3.43rem;
				width: 1.25rem;
				background: transparent;
				border: 0.4px solid #e5e5e5;
				box-sizing: border-box;
				box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
				border-radius: 5px;
				margin-top: 25%;
				z-index: 10;
			}
		}

		.divTable {
			display: flex;
			margin-top: 20px;
			flex-direction: column;
			width: 100%;

			.contentTable {
				width: 98%;
				margin-right: 1rem;


				::-webkit-scrollbar {
					width: 4px;
					height: 10px;
				}
				::-webkit-scrollbar-thumb {
					background: #e0e0e0;
					border-radius: 10px;
				}
				::-webkit-scrollbar-track {
					background: #ffffff;
					border-radius: 10px;
				}

				.hr {
					height: 1px;
					background-color: #e5e5e5;
					border: none;
					margin-top: 0.5px;
					width: 100%;
					margin-left: 35px;
					margin-bottom: 1.12rem;

					@media only screen and (max-width: 1350px) {
						width: 92%;
					}
					@media only screen and (max-width: 1310px) {
						width: 90%;
					}
				}

				.title {
					font-family: Roboto;
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 23px;
					color: #5c5b5b;
					margin-bottom: 1.625rem;
				}

				.table {
					width: 97%;
					.tr {
						cursor: pointer;
						.number {
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: #4d4d4d;
							border-radius: 50%;
							width: 20px;
							height: 20px;
							font-size: 9px;
							color: #ffffff;
						}
						.name {
							font-family: Roboto;
							font-style: normal;
							font-weight: normal;
							font-size: 16px;
							line-height: 19px;
							color: #5c5b5b;
							padding-left: 10px;
						}
						.percentage {
							font-family: Roboto;
							font-style: normal;
							font-weight: bold;
							font-size: 16px;
							line-height: 19px;
							text-align: right;
							color: ${Colors.tertirary_red_1_dark};
						}
					}
				}
			}

			.avisoDiv {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 80%;

				.avisoDados {
					font-size: 1.2rem;
					color: #5c5b5b;
					margin-top: 1.6rem;
					text-align: center;
					margin-bottom: 1.5rem;
				}

				.avisoFuncional {
					font-size: 0.8rem;
					color: #001574;
					margin-top: 1.6rem;
					text-align: center;
					margin-bottom: 1rem;
				}
			}
			.containerLegenda {
				display: flex;
				width: 98%;
				background-color: #ffffff;
				/* margin-left: 3.875rem; */
				margin-right: 1rem;
				margin-top: 1rem;
				margin-bottom: 1rem;
				height: 7rem;
				padding: 0.5rem;
				border-radius: 10px;
				flex-direction: column;
				box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

				@media only screen and (max-width: 1340px) {
					width: 90%;
				}
				@media only screen and (max-width: 1130px) {
					width: 80%;
				}

				.ContentInfo {
					display: flex;
					width: 100%;
					margin-top: 4px;
					height: 1.5rem;
					align-items: center;

					.TextLegenda {
						margin-left: 5px;
						color: #000000;
						@media only screen and (max-width: 1440px) {
							font-size: small;
						}
						@media only screen and (max-width: 900px) {
							font-size: 10px;
						}
					}

					.danger{
						background-color: #D13E3E;
						border-radius: 3px;
						width: 1rem;
						height: 1rem;
					}
					.alert{
						background-color: #FFC530;
						border-radius: 3px;
						width: 1rem;
						height: 1rem;
					}
					.good{
						background-color: #39E664;
						border-radius: 3px;
						width: 1rem;
						height: 1rem;
					}
					.dominantMuscle{
						background-color: #7F7F7F;
						border-radius: 3px;
						width: 1rem;
						height: 1rem;
					}
				}
			}
		}
	}

	.divIq {
		display: flex;
		flex-direction: column;
		width: 98%;
		background: #ffffff;
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
		border-radius: 12px;
		margin-bottom: 1rem;
		padding-top: 1.6rem;
		padding-bottom: 1.6rem;
		padding-left: 1rem;
		padding-right: 1rem;
		z-index: 1;

		@media only screen and (max-width: 1380px) {
			width: 95%;
		}
		@media only screen and (max-width: 900px) {
			width: 90%;
		}
		@media only screen and (max-width: 800px) {
			width: 80%;
		}
		
		.ContainerInfos {
			min-height: 3rem;
			max-height: 5rem;
			width: 100%;
			margin-top: 0.5rem;
			display: flex;
			flex-direction: column;
			overflow-y:scroll;
			overflow-x: hidden;

			::-webkit-scrollbar {
				width: 2px;
				height: 2px;
			}
			::-webkit-scrollbar-thumb {
				background: #e0e0e0;
				border-radius: 10px;
			}
			::-webkit-scrollbar-track {
				background: #ffffff;
				border-radius: 10px;
			}

			.table {
				display: flex;
				flex-direction: row;
				margin-top: 0.1rem;
				width: 100%;

				.ContentTitles {
					flex-direction: row;
					display: flex;
					width: 100%;
					border-bottom: solid;

					.title {
						color:'#000000';
						font-weight: bold;
						font-size: 15px;

						@media only screen and (max-width: 1820px) {
							font-size: 13px;
						}
						@media only screen and (max-width: 1654px) {
							font-size: 11px;
						}
						@media only screen and (max-width: 1348px) {
							font-size: 10px;
						}
						@media only screen and (max-width: 1135px) {
							font-size: 8px;
						}
					}
					.values {
						font-size: 15px;
						margin-left: 5px;

						@media only screen and (max-width: 1820px) {
							font-size: 13px;
						}
						@media only screen and (max-width: 1654px) {
							font-size: 11px;
						}
						@media only screen and (max-width: 1348px) {
							font-size: 10px;
						}
						@media only screen and (max-width: 1135px) {
							font-size: 8px;
						}
					}
				}
				.indiceiq {
					width: 19%

				}
				.ultimaAvaliacao {
					width: 25%
				}
				.valorEsquerdo {
					width: 26%;
					display: flex;
					flex-direction: row;

					@media only screen and (max-width: 1440px) {
						font-size: 12px;
					}
				}
				.valorDireito {
					width: 25%;
					display: flex;
					flex-direction: row;

					@media only screen and (max-width: 1440px) {
						font-size: 12px;
					}
				}
				.data {
					width: 24;

					@media only screen and (max-width: 1440px) {
						font-size: 12px;
					}
				}
			}
		}
		.contentTable {
			height: 2rem;
			width: 100%;
		}
		.nenhumIQ {
			text-align: center;
		}
		.modalUpgrade {
			text-align: center;
		}
		.divTitle {
			display: flex;
			justify-content: center;
			color: ${Colors.primary_blue_k_main};
		}

		.table {
			width: 100%;
			.tr {
				height: 3rem;
			}

			.iq {
				font-family: Roboto;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 21px;
				color: #5c5b5b;
				width: 11rem;
				display: flex;
				flex-direction: row;

				.ajuda {
					border: none;
					background-color: transparent;
					align-items: center;
				}
			}

			.iqDireito {
				font-family: Roboto;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: #5c5b5b;
			}

			.value {
				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: #8c8e86;
			}
		}
		.divider {
			height: 1px;
			width: 100%;
			background-color: #001574;
		}
		.divInfoIQ {
			margin-top: 1rem;
			
			@media only screen and (max-width: 1110px) {
				font-size: 13px;
			}

			.infoIQ {
				color: '#000000'
			}
		}
	}
`;
