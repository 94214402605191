import {
	Backdrop,
	Fade,
	Modal,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../store/ducks/app";
import { PacAvalActions } from "../../store/ducks/manage_pac_aval";
import buttonClose from "../../assets/icons/buttonCloseBlack.svg";
import { PacientesProps } from "../../interfaces/pacientes.interface";
import Perfil from "../../assets/icons/perfil.svg";
import { AtribuirActions } from "../../store/ducks/atribuir";
import { DashBoardActions } from "../../store/ducks/dashboard";
import { ButtonSave, RadioButton } from "../../styles/global";
import { TextsProps } from "../../database/txt.interface";
import {
	Close,
	Container,
	ContainerOptions,
	ContainerPaci,
	Name,
	NameOption,
	Photo,
	Subtitle,
	Title,
	Option,
} from "../../styles/styles_modais_padroes";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalCondicaoFisica: React.FC = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [optionChecked, setOptionChecked] = useState("");

	const is_open_atribuir_fisica = useSelector(
		(state: any) => state.app.is_open_atribuir_fisica
	);

	const lista_pacientes = useSelector(
		(state: any) => state.app.lista_pacientes
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);

	const opcoes = [
		{ id: 1, name: current_language?.menu_lateral_atleta },
		{ id: 2, name: current_language?.menu_lateral_ativo },
		{ id: 3, name: current_language?.menu_lateral_pos_operatorio },
		{ id: 4, name: current_language?.menu_lateral_sedentario },
		{ id: 5, name: current_language?.menu_lateral_idoso },
	];

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_lista_pacientes([]));
		dispatch(PacAvalActions.reset_patient());
		dispatch(AppActions.set_modal_atribuir_fisica(false));
	};

	const atribuir = () => {
		lista_pacientes.map((id: number) => {
			const request = {
				ID_USUARIO: pacientes.find(
					(user: PacientesProps) => user?.ID_USUARIO === id
				).ID_USUARIO,
				USUARIO_NOME: pacientes.find(
					(user: PacientesProps) => user?.ID_USUARIO === id
				)?.USUARIO_NOME,
				USUARIO_CONDICIONAMENTO: Number(optionChecked),
			};
			dispatch(AtribuirActions.edit_atribuir(request));
		});
		dispatch(PacAvalActions.reset_patient());
		dispatch(DashBoardActions.paciente_request());
		dispatch(AppActions.set_lista_pacientes([]));
		dispatch(AppActions.set_modal_atribuir_fisica(false));
	};


	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open_atribuir_fisica}
			onClose={ModalCadastroClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open_atribuir_fisica}>
				<Container>
					<Close
						className="close"
						src={buttonClose}
						onClick={ModalCadastroClose}
						alt="Close"
					/>
					<Title>{current_language?.condicao_fisica}</Title>
					<Subtitle>{current_language?.paciente_selecionado}:</Subtitle>
					<ContainerPaci>
						{lista_pacientes.length == 1 ? (
							<Photo
								src={
									pacientes.find(
										(user: PacientesProps) =>
											user.ID_USUARIO === lista_pacientes[0]
									)?.USUARIO_FOTO
										? pacientes.find(
												(user: PacientesProps) =>
													user.ID_USUARIO === lista_pacientes[0]
										  )?.USUARIO_FOTO
										: Perfil
								}
							/>
						) : (
							<Photo src={Perfil} />
						)}
						<Name>
							{lista_pacientes.length == 1
								? pacientes.find(
										(user: PacientesProps) =>
											user.ID_USUARIO === lista_pacientes[0]
								  )?.USUARIO_NOME
								: lista_pacientes.length +
								  " " +
								  current_language?.pacientes_selecionados}
						</Name>
					</ContainerPaci>
					<Subtitle>{current_language?.selecione_cond_fisico}:</Subtitle>
					<ContainerOptions>
						{opcoes.map((opcao: { id: number; name: string }) => {
							return (
								<Option key={opcao.id}>
									<NameOption>{opcao.name}</NameOption>
									<RadioButton
										type="radio"
										name={opcao.id.toString()}
										value={opcao.id.toString()}
										onChange={(e) => setOptionChecked(opcao.id.toString())}
										checked={optionChecked === opcao.id.toString()}
									/>
								</Option>
							);
						})}
					</ContainerOptions>
					<ButtonSave
						active={optionChecked != "" ? true : false}
						onClick={
							optionChecked != ""
								? atribuir
								: () => {
										/*No Op*/
								  }
						}
					>
						{current_language?.atribuir}
					</ButtonSave>
				</Container>
			</Fade>
		</Modal>
	);
};

export default ModalCondicaoFisica;
