import { CircularProgress, dividerClasses } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	AddTags,
	CardUserInTag,
	Container,
	ContainerArrowIcon,
	ContainerCardUsers,
	ContainerGoBack,
	ContainerPhotoAndName,
	ContainerTitle,
	ContainerTrash,
	Header,
	Img,
	Photo,
	Save,
} from "./styles";

import Text from "../../components/Texts/Text";

import { ReactComponent as ArrowBack } from "../../assets/kinologyIcons/svg/arrow_back.svg";
import { ReactComponent as Trash } from "../../assets/kinologyIcons/svg/trash.svg";
import { ReactComponent as UserIcon } from "../../assets/kinologyIcons/svg/patient.svg";
import { TextsProps } from "../../database/txt.interface";
import { Colors } from "../../styles/colors";
import { useForm } from "react-hook-form";
import { AmplitudeLog } from "../../utils/amplitude";
import moment from "moment";
import Form from "../../components/Form";
import InputTextEntryForm from "../../components/Inputs/inputForm";
import { TagActions } from "../../store/ducks/tags";
import ToastAlert from "../../components/toast";
import { AppActions } from "../../store/ducks/app";
import { PacientesProps } from "../../interfaces/pacientes.interface";
import { ContainerDash, Icon, Return, Scroll } from "../../styles/global";
import { H3Desk, P3Desk } from "../../styles/styles_text";
import ReturnIcon from "../../assets/icons/back_page.svg";

interface TagsProps {
	ID_ETIQUETA: number;
	ETIQUETA_NOME: string;
	ID_USUARIOS: [];
}
const EditTagPage: React.FC = () => {
	const dispatch = useDispatch();
	const dataTag: TagsProps = useSelector(
		(state: any) => state.app.data_edit_tag
	);
	const editDatSuccess = useSelector(
		(state: any) => state.tags.salvar_edicao_tag
	);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const patientEtiqueta = useSelector(
		(state: any) => state.app.set_array_patient_etiquetas
	);
	const ids: number[] = [];
	patientEtiqueta?.forEach((item: any) => ids.push(item.ID_USUARIO));

	const [isSearch, setIsSearch] = useState<boolean>(false);
	const [value, setValue1] = useState<any>();
	const [aprovado, setAprovado] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm();

	const methods = useForm({
		defaultValues: {
			etiqueta: dataTag?.ETIQUETA_NOME || "",
		},
	});
	const EditTag = methods?.watch("etiqueta");

	const onSubmit = async (data: any) => {
		const request = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			ETIQUETA_NOME: data?.etiqueta,
			ID_ETIQUETA: dataTag?.ID_ETIQUETA,
		};
		dispatch(TagActions.set_editar_tags(request));
		AmplitudeLog("sucesso_edit_etiqueta", {
			body: request,
		});
		reset();
	};

	useEffect(() => {
		if (dataTag?.ETIQUETA_NOME != EditTag) {
			dispatch(TagActions.set_botao_salvar_edicao_tag(true));
		}
	}, [EditTag]);

	return (
		<ContainerDash>
			{is_open_toast && <ToastAlert />}

			<Return
				onClick={() => {
					history.back();
					dispatch(
						AppActions.set_option_menu(
							false,
							current_language?.menu_lateral_minha_conta
						)
					);
				}}
			>
				<Icon src={ReturnIcon} style={{ width: "1rem", height: "1rem" }} />
				<P3Desk>Minha conta</P3Desk>
			</Return>

			<ContainerTitle style={{ width: "60%", marginTop: "2rem" }}>
				<H3Desk>Editar etiqueta</H3Desk>
				<AddTags
					onClick={() => {
						AmplitudeLog("excluir_etiqueta");
						dispatch(TagActions.set_tags([dataTag]));
						dispatch(AppActions.set_modal_excluir_etiqueta(true));
					}}
				>
					<ContainerArrowIcon>
						<Trash
							color={Colors?.primary_blue_k_main}
							className="DeleteTagIcon"
						/>
					</ContainerArrowIcon>
				</AddTags>
			</ContainerTitle>
			<Form
				style={{
					width: "60%",
					position: "relative",
					marginTop: "2rem",
					height: 70,
					display: "flex",
				}}
				methods={methods}
				onSubmit={(data) => {
					onSubmit(data);
				}}
			>
				<InputTextEntryForm
					type="text"
					width={100}
					title={current_language?.etiqueta}
					placeholder={current_language?.placeholder_digite}
					leftIcon="Tag"
					fontFamily="NotoSans-SemiBold"
					fontWeight={600}
					typeInputName="etiqueta"
				/>

				{!!editDatSuccess && (
					<Save
						type="submit"
						dataTagName={dataTag?.ETIQUETA_NOME}
						EditTag={EditTag}
					>
						{current_language?.pagamento_salvar}
					</Save>
				)}
			</Form>
			<div style={{ width: "60%", marginTop: "1rem" }}>
				<H3Desk>Pacientes atribuidos</H3Desk>
			</div>
			<Scroll style={{ width: "60%" }}>
				<ContainerCardUsers>
					{patientEtiqueta?.map((item: any, index: number) => (
						<CardUserInTag key={index}>
							<ContainerPhotoAndName>
								<Photo>
									{item?.USUARIO_FOTO ? (
										<Img src={item?.USUARIO_FOTO} />
									) : (
										<UserIcon width={20} height={20} color={Colors?.white} />
									)}
								</Photo>
								<Text
									title={item?.USUARIO_NOME}
									fontFamily="NotoSans-SemiBold"
									fontWeight={700}
									fontSize={1}
									textColor={Colors?.primary_blue_k_main}
									textAlign="left"
									marginLeft={13}
								/>
							</ContainerPhotoAndName>
							<ContainerTrash
								onClick={() => {
									const type = {
										mode: "removePaciEtiqueta",
										deletePaci: item,
										idUsers: ids,
									};
									dispatch(
										AppActions.set_modal_excluir({
											status: true,
											parameter: type,
										})
									);
								}}
							>
								<Trash
									color={Colors?.primary_blue_k_main}
									className="DeleteTagInPatient"
								/>
							</ContainerTrash>
						</CardUserInTag>
					))}
				</ContainerCardUsers>
			</Scroll>
		</ContainerDash>
	);
};

export default EditTagPage;
