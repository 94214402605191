import moment from "moment";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
	PacientesProps,
	UserProps,
} from "../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "../../utils/types";
import { AppActions } from "../ducks/app";
import { AtribuirActions, Types } from "../ducks/atribuir";
import { DashBoardActions } from "../ducks/dashboard";
import { PacAvalActions } from "../ducks/manage_pac_aval";
import * as Sentry from "@sentry/react";

function* atribuirUser({ payload }: any): any {
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const language: any = yield select((state) => state.configs.language);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const dataRequest: any = {};
			const dat = {
				...payload,
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				txIdioma: language?.toLowerCase(),
			};
			dataRequest["0"] = { TOKEN: user_logado.token };
			dataRequest["1"] = dat;
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}usuarios/usuarios_editar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: usuarios_editar.php", "error");
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.FAIL) {
			if (response.code == 1) {
				message = current_language?.edicao_erro_1;
			}
			if (response.code == 2) {
				message = current_language?.edicao_erro_2;
			}
			if (response.code == 3) {
				message = current_language?.edicao_erro_3;
			}
			if (response.code == 4) {
				message = current_language?.edicao_erro_4;
			}
			if (response.code == 5) {
				message = current_language?.edicao_erro_5;
			}
			if (response.code == 6) {
				message = current_language?.edicao_erro_6;
			}

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			//msg de sucesso
			yield put(DashBoardActions.paciente_request());
			if (response.code == 0) {
				message = current_language?.edicao_concluida;
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: message,
				})
			);
		}
	} catch (e) {
	} finally {
		yield put(AtribuirActions.edit_atribuir_finally());
		yield put(PacAvalActions.reset_patient());
		yield put(PacAvalActions.reset_aval());
		const pacientes = yield select((state: any) => state.dashboard.pacientes);
		const p = pacientes.filter(
			(item: PacientesProps) => item.ID_USUARIO == payload.ID_USUARIO
		);
		yield put(AppActions.set_open_paciente(p[0]));
	}
}

export function* AtribuirSaga(): any {
	return yield all([takeLatest(Types.ATRIBUIR_USUARIO_PERFIL, atribuirUser)]);
}
