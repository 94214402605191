import { TypeCategoriasAnamnese, TypeTypeAnamnese } from "../../utils/types";

export const Types = {
	GET_HISTORICO_ANAMNESE: "GET_HISTORICO_ANAMNESE",
	SET_HISTORICO_ANAMNESE: "SET_HISTORICO_ANAMNESE",

	GET_RELATORIO_ANAMNESE: "GET_RELATORIO_ANAMNESE",
	SET_RELATORIO_ANAMNESE: "SET_RELATORIO_ANAMNESE",

	SET_ANAMNESE_CATEGORY: "SET_ANAMNESE_CATEGORY",
	SET_ANAMNESE_TYPE: "SET_ANAMNESE_TYPE",

	SET_TYPE_HISTORICO: "SET_TYPE_HISTORICO",

	SALVAR_QUESTOES_V7: "SALVAR_QUESTOES_V7",

	SET_RELATORIO_QUALIDADE_VIDA_HISTORY: "SET_RELATORIO_QUALIDADE_VIDA_HISTORY",
};

const INITIAL_STATE = {
	anamnese_category: "",
	anamnese_type: "",
	historico_anamnese: null,
	relatorio_anamnese: null,
	type_historico: "avaliacao",
	current_qualidade_vida_history: null,
};

export default function anamnese(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.SET_ANAMNESE_CATEGORY:
			return {
				...state,
				anamnese_category: action.payload,
			};
		case Types.SET_ANAMNESE_TYPE:
			return {
				...state,
				anamnese_type: action.payload,
			};
		case Types.SET_HISTORICO_ANAMNESE:
			return {
				...state,
				historico_anamnese: action.payload,
			};
		case Types.SET_RELATORIO_ANAMNESE:
			return {
				...state,
				relatorio_anamnese: action.payload,
			};

		case Types.SET_TYPE_HISTORICO:
			return {
				...state,
				type_historico: action.payload,
			};
		case Types.SET_RELATORIO_QUALIDADE_VIDA_HISTORY:
			return {
				...state,
				current_qualidade_vida_history: action.payload,
			};
		default:
			return state;
	}
}

type typeHistorico = "avaliacao" | "exame_fisico";

export const AnamneseActions = {
	set_anamnese_category: (payload: TypeCategoriasAnamnese) => ({
		type: Types.SET_ANAMNESE_CATEGORY,
		payload: payload,
	}),
	set_anamnese_type: (payload: TypeTypeAnamnese) => ({
		type: Types.SET_ANAMNESE_TYPE,
		payload: payload,
	}),
	get_historico_anamnese_request: () => ({
		type: Types.GET_HISTORICO_ANAMNESE,
	}),
	set_historico_anamnese: (payload: any) => ({
		type: Types.SET_HISTORICO_ANAMNESE,
		payload: payload,
	}),

	get_relatorio_anamnese_request: (payload: any) => ({
		type: Types.GET_RELATORIO_ANAMNESE,
		payload: payload,
	}),
	set_relatorio_anamnese: (payload: any) => ({
		type: Types.SET_RELATORIO_ANAMNESE,
		payload: payload,
	}),
	set_type_historico: (payload: typeHistorico) => ({
		type: Types.SET_TYPE_HISTORICO,
		payload: payload,
	}),
	salvar_questoes_v7: () => ({
		type: Types.SALVAR_QUESTOES_V7,
	}),
	set_relatorio_qualidade_vida_history: (payload: number | null) => ({
		type: Types.SET_RELATORIO_QUALIDADE_VIDA_HISTORY,
		payload: payload,
	}),
};

