import React from "react";
import styled from "styled-components";
import ListagemProtocolos from "./ListagemProtocolos/ListagemProtocolos";
import InputPesquisaProtocolo from "./InputPesquisaProtocolo/InputPesquisaProtocolo";
import { ButtonAdd, ContainerDash, Icon } from "../../styles/global";
import AddIcon from "../../assets/icons/adicionar_paci.svg";
import { useDispatch, useSelector } from "react-redux";
import { ManageProtocolActions } from "../../store/ducks/manage_protocols";
import { DashBoardActions } from "../../store/ducks/dashboard";
import { TextsProps } from "../../database/txt.interface";

const Protocolos: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	return (
		<ContainerDash>
			<ContainerOptions>
				<OptionSelected>{current_language?.menu_lateral_protocolos}</OptionSelected>
				<Option
					onClick={() =>
						dispatch(DashBoardActions.set_current_page("exercicios"))
					}
				>
					{current_language?.exercicios}
				</Option>
			</ContainerOptions>
			<InputPesquisaProtocolo />
			<ListagemProtocolos />
			<PaginationAndAddButton>
				<ButtonAdd
					active={false}
					onClick={() =>
						dispatch(ManageProtocolActions.set_open_modal_add_protocolo(true))
					}
				>
					<Icon style={{ width: "1.6rem", height: "1.6rem" }} src={AddIcon} />
					{current_language?.adicionar_protocolo}
				</ButtonAdd>
			</PaginationAndAddButton>
		</ContainerDash>
	);
};

const ContainerOptions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 60%;
`;

const Option = styled.text`
	font-family: "NotoSans-regular";
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 38.4px;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	border-bottom: 3px solid #dedfe3;
	color: #000e4b;
`;

const OptionSelected = styled.text`
	font-family: "NotoSans-regular";
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 38.4px;
	padding-right: 1.5rem;
	border-bottom: 3px solid #00d084;
	color: #000e4b;
`;

const PaginationAndAddButton = styled.div`
	position: fixed;
	bottom: 0;
	width: 56%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding-bottom: 1.5rem;
	padding-top: 0.5rem;
	background-color: #fafafa;
`;

export default Protocolos;
