import styled from "styled-components";
import { Colors } from "../../styles/colors";

export const Conteiner = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 43px; 
    height: 21px; 
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${Colors?.neutral_grey_k_80};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 15px; 
    width: 15px;  
    left: 2.6px;  
    bottom: 2.6px; 
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: ${Colors?.secondary_green_k_dark_2};
  }
  input:hover + .slider  {
    background-color: ${Colors?.primary_blue_k_main}
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${Colors?.secondary_green_k_dark_2};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(21px); 
    -ms-transform: translateX(21px);     
    transform: translateX(21px);         
  }

  .slider.round {
    border-radius: 34px;
  }
`;
