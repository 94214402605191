import { TypesApi, TypesLanguage, TypesMedida } from "../../utils/types";

export const Types = {
	CONFIG_REQUEST: "CONFIG_REQUEST",
	CONNECTION_REQUEST: "CONNECTION_REQUEST",
	CONFIG_EDIT_REQUEST: "CONFIG_EDIT_REQUEST",

	SET_LANGUAGE: "SET_LANGUAGE",
	SET_CURRENT_LANGUAGE: "SET_CURRENT_LANGUAGE",

	SET_MEDIDA: "SET_MEDIDA",

	SET_TEMPO_CONTRACAO: "SET_TEMPO_CONTRACAO",
	SET_ALERTA_SONORO: "SET_ALERTA_SONORO",
	SET_ALERTA_VIBRATORIO: "SET_ALERTA_VIBRATORIO",
	SET_CONTAGEM_REGRESIVA: "SET_CONTAGEM_REGRESIVA",
	SET_INTERVALO_DESCANSO: "SET_INTERVALO_DESCANSO",

	SET_CONFIG_APP: "SET_CONFIG_APP",
	SET_CONFIG_SERVICO: "SET_CONFIG_SERVICO",
	SET_CONFIG_LISTA_USUARIOS: "SET_CONFIG_LISTA_USUARIOS",

	SET_INTERVALO_DESCANSO_TIMER: "SET_INTERVALO_DESCANSO_TIMER",
	SET_TEMPO_CONTRACAO_TIMER: "SET_TEMPO_CONTRACAO_TIMER",

	SET_BLUETOOTH: "SET_BLUETOOTH",

	SET_MODO_DEMO: "SET_MODO_DEMO",

	SET_CONFIG_RELATORIO: "SET_CONFIG_RELATORIO",

	RESULT_BLUETOOTH: "RESULT_BLUETOOTH",

	SET_QUANTIDADE_EMISSOES_RELATORIOS: "SET_QUANTIDADE_EMISSOES_RELATORIOS",
	SET_TITLE_MODAL_UPGRADE: 'SET_TITLE_MODAL_UPGRADE',
	REMOVE_BUTTON_CLOSE: 'REMOVE_BUTTON_CLOSE',
	CLOSE_MODAL_SHARE_AND_NAVIGATI: 'CLOSE_MODAL_SHARE_AND_NAVIGATI',
	CLOSE_MODAL_AND_GOBACK: 'CLOSE_MODAL_AND_GOBACK',
	VERIFICATION_BY_THE_MOST_VISITED_PATIENTS: 'VERIFICATION_BY_THE_MOST_VISITED_PATIENTS',
	SET_IDENTIFICATION_DINAMO: 'SET_IDENTIFICATION_DINAMO',
	BLOCK_DINO_REQUEST: 'BLOCK_DINO_REQUEST',
	VERIFY_BLOCKED_DINO: 'VERIFY_BLOCKED_DINO',
	SET_CONFIG_FUNCIONAL: 'SET_CONFIG_FUNCIONAL',
	SET_NUMBER_OF_EVALUATED_PATIENTS: 'SET_NUMBER_OF_EVALUATED_PATIENTS',
	REQUEST_EVALUATED_PATIENTS: 'REQUEST_EVALUATED_PATIENTS',
	DINO_BATTERY: 'DINO_BATTERY',
	RESULT_BLUETOOTH_HAND_GRIP: 'RESULT_BLUETOOTH_HAND_GRIP'
};

const INITIAL_STATE = {
	language: localStorage.getItem('@elastic:config_language')?.replace(/"/g, '') || TypesLanguage.PT,
	current_language: null,

	config_app: null,
	config_servicos: null,
	config_lista_usuarios: null,

	medida: TypesMedida.KG,

	tempo_contracao_flag: true,
	intervalo_descanso_flag: true,

	tempo_contracao: 20,
	intervalo_descanso: 60,

	alerta_sonoro: false,
	alerta_vibratorio: false,
	contagem_regressiva: false,
	bluetooth: false,

	modo_demo: false,

	version: "6.3.7",
	build: "6.3.1.5",

	end_point: { END_POINT: TypesApi.PROD},

	config_relatorio: null,

	result_bluetooth: false,

	set_quantidade_emissoes_relatorios: null,
	set_title_modal_upgrade: false,
	remove_button_close: false,
	close_modal_shere_navigate: false,
	close_modal_and_goback: false,
	verification_by_patient_most_visited: null,
	mac_address: null,
	dinamo_uuid: null,
	device_name: null,
	config_funcional: false,
	number_evaluated_patient: null,
	dino_battery: null,
	result_bluetooth_hand_grip: false,
}

export default function configs(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.SET_LANGUAGE:
			return {
				...state,
				language: action.payload,
			};

		case Types.SET_CURRENT_LANGUAGE:
			return {
				...state,
				current_language: action.payload,
			};

		case Types.SET_MEDIDA:
			return {
				...state,
				medida: action.payload,
			};
		case Types.SET_TEMPO_CONTRACAO:
			return {
				...state,
				tempo_contracao_flag: action.payload,
			};
		case Types.SET_ALERTA_SONORO:
			return {
				...state,
				alerta_sonoro: action.payload,
			};
		case Types.SET_ALERTA_VIBRATORIO:
			return {
				...state,
				alerta_vibratorio: action.payload,
			};
		case Types.SET_CONTAGEM_REGRESIVA:
			return {
				...state,
				contagem_regressiva: action.payload,
			};
		case Types.SET_INTERVALO_DESCANSO:
			return {
				...state,
				intervalo_descanso_flag: action.payload,
			};

		case Types.SET_CONFIG_APP:
			return {
				...state,
				config_app: action.payload,
			};
		case Types.SET_CONFIG_SERVICO:
			return {
				...state,
				config_servicos: action.payload,
			};
		case Types.SET_CONFIG_LISTA_USUARIOS:
			return {
				...state,
				config_lista_usuarios: action.payload,
			};
		case Types.SET_TEMPO_CONTRACAO_TIMER:
			return {
				...state,
				tempo_contracao: action.payload,
			};
		case Types.SET_INTERVALO_DESCANSO_TIMER:
			return {
				...state,
				intervalo_descanso: action.payload,
			};
		case Types.SET_BLUETOOTH:
			return {
				...state,
				bluetooth: action.payload,
			};
		case Types.SET_MODO_DEMO:
			return {
				...state,
				modo_demo: action.payload,
			};
		case Types.SET_CONFIG_RELATORIO:
			return {
				...state,
				config_relatorio: action.payload,
			};
		case Types.RESULT_BLUETOOTH:
			return {
				...state,
				result_bluetooth: action.payload,
			};
		case Types.SET_QUANTIDADE_EMISSOES_RELATORIOS:
			return {
				...state,
				set_quantidade_emissoes_relatorios: action.payload,
			};
		case Types.SET_TITLE_MODAL_UPGRADE:
			return {
				...state,
				set_title_modal_upgrade: action.payload,
			}
		case Types.REMOVE_BUTTON_CLOSE:
			return {
				...state,
				remove_button_close: action.payload,
			}
		case Types.CLOSE_MODAL_SHARE_AND_NAVIGATI:
			return {
				...state,
				close_modal_shere_navigate: action.payload,
			}
		case Types.CLOSE_MODAL_AND_GOBACK:
			return {
				...state,
				close_modal_and_goback: action.payload
			}
		case Types.VERIFICATION_BY_THE_MOST_VISITED_PATIENTS:
			return {
				...state,
				verification_by_patient_most_visited: action.payload
			}
		case Types.SET_IDENTIFICATION_DINAMO: 
		return {
			...state,
			mac_address: action.payload.mac_address,
			dinamo_uuid: action.payload.dinamo_uuid,
			device_name: action.payload.device_name,
		}
		case Types.SET_CONFIG_FUNCIONAL:
			return {
				...state,
				config_funcional: action.payload
			};
		case Types.SET_NUMBER_OF_EVALUATED_PATIENTS:
			return {
				...state,
				number_evaluated_patient: action.payload
			};
		case Types.DINO_BATTERY:
			return {
				...state,
				dino_battery: action.payload,
			};
		case Types.RESULT_BLUETOOTH_HAND_GRIP:
			return {
				...state,
				result_bluetooth_hand_grip: action.payload,
			};
		default:
			return state;
	}
}

export const ConfigsActions = {
	set_language: (language: any) => ({
		type: Types.SET_LANGUAGE,
		payload: language,
	}),

	set_current_language: (array: any) => ({
		type: Types.SET_CURRENT_LANGUAGE,
		payload: array,
	}),

	config_request: () => ({
		type: Types.CONFIG_REQUEST,
	}),
	block_dino_request: () => ({
		type: Types.BLOCK_DINO_REQUEST,
	}),
	verify_blocked_dino: () => ({
		type: Types.VERIFY_BLOCKED_DINO,
	}),
	connection_request: () => ({
		type: Types.CONNECTION_REQUEST,
	}),
	set_medida: (medida: any) => ({
		type: Types.SET_MEDIDA,
		payload: medida,
	}),

	set_tempo_contracao: (status: any) => ({
		type: Types.SET_TEMPO_CONTRACAO,
		payload: status,
	}),
	set_alerta_sonoro: (status: any) => ({
		type: Types.SET_ALERTA_SONORO,
		payload: status,
	}),
	set_alerta_vibratorio: (status: any) => ({
		type: Types.SET_ALERTA_VIBRATORIO,
		payload: status,
	}),
	set_contagem_regresiva: (status: any) => ({
		type: Types.SET_CONTAGEM_REGRESIVA,
		payload: status,
	}),
	set_intervalo_descanso: (status: any) => ({
		type: Types.SET_INTERVALO_DESCANSO,
		payload: status,
	}),

	set_config_app: (config: any) => ({
		type: Types.SET_CONFIG_APP,
		payload: config,
	}),
	set_config_servico: (servico: any) => ({
		type: Types.SET_CONFIG_SERVICO,
		payload: servico,
	}),
	set_config_lista_usuarios: (usuarios: any) => ({
		type: Types.SET_CONFIG_LISTA_USUARIOS,
		payload: usuarios,
	}),
	set_intervalo_descanso_timer: (status: any) => ({
		type: Types.SET_INTERVALO_DESCANSO_TIMER,
		payload: status,
	}),
	set_tempo_contracao_timer: (status: any) => ({
		type: Types.SET_TEMPO_CONTRACAO_TIMER,
		payload: status,
	}),
	config_request_edit: (data: any) => ({
		type: Types.CONFIG_EDIT_REQUEST,
		payload: data,
	}),
	set_bluetooth: (status: any) => ({
		type: Types.SET_BLUETOOTH,
		payload: status,
	}),
	set_modo_demo: (status: any) => ({
		type: Types.SET_MODO_DEMO,
		payload: status,
	}),
	set_config_relatorio: (status: any) => ({
		type: Types.SET_CONFIG_RELATORIO,
		payload: status,
	}),
	set_bluetooth_result: (status: any) => ({
		type: Types.RESULT_BLUETOOTH,
		payload: status,
	}),
	set_quantidade_emissao_relatorios: (status: any) => ({
		type: Types.SET_QUANTIDADE_EMISSOES_RELATORIOS,
		payload: status,
	}),
	set_title_modal_upgrade: (status: boolean)=>({
		type: Types.SET_TITLE_MODAL_UPGRADE,
		payload: status,
	}),
	set_remove_button_close: (status: boolean)=>({
		type: Types.REMOVE_BUTTON_CLOSE,
		payload: status,
	}),
	set_close_modal_shere_and_navigate: (status: boolean)=>({
		type: Types.CLOSE_MODAL_SHARE_AND_NAVIGATI,
		payload: status,
	}),
	set_close_modal_upgrade_and_goback: ( status: boolean)=> ({
		type: Types.CLOSE_MODAL_AND_GOBACK,
		payload: status,
	}),
	set_physical_anamnesis_verification_by_the_most_visited_patients: (status: boolean) => ({
		type: Types.VERIFICATION_BY_THE_MOST_VISITED_PATIENTS,
		payload: status,
	}),
	set_identification_dinamo: (mac_address: string, dinamo_uuid: string, device_name?: string)=> ({
		type: Types.SET_IDENTIFICATION_DINAMO,
		payload: {mac_address, dinamo_uuid, device_name},
	}),
	set_config_funcional: (config_state: boolean)=> ({
		type: Types.SET_CONFIG_FUNCIONAL,
		payload: config_state
	}),
	set_number_of_evaluated_patients: (numberEvaluated: number | number[] | null)=> ({
		type: Types.SET_NUMBER_OF_EVALUATED_PATIENTS,
		payload: numberEvaluated,
	}),
	request_evaluated_patients: ()=> ({
		type: Types.REQUEST_EVALUATED_PATIENTS,
	}),
	set_dino_battery: (battery: number) => ({
		type: Types.DINO_BATTERY,
		payload: battery,
	}),
	set_bluetooth_result_hand_grip: (battery: number) => ({
		type: Types.RESULT_BLUETOOTH_HAND_GRIP,
		payload: battery,
	}),
};
