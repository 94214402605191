import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import buttonClose from "../../assets/icons/buttonCloseBlack.svg";
import IconWarning from "../../assets/icons/status_warning.svg";
import { TextsProps } from "../../database/txt.interface";
import { ContainerModalExcluir } from "../../styles/styles_modais_excluir";
import { AppActions } from "../../store/ducks/app";
import { P2Desk } from "../../styles/styles_text";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalFimDescanso: React.FC = () => {
	const dispatch = useDispatch();
	const classes = useStyles();

	// comunica com redux
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const is_open_modal_fim_descanso = useSelector(
		(state: any) => state.app.is_open_modal_fim_descanso
	);

	const ModalClose = () => {
		dispatch(AppActions.set_open_modal_fim_descanso(false));
	};

	const Continuar = () => {
		console.log("continuar")
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open_modal_fim_descanso}
			onClose={ModalClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open_modal_fim_descanso}>
				<ContainerModalExcluir>
					<img
						className="close"
						src={buttonClose}
						onClick={ModalClose}
						alt="Close"
					/>
					<img className="icon_warning" src={IconWarning} alt="" />
					<div className="title">
						<div className="containerFrases">
							<p className="QtdPacientes">
								{" "}
								Deseja escolher o próximo exercício para continuar?
							</p>
						<P2Desk>O tempo de descanso foi concluído</P2Desk>
						</div>
					</div>
					<div className="buttons"> 
						<button onClick={Continuar} style={{border: "2px, solid, #00D084"}} className="button excluir">
							Sim
						</button>
						<button className="button cancelar" onClick={ModalClose}>
							{current_language?.pagamento_cancelar}
						</button>
					</div>
				</ContainerModalExcluir>
			</Fade>
		</Modal>
	);
};

export default ModalFimDescanso;
