import { push } from "connected-react-router";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { UserProps } from "../../interfaces/pacientes.interface";
import { persistStorage, removeStorage } from "../../utils/base_async_storage";
import {
	TypesErrorModal,
	TypesErrorRequest,
	TypesModalAviso,
} from "../../utils/types";
import history from "../../routes/history"

import { AppActions } from "../ducks/app";
import { AuthActions, Types } from "../ducks/auth";
import { ConfigsActions } from "../ducks/configs";
import { DashBoardActions } from "../ducks/dashboard";
import { FinancialActions } from "../ducks/financial";
import { getTimeExecFunction, getTimeout } from '../../utils/funcs';
import * as Sentry from "@sentry/react";
import { TextsProps } from "../../database/txt.interface";

function* logout() {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const current_language: TextsProps = yield select(
		(state: any) => state.configs.current_language
	);
	const dat = {
		ID_USUARIO: user_logado?.ID_USUARIO,
		TOKEN: user_logado?.token,
	};

	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			await fetch(
				`${process.env.REACT_APP_BASE_URL}acesso/acesso_logout.php`,
				requestOptions
			);
		} catch (e) {
			throw e;
		}
	}
	
	yield call(apiCall);
	yield call(removeStorage);
	yield put(AppActions.set_option_menu(false, current_language?.menu_lateral_inicio))
	yield put(AppActions.set_is_first_load(true))
	history.push('login');
}

function* authentication({ payload: { payload, isRequest } }: any): any {
	const TimeInitial = new Date().getTime();
	const current_language: any = yield select(
		(state: any) => state.configs.current_language
	);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(payload),
			};

			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}acesso/acesso_login.php`,
				requestOptions
			);
			const response = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);

		const config_servicos = yield select(
			(state: any) => state.configs.config_servicos
		);

		if (data?.status == TypesErrorRequest.SUCCESS) {
			yield call(persistStorage, {
				res: {
					...data.user,
					USUARIO_SENHA: payload?.USUARIO_SENHA,
				},
				tag: "auth",
			});

			yield put(
				AuthActions.auth_success({
					...data.user,
					USUARIO_SENHA: payload?.USUARIO_SENHA,
				})
			);

			if (isRequest) {
				yield put(push("/dashboard"));
				yield put(ConfigsActions.config_request());
				yield put(DashBoardActions.paciente_request());
				yield put(DashBoardActions.mais_visitados_request());
				yield put(DashBoardActions.avaliadores_request());
				yield put(DashBoardActions.etiquetas_request());
				yield put(DashBoardActions.blog_request());
				yield put(FinancialActions.set_planos_itens());
				yield put(DashBoardActions.notificacoes_request());
				yield put(ConfigsActions.block_dino_request());
				yield put(FinancialActions.financial_request(config_servicos?.SERVICO_IUGU, config_servicos?.SERVICO_SUPERLOGICA));
				yield put(AuthActions.edit_user_profile(data?.user))
			}
		} else {
				let message = "";
	
				if (data.code == -1) {
					message = current_language.login_request_error;
				}
				if (data.code == 1) {
					message = current_language.login_request_error_email;
				}
				if (data.code == 2) {
					message = current_language.login_request_error_validade;
				}
				if (data.code == 3) {
					message = current_language.login_request_error_inadim;
				}
				if (data.code == 4) {
					if(user_logado?.token) yield call(logout);
					message = current_language.login_request_error_new_password;
				}
				if (data.code == 5) {
					yield call(logout);
					message = current_language.deleted_account;
				}
				yield put(
					AppActions.set_modal_visible({
						type: TypesErrorModal.FAIL,
						message: message,
						redirect: data.code == 4 && '/recuperar_senha' || ""
					})
				);
		}
	} catch (e) {
		yield put(AuthActions.cancel_request());
		Sentry.captureMessage("CAIU NO CATCH: acesso_login.php", "error");
	} finally {
		yield put(AuthActions.cancel_request());
	}
	const TimeFinal = new Date().getTime()
	
	// getTimeExecFunction(TimeInitial, TimeFinal, 'Function_Authentication');
	getTimeout(TimeInitial, TimeFinal, "TIMEOUT_Function_Authentication",user_logado?.ID_USUARIO)
}

function* notification({ payload: { type, email } }: any): any {
	const current_language = yield select(
		(state: any) => state.configs.current_language
	);
	const language = yield select((state: any) => state.configs.language);


	const dat = {
		USUARIO_EMAIL: email,
		txEmailTipo: type,
		txIdioma: language.toLowerCase(),
	};

	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}acesso/acesso_emails.php`,
				requestOptions
			);
			const response = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		if (data.status == TypesErrorRequest.FAIL && data.code == 1) {
			const message = current_language?.forgot_request_error_email;
			yield put(
				AppActions.set_modal_visible({
					type: TypesModalAviso.WARNING,
					message: message,
				})
			);
		} else {
			
			yield put (AppActions.set_Modal_Register_Success(
				true,
				current_language?.email_enviado_sucesso,
				current_language?.email_redefinicao_senha
			))
			yield put(AuthActions.cancel_request_notification());
			yield put(push("/login"));
		}
	} catch (e) {
		Sentry.captureMessage("CAIU NO CATCH: acesso_cadastro.php", "error");
	} finally {
		yield put(AuthActions.cancel_request_notification());
	}
}

export function* AuthSaga(): any {
	return yield all([
		takeLatest(Types.AUTH_REQUEST, authentication),
		takeLatest(Types.AUTH_LOGOUT, logout),
		takeLatest(Types.NOTIFICATION_REQUEST, notification),
	]);
}
