import React from "react";
import { useSelector } from "react-redux";
import { Container, InfoText, QualityLifeContent, Row } from "./styles";
import SpectrumLight from "./SpectrumLight";
import { QualityLifeProps } from "../../../interfaces/anamnese.interface";

const GraficoOutrosAcpectos: React.FC = () => {
	const qualidade_de_vida = useSelector(
		(state: any) => state.pac_aval.perfil_anamnese_array.qualidade_vida
	);

	function _RenderQuality(fator: QualityLifeProps) {
		return (
			<Container key={fator.TITULO_FATOR}>
				<QualityLifeContent >
					<Row>
						<InfoText>
							{fator.TITULO_FATOR}
							{":"} {fator.FATOR_VALOR}
						</InfoText>
					</Row>
					<SpectrumLight factor={fator} />
				</QualityLifeContent>
			</Container>
		);
	}

	if (qualidade_de_vida?.FATORES.length > 1) {
		return qualidade_de_vida?.FATORES?.map((fator: QualityLifeProps) =>
			_RenderQuality(fator)
		);
	} else {
		return <></>;
	}
};

export default GraficoOutrosAcpectos;
