import DOMParserReact from "dom-parser-react";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextsProps } from "../../../database/txt.interface";
import {
	QuestionnaireComplementProps,
	QuestionnaireProps,
} from "../../../interfaces/pacientes.interface";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import {
	TypeQuestionnaireModoProps,
	TypeQuestionnaireProps,
	TypesMenuInfoProps,
	TypesQuestionnaire,
} from "../../../utils/types";
import BotoesTypes from "./Components/BotoesTypes";
import CheckBoxButton from "./Components/CheckBoxButton";
import InputCheckbox from "./Components/InputCheckbox";
import InputPhoto from "./Components/InputPhoto";
import Nota_adicional from "./Components/Nota_adicional";
import Progress from "./Components/Progress";
import RadioButton from "./Components/RadioButton";
import RadioButtonTipoCorpo from "./Components/RadioButtonTipoCorpo";
import Select from "./Components/Select";
import TermografiaAplicada from "./Components/TermografiaAplicada";
import TextArea from "./Components/TextArea";
import TextInput from "./Components/TextInput";
import Loading from "../../DashPaciente/Loading";
import {
	Body,
	Botoes,
	Container,
	Content,
	ContentBody,
	Description,
	ErroObrigatoriedade,
	Footer,
	Header,
	InicioFim,
	Question,
	Title,
} from "./styles";
import { P2Desk } from "../../../styles/styles_text";
import styled from "styled-components";
import { AnamneseActions } from "../../../store/ducks/anamnese";

interface TypeQuestionaryProps {
	Type: string;
}

const Questionario_progress: React.FC<TypeQuestionaryProps> = ({ Type }) => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const questionario_array = useSelector(
		(state: any) => state.pac_aval.questionario_array
	);

	const questionarioAsync: any = useSelector(
		(state: any) => state.pac_aval.questionario_async
	);

	const questionario_request = useSelector(
		(state: any) => state.pac_aval.questionario_request
	);

	const continuar_questionario = useSelector(
		(state: any) => state.pac_aval.continuar_questionario
	);

	const questionario = useSelector((state: any) => state.pac_aval.questionario);

	const checked = useSelector((state: any) => state.pac_aval.checkbox_sf_36);

	const gerar_novo = useSelector((state: any) => state.pac_aval.gerar_novo);

	const [question, setQuestion] = useState(0);
	const [questionCont, setQuestionCont] = useState(0);
	const [quest_array_principais, setQuest_array_principais] = useState([]);
	const [parte, setParte] = useState(TypeQuestionnaireModoProps?.telas_inicio);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let aproved: any = false;
		if (!gerar_novo) {
			if (!!continuar_questionario?.respostas?.ANAMNESE_TIPO_CONCLUIDO) {
				if (
					continuar_questionario?.respostas?.ANAMNESE_TIPO_CONCLUIDO[0] === "N"
				)
					aproved = true;
			}

			if (!!continuar_questionario?.respostas?.ANAMNESE_AVALIACAO_CONCLUIDO) {
				if (
					continuar_questionario?.respostas?.ANAMNESE_AVALIACAO_CONCLUIDO[0] ===
					"N"
				) {
					aproved = true;
				}
			}

			if (!!continuar_questionario?.respostas?.SF_QUALIDADE_CONCLUIDO) {
				if (
					continuar_questionario?.respostas?.SF_QUALIDADE_CONCLUIDO[0] === "N"
				) {
					aproved = true;
				}
			}
		}

		if (aproved) {
			const id_resposta = continuar_questionario.respostas.ID_RESPOSTA;
			dispatch(PacAvalActions.set_id_resposta(id_resposta));
			setParte(TypeQuestionnaireModoProps.questionario);
			const array: any = [];

			continuar_questionario?.questionario?.map((e: QuestionnaireProps) => {
				if (
					continuar_questionario?.respostas[e?.QUESTIONARIO_LABEL] !== undefined
				) {
					if (
						continuar_questionario?.respostas[e?.QUESTIONARIO_LABEL][0] !==
							null &&
						continuar_questionario?.respostas[e?.QUESTIONARIO_LABEL][0] !==
							"" &&
						continuar_questionario?.respostas[e?.QUESTIONARIO_LABEL][0] !==
							undefined
					) {
						const number_ordem =
							parseInt(e?.QUESTIONARIO_ORDEM?.slice(0, 2)) + 1;
						setQuestion(
							number_ordem >= quest_array_principais.length
								? quest_array_principais.length - 1
								: number_ordem
						);

						const new_resposta: any = [];

						continuar_questionario.respostas[e?.QUESTIONARIO_LABEL]?.map(
							(r: any) => {
								if (!isNaN(r)) {
									if (r % 1 === 0) {
										new_resposta.push(parseInt(r));
									} else {
										new_resposta.push(parseFloat(r));
									}
								} else {
									new_resposta.push(r);
								}
							}
						);
						array.push({
							QUESTIONARIO_LABEL: e?.QUESTIONARIO_LABEL,
							QUESTIONARIO_RESPOSTA: new_resposta,
						});
					}

					e?.QUESTIONARIO_PERGUNTA_COMPLEMENTO?.map(
						(i: QuestionnaireComplementProps) => {
							if (
								continuar_questionario?.respostas[i?.QUESTIONARIO_LABEL][0] !==
									null &&
								continuar_questionario?.respostas[i?.QUESTIONARIO_LABEL][0] !==
									"" &&
								continuar_questionario?.respostas[i?.QUESTIONARIO_LABEL][0] !==
									undefined
							) {
								array.push({
									QUESTIONARIO_LABEL: i?.QUESTIONARIO_LABEL,
									QUESTIONARIO_RESPOSTA:
										continuar_questionario.respostas[i?.QUESTIONARIO_LABEL],
								});
							}
						}
					);
				}
			});

			dispatch(PacAvalActions.set_array_respostas(array));
			dispatch(PacAvalActions.set_array_resposta(array));
			setQuest_array_principais(
				continuar_questionario?.questionario?.filter(
					(e: QuestionnaireProps) =>
						e?.QUESTIONARIO_ORDEM?.substring(3, 5) === "00"
				)
			);
		}
	}, [continuar_questionario]);

	useEffect(() => {
		if (!questionario_request) {
			setQuest_array_principais(
				questionario_array?.questionario?.filter(
					(e: QuestionnaireProps) =>
						e?.QUESTIONARIO_ORDEM?.substring(3, 5) === "00"
				)
			);
		}
	}, [questionario_array, questionario_request]);

	const avancarRadioButton = () => {
		if (checked) {
			setTimeout(() => {
				Type === TypesQuestionnaire.SF_36 &&
				question < quest_array_principais.length - 1 ? (
					setQuestion(question + 1)
				) : (
					<>
						{setParte(TypeQuestionnaireModoProps.telas_final)}
						{setQuestionCont(0)}
					</>
				);
			}, 100);
		}
	};

	const setMenu = () => {
		dispatch(DashBoardActions.set_select_info(TypesMenuInfoProps.BTN_MENU));
		dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.MENU));
	};

	const render_complemento = (array: any, ordem: string) => {
		const quest = array.questionario.filter(
			(e: QuestionnaireProps) =>
				e?.QUESTIONARIO_ORDEM !== ordem &&
				e?.QUESTIONARIO_ORDEM?.substring(2, 0) === ordem?.substring(2, 0)
		);
		return (
			<>
				{quest?.map((e: QuestionnaireProps, i: number) => (
					<Body key={i}>
						{e.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_text &&
						e.QUESTIONARIO_PERGUNTA_LINK !== "S" ? (
							<Question>
								<DOMParserReact  source={e.QUESTIONARIO_PERGUNTA} />
							</Question>
						) : (
							e.QUESTIONARIO_PERGUNTA_TIPO !==
								TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_text &&
							e.QUESTIONARIO_PERGUNTA_TIPO !==
								TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
									.input_checkbox &&
							e.QUESTIONARIO_PERGUNTA_TIPO !==
								TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
									.button_upload_arquivo &&
							e.QUESTIONARIO_PERGUNTA_TIPO !==
								TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
									.button_nota_adicional &&
							e.QUESTIONARIO_PERGUNTA !== null && (
								<Question>
									<DOMParserReact source={e.QUESTIONARIO_PERGUNTA} />
								</Question>
							)
						)}
						{e.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
								.radio_button && (
							<RadioButton
								item={e}
								functionItem={() => avancarRadioButton()}
								type={Type}
								hasComplement={e.QUESTIONARIO_PERGUNTA_LINK === "S"}
							/>
						)}
						{e.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.checkbox && (
							<CheckBoxButton item={e} />
						)}
						{e.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_text &&
						e.QUESTIONARIO_PERGUNTA_LINK !== "S" ? (
							<>
								<TextInput item={e} />
							</>
						) : (
							e.QUESTIONARIO_PERGUNTA_TIPO ===
								TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
									.input_text && (
								<Content>
									<ContentBody>
										<Question>
											<DOMParserReact source={e.QUESTIONARIO_PERGUNTA} />
										</Question>
										<TextInput item={e} />
									</ContentBody>
									{e?.QUESTIONARIO_PERGUNTA_COMPLEMENTO?.map(
										(e: QuestionnaireProps, i: number) => {
											return (
												<ContentBody key={i}>
													<Question>
														<DOMParserReact source={e.QUESTIONARIO_PERGUNTA} />
													</Question>
													<TextInput item={e} />
												</ContentBody>
											);
										}
									)}
								</Content>
							)
						)}
						{e.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
								.input_text_disable && <TextInput item={e} isDisabled={true} />}
						{e.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
								.input_text_area && <TextArea item={e} />}
						{e.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
								.input_select && <Select item={e} />}
						{e.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
								.button_nota_adicional && <Nota_adicional item={e} />}
						{e.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
								.input_checkbox && <InputCheckbox item={e} />}
						{e.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
								.button_upload_arquivo && <InputPhoto item={e} />}
						{e?.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
								.input_aparencia_como_se_ve && (
							<RadioButtonTipoCorpo item={e} />
						)}
						{e?.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
								.input_termografia_aplicada && <TermografiaAplicada item={e} />}
					</Body>
				))}
			</>
		);
	};

	const render_question = (array: any, question: number) => {
		const quest: QuestionnaireProps = array.find(
			(e: any, i: number) => i === question
		);
		if (question === quest_array_principais.length)
			setParte(TypeQuestionnaireModoProps?.telas_inicio);
		return (
			<>
				<Body>
					{quest?.QUESTIONARIO_TITULO !== null && (
						<Title>
							<DOMParserReact source={quest?.QUESTIONARIO_TITULO} />
						</Title>
					)}
					{quest?.QUESTIONARIO_DESCRICAO && (
						<Description>
							<DOMParserReact source={quest?.QUESTIONARIO_DESCRICAO} />
						</Description>
					)}

					{quest?.QUESTIONARIO_PERGUNTA_TIPO ===
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_text &&
					quest?.QUESTIONARIO_PERGUNTA_LINK !== "S" ? (
						<Question>
							<DOMParserReact source={quest?.QUESTIONARIO_PERGUNTA} />
						</Question>
					) : (
						quest?.QUESTIONARIO_PERGUNTA_TIPO !==
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_text &&
						quest?.QUESTIONARIO_PERGUNTA_TIPO !==
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
								.input_checkbox &&
						quest?.QUESTIONARIO_PERGUNTA_TIPO !==
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
								.button_upload_arquivo &&
						quest?.QUESTIONARIO_PERGUNTA_TIPO !==
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
								.button_nota_adicional &&
						quest?.QUESTIONARIO_PERGUNTA !== null && (
							<Question>
								<DOMParserReact source={quest?.QUESTIONARIO_PERGUNTA} />
							</Question>
						)
					)}

					{quest?.QUESTIONARIO_PERGUNTA_TIPO ===
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.radio_button && (
						<RadioButton
							item={quest}
							functionItem={() => null}
							type={Type}
							hasComplement={
								quest?.QUESTIONARIO_PERGUNTA_LINK === "S" ? true : false
							}
						/>
					)}
					{quest?.QUESTIONARIO_PERGUNTA_TIPO ===
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.checkbox && (
						<CheckBoxButton item={quest} />
					)}
					{quest?.QUESTIONARIO_PERGUNTA_TIPO ===
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_text &&
					quest?.QUESTIONARIO_PERGUNTA_LINK !== "S" ? (
						<TextInput item={quest} />
					) : (
						quest?.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_text && (
							<Content>
								<ContentBody>
									<Question>
										<DOMParserReact source={quest?.QUESTIONARIO_PERGUNTA} />
									</Question>
									<TextInput item={quest} />
								</ContentBody>
								{quest?.QUESTIONARIO_PERGUNTA_COMPLEMENTO?.map(
									(e: QuestionnaireProps, i: number) => {
										return (
											<ContentBody key={i}>
												<Question>
													<DOMParserReact source={e.QUESTIONARIO_PERGUNTA} />
												</Question>
												<TextInput item={e} />
											</ContentBody>
										);
									}
								)}
							</Content>
						)
					)}
					{quest?.QUESTIONARIO_PERGUNTA_TIPO ===
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
							.input_text_disable && (
						<TextInput item={quest} isDisabled={true} />
					)}
					{quest?.QUESTIONARIO_PERGUNTA_TIPO ===
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
							.input_text_area && <TextArea item={quest} />}
					{quest?.QUESTIONARIO_PERGUNTA_TIPO ===
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO.input_select && (
						<Select item={quest} />
					)}
					{quest?.QUESTIONARIO_PERGUNTA_TIPO ===
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
							.button_nota_adicional && <Nota_adicional item={quest} />}
					{quest?.QUESTIONARIO_PERGUNTA_TIPO ===
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
							.input_checkbox && <InputCheckbox item={quest} />}
					{quest?.QUESTIONARIO_PERGUNTA_TIPO ===
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
							.button_upload_arquivo && <InputPhoto item={quest} />}
					{(quest?.QUESTIONARIO_PERGUNTA_TIPO ===
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
							.input_aparencia_como_se_ve ||
						quest?.QUESTIONARIO_PERGUNTA_TIPO ===
							TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
								.input_aparencia_deseja_ser) && (
						<RadioButtonTipoCorpo item={quest} />
					)}
					{quest?.QUESTIONARIO_PERGUNTA_TIPO ===
						TypeQuestionnaireProps.QUESTIONARIO_PERGUNTA_TIPO
							.input_termografia_aplicada && (
						<TermografiaAplicada item={quest} />
					)}
					{render_complemento(questionario_array, quest?.QUESTIONARIO_ORDEM)}
				</Body>
			</>
		);
	};

	function render_inicio(valor: number) {
		const questionValue = valor < 10 ? `0${valor}` : `${valor}`;
		const array =
			questionario_array?.telas_inicio !== undefined &&
			questionario_array?.telas_inicio.filter(
				(e: any) =>
					e.TELA_ORDEM.substring(2, 0) === questionValue &&
					e.TELA_CONTEUDO_TIPO.substr(0, 2) !== "BT"
			);
		if (array?.length === 0) {
			setParte(TypeQuestionnaireModoProps.questionario);
		}
		return (
			<InicioFim>
				{array?.length > 0 &&
					array?.map((e: any, i: number) => {
						return (
							<div key={`${e?.TELA_ORDEM}${i}`}>
								<p
									className={`${
										e?.TELA_CONTEUDO_TIPO === "TEXT_ITALIC" && "inicioItalico"
									} inicioParagrafo`}
								>
									<DOMParserReact source={e?.TELA_CONTEUDO} />
								</p>
								<br />
							</div>
						);
					})}
			</InicioFim>
		);
	}

	function render_final(valor: number) {
		const questionValue = valor < 10 ? `0${valor}` : `${valor}`;
		const array =
			questionario_array?.telas_final !== undefined &&
			questionario_array?.telas_final.filter(
				(e: any) =>
					e.TELA_ORDEM.substring(2, 0) === questionValue &&
					e.TELA_CONTEUDO_TIPO.substr(0, 2) !== "BT"
			);
	
		return (
			<InicioFim>
				{array?.length > 0 &&
					array?.map((e: any, i: number) => {
						return (
							<div key={`${e?.TELA_ORDEM}${i}`}>
								<p
									className={`${
										e?.TELA_CONTEUDO_TIPO === "TEXT_ITALIC" && "inicioItalico"
									} inicioParagrafo`}
								>
									<DOMParserReact source={e?.TELA_CONTEUDO} />
								</p>
								<br />
							</div>
						);
					})}
			</InicioFim>
		);
	}

	function render_parte(parte: string) {
		if (parte === TypeQuestionnaireModoProps?.telas_inicio) {
			return render_inicio(questionCont);
		}
		if (parte === TypeQuestionnaireModoProps.questionario) {
			return (
				quest_array_principais !== undefined &&
				quest_array_principais.length !== 0 &&
				render_question(quest_array_principais, question)
			);
		}
		if (parte === TypeQuestionnaireModoProps.telas_final) {
			return render_final(questionCont);
		}
	}

	const array_resposta = useSelector(
		(state: any) => state.pac_aval.array_resposta
	);
	const array_respostas = useSelector(
		(state: any) => state.pac_aval.array_respostas
	);

	const proximo = () => {
		if (question < quest_array_principais.length - 1) {
			setQuestion(question + 1);
		} else {
			setParte(TypeQuestionnaireModoProps.telas_final);
			setQuestionCont(0);
		}

		
		dispatch(PacAvalActions.set_questionario_respostas(array_resposta));

		if (
			questionario == "ANAMNESE_V7" ||
			questionario == "ANAMNESE_CLINICO_V7"
		) {
			dispatch(AnamneseActions.salvar_questoes_v7());
		}
		//setLabelValid(false);
		// } else {
		// 	setLabelValid(true);
		// }
	};

	const voltar = () => {
		if (
			question === quest_array_principais.length - 1 &&
			parte === TypeQuestionnaireModoProps.telas_final
		) {
			setQuestion(quest_array_principais.length - 1);
			setParte(TypeQuestionnaireModoProps.questionario);
		} else if (question > 0) {
			setQuestion(question - 1);
		} else {
			setParte(TypeQuestionnaireModoProps?.telas_inicio);
			setQuestionCont(0);
		}
	};

	
	const get_comp_complemento = (exist: any, arr: any) => {
		if (!!exist && arr.QUESTIONARIO_PERGUNTA_LINK === "S") {
			const condicao = exist.QUESTIONARIO_RESPOSTA.find(
				(c: any) => c == arr.QUESTIONARIO_PERGUNTA_CONDICAO
			);
			if (condicao != null && condicao != undefined) {
				arr?.QUESTIONARIO_PERGUNTA_COMPLEMENTO?.map(
					(comp: QuestionnaireComplementProps) => {
						if (
							comp?.QUESTIONARIO_LABEL?.substr(0, 2) !== "BT" &&
							comp.QUESTIONARIO_PERGUNTA_TIPO !== "label"
						) {
							const exist = array_respostas.find(
								(e: any) => comp.QUESTIONARIO_LABEL === e.QUESTIONARIO_LABEL
							);
						
							if (comp.QUESTIONARIO_PERGUNTA_LINK === "S") {
								if (comp.QUESTIONARIO_PERGUNTA_CONDICAO === "-1") {
									comp?.QUESTIONARIO_PERGUNTA_COMPLEMENTO?.map((cc: any) => {
										const exist = array_respostas.find(
											(e: any) => cc.QUESTIONARIO_LABEL === e.QUESTIONARIO_LABEL
										);
										
									});
								} else {
									const condicao = exist.QUESTIONARIO_RESPOSTA.find(
										(c: any) => c == comp.QUESTIONARIO_PERGUNTA_CONDICAO
									);
									if (condicao != null && condicao != undefined) {
										comp?.QUESTIONARIO_PERGUNTA_COMPLEMENTO?.map((cc: any) => {
											const exist = array_respostas.find(
												(e: any) =>
													cc.QUESTIONARIO_LABEL === e.QUESTIONARIO_LABEL
											);
										});
									}
								}
							}
						}
					}
				);
			}
		}
	};

	const get_complemento = (array: any, ordem: string) => {
		const quest = array.questionario.filter(
			(e: QuestionnaireProps) =>
				e?.QUESTIONARIO_ORDEM !== ordem &&
				e?.QUESTIONARIO_ORDEM?.substring(2, 0) === ordem?.substring(2, 0)
		);
		return quest;
	};

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 300);
	}, [question]);

	return (
		<Container>
			{!!questionario_request ? (
				<P2Desk>Carregando...</P2Desk>
			) : (
				<>
					<Header>
						<Progress
							questionAsync={questionarioAsync}
							actualNumber={question + 1}
							totalNumber={quest_array_principais?.length}
						/>
					</Header>
					{loading ? <P2Desk>Carregando...</P2Desk> : render_parte(parte)}
				
					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "end",
							paddingRight: "2rem",
						}}
					>
						<BotaoPular onClick={proximo}>Pular</BotaoPular>
					</div>
					<Footer>
						<Botoes>
							{parte === TypeQuestionnaireModoProps?.telas_inicio ? (
								<button
									className="btnComPreenchimento"
									onClick={() => setQuestionCont(questionCont + 1)}
								>
									{current_language?.botao_iniciar_protocolo}
								</button>
							) : (
								<>
									{parte !== TypeQuestionnaireModoProps.telas_final && (
										<button
											className={`${
												Type === TypesQuestionnaire.SF_36
													? "btnComPreenchimento"
													: "btnSemPreenchimento"
											}`}
											onClick={() => voltar()}
										>
											{current_language?.voltar}
										</button>
									)}
									{Type == TypesQuestionnaire.SF_36 &&
										parte !== TypeQuestionnaireModoProps.telas_final && (
											<button
												style={
													!checked
														? {
																backgroundColor: "white",
																color: "#A5ABB3",
																borderWidth: 2,
																borderColor: "#A5ABB3",
														  }
														: {}
												}
												className={`${
													Type === TypesQuestionnaire.SF_36
														? "btnComPreenchimento"
														: "btnSemPreenchimento"
												}`}
												onClick={() => avancarRadioButton()}
											>
												{current_language?.menu_lateral_botao_avancar}
											</button>
										)}
								</>
							)}
							{parte === TypeQuestionnaireModoProps.telas_final &&
								questionario_array.telas_final?.map((e: any, index: number) =>
									e.TELA_CONTEUDO_TIPO === "BT_PERFIL_PACIENTE" ? (
										<button
											className="btnFinal"
											onClick={() => setMenu()}
											key={index}
										>
											{e.TELA_CONTEUDO.split("<br>")?.map(
												(ee: any, i: number) => {
													return <p key={i}>{ee}</p>;
												}
											)}
										</button>
									) : (
										e.TELA_CONTEUDO_TIPO === "BT_SF_36" && ""
									)
								)}
							{Type !== TypesQuestionnaire.SF_36 &&
								parte !== TypeQuestionnaireModoProps?.telas_inicio &&
								parte !== TypeQuestionnaireModoProps.telas_final && (
									<button
										className="btnComPreenchimento"
										onClick={() => proximo()}
									>
										{current_language?.proximo_button}
									</button>
								)}
							{parte === TypeQuestionnaireModoProps.telas_final && (
								<BotoesTypes
									btns={questionario_array?.telas_final}
									back={voltar}
									questionAsync={questionarioAsync}
								/>
							)}
						</Botoes>
					</Footer>
				</>
			)}
		</Container>
	);
};

export const BotaoPular = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 36px;
	color: #323d6e;
	text-decoration-line: underline;
`;

export default memo(Questionario_progress);
