import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonClose.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { AppActions } from "../../../store/ducks/app";
import {
	ContainerInput,
	Title,
	Option,
	ContainerAddSignature,
	Label,
} from "./styles";
import { ReactComponent as Close } from "../../../assets/kinologyIcons/svg/close.svg";
import { ReactComponent as Trash } from "../../../assets/kinologyIcons/svg/trash.svg";
import { ReactComponent as AddPhoto } from "../../../assets/kinologyIcons/svg/add_photo_alternate.svg";
import { Colors } from "../../../styles/colors";
import Text from "../../../components/Texts/Text";
import InputTextEntryForm from "../../../components/Inputs/inputForm";
import FormSubmiteButton from "../../Buttons/FormSubmitButton";
import { useForm } from "react-hook-form";
import Form from "../../Form";
import { SenhaActions } from "../../../store/ducks/senha";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { AmplitudeLog } from "../../../utils/amplitude";
import { AssinaturaActions } from "../../../store/ducks/assinatura";
import SetFoto from "../../../assets/kinologyIcons/svg/setFoto.svg";
import HoverPhoto from "../../../assets/kinologyIcons/svg/hoverSetFoto.svg";
import PressSetPhoto from "../../../assets/kinologyIcons/svg/pressSetFoto.svg";
import SucessSetPhoto from "../../../assets/kinologyIcons/svg/SetFotoSucesso.svg";
import { Container } from "../../../styles/styles_modais_padroes";
import { H2Desk, H3Desk, P2Desk } from "../../../styles/styles_text";
import { Scroll } from "../../../styles/global";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalAdicionarAssinatura: React.FC = () => {
	const dispatch = useDispatch();
	const methods = useForm();
	const inputRef = useRef<HTMLInputElement>(null);

	const [img, setImg] = useState<any>("");
	const [image, setImage] = useState<string>(SetFoto);
	const [imageName, setImageName] = useState<string>("");
	const [click, setClick] = useState(false);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const img_assinatura: any = useSelector(
		(state: any) => state.assinatura.assinatura
	);

	const classes = useStyles();
	const is_open_modal_adicionar_assinatura = useSelector(
		(state: any) => state.app.is_open_modal_adicionar_assinatura
	);

	const onSubmit = (data: any) => {
		const dat_request = {
			CERTIFICACAO_ASSINATURA_BASE64: img_assinatura,
			CERTIFICACAO_CONSELHO: data.conselho,
			CERTIFICACAO_CONSELHO_NUMERO: data.nConselho,
		};
		AmplitudeLog("edit_assinatura_salva", {
			assinatura: dat_request,
		});
		dispatch(AssinaturaActions.edit_assinatura(dat_request));
	};

	const handleImageProfile = (imageName: string) => {
		const typeImages: any = {
			SetPhoto: SetFoto,
			HoverPhoto: HoverPhoto,
			PressSetPhoto: PressSetPhoto,
			SucessSetPhoto: SucessSetPhoto,
		};
		setImage(typeImages[imageName]);
		setImageName(imageName);
	};

	useEffect(() => {
		if (click && inputRef.current) {
			inputRef.current.click();
			setClick(false);
		}
	}, [click]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_modal_adicionar_assinatura}
				closeAfterTransition
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_modal_adicionar_assinatura}>
					<Container style={{ width: "30vw" }}>
						<Close
							onClick={() => {
								dispatch(AppActions.set_modal_adicionar_assinatura(false));
							}}
							style={{
								position: "absolute",
								right: 32,
								top: 22,
								cursor: "pointer",
							}}
						/>
						<H3Desk>{current_language?.adicionar_assinatura}</H3Desk>
						<Scroll>
							<Form
								methods={methods}
								onSubmit={(data) => {
									onSubmit(data);
								}}
								style={{
									width: "100%",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<ContainerInput>
									<InputTextEntryForm
										type="text"
										title={current_language?.conselho + "*"}
										placeholder={current_language?.conselho}
										fontFamily="NotoSans-SemiBold"
										fontWeight={600}
										typeInputName="conselho"
										marginTop={32}
										width={100}
										fontSize={1}
									/>
									<InputTextEntryForm
										type="text"
										title={current_language?.n_conselho + "*"}
										placeholder={"000000000"}
										fontFamily="NotoSans-SemiBold"
										fontWeight={600}
										marginTop={32}
										typeInputName="nConselho"
										width={100}
										fontSize={1}
									/>
								</ContainerInput>
								<ContainerAddSignature
									imageName={imageName}
									img={img_assinatura}
									onMouseEnter={() => handleImageProfile("HoverPhoto")}
									onMouseLeave={() => handleImageProfile("SetPhoto")}
									onMouseDown={() => handleImageProfile("PressSetPhoto")}
									onClick={() =>
										dispatch(
											AppActions.set_modal_foto_edicao_perfil_assinatura(
												true,
												current_language?.editar_assinatura
											)
										)
									}
								>
									<Label className="labelForInput">
										<img
											src={img_assinatura ? SucessSetPhoto : image}
											className="imgForInput"
										/>
										<P2Desk>
											{!img_assinatura &&
												current_language?.adicionar_assinatura}
										</P2Desk>
										<Text
											title={
												img
													? current_language?.foto_inserida
													: current_language?.Insira_foto
											}
											fontFamily="NotoSans-Regular"
											fontSize={1.25}
											textColor={Colors.primary_blue_k_main}
											marginTop={8}
										/>
									</Label>
								</ContainerAddSignature>
								<FormSubmiteButton
									type="submit"
									onClick={() => methods.trigger()}
									disabled={
										Object.values(methods.watch()).every(
											(item: string) => item != ""
										) && img_assinatura != ""
											?  false
											: true
									}
									marginTop={40}
									fontFamily="Kanit-Medium"
									typeButtonComponent="primary"
									activityIndicator={false}
									title={current_language?.menu_lateral_botao_salvar}
								/>
							</Form>
						</Scroll>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalAdicionarAssinatura;
