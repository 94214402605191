import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect, memo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import KeyGray from "../../../assets/icons/keyGray.svg";
import NotVisible from "../../../assets/icons/notvisible.svg";
import Visible from "../../../assets/icons/visible.svg";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { SenhaActions } from "../../../store/ducks/senha";
import { Conteiner, BodyEdite } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";

const AlterarSenha: React.FC = () => {
	const [passwordShown1, setPasswordShown1] = useState(false);
	const [passwordShown2, setPasswordShown2] = useState(false);
	const [passwordShown3, setPasswordShown3] = useState(false);
	const togglePasswordVisiblity1 = () => {
		setPasswordShown1(passwordShown1 ? false : true);
	};
	const togglePasswordVisiblity2 = () => {
		setPasswordShown2(passwordShown2 ? false : true);
	};
	const togglePasswordVisiblity3 = () => {
		setPasswordShown3(passwordShown3 ? false : true);
	};

	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	//Login
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm();

	const [aprovado, setAprovado] = useState(false);

	const onSubmit = (data: any) => {
		setAprovado(true);
		const dat_request = {
			USUARIO_EMAIL: user_logado.USUARIO_EMAIL,
			txSenhaAtual: data.senha,
			txSenhaNova: data.confirmeSenha,
		};
		dispatch(SenhaActions.edit_senha(dat_request));
	};
	//console.log(errors);

	useEffect(() => {
		if (is_open_toast === true) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	return (
		<Conteiner>
			{is_open_toast && <ToastAlert />}
			<BodyEdite onSubmit={handleSubmit(onSubmit)}>
				<div className="coluna">
					<h3>{current_language?.menu_lateral_alterar_senha}</h3>
				</div>

				<div className="coluna">
					<div className="globalInput">
						<div className="iconIput">
							<img src={KeyGray} alt="key" className="icon" />
						</div>
						<input
							type={passwordShown1 ? "text" : "password"}
							id="senha"
							placeholder={current_language?.alterar_senha_senha_atual}
							className="input"
							{...register("senha", {
								required: `${current_language?.login_input_senha}`,
								minLength: {
									value: 8,
									message: current_language?.alterar_senha_minimo,
								},
							})}
						/>
						<button
							type="button"
							id="eye"
							className="eyeButton"
							onClick={togglePasswordVisiblity1}
						>
							<img src={passwordShown1 ? NotVisible : Visible} alt="eye" />
						</button>
					</div>
					<div className="divhr">
						<hr className={errors.senha ? "hr hrcor" : "hr"} />
						{errors.senha && (
							<div className="error">{errors.senha.message}</div>
						)}
					</div>
				</div>

				<div className="coluna">
					<div className="globalInput">
						<div className="iconIput">
							<img src={KeyGray} alt="key" className="icon" />
						</div>
						<input
							type={passwordShown2 ? "text" : "password"}
							id="novaSenha"
							placeholder={current_language?.alterar_senha_nova_senha}
							className="input"
							{...register("novaSenha", {
								required: `${current_language?.login_input_senha}`,
								minLength: {
									value: 8,
									message: current_language?.alterar_senha_minimo,
								},
								pattern: {
									value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
									message: current_language?.password_requiriments
								}
							})}
						/>
						<button
							type="button"
							id="eye"
							className="eyeButton"
							onClick={togglePasswordVisiblity2}
						>
							<img src={passwordShown2 ? NotVisible : Visible} alt="eye" />
						</button>
					</div>
					<div className="divhr">
						<hr className={errors.novaSenha ? "hr hrcor" : "hr"} />
						{errors.novaSenha && (
							<div className="error">{errors.novaSenha.message}</div>
						)}
					</div>
				</div>

				<div className="coluna">
					<div className="globalInput">
						<div className="iconIput">
							<img src={KeyGray} alt="key" className="icon" />
						</div>
						<input
							type={passwordShown3 ? "text" : "password"}
							id="confirmeSenha"
							placeholder={current_language?.alterar_senha_confirme_senha}
							className="input"
							{...register("confirmeSenha", {
								required: `${current_language?.login_input_senha}`,
								minLength: {
									value: 8,
									message: current_language?.alterar_senha_minimo,
								},
								pattern: {
									value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
									message: current_language?.password_requiriments
								},
								validate: (value) =>
									value === getValues("novaSenha") ||
									`${current_language?.confirmar_senha}`,
							})}
						/>
						<button
							type="button"
							id="eye"
							className="eyeButton"
							onClick={togglePasswordVisiblity3}
						>
							<img src={passwordShown3 ? NotVisible : Visible} alt="eye" />
						</button>
					</div>
					<div className="divhr">
						<hr className={errors.confirmeSenha ? "hr hrcor" : "hr"} />
						{errors.confirmeSenha && (
							<div className="error">{errors.confirmeSenha.message}</div>
						)}
					</div>
				</div>
				<div className="inf">
					<button 
						onClick={()=> AmplitudeLog('edit_senha_salva')}
						type="submit" id="salvar" 
						className="salve"
					>
						{aprovado === true ? (
							<CircularProgress color="inherit" />
						) : (
							current_language?.pagamento_salvar
						)}
					</button>
				</div>
			</BodyEdite>
		</Conteiner>
	);
};

export default memo(AlterarSenha);
