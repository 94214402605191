const EVENTS_KEY = "@elastic";

export const persistStorage = async (data: any) => {
	try {
		return localStorage.setItem(
			`${EVENTS_KEY}:${data.tag}`,
			JSON.stringify(data.res)
		);
	} catch (e) {
		console.warn("localStorage:setItem", e);
	}
};

export const loadStorage = async <T>(tag: any): Promise<T | null> => {
	try {
		const value = await localStorage.getItem(`${EVENTS_KEY}:${tag}`);
		if (value !== null) {
			return JSON.parse(value);
		}
	} catch (e) {
		console.warn("localStorage:getItem", e);
	}
	return Promise.resolve(null);
};

export const removeStorage = async <T>(): Promise<T | null> => {
	try {
		await localStorage.clear();
	} catch (e) {
		console.warn("localStorage:getItem", e);
	}
	return Promise.resolve(null);
};

export const removeItemStorage = async <T>(tag: string): Promise<T | null> => {
	try {
		await localStorage.removeItem(`${EVENTS_KEY}:${tag}`);
	} catch (e) {
		console.warn("localStorage:removeItem", e);
	}
	return Promise.resolve(null);
};
