import React, { memo, useEffect, useState } from "react";
import { Nota_add } from "./styles";
import { QuestionnaireProps } from '../../../../../interfaces/pacientes.interface';
import { useSelector } from 'react-redux';
import { Question } from '../../styles';
import TextArea from '../TextArea';
import DOMParserReact from 'dom-parser-react';

interface questionProps {
	item: QuestionnaireProps,
}

const Nota_adicional: React.FC<questionProps> = ({ item }) => {
	const array_respostas = useSelector(
		(state: any) => state.pac_aval.array_respostas
	);
	const [isComplement, setIsComplement] = useState(false)

	useEffect(() => {
		item?.QUESTIONARIO_PERGUNTA_COMPLEMENTO?.map((i: QuestionnaireProps) => {
			const valor = array_respostas?.filter((e: any) => e?.QUESTIONARIO_LABEL === i?.QUESTIONARIO_LABEL);
			setIsComplement(valor.length === 1)
		})
	}, [])

	useEffect(() => {
		item?.QUESTIONARIO_PERGUNTA_COMPLEMENTO?.map((i: QuestionnaireProps) => {
			const valor = array_respostas?.filter((e: any) => e?.QUESTIONARIO_LABEL === i?.QUESTIONARIO_LABEL);
			setIsComplement(valor.length === 1)
		})
	}, [array_respostas])

	function complemento(item: QuestionnaireProps) {
		return (
			<>
				<Question><DOMParserReact source={item.QUESTIONARIO_PERGUNTA} /></Question>
				<TextArea
					item={item}
				/>
			</>
		);
	}

	return (
		<>
			{
				isComplement ?
					item.QUESTIONARIO_PERGUNTA_COMPLEMENTO?.map((e: QuestionnaireProps, i: number) => {
						return <div key={i}>{complemento(e)}</div>
					})
					:
					<Nota_add onClick={() => setIsComplement(!isComplement)}>
						<svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M28.9251 30.7547H6.54707V8.37665H19.9942L22.0286 6.34229H6.54707C6.00752 6.34229 5.49007 6.55662 5.10855 6.93814C4.72703 7.31966 4.5127 7.83711 4.5127 8.37665V30.7547C4.5127 31.2943 4.72703 31.8117 5.10855 32.1932C5.49007 32.5748 6.00752 32.7891 6.54707 32.7891H28.9251C29.4647 32.7891 29.9821 32.5748 30.3636 32.1932C30.7452 31.8117 30.9595 31.2943 30.9595 30.7547V15.4969L28.9251 17.5313V30.7547Z" fill="#000E4B" />
							<path d="M34.5503 6.17967L31.1223 2.75176C30.9702 2.59921 30.7895 2.47818 30.5905 2.3956C30.3915 2.31302 30.1782 2.27051 29.9627 2.27051C29.7473 2.27051 29.534 2.31302 29.335 2.3956C29.136 2.47818 28.9553 2.59921 28.8032 2.75176L14.8576 16.7787L13.7285 21.6714C13.6804 21.9086 13.6854 22.1534 13.7433 22.3884C13.8011 22.6234 13.9103 22.8426 14.0631 23.0304C14.2158 23.2181 14.4082 23.3696 14.6265 23.4741C14.8447 23.5786 15.0835 23.6334 15.3255 23.6346C15.4505 23.6483 15.5767 23.6483 15.7018 23.6346L20.6352 22.5462L34.5503 8.49885C34.7028 8.34672 34.8238 8.16599 34.9064 7.967C34.989 7.76802 35.0315 7.5547 35.0315 7.33926C35.0315 7.12382 34.989 6.9105 34.9064 6.71152C34.8238 6.51253 34.7028 6.3318 34.5503 6.17967ZM19.5773 20.6644L15.8544 21.4883L16.719 17.7959L27.2163 7.22737L30.0848 10.0958L19.5773 20.6644ZM31.2342 8.94641L28.3658 6.07795L29.9424 4.4708L32.8312 7.3596L31.2342 8.94641Z" fill="#000E4B" />
						</svg>
						<p>{item.QUESTIONARIO_PERGUNTA}</p>
					</Nota_add>
			}
		</>
	)
};

export default memo(Nota_adicional);