import styled from "styled-components";
import { Colors } from "../../../../../styles/colors";

interface ContainerProps {
	questionAsync: boolean;
}

export const Container = styled.div<ContainerProps>`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: ${({ questionAsync }) =>
		questionAsync ? "center" : "flex-start"};
	flex-direction: column;
	${({ questionAsync }) => questionAsync && "padding: 0 0; "}
`;

export const ProgressNumber = styled.div`
color: var(--Neutras-Grey-K-70, #3D4259);
/* P3-Desk */
font-family: "NotoSans-Regular";
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 150%;
`;

export const ProgressBar = styled.div`
	height: 0.5rem;
	width: 100%;
	background: #e4e4e4;
	border-radius: 4px;

	overflow: hidden;
`;

export const Preenchimento = styled.div`
	height: 100%;

	background: #00D084;
	border-radius: 4px;

	transition: all 0.2s linear;
`;
