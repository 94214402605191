import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { CentralAjuda } from "../../../store/ducks/central";
import { TypesErrorRequest } from "../../../utils/types";
import ToastAlert from "../../toast";
import { Container, Content } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalCentraAjuda: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();

	const is_open_central_ajuda = useSelector(
		(state: any) => state.app.is_open_central_ajuda
	);

	const [value, setvalor]: any = useState<any>(0);
	const ModalCentralAjuda = () => {
		dispatch(AppActions.set_modal_central_ajuda(false));
		reset();
		setvalor(0);
	};

	const [isSearch, setIsSearch] = useState<boolean>(false);

	const isWriting = (e: any) => {
		if (e.target.value !== "") {
			setIsSearch(true);
		} else {
			setIsSearch(false);
		}
		setvalor(e.target.value);
	};

	//Form
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const [aprovado, setAprovado] = useState(false);
	const [marcado, setMarcado] = useState("Problemas");
	const [check, setCheck] = useState(false);
	const onSubmit = async (data: any) => {
		setAprovado(true);
		const central = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			AJUDA_TIPO: marcado === "Sugestoes" ? "sugestao" : "problema",
			AJUDA_TXT: data.text,
			AJUDA_EMAIL: user_logado?.USUARIO_EMAIL,
			AJUDA_CONTATO: user_logado?.USUARIO_CONTATO,
			AJUDA_CHECK: check ? "S" : "N",
		};
		dispatch(CentralAjuda.central_ajuda_enviar(central));
		setvalor("");
		reset();
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const type_toast = useSelector((state: any) => state.app.type_toast);

	useEffect(() => {
		if (type_toast == TypesErrorRequest.SUCCESS) {
			ModalCentralAjuda();
		}
		if (is_open_toast == true) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	const windowWidth = window.innerWidth;

	const radioMarcador = (status: any) => {
		AmplitudeLog(status)
		setMarcado(status);
	};

	const entrarContato = () => {
		check ? setCheck(false) : setCheck(true);
	};

	useEffect(() => {
		setValue("nome", user_logado?.USUARIO_NOME);
	}, [is_open_central_ajuda]);

	useEffect(() => {
		if (is_open_toast && type_toast === TypesErrorRequest.SUCCESS)
			ModalCentralAjuda();
	}, [is_open_toast, type_toast]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_central_ajuda}
				onClose={ModalCentralAjuda}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_central_ajuda}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCentralAjuda}
							alt="Close"
						/>
						<div className="scroll">
							<Content>
								{is_open_toast && type_toast == TypesErrorRequest.FAIL ? (
									<ToastAlert />
								) : (
									""
								)}
								<form action="" onSubmit={handleSubmit(onSubmit)}>
									<div className="sup">
										<p className="title">
											{current_language?.problemas_sugestoes}
										</p>

										<div className="radioButtons">
											<div className="radio">
												<label className="container">
													<input
														type="checkbox"
														checked={marcado == "Problemas"}
														onChange={() => radioMarcador("Problemas")}
													/>
													<span className="checkmark"></span>
												</label>
												<div className="radioText">
													{current_language?.problemas}
												</div>
											</div>
											<div className="radio">
												<label className="container">
													<input
														type="checkbox"
														checked={marcado == "Sugestoes"}
														onChange={() => radioMarcador("Sugestoes")}
													/>
													<span className="checkmark"></span>
												</label>
												<div className="radioText">
													{current_language?.sugestoes}
												</div>
											</div>
										</div>

										<div className="inputNome">
											<p>{current_language?.primeiro_acesso_nome}</p>
											<input
												type="text"
												placeholder={current_language?.Digite_seu_nome}
												{...register("nome", {
													required: `${current_language?.login_input_senha}`,
													pattern: {
														value:
															/\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú][A-Za-zÀ-ú]{2,}\b/gi,
														message: `${current_language?.mensag_nome_inválido}`,
													},
												})}
											/>
										</div>
										<div className="divhr">
											{errors.nome && (
												<div className="error">{errors.nome.message}</div>
											)}
										</div>
										<textarea
											className="textArea"
											id="text"
											placeholder={current_language?.digite_seu_problema}
											cols={30}
											rows={windowWidth < 1440 ? 5 : 10}
											onKeyUp={(e) => isWriting(e)}
											{...register("text", {
												required: `${current_language?.login_input_senha}`,
											})}
										></textarea>
										<p className="limite">
											{value.length === undefined ? "0" : value.length}/1000
										</p>
										<div className="divhr">
											{errors.text && (
												<div className="error">{errors.text.message}</div>
											)}
										</div>
										<div className="contato">
											<div className="item">
												<div className={`pergun ${!check && "colorFalse"}`}>
													{current_language?.telefone}:
												</div>
												<div className={`resp ${!check && "colorFalse"}`}>
													{user_logado?.USUARIO_CONTATO}
												</div>
											</div>
											<div className="item">
												<div className={`pergun ${!check && "colorFalse"}`}>
													E-mail:
												</div>
												<div className={`resp ${!check && "colorFalse"}`}>
													{user_logado?.USUARIO_EMAIL}
												</div>
											</div>
										</div>
										<div className="entrarContato">
											<div className="custom-checkbox">
												<input
													id="check"
													type="checkbox"
													checked={check}
													onClick={entrarContato}
													{...register(`check`, {})}
												/>
												<label htmlFor="check"></label>
											</div>
											<p>{current_language?.entrar_contato}</p>
										</div>
									</div>

									<div className="inf">
										<button
											onClick={()=> AmplitudeLog('enviar_sugestoes/problemas')}
											type="submit"
											id="enviar"
											value="ENVIAR"
											className="salve"
										>
											{aprovado == true ? (
												<CircularProgress color="inherit" />
											) : (
												current_language?.pagamento_salvar
											)}
										</button>
									</div>
								</form>
							</Content>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalCentraAjuda;
