import React from "react";
import { useSelector } from "react-redux";
import Menu from "../components/Menu";
import BarSuperior from "../components/SuperiorBar";
import styled from "styled-components";
import Pacientes from "./DashPaciente/Pacientes";
import Protocolos from "./DashProtocolos/Protocolos";
import ExercisesInProtocol from "./DashProtocolos/ExercisesInProtocol/ExercisesInProtocol";
import MinhaConta from "./MenueConta/MinhaConta";
import Perfil from "./MenueConta/Perfil";
import MeuPlano from "./MenueConta/MeuPlano";
import ListagemAval from "./DashPaciente/ListagemAval";
import Pagamentos from "./MenueConta/Pagamentos";
import PersonalizarRelatorio from "./MenueConta/PersonalizarRelatorio";
import ExportarDados from "./MenueConta/ExportarDados";
import Etiquetas from "./MenueConta/Etiquetas";
import Configuracoes from "./MenueConta/Configuracoes";
import GerenciadorExcluidos from "./MenueConta/GerenciadorExcluidos";
import Exercicios from "./DashProtocolos/Exercicios";
import Paciente from "./Paciente";
import DetailInfoPatient from "./Paciente/InformacoesPaciente";
import ProtocolsInPatient from "./Paciente/Protocolos/Protocolos";
import ExercisesForExecution from "./Paciente/Protocolos/ExercisesInProtocol";
import ExercisesInPatient from "./Paciente/Exercicios/Exercicios";
import Avaliacao from "./Execucao/Protocolo";
import Anotacoes from "./Paciente/Anotacoes/ListagemAnotacoes";
import CriarAnotacao from "./Paciente/Anotacoes/CriarAnotacao";
import Anotacao from "./Paciente/Anotacoes/Anotacao";
import EditarAnotacao from "./Paciente/Anotacoes/EditarAnotacao";
import Relatorios from "./Relatorios";
import Assimetria from "./Relatorios/Assimetria";
import HistoricoAssimetria from "./Relatorios/Assimetria/EvolucaoAssimetria/HistoricoAssimetria/HistoricoAssimetria";
import Anamnese from "./Paciente/Anamnese/Anamnese";
import TiposAnamnese from "./Paciente/Anamnese/TiposAnamnese";
import QuestionariosAntigos from "./Paciente/Anamnese/QuestionariosAntigos";
import QuestionarioNovo from "./Paciente/Anamnese/QuestionarioNovo";
import RelatorioAnamnese from "./Relatorios/RelatorioAnamnese/RelatorioAnamnese";
import ListaHistoricoAnamnese from "./Relatorios/RelatorioAnamnese/ListaHistoricoAnamnese";
import HistoricoAnamnese from "./Relatorios/RelatorioAnamnese/HistoricoAnamnese";
import QualidadeDeVida from "./Relatorios/QualidadeDeVida/QualidadeDevida";
import ListaHistoricoQualidadeVida from "./Relatorios/QualidadeDeVida/ListaHistoricoQualidadeVida";
import Evolucao from "./Relatorios/Evolucao";
import ForcaTempo from "./Relatorios/ForcaTempo";
import EmissaoRelatorios from "./Relatorios/EmissaoRelatorios";
import SelecaoExercicios from "./Relatorios/EmissaoRelatorios/SelecaoExercicios";
import SelecaoPeriodo from "./Relatorios/EmissaoRelatorios/SelecaoPeriodo";
import Desequilibrio from "./Relatorios/Desequilibrio";
import MetodoPagamento from "./MenueConta/MetodoPagamento";
import EditTagPage from "./EditTag";

const Dashboard: React.FC = () => {
	const openMenu = useSelector((state: any) => state.app.openMenu);
	const current_page: string = useSelector(
		(state: any) => state.dashboard.current_page
	);

	const pages_components: any = {
		pacientes: <Pacientes />,
		protocolos: <Protocolos />,
		exercicios: <Exercicios />,
		exercises_in_protocol: <ExercisesInProtocol />,
		minha_conta: <MinhaConta />,
		perfil: <Perfil />,
		meu_plano: <MeuPlano />,
		evaluator: <ListagemAval />,
		pagamentos: <Pagamentos />,
		personalizar_relatorio: <PersonalizarRelatorio />,
		exportarDados: <ExportarDados />,
		etiquetas: <Etiquetas />,
		configuracoes: <Configuracoes />,
		gerenciador_excluidos: <GerenciadorExcluidos />,
		perfil_paciente: <Paciente />,
		informacoes_paciente: <DetailInfoPatient />,
		protocols_in_patient: <ProtocolsInPatient />,
		exercises_in_patient: <ExercisesInPatient />,
		exercises_for_execution: <ExercisesForExecution />,
		execucao: <Avaliacao />,
		listagem_anotacoes: <Anotacoes />,
		anotacao: <Anotacao />,
		criar_anotacao: <CriarAnotacao />,
		editar_anotacao: <EditarAnotacao />,
		assimetria: <Assimetria />,
		historico_assimetria: <HistoricoAssimetria />,
		anamnese: <Anamnese />,
		tipos_anamnese: <TiposAnamnese />,
		questionarios_antigos: <QuestionariosAntigos />,
		questionario_novo: <QuestionarioNovo />,
		relatorio_anamnese: <RelatorioAnamnese />,
		lista_historico_anamnese: <ListaHistoricoAnamnese />,
		historico_anamnese: <HistoricoAnamnese />,
		relatorio_qualidade_vida: <QualidadeDeVida />,
		lista_historico_qualidade_vida: <ListaHistoricoQualidadeVida />,
		relatorio_evolucao: <Evolucao />,
		relatorio_forca_tempo: <ForcaTempo />,
		emissao_relatorios: <EmissaoRelatorios />,
		selecao_exercicios: <SelecaoExercicios />,
		selecao_periodo: <SelecaoPeriodo />,
		desequilibrio_muscular: <Desequilibrio />,
		adicionar_pagamento: <MetodoPagamento />,
		edit_tag: <EditTagPage />,
	};

	return (
		<Body>
			<BarSuperior />
			<ContentApp>
				<Menu />
				{openMenu && <Overlay isVisible={openMenu} />}
				{pages_components[current_page]}
			</ContentApp>
		</Body>
	);
};

const Body = styled.div`
	display: flex;
	color: rgba(140, 142, 134, 0, 8);
	background-color: #f5fbfc;
	flex-direction: column;
	min-width: 1247px;
`;

const ContentApp = styled.div`
	display: flex;
	flex-direction: row;
	height: 90vh;
	position: relative;
	overflow-y: hidden;
`;

export const Overlay = styled.div<{ isVisible: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 10;
	display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

export default Dashboard;
