import styled, { keyframes } from "styled-components";

export const Conteiner = styled.div<any>`
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	${(props) =>
		props.background && `background-image: url(${props.background});`}
	background-color: #fff;
	color: rgba(140, 142, 134, 0, 8);
	justify-content: center;

	overflow: hidden;
`;

const sizeContent = keyframes`
  from {
    width: 45%;
	height: 90%;
  }

  to {
    width: 100%;
	height: 100%;
  }
`;

export const Content = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: 45%;
	padding: 1%;
	height: 90%;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	animation: ${sizeContent} 0.3s linear;

	@media (max-width: 1525px) {
		width: 70%;
		height: 70%;
	}

	@media (max-width: 964px) {
		width: 100%;
		height: 100%;
	}
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 8px 0 8px;
	height: 3rem;
	position: relative;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 90%;
	padding: 0.5rem 1rem;
`;
