import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Toogle from "../../components/Toogle";
import { TextsProps } from "../../database/txt.interface";
import { AppActions } from "../../store/ducks/app";
import { ConfigsActions } from "../../store/ducks/configs";

const Configuracoes: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);


	const tempo_contracao_flag = useSelector(
		(state: any) => state.configs.tempo_contracao_flag
	);
	const alerta_sonoro = useSelector(
		(state: any) => state.configs.alerta_sonoro
	);
	const contagem_regressiva = useSelector(
		(state: any) => state.configs.contagem_regressiva
	);
	const tempo_contracao = useSelector(
		(state: any) => state.configs.tempo_contracao
	);


	const handleOpenConfiguracoes = () => {
		dispatch(AppActions.set_open_configuracoes_exercicios(true));
	};

	const handleCloseConfiguracoes = () => {
		dispatch(AppActions.set_open_configuracoes_exercicios(false));
	};

	const isWritingContracao = (e: any) => {
		if (e.target.value !== "") {
			dispatch(ConfigsActions.set_tempo_contracao_timer(e.target.value));
			dispatch(
				ConfigsActions.config_request_edit({
					CONFIG_EXER_CONTRACAO_FLAG: tempo_contracao_flag,
					CONFIG_EXER_CONTRACAO: e.target.value,
				})
			);
		} else {
			dispatch(ConfigsActions.set_tempo_contracao_timer(20));
		}
	};

	return (
		<>
			<div className="header configuracoes">
				<div className="perfil">
					<div className="info">
						<p>{current_language?.menu_lateral_config}</p>
					</div>
				</div>
				<div className="config">
					<div className="configArea">
						<div className="headConfig">

							<svg
								onClick={handleOpenConfiguracoes}
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M17.6502 10.975C17.6888 10.6625 17.7145 10.3375 17.7145 10C17.7145 9.6625 17.6888 9.3375 17.6373 9.025L19.8102 7.375C20.0031 7.225 20.0545 6.95 19.9388 6.7375L17.8816 3.275C17.753 3.05 17.483 2.975 17.2516 3.05L14.693 4.05C14.153 3.65 13.5872 3.325 12.9572 3.075L12.5715 0.425C12.5329 0.175 12.3143 0 12.0572 0H7.94281C7.68566 0 7.47994 0.175 7.44137 0.425L7.05565 3.075C6.42563 3.325 5.84705 3.6625 5.31989 4.05L2.76126 3.05C2.52982 2.9625 2.25982 3.05 2.13124 3.275L0.0740529 6.7375C-0.0545215 6.9625 -0.00309162 7.225 0.202628 7.375L2.37554 9.025C2.32411 9.3375 2.28553 9.675 2.28553 10C2.28553 10.325 2.31125 10.6625 2.36268 10.975L0.18977 12.625C-0.00309189 12.775 -0.0545215 13.05 0.0611955 13.2625L2.11839 16.725C2.24696 16.95 2.51697 17.025 2.7484 16.95L5.30703 15.95C5.84705 16.35 6.41277 16.675 7.04279 16.925L7.42851 19.575C7.47994 19.825 7.68566 20 7.94281 20H12.0572C12.3143 20 12.5329 19.825 12.5586 19.575L12.9444 16.925C13.5744 16.675 14.153 16.3375 14.6801 15.95L17.2387 16.95C17.4702 17.0375 17.7402 16.95 17.8688 16.725L19.9259 13.2625C20.0545 13.0375 20.0031 12.775 19.7974 12.625L17.6502 10.975ZM10 13.75C7.87852 13.75 6.14277 12.0625 6.14277 10C6.14277 7.9375 7.87852 6.25 10 6.25C12.1215 6.25 13.8572 7.9375 13.8572 10C13.8572 12.0625 12.1215 13.75 10 13.75Z"
									fill="#000E4B"
								/>
							</svg>
						</div>
						<div className="bodyConfig">
							<svg
								onClick={handleCloseConfiguracoes}
								className="botaoClick"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M17.5002 0L9.99912 7.5L2.49978 0L0 2.5L7.49934 10L0 17.5L2.49978 20L9.99912 12.5L17.5002 20L20 17.5L12.5007 10L20 2.5L17.5002 0Z"
									fill="#5C5B5B"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>

			<div className="divExercicio">
				<div className="fichaExercicio">
					<div className="DivIcone">
						<svg
							width="20"
							height="24"
							viewBox="0 0 20 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0 14.4C0 19.68 4.5 24 10 24C15.5 24 20 19.68 20 14.4H17.5C17.5 18.36 14.125 21.6 10 21.6C5.875 21.6 2.5 18.36 2.5 14.4C2.5 10.44 5.875 7.2 10 7.2V12L16.25 6L10 0V4.8C4.5 4.8 0 9.12 0 14.4ZM8.375 15.48L8.625 12.84H11.625V13.68H9.5L9.375 14.76C9.375 14.76 9.5 14.76 9.5 14.64C9.5 14.52 9.625 14.64 9.625 14.52C9.625 14.4 9.75 14.52 9.875 14.52H10.125C10.375 14.52 10.625 14.52 10.75 14.64C10.875 14.76 11.125 14.88 11.25 15C11.375 15.12 11.5 15.36 11.625 15.6C11.75 15.84 11.75 16.08 11.75 16.32C11.75 16.56 11.75 16.8 11.625 16.92C11.5 17.04 11.5 17.28 11.25 17.52C11 17.76 10.875 17.76 10.625 17.88C10.375 18 10.125 18 9.875 18C9.625 18 9.375 18 9.25 17.88C9.125 17.76 8.875 17.76 8.625 17.64C8.375 17.52 8.375 17.4 8.25 17.16C8.125 16.92 8.125 16.8 8.125 16.56H9.125C9.125 16.8 9.25 16.92 9.375 17.04C9.5 17.16 9.625 17.16 9.875 17.16C10 17.16 10.125 17.16 10.25 17.04L10.5 16.8C10.5 16.8 10.625 16.56 10.625 16.44V15.72L10.5 15.48L10.25 15.24C10.25 15.24 10 15.12 9.875 15.12H9.625C9.625 15.12 9.5 15.12 9.375 15.24C9.25 15.36 9.25 15.24 9.25 15.36C9.25 15.48 9.125 15.48 9.125 15.48H8.375Z"
								fill="#001574"
							/>
						</svg>
					</div>
					<div className="a">
						<p className="p">
							{current_language?.menu_lateral_exercicio_contagem}
						</p>
						<p>{current_language?.menu_lateral_exercicio_contagem_legenda}</p>
					</div>
					<div className="opo">
						<Toogle action={"ativarContagem"} isCheck={contagem_regressiva} />
					</div>
				</div>

				<div className="fichaExercicio">
					<div className="DivIcone">
						<svg
							width="21"
							height="21"
							viewBox="0 0 21 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M10.4063 0C4.65625 0 0 4.66667 0 10.4167C0 16.1667 4.65625 20.8333 10.4063 20.8333C16.1667 20.8333 20.8333 16.1667 20.8333 10.4167C20.8333 4.66667 16.1667 0 10.4063 0ZM10.4167 18.75C5.8125 18.75 2.08333 15.0208 2.08333 10.4167C2.08333 5.8125 5.8125 2.08333 10.4167 2.08333C15.0208 2.08333 18.75 5.8125 18.75 10.4167C18.75 15.0208 15.0208 18.75 10.4167 18.75Z"
								fill="#001574"
							/>
							<path
								d="M8.9375 5.20898H7.375V11.459L12.8437 14.7402L13.625 13.459L8.9375 10.6777V5.20898Z"
								fill="#001574"
							/>
						</svg>
					</div>
					<div className="a">
						<p className="p">{current_language?.contracao_tempo}</p>
						<p>{current_language?.menu_tempo_contracao_legenda}</p>
					</div>
					<div className="opo">
						<Toogle action={"ativarContracao"} isCheck={tempo_contracao_flag} />
					</div>
				</div>
				<div className="fichaExercicio">
					<div className="divInput">
						<p className="tempo">{current_language?.menu_tempo_segundos}</p>
						<input
							className="input"
							placeholder={tempo_contracao.toString()}
							type="text"
							onBlur={(e) => isWritingContracao(e)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Configuracoes;
