import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	height: 100%;
	width: 100%;
	padding: 3.6rem;
	border-radius: 1.25rem;

	overflow: hidden;

	.foto {
		width: 5.5rem;
		height: 5.5rem;
		position: relative;
	}

	.camera {
		position: absolute;
		right: 0;
		top: 60%;
	}

	.header {
		width: 100%;
	}

	.inf {
		margin-top: 4rem;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
	}

	.salve {
		width: 23rem;
		height: 3.625rem;
		min-height: 3rem;

		background-color: ${Colors.primary_blue_k_main};
		color: white;

		font-weight: 700;
		font-size: 1.25rem;

		border: none;
		border-radius: 8px;

		transition: all 0.2s ease;
	}

	.salve:hover {
		background-color: ${Colors.primary_blue_k_dark_2};
	}

	.cancel {
		width: 23rem;
		height: 3.625rem;
		min-height: 3rem;

		background-color: transparent;
		color: ${Colors.primary_blue_k_main};

		font-weight: 700;
		font-size: 1.25rem;

		border: none;
		border-radius: 8px;

		transition: all 0.2s ease;
	}

	.cancel:hover {
		color: ${Colors.primary_blue_k_dark_2};
	}
`;

export const BodyEdite = styled.form`
	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	height: 100%;

	.coluna {
		margin: 0 1rem 3rem;

		h3 {
			font-size: 1.5rem;
			font-weight: 500;
			color: #5c5b5b;
		}
	}

	.global {
		width: 100%;
	}

	.globalInput {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: baseline;
		justify-content: center;
	}

	.input {
		width: 100%;
		margin-left: 1rem;
		color: #8c8e86;
		font-weight: 500;
		font-size: 1.125rem;
		border: none;
		height: 2rem;
	}

	.select {
		-webkit-appearance: none; /* Remove estilo padrão do Chrome */
		-moz-appearance: none; /* Remove estilo padrão do FireFox */
		appearance: none; /* Remove estilo padrão do FireFox*/
		font-family: "roboto";
		font-weight: 500;
		font-size: 1.125rem;
		color: #8c8e86;
	}

	.select2 {
		font-family: "roboto";
		font-weight: 500;
		font-size: 1.125rem;
		color: #8c8e86;
	}

	.option {
		font-weight: 500;
		font-size: 1.125rem;
		color: #8c8e86;
	}

	.alterSenha {
		margin-left: 1.6rem;
		color: ${Colors.primary_blue_k_main};
		font-weight: 500;
		font-size: 1rem;
	}

	.buttonSenha {
		text-decoration: none;
		display: flex;
		justify-content: space-between;

		div {
			display: flex;
			align-items: center;
		}
	}

	.buttonSenha:hover {
		text-decoration: none;
	}

	.iconIput {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1rem;
		height: 1rem;
		position: relative;
		margin-bottom: 0.7rem;
		margin-left: 0.5rem;
	}

	.condicionamento {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 1rem;
		position: relative;
		margin-bottom: 0.6rem;
		margin-left: -0.8rem;
	}

	.icon {
		position: absolute;
	}

	.divhr {
		width: 100%;
	}

	.hr {
		width: 100%;
		background-color: #e0e0e0;
		color: #e0e0e0;
		border: none;
		height: 2px;
	}

	.hrcor {
		background-color: ${Colors.tertirary_red_1_dark};
		color: ${Colors.tertirary_red_1_dark};
	}

	.error {
		margin-top: 0.5rem;
		color: ${Colors.tertirary_red_1_dark};
	}

	.eyeButton {
		background-color: white;
		border: none;
	}
`;
