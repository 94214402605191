import styled from "styled-components";
import { Colors } from "../../../../styles/colors";

export const Conteiner = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 2rem;
	padding-bottom: 5vh;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	border-radius: 1.25rem;

	overflow: hidden;

	.title {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 28px;
		line-height: 120%;
		color: #a21c53;
		margin-bottom: 2.813rem;
	}
`;

export const Content = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	overflow-x: hidden;

	overflow-y: scroll;

	padding-right: 1rem;

	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.divPlano {
		width: 100%;
		background-color: #f8f8f8;
		padding: 1.5rem;
		border-radius: 12px;
		filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
		margin-left: 3px;
		display: flex;
		margin-bottom: 1.5rem;

		.divPlanoDetail {
			width: 80%;
			height: 100%;

			.titleAndPrice {
				display: flex;
				flex-direction: row;
				align-items: center;

				.rs {
					font-family: "Roboto";
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					color: #404040;
					margin-right: 4px;
					margin-top: 7px;
				}

				.titlePlan {
					font-family: "Roboto";
					font-style: normal;
					font-weight: 700;
					font-size: 28px;
					color: #a21c53;
					margin-right: 0.688rem;
				}

				.price {
					font-family: "Roboto";
					font-style: normal;
					font-weight: 700;
					font-size: 28px;
					color: #404040;
				}
			}

			.detail {
				margin-top: 1.5rem;
				font-family: "Roboto";
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 150%;
				color: #404040;
			}
		}

		.divAssinar {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 20%;

			.button {
				width: 10rem;
				height: 35px;
				background-color: #a21c53;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				border: none;
				border-radius: 8px;
				color: #fcfcfc;
				margin-bottom: 1rem;
			}

			.termos {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 600;
				font-size: 12px;
				line-height: 150%;
				text-align: center;
				color: #404040;
			}
		}
	}

	.item {
		border: 1px solid #e0e0e0;
		border-radius: 1rem;
		padding: 2rem;
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
		background-color: #f8f8f8;
		display: flex;
		flex-direction: row;
		width: 100%;

		.divSub {
			display: flex;
			flex-direction: column;
			width: 100%;

			.metodo_pagamento {
				margin-bottom: 2rem;
				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 20px;
				line-height: 120%;
				color: black;
			}
		}

		p {
			margin-left: 1.3rem;

			font-size: 1rem;
			font-weight: 500;

			color: #5c5b5b;
		}

		svg {
			margin-left: 1rem;
		}

		.left {
			display: flex;

			.info {
				padding: 0.5rem 0;
				p {
					font-weight: 500;
					font-size: 1.25rem;
					color: #5c5b5b;
				}
				.tituloCard {
					margin-bottom: 1rem;
				}
			}
		}

		.botaoSelecao {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			height: 100%;
			cursor: pointer;
			text-decoration: none;

			.editar {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 120%;
				text-align: center;
				margin-left: 10px;
				color: black;
			}
		}
	}

	.divContrato {
		display: flex;
		flex-direction: row;

		.checkbox {
			width: 20px;
			height: 20px;
			margin-top: 33px;
			margin-right: 10px;
		}

		.declaracao {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 300;
			font-size: 18px;
			line-height: 63%;
			color: #404040;
			margin-top: 2.5rem;

			.contrato {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 300;
				font-size: 18px;
				line-height: 63%;
				color: ${Colors.primary_blue_k_main};
				background-color: transparent;
				border: none;
			}
		}
	}

	.enviar {
		width: 23rem;
		height: 3.625rem;
		min-height: 3rem;
		margin-top: 2rem;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		color: white;
		font-weight: 700;
		font-size: 1.25rem;
		border: none;
		border-radius: 8px;
		transition: all 0.2s ease;
	}

	.encaminhar {
		width: 50%;
		height: 100%;

		text-align: center;
		font-size: x-large;
		
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		svg {
			margin-bottom: 3rem;
		}
	}
`;
