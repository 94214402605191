import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppActions } from "../../../store/ducks/app";
import { TypesErrorModal } from "../../../utils/types";
import RadioButtonTipoCorpo from '../../Paciente/Questionario_progress/Components/RadioButtonTipoCorpo';
import { Container } from "./styles";

const TesteModal: React.FC = () => {
	const dispatch = useDispatch();
	const handlemodal = () => {
		dispatch(AppActions.set_modal_emissao_rel(true));
	};
	return (
		<Container>
			{/* <RadioButtonTipoCorpo /> */}
			<button type="button" onClick={handlemodal}>
				Relatorios
			</button>
		</Container>
	);
};

export default memo(TesteModal);
