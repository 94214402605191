import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonClose.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { PacAvalActions } from '../../../store/ducks/manage_pac_aval';
import { Container } from "./styles";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

interface Props {
	FUNCAO: any,
}

const ModalExcluirEVA: React.FC<Props> = ({ FUNCAO }) => {
	// comunica com redux
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	// função que abre a modal, mais sobre ela em store/ducks/app.ts
	const excluir_eva = useSelector((state: any) => state.app.excluir_eva);

	// função para fechar modal
	const ModalCadastroClose = () => {
		dispatch(AppActions.set_excluir_eva(false));
	};

	const ModalConfirm = () => {
		FUNCAO()
		dispatch(AppActions.set_excluir_eva(false));
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={excluir_eva}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={excluir_eva}>
					<Container>
						<svg width="52" height="61" viewBox="0 0 52 61" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect x="2.53174" y="20.1685" width="45.4737" height="37.7494" fill="white" />
							<path fillRule="evenodd" clipRule="evenodd" d="M44.5468 20.8738H7.2518C5.53534 20.8738 4.14388 22.0289 4.14388 23.4538V54.4137C4.14388 55.8386 5.53535 56.9937 7.2518 56.9937H44.5468C46.2632 56.9937 47.6547 55.8386 47.6547 54.4137V23.4538C47.6547 22.0289 46.2632 20.8738 44.5468 20.8738ZM7.2518 17.4338C3.24674 17.4338 0 20.1291 0 23.4538V54.4137C0 57.7385 3.24674 60.4337 7.2518 60.4337H44.5468C48.5518 60.4337 51.7986 57.7385 51.7986 54.4137V23.4538C51.7986 20.1291 48.5518 17.4338 44.5468 17.4338H7.2518Z" fill="#5C5B5B" />
							<path d="M13.7266 33.9884C13.7266 33.0385 14.6542 32.2684 15.7985 32.2684C16.9428 32.2684 17.8704 33.0385 17.8704 33.9884V38.2884C17.8704 39.2383 16.9428 40.0084 15.7985 40.0084C14.6542 40.0084 13.7266 39.2383 13.7266 38.2884V33.9884Z" fill="#5C5B5B" />
							<path d="M34.7051 33.9884C34.7051 33.0385 35.6327 32.2684 36.777 32.2684C37.9213 32.2684 38.849 33.0385 38.849 33.9884V38.2884C38.849 39.2383 37.9213 40.0084 36.777 40.0084C35.6327 40.0084 34.7051 39.2383 34.7051 38.2884V33.9884Z" fill="#5C5B5B" />
							<path fillRule="evenodd" clipRule="evenodd" d="M23.6752 39.7383C25.848 39.0577 28.2677 39.4589 29.1596 39.7383C29.6949 39.906 30.2926 39.6816 30.4945 39.2372C30.6965 38.7929 30.4263 38.2967 29.891 38.129C28.7109 37.7594 25.7436 37.2521 22.9437 38.129C22.4084 38.2967 22.1382 38.7929 22.3402 39.2372C22.5422 39.6816 23.1398 39.906 23.6752 39.7383Z" fill="#5C5B5B" />
							<path d="M22.2734 13.349C22.2734 12.0429 23.5489 10.984 25.1224 10.984C26.6958 10.984 27.9713 12.0429 27.9713 13.349V18.509C27.9713 19.8151 26.6958 20.874 25.1224 20.874C23.5489 20.874 22.2734 19.8151 22.2734 18.509V13.349Z" fill="#5C5B5B" />
							<path d="M30.0431 14.4246C30.6153 14.4246 31.0791 14.8096 31.0791 15.2846C31.0791 15.7595 30.6153 16.1446 30.0431 16.1446L20.2014 16.1446C19.6293 16.1446 19.1654 15.7595 19.1654 15.2846C19.1654 14.8096 19.6293 14.4246 20.2014 14.4246L30.0431 14.4246Z" fill="#5C5B5B" />
							<path fillRule="evenodd" clipRule="evenodd" d="M24.863 4.10405C22.5744 4.10405 20.7191 5.64419 20.7191 7.54405V8.61719C20.7191 9.81415 21.2919 10.9621 22.3114 11.8085L23.9971 13.2078L21.0669 15.6403L19.3813 14.2409C17.5846 12.7494 16.5752 10.7265 16.5752 8.61719V7.54405C16.5752 3.74434 20.2858 0.664062 24.863 0.664062V4.10405Z" fill="#5C5B5B" />
							<path fillRule="evenodd" clipRule="evenodd" d="M24.8636 4.10405C27.1522 4.10405 29.0075 5.64419 29.0075 7.54405V8.61719C29.0075 9.81415 28.4347 10.9621 27.4151 11.8085L25.7294 13.2078L28.6596 15.6403L30.3453 14.2409C32.142 12.7494 33.1514 10.7265 33.1514 8.61719V7.54405C33.1514 3.74434 29.4408 0.664062 24.8636 0.664062V4.10405Z" fill="#5C5B5B" />
							<path d="M46.3601 53.1239V44.0939C33.5954 46.7783 20.2428 46.8915 7.41475 44.424L5.69824 44.0939V53.1239L7.46472 52.8914C20.3572 51.1946 33.4984 51.2732 46.3601 53.1239Z" fill="#000E4B" />
						</svg>

						<div className="divText">
							<p className="text">
								<b>{current_language?.cuidado}</b>
							</p>
							<p className="text">{current_language?.excluir_dor}</p>
						</div>
						<div className="buttons">
							<button className="button confirmar" onClick={() => ModalConfirm()}>
								{current_language?.modal_confirmar}
							</button>
							<button className="button cancelar" onClick={() => ModalCadastroClose()}>
								{current_language?.botao_cancelar}
							</button>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalExcluirEVA);
