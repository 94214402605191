import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

import Mais from "../../../assets/icons/+.svg";
import DinoFeliz from "../../../assets/icons/dinoFeliz.svg";
import { TextsProps } from "../../../database/txt.interface";
import { Conteiner } from "./styles";

const Loading: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	//UI
	return (
		<Conteiner id="DivPrincipal">
			<div className="divLoading">
				<div className="circulo">
					<svg
						width="50"
						height="60"
						viewBox="0 0 69 95"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect x="4" y="31" width="60" height="60" fill="white" />
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M58.777 32.1219H9.56835C7.30358 32.1219 5.46763 33.9579 5.46763 36.2226V85.4313C5.46763 87.696 7.30358 89.532 9.56834 89.532H58.777C61.0417 89.532 62.8777 87.696 62.8777 85.4313V36.2226C62.8777 33.9579 61.0417 32.1219 58.777 32.1219ZM9.56835 26.6543C4.28389 26.6543 0 30.9382 0 36.2226V85.4313C0 90.7157 4.28389 94.9996 9.56834 94.9996H58.777C64.0614 94.9996 68.3453 90.7157 68.3453 85.4313V36.2226C68.3453 30.9382 64.0614 26.6543 58.777 26.6543H9.56835Z"
							fill="#5C5B5B"
						/>
						<path
							d="M18.1113 52.9672C18.1113 51.4574 19.3352 50.2334 20.8451 50.2334C22.3549 50.2334 23.5789 51.4574 23.5789 52.9672V59.8017C23.5789 61.3116 22.3549 62.5356 20.8451 62.5356C19.3352 62.5356 18.1113 61.3116 18.1113 59.8017V52.9672Z"
							fill="#5C5B5B"
						/>
						<path
							d="M45.7916 52.9672C45.7916 51.4574 47.0156 50.2334 48.5254 50.2334C50.0353 50.2334 51.2593 51.4574 51.2593 52.9672V59.8017C51.2593 61.3116 50.0353 62.5356 48.5254 62.5356C47.0156 62.5356 45.7916 61.3116 45.7916 59.8017V52.9672Z"
							fill="#5C5B5B"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M31.238 58.8659C34.1049 59.9476 37.2976 59.3099 38.4743 58.8659C39.1807 58.5994 39.9693 58.9559 40.2358 59.6623C40.5023 60.3686 40.1457 61.1572 39.4394 61.4237C37.8823 62.0112 33.9671 62.8176 30.2729 61.4237C29.5666 61.1572 29.21 60.3686 29.4765 59.6623C29.743 58.9559 30.5317 58.5994 31.238 58.8659Z"
							fill="#5C5B5B"
						/>
						<path
							d="M29.3885 20.1623C29.3885 18.0863 31.0714 16.4033 33.1475 16.4033C35.2235 16.4033 36.9065 18.0863 36.9065 20.1623V28.3638C36.9065 30.4398 35.2235 32.1227 33.1475 32.1227C31.0714 32.1227 29.3885 30.4398 29.3885 28.3638V20.1623Z"
							fill="#5C5B5B"
						/>
						<path
							d="M39.6403 21.8701C40.3952 21.8701 41.0072 22.4821 41.0072 23.237C41.0072 23.9919 40.3952 24.6039 39.6403 24.6039L26.6547 24.6039C25.8998 24.6039 25.2878 23.9919 25.2878 23.237C25.2878 22.4821 25.8998 21.8701 26.6547 21.8701L39.6403 21.8701Z"
							fill="#5C5B5B"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M32.8057 5.46763C29.786 5.46763 27.3381 7.91557 27.3381 10.9353V12.6409C27.3381 14.5434 28.0939 16.368 29.4391 17.7132L31.6633 19.9374L27.7971 23.8036L25.5729 21.5794C23.2023 19.2088 21.8705 15.9935 21.8705 12.6409V10.9353C21.8705 4.89588 26.7664 0 32.8057 0V5.46763Z"
							fill="#5C5B5B"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M32.8058 5.46763C35.8255 5.46763 38.2734 7.91557 38.2734 10.9353V12.6409C38.2734 14.5434 37.5176 16.368 36.1724 17.7132L33.9482 19.9374L37.8144 23.8036L40.0386 21.5794C42.4092 19.2088 43.741 15.9935 43.741 12.6409V10.9353C43.741 4.89588 38.8451 0 32.8058 0V5.46763Z"
							fill="#5C5B5B"
						/>
						<path
							d="M61.1691 83.3808V69.0283C44.3268 73.295 26.7087 73.475 9.78284 69.5531L7.51801 69.0283V83.3808L9.84878 83.0113C26.8597 80.3145 44.1988 80.4393 61.1691 83.3808Z"
							fill="#000E4B"
						/>
					</svg>
				</div>
				<CircularProgress
					color="primary"
					size={113}
					style={{ marginTop: -112 }}
				/>
				<h1 className="texto">{current_language?.loading}...</h1>
			</div>
		</Conteiner>
	);
};

export default Loading;
