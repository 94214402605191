import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import email from "../../../assets/icons/email.svg";
import {
	PacientesProps,
	UserProps,
} from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import { TypesErrorRequest, TypesPermission, TypesQuestionnaire } from "../../../utils/types";
import ToastAlert from "../../toast";
import { ContainerEmail } from "./styles";
import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { getPermission } from "../../../utils/funcs";
import { ConfigsActions } from "../../../store/ducks/configs";
import { AmplitudeLog } from "../../../utils/amplitude";

const ShereAnamneseAsync: React.FC = () => {
	const dispatch = useDispatch();
	const language: any = useSelector((state: any) => state.configs.language);

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const is_open_paciente = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const questionario = useSelector((state: any) => state.pac_aval.questionario);

	const tipo_anamnese: any = useSelector(
		(state: any) => state.pac_aval.tipo_anamnese
	);

	const shere_anamnese_url = useSelector(
		(state: any) => state.avaliation.shere_anamnese_url
	);

	const Type_envio = {
		EMAIL: "EMAIL",
		DOWNLOAD: "DOWNLOAD",
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();
	const [aprovadoEmail, setAprovadoEmail] = useState(false);
	const [aprovadoDownload, setAprovadoDownload] = useState(false);
	const [loadCircularProgress, setLoadCircularProgress] = useState(false);

	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos,
	);

	async function permissionAsynchronousAnamnesis(data: any){
		const asynchronousAnamnesis: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_ANAMNESE_ASSINCRONA,
		)
		if(asynchronousAnamnesis){
			setAprovadoEmail(true);
			shereLinkAnamnese(Type_envio.EMAIL, data.email);
		}else{
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true))
		}
	};

	function permissionAnamnesisWithPatient(config_servicos: ServicosProps[], dispatch: any){
		const anamnesisWithPatient: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_ANAMNESE_FISICA,
		)
		if(anamnesisWithPatient){
			 setLoadCircularProgress(true), realizarComPaciente(tipo_anamnese)
		}else{
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true))
			dispatch(ConfigsActions.set_close_modal_shere_and_navigate(true))
		}
	};

	async function permissionAsynchronousSF_36(data: any){
		const asynchronousSF_36: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_QUALIDADE_VIDA_ASSINCRONO,
		)
		if(asynchronousSF_36){
			setAprovadoEmail(true);
			shereLinkAnamnese(Type_envio.EMAIL, data.email);
		}else{
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true))
			dispatch(ConfigsActions.set_close_modal_shere_and_navigate(true))
		}
	};

	function permissionSF_36WithPatient(config_servicos: ServicosProps[], dispatch: any){
		const SF_36WithPatient: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_QUALIDADE_VIDA ,
		)
		if(SF_36WithPatient){
			realizarComPaciente(tipo_anamnese), setLoadCircularProgress(true);
		}else{
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true))
		}
	};


	const shereLinkAnamnese = (tipo: string, email?: string) => {
		const permissionLinkShere: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_QUALIDADE_VIDA_ASSINCRONO,
		)
		if(permissionLinkShere){
			if (!email) {
				setAprovadoDownload(true);
			} else {
				setAprovadoEmail(true);
			}
	
			const txTipoProvider: Record<string, string> = {
				ANAMNESE: "ANAMNESE_TIPO",
				SF_36: "SF_36",
			};
	
			const authData: any = {
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				ID_USUARIO: is_open_paciente?.ID_USUARIO,
				txTipo: txTipoProvider[questionario],
				compartilhamento: email ? "email" : "url",
				txIdioma: language?.toLowerCase(),
				txCategoria: tipo_anamnese,
				txEmail: email || null,
			};
			if (authData) {
				if(questionario === TypesQuestionnaire.ANAMNESE ){
					if(email){
						AmplitudeLog('enviar_email_anamnese', {body: authData})
					}else{
						AmplitudeLog('copiar_link_anamnese', {body: authData})
					}
				}else{
					if(email){
						AmplitudeLog( 'enviar_email_sf_36', {body: authData}	)
					}else{
						AmplitudeLog( 'copiar_link_sf_36', {body: authData}	)
					}
				}
				
				dispatch(AvaliationActions.set_shere_anamnese_request(authData));
			}

		}else{
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true))
			dispatch(ConfigsActions.set_close_modal_shere_and_navigate(true))
		}
		if(questionario === TypesQuestionnaire.SF_36 ){
			dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.MENU));
		}
		dispatch(AppActions.set_modal_compartilhamento(false, 0));
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const type_toast = useSelector((state: any) => state.app.type_toast);

	const realizarComPaciente = (quest: string) => {
		if (questionario === TypesQuestionnaire.ANAMNESE) {
			dispatch(PacAvalActions.set_questionario_request(true));
			dispatch(PacAvalActions.set_tipo_anamnese(quest));
			dispatch(
				PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE_TIPO)
			);
		} else {
			dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.SF_36));
		}
		if(questionario === TypesQuestionnaire.ANAMNESE){
			AmplitudeLog('fazer_anamnese_com_paciente', { body: quest})
		}
		if(questionario === TypesQuestionnaire.SF_36){
			AmplitudeLog('fazer_sf_36_com_paciente')
		}
		dispatch(AppActions.set_modal_compartilhamento(false, 0));
	};


	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	useEffect(() => {
		setValue(
			"email",
			paciente.USUARIO_EMAIL
				? paciente.USUARIO_EMAIL
				: user_logado.USUARIO_EMAIL
		);
	}, []);

	return (
		<ContainerEmail>
			{is_open_toast && type_toast == TypesErrorRequest.FAIL ? (
				<ToastAlert />
			) : (
				""
			)}

			<div className="divText" style={{ width: "100%" }}>
				<p className="text" style={{ textAlign: "left", marginTop: 10 }}>
					{questionario === TypesQuestionnaire.ANAMNESE
						? current_language?.enviar_anamnese
						: current_language?.enviar_sf36}
				</p>
			</div>
			<span className="subText" style={{ fontSize: 18, marginTop: -16 }}>
				{current_language?.vc_recebera}
			</span>

			<form
				className="form"
				action=""
				onSubmit={handleSubmit( 
					questionario === TypesQuestionnaire.ANAMNESE 
					? permissionAsynchronousAnamnesis
					: permissionAsynchronousSF_36
				)}
				style={{ marginTop: 4 }}
			>
				<p>{current_language?.modal_compartilhamento}</p>
				<div className="globalInput">
					<img src={email} alt="email" className="icon" />
					<input
						className="input"
						type="text"
						id="email"
						// required
						placeholder={current_language?.login_email}
						{...register("email", {
							required: `${current_language?.login_input_senha}`,
							pattern: {
								value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: `${current_language?.email_invalido}`,
							},
						})}
					/>
				</div>
				<div className="divhr">
					<hr className={errors.email ? "hr hrcor" : "hr"} />
					{errors.email && <div className="error">{errors.email.message}</div>}
				</div>
				<button 
					className="enviar"
				>
					{aprovadoEmail == true ? (
						<CircularProgress color="inherit" />
					) : (
						current_language?.enviar
					)}
				</button>
			</form>

			<p className="ou"></p>

			<button
				className="baixar"
				onClick={() => { 
					shereLinkAnamnese(Type_envio.DOWNLOAD)
					AmplitudeLog(
						questionario === TypesQuestionnaire.ANAMNESE 
						? 'copiar_link_anamnese'
						: 'copiar_link_sf_36'
					)
				}}
			>
				{!aprovadoDownload && (
					<svg
						width="17"
						height="16"
						viewBox="0 0 17 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M14.5 11V14H2.5V11H0.5V14C0.5 15.1 1.4 16 2.5 16H14.5C15.6 16 16.5 15.1 16.5 14V11H14.5ZM13.5 7L12.09 5.59L9.5 8.17V0H7.5V8.17L4.91 5.59L3.5 7L8.5 12L13.5 7Z"
							fill="#4585FF"
						/>
					</svg>
				)}
				{aprovadoDownload ? (
					<CircularProgress color="inherit" />
				) : (
					current_language?.copiar_link
				)}
			</button>
			<p className="ou">{current_language?.modal_compartilhamento_ou}</p>
			<button
				className="fPaciente"
				onClick={() => {
					if(questionario === TypesQuestionnaire.ANAMNESE ){
						permissionAnamnesisWithPatient(config_servicos, dispatch)
					}else if(questionario === TypesQuestionnaire.SF_36 ){
						permissionSF_36WithPatient(config_servicos, dispatch)
					}
				}}
			>
				{aprovadoEmail == true && loadCircularProgress == true ? (
					<CircularProgress color="inherit" />
				) : (
					current_language?.fazer_com_o_paciente
				)}
			</button>
		</ContainerEmail>
	);
};

export default ShereAnamneseAsync;
