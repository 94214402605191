import * as amplitude from '@amplitude/analytics-browser';

let plano = "";

export function handleInstanceAmplitude (user_id: number, userPlan: string){
		plano = userPlan;
    const id_usuario = user_id.toString()

    amplitude.init(`${process.env.REACT_APP_AMPLITUDE_API_KEY}`, id_usuario,{minIdLength: 1})
};

export function AmplitudeLog(log: any, metadata?: any){
	if ((!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') && window.location.hostname === 'web.elastic.fit') {
		const eventProperties: any = {
			...metadata,
			plano: plano,
		};
		return amplitude.track(log, eventProperties)
	}
	return false;
}


