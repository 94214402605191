import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 25rem;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	width: 26%;
	padding: 1rem;
	padding-top: 4rem;

	.ContainerTitle{
		display: flex;
		width: 100%;
		align-items: center;
	}
	.RemovePaciEtiqueta {
		font-family: "NotoSans-Bold";
		font-size: 28px;
		color: ${Colors.primary_blue_k_main};
		font-weight: 700;
		text-align: center;
		margin-top: 32px;
		margin-bottom: 32px;

	}

	.close {
		position: absolute;
		right: 2rem;
		top: 2rem;
		cursor: pointer;
		width: 1rem;
		height: 1rem;
	}

	.icon_warning {
		width: 3.5rem;
		height: 3.5rem;
		margin-bottom: 3rem;
	}

	.text {
		font-family: "NotoSans-Regular";
		font-weight: 400;
		text-align: center;
		color: #63677a;
		font-size: 0.9rem;
	}

	.containerFrases {
		font-family: "NotoSans-Regular";
		font-size: 1.1rem;
		font-weight: 700;
		line-height: 40px;
		text-align: center;
		color: #000e4b;
	}

	.buttons {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-top: 2rem;
	}

	.button {
		color: #000e4b;
		border: 2px;
		border-radius: 12px;
		font-family: "Kanit-Regular";
		font-weight: 600;
		line-height: 26.4px;
		text-align: center;
		background-color: transparent;
		padding: 0.3rem;
		padding-left: 2rem;
		padding-right: 2rem;
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}

	.excluir {
		border: 2px solid #ef0000;
		transition: all 0.3s ease;
	}

	.excluir:hover {
		background: #ef0000;
		color: white;
	}

	.cancelar {
		border: 2px solid #000e4b;
		transition: all 0.3s ease;
	}

	.cancelar:hover {
		background: #000e4b;
		color: white;
	}
`;
