import React from "react";
import { useSelector } from "react-redux";
import * as V from "victory";
import { TextsProps } from "../../../../../database/txt.interface";
import { Content } from "./styles";
import { Icon } from "../../../../../styles/global";
import VerifiedIcon from "../../../../../assets/icons/verified_icon.svg";
import AlertIcon from "../../../../../assets/kinologyIcons/svg/alert_icon.svg";
import { S3Desk } from "../../../../../styles/styles_text";

const GraficoEvolucaoEquilibrio: React.FC<any> = ({ desequilibrio }) => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const lados = [
		{ key: "E", value: "Esquerdo" },
		{ key: "D", value: "Direito" },
	];

	function makeData(date: string, peso: string) {
		const pesoFiltred = parseFloat(peso.replace("%", ""));
		const data = [{ x: date, y: pesoFiltred }];
		return data;
	}

	return (
		<Content>
			<div className="cards">
				{desequilibrio?.REFERENCIAS?.map((referencia: any, index: number) => {
					return (
						<>
							{(desequilibrio?.TITULO_TABELA == "Cervical"
								? [{ key: "D", value: "Direito" }]
								: lados
							).map((lado: any) => (
								<div className="card" key={index}>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
										}}
									>
										<p className="title">
											<S3Desk>
												<strong>
													{referencia?.TITULO} {lado?.value}
												</strong>
											</S3Desk>
										</p>
									</div>
									<div className="chart">
										<V.VictoryChart width={1300} >
											<V.VictoryGroup offset={65}>
												{referencia.EXECUCOES.reverse()?.map(
													(execucao: any, index: any) => (
														<V.VictoryBar
															key={index}
															data={makeData(
																execucao?.DATA,
																lado?.key == "D"
																	? execucao?.RAZAO_D
																	: execucao?.RAZAO_E
															)}
															labels={({ datum }) => [datum._y, datum.position]}
															style={{
																data: {
																	fill: ({ datum }) => {
																		return (lado?.key == "D"
																			? execucao.DESVIO_D
																			: execucao.DESVIO_E) == "POSITIVO"
																			? "#7FE179"
																			: "#D12600";
																	},
																	width: "50",
																},
																labels: {
																	fill: "white",
																	fontSize: 25,
																	fontFamily: "roboto",
																},
															}}
															labelComponent={
																<V.VictoryLabel
																	dy={50}
																	lineHeight={[3, 1]}
																	style={[
																		{ fill: "#000", fontSize: "30px" },
																		{ fill: "white", fontSize: "30px" },
																	]}
																/>
															}
														/>
													)
												)}
											</V.VictoryGroup>

											<V.VictoryAxis
												dependentAxis
												label={current_language?.forca_kg}
												style={{
													axisLabel: {
														fontSize: 25,
														fontFamily: "roboto",
													},

													tickLabels: {
														fontSize: 25,
														fill: "transparent",
													},
													axis: {
														color: "transparent",
													},
												}}
											/>

											<V.VictoryAxis
												style={{
													tickLabels: {
														fontSize: 30,
														fill: "black",
														fontFamily: "roboto",
													},
												}}
											/>
										</V.VictoryChart>
									</div>
									<table className="table">
										<tr style={{ backgroundColor: "#F1F2F3" }}>
											<td
												className="parameter"
												style={{ borderRadius: "0.625rem 0rem 0rem 0rem" }}
											>
												{current_language?.data}
											</td>
											<td className="parameter">Força máxima</td>
											<td className="parameter">Desvio</td>
										</tr>

										{referencia.EXECUCOES?.map((execucao: any, index: any) => {
											return (
												<tr key={index}>
													<td className="value">{execucao?.DATA}</td>
													<td className="value">
														{lado?.key == "D"
															? execucao?.FORCAS_D
															: execucao?.FORCAS_E}{" "}
														kg
													</td>
													<td className="value">
														{" "}
														<Icon
															src={
																(lado?.key == "D"
																	? execucao?.DESVIO_D
																	: execucao?.DESVIO_E) == "POSITIVO"
																	? VerifiedIcon
																	: AlertIcon
															}
														/>
													</td>
												</tr>
											);
										})}
									</table>
								</div>
							))}
						</>
					);
				})}
			</div>
		</Content>
	);
};

export default GraficoEvolucaoEquilibrio;
