import React, { memo, useEffect, useState } from "react";
import { FieldValues, useForm, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import backgroundLogin from "../../../assets/kinologyIcons/png/BackgrounLogin.png";
import Ajuda from "../../../assets/kinologyIcons/svg/ajuda.svg";
import Idioma from "../../../assets/kinologyIcons/svg/idioma.svg";
import LogoLogin from "../../../assets/kinologyIcons/svg/LogoLogin.svg";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import history from "../../../routes/history";
import { AppActions } from "../../../store/ducks/app";
import { AuthActions } from "../../../store/ducks/auth";
import { checkLanguage } from "../../../utils/texts";
import { Background, Conteiner, Content } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import InputTextEntryForm from '../../../components/Inputs/inputForm';
import FormSubmiteButton from "../../../components/Buttons/FormSubmitButton";
import { Colors } from "../../../styles/colors";
import Form from "../../../components/Form";
import Text from "../../../components/Texts/Text";


const Login: React.FC = () => {
	const dispatch = useDispatch();
	const methods = useForm();

	const [aprovado, setAprovado] = useState(false);

	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const config_servicos: ServicosProps = useSelector((state: any) => state.configs.config_servicos);
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const is_open = useSelector((state: any) => state.app.is_open);

	const onSubmit = async (data: FieldValues) => {
		AmplitudeLog('login_usuario', {
			usuario:{
				USUARIO_EMAIL: data.email,
				txIdioma: "pt",
				txLoginTipo: 2,
				DEVICE_UUID: "",
			}
		})
		setAprovado(true);
		const authData = {
			USUARIO_EMAIL: data.email,
			USUARIO_SENHA: data.password,
			DEVICE_UUID: "24c6482b64839e8384cJ",
			txIdioma: "pt",
			txLoginTipo: 2,
		};
		dispatch(AuthActions.auth_request(authData, true));
	};


	const handleOpenLinguagem = () => {
		dispatch(AppActions.set_modal_linguagem(true));
	};

	const helpElastic = () => {
		window.open("https://base.materials2.kinology.com.br/perguntas-frequentes", "_blank")
	};

	useEffect(() => {
		checkLanguage(language, dispatch);
	}, [language]);

	useEffect(() => {
		if (is_open_toast === true || is_open === true) {
			setAprovado(false);
		}
	}, [is_open_toast, is_open]);

	return (
		<Conteiner>
			{is_open_toast && <ToastAlert />}
			<Content>
				<img src={LogoLogin} alt="logo" className="logo" style={{width:303}} />
				<div className="interativos">
					<div className="groupForm">
						<div className="Textos">
							<h1 className="h1">Login</h1>
							<p className="subtitle">{current_language?.inicie_os_trabalhos}</p>
						</div>
						<Form 	methods={methods} 	onSubmit={(data)=>{onSubmit(data) }}>
							<InputTextEntryForm 
								type="text"
								width={100}
								title={current_language?.login_email + '*'}
								placeholder={current_language?.placeholder_digite}
								leftIcon="EmailIcon"
								fontFamily="NotoSans-SemiBold"
								fontWeight={600}
								typeInputName="email"
							/>
							<InputTextEntryForm 
								type="password"
								width={100}
								title={current_language?.login_senha + '*'}
								fontFamily="NotoSans-SemiBold"
								placeholder={'*********'}
								leftIcon="KeyPassword"
								rightIcon="CloseEye"
								fontWeight={600}
								marginTop={24}
								typeInputName="password"
							/>
							<div style={{width: '100%'}}>
								<Text
									onClick={()=>{AmplitudeLog('esqueceu_senha'), history.push('/recuperar_senha')}}
									title={current_language?.login_esqueci_senha}
									textColor={Colors.primary_blue_k_main}
									textAlign="left"
									fontSize={1.12}
									fontFamily="NotoSans-Medium"
									marginTop={18}
									textDecoration="underline"
									cursor="pointer"
									width={'40%'}
								/>
							</div>
							
							<div className="LoginCadastro">
								<>
									<FormSubmiteButton
										type="submit"
										onClick={()=> methods.trigger()}
										disabled={Object.values(methods.watch()).every((item)=> item != '') 
											? false
											: true
										}
										marginTop={20}
										fontFamily="Kanit-Medium"
										fontWeight={500}
										typeButtonComponent="primary"
										activityIndicator={!!aprovado}
										title={current_language?.login_entrar_botao}
									/>
								</>
							</div>
						</Form>
						<FormSubmiteButton
							disabled={false}
							typeButtonComponent="secondary"
							title={current_language?.criar_minha_conta}
							fontFamily="Kanit-Medium"
							fontWeight={500}
							marginTop={24}
							width={70}
							onClick={()=>{ history.push("/cadastro") , AmplitudeLog('criar_conta')}}
						/>
						<div className="global">
							<button
								type="button"
								value="Idioma"
								onClick={handleOpenLinguagem}
								className="inputIA idiomaAjuda"
							>
								<img src={Idioma} alt="logo" className="imgIA" />
								{current_language?.login_idioma}
							</button>
							<button
								type="button"
								value="Ajuda"
								onClick={helpElastic}
								className="inputIA idiomaAjuda"
							>
								<img src={Ajuda} alt="logo" className="imgIA" />
								{current_language?.login_ajuda}
							</button>
						</div>
					</div>
				</div>
			</Content>
			<Background>
				<img 
					src={backgroundLogin} 
					width={'100%'}
					height={'100%'}
				/>
			</Background>
		</Conteiner>
	);
};

export default memo(Login);
