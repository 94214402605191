export const Types = {
	ATRIBUIR_USUARIO_PERFIL: "ATRIBUIR_USUARIO_PERFIL",
	ATRIBUIR_USUARIO_PERFIL_FINALLY: "ATRIBUIR_USUARIO_PERFIL_FINALLY",
	SET_MENU_OPTION_SELECTED: "SET_MENU_OPTION_SELECTED",	
	SET_PERSIST_CURRENT_EVALUATOR: 'SET_PERSIST_CURRENT_EVALUATOR',
};

const INITIAL_STATE = {
	loading_perfil: false,
	menu_option_selected: "",
	current_evaluator: null, 
};

export default function atribuir(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.ATRIBUIR_USUARIO_PERFIL:
			return {
				...state,
				loading_perfil: true,
			};
		case Types.ATRIBUIR_USUARIO_PERFIL_FINALLY:
			return {
				...state,
				loading_perfil: false,
			};
		case Types.SET_MENU_OPTION_SELECTED:
			return {
				...state,
				menu_option_selected: action.payload,
			};
		case Types.SET_PERSIST_CURRENT_EVALUATOR:
			return {
				...state,
				current_evaluator: action.payload,
			};

		default:
			return state;
	}
}

export const AtribuirActions = {
	edit_atribuir: (data: any) => ({
		type: Types.ATRIBUIR_USUARIO_PERFIL,
		payload: data,
	}),
	edit_atribuir_finally: () => ({
		type: Types.ATRIBUIR_USUARIO_PERFIL_FINALLY,
	}),
	set_menu_option_selected: (option: any) => ({
		type: Types.SET_MENU_OPTION_SELECTED,
		payload: option,
	}),
	set_persist_current_evaluator: (id:number)=> ({
		type: Types.SET_PERSIST_CURRENT_EVALUATOR,
		payload: id
	})
};
