import styled from "styled-components";

export const Container = styled.button`
	width: 10.568rem;
	min-width: 10.568rem;
	height: 5.696rem;
	min-height: 5.696rem;
	background: #fcfcfc;

	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.08);
	border-radius: 8px;

	margin-bottom: 5px;
	padding: .5rem 1rem;

	display: flex;
	justify-content: center;
	align-items: center;

	flex-direction: column;

	cursor: pointer;

	transition: all linear .2s;

	border: 0;

	&:hover {
		background: #E9E9E9;
	}

	&:disabled {
		background-color: rgba(0, 0, 0, 0.08);
	}
`;

export const Text = styled.span`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 120%;

	margin-top: 0.842rem;

	color: #404040;

	text-align: center;
`;
