import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import cartaoCredito from "../../../assets/icons/cartaoCredito.svg";
import Editar from "../../../assets/icons/editar.svg";
import Excluir from "../../../assets/icons/excluir.svg";
import mastercard from "../../../assets/icons/mastercard.svg";
import Pontos from "../../../assets/icons/pontos.svg";
import { TextsProps } from "../../../database/txt.interface";
import {
	CartaoAlterarProps,
	FaturaProps,
} from "../../../interfaces/cartao.interface";
import { AppActions } from "../../../store/ducks/app";
import { FinancialActions } from "../../../store/ducks/financial";
import { Conteiner, Header, ContainerGoBack, CardInvoice, ContainerValueInvoice, ContainerIConValue, Content, BodyEdite, ButtonLogoutInadimplente } from "./styles";
import { AuthActions } from "../../../store/ducks/auth";

import {ReactComponent as ArrowBack} from '../../../assets/kinologyIcons/svg/arrow_back.svg';
import {ReactComponent as ArrowInvoice} from '../../../assets/kinologyIcons/svg/arrow_forward_ios.svg';
import {ReactComponent as WorningInvoices} from '../../../assets/kinologyIcons/svg/warningInvoices.svg';

import Text from '../../../components/Texts/Text';
import { Colors } from "../../../styles/colors";
import history from "../../../routes/history";



enum TypeFatura {
	BOLETO = 'Boleto',
	PENDING = 'pending',
};

const Fatura: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);

	useEffect(() => {
		dispatch(FinancialActions.financial_request(
			config_servicos?.SERVICO_IUGU,
			config_servicos?.SERVICO_SUPERLOGICA
		));
	}, []);

	const faturas = useSelector((state: any) => state.financial.faturas);
	
	const faturaFiltro = faturas?.filter(
		(fatura: FaturaProps) => fatura?.FATURA_TIPO === TypeFatura?.BOLETO || fatura?.FATURA_TIPO === TypeFatura?.PENDING
	);
	const numberFaturas = faturaFiltro?.length;

	const handleConta = () => {
		dispatch(AppActions.set_open_editar_perfil(true));
	};

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("faturas"));
	}, []);

	const ver_fatura = (url: string) => {
		window.open(url, "_blank");
	};
	const is_inadimplente = useSelector(
		(state: any) => state.app.is_inadimplente
	);

	const language: any = useSelector((state: any) => state.configs.language);

	const formatDate = (date: any) => {
		if(config_servicos?.SERVICO_SUPERLOGICA) {
			const splitDate = date.split('/');
			const formatted = language == 'EN' && date || `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
			return formatted;	
		}
		if(config_servicos?.SERVICO_IUGU && !config_servicos?.SERVICO_SUPERLOGICA) {
			const splitDate = date.split('-');
			const formatted = language == 'EN' && date || `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
			return formatted;
		}
	}
	const formatPrice = (price: any) => (price / 100)?.toFixed(2).replace(/\./g, ',');

	//UI
	return (
		<Conteiner>
			<BodyEdite >
				{numberFaturas <= 0 || numberFaturas == undefined ? (

					<>
						<Header >
							<ContainerGoBack 
								onClick={()=> {
									history.goBack()
									dispatch(AppActions.set_option_menu(false, current_language?.menu_lateral_minha_conta))
								}}
							>
								<ArrowBack />
								<Text 
									title={current_language.pagamentos}
									fontFamily="NotoSans-Regular"
									fontSize={1}
									fontWeight={400}
									textColor={Colors?.primary_blue_k_main}
									marginLeft={6}
									cursor="pointer"
								/>
							</ContainerGoBack>
							<Text 
								title={current_language.menu_lateral_faturas}
								fontFamily="NotoSans-Regular"
								fontSize={2}
								fontWeight={700}
								textColor={Colors?.primary_blue_k_main}
								marginTop={15}
							/>
						</Header>
						<div className="semFatura">
							<h2 className="informacao">
								{current_language?.menu_lateral_faturas_pendente}
							</h2>
						</div>
					</>
				) : (
					<>
						<Header >
							<ContainerGoBack 
								onClick={()=> {
									history.goBack()
									dispatch(AppActions.set_option_menu(false, current_language?.menu_lateral_minha_conta))
								}}
							>
								<ArrowBack />
								<Text 
									title={current_language.pagamentos}
									fontFamily="NotoSans-Regular"
									fontSize={1}
									fontWeight={400}
									textColor={Colors?.primary_blue_k_main}
									marginLeft={6}
									cursor="pointer"
								/>
							</ContainerGoBack>
							<Text 
								title={current_language.menu_lateral_faturas}
								fontFamily="NotoSans-Regular"
								fontSize={2}
								fontWeight={700}
								textColor={Colors?.primary_blue_k_main}
								marginTop={15}
							/>
						</Header>
						
						{	
							faturaFiltro?.map((fatura: any, index: number) => {
								return (
									<CardInvoice 
										key={index}
									>	
										<Content onClick={() => ver_fatura(fatura?.FATURA_URL)}>
											<Text 
												title={current_language?.menu_lateral_fatura_pendente}
												fontFamily="NotoSans-Regular"
												fontSize={2}
												fontWeight={700}
												textColor={Colors?.primary_blue_k_dark_2}
												cursor="pointer"
												marginTop={20}
												textAlign="left"
											/>
											<Text 
												title={current_language?.pagamento_vencimento + formatDate(fatura?.FATURA_VALIDADE)}
												fontFamily="NotoSans-Medium"
												fontSize={1.25}
												fontWeight={500}
												textColor={Colors?.primary_blue_k_dark_2+53}
												cursor="pointer"
												marginTop={6}
												textAlign="left"
											/>
											
											<ContainerValueInvoice>
												<ContainerIConValue>
													<WorningInvoices />
													<Text 
														title={'R$'+ formatPrice(fatura?.FATURA_VALOR)}
														fontFamily="NotoSans-Regular"
														fontSize={1.75}
														fontWeight={600}
														textColor={Colors?.tertirary_red_1_dark}
														textAlign="left"
														marginLeft={8}
														marginTop={8}
													/>
												</ContainerIConValue>
												<ArrowInvoice color={Colors?.primary_blue_k_main}/>
											</ContainerValueInvoice>
										</Content>
									</CardInvoice>
								);
							})
						}
					</>
				)}
				{ !!is_inadimplente && (
						<div style={{display: 'flex', justifyContent: 'center'}}>
							<ButtonLogoutInadimplente
								onClick={()=> dispatch(AuthActions.auth_logout())}
							>
								{current_language?.sair_modal}
							</ButtonLogoutInadimplente>
						</div>
					)
				}
			</BodyEdite>
		</Conteiner>
	);
};

export default memo(Fatura);
