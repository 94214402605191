import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import AdicionarLogo from "../../../components/AdicionarLogo";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { RelatorioPersonal } from "../../../interfaces/config.interface";
import { AppActions } from "../../../store/ducks/app";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import { AmplitudeLog } from "../../../utils/amplitude";
import { ContainerGoBack, Conteiner, Content, Header } from "./styles";

import { ReactComponent as ArrowBack } from "../../../assets/kinologyIcons/svg/arrow_back.svg";

import { Colors } from "../../../styles/colors";
import history from "../../../routes/history";

import Text from "../../../components/Texts/Text";
import Form from "../../../components/Form";
import InputTextEntryForm from "../../../components/Inputs/inputForm";
import FormSubmiteButton from "../../../components/Buttons/FormSubmitButton";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { ContainerDash, Icon, Return, Scroll } from "../../../styles/global";
import { P3Desk } from "../../../styles/styles_text";
import ReturnIcon from "../../../assets/icons/back_page.svg";

interface Usuario {
	celular: string;
	cpf: string;
}

const PersonalizarRelatorio: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const methods = useForm();

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("personalizarRelatorio"));
	}, []);

	const [usuario, setUsuario] = useState<Usuario>({} as Usuario);
	const hendleChange = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUsuario({
				...usuario,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[usuario]
	);

	const image_relatorio = useSelector(
		(state: any) => state.relatorios.image_relatorio
	);

	const config_relatorio: RelatorioPersonal = useSelector(
		(state: any) => state.configs.config_relatorio
	);

	const handleKeyUpCEP = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		if (current_language.idioma === "Português") {
			// 12345-678
			e.currentTarget.maxLength = 9;
			const value = e.currentTarget.value;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/(\d{5})(\d)/, "$1-$2");

			e.currentTarget.value = ajustado;
		} else {
			e.currentTarget.maxLength = 14;
			const value = e.currentTarget.value;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			e.currentTarget.value = ajustado;
		}
	}, []);

	const handleContato = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		if (current_language.idioma === "Português") {
			// (99)99999-9999
			e.currentTarget.maxLength = 15;
			const value = e.currentTarget.value;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			if (ajustado.length > 10) {
				ajustado = ajustado.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
			} else if (ajustado.length > 5) {
				ajustado = ajustado.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
			} else if (ajustado.length > 2) {
				ajustado = ajustado.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
			} else {
				ajustado = ajustado.replace(/^(\d*)/, "($1");
			}
			e.currentTarget.value = ajustado;
		} else {
			// + 456 544 344 454
			e.currentTarget.maxLength = 17;
			const value = e.currentTarget.value;
			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			ajustado = ajustado.replace(/^(\d{3})/, "+ $1");

			e.currentTarget.value = ajustado;
		}
	}, []);

	if (image_relatorio == undefined) {
		dispatch(
			RelatorioActions.set_imagem_relatorio(
				config_relatorio?.CONFIG_LOGOMARCA_BASE64
			)
		);
	}
	useEffect(() => {
		methods.setValue("celular", config_relatorio?.CONFIG_EMPRESA_TELEFONE);
		methods.setValue("email", config_relatorio?.CONFIG_EMPRESA_EMAIL);
		methods.setValue("empresa", config_relatorio?.CONFIG_EMPRESA_NOME);
		methods.setValue(
			"lagradouro",
			config_relatorio?.CONFIG_ENDERECO_LOGRADOURO
		);
		methods.setValue("bairro", config_relatorio?.CONFIG_ENDERECO_BAIRRO);
		methods.setValue("cidade", config_relatorio?.CONFIG_ENDERECO_CIDADE_UF);
		methods.setValue("cep", config_relatorio?.CONFIG_ENDERECO_CEP_ZIP);
		dispatch(
			RelatorioActions.set_imagem_relatorio(
				config_relatorio?.CONFIG_LOGOMARCA_BASE64
			)
		);
	}, [config_relatorio]);

	const [aprovado, setAprovado] = useState(false);

	const onSubmit = (data: any) => {
		setAprovado(true);
		const dat_request = {
			CONFIG_LOGOMARCA_BASE64: image_relatorio,
			CONFIG_EMPRESA_TELEFONE: data.celular,
			CONFIG_EMPRESA_EMAIL: data.email,
			CONFIG_EMPRESA_NOME: data.empresa,
			CONFIG_ENDERECO_LOGRADOURO: data.lagradouro,
			CONFIG_ENDERECO_BAIRRO: data.bairro,
			CONFIG_ENDERECO_CIDADE_UF: data.cidade,
			CONFIG_ENDERECO_CEP_ZIP: data.cep,
		};
		AmplitudeLog("relatorio_personalizado_sucesso", {
			body: dat_request,
		});
		dispatch(RelatorioActions.personalizar_relatorio_request(dat_request));
	};

	const voltar = () => {
		window.history.back();
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	useEffect(() => {
		if (is_open_toast) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	return (
		<ContainerDash>
			{is_open_toast && <ToastAlert />}
			<Return
				onClick={() => {
					dispatch(DashBoardActions.set_current_page("minha_conta"));
					dispatch(
						AppActions.set_option_menu(
							false,
							current_language?.menu_lateral_minha_conta
						)
					);
				}}
			>
				<Icon src={ReturnIcon} style={{ width: "1rem", height: "1rem" }} />
				<P3Desk>Minha conta</P3Desk>
			</Return>
			<Scroll style={{ width: "60%" }}>
				<AdicionarLogo />
				<Form
					style={{width: "60%"}}
					methods={methods}
					onSubmit={(data) => {
						onSubmit(data);
					}}
				>
					<InputTextEntryForm
						type="number"
						width={100}
						title={current_language?.primeiro_acesso_celular + "*"}
						placeholder={"( ) 00000-0000"}
						fontFamily="NotoSans-SemiBold"
						fontWeight={600}
						leftIcon="Phone"
						marginTop={24}
						typeInputName="celular"
					/>
					<InputTextEntryForm
						type="text"
						width={100}
						title={"E-mail*"}
						placeholder={current_language?.placeholder_digite}
						leftIcon="EmailIcon"
						fontFamily="NotoSans-SemiBold"
						fontWeight={600}
						marginTop={24}
						typeInputName="email"
					/>
					<InputTextEntryForm
						type="text"
						width={100}
						title={current_language?.menu_lateral_person_empresa + "*"}
						placeholder={current_language?.placeholder_digite}
						leftIcon="Business"
						fontFamily="NotoSans-SemiBold"
						fontWeight={600}
						marginTop={24}
						typeInputName="empresa"
					/>
					<InputTextEntryForm
						type="text"
						width={100}
						title={current_language?.menu_lateral_person_endereco + "*"}
						placeholder={current_language?.placeholder_digite}
						leftIcon="PositionIcon"
						fontFamily="NotoSans-SemiBold"
						fontWeight={600}
						marginTop={24}
						typeInputName="lagradouro"
					/>
					<InputTextEntryForm
						type="text"
						width={100}
						title={current_language?.menu_lateral_person__cep + "*"}
						placeholder={current_language?.placeholder_digite}
						leftIcon="PositionIcon"
						fontFamily="NotoSans-SemiBold"
						fontWeight={600}
						marginTop={24}
						typeInputName="cep"
					/>
					<InputTextEntryForm
						type="text"
						width={100}
						title={current_language?.menu_lateral_person_bairro + "*"}
						placeholder={current_language?.placeholder_digite}
						leftIcon="PositionIcon"
						fontFamily="NotoSans-SemiBold"
						fontWeight={600}
						marginTop={24}
						typeInputName="bairro"
					/>
					<InputTextEntryForm
						type="text"
						width={100}
						title={current_language?.menu_lateral_person_cidade + "*"}
						placeholder={current_language?.placeholder_digite}
						leftIcon="PositionIcon"
						fontFamily="NotoSans-SemiBold"
						fontWeight={600}
						marginTop={24}
						typeInputName="cidade"
					/>
					<FormSubmiteButton
						type="submit"
						onClick={() => methods.trigger()}
						disabled={
							Object.values(methods.watch()).every((item: string) => item != "")
								? false
								: true
						}
						marginTop={40}
						fontFamily="Kanit-Medium"
						typeButtonComponent="primary"
						activityIndicator={aprovado}
						title={current_language?.menu_lateral_botao_salvar}
					/>
				</Form>
			</Scroll>
		</ContainerDash>
	);
};

export default PersonalizarRelatorio;
