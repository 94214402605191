import { Fade } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import addEtiqueta from "../../../assets/icons/addEtiqueta.svg";
import Celular from "../../../assets/icons/celular.svg";
import Localizacao from "../../../assets/icons/localizacao.svg";
import cpfUser from "../../../assets/icons/cpfAvaliador.svg";
import Datanasc from "../../../assets/icons/datanasc.svg";
import emailGray from "../../../assets/icons/emailGray.svg";
import Fechar from "../../../assets/icons/fechar.svg";
import user from "../../../assets/icons/user.svg";
import Cpf from "../../../assets/icons/user.svg";
import { TextsProps } from "../../../database/txt.interface";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { AppActions } from "../../../store/ducks/app";
import { TypesErrorRequest } from "../../../utils/types";
import FotoPerfilMenor from "../../FotoPerfilMenor";
import ToastAlert from "../../toast";
import { Conteiner } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import handleMaskCPF from "../../MascaraCPF";
import handleMaskPhone from "../../MascaraTel";
import { DashBoardActions } from "../../../store/ducks/dashboard";


interface Usuario {
	celular: string;
	cpf: string;
}

const AdicionarAvaliador = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const is_open = useSelector(
		(state: any) => state.app.is_open_adicionar_avaliador
	);

	let photo = useSelector((state: any) => state.add_pac_aval.photo);

	const ModalAdicionarPacienteClose = () => {
		dispatch(AppActions.set_modal_adicionar_avaliador(false));
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();


	const [aprovado, setAprovado] = useState(false);

	const onSubmit = (data: any) => {
		setAprovado(true);
		if (photo === undefined) {
			photo = null;
		}
		const dat_request = {
			USUARIO_NIVEL: data?.adm ? "1" : "2",
			USUARIO_NOME: data?.nome,
			USUARIO_EMAIL: data?.email,
			USUARIO_CONTATO: data?.celular,
			USUARIO_CPF: data?.cpf,
			USUARIO_ESTATURA: data?.altura,
			USUARIO_MASSA: data?.massa,
			USUARIO_NASCIMENTO:
				current_language?.idioma === "Português"
					? data?.data?.split("/").reverse().join("-")
					: current_language?.idioma === "English"
					? `${data?.data?.substr(6, 10)}-${data?.data
							?.substr(0, 5)
							.split("/")
							.reverse()
							.join("-")}`
					: data?.data.split("-").reverse().join("-"),
			USUARIO_SEXO: data?.sexo,
			USUARIO_CONDICIONAMENTO: data?.condicionamento,
		};
		AmplitudeLog('avaliador_criado', {
			body: dat_request
		})
		dispatch(AddPacAvalActions.request_add_evaluetor(dat_request));
		reset()
		dispatch(AppActions.set_modal_adicionar_avaliador(false));
		dispatch(DashBoardActions.avaliadores_request());
	};

	const [usuario, setUsuario] = useState<Usuario>({} as Usuario);
	const hendleChange = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUsuario({
				...usuario,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[usuario]
	);

	const [us, setUs] = useState<Usuario>({} as Usuario);
	const hendleChange2 = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUs({
				...us,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[us]
	);

	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			modal: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			paper: {
				backgroundColor: theme.palette.background.paper,
				border: "0",
				boxShadow: theme.shadows[5],
			},
		})
	);

	const classes = useStyles();

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const type_toast = useSelector((state: any) => state.app.type_toast);

	useEffect(() => {
		if (type_toast == TypesErrorRequest.SUCCESS) {
			ModalAdicionarPacienteClose();
		}
		if (is_open_toast == true) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				onClose={ModalAdicionarPacienteClose}
				open={is_open}
				closeAfterTransition
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open}>
					<Conteiner>
						{is_open_toast && type_toast == TypesErrorRequest.FAIL ? (
							<ToastAlert />
						) : (
							""
						)}
						<div className="fechar">
							<button className="botaofechar">
								<img
									src={Fechar}
									alt=""
									onClick={()=> {
										ModalAdicionarPacienteClose(), 
										AmplitudeLog('fechar_modal_add_avaliador')
									}}
								/>
							</button>
						</div>
						<form onSubmit={handleSubmit(onSubmit)} className="form">
							<div className="sup">
								<div className="NomeEperfil">
									<h1 className="NovoPc">
										{current_language?.adicionar_avaliador}
									</h1>
									<FotoPerfilMenor />
								</div>
							</div>
							<div className="mid" >
								<div className="formP1">
									<div className="globalInput" >
										<img src={user} alt="key" className="icon" />
										<input
											type="text"
											id="nome"
											placeholder={current_language?.primeiro_acesso_nome}
											className="input"
											{...register("nome", {
												required: `${current_language?.login_input_senha}`,
												pattern: {
													value:
														/[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{3,35}/i,
													message: "Nome inválido",
												},
											})}
										/>
									</div>
									<div className="divhr">
										<hr className={errors.nome ? "hrcor" : "hr"} />
										{errors.nome && (
											<div className="error">{errors.nome.message}</div>
										)}
									</div>
									<div className="globalInput" >
										<img src={cpfUser} alt="cpf" className="icon" />
										<input
											type="text"
											id="cpf"
											onKeyDown={handleMaskCPF}
											placeholder={current_language?.primeiro_acesso_CPF}
											className="input"
											{...register("cpf", {
												pattern: {
													value:
														/(0[0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/i,
													message: "CPF inválido",
												},
											})}
											{...hendleChange2}
										/>
									</div>
									<div className="divhrCPF" >
										<hr className={errors.cpf ? "hr hrcor" : "hr"} />
										{errors.cpf && (
											<div className="error">{errors.cpf.message}</div>
										)}
									</div>
									<div className="grupo">
										<div className="adm">
											<p className="input">
												{current_language?.add_avaliador}
											</p>
											<label className="switch">
												<input type="checkbox" {...register("adm")} />
												<span className="slider round"></span>
											</label>
										</div>
									</div>
								</div>
								<div className="formP2">
									<div className="formP21" >
										<div className="grupo" >
											<div className="agrupar">
												<div className="globalInput">
												<img src={emailGray} alt="key" className="icon" />
												<input
													type="text"
													id="email"
													placeholder={current_language?.login_email}
													className="input"
													{...register("email", {
														required: `${current_language?.login_input_senha}`,
														pattern: {
															value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
															message: "E-mail inválido",
														},
													})}
												/>
												</div>
												<div className="divhr">
													<hr className={errors.email ? "hr hrcor" : "hr"} />
													{errors.email && (
														<div className="error">{errors.email.message}</div>
													)}
												</div>
											</div>
										</div>
										<div className="globalInput">
											<img 
												src={Celular} 
												alt="key" 
												className="icon" 
												style={{marginTop: 4}}
											/>
											<input
												type="text"
												id="celular"
												onKeyDown={handleMaskPhone}
												placeholder={'Celular'}
												className="input"
												{...register("celular", {
													required: `${current_language?.login_input_senha}`,
												})}
												{...hendleChange}
											/>
										</div>
										<div className="divhr">
											<hr className={errors.celular ? "hr hrcor" : "hr"} />
											{errors.celular && (
												<div className="error">{errors.celular.message}</div>
											)}
										</div>
										
									</div>
								</div>
							</div>
							<div className="inf">
								<button 
									id="concluir" className="concluir"
								>
									{aprovado == true ? (
										<CircularProgress color="inherit" />
									) : (
										current_language?.pagamento_salvar
									)}
								</button>
							</div>
						</form>
					</Conteiner>
				</Fade>
			</Modal>
		</div>
	);
};

export default AdicionarAvaliador;
