import React, { useEffect, useState } from "react";
import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ContainerOptions } from "./styles";

import buttonClose from "../../../../assets/icons/iconClose.svg";
import { PacientesProps } from "../../../../interfaces/pacientes.interface";
import { ProtocolosProps } from "../../../../interfaces/protocolo.interface";
import { DashBoardActions } from "../../../../store/ducks/dashboard";
import { Close, Container } from "../../../../styles/styles_modais_padroes";
import { P1Desk, P2Desk } from "../../../../styles/styles_text";
import Filter from "../../../../assets/icons/filter.svg";

import { ButtonSave, Checkbox, Icon } from "../../../../styles/global";
import { AssimetriaPros } from "../../../../interfaces/assimetria.interface";

const ModalFilterGrupamento: React.FC = () => {
	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			modal: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			paper: {
				backgroundColor: theme.palette.background.paper,
				border: "0",
				boxShadow: theme.shadows[5],
			},
		})
	);
	const dispatch = useDispatch();
	const classes = useStyles();

	const [idCheck, setIdCheck] = useState<number[]>([]);
	const [dropDown, setDropDown] = useState<number[]>([]);

	const [lengthProtocol, setLengthprotocol] = useState<any>();

	const open_modal_filter_agrupamento = useSelector(
		(state: any) => state.manage_protocol.is_open_modal_filter_agrupamento
	);

	const protocolos: ProtocolosProps[] = useSelector(
		(state: any) => state.manage_protocol.protocolos
	);
	const categorias_atribuidas: number[] = useSelector(
		(state: any) => state.dashboard.categorias_atribuidas
	);
	const searchProtocolInStorage = useSelector(
		(state: any) => state.dashboard.ids_storage_protocol
	);

	useEffect(() => {
		if (open_modal_filter_agrupamento) {
			if (categorias_atribuidas?.length == 0) {
				setIdCheck([]);
			}
		}
	}, [!!open_modal_filter_agrupamento]);

	useEffect(() => {
		dispatch(DashBoardActions.search_protocol_in_storage(idCheck));
	}, [idCheck]);

	useEffect(() => {
		if (categorias_atribuidas?.length > 0) {
			setIdCheck(categorias_atribuidas);
			setDropDown(categorias_atribuidas);
		}
	}, [!!open_modal_filter_agrupamento]);

	const handleCheckBox = (id: number) => {
		if (idCheck?.includes(id)) {
			setIdCheck(idCheck?.filter((item) => item !== id));
			setDropDown(dropDown?.filter((item) => item !== id));
		} else {
			setIdCheck([...idCheck, id]);
			setDropDown([...dropDown, id]);
		}
	};

	useEffect(() => {
		const searchLengthProtocols = protocolos?.filter((item: any) =>
			searchProtocolInStorage.includes(item?.ID_CATEGORIA_PROTOCOLO)
		);
		setLengthprotocol(searchLengthProtocols?.length);
	}, [searchProtocolInStorage]);

	const assimetrias: AssimetriaPros[] = useSelector(
		(state: any) => state.dashboard.assimetria
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const assimetria: any = assimetrias.filter(
		(ass) => ass.ID_USUARIO == patient.ID_USUARIO
	);

	const [agrupamentos, setAgrupamentos] = useState<any>([]);

	useEffect(() => {
		if (assimetria[0]?.LISTAS) {
			const arrayDeObjetos = Object.values(assimetria[0]?.LISTAS);
			const aux_grupamentos: any = [];
			arrayDeObjetos.map((item: any) => {
				item.map((i: any) => {
					aux_grupamentos.push({
						AGRUPAMENTO_DESCRICAO_EN: i.AGRUPAMENTO_DESCRICAO_EN,
						AGRUPAMENTO_DESCRICAO_ES: i.AGRUPAMENTO_DESCRICAO_ES,
						AGRUPAMENTO_DESCRICAO_PT: i.AGRUPAMENTO_DESCRICAO_PT,
						ID_AGRUPAMENTO: i.ID_AGRUPAMENTO,
					});
				});
			});

			const uniqueTitles = new Set();
			const uniqueArray = aux_grupamentos.filter((item: any) => {
				if (!uniqueTitles.has(item.AGRUPAMENTO_DESCRICAO_PT)) {
					uniqueTitles.add(item.AGRUPAMENTO_DESCRICAO_PT);
					return true;
				}
				return false;
			});

			setAgrupamentos(uniqueArray);
		}
	}, [assimetrias]);

	const HandleOptions = (item: any, index: number) => {
		return (
			<ContainerOptions key={index}>
				<div
					onClick={() => handleCheckBox(item?.ID_AGRUPAMENTO)}
					style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
				>
					<Checkbox
						type="checkbox"
						checked={idCheck.includes(item?.ID_AGRUPAMENTO)}
						style={{ marginRight: "0.5rem" }}
					></Checkbox>

					<P2Desk>{item?.AGRUPAMENTO_DESCRICAO_PT}</P2Desk>
				</div>
			</ContainerOptions>
		);
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open_modal_filter_agrupamento}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open_modal_filter_agrupamento}>
					<Container>
						<div className="Header">
							<P1Desk className="text">
								Selecione os exercícios que deseja visualizar:
							</P1Desk>
							<Close className="close" src={buttonClose} alt="Close" />

							<div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
								<Icon
									src={Filter}
									alt=""
									style={{
										marginRight: "0.5rem",
									}}
								/>
								<P2Desk
									className="Length"
									style={{
										color: lengthProtocol > 0 ? "#2E5AAC" : "#798087",
										marginRight: "0.2rem",
									}}
								>
									{lengthProtocol + " "}
								</P2Desk>
								<P2Desk
									className="Disponiveis"
									style={{
										color: lengthProtocol > 0 ? "#2E5AAC" : "#798087",
									}}
								>
									exercícios selecionados
								</P2Desk>
							</div>
						</div>

						<P2Desk>Selecionar todos (6)</P2Desk>

						<div
							style={{
								display: "flex",
								flexDirection: "row",
								height: "100%",
							}}
						>
							<div style={{ marginRight: "2rem" }}>
								<div>
									{agrupamentos?.map((item: any, index: number) =>
										HandleOptions(item, index)
									)}
								</div>
							</div>
						</div>

						<ButtonSave active={true}>
							<div className="textSave">Filtrar</div>
						</ButtonSave>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};
export default ModalFilterGrupamento;

