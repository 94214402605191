import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	width: 35vw;
	padding: 2rem;
	background-color: #FAFAFA;
	align-items: center;
	justify-content: center;

	.choice {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.buttons {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.botao {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.select {
		width: 1.5rem;
		margin-bottom: 2rem;

		fill: #001574;
	}

	.bordaNormal {
		border: 2px solid #fff;
		transition: all 0.2s ease-in;
	}

	.bordaAzul {
		border: 2px solid #54DFAC;
		transition: all 0.2s ease-in;
	}

	.card {
		width: 48%;
		height: 17rem;

		border-radius: 1rem;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);

		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;

		label {
			cursor: pointer;
			font-size: 1.5rem;
			font-weight: 500;
			margin-top: 1rem;
			color: #7f7f7f;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			svg {
				margin-top: 1.2rem;
				margin-bottom: 0.5rem;
			}

			.svg {
				margin-top: 1.2rem;
				margin-bottom: 1.5rem;
			}
		}

		
	}

	.corNormal {
		margin-top: 1rem;
		color: #7f7f7f;
		transition: all 0.2s ease-in;
	}

	.corSelected {
		margin-top: 1rem;
		color: ${Colors.primary_blue_k_main};
		transition: all 0.2s ease-in;
	}

	.select {
		width: 1.5rem;
		margin-bottom: 2rem;

		fill: #001574;
		transition: all 0.2s ease-in;
	}

	.groupRadio {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		transition: all 0.2s ease-in;

		margin-bottom: 1rem;
	}

	.groupRadio input {
		opacity: 0;
		cursor: pointer;
		z-index: 999;
		height: 25px;
		transition: all 0.2s ease-in;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eaebeb;
		border-radius: 50%;
		transition: all 0.2s ease-in;
	}

	.checkmarkSeleted {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		border: 0.3rem solid #eaebeb;
		background-color: ${Colors.secondary_green_k_medium_1};
		border-radius: 50%;
		transition: all 0.2s ease-in;
	}

	.textAvFunc {
		font-size: 0.8rem;
		font-weight: 400;
		width: 80%;

		text-align: center;
	}
`;
