import styled, { css } from 'styled-components';
import { InputTextEntryFormProps } from './Models';
import { Colors } from '../../styles/colors';

export const ContainerInputForm = styled.div<Partial<InputTextEntryFormProps>>`
    width: ${({ width }) =>  width ? `${width}%` : ` ${100}%`};
    height: ${({ height }) =>  height && `${height}px`  };
    background-color: ${({bgColor})=> bgColor ? bgColor : 'transparent'};
    border-radius: ${({borderRadius})=> borderRadius ? borderRadius : 0}px;
    border-width: ${({borderWidth})=> borderWidth ? borderWidth  : 0}px;
    border-color: ${({borderColor})=> borderColor ?  borderColor : 'transparent'};
    margin-top: ${({marginTop})=> marginTop ? marginTop : 0}px;
    margin-bottom: ${({margintBottom})=> margintBottom ? margintBottom : 0}px;
    margin-left: ${({marginLeft})=> marginLeft ? marginLeft : 0}px;
    margin-right: ${({marginRight})=> marginRight ? marginRight : 0}px;
    padding-top: ${({paddingTop})=> paddingTop ? paddingTop : 0}px;
    padding-bottom: ${({paddingBottom})=> paddingBottom ? paddingBottom : 0}px;
    padding-left: ${({paddingLeft})=> paddingLeft ? paddingLeft: 0 }px;
    padding-right: ${({paddingRight})=> paddingRight ? paddingRight : 2.5}px;
`;

export const ContentInput = styled.div<Pick<InputTextEntryFormProps, 'borderBottomColor'>>`
    display: flex;
    width: 100%;
    flex-direction: row;
    border-bottom: solid 2px;
    border-bottom-color: ${({borderBottomColor})=> borderBottomColor ? borderBottomColor  : Colors?.primary_blue_k_main_opacity};
`;

export const Input = styled.input<Partial<InputTextEntryFormProps & InputEvent>>`
    border: none;
    width: ${({width})=> width ? width : `${100}%`};

    ::-webkit-input-placeholder {
        color: ${Colors.primary_blue_k_main+50};
        font-size: 1.25rem;
    }
`;

export const HandleIcon = styled.img``;

export const ContainerIcon = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
`;