import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	display: flex;
	margin-top: 2rem;

	.labelForInput {
		display: flex;
		flex-direction: column;
		cursor: pointer;
	}

	.conteinerImg {
		background-color: #e5e5e5;
		width: 7.5rem;
		height: 7.5rem;
		border-radius: 50%;
		padding: 5.8px;
	}

	.imgForInput {
		width: 6.8rem;
		height: 6.8rem;
		border-radius: 50%;
		object-fit: cover;
	}

	.camera {
		display: flex;
		margin-left: 75px;
		width: 45px;
		height: 45px;
		margin-top: -40px;
	}

	.ellipse {
		margin-left: 75px;
		width: 45px;
		height: 45px;
		margin-top: -40px;
		background: #4D4D4D;
		align-items: center;
		display: flex;
		border-radius: 50%;
		justify-content: center;
	}

	.lixeira {
		display: flex;
		width: 35px;
		height: 35px;
	}
`;
