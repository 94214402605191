import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 539px;
	width: 722px;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	.close {
		width: 21px;

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		cursor: pointer;
	}

	.alert {
		margin-bottom: 3rem;
		width: 12%;
	}

	.divText {
		width: 65%;
	}

	.text {
		color: #b3b3b3;
		font-weight: 500;
		font-family: Roboto;
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
		margin-bottom: 2rem;
	}

	.buttons {
		width: 70%;
		display: flex;
		justify-content: space-between;
		margin-top: 2rem;
	}

	.button {
		font-family: Roboto;
		font-size: 1.5rem;
		line-height: 1.758rem;
		color: #ffffff;
		padding: 1.3rem 3.3rem;
		border: 0;
		border-radius: 0.5rem;
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
	}

	.confirmar {
		border: 3px solid ${Colors.secondary_green_k_medium_1};
		background: ${Colors.secondary_green_k_medium_1};
		transition: all 0.3s ease;
	}

	.confirmar:hover {
		background: transparent;
		color: ${Colors.secondary_green_k_medium_1};
	}

	.cancelar {
		border: 3px solid #7f7f7f;
		background: #7f7f7f;
		transition: all 0.3s ease;
	}

	.cancelar:hover {
		background: transparent;
		color: #7f7f7f;
	}
`;
