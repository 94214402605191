import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import alert from "../../../assets/icons/alertUpgrade.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";
import history from "../../../routes/history";
import { ServiceTypePatient } from "../../../interfaces/planos.interface";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalUpgrade: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const classes = useStyles();
	const is_open_upgrade = useSelector(
		(state: any) => state.app.is_open_upgrade
	);
	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const block_evaluated_patients = useSelector(
		(state: any) => state.app.block_evaluated_patients,
	);
	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_upgrade(false, false));
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_upgrade}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_upgrade}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<div className="divText">
							<div>
								<img
									src={alert}
									style={{
										width: 150,
										height: 150
									}}
								/>
							</div>
							<h1 className="title">
								{!!block_evaluated_patients
									? current_language?.limite_pacientes_avaliados_atingido
									: current_language?.limite_cadastro
								}
							</h1>
							<p className="text">
								{ !!block_evaluated_patients
									? current_language?.limite_pacientes_avaliados_mes
									: config_servicos?.SERVICO_PACIENTES_TIPO != ServiceTypePatient?.MENSAL
										? current_language?.voce_chegou_ao_limite
										: current_language?.limite_cadastros_mensal
								}
							</p>
						</div>
						<button 
							onClick={()=> {
								history.push('/upgrade')
								dispatch(AppActions.set_modal_upgrade(false));
							}}
							className="button upgrade"
						>
							UPGRADE
						</button>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default  memo(ModalUpgrade);
