import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 753px;
    height: 588px;
    border-radius: 24px;
    background-color: white;

    .containerBg {
        display: flex;
        width: 100%;
        height: 45%;
        align-items: center;
        justify-content: center;
        /* background-color: black; */

        .BG {
            display: flex;
            width: 50%;
            height: 100%;
            align-items: center;
            justify-content: center;
            /* background-color: blue; */
        }
    }

    .ContainerText {
        display: flex;
        width: 100%;
        height: 20%;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        line-height: 34.5px;
        /* background-color: blue; */

        .Text {
            display: flex;
            text-align: center;
            font-size: 23px;
            font-weight: 400;
            color: #09101D;
        }
        .containerNomePaci {
            display: flex;
            flex-direction: row;

            .namePaci {
                display: flex;
                color: #E5085C;
                font-size: 23px;
                font-weight: 700;
    
            }
            .interrogacao {
                display: flex;
                font-size: 23px;
                font-weight: 400;
                color: #09101D;
            }
        }
        

    }

    .containerButtons {
        display: flex;
        width: 100%;
        height: 18%;
        align-items: center;
        justify-content: space-around;
        /* background-color: brown; */

        .Positive {
            display: flex;
            width: 92px;
            height: 92px;
            padding: 14px, 18px, 14px, 18px;
            align-items: center;
            justify-content: center;
            border-radius: 48px;
            cursor: pointer;
            background-color: #23A757;
        }
        .Negative {
            display: flex;
            width: 92px;
            height: 92px;
            align-items: center;
            justify-content: center;
            border-radius: 48px;
            transform: rotate(180deg);
            padding: 14px, 18px, 14px, 18px;
            cursor: pointer;
            background-color: #DA1414
            
        }
    }

    .footer {
        display: flex;
        width: 100%;
        height: 18%;
        align-items: center;
        justify-content: center;
        /* background-color: darkblue; */

        .pular {
            display: flex;
            color: #141622;
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;

        }

    }
`;