export const Types = {
	ADD_ANOTATION_REQUEST: "ADD_ANOTATION_REQUEST",
	ADD_ANOTATION_REQUEST_CANCEL: "ADD_ANOTATION_REQUEST_CANCEL",

	EDIT_ANOTATION_REQUEST: "EDIT_ANOTATION_REQUEST",
	EDIT_ANOTATION_REQUEST_CANCEL: "EDIT_ANOTATION_REQUEST_CANCEL",

	DELETE_ANOTATION_REQUEST: "DELETE_ANOTATION_REQUEST",
	DELETE_ANOTATION_REQUEST_CANCEL: "DELETE_ANOTATION_REQUEST_CANCEL",

	SET_CURRENT_ANOTATION: "SET_CURRENT_ANOTATION",
};

const INITIAL_STATE = {
	loading_anotacao: false,
	loading_edit_anotacao: false,
	loading_delete_anotacao: false,
	current_anotation: {},
};

export default function anotations(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.ADD_ANOTATION_REQUEST:
			return {
				...state,
				loading_anotacao: true,
			};
		case Types.ADD_ANOTATION_REQUEST_CANCEL:
			return {
				...state,
				loading_anotacao: false,
			};
		case Types.EDIT_ANOTATION_REQUEST:
			return {
				...state,
				loading_edit_anotacao: true,
			};
		case Types.EDIT_ANOTATION_REQUEST_CANCEL:
			return {
				...state,
				loading_edit_anotacao: false,
			};
		case Types.DELETE_ANOTATION_REQUEST:
			return {
				...state,
				loading_delete_anotacao: true,
			};
		case Types.DELETE_ANOTATION_REQUEST_CANCEL:
			return {
				...state,
				loading_delete_anotacao: false,
			};
		case Types.SET_CURRENT_ANOTATION:
			return {
				...state,
				current_anotation: action.payload,
			};

		default:
			return state;
	}
}

export const AnotationActions = {
	add_anotation_request: (data: any) => ({
		type: Types.ADD_ANOTATION_REQUEST,
		payload: data,
	}),
	add_anotation_request_cancel: () => ({
		type: Types.ADD_ANOTATION_REQUEST_CANCEL,
	}),
	edit_anotation_request: (data: any) => ({
		type: Types.EDIT_ANOTATION_REQUEST,
		payload: data,
	}),
	edit_anotation_request_cancel: () => ({
		type: Types.EDIT_ANOTATION_REQUEST_CANCEL,
	}),
	delete_anotation_request: (data: any) => ({
		type: Types.DELETE_ANOTATION_REQUEST,
		payload: data,
	}),
	delete_anotation_request_cancel: () => ({
		type: Types.DELETE_ANOTATION_REQUEST_CANCEL,
	}),
	set_current_anotation: (status: any) => ({
		type: Types.SET_CURRENT_ANOTATION,
		payload: status,
	}),
};
