import React, { useEffect, useState } from "react";
import {
	Conteiner,
	Content,
	ContentTitle,
	Title,
	SubTitle,
	DinoImg,
} from "./stylesRenderAlerts";
import DinoBad from "../../../assets/dinoBadAsync.svg";
import DinoHappy from "../../../assets/dinoHappyAsync.svg";

interface AlertsProps {
	type: string;
	title: string;
	subTitle: string;
}

const Alerts: any = ({ type, title, subTitle }: AlertsProps) => {
	const [dinoIcon, setDinoIcon] = useState(DinoBad);

	useEffect(() => {
		if (type === "success") {
			setDinoIcon(DinoHappy);
		} else {
			setDinoIcon(DinoBad);
		}
	}, [type]);

	return (
		<Conteiner>
			<Content>
				<DinoImg src={dinoIcon} />
				<ContentTitle>
					<Title>{title}</Title>
					<SubTitle>{subTitle}</SubTitle>
				</ContentTitle>
			</Content>
		</Conteiner>
	);
};

export default Alerts;

