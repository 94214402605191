import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Menu from "../../../../components/Menu";
import { UserProps } from "../../../../interfaces/pacientes.interface";
import ContentDash from "../../../DashPaciente/ContentDash";

const CentralAjudaController: React.FC = () => {
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);
	return user_logado === null ? (
		<ViewPort>
			<ContentDash listagem={"centralAjuda"} />
		</ViewPort>
	) : (
		<ViewPort>
			<Menu setMenu="centralAjuda"></Menu>
			<ContentDash listagem={"centralAjuda"} />
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	display: flex;
`;

export default CentralAjudaController;
