import UIfx from "uifx";

import long_beep from "../../assets/audio/long_beep.ogg";

export function beep_long() {
	const Tocar = new UIfx(long_beep, {
		volume: 1,
	});
	Tocar.play();
}
