import React, { memo, useCallback, useEffect, useState } from "react";

import { FieldValues, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";

import FotoPerfil from "../../../components/FotoPerfil";
import Text from "../../../components/Texts/Text";
import Form from "../../../components/Form/index";
import InputTextEntryForm from '../../../components/Inputs/inputForm';
import InputSelectForm from '../../../components/SelectComponent/InputSelectForm';
import FormSubmiteButton from "../../../components/Buttons/FormSubmitButton";

import { RegisterActions } from "../../../store/ducks/register";

import { Background, ContainerButtons, ContainerTwoLabels, Conteiner, Content, Margem } from "./styles";

import history from "../../../routes/history";
import { Colors } from "../../../styles/colors";
import { countries } from "../../../components/SelectComponent/Countries";

import BackgroundRegister from "../../../assets/kinologyIcons/png/BackgrounLogin.png";
interface Usuario {
	celular: string;
	cpf: string;
};

const Cadastro: React.FC = function () {
	const dispatch = useDispatch();
	const methods = useForm();

	const [aprovado, setAprovado] = useState(false);
	const [usuario, setUsuario] = useState<Usuario>({} as Usuario);
	const [us, setUs] = useState<Usuario>({} as Usuario);

	const current_language: TextsProps = useSelector((state: any) => state.configs.current_language);
	const language: any = useSelector((state: any) => state.configs.language);
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	let photo = useSelector((state: any) => state.add_pac_aval.photo);

	const [passwordShown, setPasswordShown] = useState(false);
	const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

	const togglePasswordVisiblity = (type: string) => {
		if(type === 'password') setPasswordShown(passwordShown ? false : true);
		if(type === 'confirm_password') setConfirmPasswordShown(confirmPasswordShown ? false : true);
	};

	const handleKeyUpTel = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		if (language === "PT") {
			// (99)99999-9999
			e.currentTarget.maxLength = 15;
			const { value } = e.currentTarget;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			if (ajustado.length > 10) {
				ajustado = ajustado.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
			} else if (ajustado.length > 5) {
				ajustado = ajustado.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
			} else if (ajustado.length > 2) {
				ajustado = ajustado.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
			} else {
				ajustado = ajustado.replace(/^(\d*)/, "($1");
			}
			e.currentTarget.value = ajustado;
		} else {
			// + 456 544 344 454
			e.currentTarget.maxLength = 17;
			const { value } = e.currentTarget;
			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			ajustado = ajustado.replace(/^(\d{3})/, "+ $1");

			e.currentTarget.value = ajustado;
		}
	}, []);

	const handleKeyUpCpf = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		if (language === "PT") {
			// 234.234.324-34
			e.currentTarget.maxLength = 14;
			const { value } = e.currentTarget;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/(\d{3})(\d)/, "$1.$2");
			ajustado = ajustado.replace(/(\d{3})(\d)/, "$1.$2");
			ajustado = ajustado.replace(/(\d{3})(\d{1,2})/, "$1-$2");
			ajustado = ajustado.replace(/(-\d{2})\d+?$/, "$1");
			e.currentTarget.value = ajustado;
		} else {
			e.currentTarget.maxLength = 14;
			const { value } = e.currentTarget;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			e.currentTarget.value = ajustado;
		}
	}, []);

	const onSubmit = (data: FieldValues) => {
		setAprovado(true);
		if (photo === undefined) {
			photo = null;
		}
		const dat_request = {
			USUARIO_EMAIL: data.email,
			USUARIO_NOME: data.nome,
			USUARIO_FOTO: photo,
			USUARIO_CONTATO: data.celular,
			USUARIO_SENHA: data.password,
			USUARIO_CPF: data.cpf,
			USUARIO_NACIONALIDADE: data.nacionalidade,
		};
		dispatch(RegisterActions.register_request(dat_request));
	};
	
	const hendleChange = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUsuario({
				...usuario,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[usuario]
	);

	
	const hendleChange2 = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUs({
				...us,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[us]
	);

	

	useEffect(() => {
		if (is_open_toast == true) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	return (
		<Conteiner>
			<Background>
				<img src={BackgroundRegister} alt=""  width={'100%'} height={'100%'}/>
			</Background>
			<Margem>
				<Content>
					<Text 
						title={current_language?.criar_minha_conta}
						fontFamily="NotoSans-Bold"
						fontSize={2}
						textColor={Colors.primary_blue_k_main}
						textAlign="left"
						marginTop={45}
					/>
					<Text 
						title={current_language?.preencha_informacoes_comecar}
						fontFamily="NotoSans-Regular"
						fontSize={1.5}
						fontWeight={400}
						textColor={Colors.primary_blue_k_main+70}
						textAlign="left"
						marginTop={15}
						marginBottom={37}
					/>
					<FotoPerfil />
					<Form 	methods={methods} 	onSubmit={(data)=>{onSubmit(data)}}>
						<ContainerTwoLabels>
							<InputTextEntryForm 
								type="text"
								width={48.5}
								title={current_language?.nome_completo + '*'}
								placeholder={current_language?.placeholder_digite}
								fontFamily="NotoSans-SemiBold"
								fontWeight={600}
								typeInputName="nome" 
							/>
							<InputTextEntryForm 
								type="number"
								width={48.5}
								title={'CPF*'}
								placeholder={'__.__.__-_'}
								leftIcon="DigitalIcon"
								fontFamily="NotoSans-SemiBold"
								fontWeight={600}
								typeInputName="cpf" 
							/>
						</ContainerTwoLabels>
						<InputSelectForm 
							type="select"
							width={100}
							title={current_language?.primeiro_acesso_nacionalidade + '*'}
							placeholder={current_language?.select_country}
							leftIcon="PositionIcon"
							rightIcon="ArrowSelect"
							arrayOptions={countries}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							marginTop={24}
							typeInputName="nacionalidade" 
						/>
						<InputTextEntryForm 
							type="number"
							width={100}
							title={current_language?.primeiro_acesso_celular + '*'}
							placeholder={'( ) 00000-0000'}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							leftIcon="Phone"
							marginTop={24}
							typeInputName="celular" 
						/>
						<InputTextEntryForm 
							type="text"
							width={100}
							title={'E-mail*'}
							placeholder={current_language?.placeholder_digite}
							leftIcon="EmailIcon"
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							marginTop={24}
							typeInputName="email" 
						/>
						<InputTextEntryForm 
							type="text"
							width={100}
							title={current_language?.confirme_email + '*'}
							placeholder={current_language?.placeholder_digite}
							leftIcon="EmailIcon"
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							marginTop={24}
							typeInputName="confirm_email" 
						/>
						<ContainerTwoLabels>
							<InputTextEntryForm 
								type="text"
								width={45.5}
								title={current_language?.login_senha + '*'}
								placeholder={'*********'}
								leftIcon="KeyPassword"
								rightIcon="CloseEye"
								fontFamily="NotoSans-SemiBold"
								fontWeight={600}
								typeInputName="password" 
							/>
							<InputTextEntryForm 
								type="text"
								width={45.5}
								title={current_language?.menu_lateral_confirmacao + '*'}
								placeholder={'*********'}
								leftIcon="KeyPassword"
								rightIcon="CloseEye"
								fontFamily="NotoSans-SemiBold"
								fontWeight={600}
								typeInputName="confirm_password" 
							/>
						</ContainerTwoLabels>
						<FormSubmiteButton
							type="submit"
							onClick={()=> methods.trigger()}
							disabled={Object.values(methods.watch()).every((item: string)=> item != '')
								? false
								: true
							}
							marginTop={40}
							fontFamily="Kanit-Medium"
							typeButtonComponent="primary"
							activityIndicator={!!aprovado}
							title={current_language?.criar_minha_conta}
						/>
					</Form>
					<ContainerButtons style={{marginTop:10}}>
						<FormSubmiteButton
							disabled={false}
							typeButtonComponent="primary"
							title={current_language?.voltar}
							fontFamily="Kanit-Medium"
							marginTop={15}
							textColor={Colors.primary_blue_k_main}
							bgColor={"transparent"}
							borderWidth={0}
							width={100}
							margintBottom={20}
							onClick={()=>{ history.push("/login")}}
						/>
					</ContainerButtons>
				</Content>
			</Margem>
		</Conteiner>
	);
};

export default memo(Cadastro);
