import styled from "styled-components";

export const Nota_add = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 22.875rem;

	background: transparent;
	border: 0;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 1.017rem;
	line-height: 120%;

	color: #404040;

	p{
		margin-top: .4rem;
	}
`;