import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as V from "victory";

import { TextsProps } from "../../../../../database/txt.interface";
import { ExerCucaoProp } from "../../../../../interfaces/assimetria.interface";
import { RelatorioActions } from "../../../../../store/ducks/relatorios";
import { TypesCores } from "../../../../../utils/types";
import { Content } from "./styles";
import { S2Desk, S3Desk } from "../../../../../styles/styles_text";
import { Icon } from "../../../../../styles/global";
import Historico from "../../../../../assets/kinologyIcons/svg/calendar_month.svg";
import { DashBoardActions } from "../../../../../store/ducks/dashboard";

const GraficoEvolucaoAssimetria: React.FC = () => {
	const dispatch = useDispatch();

	const exercicios_historicos = useSelector(
		(state: any) => state.relatorios.exercicios_historicos
	);

	const current_exercise_history: Array<ExerCucaoProp> = useSelector(
		(state: any) => state.relatorios.current_exercise_history
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const exer_historico: any = [];

	(current_exercise_history.length > 0
		? current_exercise_history
		: exercicios_historicos
	).map((e: ExerCucaoProp) => {
		const ladoDireito = [];
		const ladoEsquerdo = [];
		const legendas = [];
		if (e?.EXECUCOES[2]) {
			ladoDireito.push({
				x:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[2].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[2].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[2].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[2].DATA?.substr(0, 10).split("/").join("-")}`,
				y: e?.EXECUCOES[2].MAXEXEC_D,
				position: "D",
				color: e.EXECUCOES[2].LEGENDA_ASSIMETRIA,
				maior:
					e.EXECUCOES[2].MAXEXEC_E > e.EXECUCOES[2].MAXEXEC_D
						? e.EXECUCOES[2].MAXEXEC_E
						: e.EXECUCOES[2].MAXEXEC_D,
			});
			ladoEsquerdo.push({
				x:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[2].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[2].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[2].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[2].DATA?.substr(0, 10).split("/").join("-")}`,
				y: e?.EXECUCOES[2].MAXEXEC_E,
				position: "E",
				color: e.EXECUCOES[2].LEGENDA_ASSIMETRIA,
				maior:
					e.EXECUCOES[2].MAXEXEC_E > e.EXECUCOES[2].MAXEXEC_D
						? e.EXECUCOES[2].MAXEXEC_E
						: e.EXECUCOES[2].MAXEXEC_D,
			});
			legendas.push({
				data:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[2].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[2].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[2].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[2].DATA?.substr(0, 10).split("/").join("-")}`,
				direito: e?.EXECUCOES[2].MAXEXEC_D,
				esquerdo: e?.EXECUCOES[2].MAXEXEC_E,
				assimetria: e?.EXECUCOES[2].ASSIMETRIA_VALOR,
			});
		}
		if (e?.EXECUCOES[1]) {
			ladoDireito.push({
				x:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[1].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[1].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[1].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[1].DATA?.substr(0, 10).split("/").join("-")}`,
				y: e?.EXECUCOES[1].MAXEXEC_D,
				position: "D",
				color: e.EXECUCOES[1].LEGENDA_ASSIMETRIA,
				maior:
					e.EXECUCOES[1].MAXEXEC_E > e.EXECUCOES[1].MAXEXEC_D
						? e.EXECUCOES[1].MAXEXEC_E
						: e.EXECUCOES[1].MAXEXEC_D,
			});
			ladoEsquerdo.push({
				x:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[1].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[1].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[1].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[1].DATA?.substr(0, 10).split("/").join("-")}`,
				y: e?.EXECUCOES[1].MAXEXEC_E,
				position: "E",
				color: e.EXECUCOES[1].LEGENDA_ASSIMETRIA,
				maior:
					e.EXECUCOES[1].MAXEXEC_E > e.EXECUCOES[1].MAXEXEC_D
						? e.EXECUCOES[1].MAXEXEC_E
						: e.EXECUCOES[1].MAXEXEC_D,
			});
			legendas.push({
				data:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[1].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[1].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[1].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[1].DATA?.substr(0, 10).split("/").join("-")}`,
				direito: e?.EXECUCOES[1].MAXEXEC_D,
				esquerdo: e?.EXECUCOES[1].MAXEXEC_E,
				assimetria: e?.EXECUCOES[1].ASSIMETRIA_VALOR,
			});
		}
		if (e?.EXECUCOES[0]) {
			ladoDireito.push({
				x:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[0].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[0].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[0].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[0].DATA?.substr(0, 10).split("/").join("-")}`,
				y: e?.EXECUCOES[0].MAXEXEC_D,
				position: "D",
				color: e.EXECUCOES[0].LEGENDA_ASSIMETRIA,
				maior:
					e.EXECUCOES[0].MAXEXEC_E > e.EXECUCOES[0].MAXEXEC_D
						? e.EXECUCOES[0].MAXEXEC_E
						: e.EXECUCOES[0].MAXEXEC_D,
			});
			ladoEsquerdo.push({
				x:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[0].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[0].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[0].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[0].DATA?.substr(0, 10).split("/").join("-")}`,
				y: e?.EXECUCOES[0].MAXEXEC_E,
				position: "E",
				color: e.EXECUCOES[0].LEGENDA_ASSIMETRIA,
				maior:
					e.EXECUCOES[0].MAXEXEC_E > e.EXECUCOES[0].MAXEXEC_D
						? e.EXECUCOES[0].MAXEXEC_E
						: e.EXECUCOES[0].MAXEXEC_D,
			});
			legendas.push({
				data:
					current_language?.idioma === "Português"
						? `${e?.EXECUCOES[0].DATA?.substr(0, 10)
								.split("-")
								.reverse()
								.join("/")}`
						: current_language?.idioma === "English"
						? `${e?.EXECUCOES[0].DATA?.substr(0, 5)
								.split("/")
								.reverse()
								.join("/")}/${e?.EXECUCOES[0].DATA?.substr(6, 10)}`
						: `${e?.EXECUCOES[0].DATA?.substr(0, 10).split("/").join("-")}`,
				direito: e?.EXECUCOES[0].MAXEXEC_D,
				esquerdo: e?.EXECUCOES[0].MAXEXEC_E,
				assimetria: e?.EXECUCOES[0].ASSIMETRIA_VALOR,
			});
		}
		exer_historico.push({
			ID_EXERCICIO: e.ID_EXERCICIO,
			EXERCICIO_TITULO_EN: e.EXERCICIO_TITULO_EN,
			EXERCICIO_TITULO_ES: e.EXERCICIO_TITULO_ES,
			EXERCICIO_TITULO_PT: e.EXERCICIO_TITULO_PT,
			DADOS_DIREITA: ladoDireito,
			DADOS_ESQUERDA: ladoEsquerdo,
			LEGENDAS: legendas,
			TOTAL_EXECUCOES: e?.EXECUCOES.length,
			COMPLETO: e,
		});
	});

	return (
		<Content>
			<div className="cards">
				{exer_historico.map((e: any, index: number) => {
					return (
						<div className="card" key={index}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
								}}
							>
								<p className="title">
									{current_language?.idioma === "Português" && (
										<S3Desk>
											<strong>{e?.EXERCICIO_TITULO_PT}</strong>
										</S3Desk>
									)}
									{current_language?.idioma === "English" && (
										<S2Desk>
											<strong>{e?.EXERCICIO_TITULO_EN}</strong>
										</S2Desk>
									)}
									{current_language?.idioma === "Español" && (
										<S2Desk>
											<strong>{e?.EXERCICIO_TITULO_ES}</strong>
										</S2Desk>
									)}
								</p>
								{e?.TOTAL_EXECUCOES > 3 && (
									<S3Desk
										style={{ textDecorationLine: "underline" }}
										onClick={() => {
											dispatch(
												RelatorioActions.set_current_page_from_history_asymmetry(
													e?.COMPLETO
												)
											);
											dispatch(
												DashBoardActions.set_current_page(
													"historico_assimetria"
												)
											);
										}}
									>
										Ver histórico{" "}
										<Icon
											style={{ width: "0.7rem", marginLeft: "0.3rem" }}
											src={Historico}
										/>
									</S3Desk>
								)}
							</div>
							<div className="chart">
								<V.VictoryChart width={1300}>
									<V.VictoryGroup offset={65}>
										<V.VictoryBar
											data={e?.DADOS_ESQUERDA}
											labels={({ datum }) => [datum._y, datum.position]}
											style={{
												data: {
													fill: ({ datum }) => {
														let cor: any = null;
														if (datum.color == TypesCores.POSITIVO) {
															cor = "#7FE179";
														} else if (datum.color == TypesCores.NEGATIVO) {
															cor = "#D12600";
														} else if (
															datum.color == TypesCores.INTERMEDIARIO
														) {
															cor = "#FFC530";
														} else {
															cor = "#797D8D";
														}
														if (datum.y < datum.maior) {
														} else if (
															datum.color == TypesCores.POSITIVO &&
															datum.y === datum.maior
														) {
															cor = "#7FE179";
														} else {
															cor = "#797D8D";
														}
														return cor;
													},
													width: "50",
												},
												labels: {
													fill: "white",
													fontSize: 20,
													fontFamily: "roboto",
												},
											}}
											labelComponent={
												<V.VictoryLabel
													dy={50}
													lineHeight={[3, 1]}
													style={[
														{ fill: "#000", fontSize: "25px" },
														{ fill: "white", fontSize: "25px" },
													]}
												/>
											}
										/>
										<V.VictoryBar
											data={e?.DADOS_DIREITA}
											labels={({ datum }) => [datum._y, datum.position]}
											style={{
												data: {
													fill: ({ datum }) => {
														let cor: any = null;
														if (datum.color == TypesCores.POSITIVO) {
															cor = "#7FE179";
														} else if (datum.color == TypesCores.NEGATIVO) {
															cor = "#D12600";
														} else if (
															datum.color == TypesCores.INTERMEDIARIO
														) {
															cor = "#FFC530";
														} else {
															cor = "#797D8D";
														}
														if (datum.y < datum.maior) {
														} else if (
															datum.color == TypesCores.POSITIVO &&
															datum.y === datum.maior
														) {
															cor = "#7FE179";
														} else {
															cor = "#797D8D";
														}

														return cor;
													},
													width: "50",
												},
												labels: {
													fill: "white",
													fontSize: 25,
													fontFamily: "roboto",
												},
											}}
											labelComponent={
												<V.VictoryLabel
													dy={50}
													lineHeight={[3, 1]}
													style={[
														{ fill: "#000", fontSize: "25px" },
														{ fill: "white", fontSize: "25px" },
													]}
												/>
											}
										/>
									</V.VictoryGroup>

									<V.VictoryAxis
										dependentAxis
										label={current_language?.forca_kg}
										style={{
											axisLabel: {
												fontSize: 25,
												fontFamily: "roboto",
											},

											tickLabels: {
												fontSize: 25,
												fill: "transparent",
											},
											axis: {
												color: "transparent",
											},
										}}
									/>

									<V.VictoryAxis
										style={{
											tickLabels: {
												fontSize: 25,
												fill: "black",
												fontFamily: "roboto",
											},
										}}
									/>
								</V.VictoryChart>
							</div>
							<table className="table">
								<tr style={{ backgroundColor: "#F1F2F3" }}>
									<td
										className="parameter"
										style={{ borderRadius: "0.625rem 0rem 0rem 0rem" }}
									>
										{current_language?.data}
									</td>
									<td className="parameter">
										{current_language?.hemisferio_direito} (D)
									</td>
									<td className="parameter">
										{current_language?.hemisferio_esquerdo} (E)
									</td>
									<td
										className="parameter"
										style={{ borderRadius: "0rem 0.625rem 0rem 0rem" }}
									>
										{current_language?.assimetria}
									</td>
								</tr>

								{e?.LEGENDAS.map((i: any, index: any) => {
									return (
										<tr key={index}>
											<td className="value">{i?.data}</td>
											<td className="value">{i?.direito} Kg</td>
											<td className="value">{i?.esquerdo} Kg</td>
											<td className={`value`}>{i?.assimetria}</td>
										</tr>
									);
								})}
							</table>
						</div>
					);
				})}
			</div>
		</Content>
	);
};

export default GraficoEvolucaoAssimetria;
