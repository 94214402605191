import styled from "styled-components";

export const InputText = styled.input`
	width: 22.875rem;
	padding: 1rem;

	color: var(--Neutras-Grey-K-50, #797d8d);
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-style: normal;
	font-weight: 400;

	border-radius: 0.75rem;
	border: 1px solid var(--Neutras-Grey-K-10, #dedfe3);
	background: #fff;
	color: #606060;
`;

export const Question = styled.div`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 120%;

	color: #404040;

	margin-bottom: 1.8rem;
`;

export const Body = styled.div`
	display: flex;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;

	margin-right: 1rem;
`;
