import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { TextsProps } from "../../../database/txt.interface";
import { ExecucaoProps } from "../../../interfaces/execucao.interface";
import { ExerProtoProps } from "../../../interfaces/exercicio_protocolo";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { AppActions } from "../../../store/ducks/app";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import { TypesErrorRequest } from "../../../utils/types";
import ToastAlert from "../../toast";
import RenderItemGroup from "./_renderItemGroup";
import { ContainerList } from "./styles";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { AmplitudeLog } from "../../../utils/amplitude";

const ExercicioList: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const seletec_hemisferios = useSelector(
		(state: any) => state.manage_protocol.seletec_hemisferios
	);

	const execucoes: ExecucaoProps[] = useSelector(
		(state: any) => state.dashboard.execucoes
	);

	const is_open_paciente = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const is_relatorio_evolucao = useSelector(
		(state: any) => state.relatorios.is_relatorio_evolucao
	);
	const is_relatorio_assimetria = useSelector(
		(state: any) => state.relatorios.is_relatorio_assimetria
	);
	const is_relatorio_forca_tempo = useSelector(
		(state: any) => state.relatorios.is_relatorio_forca_tempo
	);
	const is_relatorio_multifatorial = useSelector(
		(state: any) => state.relatorios.is_relatorio_multifatorial
	);
	const is_relatorio_fisioterapeutico = useSelector(
		(state: any) => state.relatorios.is_relatorio_fisioterapeutico
	);

	const [toastAut, setToastAut] = useState(false);

	const handleListExercicios = () => {
		const exercicio: any = [];
		seletec_hemisferios.map((e: ExerProtoProps) => {
			const tipo: any = [];
			const D = e.PROTOCOLO_EXERCICIO_TIPO.find(
				(i: any) => i.substr(0, 1) == "D"
			);
			const E = e.PROTOCOLO_EXERCICIO_TIPO.find(
				(i: any) => i.substr(0, 1) == "E"
			);
			const B = e.PROTOCOLO_EXERCICIO_TIPO.find(
				(i: any) => i.substr(0, 1) == "B"
			);
			const S = e.PROTOCOLO_EXERCICIO_TIPO.find(
				(i: any) => i.substr(0, 1) == "S"
			);

			S != undefined && tipo.push("S");
			D != undefined && tipo.push("D");
			E != undefined && tipo.push("E");
			B != undefined && tipo.push("B");

			exercicio.push({
				ID_EXERCICIO: e?.ID_EXERCICIO,
				MODOS: tipo,
			});
		});
		if (seletec_hemisferios.length == 0) {
			setToastAut(true);
			const message = current_language?.selecione_exercicios;
			dispatch(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			setToastAut(false);
			dispatch(RelatorioActions.set_exercicios_compartilhar(exercicio));
			if (is_relatorio_multifatorial || is_relatorio_fisioterapeutico) {
				dispatch(AppActions.set_continue_compartilhamento("EscolhaAnamnese"));
			} else {
				dispatch(AppActions.set_continue_compartilhamento("EscolhaEmail"));
			}
		}
	};
	const handleListExerciciosVoltar = () => {
		dispatch(AppActions.set_continue_compartilhamento("EscolhaData"));
	};

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);

	const marcarTodos = useSelector(
		(state: any) => state.relatorios.marcar_todas
	);

	const handleMarcarTodos = () => {
		if (!marcarTodos) {
			dispatch(RelatorioActions.set_marcar_todas(true));
		} else {
			dispatch(RelatorioActions.set_marcar_todas(false));
		}
	};

	return (
		<ContainerList>
			{toastAut == true && <ToastAlert />}
			<p className="text">{current_language?.selecione_exercicios}</p>
			{marcarTodos ? (
				<p className="selecionarTodos" onClick={handleMarcarTodos}>
					{current_language?.limpar_selecao}
				</p>
			) : (
				<p className="selecionarTodos" onClick={handleMarcarTodos}>
					{current_language?.selecionar_todos}
				</p>
			)}
			{is_relatorio_assimetria ? (
				<div className="lista">
					{exercicios.map(
						(row, index) =>
							execucoes.find(
								(e: any) =>
									e.ID_EXERCICIO == row.ID_EXERCICIO &&
									e.ID_USUARIO == is_open_paciente.ID_USUARIO &&
									e.EXECUCAO_TIPO != "f"
							) && (
								<div key={index} className="ficha">
									<RenderItemGroup row={row} index={index} />
								</div>
							)
					)}
				</div>
			) : (
				<div className="lista">
					{exercicios.map(
						(row, index) =>
							execucoes.find(
								(e: any) =>
									e.ID_EXERCICIO == row.ID_EXERCICIO &&
									e.ID_USUARIO == is_open_paciente.ID_USUARIO
							) && (
								<div key={index} className="ficha">
									<RenderItemGroup row={row} index={index} />
								</div>
							)
					)}
				</div>
			)}

			<div className="botoes">
				<button 
					className="continuar" 
					onClick={()=> {
						handleListExercicios()
						AmplitudeLog('exercicio_selecionado')
					}}
				>
					{current_language?.continuar}
				</button>
				<button 
					className="voltar" 
					onClick={()=> {
						handleListExerciciosVoltar()
						AmplitudeLog('voltar_selecionar_exerc_relat')
					}}
				>
					{current_language?.voltar}
				</button>
			</div>
		</ContainerList>
	);
};

export default ExercicioList;
