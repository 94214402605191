import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonClose.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalFotoPerfil: React.FC = () => {
	// comunica com redux
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	// função que abre a modal, mais sobre ela em store/ducks/app.ts
	const is_open_foto_perfil = useSelector(
		(state: any) => state.app.is_open_foto_perfil
	);
	

	// função para fechar modal
	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_foto_perfil(false));
	};
	const foto_perfil = useSelector((state: any) => state.app.foto_perfil);

	function getBase64(event: any) {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = function () {
			dispatch(AppActions.set_foto_perfil(reader.result));
			dispatch(AddPacAvalActions.set_avatar_paci_64(reader.result));
			dispatch(AddPacAvalActions.set_avatar_eval_64(reader.result));
		};
		reader.onerror = function (error) {
			//console.log("Error: ", error);
		};
		dispatch(AppActions.set_modal_foto_perfil(false));
	}

	const removerFoto = (event: any) => {
		event.preventDefault();
		event.target.value = null;
		dispatch(AppActions.set_foto_perfil(""));
		dispatch(AddPacAvalActions.set_avatar_paci_64(" "));
		dispatch(AddPacAvalActions.set_avatar_eval_64(" "));
		dispatch(AppActions.set_modal_foto_perfil(false));
	};

	return (
		<div>
			{/* librarie para abrir o modal */}
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_foto_perfil}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_foto_perfil}>
					{/* modal com as infos, feito html e css */}
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<div className="divText">
							<p className="text">
								{current_language?.perfil_selecione_imagem}
							</p>
						</div>
						<div className="buttons">
							<div className="option" onClick={removerFoto}>
								<svg
									width="47"
									height="52"
									viewBox="0 0 47 52"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M45.2422 3.28322H32.7617L31.7841 1.41938C31.577 1.02092 31.258 0.685732 30.8629 0.451543C30.4679 0.217353 30.0125 0.0934482 29.548 0.0937675H17.6604C17.1969 0.0920601 16.7423 0.215503 16.3487 0.449953C15.955 0.684403 15.6383 1.02038 15.4347 1.41938L14.457 3.28322H1.97656C1.53523 3.28322 1.11196 3.45123 0.799893 3.7503C0.48782 4.04937 0.3125 4.455 0.3125 4.87795L0.3125 8.0674C0.3125 8.49035 0.48782 8.89597 0.799893 9.19504C1.11196 9.49411 1.53523 9.66212 1.97656 9.66212H45.2422C45.6835 9.66212 46.1068 9.49411 46.4189 9.19504C46.7309 8.89597 46.9062 8.49035 46.9062 8.0674V4.87795C46.9062 4.455 46.7309 4.04937 46.4189 3.7503C46.1068 3.45123 45.6835 3.28322 45.2422 3.28322ZM5.84551 46.6398C5.92488 47.8544 6.48426 48.9944 7.40979 49.8277C8.33531 50.6609 9.55741 51.1248 10.8273 51.125H36.3915C37.6613 51.1248 38.8834 50.6609 39.809 49.8277C40.7345 48.9944 41.2939 47.8544 41.3732 46.6398L43.5781 12.8516H3.64062L5.84551 46.6398Z"
										fill="#D12600"
									/>
								</svg>
								<button className="button">
									{current_language?.menu_remover_imagem}
								</button>
							</div>
							<div className="option">
								<label htmlFor="img">
									<svg
										width="49"
										height="49"
										viewBox="0 0 49 49"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M48.9062 34.2625V4.975C48.9062 2.29031 46.7097 0.09375 44.025 0.09375H14.7375C12.0528 0.09375 9.85625 2.29031 9.85625 4.975V34.2625C9.85625 36.9472 12.0528 39.1437 14.7375 39.1437H44.025C46.7097 39.1437 48.9062 36.9472 48.9062 34.2625ZM22.0594 24.5L27.0138 31.1141L34.2625 22.0594L44.025 34.2625H14.7375L22.0594 24.5ZM0.09375 9.85625V44.025C0.09375 46.7097 2.29031 48.9062 4.975 48.9062H39.1437V44.025H4.975V9.85625H0.09375Z"
											fill="#00A377"
										/>
									</svg>
								</label>

								<label
									htmlFor="img"
									className="button"
									// onClick={ModalCadastroClose}
								>
									<input
										id="img"
										type="file"
										onChange={getBase64}
										style={{ display: "none" }}
										accept="image/*"
									/>
									{current_language?.abrir_arquivo}
								</label>
							</div>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalFotoPerfil;
