import { CircularProgress } from "@material-ui/core";
import React, { useState, useCallback, useEffect, memo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import altura from "../../../assets/icons/altura.svg";
import balanca from "../../../assets/icons/balanca.svg";
import Celular from "../../../assets/icons/celular.svg";
import dataNasc from "../../../assets/icons/datanasc.svg";
import emailGray from "../../../assets/icons/emailGray.svg";
import idPerson from "../../../assets/icons/idPerson.svg";
import masculino from "../../../assets/icons/masculino.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import user from "../../../assets/icons/user.svg";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { UserProps } from "../../../interfaces/pacientes.interface";
import history from "../../../routes/history";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { AppActions } from "../../../store/ducks/app";
import { PerfilActions } from "../../../store/ducks/perfil";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission } from "../../../utils/types";
import { Conteiner, BodyEdite, Content } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";

interface Usuario {
	celular: string;
	cpf: string;
}

const EditarPerfil: React.FC = () => {
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);
	const userTeste: UserProps = useSelector((state: any) => state.configs);
	const user_editation: UserProps = useSelector((state: any)=> state?.auth?.data_user)
	const perfil = user_logado;

	const dispatch = useDispatch();

	const language: any = useSelector((state: any) => state.configs.language);

	const handleKeyUpCpf = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		if (language === "PT") {
			e.currentTarget.maxLength = 14;
			const value = e.currentTarget.value;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/(\d{3})(\d)/, "$1.$2");
			ajustado = ajustado.replace(/(\d{3})(\d)/, "$1.$2");
			ajustado = ajustado.replace(/(\d{3})(\d{1,2})/, "$1-$2");
			ajustado = ajustado.replace(/(-\d{2})\d+?$/, "$1");
			e.currentTarget.value = ajustado;
		} else {
			e.currentTarget.maxLength = 14;
			const value = e.currentTarget.value;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			e.currentTarget.value = ajustado;
		}
	}, []);

	const handleKeyUpData = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			e.currentTarget.maxLength = 10;
			const value = e.currentTarget.value;

			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/(\d{2})(\d)/, "$1/$2");
			ajustado = ajustado.replace(/(\d{2})(\d)/, "$1/$2");

			e.currentTarget.value = ajustado;
		},
		[]
	);

	const handleKeyUpAltura = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			if (language === "PT") {
				e.currentTarget.maxLength = 14;
				const value = e.currentTarget.value;

				let ajustado = value.replace(/\D/g, "");
				ajustado = ajustado.replace(/(\d{1})(\d)/, "$1,$2");
				ajustado = ajustado.replace(/(\d{1})(\d)/, "$1$2");

				e.currentTarget.value = ajustado;
			} else {
				e.currentTarget.maxLength = 5;
				const value = e.currentTarget.value;

				let ajustado = value.replace(/\D/g, "");
				ajustado = ajustado.replace(/(\d{1})(\d)/, "$1.$2");
				ajustado = ajustado.replace(/(\d{1})(\d)/, "$1$2");
				e.currentTarget.value = ajustado;
			}
		},
		[]
	);

	const handleKeyUpTel = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		if (language === "PT") {
			e.currentTarget.maxLength = 15;
			const value = e.currentTarget.value;
			if (value.length > 0) {
				let ajustado = value.replace(/\D/g, "");
				ajustado = ajustado.replace(/^0/, "");
				if (ajustado.length > 10) {
					ajustado = ajustado.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
				} else if (ajustado.length > 5) {
					ajustado = ajustado.replace(
						/^(\d\d)(\d{4})(\d{0,4}).*/,
						"($1) $2-$3"
					);
				} else if (ajustado.length > 2) {
					ajustado = ajustado.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
				} else {
					ajustado = ajustado.replace(/^(\d*)/, "($1");
				}
				e.currentTarget.value = ajustado;
			}
		} else {
			e.currentTarget.maxLength = 17;
			const value = e.currentTarget.value;
			let ajustado = value.replace(/\D/g, "");
			ajustado = ajustado.replace(/^0/, "");
			ajustado = ajustado.replace(/^(\d{3})/, "+ $1");

			e.currentTarget.value = ajustado;
		}
	}, []);

	const handleKeyUpPeso = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			if (language === "PT") {
				e.currentTarget.maxLength = 14;
				const value = e.currentTarget.value;

				let ajustado = value.replace(/\D/g, "");
				ajustado = ajustado.replace(/(\d{1})(\d)/, "$1$2");
				ajustado = ajustado.replace(/(\d{3})(\d)/, "");

				e.currentTarget.value = ajustado;
			} else {
				e.currentTarget.maxLength = 2;
				const value = e.currentTarget.value;

				let ajustado = value.replace(/\D/g, "");
				ajustado = ajustado.replace(/^0/, "");
				e.currentTarget.value = ajustado;
			}
		},
		[]
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const [aprovado, setAprovado] = useState(false);
	const onSubmit = (data: any) => {
		setAprovado(true);
		const novoEmail = perfil?.USUARIO_EMAIL !== data.email;
		const dat_request = {
			ID_USUARIO: perfil.ID_USUARIO,
			USUARIO_AVALIADOR: perfil.ID_USUARIO,
			USUARIO_EMAIL: data.email,
			USUARIO_NOME: data.nome,
			USUARIO_SEXO: data.sexo,
			USUARIO_ESTATURA: data.altura.replace("m", ""),
			USUARIO_CONTATO: data.celular,
			USUARIO_MASSA: data.massa.replace("kg", ""),
			USUARIO_CPF: data.cpf,
			USUARIO_NASCIMENTO:
				current_language?.idioma === "Português"
					? `${data.data.substr(0, 10).split("/").reverse().join("-")}`
					: current_language?.idioma === "English"
					? `${data.data}`
					: `${data.data.substr(0, 10).split("-").reverse().join("-")}`,
			USUARIO_CONDICIONAMENTO: data.condicionamento,
			NOVO_EMAIL: novoEmail,
			USUARIO_FOTO: !!foto_perfil ? foto_perfil : "",
		};
		dispatch(PerfilActions.edit_perfil(dat_request));
		AmplitudeLog('salvar_edic_perfil', {
			usuario:dat_request
		})
		history.push("/perfil");
	};

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const foto_perfil = useSelector((state: any) => state.app.foto_perfil);

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("perfilEditar"));
	}, []);

	const handleConta = () => {
		dispatch(AppActions.set_open_alterar_senha(true));
		dispatch(AppActions.set_open_barraSuperior("alterarSenha"));
	};

	function permissionHandleAssinaturaConta(){
		const permissionSubscriptionReport: any =  getPermission(
			config_servicos,
			TypesPermission.SERVICO_ASSINATURA_RELATORIO
		)
		if(permissionSubscriptionReport){
			dispatch(AppActions.set_open_alterar_senha(true));
			dispatch(AppActions.set_open_barraSuperior("assinatura"));
			history.push("/assinatura")
		}else{
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true))
		}
	};

	const [usuario, setUsuario] = useState<Usuario>({} as Usuario);
	const hendleChange = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUsuario({
				...usuario,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[usuario]
	);

	const [us, setUs] = useState<Usuario>({} as Usuario);
	const hendleChange2 = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUs({
				...us,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[us]
	);

	const voltar = () => {
		window.history.back();
	};

	useEffect(() => {
		setValue("nome", user_editation?.USUARIO_NOME);
		setValue("email", user_editation?.USUARIO_EMAIL);
		setValue(
			"celular",
			user_editation?.USUARIO_CONTATO?.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3")
		);
		setValue(
			"cpf",
			user_editation?.USUARIO_CPF?.replace(
				/(\d{3})(\d{3})(\d{3})(\d{2})/,
				"$1.$2.$3-$4"
			)
		);
		setValue(
			"data",
			user_editation?.USUARIO_NASCIMENTO == undefined
				? ""
				: current_language?.idioma === "Português"
				? `${user_editation?.USUARIO_NASCIMENTO?.substr(0, 10)
						.split("-")
						.reverse()
						.join("/")}`
				: current_language?.idioma === "English"
				? `${user_editation?.USUARIO_NASCIMENTO?.substr(0, 5)
						.split("/")
						.reverse()
						.join("/")}/${user_editation?.USUARIO_NASCIMENTO?.substr(6, 10)}`
				: `${user_editation?.USUARIO_NASCIMENTO?.substr(0, 10).split("/").join("-")}`
		);
		setValue(
			"altura",
			user_editation?.USUARIO_ESTATURA == undefined
				? ""
				: `${user_editation?.USUARIO_ESTATURA?.split(".")?.join(",")}m`
		);
		setValue(
			"massa",
			user_editation?.USUARIO_MASSA == undefined
				? ""
				: `${user_editation?.USUARIO_MASSA?.split(".")?.join(",")}kg`
		);
	}, [user_editation]);

	const user_eval_avatar_64 = useSelector(
		(state: any) => state.add_pac_aval.user_eval_avatar_64
	);
	const [img, setImg] = useState<any>();

	useEffect(() => {
		setImg(foto_perfil);
	}, [foto_perfil, user_eval_avatar_64]);

	if (
		user_logado?.USUARIO_FOTO != "" &&
		user_logado?.USUARIO_FOTO != null &&
		img == null
	) {
		setImg(user_logado?.USUARIO_FOTO);
	}

	const handleOpenFotoPerfil = () => {
		dispatch(AppActions.set_modal_foto_perfil(true));
	};

	useEffect(() => {
		dispatch(AppActions.set_foto_perfil(user_editation?.USUARIO_FOTO));
	}, []);

	return (
		<Conteiner>
			<Content onSubmit={handleSubmit(onSubmit)}>
				<div className="header">
					<div className="foto">
						<div className="DivInput">
							<label className="labelForInput" onClick={handleOpenFotoPerfil}>
								<div className="conteinerImg">
									<img
										src={
											!!foto_perfil ? foto_perfil : Perfil
										}
										alt=""
										className="imgForInput"
									/>
								</div>
							</label>
						</div>
						<svg
							className="camera"
							width="30"
							height="30"
							viewBox="0 0 52 52"
							fill="none"
						>
							<ellipse
								rx="25.6557"
								ry="25.9526"
								transform="matrix(1 0 0 -1 26.3442 26.0474)"
								fill="#4D4D4D"
							/>
							<path
								d="M43.455 36.0368C43.455 36.8341 43.1394 37.5987 42.5777 38.1625C42.016 38.7263 41.2542 39.043 40.4598 39.043H13.5032C12.7089 39.043 11.947 38.7263 11.3853 38.1625C10.8236 37.5987 10.5081 36.8341 10.5081 36.0368V19.5029C10.5081 18.7057 10.8236 17.941 11.3853 17.3773C11.947 16.8135 12.7089 16.4968 13.5032 16.4968H19.4936L22.4888 11.9875H31.4743L34.4694 16.4968H40.4598C41.2542 16.4968 42.016 16.8135 42.5777 17.3773C43.1394 17.941 43.455 18.7057 43.455 19.5029V36.0368Z"
								stroke="white"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M26.9811 33.0306C30.2894 33.0306 32.9714 30.3388 32.9714 27.0183C32.9714 23.6978 30.2894 21.006 26.9811 21.006C23.6727 21.006 20.9907 23.6978 20.9907 27.0183C20.9907 30.3388 23.6727 33.0306 26.9811 33.0306Z"
								stroke="white"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
				</div>
				<BodyEdite>
					<div className="coluna">
						<div className="globalInput">
							<div className="iconIput">
								<img src={user} alt="key" className="icon" />
							</div>
							<input
								type="text"
								id="nome"
								placeholder={current_language?.primeiro_acesso_nome}
								className="input"
								{...register("nome", {
									required: `${current_language?.login_input_senha}`,
									pattern: {
										value:
											/\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú][A-Za-zÀ-ú]{2,}\b/gi,
										message: `${current_language?.mensag_nome_inválido}`,
									},
								})}
							/>
						</div>
						<div className="divhr">
							<hr className={errors.nome ? "hr hrcor" : "hr"} />
							{errors.nome && (
								<div className="error">{errors.nome.message}</div>
							)}
						</div>
					</div>
					<div className="coluna">
						<div className="globalInput">
							<div className="iconIput">
								<img src={masculino} alt="key" className="icon" />
							</div>
							<select
								id="sexo"
								className="input select"
								{...register("sexo", {
									required: `${current_language?.login_input_senha}`,
								})}
							>
								<option
									className="option"
									selected={user_editation?.USUARIO_SEXO === "M" ? true : false}
									value="M"
								>
									{current_language?.masculino}
								</option>
								<option
									className="option"
									selected={user_editation?.USUARIO_SEXO === "F" ? true : false}
									value="F"
								>
									{" "}
									{current_language?.feminino}
								</option>
							</select>
						</div>
						<div className="divhr">
							<hr className={errors.sexo ? "hr hrcor" : "hr"} />
							{errors.sexo && (
								<div className="error">{errors.sexo.message}</div>
							)}
						</div>
					</div>
					<div className="coluna">
						<div className="globalInput">
							<div className="iconIput">
								<img src={emailGray} alt="key" className="icon" />
							</div>
							<input
								type="text"
								id="email"
								placeholder="E-mail"
								className="input"
								{...register("email", {
									required: `${current_language?.login_input_senha}`,
									pattern: {
										value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										message: "E-mail inválido",
									},
								})}
							/>
						</div>
						<div className="divhr">
							<hr className={errors.email ? "hr hrcor" : "hr"} />
							{errors.email && (
								<div className="error">{errors.email.message}</div>
							)}
						</div>
					</div>
					<div className="coluna">
						<div className="globalInput">
							<div className="iconIput">
								<img src={altura} alt="key" className="icon" />
							</div>
							<input
								onKeyUp={handleKeyUpAltura}
								type="text"
								id="altura"
								placeholder={current_language?.menu_lateral_altura}
								className="input"
								{...register("altura", {
									required: `${current_language?.login_input_senha}`,
									pattern: {
										value: /(?:\.|,|[0-9])*/i,
										message: "Altura inválida",
									},
								})}
							/>
						</div>
						<div className="divhr">
							<hr className={errors.altura ? "hr hrcor" : "hr"} />
							{errors.altura && (
								<div className="error">{errors.altura.message}</div>
							)}
						</div>
					</div>
					<div className="coluna">
						<div className="globalInput">
							<div className="iconIput">
								<img src={Celular} alt="key" className="icon" />
							</div>
							<input
								onKeyUp={handleKeyUpTel}
								type="text"
								id="celular"
								placeholder={current_language?.primeiro_acesso_celular}
								className="input"
								{...register("celular")}
								{...hendleChange}
							/>
						</div>
						<div className="divhr">
							<hr className={errors.celular ? "hr hrcor" : "hr"} />
							{errors.celular && (
								<div className="error">{errors.celular.message}</div>
							)}
						</div>
					</div>
					<div className="coluna">
						<div className="globalInput">
							<div className="iconIput">
								<img src={balanca} alt="key" className="icon" />
							</div>
							<input
								onKeyUp={handleKeyUpPeso}
								type="text"
								id="massa"
								placeholder={current_language?.dash_peso}
								className="input"
								{...register("massa", {
									required: `${current_language?.login_input_senha}`,
									pattern: {
										value: /(?:\.|,|[0-9])*/i,
										message: "massa inválida",
									},
								})}
							/>
						</div>
						<div className="divhr">
							<hr className={errors.altura ? "hr hrcor" : "hr"} />
							{errors.altura && (
								<div className="error">{errors.altura.message}</div>
							)}
						</div>
					</div>
					<div className="coluna">
						<div className="globalInput">
							<div className="iconIput">
								<img src={idPerson} alt="cpf" className="icon" />
							</div>
							<input
								onKeyUp={handleKeyUpCpf}
								type="text"
								id="cpf"
								placeholder={current_language?.primeiro_acesso_CPF}
								className="input"
								{...register("cpf", {
									required: `${current_language?.login_input_senha}`,
									// pattern: {
									//   value:
									//     /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/i,
									//   message: "CPF inválido",
									// },
								})}
								{...hendleChange2}
							/>
						</div>
						<div className="divhr">
							<hr className={errors.cpf ? "hr hrcor" : "hr"} />
							{errors.cpf && <div className="error">{errors.cpf.message}</div>}
						</div>
					</div>
					<div className="coluna">
						<div className="globalInput">
							<div className="condicionamento"></div>
							<select
								id="condicionamento"
								className="input select2"
								{...register("condicionamento", {
									required: `${current_language?.login_input_senha}`,
								})}
							>
								<option className="option" value="">
									{current_language?.menu_lateral_condicionamento}
								</option>
								<option
									className="option"
									value="1"
									selected={
										user_logado.USUARIO_CONDICIONAMENTO === "1" ? true : false
									}
								>
									{current_language?.menu_lateral_atleta}
								</option>
								<option
									className="option"
									value="2"
									selected={
										user_logado.USUARIO_CONDICIONAMENTO === "2" ? true : false
									}
								>
									{current_language?.menu_lateral_ativo}
								</option>
								<option
									className="option"
									value="3"
									selected={
										user_logado.USUARIO_CONDICIONAMENTO === "3" ? true : false
									}
								>
									{current_language?.menu_lateral_idoso}
								</option>
								<option
									className="option"
									value="4"
									selected={
										user_logado.USUARIO_CONDICIONAMENTO === "4" ? true : false
									}
								>
									{current_language?.menu_lateral_pos_operatorio}
								</option>
								<option
									className="option"
									value="5"
									selected={
										user_logado.USUARIO_CONDICIONAMENTO === "5" ? true : false
									}
								>
									{current_language?.menu_lateral_sedentario}
								</option>
							</select>
						</div>
						<div className="divhr">
							<hr className={errors.condicionamento ? "hr hrcor" : "hr"} />
							{errors.condicionamento && (
								<div className="error">{errors?.condicionamento?.message}</div>
							)}
						</div>
					</div>
					<div className="coluna">
						<div className="globalInput">
							<div className="iconIput">
								<img src={dataNasc} alt="data" className="icon" />
							</div>
							<input
								onKeyUp={handleKeyUpData}
								type="text"
								id="data"
								placeholder={current_language?.menu_lateral_data_nascimento}
								className="input"
								{...register("data", {
									required: `${current_language?.login_input_senha}`,
									pattern: {
										value:
											/^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/,
										message: "Data inválida",
									},
								})}
							/>
						</div>
						<div className="divhrN">
							<hr className={errors.data ? "hr hrcor" : "hr"} />
							{errors.data && (
								<div className="error">{errors.data.message}</div>
							)}
						</div>
					</div>
					<Link to="/alterar_senha">
						<div className="coluna">
							<span className="globalInput buttonSenha" onClick={handleConta}>
								<div>
									<svg
										width="26"
										height="23"
										viewBox="0 0 26 23"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M14.6411 9.17355C15.2684 6.2884 14.0129 3.128 11.2865 1.3811C7.82888 -0.834319 3.30582 0.0625257 1.19788 3.3815C-0.910055 6.70047 0.186808 11.1982 3.64442 13.4136C6.37081 15.1605 9.75333 14.9719 12.0937 13.1844L16.6377 16.0959L14.0904 20.1067L18.2687 22.784L20.8161 18.7731L22.9053 20.1118L25.4527 16.1009L14.6411 9.17355ZM6.19178 9.40278C5.04272 8.66654 4.67574 7.16172 5.37626 6.05874C6.07679 4.95576 7.59008 4.6557 8.73913 5.39194C9.88819 6.12818 10.2552 7.633 9.55465 8.73599C8.85412 9.83897 7.34083 10.139 6.19178 9.40278Z"
											fill="#0023BD"
										/>
									</svg>
									<span 
										className="alterSenha" 
										onClick={()=> AmplitudeLog('edit_alterar_senha')}
									>
										{current_language?.menu_lateral_alterar_senha}
									</span>
								</div>
								<svg
									width="8"
									height="17"
									viewBox="0 0 8 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1 16L7 8.5L1 1"
										stroke="#000E4B"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
						</div>
					</Link>
						<div className="coluna">
							<span
								className="globalInput buttonSenha"
								onClick={permissionHandleAssinaturaConta}
							>
								<div>
									<svg
										width="45"
										height="45"
										version="1.1"
										viewBox="0 0 752 752"
										xmlns="http://www.w3.org/2000/svg"
									>
										<g>
											<path
												fill="#0023BD"
												d="m470.93 284.66 17.344 17.344-48.719 48.719c-5.7734 5.7734-5.7734 15.156 0 20.926 2.8711 2.9023 6.6602 4.3516 10.449 4.3516s7.5781-1.4492 10.477-4.3203l59.199-59.199c5.7734-5.7734 5.7734-15.156 0-20.926l-29.926-29.926c7.7852-16.488 5.2695-36.586-8.3477-50.199-17.344-17.344-45.434-17.344-62.777 0l-190.62 190.59v62.777h62.777z"
											/>
											<path
												fill="#0023BD"
												d="m168.81 494.4h414.38v29.598h-414.38z"
											/>
										</g>
									</svg>

									<span className="alterSenha" onClick={()=> AmplitudeLog('adicionar_assinatura')}>
										{current_language?.adicionar_assinatura}
									</span>
								</div>
								<svg
									width="8"
									height="17"
									viewBox="0 0 8 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1 16L7 8.5L1 1"
										stroke="#000E4B"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
						</div>
				</BodyEdite>

				<div className="inf">
					<button 
						type="submit" 
						id="salvar" 
						className="salve"
					>
						{aprovado == true ? (
							<CircularProgress color="inherit" />
						) : (
							current_language?.pagamento_salvar
						)}
					</button>
					<button 
						type="button" id="cancel" 
						className="cancel"
						onClick={()=> {
							voltar()
							AmplitudeLog('cancelar_edic_perfil')
						}}
					>
						{current_language?.botao_cancelar}
					</button>
				</div>
			</Content>
		</Conteiner>
	);
};

export default memo(EditarPerfil);
