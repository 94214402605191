import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: 100%;
`;

export const QualityLifeContent = styled.div`
	padding-vertical: 3px;
`;

export const Row = styled.div`
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 0.5rem;
`;

export const InfoText = styled.p`
	margin-top: 5px;
	color: var(--Neutras-Grey-K-50, #3D4259);
	font-family: "NotoSans-Regular";
	font-size: 0.9rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

export const Spectrum = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 0.5rem;
`;

export const Dot = styled.div`
	width: 40px;
	height: 0.25rem;
	margin-right: 0.09em;
`;

