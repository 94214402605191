import React, { useState } from "react";
import styled from "styled-components";
import AddIcon from "../../../assets/icons/adicionar_paci.svg";
import { useDispatch, useSelector } from "react-redux";
import { AmplitudeLog } from "../../../utils/amplitude";
import { ServicosProps } from "../../../interfaces/config.interface";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission } from "../../../utils/types";
import { AppActions } from "../../../store/ducks/app";
import history from "../../../routes/history";
import { TextsProps } from "../../../database/txt.interface";
import { ButtonAdd } from "../../../styles/global";
import { ExerciciosActions } from "../../../store/ducks/exer";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";

const PrincipalAddButton: React.FC = () => {
	//UI
	const dispatch = useDispatch();

	const [active, setActive] = useState(false);
	const [activedisplay, setActivedisplay] = useState(false);

	const total_pacientes = useSelector(
		(state: any) => state.dashboard.total_pacientes
	);

	const avaliadores = useSelector((state: any) => state.dashboard.avaliadores);

	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const HandleActive = () => {
		if (active) {
			setActive(false);
			setTimeout(() => {
				setActivedisplay(false);
			}, 500);
		} else {
			setActivedisplay(true);
			setTimeout(() => {
				setActive(true);
			}, 0);
		}
	};

	const AddPaci = () => {
		AmplitudeLog("adicionar_paciente");
		const permissionAdicionarPatient: any = getPermission(
			config_servicos,
			TypesPermission?.SERVICO_PACIENTES
		);
		total_pacientes < permissionAdicionarPatient
			? dispatch(AppActions.set_modal_adicionar_paciente(true))
			: dispatch(AppActions.set_modal_upgrade(true));
	};

	const AddAval = () => {
		AmplitudeLog("adicionar_avaliador");
		const permissionAdicionarEvaluator: any = getPermission(
			config_servicos,
			TypesPermission?.SERVICO_AVALIADORES
		);
		avaliadores.length < permissionAdicionarEvaluator
			? dispatch(AppActions.set_modal_adicionar_avaliador(true))
			: dispatch(AppActions.set_modal_upgrade(true));
	};

	const AddExer = () => {
		AmplitudeLog("adicionar_exercicio");
		const accessAddExercicio = getPermission(
			config_servicos,
			TypesPermission?.SERVICO_EXERCICIOS_PERSONALIZADOS
		);
		if (accessAddExercicio) {
			dispatch(ExerciciosActions.set_open_modal_adicionar_exercicio(true));
		} else {
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
		}
	};

	const AddProtocol = () => {
		AmplitudeLog("adicionar_protocolo");
		const accessAddProtocol: any = getPermission(
			config_servicos,
			TypesPermission?.SERVICO_PROTOCOLOS_PERSONALIZADOS
		);
		if (accessAddProtocol) {
			dispatch(ManageProtocolActions.set_open_modal_add_protocolo(true));
		} else {
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
		}
	};

	return (
		<Container>
			<TopOptions active={activedisplay}>
				<Tag onClick={AddAval} active={active} className={"options top"}>
					{current_language?.pag_inicial_avaliador}
				</Tag>
				<Tag onClick={AddPaci} active={active} className={"options top"}>
					{current_language?.pag_inicial_pacientes}
				</Tag>
			</TopOptions>
			<Medium active={activedisplay}>
				<Tag onClick={AddExer} active={active} className={"options bottom"}>
					{current_language?.exercicios}
				</Tag>
				<Tag onClick={AddProtocol} active={active} className={"options bottom"}>
					{current_language?.pag_inicial_menu_inferior_proto}
				</Tag>
			</Medium>
			<BottomOptions>
				<ButtonAdd active={active} onClick={() => HandleActive()}>
					<IconAdd src={AddIcon} />
					{current_language?.pag_inicial_menu_inferior_add}
				</ButtonAdd>
			</BottomOptions>
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	bottom: 1.5rem;
	right: 13%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.options {
		background-color: #c1fee8;
	}
	.top {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
	.bottom {
		margin-left: 2.3rem;
		margin-right: 2.3rem;
	}
`;

interface ActiveProps {
	active: boolean;
}

const Tag = styled.div<ActiveProps>`
	border-radius: 30px;
	font-size: 0.7rem;
	cursor: pointer;
	padding: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #000e4b;
	font-family: "NotoSans-Regular";
	font-weight: 600;
	text-align: center;
	width: 6rem;
	box-shadow: 0px 2.32px 4.65px 0px rgba(65, 77, 74, 0.6);

	transition-property: transform, opacity;
	transition-duration: 0.5s;
	transition-timing-function: ease;

	transform: ${({ active }) => (active ? "translateX(0)" : "translateY(30px)")};
	opacity: ${({ active }) => (active ? "100%" : "0")};

	&:hover {
		background-color: #000E4B;
		color: white;
	}
`;

const TopOptions = styled.div<ActiveProps>`
	display: flex;
	display: ${({ active }) => (active ? "flex" : "none")};
	flex-direction: row;
	justify-content: center;
	margin-bottom: 0.5rem;
`;

const Medium = styled.div<ActiveProps>`
	display: ${({ active }) => (active ? "flex" : "none")};
	flex-direction: row;
	justify-content: center;
	margin-bottom: 0.5rem;
`;

const BottomOptions = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 21.5rem;
`;

const IconAdd = styled.img`
	width: 1.6rem;
	height: 1.6rem;
	margin-right: 0.4rem;
`;

export default PrincipalAddButton;

