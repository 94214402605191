import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { TextsProps } from "../../../database/txt.interface";
import Limpar from "../../../assets/icons/limpar.svg";
import { useForm } from "react-hook-form";
import Lupa from "../../../assets/icons/lupa.svg";
import {
	ButtomSearch,
	ButtomSearchPulse,
	Container,
	DivInput,
	Icon,
	InputSearch,
	Reset,
} from "../../../styles/styles_input_pesquisas";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { ExerciciosActions } from "../../../store/ducks/exer";

const InputPesquisaProtocolo: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const { reset } = useForm();

	const dispatch = useDispatch();
	const [pulse, setPulse] = useState(false);

	const [isWriting, SetIsWriting] = useState(false);

	const Submit = (event: any) => {
		setPulse(false);
		if (event.key == "Enter" || event.type == "click") {
			setPulse(false);
			dispatch(DashBoardActions.set_button_search(true));
		}
	};

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);
	const [inputValueProtocol, setInputValueProtocol] = useState("");

	const resetInput = () => {
		dispatch(DashBoardActions.set_search_protocol(""));
		setInputValueProtocol("");
		reset();
	};

	const handleInputChangeProtocol = (event: any) => {
		setInputValueProtocol(event.target.value);
		if (inputValueProtocol != "") {
			const matchedExercises = exercicios.filter(
				(exercicio: Exercicios2Props) => {
					return (
						exercicio.EXERCICIO_TITULO_PT.toLowerCase().indexOf(
							event.target.value.toLowerCase()
						) !== -1
					);
				}
			);
			dispatch(ExerciciosActions.set_search_exercise(matchedExercises));
		}
	};

	//UI
	return (
		<Container>
			<div style={{ display: "flex" }}>
				<DivInput>
					{pulse ? (
						<ButtomSearchPulse type="button" onClick={Submit}>
							<Icon src={Lupa} />
						</ButtomSearchPulse>
					) : (
						<ButtomSearch type="button" onClick={Submit}>
							<Icon src={Lupa} />
						</ButtomSearch>
					)}
					<InputSearch
						onKeyDown={Submit}
						value={inputValueProtocol}
						onChange={handleInputChangeProtocol}
						type="text"
						placeholder={current_language?.pesquisar_exercicio}
					/>
					{isWriting === true && (
						<Reset type="reset">
							<Icon src={Limpar} alt="" onClick={resetInput} />
						</Reset>
					)}
				</DivInput>
			</div>
		</Container>
	);
};

export default InputPesquisaProtocolo;
