import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	height: 100vh;

	background-color: #fcfcfc;
	color: rgba(140, 142, 134, 0, 8);

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	svg {
		margin-bottom: 5rem;
	}
	.button {
		font-family: Roboto;
		margin-top:10px;
		font-size: 1.3rem;
		font-weight: 600;
		line-height: 1.758rem;

		color: #ffffff;

		width: 35%;
		height: 3.3rem;

		border: 0;
		border-radius: 0.8rem;

		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}

	.upgrade {
		background: ${Colors.primary_blue_k_main};
		margin-top:60px;
		transition: all 0.3s ease;
		color: #ffffff;
	}
	
	p {
		font-weight: 700;
		font-size: medium;
		text-align: center;

		width: 70%;

		color: #404040;

		padding: 0 1rem;

		@media (max-width: 800px) {
			width: 95%;
		}
	}

	.corpo {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.logoutAndUpgrade {
        display: flex;
        width: 80%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .logout {
        background: #40404090;
        margin-top:60px;
        transition: all 0.3s ease;
        color: #ffffff;
        font-family: Roboto;
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 1.758rem;
        width: 35%;
        height: 3.3rem;
        border: 0;
        border-radius: 0.8rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }


	.botoes {
		margin-top: 5rem;

		width: 50%;

		display: flex;
		justify-content: space-around;

		img {
			cursor: pointer;
		}
		@media (max-width: 800px) {
			width: 70%;
		}
		@media (max-width: 600px) {
			width: 100%;
		}
	}
`;
