import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Camera from "../../assets/icons/camera.svg";
import Lixeira from "../../assets/icons/trash.svg";
import Perfil from "../../assets/icons/perfil.svg";
import { AddPacAvalActions } from "../../store/ducks/add_pac_aval";
import { Container } from "./styles";

const FotoPerfilMenor: React.FC = () => {
	const dispatch = useDispatch();

	const [img, setImg] = useState<any>();
	function getBase64(event: any) {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = function () {
			setImg(reader.result);
			dispatch(AddPacAvalActions.set_avatar_eval_64(reader.result));
			dispatch(AddPacAvalActions.set_avatar_paci_64(reader.result));
		};
		reader.onerror = function (error) {
			console.log("Error: ", error);
		};
	}

	function deletePhoto(event: any) {
		event.target.value = null;
	}

	useEffect(() => {
		dispatch(AddPacAvalActions.set_photo(img));
	}, [img]);


	const is_open_paciente = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const removeFoto = (e: any) => {
		e.preventDefault();
		setImg("");
		dispatch(AddPacAvalActions.set_avatar_eval_64(""));
		dispatch(AddPacAvalActions.set_avatar_paci_64(""));
	}

	useEffect(() => {
		if (
			is_open_paciente?.USUARIO_FOTO != "" &&
			is_open_paciente?.USUARIO_FOTO != null &&
			img == null
		) {
			setImg(is_open_paciente?.USUARIO_FOTO);
		}
	}, []);

	return (
		<Container className="DivInput">
			<input
				id="img"
				type="file"
				onChange={getBase64}
				onClick={deletePhoto}
				style={{ display: "none" }}
				accept="image/*"
				disabled={!!img}
			/>
			<label htmlFor="img" className="labelForInput">
				<div className="conteinerImg">
					<img src={img && img || Perfil} alt="" className="imgForInput" />
				</div>
				{ img && (
					<div onClick={(e) => removeFoto(e)} className="ellipse">
						<img src={Lixeira} alt="" className="lixeira" />
					</div>
				) || (
					<img src={Camera} alt="" className="camera" />
				)}
			</label>
		</Container>
	);
};

export default memo(FotoPerfilMenor);
