import {
	Container,
	Box,
	Icon,
	Typography,
	Title,
	SubTitle,
	RadioBox,
	RadioInput,
	RadioLabel,
} from "./stylesCardItem";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

const CardItem = ({
	title = "",
	subTitle = "",
	icon = "",
	value = false,
	fncBtn,
	type,
}: any) => {
	const RadioButton: any = ({ active }: any) => {
		return (
			<RadioBox>
				<RadioLabel active={active} />
				<RadioInput  defaultChecked={active} /> 
			</RadioBox>
		);
	};

	return (
		<Container active={value} type={type} onClick={() => fncBtn(type)}>
			<Box>
				<Icon>
					<img src={icon} />
				</Icon>
				<Typography>
					<Title>{title}</Title>
					<SubTitle>{subTitle}</SubTitle>
				</Typography>
				<RadioButton active={value} />
			</Box>
		</Container>
	);
};

export default CardItem;

