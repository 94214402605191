import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextsProps } from "../../../../database/txt.interface";

import {
	Container,
	DivInput,
	Icon,
	InputSearch,
	Reset,
} from "../../../../styles/styles_input_pesquisas";
import { P2Desk } from "../../../../styles/styles_text";
import Arrow_drop_down from "../../../../assets/kinologyIcons/svg/ArrowSelect.svg";
import Filter from "../../../../assets/icons/filter.svg";
import { MenuOption } from "../../../../styles/global";
import styled from "styled-components";
import { ExerCucaoProp } from "../../../../interfaces/assimetria.interface";
import { RelatorioActions } from "../../../../store/ducks/relatorios";
import { ManageProtocolActions } from "../../../../store/ducks/manage_protocols";

const InputSelectSearch: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const exercicios_historicos = useSelector(
		(state: any) => state.relatorios.exercicios_historicos
	);

	const current_exercise_history: Array<ExerCucaoProp> = useSelector(
		(state: any) => state.relatorios.current_exercise_history
	);

	const [controler_menu, set_controler_menu] = useState(false);
	const [inputValueExer, setInputValueExer] = useState("");

	function returnPlaceholder() {
		let placeholder = "";
		current_exercise_history.map((item: ExerCucaoProp) => {
			placeholder = placeholder + `, ${item.EXERCICIO_TITULO_PT}`;
		});

		return placeholder == ""
			? "Digite o exercício ou selecione"
			: placeholder.slice(1);
	}

	const dispatch = useDispatch();

	const [exercicios_pesquisados, set_exercicios_pesquisados] = useState([]);
	const handleInputChangeExer = (event: any) => {
		setInputValueExer(event.target.value);
		if (inputValueExer != "") {
			set_controler_menu(true);
			const matcheExers = exercicios_historicos.filter(
				(exer: ExerCucaoProp) => {
					return (
						exer.EXERCICIO_TITULO_PT.toLowerCase().indexOf(
							event.target.value.toLowerCase()
						) !== -1
					);
				}
			);
			set_exercicios_pesquisados(matcheExers);
		} else {
			set_controler_menu(false);
		}
	};

	//UI
	return (
		<Container style={{ width: "100%" }}>
			<P2Desk>
				<strong>Escolha para visualizar</strong>
			</P2Desk>
			<div style={{ display: "flex", marginTop: "1rem" }}>
				<DivInput
					style={{ marginRight: "2rem" }}
			
				>
					<InputSearch
						style={{ marginLeft: "0px" }}
						value={inputValueExer}
						onChange={handleInputChangeExer}
						type="text"
						disabled
						placeholder={returnPlaceholder()}
					/>

					<Reset type="reset">
						<Icon src={Arrow_drop_down} alt="" 		onClick={(event) => {
						event.stopPropagation(),
							set_controler_menu(!controler_menu ? true : false);
					}} />
					</Reset>
				</DivInput>
				{/* <Icon
					src={Filter}
					onClick={() =>
						dispatch(
							ManageProtocolActions.set_open_modal_filter_agrupamento(true)
						)
					}
					alt=""
				/> */}
			</div>
			<DropDownExers active={controler_menu}>
				<div className="dropdown">
					<div className="dropdown-content">
						{(exercicios_pesquisados.length > 0
							? exercicios_pesquisados
							: exercicios_historicos
						).map((item: ExerCucaoProp, index: number) => (
							<MenuOption
								key={index}
								className="menuOption"
								onClick={() => {
									set_controler_menu(false);
									setInputValueExer("");
									dispatch(
										RelatorioActions.set_current_exercise_history([item])
									);
								}}
							>
								{item.EXERCICIO_TITULO_PT}
							</MenuOption>
						))}
					</div>
				</div>
			</DropDownExers>
		</Container>
	);
};

interface ActiveProps {
	active: boolean;
}

export const DropDownExers = styled.div<ActiveProps>`
	.dropdown-content {
		display: ${({ active }) => (active ? "flex" : "none")};
		flex-direction: column;
		position: absolute;
		white-space: nowrap;
		background-color: white;
		box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
		padding: 1rem;
		border-radius: 12px;
		width: 90%;
		z-index: 1;
	}

	.menuOption {
		padding-left: 1rem;
		&:hover {
			background: var(--Neutras-Grey-K-10, #dedfe3);
		}
	}
`;

export default InputSelectSearch;
