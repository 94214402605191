export const Types = {
	ADD_FIRST_INFOS: "ADD_FIRST_INFOS",
	REGISTER_REQUEST_PACI: "REGISTER_REQUEST_PACI",
	REGISTER_CANCEL: "REGISTER_CANCEL",
	SET_AVATAR_ADD_64: "SET_AVATAR_ADD_64",

	REGISTER_REQUEST_EVALUETOR: "REGISTER_REQUEST_EVALUETOR",
	REGISTER_CANCEL_EVALUETOR: "REGISTER_CANCEL_EVALUETOR",
	SET_AVATAR_EVAL_64: "SET_AVATAR_EVAL_64",

	DESATIVAR_PACI_REQUEST: "DESATIVAR_PACI_REQUEST",
	DESATIVAR_PACI_CANCEL: "DESATIVAR_PACI_CANCEL",

	RECUPERAR_PACI_REQUEST: "RECUPERAR_PACI_REQUEST",
	RECUPERAR_PACI_CANCEL: "RECUPERAR_PACI_CANCEL",

	SET_PACI_EDITION: "SET_PACI_EDITION",

	SET_TEST_LOAD: "SET_TEST_LOAD",

	SET_CONTAS: "SET_CONTAS",

	SET_PHOTO: "SET_PHOTO",

	SET_RECORRENTE: "SET_RECORRENTE",
	SET_FLAG_FILTRO: 'SET_FLAG_FILTRO',
};

const INITIAL_STATE = {
	first_info_user: null,
	loadinng_paci: false,

	user_paci_avatar_64: null,
	user_eval_avatar_64: null,

	loadinng_eval: false,

	loading_desativacao: false,
	loading_recuperarcao: false,

	paci_edition: null,

	test_load: false,

	contas: null,

	photo: null,

	recorrente: null,
	call_filtro: false,
};

export default function add_pac_aval(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.ADD_FIRST_INFOS:
			return {
				...state,
				first_info_user: action.payload,
			};
		case Types.REGISTER_REQUEST_PACI:
			return {
				...state,
				loadinng_paci: true,
			};
		case Types.SET_AVATAR_ADD_64:
			return {
				...state,
				user_paci_avatar_64: action.payload,
			};
		case Types.REGISTER_CANCEL:
			return {
				...state,
				loadinng_paci: false,
			};
		case Types.REGISTER_REQUEST_EVALUETOR:
			return {
				...state,
				loadinng_eval: true,
			};
		case Types.REGISTER_CANCEL_EVALUETOR:
			return {
				...state,
				loadinng_eval: false,
			};
		case Types.SET_AVATAR_EVAL_64:
			return {
				...state,
				user_eval_avatar_64: action.payload,
			};

		case Types.DESATIVAR_PACI_REQUEST:
			return {
				...state,
				loading_desativacao: true,
			};
		case Types.DESATIVAR_PACI_CANCEL:
			return {
				...state,
				loading_desativacao: false,
			};
		case Types.RECUPERAR_PACI_REQUEST:
			return {
				...state,
				loading_recuperarcao: true,
			};
		case Types.RECUPERAR_PACI_CANCEL:
			return {
				...state,
				loading_recuperarcao: false,
			};
		case Types.SET_PACI_EDITION:
			return {
				...state,
				paci_edition: action.payload,
			};
		case Types.SET_TEST_LOAD:
			return {
				...state,
				test_load: action.payload,
			};
		case Types.SET_CONTAS:
			return {
				...state,
				contas: action.payload,
			};
		case Types.SET_PHOTO:
			return {
				...state,
				photo: action.payload,
			};
		case Types.SET_RECORRENTE:
			return {
				...state,
				recorrente: action.payload,
			};
		case Types.SET_FLAG_FILTRO:
			return {
				...state,
				call_filtro: action.payload,
			};

		default:
			return state;
	}
}

export const AddPacAvalActions = {
	add_first_infos: (data: any) => ({
		type: Types.ADD_FIRST_INFOS,
		payload: data,
	}),
	request_add_user: (data: any) => ({
		type: Types.REGISTER_REQUEST_PACI,
		payload: data,
	}),
	request_cancel: () => ({
		type: Types.REGISTER_CANCEL,
	}),
	set_avatar_paci_64: (avatar_64: any) => ({
		type: Types.SET_AVATAR_ADD_64,
		payload: avatar_64,
	}),
	request_add_evaluetor: (data: any) => ({
		type: Types.REGISTER_REQUEST_EVALUETOR,
		payload: data,
	}),
	request_cancel_evaluetor: () => ({
		type: Types.REGISTER_CANCEL_EVALUETOR,
	}),
	set_avatar_eval_64: (avatar_64: any) => ({
		type: Types.SET_AVATAR_EVAL_64,
		payload: avatar_64,
	}),
	request_desativar_paci: (data: any) => ({
		type: Types.DESATIVAR_PACI_REQUEST,
		payload: data,
	}),
	request_cancel_paci: () => ({
		type: Types.DESATIVAR_PACI_CANCEL,
	}),
	request_recuperar_paci: (data: any) => ({
		type: Types.RECUPERAR_PACI_REQUEST,
		payload: data,
	}),
	request_recuperar_cancel_paci: () => ({
		type: Types.RECUPERAR_PACI_CANCEL,
	}),
	set_edit_paci: (paci: any) => ({
		type: Types.SET_PACI_EDITION,
		payload: paci,
	}),
	set_teste_load: (status: any) => ({
		type: Types.SET_TEST_LOAD,
		payload: status,
	}),
	set_contas: (status: any) => ({
		type: Types.SET_CONTAS,
		payload: status,
	}),
	set_photo: (status: any) => ({
		type: Types.SET_PHOTO,
		payload: status,
	}),
	set_recorrente: (status: any) => ({
		type: Types.SET_RECORRENTE,
		payload: status,
	}),
	set_flag_filtro: (status: boolean)=>({
		type: Types.SET_FLAG_FILTRO,
		payload: status,
	})
};
