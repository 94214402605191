
import React, { memo, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AmplitudeLog } from "../../../utils/amplitude";
import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";

import buttonClose from "../../../assets/icons/fechar.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import DinoHappy from "../../../assets/icons/dinoFeliz.svg";
import { TypesQuestionnaire } from "../../../utils/types";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { PacientesProps } from "../../../interfaces/pacientes.interface";

import { 
	ButtonContinuar, 
	ButtonGerarNovo, 
	Close, 
	Container, 
	ContainerButtons, 
	ContainerDino, 
	Content, 
	Dino, 
	Phrase, 
	TextArea, 
	Title 
} from "./styles";
import { Colors } from "../../../styles/colors";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalContinuarAnamnese: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const classes = useStyles();
	const is_open_continuar_ou_gerar_anamnese = false
	const ModalContinuarAnamneseClose = () => {
		dispatch(AppActions.set_modal_continue_or_to_generate_new_anamnese(false))
	};
	const historicos_questionarios = useSelector(
		(state: any) => state.pac_aval.perfil_anamnese_array
	);
	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const typeQuestionario = useSelector(
		(state: any) => state.app.continue_anamnese_type
	);
	
	
	
	function compareForExecutada(a: any, b: any) {
		return (
			new Date(b.QUESTIONARIO_RESPOSTA_CRIADO).getTime() -
			new Date(a.QUESTIONARIO_RESPOSTA_CRIADO).getTime()
		);
	}
	let final_filtered_anamneses: any = [
		historicos_questionarios?.historico_anamnese_tipo,
		historicos_questionarios?.historico_anamnese_avaliacao,
	];

	const filtered: any = final_filtered_anamneses.sort(compareForExecutada);

	const first_anamneses: any = final_filtered_anamneses[0];
	
	const filtered_anamneses = filtered.filter(
		(element: any) => element?.QUESTIONARIO_RESPOSTA_CONCLUIDO == "S"
	);

	if (first_anamneses?.QUESTIONARIO_RESPOSTA_CONCLUIDO == "N") {
		final_filtered_anamneses = [first_anamneses, ...filtered_anamneses];
	} else {
		final_filtered_anamneses = filtered_anamneses;
	}

	
	const continuarQuestionario = () => {
		if(typeQuestionario == TypesQuestionnaire.ANAMNESE){

			dispatch(PacAvalActions.set_questionario_request(true));
			dispatch(
				PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE_TIPO)
			);
			dispatch(PacAvalActions.set_tipo_anamnese( 
				historicos_questionarios?.historico_anamnese_tipo[0]?.QUESTIONARIO_RESPOSTA_TIPO.split("_")[2]
			));
			dispatch(
				PacAvalActions.set_usu_continuar_questionario(paciente.ID_USUARIO)
			);
			dispatch(AppActions.set_modal_continue_or_to_generate_new_anamnese(false))
		}

		if(typeQuestionario == TypesQuestionnaire.ANAMNESE_FISICA){
			dispatch(PacAvalActions.set_questionario_request(true));
			dispatch(
				PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE_AVALIACAO) 
			);
			dispatch(PacAvalActions.set_tipo_anamnese(
				historicos_questionarios?.historico_anamnese_avaliacao[0]?.QUESTIONARIO_RESPOSTA_TIPO.split("_")[2]
			));
			
			dispatch(
				PacAvalActions.set_usu_continuar_questionario(paciente.ID_USUARIO)
			);
			dispatch(AppActions.set_modal_continue_or_to_generate_new_anamnese(false))
		}
	};


	const selectNewTypeAnamnese = () => {

		if(typeQuestionario == TypesQuestionnaire.ANAMNESE){
			dispatch(PacAvalActions.set_usu_continuar_questionario(""));
			dispatch(PacAvalActions.set_reset_questionario());
			dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE));
		}
		if(typeQuestionario == TypesQuestionnaire.ANAMNESE_FISICA){
			
			dispatch(PacAvalActions.set_usu_continuar_questionario(""));
			dispatch(PacAvalActions.set_reset_questionario());
			dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE_FISICA))
		}
		dispatch(AppActions.set_modal_continue_or_to_generate_new_anamnese(false))
	};


	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_continuar_ou_gerar_anamnese}
				onClose={ModalContinuarAnamneseClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_continuar_ou_gerar_anamnese}>
					<Container>
						<Close
							src={buttonClose}
							onClick={()=>  
								dispatch(AppActions.set_modal_continue_or_to_generate_new_anamnese(false))
							}
							alt="Close"
						/>
						<Content>
							<ContainerDino >
								<Dino src={DinoHappy} alt="" />
							</ContainerDino>

							<Title>
								{current_language?.anamnese_andamento}
							</Title>

							<TextArea>
								<Phrase>
									{current_language?.anamnese_andamento_p1}
								</Phrase>
								<br/>
								<Phrase>
									{current_language?.anamnese_andamento_p2}
								</Phrase>
							</TextArea>
							
							<ContainerButtons>
								<ButtonGerarNovo
									onClick={()=> {
										selectNewTypeAnamnese()
										AmplitudeLog('gerar_nova_anamnese')
									}}
								>
									{current_language?.gerar_nova}
								</ButtonGerarNovo>

								<ButtonContinuar
									style={{
										backgroundColor:  Colors.primary_blue_k_main
									}}
									onClick={()=> 
										continuarQuestionario() 
									}
								>
									{current_language?.continuar_anamnese}
								</ButtonContinuar>
							</ContainerButtons>
						</Content>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalContinuarAnamnese);
