import styled from "styled-components";

export const Container = styled.div`
	background-color: #ffffff;
	height: 539px;
	width: 722px;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	.close {
		width: 21px;

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		cursor: pointer;
	}

	.divText {
		width: 70%;
	}

	.text {
		font-weight: 500;
		font-family: Roboto;
		font-size: 24px;
		text-align: center;
		color: #5c5b5b;

		margin-bottom: 2rem;
	}

	.subText {
		font-weight: 400;
		font-family: Roboto;
		font-size: 22px;
		text-align: center;
		color: #5c5b5b;
	}

	.card {
		height: 70%;
		width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
	}
`;
