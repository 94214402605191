import React, { useState } from 'react';

import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { ComponentButtonOptions, Container, Faces,  } from './styles';
import { DashBoardActions } from '../../../store/ducks/dashboard';
import { AppActions } from '../../../store/ducks/app';
import { TextsProps } from '../../../database/txt.interface';

import  ImageBG from "../../../assets/icons/ImageBG.svg";
import  PositivoNegativo from "../../../assets/icons/PositivoNegativo.svg";
import SatisfacaoQuestion from '../../../assets/icons/SatisfacaoQuestion.svg';
import Triste from '../../../assets/icons/Triste.svg';
import MuitoTriste from '../../../assets/icons/MuitoTriste.svg';
import Feliz from '../../../assets/icons/Feliz.svg';
import Neutro from '../../../assets/icons/Neutro.svg';
import MuitoFeliz from '../../../assets/icons/MuitoFeliz.svg';
import buttonClose from "../../../assets/icons/closeSatisfacao.svg";


interface PropsFace {
    id: number,
    faceOption: string,
    color: string,
    icon: string,
};
enum typeModal {
    EXAME_1 = 'EXAME_1',
    EXAME_2 = 'EXAME_2',
}

const ModalSatisfacaoExame:React.FC = ()=> {
    const dispatch = useDispatch();

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            modal: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            paper: {
                backgroundColor: theme.palette.background.paper,
                border: "0",
                boxShadow: theme.shadows[5],
            },
        })
    );
    
    const classes = useStyles();
    const open_modal_satisfacaoExame: boolean = useSelector((state: any)=> state.app.set_modal_satisfacao_exame)
    const type_modal_satisfacao_exame: string | null = useSelector((state: any)=> state.app.type_modal_satisfacao_exame)
    const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
    const amount_executions: number =  useSelector(
	 	(state: any) => state.dashboard.set_quantidade_execucoes,
    );
    const id_current_exercise: number = useSelector((state:any)=> state.exercicios.id_exercise)

    const [text, setText] = useState('')
    const [question, setQuestion] = useState(1);

    const faces = [
        {id: 0, faceOption: '1', color:  '#FFF4EC', icon: Triste},
        {id: 1, faceOption: '2', color: '#FEEFEF', icon: MuitoTriste },
        {id: 2, faceOption: '3', color: '#FFF1CC', icon: Neutro  },
        {id: 3, faceOption: '4', color: '#DEF1F5', icon: Feliz },
        {id: 4, faceOption: '5', color: '#EDF9F0', icon: MuitoFeliz },
    ];

    const ButtonOptions = [
        {id: 0, option: current_language?.muito_facil},
        {id: 1, option: current_language?.facil},
        {id: 2, option: current_language?.neutro},
        {id: 3, option: current_language?.dificil},
        {id: 4, option: current_language?.muito_dificil},
    ];


    const faceOptions = (item:PropsFace)=> {
        return (
            <Faces
                key={item?.id}
                style={{ backgroundColor: item?.color }}
                onClick={()=> handleInfo(item?.id)}
            >
                <img src={item?.icon}  width={24} height={24}/>
            </Faces>
        )
    };

    const HandleButtonOptions = (item:{id:number, option: string})=> {

        const handleClickButton = (id: number, option: string)=> {
           
            if(option == current_language?.dificil || option == current_language?.muito_dificil){
                setQuestion(2)
            }else{
                dispatch(DashBoardActions.set_salvar_questionario_satisfacao(
                    moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
                    type_modal_satisfacao_exame == typeModal.EXAME_1 ?  "EXAME_1" : "EXAME_2"  ,
                    [id],
                    [text],
                    -1
                ))
                dispatch(AppActions.set_modal_satisfacao_exame(false, null))
                dispatch(DashBoardActions.set_amount_executions(null))
                dispatch(DashBoardActions.set_info_questionario_satisfacao(null,null))
                setQuestion(1)
            }
        };


        return (
            <ComponentButtonOptions
                key={item?.id}
                style={{
                    border: 'solid',
                    borderWidth: 2,
                    borderColor: '#000E4B'
                }}
                onClick={()=> handleClickButton(item?.id, item?.option)}
            >
                <p className='textOption'>{item?.option}</p>
            </ComponentButtonOptions>
        )
    };

    const handleInfo = (id?: number)=> {
        if(id == 0 ){
            setQuestion(2)
        }else {
            dispatch(DashBoardActions.set_salvar_questionario_satisfacao(
                moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
                type_modal_satisfacao_exame == typeModal.EXAME_1 ?  "EXAME_1" : "EXAME_2"  ,
                [id ? id : 0],
                [text],
                -1
            ))
            dispatch(AppActions.set_modal_satisfacao_exame(false, null))
            dispatch(DashBoardActions.set_amount_executions(null))
            dispatch(DashBoardActions.set_info_questionario_satisfacao(null,null))
            setQuestion(1)
            setText('')
        }
    };

    const NotAnswer = ()=> {
        dispatch(DashBoardActions.set_salvar_questionario_satisfacao(
            moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
            type_modal_satisfacao_exame == typeModal.EXAME_1 ?  "EXAME_1" : "EXAME_2" ,
            ["PULOU"],
            [""],
            -1
        ))
        dispatch(AppActions.set_modal_satisfacao_exame(false, null))
        dispatch(DashBoardActions.set_amount_executions(null))
        dispatch(DashBoardActions.set_info_questionario_satisfacao(null,null))
        setQuestion(1)
    };

    const HandlePhrase = ()=> {
        return  type_modal_satisfacao_exame == typeModal.EXAME_1 && question == 1
            ? current_language?.esforco_primeiro_exame
            : type_modal_satisfacao_exame == typeModal.EXAME_1 && question == 2
                ? current_language?.compartilhe_percepcoes
                :  type_modal_satisfacao_exame == typeModal.EXAME_2  && question == 1
                    ? current_language?.facil_realizar_exame
                    : type_modal_satisfacao_exame == typeModal.EXAME_2 && question == 2 && current_language?.compartilhe_percepcoes
    };

    const HandleInput = ()=> {
        return (
            <div className='ContainerInput'>
                <textarea 
                    className='Sugestions'
                    cols={0} 
                    rows={10}
                    placeholder={current_language?.digite_aqui}
                    onChange={(text)=> setText(text.target.value) }
                    value={text}
                />
            </div>
        )
    };

    const HandleContent = ()=> {
        return (
                       
            type_modal_satisfacao_exame == typeModal.EXAME_1 && question == 1
            ?
                <div className='ContainerFaces' >
                    {
                        faces.map((item: PropsFace)=> (
                            faceOptions(item)
                        ))
                    }
                </div>
            : type_modal_satisfacao_exame == typeModal.EXAME_1 && question == 2
                    ?
                     HandleInput()
                    : type_modal_satisfacao_exame == typeModal.EXAME_2 && question == 1
                        ? 
                            ButtonOptions.map((item:{id:number, option: string})=> (
                                HandleButtonOptions(item)
                            ))
                        : type_modal_satisfacao_exame == typeModal.EXAME_2 && question == 2 && HandleInput()

        )
    };

    return (
        <div>
            <Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open_modal_satisfacaoExame}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
                    <Fade in={open_modal_satisfacaoExame}>
                        <Container>
                            <div className='ContentClose'>
                                <img
                                    src={buttonClose}
                                    width={32}
                                    height={32}
                                    onClick={()=> NotAnswer()}
                                    alt="Close"
                                />
                            </div>

                            <div className='containerBg'>
                                <div className='BG'>
                                    <img src={ImageBG} alt="" />
                                    <img 
                                        src={
                                            type_modal_satisfacao_exame == typeModal.EXAME_1 || question == 2
                                            ? PositivoNegativo
                                            : SatisfacaoQuestion
                                        }  
                                        alt="" 
                                        style={{position: 'absolute'}}
                                    />
                                </div>
                            </div>

                            <div className='ContainerText'>
                                <p className='Text'>
                                    {HandlePhrase()}
                                </p>
                            </div>

                            <div className='containerButtons'>
                                    {HandleContent()}
                            </div>

                            <div className='footer'>
                                {
                                    question == 1
                                    ?
                                        <p 
                                            className='pular'
                                            onClick={()=> NotAnswer()}
                                        >
                                            {current_language?.jump}
                                        </p>
                                    :
                                        <div className='Send' onClick={()=> handleInfo()}>
                                            <p className='TextSend'>{current_language?.enviar}</p>
                                        </div> 
                                }
                            </div>
                        </Container>
                    </Fade>
			</Modal>
        </div>
    )
}
export default ModalSatisfacaoExame;