import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { TagActions } from "../../../store/ducks/tags";
import { Container, Content } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalAdicionarEtiqueta: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const classes = useStyles();
	const is_open_atribuir_etiqueta = useSelector(
		(state: any) => state.app.is_open_adicionar_etiqueta
	);
	const ModalAddEtiquetaClose = () => {
		dispatch(AppActions.set_modal_adicionar_etiqueta(false));
	};

	//Pega o valor do input em tempo real
	const [value, setvalue] = useState<any>();
	const [isSearch, setIsSearch] = useState<boolean>(false);
	const isWriting = (e: any) => {
		if (e.target.value !== "") {
			setIsSearch(true);
		} else {
			setIsSearch(false);
		}
		setvalue(e.target.value);
	};
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	//Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const [aprovado, setAprovado] = useState(false);

	const onSubmit = async (data: any) => {
		setAprovado(true);
		const request = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			ETIQUETA_NOME: data.etiqueta,
		};
		dispatch(TagActions.add_tag_request(request));
		AmplitudeLog('etiqueta_adicionada', {
			body: request
		})
		reset();
		ModalAddEtiquetaClose();
	};

	useEffect(() => {
		if (is_open_toast == true) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_atribuir_etiqueta}
				onClose={ModalAddEtiquetaClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_atribuir_etiqueta}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalAddEtiquetaClose}
							alt="Close"
						/>
						<Content>
							<form className="form" onSubmit={handleSubmit(onSubmit)}>
								<div className="cabecalho">
									<p className="title">
										{current_language?.modal_nova_etiqueta}
									</p>
									<div className="divInput">
										<svg
											width="32"
											height="33"
											viewBox="0 0 32 33"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M5.18322 16.698C5.08072 17.5281 5.38532 18.4361 6.06238 19.1132L17.1873 30.2198C18.2989 31.3314 20.0221 31.4271 21.0278 30.4214L30.1706 21.2786C31.1764 20.2729 31.0806 18.5497 29.969 17.4381L18.8624 6.31316C18.1853 5.6361 17.2773 5.3315 16.4472 5.434L6.39922 6.65L5.18322 16.698Z"
												fill="#8D8D8D"
											/>
											<circle
												cx="12.8512"
												cy="13.1012"
												r="2.54062"
												fill="white"
											/>
										</svg>
										<input
											className="input"
											placeholder={current_language?.modal_nova_etiqueta}
											onKeyUp={(e) => isWriting(e)}
											{...register("etiqueta", {
												required: `${current_language?.login_input_senha}`,
												pattern: {
													value: /^[0-9a-zA-Z$*&@#]{3,}/,
													message: `${current_language?.alterar_senha_minimo}`,
												},
											})}
										/>
									</div>
									<div className="divhr">
										<hr className={errors.etiqueta ? "hr hrcor" : "hr"} />
										{errors.etiqueta && (
											<div className="error">{errors.etiqueta.message}</div>
										)}
									</div>
								</div>
								{isSearch && <span className="tag">{value}</span>}
								<div className="divButtons">
									<button 
										className="salvar" 
										type="submit"
									>
										{aprovado == true ? (
											<CircularProgress color="inherit" />
										) : (
											current_language?.pagamento_salvar
										)}
									</button>
									<button
										className="cancelar"
										type="button"
										onClick={()=> {
											ModalAddEtiquetaClose(), 
											AmplitudeLog('cancelar_add_etiqueta')
										}}
									>
										{current_language?.botao_cancelar}
									</button>
								</div>
							</form>
						</Content>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalAdicionarEtiqueta);
