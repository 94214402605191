import { CardProps } from "material-ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import cartaoCredito from "../../../assets/icons/cartaoCredito.svg";
import Editar from "../../../assets/icons/editar.svg";
import Excluir from "../../../assets/icons/excluir.svg";
import mastercard from "../../../assets/icons/mastercard.svg";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Pontos from "../../../assets/icons/pontos.svg";
import { TextsProps } from "../../../database/txt.interface";
import { CartaoProps, FaturaProps } from "../../../interfaces/cartao.interface";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { ConfigsActions } from "../../../store/ducks/configs";
import { FinancialActions } from "../../../store/ducks/financial";
import {
	Conteiner,
	Footer,
	Header,
	ContainerGoBack,
	Content,
	ContainerInfoCard,
	CardNumbers,
	ContainerIconEdit,
	Faturas,
	FaturasPendentes,
	ContainerTextFaturas,
	ArrowFaturas,
	Content2,
} from "./styles";
import history from "../../../routes/history";
import { ReactComponent as ArrowBack } from "../../../assets/kinologyIcons/svg/arrow_back.svg";
import { ReactComponent as Edit } from "../../../assets/kinologyIcons/svg/edit.svg";
import { ReactComponent as Card } from "../../../assets/kinologyIcons/svg/credit_card.svg";
import Invoice from "../../../assets/kinologyIcons/svg/Invoice.svg";
import ArrowInvoice from "../../../assets/kinologyIcons/svg/arrow_forward_ios.svg";
import Add from "../../../assets/kinologyIcons/svg/add_Payment_round.svg";
import Text from "../../../components/Texts/Text";
import { Colors } from "../../../styles/colors";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { ContainerDash, Icon, Return } from "../../../styles/global";
import { H2Desk, P1Desk, P2Desk, P3Desk } from "../../../styles/styles_text";

enum TypeFatura {
	BOLETO = "Boleto",
	PENDING = "pending",
}

const Pagamentos: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);

	useEffect(() => {
		dispatch(
			FinancialActions.financial_request(
				config_servicos?.SERVICO_IUGU,
				config_servicos?.SERVICO_SUPERLOGICA
			)
		);
	}, []);
	const faturas = useSelector((state: any) => state.financial.faturas);
	const cartao = useSelector((state: any) => state.financial.cartao);

	const fatura = faturas?.filter(
		(fatura: FaturaProps) =>
			fatura?.FATURA_TIPO === TypeFatura?.BOLETO ||
			fatura?.FATURA_TIPO === TypeFatura?.PENDING
	);

	const numberFaturas = 2;

	const handleVisualizarFatura = () => {
		dispatch(AppActions.set_open_visualizar_fatura(true));
	};
	const handleAdicionarMetodoPagamento = () => {
		dispatch(AppActions.set_open_adicionar_metodo_pagamento(true));
	};

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("pagamentos"));
	}, []);

	return (
		<ContainerDash>
			<Content2>
				<Return
					onClick={() => {
						dispatch(DashBoardActions.set_current_page("minha_conta"));
						dispatch(
							AppActions.set_option_menu(
								false,
								current_language?.menu_lateral_minha_conta
							)
						);
					}}
				>
					<Icon src={ReturnIcon} style={{ width: "1rem", height: "1rem" }} />
					<P3Desk>Minha conta</P3Desk>
				</Return>
				<H2Desk style={{ marginTop: "1rem" }}>Pagamentos</H2Desk>
				<P2Desk style={{ marginTop: "1rem" }}>Método de Pagamento</P2Desk>
				<Content>
					{cartao?.lenght > 0 ? (
						cartao?.map((card: CartaoProps) => (
							<ContainerInfoCard key={card.CC_NUMERO}>
								<CardNumbers>
									<Card color={Colors?.primary_blue_k_main} />
									<P2Desk>
										"****-****-****- " + `$
										{card?.CC_NUMERO?.toString().slice(-4)}`
									</P2Desk>
								</CardNumbers>
								<ContainerIconEdit
									onClick={() => {
										handleAdicionarMetodoPagamento(),
											history.push("metodo_pagamento");
									}}
								>
									<Edit
										color={Colors.primary_blue_k_main}
										className="EditCard"
									/>
								</ContainerIconEdit>
							</ContainerInfoCard>
						))
					) : (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								marginTop: 20,
								alignItems: "center",
								cursor: "pointer",
							}}
							onClick={() => {
								dispatch(DashBoardActions.set_current_page("adicionar_pagamento"))
							}}
						>
							<Icon style={{ marginRight: "1rem" }} src={Add} />
							<P2Desk>
								<strong>{current_language?.pagamento_adicionar_metodo}</strong>
							</P2Desk>
						</div>
					)}
				</Content>
				{numberFaturas > 0 && (
					<Faturas>
						<P2Desk>{current_language?.pagamento_faturas}</P2Desk>
						<FaturasPendentes>
							<ContainerTextFaturas>
								<Icon src={Invoice} style={{ marginRight: "1rem" }} />

								<P1Desk style={{ color: Colors?.tertirary_red_1_dark }}>
									{current_language?.pagamento_fatura_pendente}
								</P1Desk>
							</ContainerTextFaturas>
							<ArrowFaturas
								onClick={() => {
									handleVisualizarFatura(), history.push("/faturas");
								}}
							>
								<Icon src={ArrowInvoice} />
							</ArrowFaturas>
						</FaturasPendentes>
					</Faturas>
				)}
			</Content2>
		</ContainerDash>
	);
};

export default Pagamentos;
