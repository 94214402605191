import {
	Backdrop,
	Fade,
	Modal,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import {Button, Container, ContainerButtons, ContainerClose, Content, Title} from "./styles";
import { TextsProps } from "../../../database/txt.interface";

import {ReactComponent  as Close }from '../../../assets/kinologyIcons/svg/close.svg';
import {ReactComponent  as Worning }from '../../../assets/kinologyIcons/svg/WorningRed.svg';

import { Colors } from "../../../styles/colors";
import Text from "../../Texts/Text";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);
interface ModalProps {
    visible: boolean;
    save?: (data: boolean)=> void;
    cancel?: ()=> void;
}

const ModalSaveChangeEditPatient: React.FC<ModalProps> = ({
    visible,
    save,
    cancel
}) => {
	const classes = useStyles();
	const current_language: TextsProps = useSelector((state: any) => state.configs.current_language);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={visible}
			// onClose={ModalCadastroClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={visible}>
				<Container>
                    <ContainerClose>
                        <Close  color={Colors?.primary_blue_k_main}/>
                    </ContainerClose>
                    <Content>
                        <Worning />
                        <Text
                            title={current_language?.alteracoes_nao_salvas}
                            fontSize={1.5}
                            fontFamily="NotoSans-Regular"
                            fontWeight={700}
                            textColor={Colors?.primary_blue_k_main}
                            marginTop={20}
                        />
                        <Text
                            title={current_language?.deseja_salvar_alteracoes}
                            fontSize={1.3}
                            fontFamily="NotoSans-Regular"
                            fontWeight={400}
                            textColor={Colors?.neutral_grey_k_60}
                            marginTop={20}
                        />
                        <ContainerButtons >
                            <Button 
                                style={{border: `2px solid ${Colors?.secondary_green_k_main}`  }}
                                onClick={()=> save?.(true)}
                            >
                                <Title>
                                    {current_language?.menu_lateral_botao_salvar}
                                </Title>
                            </Button>
                            <Button 
                                style={{border: `2px solid ${Colors?.primary_blue_k_main}`}}
                                onClick={()=> cancel?.()}
                            >
                                <Title>
                                    {current_language?.botao_cancelar}
                                </Title>
                            </Button>
                        </ContainerButtons>
                    </Content>
				</Container>
			</Fade>
		</Modal>
	);
};

export default ModalSaveChangeEditPatient;
