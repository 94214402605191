import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AvaliationActions } from "../../store/ducks/avaliation";

const Timer: React.FC = () => {
	const dispatch = useDispatch();

	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const is_play = useSelector((state: any) => state.app.is_play_exercicios);
	const time_final = useSelector((state: any) => state.avaliation.time_final);

	useEffect(() => {
		if (is_play) {
			setTimeout(() => {
				dispatch(AvaliationActions.set_timer_final(time_final + 1));
				if (seconds === 59) {
					setMinutes(minutes + 1);
					setSeconds(0);
				} else {
					setSeconds(seconds + 1);
				}
			}, 1000);
		} else if (is_play === false) {
			return;
		}
	}, [is_play, seconds]);
	return (
		<p>
			{minutes <= 9 ? `0${minutes}` : minutes}:
			{seconds <= 9 ? `0${seconds}` : seconds}
		</p>
	);
};

export default Timer;
