import styled from "styled-components";

export const AreaText = styled.textarea`
	width: 22.875rem;
	height: 12.063rem;
	min-height: 12.063rem;
	min-width: 22.875rem;

	background-color: #EDEDED;

	padding: .4rem 0;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 120%;

	border: 0;

	border-bottom: 1px solid #404040;

	color: #606060;
	
  ::placeholder,
  ::-webkit-input-placeholder {
		color: #B3B3B3;
  }
  :-ms-input-placeholder {
		color: #B3B3B3;
  }
`;


export const Radio = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	
	label {
		cursor: pointer;
		color: #404040;
	}

	.custom-checkbox input + .label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 20%;
		background-color: #EDEDED;
		border: 2px solid #E4E3E3;
		/* display: inline-block;
		vertical-align: middle; */
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.custom-checkbox input:checked + .label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: #001574;
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
		display: flex;
		align-items: center;
	}
`;

export const Content = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	height: 2.5rem;
	width: 90%;

	margin-bottom: 3rem;

	background: #FCFCFC;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.16);
	border-radius: 8px;

	p {
		margin-right: 1rem;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 150%;

		color: #404040;
}
`;