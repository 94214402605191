import styled from "styled-components";
import { Colors } from "../../../styles/colors";
import { TypesLanguage } from "../../../utils/types";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	border-radius: 1.25rem;
	overflow-y: auto;
`;

export const Content = styled.div`
	width: 60%;
	display: flex;
	flex-direction: column;
`;

export const Header = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`;
export const ContainerTitle = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
`;

export const ContainerArrowIcon = styled.div`
	display: flex;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	align-items: center;
	justify-content: center;
	background-color: transparent;
`;
export const OptionConfigs = styled.div`
	display: flex;
	width: 100%;
	height: 2.5rem;
	border: 2px solid ${Colors.primary_blue_k_main};
	border-radius: 12px ;
	margin-top: 40px;

`;
export const TextGeneral = styled.p`
	font-family: "Kanit-Regular";
	font-size: 1rem;
	font-weight: 600;
	color: ${Colors.neutral_grey_k_5};
	cursor: pointer;
`;

export const GeneralOptions = styled.div<{selected: string}>`
	display: flex;
	width: 50%;
	height: 100%;
	justify-content: center;
	align-items: center;
	border-top-left-radius:${({selected})=> selected != 'gerais' ? '20px' : '7px' };
	border-bottom-left-radius:${({selected})=> selected != 'gerais' ? '20px' : '7px' };
	background-color: ${({selected})=> selected == 'gerais' 
		? Colors?.primary_blue_k_main
		: Colors?.white
	};
	cursor: pointer;

	${TextGeneral}{
		color: ${({selected})=> selected == 'gerais' 
			? Colors?.white
			: Colors?.primary_blue_k_main
		}
	}
`;

export const TextExercise= styled.p`
	font-family: "Kanit-Regular";
	font-size: 1rem;
	font-weight: 600;
	color: ${Colors.neutral_grey_k_5};
	cursor: pointer;
`;

export const ExerciseOptions = styled.div<{selected: string}>`
	display: flex;
	width: 50%;
	height: 100%;
	justify-content: center;
	align-items: center;
	border-top-right-radius: ${({selected})=> selected != 'exercicios' ? '20px' : '7px' };
	border-bottom-right-radius:${({selected})=> selected != 'exercicios' ? '20px' : '7px' };
	background-color: ${({selected})=> selected == 'exercicios' 
		? Colors?.primary_blue_k_main
		: Colors?.white
	};
	cursor: pointer;

	${TextExercise}{
		color: ${({selected})=> selected == 'exercicios' 
			? Colors?.white
			: Colors?.primary_blue_k_main
		}
	}
`;
export const ContainerICon = styled.div`
	display: flex;
	height: 100%;
`;
export const ContainerTexts = styled.div`
	position: relative;
`;

export const OptionsGenereals = styled.div`
	display: flex;
	flex-direction: column;
	width: 623px;
`;
export const ContainerLabel = styled.div`
	display: flex;
	align-items: center;
	margin-top: 60px;
	width: 100%;
`;
export const ContainerLanguageOptions = styled.div`
	display: flex;
	width: auto;
	margin-top: 10px;
	justify-content: space-between;
`;
export const LanguageOption = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-right: 32px;
	cursor: pointer;
`;	

export const Round = styled.div`
	display: flex;
	width: 1.3rem;
	height: 1.3rem;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	border: 3px solid;
	cursor: pointer;
`;
export const TextOption = styled.p`
	font-family: "NotoSans-Regular";
	font-size: 18px;
	color: ${Colors?.primary_blue_k_main} ;
	margin-top: 6px;
	margin-left: 6px;
	cursor: pointer;
`;
export const CheckLanguage = styled.div`
	width: 18px;
	height: 18px;
	border-radius: 100%;
	background-color: ${Colors?.secondary_green_k_main};
	cursor: pointer;
`;
export const ContainerToogle = styled.div`
	position: absolute;
	right: 0px;
	top: 10px;
`;

export const ContainerExerciseOption = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 500px;
	padding-bottom: 60px;
	overflow-y: none;
	position: relative;

	&::-webkit-scrollbar {
        width: 5px; 
    }
	&::-webkit-scrollbar-thumb {
        background-color: ${Colors?.neutral_grey_k_20}; 
        border-radius: 10px; 
    }
	&::-webkit-scrollbar-thumb:hover {
		background-color: ${Colors?.neutral_grey_k_40};
    }
	
`;
export const ContainerIConExercise = styled.div`
	display: flex;
	height: 100%;
	width: 5%;
`;

export const OptionExercise = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-start;
	justify-content: space-between;
	margin-top: 40px;
`;
export const ContainerLabels = styled.div`
	display: flex;
	width: 623px;
	height: 100%;
	flex-direction: column;

`;
export const ContainerToogles = styled.div`
	display: flex;
	width: 10%;
	margin-top: 5px;
	margin-right:5px;
	justify-content: flex-end;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InputText = styled.p`
	font-family: "NotoSans-Regular" ;
	font-size: 18px;
	font-weight: 400;
	margin-top: 14px;
	margin-left: 5%;
	color: ${Colors?.primary_blue_k_main +50};
`;

export const StyledInput = styled.input`
  font-size: 20px;
  color: ${Colors?.primary_blue_k_main +80};
  width: 85%;
  border: none;
  border-bottom: 1px solid ${Colors?.primary_blue_k_main +80};
  padding: 5px 0;
  outline: none;
  margin-left: 5%;

  &::placeholder {
	color: ${Colors?.primary_blue_k_main +80};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    border-bottom: 1px solid ${Colors?.primary_blue_k_main +80};
  }
  &:disabled {
    background-color: transparent;
  }
`;

