import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 2rem;
	padding-bottom: 5vh;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	border-radius: 1.25rem;

	overflow: hidden;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const ButtonLogoutInadimplente = styled.button`
	background-color: ${Colors.primary_blue_k_main};
	margin-top: 5rem;
	width: 10rem;
	height: 3rem;
	border-width: 0;
	border-radius: 8px;
	font-weight: 600;
	color: #ffff;
`
export const BodyEdite = styled.div`
	margin-top: 2rem;
	width: 100%;
	height: 100%;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background:transparent;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.number {
		width: 4rem;
		display: flex;
		justify-content: flex-end;

		font-weight: 400;
	}

	.capacidade {
		color: #b3b3b3;
	}

	.left {
	}

	.item {
		cursor: pointer;

		border: 1px solid #e0e0e0;
		border-radius: 1rem;

		padding: 1.5rem;

		margin-bottom: 2rem;

		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			margin-left: 1.3rem;

			font-size: 1rem;
			font-weight: 500;

			color: #5c5b5b;
		}

		svg {
			margin-left: 1rem;
		}
	}

	.info {
		font-weight: 500;
		font-size: 1.25rem;
		color: ${Colors.tertirary_red_1_dark};
		margin-top: 3rem;
	}

	.botaoSelecao {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: space-between;
		height: 100%;

		.data {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			small {
				font-weight: 400;
				font-size: 0.8rem;
				color: #b3b3b3;
			}

			p {
				font-weight: 500;
				font-size: 1.25rem;
				color: #8d8d8d;
			}
		}
	}

	.Adicionar {
		cursor: pointer;

		display: flex;
		align-items: center;

		margin-left: 2.5rem;
		margin-top: 2rem;

		p {
			margin-left: 0.5rem;
			color: ${Colors.primary_blue_k_main};
		}
	}

	.semFatura {
		width: 100%;
		height: 100%;
		margin-top: -3rem;

		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.FundoRedondo {
			width: 10.063rem;
			height: 10.063rem;

			background: #ebebeb;

			border-radius: 50%;

			position: relative;

			svg {
				position: absolute;
				left: 50%;
				top: 45%;
				transform: translate(-50%, -50%);
			}
		}

		.informacao {
			width: 100%;
			font-family: 'NotoSans-SemiBold';
			font-size: 28px;
			font-weight: 600;
			color: ${Colors.primary_blue_k_main};
		}
	}
`;

export const Header = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`;
export const ContainerGoBack = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-top: 20px;
`;
export const CardInvoice = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	margin-top: 30px;
`;
export const ContainerIConValue = styled.div`
	display: flex;
	width: 65%;
	align-items: center;
	flex-direction: row;
`;
export const Content = styled.div`
	display: flex;
	width: 40%;
	cursor: pointer;
	flex-direction: column;
`;
export const ContainerValueInvoice = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
`;