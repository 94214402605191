import moment from "moment";
import React, { memo, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import {
	PacientesProps,
	UserProps,
} from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { ExerciciosActions } from "../../../store/ducks/exer";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { getPermission } from "../../../utils/funcs";
import { TypesErrorRequest, TypesPermission } from "../../../utils/types";
import { CardUser, ContainerButtons, ContainerDeleteUsers, ContainerGoBack, Conteiner, Content, ContentName, ContentTag, ContentTrash, ExecutionsOptions, ExerciseOptions, Header, Overlay, RestoreAll, SelectAll, Tag, TextExecutions, TextExercise, TextUser, TrashOptions, UserOptions } from "./styles";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { AmplitudeLog } from "../../../utils/amplitude";
import Loading from "../../DashPaciente/Loading";
import {ReactComponent as ArrowBack} from '../../../assets/kinologyIcons/svg/arrow_back.svg';
import {ReactComponent as Patient} from '../../../assets/kinologyIcons/svg/patient.svg';
import {ReactComponent as Trash} from '../../../assets/kinologyIcons/svg/trash.svg';
import {ReactComponent as Restore} from '../../../assets/kinologyIcons/svg/restore.svg';
import { Colors } from "../../../styles/colors";
import Text from '../../../components/Texts/Text';

enum excluidos {
	account = "conta",
	exercise = "exercicio",
	exercises = "exercicios",
	accounts = "contas",
}

const GerenciadorExcluidos: React.FC = () => {
	const dispatch = useDispatch();
	const containerRef = useRef<any>(null);
 	const [buttonPosition, setButtonPosition] = useState({ right: 20, top: 20 });
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("excluidos"));
	}, []);
	const selected_patients = useSelector(
		(state: any) => state.pac_aval.selected_patients
	);
	const lista_excluidos = useSelector(
		(state: any) => state.app.lista_excluidos
	);

	const carregando_execucoes = useSelector(
		(state: any) => state.dashboard.execucoes_loading
	);

	const exerciciosExcluidos = useSelector(
		(state: any) => state.exercicios.exerciciosExcluidos
	);
	const exerciciosDeletados = useSelector(
		(state: any) => state.exercicios.exerciciosDeletados
	);

	const execucoes = useSelector((state: any) => state.dashboard.execucoes);
	const openMenu = useSelector((state: any)=> state.app.openMenu)
	const execucoesExcluidas = execucoes.filter(
		(e: any) => e.EXECUCAO_EXCLUIDA == true
	);

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);

	const listaExcluidos: number[] = lista_excluidos;	

	const HandleClassUsuario = (val: PacientesProps) => (event: any) => {
		
		dispatch(PacAvalActions.set_add_selected_patient(val));
		

		if (listaExcluidos.indexOf(val.ID_USUARIO) === -1) {
			listaExcluidos.push(val.ID_USUARIO);
		} else if (listaExcluidos.indexOf(val.ID_USUARIO) >= 0) {
			listaExcluidos.splice(listaExcluidos.indexOf(val.ID_USUARIO), 1);
		}
		dispatch(AppActions.set_lista_excluidos(listaExcluidos));
	};
	
	const [exerciciosrecuperacao, setExerciciosrecuperacao] = useState(exerciciosExcluidos);

	const HandleClassExcluidos = (val: any) => (event: any) => {

		dispatch(ExerciciosActions.set_add_selected_exercices(val));

		if (exerciciosrecuperacao.indexOf(val) === -1) {
			setExerciciosrecuperacao([...exerciciosrecuperacao, val]);
		} else if (exerciciosrecuperacao.indexOf(val) >= 0) {
			const newExer = exerciciosrecuperacao.filter((e: any) => e !== val);
			setExerciciosrecuperacao(newExer);
		}
		dispatch(ExerciciosActions.set_exercicios_excluidos(exerciciosrecuperacao));
	};

	const execucoesRecuperar = useSelector(
		(state: any) => state.exercicios.execucoesRecuperar
	);


	const execucoesExc: any = execucoesRecuperar;
	

	const HandleClassExecucoes = (val: any) => (event: any) => {

		dispatch(ExerciciosActions.set_execucoes_recuperar(val));

		if (execucoesExc.indexOf(val) === -1) {
			execucoesExc.push(val);
		} else if (execucoesExc.indexOf(val) >= 0) {
			execucoesExc.splice(execucoesExc.indexOf(val), 1);
		}
		dispatch(ExerciciosActions.set_execucoes_excluidas(execucoesExc));
	};

	const { register, setValue, reset } = useForm();

	function isPresentInListSelecteds(paciente: PacientesProps) {
		const is_present = selected_patients.find(
			(p: PacientesProps) => p.ID_USUARIO === paciente.ID_USUARIO
		);
		if (is_present) {
			return true;
		}
		return false;
	}

	const handlePaciente = () => {
		dispatch(AppActions.set_open_paciente(true));
	};

	const is_list = useSelector(
		(state: any) => state.dashboard.pacientes_loading
	);


	const [selected, setSelected] = useState<string[]>(["pacientes"]);

	const handleActiveClick = (active: string) => {
		const alreadySelected = selected.findIndex((item) => item === active);

		if (alreadySelected < 0) {
			setSelected([active]);
		}
		if (active == "execucoes") {
			dispatch(DashBoardActions.execucoes_request(true));
		}
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const type_toast = useSelector((state: any) => state.app.type_toast);

	useEffect(() => {
		selected_patients.map((pac: PacientesProps) => {
			setValue(
				`${pac.ID_USUARIO}`,
				lista_excluidos.indexOf(pac.ID_USUARIO) >= 0 ? true : false
			);
		});
	}, [lista_excluidos]);

	const handleModalRecuperarConta = (item: any) => () => {
		if (item?.mode === excluidos.account) {
			AmplitudeLog("recuperar_paci_avaliador");
		}
		if (item?.mode === excluidos.exercise) {
			AmplitudeLog("recuperar_exercicio");
		}
		if (item?.mode === excluidos.accounts) {
			AmplitudeLog("recuperar_pacientes_selecionados");
		}
		if (item?.mode === excluidos.exercises) {
			AmplitudeLog("recuperar_exercicios_selecionados");
		}
		if (
			getPermission(config_servicos, TypesPermission.SERVICO_RECYCLE, dispatch)
		) {
			dispatch(AppActions.set_modal_recuperar(item));
			dispatch(ExerciciosActions.set_exercicios_excluidos([]));
		}
	};

	const etis = useSelector((state: any) => state.dashboard.etiquetas);
	const lista_filtro = useSelector((state: any) => state.app.lista_filtro);

	const calcular_diferenca_data = (data: any) => {
		const now = moment(new Date()); 
		const past = moment(data); 
		const duration = moment.duration(now.diff(past));

		const days = duration.asDays();

		return days;
	};

	const desabilitadosSessenta = pacientes.filter(
		(row: any) =>
			parseInt(calcular_diferenca_data(row.USUARIO_ATUALIZADO).toFixed(0)) <= 60
	);
	


	const exerciciosDeletadosSessenta = exerciciosDeletados.filter(
		(row: any) =>
			parseInt(calcular_diferenca_data(row.EXERCICIO_ATUALIZADO).toFixed(0)) <=
			60
	);

	const execucoesExcluidasSessenta = execucoesExcluidas.filter(
		(row: any) =>
			parseInt(calcular_diferenca_data(row.EXECUCAO_ATUALIZADA).toFixed(0)) <=
			60
	);

	const AllIdsUsers: number[] = []
	desabilitadosSessenta.map((item: any)=> AllIdsUsers.push(item.ID_USUARIO))

	const AllIdsExercisesDeleted: number[] = []
	exerciciosDeletadosSessenta.map((item:Exercicios2Props)=> AllIdsExercisesDeleted.push(item?.ID_EXERCICIO))
	
	const AllIdsExecutions: number[] = []
	execucoesExcluidasSessenta.map((item:any)=> AllIdsExecutions.push(item.ID_EXECUCAO))
	

	useEffect(() => {
		const filtros_paginacao = {
			inicio: 0,
			fim: 10000,
			filtros: [
				{ tipo: "USUARIO_EXCLUIDO", valor: "S" },
				{ tipo: "USUARIO_NIVEL", valor: [1, 2, 3] },
			],
		};

		dispatch(DashBoardActions.set_filtros_pacintes_request(filtros_paginacao));
		dispatch(DashBoardActions.paciente_request());
	}, []);

	useEffect(() => {
		const updateButtonPosition = () => {
		  if (containerRef.current) {
			const rect = containerRef.current.getBoundingClientRect();
			setButtonPosition({
			  right: window.innerWidth - rect.right,
			  top: window.innerHeight - rect.top 
			});
		  }
		};
	
		window.addEventListener('resize', updateButtonPosition);
		window.addEventListener('scroll', updateButtonPosition);
		updateButtonPosition();
	
		return () => {
		  window.removeEventListener('resize', updateButtonPosition);
		  window.removeEventListener('scroll', updateButtonPosition);
		};
	}, []);


	return (
		<Conteiner ref={containerRef}>
			{openMenu && <Overlay isVisible={openMenu} />}
			{is_open_toast && type_toast == TypesErrorRequest.SUCCESS ? (
				<ToastAlert />
			) : (
				""
			)}
			<ContentTrash>

				<Header>
					<ContainerGoBack
						onClick={()=> {
							dispatch(DashBoardActions.set_current_page("pacientes"))
							dispatch(AppActions.set_option_menu(false, current_language?.menu_lateral_inicio))
						}}
					>
						<ArrowBack />
						<Text 
							title={current_language.menu_lateral_inicio}
							fontFamily="NotoSans-Regular"
							fontSize={1}
							fontWeight={400}
							textColor={Colors?.primary_blue_k_main}
							marginLeft={6}
							cursor="pointer"
						/>
					</ContainerGoBack>
				</Header>
				<Text 
					title={current_language?.menu_lateral_lixeira}
					fontFamily="NotoSans-Bold"
					fontSize={1.8}
					fontWeight={700}
					textColor={Colors?.primary_blue_k_main}
					marginTop={15}
					textAlign="left"
				/>
				<TrashOptions>
					<UserOptions
						selected={selected[0]}
						onClick={() => {
							handleActiveClick("pacientes");
							setExerciciosrecuperacao([]);
							AmplitudeLog("pacientes_avaliadores_excluidos");
						}}
					>
						<TextUser>
							{current_language?.usuario}
						</TextUser>
					</UserOptions>
					<ExerciseOptions
						selected={selected[0]}
						onClick={() => {
							handleActiveClick("exercicios");
							reset();
							dispatch(AppActions.set_lista_excluidos([]));
							dispatch(PacAvalActions.set_add_selected_patient([]));
							AmplitudeLog("exercicios_excluidos");
						}}
					>
						<TextExercise>
							{current_language?.exercicios}{" "}
						</TextExercise>
					</ExerciseOptions>
					<ExecutionsOptions
						selected={selected[0]}
						onClick={() => {
							handleActiveClick("execucoes");
							AmplitudeLog("exercicios_excluidos");
						}}
					>
						<TextExecutions>
							{current_language?.execucoes}
						</TextExecutions>
					</ExecutionsOptions>
				</TrashOptions>

				

				{selected.includes("pacientes") &&
					<ContainerDeleteUsers >
						{desabilitadosSessenta.length > 0 
							?
								<>
									<SelectAll 
										onClick={()=> 
											listaExcluidos.length === 0 
											? dispatch(AppActions.set_lista_excluidos(AllIdsUsers))
											:[ dispatch(AppActions.set_lista_excluidos([])), dispatch(PacAvalActions.reset_patient())]
										}
										listaExcluidos={ listaExcluidos.length}
									>
										{
										listaExcluidos.length === 0 
											?`${current_language?.selecionar_todos } (${desabilitadosSessenta.length})` 
											:`${current_language?.cancelar_selecao } (${listaExcluidos.length})` 
										}
									</SelectAll>
									{ desabilitadosSessenta?.map((row: PacientesProps, index: number) => (
											<CardUser 
												key={index}
												bgColor={listaExcluidos.includes(row?.ID_USUARIO)}	
											>
												<Content>
													<ContentName>
														<Patient  color={Colors.primary_blue_k_main}/>
														<Text 
															title={row?.USUARIO_NOME}
															fontFamily="NotoSans-Bold"
															textColor={Colors?.primary_blue_k_main}
															fontSize={1}
															textAlign="left"
															fontWeight={700}
															marginLeft={10}
														/>
													</ContentName>
													<ContentTag>
														
														<Tag usuarioNivel={row.USUARIO_NIVEL}>
															{row.USUARIO_NIVEL == 2 &&
																current_language?.pag_inicial_avaliador ||
																current_language?.dash_paciente
															}
														</Tag>
														<Trash  color={Colors?.primary_blue_k_main+40} width={24} height={24}/>
														<Text 
															title={current_language?.excluido_ha + ' ' + calcular_diferenca_data(
																	row.USUARIO_ATUALIZADO
																).toFixed(0) + ' ' + current_language?.menu_lateral_dias 
															}
															fontFamily="NotoSans-Regular"
															textColor={Colors?.primary_blue_k_main+40}
															fontSize={1}
															textAlign="left"
															fontWeight={400}
															marginLeft={10}
														/>
														
													</ContentTag>
												</Content>
												<ContainerButtons>
													<Restore 
														color={Colors?.primary_blue_k_main}
														style={{cursor: 'pointer'}}
														onClick={handleModalRecuperarConta({
															id: row.ID_USUARIO,
															mode: "conta",
														})}
													/>
													<div className="custom-checkbox">
														<input
															id={row.ID_USUARIO.toString()}
															checked={ listaExcluidos.includes(row?.ID_USUARIO)	}
															type="checkbox"
															onChange={HandleClassUsuario(row)}
														/>
														<label
															htmlFor={row.ID_USUARIO.toString()}
														></label>
													</div>
												</ContainerButtons>
											</CardUser>
										))
									}
								</>
							:
								<p className="Empty">{current_language?.nenhum_paciente_excluido}</p>
					
						}
						{
							listaExcluidos.length > 1 &&
							<RestoreAll
								style={{ right: `${buttonPosition.right}px`, top: `${buttonPosition.top}px` }}
								onClick={handleModalRecuperarConta({ id: "id", mode: "contas" })}
							>
								<Restore 
									color={Colors?.white}
									style={{cursor: 'pointer'}}
									
								/>
								{current_language?.recuperar_todas_selecionadas}
							</RestoreAll>


						}
					</ContainerDeleteUsers>
				}
				{selected.includes("exercicios") &&
					<ContainerDeleteUsers>
						{exerciciosDeletadosSessenta.length > 0 
							?
								<>
									<SelectAll 
										onClick={()=> {
											exerciciosrecuperacao.length === 0 
											? setExerciciosrecuperacao(AllIdsExercisesDeleted)
											: setExerciciosrecuperacao([])
										}}
										listaExcluidos={ exerciciosrecuperacao.length}
									>
										{
										exerciciosrecuperacao.length === 0 
											?`${current_language?.selecionar_todos } (${exerciciosDeletadosSessenta.length})` 
											:`${current_language?.cancelar_selecao } (${exerciciosrecuperacao.length})` 
										}
									</SelectAll>
									{ exerciciosDeletadosSessenta.map((row: Exercicios2Props, index: number) => (
											<CardUser 
												key={index}
												bgColor={exerciciosrecuperacao.includes(row?.ID_EXERCICIO)}	
											>
												<Content>
													<ContentName>
														<Patient  color={Colors.primary_blue_k_main}/>
														<Text 
															title={
																current_language?.idioma === "Português"
																? row.EXERCICIO_TITULO_PT
																: current_language?.idioma === "English"
																	? row.EXERCICIO_TITULO_EN
																	: row.EXERCICIO_TITULO_ES
															}
															fontFamily="NotoSans-Bold"
															textColor={Colors?.primary_blue_k_main}
															fontSize={1}
															textAlign="left"
															fontWeight={700}
															marginLeft={10}
														/>
													</ContentName>
													<ContentTag>
														<Trash  color={Colors?.primary_blue_k_main+40} width={24} height={24}/>
														<Text 
															title={calcular_diferenca_data(
																row.EXERCICIO_ATUALIZADO
															).toFixed(0) + ' ' + current_language?.menu_lateral_dias 
															}
															fontFamily="NotoSans-Regular"
															textColor={Colors?.primary_blue_k_main+40}
															fontSize={1}
															textAlign="left"
															fontWeight={400}
															marginLeft={10}
														/>
														
													</ContentTag>
												</Content>
												<ContainerButtons>
													<Restore 
														color={Colors?.primary_blue_k_main}
														style={{cursor: 'pointer'}}
														onClick={handleModalRecuperarConta({
															id: row.ID_EXERCICIO,
															mode: "exercicio",
														})}
													/>
													<div className="custom-checkbox">
														<input
															id={`${row.ID_EXERCICIO}`}
															checked={exerciciosrecuperacao.includes(row?.ID_EXERCICIO)}
															type="checkbox"
															onChange={HandleClassExcluidos(row.ID_EXERCICIO)}
														/>
														<label htmlFor={`${row.ID_EXERCICIO}`}></label>
													</div>
												</ContainerButtons>
											</CardUser>
										))
									}
								</>
							:
								<p className="Empty">{current_language?.nenhum_exercicio_excluido}</p>
					
						}
						{
							exerciciosrecuperacao.length > 1 &&
							<RestoreAll
								style={{ right: `${buttonPosition.right}px`, top: `${buttonPosition.top}px` }}
								onClick={handleModalRecuperarConta({
									id: "id",
									mode: "exercicios",
								})}
							>
								<Restore 
									color={Colors?.white}
									style={{cursor: 'pointer'}}
									
								/>
								{current_language?.recuperar_todas_selecionadas}
							</RestoreAll>
						}
					</ContainerDeleteUsers>
				}
				
				{selected.includes("execucoes") &&
					<ContainerDeleteUsers>
						{execucoesExcluidasSessenta.length > 0 
							?
								<>
									<SelectAll 
										onClick={()=> {
											execucoesRecuperar.length === 0 
											? dispatch(ExerciciosActions.set_execucoes_recuperar(AllIdsExecutions))
											: dispatch(ExerciciosActions.set_reset_exercices())
										}}
										listaExcluidos={ execucoesRecuperar.length}
									>
										{
										execucoesRecuperar.length === 0 
											?`${current_language?.selecionar_todos } (${execucoesExcluidasSessenta.length})` 
											:`${current_language?.cancelar_selecao } (${execucoesRecuperar.length})` 
										}
									</SelectAll>
									{ execucoesExcluidasSessenta.map((row: any, index: number) => (
											<CardUser 
												key={index}
												bgColor={execucoesRecuperar.includes(row?.ID_EXECUCAO)}
											>
												<Content>
													<ContentName>
														<Patient  color={Colors.primary_blue_k_main}/>
														<Text 
															title={
																current_language?.idioma === "Português"
																	? row?.EXERCICIO_TITULO_PT
																	: current_language?.idioma === "English"
																		? row?.EXERCICIO_TITULO_PT
																		: row?.EXERCICIO_TITULO_PT
															}
															fontFamily="NotoSans-Bold"
															textColor={Colors?.primary_blue_k_main}
															fontSize={1}
															textAlign="left"
															fontWeight={700}
															marginLeft={10}
														/>
													</ContentName>
													<ContentTag>
														<Trash  color={Colors?.primary_blue_k_main+40} width={24} height={24}/>
														<Text 
															title={calcular_diferenca_data(
																row.EXECUCAO_ATUALIZADA
															).toFixed(0) + ' ' + current_language?.menu_lateral_dias 
															}
															fontFamily="NotoSans-Regular"
															textColor={Colors?.primary_blue_k_main+40}
															fontSize={0.87}
															textAlign="left"
															fontWeight={400}
															marginLeft={10}
														/>
														
													</ContentTag>
												</Content>
												<ContainerButtons>
													<Restore 
														color={Colors?.primary_blue_k_main}
														style={{cursor: 'pointer'}}
														onClick={handleModalRecuperarConta({
															id: row.ID_EXECUCAO,
															mode: "exec",
														})}
													/>
													<div className="custom-checkbox">
														<input
															id={`${row.ID_EXECUCAO}`}
															checked={execucoesRecuperar.includes(row?.ID_EXECUCAO)}
															type="checkbox"
															onChange={HandleClassExecucoes(row.ID_EXECUCAO)}
														/>
														<label htmlFor={`${row.ID_EXECUCAO}`}></label>
													</div>
												</ContainerButtons>
											</CardUser>
										))
									}
								</>
							: !carregando_execucoes 
								? 	<p className="Empty">{current_language?.nenhuma_execucao_excluida}</p>
								: carregando_execucoes && selected.includes("execucoes") && <Loading />
						}
						{
							execucoesRecuperar.length > 1 &&
							<RestoreAll
								style={{ right: `${buttonPosition.right}px`, top: `${buttonPosition.top}px` }}
								onClick={handleModalRecuperarConta({
									id: "id",
									mode: "execs",
								})}
							>
								<Restore 
									color={Colors?.white}
									style={{cursor: 'pointer'}}
									
								/>
								{current_language?.recuperar_todas_selecionadas}
							</RestoreAll>
						}
					</ContainerDeleteUsers>
				}
			</ContentTrash>
		</Conteiner>
	);
};

export default memo(GerenciadorExcluidos);
