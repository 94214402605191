import React, { memo, useState } from "react";
import { InputText, Question } from "./styles";
import DOMParserReact from 'dom-parser-react';

interface questionProps {
	lista: any,
	texts: any,
	funcao: any,
}

const TermografiaAplicadaCampos: React.FC<questionProps> = ({ lista, texts, funcao }) => {
	const [valueLOCAL, setValueLOCAL]: any = useState(!!texts.LOCAL ? texts.LOCAL : "")
	const [valueTEMPERATURA, setValueTEMPERATURA]: any = useState(!!texts.TEMPERATURA ? texts.TEMPERATURA : "")
	const [valueMEDIDA_CALOR, setValueMEDIDA_CALOR]: any = useState(!!texts.MEDIDA_CALOR ? texts.MEDIDA_CALOR : "")

	const set_obj = () => {
		if(
			valueLOCAL !== "" &&
			valueLOCAL !== null &&
			valueLOCAL !== undefined &&
			valueTEMPERATURA !== "" &&
			valueTEMPERATURA !== null &&
			valueTEMPERATURA !== undefined &&
			valueMEDIDA_CALOR !== "" &&
			valueMEDIDA_CALOR !== null &&
			valueMEDIDA_CALOR !== undefined 
		) {
			const obj = {
				"LOCAL": valueLOCAL,
				"TEMPERATURA": valueTEMPERATURA,
				"MEDIDA_CALOR": valueMEDIDA_CALOR
			}
			funcao(obj)
		}
	}

	return (
		<div style={{ marginBottom: '1rem' }}>
			<Question><DOMParserReact source={lista[0]} /></Question>
			<InputText
				onChange={(e) => setValueLOCAL(e.target.value)}
				value={valueLOCAL}
				onBlur={() => set_obj()}
				type="text"
				maxLength={45}
			/>
			<div style={{ display: 'flex' }}>
				<div style={{ marginRight: '1rem' }}>
					<Question><DOMParserReact source={lista[1]} /></Question>
					<InputText
						onChange={(e) => setValueTEMPERATURA(e.target.value)}
						value={valueTEMPERATURA}
						onBlur={() => set_obj()}
						type="number"
						maxLength={45}
					/>
				</div>
				<div>
					<Question><DOMParserReact source={lista[2]} /></Question>
					<InputText
						onChange={(e) => setValueMEDIDA_CALOR(e.target.value)}
						value={valueMEDIDA_CALOR}
						onBlur={() => set_obj()}
						type="text"
						maxLength={45}
					/>
				</div>
			</div>
		</div>
	)
};

export default memo(TermografiaAplicadaCampos);