import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonClose.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { AppActions } from "../../../store/ducks/app";
import { Container, ContainerOptions, Title, Option } from "./styles";
import {ReactComponent as Close} from '../../../assets/kinologyIcons/svg/close.svg';
import {ReactComponent as Trash} from '../../../assets/kinologyIcons/svg/trash.svg';
import {ReactComponent as AddPhoto} from '../../../assets/kinologyIcons/svg/add_photo_alternate.svg';
import { Colors } from "../../../styles/colors";
import Text from '../../../components/Texts/Text';
import { AssinaturaActions } from "../../../store/ducks/assinatura";
import { TypesErrorModal } from "../../../utils/types";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalFotoPerfilAssinatura: React.FC = () => {

	const dispatch = useDispatch();
	const inputRef = useRef<HTMLInputElement>(null);
	const classes = useStyles();

    const [ click, setClick] = useState(false)

	const current_language: TextsProps = useSelector((state: any) => state.configs.current_language);
	const is_open_foto_perfil = useSelector(	(state: any) => state.app.open_modal_edicao_foto_perfil_assinatura);
	const title_modal = useSelector((state: any) => state.app.titulo_modal_edicao_foto_perfil_assinatura);

    const getBase64 = (event: any) => {
		const file = event.target.files[0];

		if(title_modal == current_language?.editar_assinatura){
			if (file.size < 10485760) {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = function () {
					dispatch(AssinaturaActions.set_assinatura(reader.result));
					dispatch(AppActions.set_modal_foto_edicao_perfil_assinatura(false,''))
				};
				reader.onerror = function (error) {
					//console.log("Error: ", error);
				};
			} else {
				dispatch(
					AppActions.set_modal_visible({
						type: TypesErrorModal.FAIL,
						message: current_language?.maior_que_10mb,
					})
				);
			}
		}else{
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = function () {
				dispatch(AppActions.set_foto_perfil(reader.result));
				dispatch(AddPacAvalActions.set_avatar_paci_64(reader.result));
				dispatch(AddPacAvalActions.set_avatar_eval_64(reader.result));
			};
			dispatch(AppActions.set_modal_foto_edicao_perfil_assinatura(false, ''));
			reader.onerror = function (error) {
				//console.log("Error: ", error);
			};
		}
    };

	const removerFoto = (event: any) => {
		if(title_modal == current_language?.editar_assinatura){
			dispatch(AssinaturaActions.set_assinatura(''));
			dispatch(AppActions.set_modal_foto_edicao_perfil_assinatura(false,''))
		}else{
			event.preventDefault();
			event.target.value = null;
			dispatch(AppActions.set_foto_perfil(""));
			dispatch(AddPacAvalActions.set_avatar_paci_64(" "));
			dispatch(AddPacAvalActions.set_avatar_eval_64(" "));
			dispatch(AppActions.set_modal_foto_perfil(false));
		}
	};

    useEffect(() => {
        if (click && inputRef.current) {
          inputRef.current.click();
          setClick(false); 
        }
    }, [click]);
	

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_foto_perfil}
				closeAfterTransition
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_foto_perfil}>
					<Container>
                        <Close 
                            onClick={()=> {dispatch(AppActions.set_modal_foto_edicao_perfil_assinatura(false,'')), setClick(false)}} 
                            style={{
                                position: 'absolute', 
                                right: 15, 
                                top:25,
                                cursor: 'pointer'
                            }}
                        />
                        <Title>{title_modal}</Title>
                        <ContainerOptions>
                            <Option onClick={removerFoto}>
                                <Trash  style={{cursor: 'pointer'}}  color={Colors?.primary_blue_k_main} />
                                <Text 
                                    title={current_language?.menu_remover_imagem}
                                    fontFamily="Kanit-Medium"
                                    fontSize={1.12}
                                    fontWeight={500}
                                    textColor={Colors?.primary_blue_k_main}
                                    textAlign="center"
                                    cursor="pointer"
                                />
                            </Option>
                            <Option onClick={()=>{ setClick(true)}}>
                                
                                <AddPhoto width={45} height={45} style={{cursor: 'pointer'}}  color={Colors?.primary_blue_k_main} />
                                {
                                    <input
                                        id="img"
                                        ref={inputRef}
                                        type="file" 
                                        hidden
                                        onChange={(event:any)=>getBase64(event)}
                                        accept="image/*"
                                    />
                                }
                                <Text 
                                    title={current_language?.add_file}
                                    fontFamily="Kanit-Medium"
                                    fontSize={1.12}
                                    fontWeight={500}
                                    textColor={Colors?.primary_blue_k_main}
                                    cursor="pointer"
                                />
                            </Option>
                        </ContainerOptions>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalFotoPerfilAssinatura;
