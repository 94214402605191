import styled from "styled-components";

interface ContainerProps {
	questionAsync: boolean | undefined;
}

export const Container = styled.div<ContainerProps>`
	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 4px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 4px;
	}

	width: 100%;

	display: flex;
	justify-content: space-around;
	${({ questionAsync }) => questionAsync && `flex-direction: column;`}

	align-items: center;
`;

export const Corpos = styled.div`
	max-width: 25rem;
	width: 50%;

	display: flex;
	justify-content: space-around;
	align-items: center;
`;

export const CorpoFrente = styled.div`
	position: relative;
`;

export const CorpoCostas = styled.div`
	position: relative;
`;

export const Marcacoes = styled.div<ContainerProps>`
	max-width: 25rem;
	${({ questionAsync }) => (questionAsync ? `width: 100%;` : `width: 50%;`)}

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 4px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 4px;
	}
`;

export const Membro = styled.div`
	width: 100%;
	padding-right: 1rem;
	border-bottom: 1px solid #c2c2c2;
`;

export const MembroHead = styled.div`
	width: 100%;
	height: 3.313rem;
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding-right: 12px;

	transition: all 0.2s linear;

	p {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 400;
		font-size: 1.375rem;
		line-height: 120%;

		color: #404040;
	}

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 0;
		background-color: transparent;
		transition: all 0.2s linear;
	}

	.rotacao90 {
		transform: rotate(90deg);
		transition: all 0.2s linear;
	}
	.rotacao0 {
		transform: rotate(0deg);
		transition: all 0.2s linear;
	}
`;

export const MembroFooter = styled.div`
	overflow: hidden;

	transition: all 0.2s linear;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
