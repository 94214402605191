import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import Editar from "../../../assets/icons/editar.svg";
import Excluir from "../../../assets/icons/excluir.svg";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { TagActions } from "../../../store/ducks/tags";
import { TypesErrorRequest } from "../../../utils/types";
import {
	AddTags,
	Container,
	ContainerArrowIcon,
	ContainerGoBack,
	ContainerTags,
	ContainerTitle,
	Conteiner,
	Content,
	ContentEtiquetas,
	Header,
	Tag,
} from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import { ReactComponent as ArrowBack } from "../../../assets/kinologyIcons/svg/arrow_back.svg";
import { ReactComponent as AddTag } from "../../../assets/kinologyIcons/svg/AddTag.svg";
import Text from "../../../components/Texts/Text";
import { Colors } from "../../../styles/colors";
import history from "../../../routes/history";
import { ContainerDash, Icon, Return, Scroll } from "../../../styles/global";
import { H3Desk, P2Desk, P3Desk } from "../../../styles/styles_text";
import ReturnIcon from "../../../assets/icons/back_page.svg";

interface TagsProps {
	ID_ETIQUETA: number;
	ETIQUETA_NOME: string;
	ID_USUARIOS: [];
}

const Etiquetas: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const tags = useSelector((state: any) => state.tags.selecteds_tags);

	const etiquetas: Array<TagsProps> = useSelector(
		(state: any) => state.dashboard.etiquetas
	);

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("etiquetas"));
	}, []);

	const [identificador, setIdentificador] = useState<number[]>([]);

	const handleActiveTag = (id: number) => () => {
		let newIdentificador: any = [];
		if (identificador?.includes(id)) {
			const filter = identificador.filter((e: any) => e !== id);
			newIdentificador = filter;
		} else {
			newIdentificador = [...identificador, id];
		}
		setIdentificador(newIdentificador);
		const etiq = etiquetas.filter((e: TagsProps) =>
			newIdentificador?.includes(e.ID_ETIQUETA)
		);
		dispatch(TagActions.set_tags(etiq));
	};

	const handleActiveTagAll = () => {
		const newIdentificador: any = [];
		if (identificador.length > 0) {
			setIdentificador([]);
			dispatch(TagActions.reset_select_tag());
		} else {
			etiquetas.map((e: TagsProps) => {
				if (newIdentificador.indexOf(e.ID_ETIQUETA) === -1) {
					newIdentificador.push(e.ID_ETIQUETA);
				} else if (newIdentificador.indexOf(e.ID_ETIQUETA) >= 0) {
					newIdentificador.splice(newIdentificador.indexOf(e.ID_ETIQUETA), 1);
				}
				setIdentificador(newIdentificador);
			});
			const etiq = etiquetas.filter((e: TagsProps) =>
				newIdentificador?.includes(e.ID_ETIQUETA)
			);
			dispatch(TagActions.set_tags(etiq));
		}
	};

	const handleOpenAdicionarEtiqueta = () => {
		let message = "";
		if (etiquetas?.length >= 10) {
			message = current_language?.limite_maximo_etiquetas;
			dispatch(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			dispatch(AppActions.set_modal_adicionar_etiqueta(true));
		}
	};

	const ExcluirMarcados = () => {
		AmplitudeLog("excluir_etiquetas_selecionadas");
		setIdentificador([]);
		dispatch(AppActions.set_modal_excluir_etiqueta(true));
	};

	const ExcluirUm = (e: any) => {
		AmplitudeLog("excluir_etiqueta");
		dispatch(TagActions.set_tags([e]));
		dispatch(AppActions.set_modal_excluir_etiqueta(true));
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const { register, setValue } = useForm();

	useEffect(() => {
		identificador.length === 0
			? etiquetas.map((row: any) => {
					setValue(
						`${row.ID_ETIQUETA}`,
						identificador.indexOf(row.ID_ETIQUETA) >= 0 ? true : false
					);
			  })
			: etiquetas.map((row: any) => {
					setValue(
						`${row.ID_ETIQUETA}`,
						identificador.indexOf(row.ID_ETIQUETA) >= 0 ? true : false
					);
			  });
	}, [identificador]);

	return (
		<ContainerDash>
			<Return
				onClick={() => {
					dispatch(DashBoardActions.set_current_page("pacientes"));
					dispatch(
						AppActions.set_option_menu(
							false,
							current_language?.menu_lateral_minha_conta
						)
					);
				}}
			>
				<Icon src={ReturnIcon} style={{ width: "1rem", height: "1rem" }} />
				<P3Desk>Minha conta</P3Desk>
			</Return>

			<ContainerTitle style={{ width: "60%", marginTop: "2rem" }}>
				<H3Desk>{current_language?.menu_lateral_etiquetas}</H3Desk>
				<AddTags
					onClick={() => {
						handleOpenAdicionarEtiqueta(), AmplitudeLog("adicionar_etiqueta");
					}}
				>
					<ContainerArrowIcon>
						<AddTag
							color={Colors?.primary_blue_k_main}
							className="AddTagIcon"
						/>
					</ContainerArrowIcon>
					<Text
						title={current_language?.tags_criar_nova_etiqueta}
						fontFamily="NotoSans-Regular"
						fontSize={1.25}
						fontWeight={500}
						textColor={Colors?.primary_blue_k_main}
						cursor="pointer"
						marginLeft={5}
					/>
				</AddTags>
			</ContainerTitle>
			<div style={{ width: "60%" }}>
				<Text
					title={current_language?.tag_selecionar}
					fontFamily="NotoSans-SemiBold"
					fontWeight={600}
					textColor={Colors?.primary_blue_k_main}
					textAlign="left"
					marginTop={60}
					marginBottom={30}
				/>
			</div>

			<Scroll style={{ width: "60%" }}>
				<ContainerTags>
					{etiquetas?.map((item, index) => (
						<Tag
							key={item?.ID_ETIQUETA}
							onClick={() => {
								dispatch(DashBoardActions.set_current_page("edit_tag"))
								dispatch(AppActions.set_info_page_editar_etiqueta(item));
								const filtros_paginacao = {
									inicio: 0,
									fim: 50,
									filtros: [
										{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
										{ tipo: "ID_USUARIO", valor: item.ID_USUARIOS },
									],
								};
								dispatch(AppActions.set_get_patient_in_etiqueta(true));
								dispatch(
									DashBoardActions.set_filtros_pacintes_request(
										filtros_paginacao
									)
								);
								dispatch(DashBoardActions.paciente_request());
								AmplitudeLog("editar_etiqueta");
							}}
						>
							<P2Desk>
								<strong>{item?.ETIQUETA_NOME}</strong>{" "}
							</P2Desk>
						</Tag>
					))}
				</ContainerTags>
			</Scroll>
		</ContainerDash>
	);
};

export default memo(Etiquetas);
