import styled from "styled-components";
import { Colors } from "../../styles/colors";



export const SuperiorBar = styled.div`
	display: flex;
	width: 100%;
	padding-top: 18px;
	padding-bottom: 18px;
	padding-left: 40px;
	padding-right: 40px;
	align-items: center;
	justify-content: space-between;
	border-bottom-color: ${Colors?.neutral_grey_k_20};
	border-bottom: 1px solid;
	height: 10vh;
`;

export const Image = styled.img``;