import styled from "styled-components";
import { Colors } from "../../../styles/colors";


export const Container = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	flex-direction: column;

`;
export const ContentEtiquetas = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
`;
export const Header = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`;
export const ContainerTitle = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
`;

export const ContainerArrowIcon = styled.div`
	display: flex;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	align-items: center;
	justify-content: center;
	background-color: transparent;
`;
export const AddTags = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover ${ContainerArrowIcon} {
		.AddTagIcon {
			color: ${Colors.white}; 
		}
		background-color: ${ Colors?.primary_blue_k_medium_1 };
	}
	&:active ${ContainerArrowIcon} {
		.AddTagIcon {
			color: ${Colors.white}; 
		}
		background-color: ${ Colors?.primary_blue_k_main };
	}
`; 

export const ContainerGoBack = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-top: 20px;
`;

export const ContainerTags = styled.div`
	display: flex;
	width: 100%;
	max-height: 100%;
	overflow-y: auto;
	flex-wrap: wrap;
	padding-bottom:40px;

	&::-webkit-scrollbar {
        width: 5px; 
    }
	&::-webkit-scrollbar-thumb {
        background-color: ${Colors?.neutral_grey_k_20}; 
        border-radius: 10px; 
    }
	&::-webkit-scrollbar-thumb:hover {
		background-color: ${Colors?.neutral_grey_k_40};
    }
`;

export const Tag = styled.div`
	display: flex;
	cursor: pointer;
	padding: 12px 18px;
	height: 54px;
	border-radius: 30px;
	justify-content: center;
	margin-right: 24px;
	margin-top: 24px;
	align-items: center;
	background-color: ${Colors?.secondary_green_k_light_2};
`;

//--------------------------------//
export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	width: 100%;
	height: 100%;

	padding: 2.75rem;

	border-radius: 1.25rem;
`;

export const Content = styled.div`
	width: 100%;
	height: 100%;

	.divTitle {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: baseline;

		.divGroupTitle {
			display: flex;
			align-items: center;
			flex-direction: row;
			margin-bottom: 4.62rem;

			.title {
				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 24px;
				line-height: 28px;
				color: #5c5b5b;
				margin-right: 15px;
			}

			.count {
				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 23px;
				text-align: right;
				color: #b3b3b3;
			}
		}

		.button {
			display: flex;
			align-items: center;
			border: none;
			background-color: transparent;
		}

		.ButtonText {
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 21px;
			color: ${Colors.secondary_green_k_medium_1};
			margin-left: 7px;
		}
	}

	.divCabecalho {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}

	.cabecalho {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		padding-bottom: 2.5rem;
		padding-right: 1rem;
	}

	.colunas {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 22.25rem;
		padding-right: 0.6rem;
	}

	.tabela {
		width: 100%;
		height: 60%;
		overflow-x: hidden;
		overflow-y: scroll;
		padding-right: 1rem;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.etiqueta {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 4.5rem;
		justify-content: space-between;
		border-bottom: 1px solid #e0e0e0;
	}

	.collumn {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 21rem;
		padding-left: 1.4rem;
	}

	.group {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.button {
		display: flex;
		align-items: center;
		background-color: transparent;
		border: none;
	}

	.text {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		color: #8c8e86;
	}

	.title {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #5c5b5b;
	}

	.selecionar {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		text-align: right;
		color: ${Colors.primary_blue_k_medium_1};
		cursor: pointer;
	}

	.custom-checkbox input,
	.custom-radio input {
		display: none;
		cursor: pointer;
	}

	.custom-checkbox input + label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 20%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		float: right;
		cursor: pointer;
	}

	.custom-checkbox input:checked + label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: ${Colors.secondary_green_k_medium_1};
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
	}

	.divBotao {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	.btnRecuperar {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 26.87rem;
		height: 3.6rem;
		background: ${Colors.primary_blue_k_main};
		border-radius: 8px;
		border: none;
		color: white;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 21px;
		margin-top: 2rem;
	}

	.svg {
		margin-right: 0.9rem;
	}

	.dropdown {
		position: relative;
		display: inline-block;
		margin-top: 2rem;
		cursor: pointer;
	}

	.dropdown-content {
		display: none;
		flex-direction: row;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 9.75rem;
		min-height: 7.6rem;
		box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
		padding-left: 1.6rem;
		padding-top: 1.6rem;
		padding-right: 1.6rem;
		z-index: 1;
		border-radius: 1.12rem;
		right: 0;
		top: 10px;
	}

	.dropdown:hover .dropdown-content {
		display: block;
	}

	.op {
		font-size: 1.25rem;
		width: 100%;
		margin-bottom: 1.4rem;
		font-weight: 500;
		color: #5c5b5b;
		background-color: transparent;
		border: none;
	}

	.op2 {
		font-size: 1.25rem;
		width: 100%;
		color: ${Colors.tertirary_red_1_dark};
		font-weight: 500;
		background-color: transparent;
		border: none;
	}

	.img {
		width: 1.18rem;
		height: 1.18rem;
		margin-right: 1rem;
	}
`;
