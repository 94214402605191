import styled from "styled-components";

export const Container = styled.div`
	background-color: #ffffff;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 50vh;
	width: 50vw;

	@media only screen and (min-width: 1280px) {
		height: 70vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1440px) {
		height: 60vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1680px) {
		height: 70vh;
		width: 45vw;
	}
	.close {
		width: 21px;

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		cursor: pointer;
	}

	.divText {
		width: 65%;
		margin-bottom: 2rem;
	}

	.text {
		color: #b3b3b3;
		font-weight: 500;
		font-family: Roboto;
		font-size: 26px;
		line-height: 30.47px;
		text-align: center;
	}

	.textSecundary {
		color: #b3b3b3;
		font-weight: 500;
		font-family: Roboto;
		font-size: 24px;
		line-height: 30.47px;
		text-align: center;
	}

	.item {
		display: flex;
		margin: 1rem 0;

		img {
			margin-right: 1rem;
		}
	}

	.group {
		margin-bottom: 1rem;
	}
`;
