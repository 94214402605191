import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { UserProps } from "../../interfaces/pacientes.interface";
import history from "../../routes/history";
import { TypesErrorRequest } from "../../utils/types";
import { AppActions } from "../ducks/app";
import { ConfigsActions } from '../ducks/configs';
import { FinancialActions, Types } from "../ducks/financial";
import * as Sentry from "@sentry/react";

function* addCard({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);

	async function apiCall() {
		try {
			const dataRequest = {
				...payload,
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}financeiro/financeiro_alterar_cartao.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		if (data.status == TypesErrorRequest.FAIL) {
			let message: any = "";

			if (data.code == 1) {
				message = current_language?.cartao_invalido;
			}
			if (data.code == 2) {
				message = current_language?.falha_guardar_cartao;
			}

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else if (data.status == TypesErrorRequest.SUCCESS) {
			let message: any = "";

			if (data.code == 0) {
				message = current_language?.cartao_armazenado;
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: message,
				})
			);

			history.push("/pagamentos");
		} else if (data.message) {
			if (data.message === "Bad Request") {
				let message: any = "";

				message = current_language?.falha_salvar_cartao;

				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
	} finally {
		yield put(FinancialActions.add_card_cancel());
	}
}

function* getFaturas({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const dataRequest = {
				SERVICO_IUGU: payload?.SERVICO_IUGU,
				SERVICO_SUPERLOGICA: payload?.SERVICO_SUPERLOGICA,
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}financeiro/financeiro_faturas_cartoes.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: financeiro_faturas_cartoes.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		if (data.status === TypesErrorRequest.SUCCESS) {
			yield put(
				FinancialActions.financial_request_sucess(
					data.dados_cartoes,
					data.dados_faturas
				)
			);
			yield put (FinancialActions.set_info_cartao_faltando(data?.cartao_faltando))
		}
	} catch (e) {
		yield put(FinancialActions.financial_request_failed());
	} finally {
		yield put(FinancialActions.financial_request_failed());
	}
}

function* getPlanosItens({ payload }: any): any {

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	yield put(FinancialActions.set_loading_upsell(true));
	async function apiCall() {
		try {
			const dat = {
				TOKEN: user_logado.token,
			};
			const requestOptionsPlano: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const resPlano = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_planos.php`,
				requestOptionsPlano
			);
			const response_plano: any = await resPlano.json();

			const requestOptionsItem: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const resItem = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_itens.php`,
				requestOptionsItem
			);
			const response_item: any = await resItem.json();
			return { response_plano, response_item }
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_planos.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);

		yield put(FinancialActions.set_plano_upsell(data.response_plano));
		yield put(FinancialActions.set_item_upsell(data.response_item));
	} catch (e) {
	} finally {
		yield put(FinancialActions.set_loading_upsell(false));
	}
}

function* assinarPlano({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	yield put(FinancialActions.set_loading_upsell(true));
	async function apiCall() {
		try {
			const dat = {
				TOKEN: user_logado.token,
				...payload
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}financeiro/financeiro_alterar_plano.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: financeiro_alterar_plano.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		if (data.status == TypesErrorRequest.SUCCESS) {
			let message: any = "";

			message = current_language?.assinatura_atualizada;

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: message,
				})
			);
		} else {
			let message: any = "";

			message = current_language?.erro_iugu;

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		}
	} catch (e) {
	} finally {
		yield put(ConfigsActions.config_request());
	}
}

function* assinarItem({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	yield put(FinancialActions.set_loading_upsell(true));
	async function apiCall() {
		try {
			const dat = {
				TOKEN: user_logado.token,
				...payload
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}financeiro/financeiro_adicionar_item.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: financeiro_adicionar_item.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		if (data.status == TypesErrorRequest.SUCCESS) {
			let message: any = "";

			message = current_language?.assinatura_atualizada;

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: message,
				})
			);
		} else {
			let message: any = "";
			if(data.code === 1) {
				message = current_language?.erro_iugu;
			} 
			if(data.code === 2) {
				message = current_language?.menu_lateral_fatura_pendente;
			}
			if(data.code === 3) {
				message = current_language?.assinatura_nao_encontrada; 
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		}
	} catch (e) {
	} finally {
		yield put(ConfigsActions.config_request());
		history.push("/upgrade");
	}
}

export function* FianncialSaga(): any {
	return yield all([
		takeLatest(Types.FATURAS_REQUEST, getFaturas),
		takeLatest(Types.ADD_CARD_REQUEST, addCard),
		takeLatest(Types.PLANOS_ITENS, getPlanosItens),
		takeLatest(Types.ASSINAR_PLANO, assinarPlano),
		takeLatest(Types.ASSINAR_ITEM, assinarItem),
	]);
}
