import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../database/txt.interface";
import { AppActions } from "../../store/ducks/app";
import { Barra, Preenchimento } from "./styles";
import styled from "styled-components";
import { Icon } from "../../styles/global";
import PesoIcon from "../../assets/icons/peso.svg";
import { P3Desk } from "../../styles/styles_text";
import EditIcon from "../../assets/icons/editIcon.svg";

interface ProgressProps {
	step: any;
	max: any;
}

const Progress: React.FC<ProgressProps> = ({ step, max }: any) => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const [porcent, setPorcent] = useState((step * 100) / max);

	const force_option_min = useSelector(
		(state: any) => state.avaliation.force_option_min
	);
	const force_option_max = useSelector(
		(state: any) => state.avaliation.force_option_max
	);
	const force_max = useSelector((state: any) => state.avaliation.force_max);
	const start = useSelector((state: any) => state.avaliation.start);

	const [porcentMin, setPorcenMin] = useState((force_option_min * 100) / max);
	const [porcentMax, setPorcenMax] = useState((force_option_max * 100) / max);

	const timer_reset = useSelector((state: any) => state.app.timer_reset);

	useEffect(() => {
		let max2: any = max;
		if (force_max > max2) {
			max2 = force_max * 1.2;
		}
		setPorcent((step * 100) / max2);
		setPorcenMin((force_option_min * 100) / max2);
		setPorcenMax(((force_option_min * 100) / max2) * 1.2);
		if (porcent <= (force_option_min * 100) / max2) {
			dispatch(AppActions.set_timer_reset(false));
		}
		if (porcent >= (force_option_min * 100) / max2) {
			dispatch(AppActions.set_timer_reset(true));
		}
	}, [step, timer_reset, force_option_min]);

	const connect_flag = useSelector(
		(state: any) => state.avaliation.connect_flag
	);

	const modo_demo: any = useSelector((state: any) => state.configs.modo_demo);

	const [valorMaior, setValorMaior] = useState<number>(0);

	useEffect(() => {
		if (porcent > valorMaior) {
			setValorMaior(porcent);
		}
	}, [porcent]);

	return (
		<div style={{ width: "100%" }}>
			<Barra>
				<div className="lineMin" style={{ left: `${porcentMin}%` }} />
				<Peso
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<Icon
						style={{ width: "2rem", height: "2rem", marginRight: "1rem" }}
						src={PesoIcon}
					/>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<PesoTitle>Peso</PesoTitle>
						<PesoTitle>{parseFloat(step).toFixed(2)} Kg</PesoTitle>
					</div>
				</Peso>
				<div
					className="lineMax"
					style={{ left: `${valorMaior == 0 ? 0 : valorMaior}%` }}
				/>
				<Preenchimento style={{ width: `${porcent}%` }}></Preenchimento>
			</Barra>

			<div className="linhasBarra">
				<div className="legendaPonta">
					<P3Desk>{current_language?.execucao_force_min}</P3Desk>
					<P3Desk>
						{parseFloat(force_option_min).toFixed(2)} Kg
						<Icon
							style={{ marginLeft: "1rem" }}
							src={EditIcon}
							onClick={() =>
								!start &&
								(connect_flag || modo_demo) &&
								dispatch(AppActions.set_modal_config_forc_min(true))
							}
						/>
					</P3Desk>
				</div>

				<div className="legendaPonta">
					<P3Desk>{current_language?.execucao_force_max}</P3Desk>
					<P3Desk>{parseFloat(force_max).toFixed(2)} Kg</P3Desk>
				</div>
			</div>
		</div>
	);
};

const Peso = styled.div`
	position: absolute;
	top: 15%;
	left: 45%;
`;

const PesoTitle = styled.text`
	color: var(--Primrias-Blue-K-Principal, var(--Color, #000e4b));
	text-align: center;
	font-family: "NotoSans-Regular";
	font-size: 1.1rem;
	font-style: normal;
	font-weight: 600;
	line-height: 150%; /* 2.25rem */
`;

export default Progress;
