export const Types = {
	EDIT_USUARIO_PERFIL: "EDIT_USUARIO_PERFIL",
	EDIT_USUARIO_PERFIL_FINALLY: "EDIT_USUARIO_PERFIL_FINALLY",
};

const INITIAL_STATE = {
	loading_perfil: false,
};

export default function perfil(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.EDIT_USUARIO_PERFIL:
			return {
				...state,
				loading_perfil: true,
			};
		case Types.EDIT_USUARIO_PERFIL_FINALLY:
			return {
				...state,
				loading_perfil: false,
			};

		default:
			return state;
	}
}

export const PerfilActions = {
	edit_perfil: (data: any) => ({
		type: Types.EDIT_USUARIO_PERFIL,
		payload: data,
	}),
	edit_perfil_finally: () => ({
		type: Types.EDIT_USUARIO_PERFIL_FINALLY,
	}),
};
