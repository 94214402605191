import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import {
	Container,
	Marcacoes,
	Membro,
	MembroFooter,
	MembroHead,
} from "./styles";

import SliderCorpo, { FuncaoMusculo } from "../SliderCorpo";
import { QuestionnaireProps } from "../../../../../interfaces/pacientes.interface";
import { useDispatch, useSelector } from "react-redux";
import { PacAvalActions } from "../../../../../store/ducks/manage_pac_aval";
import ModalExcluirEVA from "../../../../../components/Modais/ModalExcluirEVA";

import RenderBody from "./_renderCorpo";
import { TextsProps } from "../../../../../database/txt.interface";

interface Musculo {
	MUSCULO: number;
	LADO: "E" | "D" | "S";
	VALOR?: number;
}

interface QuestionProps {
	item: QuestionnaireProps;
	FUNCAO?: any;
}

const CorpoEVA: React.FC<QuestionProps> = ({ item, FUNCAO }) => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const Musculo = [
		current_language?.ombro,
		current_language?.cotovelo,
		current_language?.pulso,
		current_language?.quadril,
		current_language?.joelho,
		current_language?.tornozelo,
		current_language?.cervical,
		current_language?.toracica,
		current_language?.lombar,
		current_language?.coccix,
	];

	const [valueSlider, setValueSlider] = useState<FuncaoMusculo[]>([]);
	const array_respostas = useSelector(
		(state: any) => state.pac_aval.array_respostas
	);
	const array_resposta = useSelector(
		(state: any) => state.pac_aval.array_resposta
	);
	const array_marcadores = useSelector(
		(state: any) => state.pac_aval.array_marcadores
	);
	const questionarioAsync: any = useSelector(
		(state: any) => state.pac_aval.questionario_async
	);

	const handleSetMuscle = (obj: FuncaoMusculo) => {
		const exist = valueSlider.find(
			(i: any) => `${i.MUSCULO}${i.LADO}` === `${obj.MUSCULO}${obj.LADO}`
		);

		if (!exist) {
			setValueSlider([...valueSlider, obj]);
			handleTextInput(item, [...valueSlider, obj]);
		} else if (!!exist) {
			const prox_array = valueSlider.filter(
				(i: any) => `${i.MUSCULO}${i.LADO}` !== `${exist.MUSCULO}${exist.LADO}`
			);
			setValueSlider([...prox_array, obj]);
			handleTextInput(item, [...prox_array, obj]);
		}
	};

	const handleTextInput = (item: QuestionnaireProps, arr: Musculo[]) => {
		const dataObj = {
			QUESTIONARIO_LABEL: `${item.QUESTIONARIO_LABEL}`,
			QUESTIONARIO_RESPOSTA: arr,
		};
		const new_array = array_respostas.filter(
			(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
		);
		const new_array_resposta = array_resposta.filter(
			(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
		);
		dispatch(PacAvalActions.set_array_respostas([...new_array, dataObj]));
		dispatch(
			PacAvalActions.set_array_resposta([...new_array_resposta, dataObj])
		);
	};

	useEffect(() => {
		const valor = array_respostas?.filter(
			(e: any) => e?.QUESTIONARIO_LABEL === item?.QUESTIONARIO_LABEL
		);
		if (valor.length > 0) {
			const resp = valor[0]?.QUESTIONARIO_RESPOSTA;
			const array = [];
			const array_obj = [];
			for (let i = 0; i < resp.length; i++) {
				const obj = {
					MUSCULO: resp[i].MUSCULO,
					LADO: resp[i].LADO,
				};
				array.push(obj);
				const objeto = {
					MUSCULO: obj.MUSCULO,
					LADO: obj.LADO,
					VALOR: resp[i].VALOR,
				};
				array_obj.push(objeto);
			}
			setValueSlider(array_obj);
			dispatch(PacAvalActions.set_array_marcadores(array));
			handleTextInput(item, array_obj);
		}
	}, []);

	const [resetar, setResetar] = useState(false);
	const reset = () => {
		setResetar(true);
		const new_array = array_respostas.filter(
			(e: any) => e.QUESTIONARIO_LABEL !== "EVA_CORPO"
		);
		const new_array_resposta = array_resposta.filter(
			(e: any) => e.QUESTIONARIO_LABEL !== "EVA_CORPO"
		);
		dispatch(PacAvalActions.set_array_respostas(new_array));
		dispatch(PacAvalActions.set_array_resposta(new_array_resposta));
		setValueSlider([]);
		dispatch(PacAvalActions.set_array_marcadores([]));
	};

	useEffect(() => {
		if (array_marcadores.length <= 0 && valueSlider.length <= 0 && resetar) {
			FUNCAO();
			setResetar(false);
		}
	}, [valueSlider, array_marcadores, resetar]);

	const addMarcador = useCallback(
		(obj: Musculo) => {
			const exist = array_marcadores.find(
				(e: Musculo) => `${e.MUSCULO}${e.LADO}` === `${obj.MUSCULO}${obj.LADO}`
			);
			if (!exist) {
				dispatch(
					PacAvalActions.set_array_marcadores([...array_marcadores, obj])
				);
				const objeto = {
					MUSCULO: obj.MUSCULO,
					LADO: obj.LADO,
					VALOR: 1,
				};
				setMembrosDireito(`${obj.MUSCULO}${obj.LADO}`);
				handleSetMuscle(objeto);
			} else if (!!exist) {
				const new_array = array_marcadores.filter(
					(e: Musculo) =>
						`${e.MUSCULO}${e.LADO}` !== `${obj.MUSCULO}${obj.LADO}`
				);
				dispatch(PacAvalActions.set_array_marcadores(new_array));
				const new_valueSlider = valueSlider.filter(
					(e: Musculo) =>
						`${e.MUSCULO}${e.LADO}` !== `${obj.MUSCULO}${obj.LADO}`
				);
				setValueSlider([...new_valueSlider]);
			}
		},
		[array_marcadores, valueSlider]
	);

	const isActiveRadio = array_resposta?.find(
		(e: any) => e?.QUESTIONARIO_LABEL === "EVA"
	)?.QUESTIONARIO_RESPOSTA[0];
	const [isActive, setIsActive] = useState(
		isActiveRadio === undefined ? 1 : isActiveRadio
	);

	useEffect(() => {
		if (isActiveRadio === 1) {
			dispatch(PacAvalActions.set_array_marcadores([]));
			const new_array = array_respostas.filter(
				(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
			);
			const new_array_resposta = array_resposta.filter(
				(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
			);
			dispatch(PacAvalActions.set_array_respostas(new_array));
			dispatch(PacAvalActions.set_array_resposta(new_array_resposta));
		}
	}, [isActive]);

	const [membrosDireito, setMembrosDireito]: any = useState("");

	const handleMembros = (musculo: string) => {
		const membro = membrosDireito === musculo;

		if (!!membro) {
			setMembrosDireito("");
		} else {
			setMembrosDireito(musculo);
		}
	};

	const renderBody = useMemo(() => {
		return (
			<RenderBody
				item={item}
				addMarcador={addMarcador}
				isActive={isActive}
				valueSlider={valueSlider}
			/>
		);
	}, [item, isActive, valueSlider]);

	return (
		<Container questionAsync={questionarioAsync}>
			<ModalExcluirEVA FUNCAO={reset} />
			{renderBody}
			<Marcacoes questionAsync={questionarioAsync}>
				{array_marcadores.map((e: Musculo) => {
					const classe = membrosDireito === `${e.MUSCULO}${e.LADO}`;
					const valor = valueSlider.find(
						(i: any) => i.MUSCULO === e.MUSCULO && i.LADO === e.LADO
					);
					return (
						<Membro key={`${e.MUSCULO}${e.LADO}`}>
							<MembroHead>
								<p>
									{Musculo[e.MUSCULO]} - {valor?.VALOR ? valor?.VALOR : 1}
								</p>
								<button onClick={() => handleMembros(`${e.MUSCULO}${e.LADO}`)}>
									<svg
										className={`${!!classe ? "rotacao90" : "rotacao0"}`}
										width="12"
										height="18"
										viewBox="0 0 12 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M2.86914 0.490234L11.3691 8.99023L2.86914 17.4902L0.876953 15.498L7.38477 8.99023L0.876953 2.48242L2.86914 0.490234Z"
											fill="#000E4B"
										/>
									</svg>
								</button>
							</MembroHead>

							<MembroFooter
								style={{
									height: `${!!classe ? "8rem" : "0"}`,
									visibility: `${!!classe ? "visible" : "hidden"}`,
								}}
							>
								<SliderCorpo
									VALOR={valor?.VALOR ? valor?.VALOR : 1}
									FUNCAO={handleSetMuscle}
									MUSCULO={e.MUSCULO}
									LADO={e.LADO}
								/>
							</MembroFooter>
						</Membro>
					);
				})}
			</Marcacoes>
		</Container>
	);
};

export default memo(CorpoEVA);
