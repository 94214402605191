import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Content = styled.div`
	width: 100%;
	.bloqueio {
		z-index: 10;

		width: 100%;

		position: absolute;
		left: 0;
		top: 0;
		backdrop-filter: blur(6px);
		background: rgba(0, 0, 0, 0.3);

		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
`;

export const Exercice = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	.row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
	}

	.Divtitle {
		display: flex;
		flex-direction: row;
		width: 100%;

		.selectTitle {
			width: 60rem;
		}
	
	}

	.hr {
		border: none;
		height: 1px;
		color: #e0e0e0;
	}
`;

export const Charts = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 10rem;
	margin-bottom: 6rem;

	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.25rem;
		height: 3.43rem;
		background: #ffffff;
		border: 0.4px solid #e5e5e5;
		box-sizing: border-box;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		margin-right: 0.87rem;
	}
`;

export const Grafic = styled.div`
	width: 100%;

	.dropdown {
		position: relative;
		display: inline-block;
		cursor: pointer;
	}

	.dropdown-content {
		display: none;
		flex-direction: row;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 9.75rem;
		box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
		padding-left: 1.6rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-right: 1.6rem;
		z-index: 1;
		border-radius: 1.12rem;
		right: 0;
		bottom: 0;

		.op2 {
			font-size: 1.25rem;
			width: 100%;
			color: ${Colors.tertirary_red_1_dark};
			font-weight: 500;
			background-color: transparent;
			border: none;
			display: flex;
			justify-content: center;
			align-items: center;

			.migrationIcon {
				width: 25%;
				height: 50%;
				color: red;
			}
		}
	}

	.dropdown:hover .dropdown-content {
		display: block;
	}

	.btnPontos {
		background-color: transparent;
		border: none;
		margin-left: 85%;
	}

	.legenda {
		font-weight: 500;
		font-size: 1.5rem;
	}

	.legendaInferior {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-right: 2.7rem;
		margin-top: 0.2rem;
		margin-bottom: 0.188rem;

		.force {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 10px;
			line-height: 14px;
			color: #5c5b5b;
		}
	}

	.label {
		background: black;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
		border-radius: 6px !important;
	}

	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.25rem;
		height: 3.43rem;
		background: #ffffff;
		border: 0.4px solid #e5e5e5;
		box-sizing: border-box;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		margin-right: 0.87rem;
	}

	.divForca {
		width: 5%;
		height: 1%;
		transform: rotate(-90deg);
		margin-right: -20px;

		.force {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 10px;
			line-height: 14px;
			color: #5c5b5b;
			white-space: nowrap;
			margin-left: -50px;

			padding: 0px;
		}
	}

	.divTable {
		width: 88%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 1rem;

		.divExerciceNumber {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 10%;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
			border-radius: 4px;
			background-color: white;
			padding-right: 0.5rem;
			padding-left: 0.5rem;
			padding-top: 0.2rem;
			padding-bottom: 0.2rem;
			margin-top: -105px;

			.exerciceNumber {
				font-family: Roboto;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: #000000;
			}
		}

		.table {
			width: 100%;
			height: 6rem;
			padding-left: 1rem;
			padding-right: 1rem;
			padding-top: 1.5rem;
			border: 1px solid #e5e5e5;
			box-sizing: border-box;
			border-radius: 6px;
			margin-top: 1rem;

			.tr {
				margin-bottom: 0.75rem;
			}
		}

		.data {
			display: flex;
			flex-direction: column;
			width: 18%;
			align-items: center;
		}

		.prop {
			font-family: Roboto;
			font-style: normal;
			font-weight: bold;
			font-size: 10px;
			color: #5c5b5b;
			margin-bottom: 0.75rem;
		}

		.value {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 11px;
			color: #5c5b5b;
			margin-left: 26%;
		}
	}
`;
