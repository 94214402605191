import React, { useState } from "react";

import {
	ActivityIndicator,
	ContainerActivityIndicator,
	ContainerButtonFormSubmite,
} from "./style";
import { FormSubmiteButtonProps } from "./Models";
import { Colors } from "../../../styles/colors";
import Text from "../../../components/Texts/Text";

const FormSubmiteButton: React.FC<
	FormSubmiteButtonProps & Partial<HTMLButtonElement>
> = ({
	onClick,
	disabled = true,
	typeButtonComponent,
	title,
	width,
	height,
	marginTop,
	margintBottom,
	marginLeft,
	marginRight,
	paddingLeft,
	paddingRight,
	paddingTop,
	paddingBottom,
	bgColor,
	borderRadius,
	borderWidth,
	borderColor,
	justifyContent,
	alignItems,
	flexDirection,
	textColor = Colors?.neutral_very_light,
	fontSize,
	fontWeight,
	textAlign,
	fontFamily,
	testId,
	textDecoration,
	activityIndicator = false,
	activityIndicatorColor,
	type,
	...props
}) => {
	const [isPressed, setIsPressed] = useState(false);

	const handlePressIn = () => {
		if (typeButtonComponent == "secondary") {
			setIsPressed(true);
		}
	};

	const handlePressOut = () => {
		if (typeButtonComponent == "secondary") {
			setIsPressed(false);
		}
	};

	return (
		<ContainerButtonFormSubmite
			style={{
				width: !!width && `${width}%` || "100%",
				height: "3rem",
				alignItems: "center",
				display: "flex",
			}}
			type={type && type}
			onClick={() => onClick && onClick()}
			testId={testId}
			disabled={disabled}
			typeButtonComponent={typeButtonComponent}
			height={height}
			bgColor={
				typeButtonComponent == "primary" && disabled
					? Colors?.neutral_light_gray_3
					: typeButtonComponent == "primary" && !disabled && !bgColor
					? Colors?.primary_blue_k_main
					: bgColor
			}
			marginTop={marginTop}
			margintBottom={margintBottom}
			marginLeft={marginLeft}
			marginRight={marginRight}
			paddingLeft={paddingLeft}
			paddingRight={paddingRight}
			paddingTop={paddingTop}
			paddingBottom={paddingBottom}
			borderRadius={borderRadius}
			borderWidth={borderWidth}
			borderColor={
				typeButtonComponent == "secondary" && disabled
					? Colors?.neutral_light_gray_3
					: typeButtonComponent == "secondary" && !disabled
					? Colors?.primary_blue_k_main
					: borderColor
			}
			justifyContent={justifyContent}
			alignItems={alignItems}
			flexDirection={flexDirection}
			// {...props}
		>
			{activityIndicator ? (
				<ContainerActivityIndicator>
					<ActivityIndicator activityIndicatorColor={activityIndicatorColor} />
				</ContainerActivityIndicator>
			) : (
				<Text
					{...props}
					textColor={
						typeButtonComponent === "secondary" && disabled
							? Colors?.neutral_light_gray_3
							: typeButtonComponent === "secondary" && !disabled
							? Colors?.primary_blue_k_main
							: textColor
					}
					type={typeButtonComponent == "primary" ? "primary" : "secondary"}
					fontSize={fontSize}
					textAlign={textAlign}
					fontFamily={fontFamily}
					fontWeight={fontWeight}
					textDecoration={disabled ? "none" : textDecoration}
					title={title}
				/>
			)}
		</ContainerButtonFormSubmite>
	);
};

export default FormSubmiteButton;
