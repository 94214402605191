import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { AppActions } from "../../../store/ducks/app";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission, TypesProLivre } from "../../../utils/types";
import CustomizedSlider from "./CustomizedSlider";
import { Content } from "./styles";
import {
	Container,
	Subtitle,
	Title,
} from "../../../styles/styles_modais_padroes";
import styled from "styled-components";
import { P2Desk, P3Desk } from "../../../styles/styles_text";
import { ButtonSave, Checkbox, Icon } from "../../../styles/global";
import Remove from "../../../assets/icons/remove.svg";
import Add from "../../../assets/icons/add.svg";
import { DashBoardActions } from "../../../store/ducks/dashboard";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalCofiguracoesExecucao: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const classes = useStyles();
	const is_open_configuracoes_execucao = useSelector(
		(state: any) => state.app.is_open_configuracoes_execucao
	);

	const max_force = useSelector((state: any) => state.avaliation.max_force);
	const force_option_min = useSelector(
		(state: any) => state.avaliation.force_option_min
	);
	const force_option_min_str = useSelector(
		(state: any) => state.avaliation.force_option_min_str
	);

	const force_option_max = useSelector(
		(state: any) => state.avaliation.force_option_max
	);
	const force_option_max_str = useSelector(
		(state: any) => state.avaliation.force_option_max_str
	);

	const execucoes: any = useSelector((state: any) => state.dashboard.execucoes);
	const is_selected_type_pro_livre: any = useSelector(
		(state: any) => state.app.is_selected_type_pro_livre
	);
	const id_proto: any = useSelector((state: any) => state.exercicios.id_proto);

	const atualizacao_proto: any = [];
	execucoes.map((e: any) => {
		atualizacao_proto.push({
			ID_EXERCICIO: e.ID_EXERCICIO,
			EXECUCAO_ATUALIZADA: e.EXECUCAO_ATUALIZADA,
			EXECUCAO_EXECUTADA: e.EXECUCAO_EXECUTADA,
		});
	});

	const data_protocolo = (number: any) => {
		const data: any = atualizacao_proto?.find(
			(e: any) => e?.ID_EXERCICIO == number
		);
		return !!data?.EXECUCAO_ATUALIZADA;
	};

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_open_configuracoes_execucao(false));
	};

	const MaxForce = () => {
		if (max_force) {
			dispatch(AvaliationActions.set_max_force(false));
			dispatch(AvaliationActions.set_in_execution_force_max_status(false));
		} else {
			dispatch(AvaliationActions.set_max_force(true));
			dispatch(AvaliationActions.set_in_execution_force_max_status(true));
		}
		const max = (force_option_min * 20) / 100;
		const calc = force_option_min >= 199.6 ? 200 : force_option_min + max;
		dispatch(AvaliationActions.set_force_option_max(calc));

		let newValueMax = parseFloat(calc).toFixed(2).toString();
		newValueMax = newValueMax?.replace(".", " . ");
		dispatch(AvaliationActions.set_force_option_max_str(newValueMax));
	};

	const aumentar = () => {
		let aumentei = force_option_min + 0.2;
		dispatch(AvaliationActions.set_force_option_min(aumentei));
		if (aumentei >= 199.8) {
			dispatch(AvaliationActions.set_force_option_min(0.2));
			aumentei = 0.2;
		}
		if (max_force) {
			const max = (aumentei * 20) / 100;
			const calc = aumentei >= 199.6 ? 200 : aumentei + max;
			dispatch(AvaliationActions.set_force_option_max(calc));

			let newValueMax = calc?.toFixed(2)?.toString();
			newValueMax = newValueMax?.replace(".", " . ");
			dispatch(AvaliationActions.set_force_option_max_str(newValueMax));
		}
		let newValueMin = aumentei?.toFixed(2)?.toString();
		newValueMin = newValueMin?.replace(".", " . ");
		dispatch(AvaliationActions.set_force_option_min_str(newValueMin));
	};

	const diminuir = () => {
		let diminui = force_option_min - 0.2;
		dispatch(AvaliationActions.set_force_option_min(diminui));
		if (diminui <= 0.2) {
			dispatch(AvaliationActions.set_force_option_min(199.8));
			diminui = 199.8;
		}
		if (max_force) {
			const max = (diminui * 20) / 100;
			const calc = diminui === 199.8 ? 200 : diminui + max;
			dispatch(AvaliationActions.set_force_option_max(calc));

			let newValueMax = calc?.toFixed(2)?.toString();
			newValueMax = newValueMax?.replace(".", " . ");
			dispatch(AvaliationActions.set_force_option_max_str(newValueMax));
		}
		let newValueMin = diminui?.toFixed(2)?.toString();
		newValueMin = newValueMin?.replace(".", " . ");
		dispatch(AvaliationActions.set_force_option_min_str(newValueMin));
	};

	const aumentarMax = () => {
		let aumentei = force_option_max + 0.2;
		dispatch(AvaliationActions.set_force_option_max(aumentei));
		if (aumentei >= 200) {
			const calc = (force_option_min * 20) / 100 + aumentei;
			dispatch(AvaliationActions.set_force_option_max(calc));
			aumentei = calc;
		}

		let newValueMax = aumentei?.toFixed(2)?.toString();
		newValueMax = newValueMax?.replace(".", " . ");
		dispatch(AvaliationActions.set_force_option_max_str(newValueMax));
	};

	const diminuirMax = () => {
		let diminui = force_option_max - 0.2;
		const min = (force_option_min * 20) / 100 + force_option_min;
		if (diminui <= min) {
			dispatch(AvaliationActions.set_force_option_max(min));
		} else {
			dispatch(AvaliationActions.set_force_option_max(diminui));
		}
		if (diminui <= 0.2) {
			dispatch(AvaliationActions.set_force_option_max(200));
			diminui = 200;
		}

		const max = (force_option_min * 20) / 100 + force_option_min;
		if (diminui <= max) {
		}

		let newValueMax = diminui?.toFixed(2)?.toString();
		newValueMax = newValueMax?.replace(".", " . ");
		dispatch(AvaliationActions.set_force_option_max_str(newValueMax));
	};
	const [valorMinimo, setValorMinimo] = useState(force_option_min);
	const [valorMinimoStr, setValorMinimoStr] = useState(force_option_min_str);

	useEffect(() => {
		setValorMinimo(force_option_min);
		setValorMinimoStr(force_option_min_str);

		if (max_force) {
			const max = (force_option_min * 20) / 100;
			const calc = force_option_min >= 199.6 ? 200 : force_option_min + max;
			dispatch(AvaliationActions.set_force_option_max(calc));

			let newValueMax = calc?.toFixed(2)?.toString();
			newValueMax = newValueMax?.replace(".", " . ");
			dispatch(AvaliationActions.set_force_option_max_str(newValueMax));
		}
	}, [force_option_min, force_option_min_str]);

	const execute = () => {
		if (is_selected_type_pro_livre === TypesProLivre.LIVRE) {
			dispatch(AppActions.set_open_configuracoes_execucao(false));
			dispatch(DashBoardActions.set_current_page("execucao"))
		}
		if (is_selected_type_pro_livre === TypesProLivre.PROTOCOLO) {
			dispatch(AppActions.set_open_configuracoes_execucao(false));
			dispatch(AppActions.set_checklist_execucao(false));
		}
	};

	useEffect(() => {
		if (max_force === false) {
			dispatch(AvaliationActions.set_force_option_max(force_option_min * 2));
		}
	}, [max_force]);

	// Segurar botão Mínimo Diminui rápido
	const [pressionadoMinDim, setPressionadoMinDim] = useState(false);

	const segurarBotãoMinDim = () => {
		setPressionadoMinDim(true);
	};

	const soltarBotãoMinDim = () => {
		setPressionadoMinDim(false);
	};

	useEffect(() => {
		if (pressionadoMinDim === true) {
			setTimeout(() => {
				diminuir();
			}, 50);
		} else if (pressionadoMinDim === false) {
			return;
		}
	}, [pressionadoMinDim, force_option_min]);

	// Segurar botão Mínimo Aumenta rápido
	const [pressionadoMinAum, setPressionadoMinAum] = useState(false);

	const segurarBotãoMinAum = () => {
		setPressionadoMinAum(true);
	};

	const soltarBotãoMinAum = () => {
		setPressionadoMinAum(false);
	};

	useEffect(() => {
		if (pressionadoMinAum === true) {
			setTimeout(() => {
				aumentar();
			}, 50);
		} else if (pressionadoMinAum === false) {
			return;
		}
	}, [pressionadoMinAum, force_option_min]);

	// Segurar botão Máximo Diminui rápido
	const [pressionadoMaxDim, setPressionadoMaxDim] = useState(false);

	const segurarBotãoMaxDim = () => {
		setPressionadoMaxDim(true);
	};

	const soltarBotãoMaxDim = () => {
		setPressionadoMaxDim(false);
	};

	useEffect(() => {
		if (pressionadoMaxDim === true) {
			setTimeout(() => {
				diminuirMax();
			}, 50);
		} else if (pressionadoMaxDim === false) {
			return;
		}
	}, [pressionadoMaxDim, force_option_max]);

	// Segurar botão Mínimo Aumenta rápido
	const [pressionadoMaxAum, setPressionadoMaxAum] = useState(false);

	const segurarBotãoMaxAum = () => {
		setPressionadoMaxAum(true);
	};

	const soltarBotãoMaxAum = () => {
		setPressionadoMaxAum(false);
	};

	useEffect(() => {
		if (pressionadoMaxAum === true) {
			setTimeout(() => {
				aumentarMax();
			}, 50);
		} else if (pressionadoMaxAum === false) {
			return;
		}
	}, [pressionadoMaxAum, force_option_max]);

	const [region_selected, set_region_selected] = useState("percentual");

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_configuracoes_execucao}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_configuracoes_execucao}>
					<Container>
						<Content>
							<Title>{current_language?.modal_configurar_execucao}</Title>
							<Subtitle>Escolha como definir a força mínima:</Subtitle>

							<InfeEsup>
								<OptionRegion
									style={{
										borderColor:
											region_selected == "percentual" ? "#00A377" : "#C4C5CC",
									}}
									onClick={() => set_region_selected("percentual")}
								>
									<P2Desk>Percentual de RM</P2Desk>
								</OptionRegion>
								<OptionRegion
									style={{
										borderColor:
											region_selected == "personalizado"
												? "#00A377"
												: "#C4C5CC",
									}}
									onClick={() => set_region_selected("personalizado")}
								>
									<P2Desk>Personalizado</P2Desk>
								</OptionRegion>
							</InfeEsup>
							{region_selected === "personalizado" && (
								<>
									<P2Desk style={{ marginTop: "2rem" }}>
										Força mínima por percentual de RM
									</P2Desk>
									<div className="divText">
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												marginTop: "1rem",
												marginBottom: "1rem",
											}}
										>
											<Botao
												className="buttons"
												onMouseUp={soltarBotãoMinDim}
												onMouseDown={segurarBotãoMinDim}
												onClick={diminuir}
											>
												<Icon src={Remove} />
											</Botao>

											<Span>
												{" "}
												{valorMinimo < 10
													? `00${valorMinimoStr}`
													: valorMinimo < 100
													? `0${valorMinimoStr}`
													: valorMinimoStr}
											</Span>
											<Botao
												className="buttons"
												onMouseUp={soltarBotãoMinAum}
												onMouseDown={segurarBotãoMinAum}
												onClick={aumentar}
											>
												<Icon src={Add} />
											</Botao>
										</div>
									</div>
								</>
							)}
							{region_selected === "percentual" && (
								<>
									<P2Desk style={{ marginTop: "2rem", marginBottom: "1rem" }}>
										Força mínima por percentual de RM
									</P2Desk>
									<div className="selecionados">
										{!data_protocolo(id_proto) ? (
											<div
												className="divText"
												onClick={() => {
													!getPermission(
														config_servicos,
														TypesPermission.SERVICO_PERCENTUAL_RM,
														dispatch
													) &&
														dispatch(
															AppActions.set_modal_upgrade_funcionalidade(true)
														);
												}}
											>
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM13.2 18H10.8V15.6H13.2V18ZM13.2 13.2H10.8V6H13.2V13.2Z"
														fill="#FF0000"
													/>
												</svg>
												<div className="avisos">
													{current_language?.idioma === "Português" ? (
														<>
															<p className="aviso">
																Este paciente ainda não possui histórico de RM.
															</p>
															<p className="aviso">
																A % RM será aplicada partir da{" "}
																<span>segunda</span> execução.
															</p>
														</>
													) : current_language?.idioma === "English" ? (
														<>
															<p className="aviso">
																This patient does not have a history of MR yet.
															</p>
															<p className="aviso">
																The % MR will be applied from the{" "}
																<span>second</span> run.
															</p>
														</>
													) : (
														<>
															<p className="aviso">
																Este paciente aún no tiene antecedentes de RM.
															</p>
															<p className="aviso">
																El % RM se aplicará a partir de la{" "}
																<span>segunda</span> ejecución.
															</p>
														</>
													)}
												</div>
											</div>
										) : (
											<div>
												<CustomizedSlider />
											</div>
										)}
									</div>
								</>
							)}

							<hr
								style={{
									border: "1px solid #DEDFE3",
									marginTop: "1rem",
									marginBottom: "1rem",
									width: "100%",
								}}
							/>

							<P2Desk>Força máxima personalizada</P2Desk>
							<div className={`divText forMax ${max_force ? "" : "opacidade"}`}>
								<Checkbox
									style={{marginRight: "1rem"}}
									name="max"
									id="max"
									type="checkbox"
									defaultChecked={max_force ? true : false}
									onClick={MaxForce}
								/>

								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										marginTop: "1rem",
										marginBottom: "1rem",
									}}
								>
									<Botao
										className="buttons"
										onMouseUp={max_force && soltarBotãoMaxDim}
										onMouseDown={max_force && segurarBotãoMaxDim}
										onClick={max_force && diminuirMax}
									>
										<Icon src={Remove} />
									</Botao>

									<Span>
										{" "}
										{force_option_max < 10
											? `00${force_option_max_str}`
											: force_option_max < 100
											? `0${force_option_max_str}`
											: force_option_max_str}
									</Span>
									<Botao
										className="buttons"
										onMouseUp={max_force && soltarBotãoMaxAum}
										onMouseDown={max_force && segurarBotãoMaxAum}
										onClick={max_force && aumentarMax}
									>
										<Icon src={Add} />
									</Botao>
								</div>
							</div>
						</Content>
						<ButtonSave active={true} className="button" onClick={execute}>
							OK
						</ButtonSave>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

const InfeEsup = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

const OptionRegion = styled.div`
	border-radius: 0.75rem;
	border: 2px solid var(--Neutras-Grey-K-20, #c4c5cc);
	width: 50%;
	height: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1rem;
	margin-right: 1rem;
`;

export const Botao = styled.span`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 1rem;
	border: 0;
	background: transparent;
`;

export const Span = styled.div`
	background-color: #f1f2f3;
	padding: 1rem;
	border-radius: 0.5rem;
	align-items: center;
	justify-content: center;
	margin-left: 1rem;
	margin-right: 1rem;
`;

export default ModalCofiguracoesExecucao;
