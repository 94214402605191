import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import Logo from "../../../assets/logo.svg";
import { AppActions } from "../../../store/ducks/app";
import { AuthActions } from "../../../store/ducks/auth";
import { ConfigsActions } from "../../../store/ducks/configs";
import { loadStorage } from "../../../utils/base_async_storage";
import { Conteiner } from "../Login/styles";

const Loading: React.FC = function ({ history }: any) {
	const dispatch = useDispatch();

	async function checkUserLogged() {
		const config_user: any = await loadStorage("config_user");
		const config_app: any = await loadStorage("config_app");
		const config_servicos: any = await loadStorage("config_servicos");
		const user_logado: any = await loadStorage("auth");
		const notification: any = await loadStorage('notification_view');

		dispatch(AuthActions.auth_success(user_logado));

		if (config_app) {
			dispatch(ConfigsActions.set_config_app(config_app));
		}
		if (config_servicos) {
			dispatch(ConfigsActions.set_config_servico(config_servicos));
		}
		if (config_user) {
			dispatch(ConfigsActions.set_medida(config_user.CONFIG_GER_UNIDADE));
			dispatch(
				ConfigsActions.set_tempo_contracao(
					config_user.CONFIG_EXER_CONTRACAO_FLAG
				)
			);
			dispatch(ConfigsActions.set_alerta_sonoro(config_user.CONFIG_EXER_SOM));
			dispatch(
				ConfigsActions.set_alerta_vibratorio(config_user.CONFIG_EXER_VIBRAR)
			);
			dispatch(
				ConfigsActions.set_contagem_regresiva(config_user.CONFIG_EXER_321)
			);
			dispatch(
				ConfigsActions.set_intervalo_descanso(
					config_user.CONFIG_EXER_DESCANSO_FLAG
				)
			);
		}
		if(notification){
			dispatch(AppActions.set_notification_view_all(notification))
		}



		if (user_logado) {
			const authData: any = {
				USUARIO_EMAIL: user_logado.USUARIO_EMAIL,
				USUARIO_SENHA: user_logado.USUARIO_SENHA,
				txLoginTipo: 2,
				DEVICE_UUID: "24c6482b64839e8384cJ",
				txIdioma: "pt",
			};
			dispatch(AuthActions.auth_request(authData, true));
				history.push("/dashboard");
		} else {
				history.push("/login");
		}
	}
	useEffect(() => {
		checkUserLogged();
	}, []);

	return (
		<Conteiner>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img src={Logo} alt="logo" />
			</div>
		</Conteiner>
	);
};

export default React.memo(Loading);
