import React from "react";
import { ContainerDash, Icon } from "../../../styles/global";
import { useDispatch, useSelector } from "react-redux";
import {
	ProtocolosProps,
	ProtoExercicioProps,
} from "../../../interfaces/protocolo.interface";
import styled from "styled-components";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import TimeIcon from "../../../assets/icons/timer_icon.svg";
import ExpandIcon from "../../../assets/icons/expand.svg";
import TutorialDefault from "../../../assets/tutorial_default.png";
import { TextsProps } from "../../../database/txt.interface";
import { P3Desk } from "../../../styles/styles_text";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import RenderExerProto from "../../Execucao/Protocolo/renderExerProps";
import { AppActions } from "../../../store/ducks/app";
import { tutorialImages } from "../../../utils/tutorials";

const ExercisesForExecution: React.FC = () => {
	const dispatch = useDispatch();

	const protocolo_list: ProtocolosProps = useSelector(
		(state: any) => state.app.protocolo_list
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("protocols_in_patient"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "1rem", height: "1rem" }} />
				<P3Desk>{current_language?.menu_lateral_protocolos}</P3Desk>
			</Return>
			<TitleDiv style={{ width: "60%" }}>
				<Title>{protocolo_list.PROTOCOLO_TITULO_PT}</Title>
			</TitleDiv>
			{protocolo_list?.PROTOCOLO_TEMPO_ESTIMADO && (
				<TempoEstimado>
					<Icon src={TimeIcon} style={{ width: "1rem", height: "1rem" }} />
					{current_language?.tempo_estimado}{" "}
					{protocolo_list.PROTOCOLO_TEMPO_ESTIMADO}
				</TempoEstimado>
			)}
			<ListContainer>
				{protocolo_list?.PROTOCOLO_EXERCICIO.map(
					(exercicio: ProtoExercicioProps, index) => (
						<ExerciseSection key={index}>
							<TitleAndDescription>
								<ExerciseName>{exercicio.EXERCICIO_TITULO_PT}</ExerciseName>
								<RenderExerProto item={exercicio} index={index} />
							</TitleAndDescription>
							<TutorialSection
							onClick={() =>
								dispatch(AppActions.set_modal_tutorial(true, exercicio))
							}>
								{tutorialImages[exercicio.EXERCICIO_TITULO_PT] && tutorialImages[exercicio.EXERCICIO_TITULO_PT][0] && (
									<>
										<Icon
											style={{ 
												width: "8rem",
												height: "100%",
												objectFit: "cover",
												borderTopRightRadius: "15px",
												borderBottomRightRadius: "15px",
												marginRight: "7px"
											}}
											src={tutorialImages[exercicio.EXERCICIO_TITULO_PT][0]}
										/>
										<Icon
											src={ExpandIcon}
											style={{
												width: "1.5rem",
												height: "1.5rem",
												marginLeft: "-2rem",
												marginTop: "0.5rem",
											}}
										/>
									</>
								)}
							</TutorialSection>
						</ExerciseSection>
					)
				)}
			</ListContainer>
		</ContainerDash>
	);
};

const Return = styled.div`
	display: flex;
	flex-direction: row;
	width: 60%;
	align-items: center;
	cursor: pointer;
`;

const Title = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1.5rem;
	font-weight: 800;
	line-height: 60px;
`;

const TempoEstimado = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-family: "NotoSans-Regular";
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 28.8px;
	border: 1px solid #00a377;
	width: 60%;
	border-radius: 30px;
	color: #00a377;
	align-items: center;
	padding: 0.1rem;
	margin-bottom: 1.5rem;
`;

const ListContainer = styled.div`
	width: 60%;
	height: 65vh;
	margin-bottom: 1.5rem;
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const ExerciseSection = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border: 2px solid #dedfe3;
	border-radius: 18px;
	margin-bottom: 1rem;
	height: 8rem;
`;

const TitleAndDescription = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1rem;
`;

const ExerciseName = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1.1rem;
	font-weight: 600;
	color: #323d6e;
`;

const TutorialSection = styled.div`
	display: flex;
	max-height: 100%;
`;

const TitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 60%;
`;

export default ExercisesForExecution;

