import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import bluetooth from "../../../assets/icons/bluetooth.svg";
import buttonClose from "../../../assets/icons/buttonClose.svg";
import dinamo from "../../../assets/icons/Dinamo.svg";
import lightConnect from "../../../assets/icons/lightConnect.svg";
import lightSearch from "../../../assets/icons/lightSearch.svg";
import localization from "../../../assets/icons/localiozation.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalConectaHandGrip: React.FC = () => {
	const dispatch = useDispatch();

	const classes = useStyles();
	const is_open_conecta_hand_grip = useSelector(
		(state: any) => state.app.is_open_conecta_hand_grip
	);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const result_bluetooth_hand_grip = useSelector(
		(state: any) => state.configs.result_bluetooth_hand_grip
	);
	const ModalBluetoothClose = () => {
		dispatch(AppActions.set_modal_conecta_hand_grip(false));
		if (!result_bluetooth_hand_grip) {
			window.history.back();
		}
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_conecta_hand_grip}
				onClose={ModalBluetoothClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_conecta_hand_grip}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalBluetoothClose}
							alt="Close"
						/>
						<svg
							width="64"
							height="88"
							viewBox="0 0 22 34"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M16.7656 24.1875L13.6406 20.9844V27.3125L16.7656 24.1875ZM13.6406 6.6875V13.0156L16.7656 9.8125L13.6406 6.6875ZM21.5312 9.8125L14.3438 17L21.5312 24.1875L12 33.6406H10.3594V20.9844L2.70312 28.6406L0.359375 26.2969L9.65625 17L0.359375 7.70312L2.70312 5.35938L10.3594 13.0156V0.359375H12L21.5312 9.8125Z"
								fill="#0094D1"
							/>
						</svg>

						<div className="divText">
							<p className="text">
								{current_language?.menu_lateral_permissoes_bluetooth_mensg_hg}
							</p>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalConectaHandGrip;
