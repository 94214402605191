import styled from "styled-components";
import { Colors } from "../../../styles/colors";

interface CardItemProps {
	active?: boolean | undefined;
	type?: string | undefined;
}

export const Container = styled.div`
	width: 23.063rem;
	height: 6.75rem;
	margin-top: 2rem;
	border-radius: 1rem;
	cursor: pointer;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.22);

	display: flex;
	align-items: center;

	${({ active }: CardItemProps) =>
		active
			? `border: 1px solid ${Colors.secondary_green_k_medium_1};
    transition: all 0.2s ease-in;`
			: `border: 1px solid #fff;
    transition: all 0.2s ease-in;`}
	&:hover {
		border: 1px solid ${Colors.secondary_green_k_medium_1};
		transition: all 0.2s ease-in;
	}
`;
export const Box = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0.5rem;
`;
export const Icon = styled.div``;
export const Typography = styled.div`
	display: flex;
	flex-direction: column;
	width: 70%;
	align-items: flex-start;
	justify-content: space-between;
`;
export const Title = styled.span`
	color: #7f7f7f;
	transition: all 0.2s ease-in;
	font-weight: 700;
	font-size: 18px;
`;
export const SubTitle = styled.span`
	padding-top: 0.3rem;
	font-size: 0.7rem;
	font-weight: 400;
	color: #7f7f7f;
`;
export const RadioBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	transition: all 0.2s ease-in;
`;
export const RadioLabel = styled.label`
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	border-radius: 50%;
	transition: all 0.2s ease-in;
	${({ active }: CardItemProps) =>
		active
			? `background-color: ${Colors.secondary_green_k_medium_1};
			border: 0.3rem solid #eaebeb;`
			: `background-color: #eaebeb;`}
`;
export const RadioInput = styled.input.attrs(({ checked }: any) => ({
	type: "radio",
	checked: checked,
	defaultChecked: checked 

}))`
	width: 1.5rem;
	fill: ${Colors.secondary_green_k_medium_1};
	transition: all 0.2s ease-in;
	opacity: 0;
	cursor: pointer;

	height: 25px;
	transition: all 0.2s ease-in;
`;

