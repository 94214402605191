import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
}

body{
    background-color: white;
    color: #B3B3B3;
    -webkit-font-smoothing: antialiased;
		height: 100%;
}

//Regular quando tiver o design
body, input, button{
    font-family: 'Roboto', serif;
    font-size: 16px;
}

h1, h2, h3, h4, h5, h6, strong{
    font-weight: 600;
    color: #000E4B;
}

button{
  cursor: pointer;
}

text{
	font-family: "NotoSans-Regular";
	font-size: 0.9rem;
	font-weight: 400;
	color: #000E4B;
}

`;

interface ActiveProps {
	active: boolean;
}

export const ContainerDash = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 1rem;
	background-color: #fafafa;
	height: 90vh;
`;

export const Checkbox = styled.input`
	width: 1.1rem;
	height: 1.1rem;
	border-radius: 20%;
	background-color: transparent;
	border: 2px solid #797d8d;
	appearance: none;
	cursor: pointer;

	&:checked {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: #007c6b;
		background-position: center;
		border: none;
		cursor: pointer;
		width: 1.1rem;
		height: 1.1rem;
	}
`;

export const RadioButton = styled.input`
	width: 1.1rem;
	height: 1.1rem;
	border-radius: 50%;
	background-color: transparent;
	border: 2px solid #797d8d;
	outline: 2px solid #797d8d;
	border: 2px solid white;
	appearance: none;

	&:checked {
		background-color: #00d084;
		outline: 2px solid #00d084;
		border: 2px solid white;
	}
`;

export const Tag = styled.div`
	border-radius: 30px;
	font-size: 1rem;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 1rem;
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
	display: flex;
	align-items: center;
	color: #3d4259;
	font-family: "NotoSans-Regular";
	font-weight: 600;
	text-align: center;
`;

export const ButtonSave = styled.button<ActiveProps>`
	font-family: "Kanit-Regular";
	font-size: 1.1rem;
	font-weight: 600;
	line-height: 26.4px;
	text-align: center;
	padding: 0.5rem;
	background-color: ${({ active }) => (active ? "#000E4B" : "#aea9b4")};
	border-radius: 12px;
	border: none;
	color: white;
	padding: 0.8rem;
	width: 100%;
`;

export const ButtonAdd = styled.div<ActiveProps>`
	padding: 0.7rem;
	padding-left: 1rem;
	padding-right: 1rem;
	background-color: ${({ active }) => (active ? "#000E4B" : "#00A377")};
	border-radius: 45px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: #fafafa;
	font-family: "NotoSans-regular";
	font-size: 0.8rem;
	font-weight: 700;
	text-align: left;
	cursor: pointer;

	&:hover {
		background-color: #007c6b;
	}
`;

export const DropDown = styled.div<ActiveProps>`
	position: relative;
	display: inline-block;
	cursor: pointer;

	.dropdown-content {
		display: ${({ active }) => (active ? "flex" : "none")};
		flex-direction: column;
		position: absolute;
		white-space: nowrap;
		background-color: #f9f9f9;
		box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
		padding: 1rem;
		z-index: 1;
		border-radius: 12px;
		right: 0;
	}
`;

export const MenuOption = styled.text`
	font-family: "NotoSans-regular";
	font-size: 0.95rem;
	font-weight: 500;
	line-height: 2.5rem;
	color: #3d4259;
`;

export const Return = styled.div`
	display: flex;
	flex-direction: row;
	width: 60%;
	align-items: center;
	cursor: pointer;
`;

export const ProfilePicture = styled.img`
	width: 2rem;
	height: 2rem;
	background-color: #ededed;
	border: 0;
	border-radius: 100%;
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
	object-fit: cover;
`;

export const Icon = styled.img`
	width: 1rem;
	height: 1rem;
`;

export const HeaderProfile = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 1rem;
	margin-bottom: 3rem;
	width: 60%;
`;

export const Scroll = styled.div`
	width: 100%;
	padding-bottom: 1rem;
	padding-top: 1rem;
	overflow-x: hidden;
	height: 100%;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;
