import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextsProps } from "../../../database/txt.interface";

import { AppActions } from "../../../store/ducks/app";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import ToastAlert from "../../toast";
import { ContainerAnamnese } from "./styles";
import Accordion from "./Accordion";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "../../../utils/types";
import { TypesDataAnamnese } from '../../../utils/types';
import { AmplitudeLog } from "../../../utils/amplitude";

const EscolhaAnamnese: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const is_relatorio_evolucao = useSelector(
		(state: any) => state.relatorios.is_relatorio_evolucao
	);
	const is_relatorio_assimetria = useSelector(
		(state: any) => state.relatorios.is_relatorio_assimetria
	);
	const is_relatorio_forca_tempo = useSelector(
		(state: any) => state.relatorios.is_relatorio_forca_tempo
	);
	const is_relatorio_multifatorial = useSelector(
		(state: any) => state.relatorios.is_relatorio_multifatorial
	);
	const is_relatorio_fisioterapeutico = useSelector(
		(state: any) => state.relatorios.is_relatorio_fisioterapeutico
	);

	const selected_type_av = useSelector(
		(state: any) => state.relatorios.selected_type_av
	);
	const selected_type_qv = useSelector(
		(state: any) => state.relatorios.selected_type_qv
	);
	const selected_type_fisio = useSelector(
		(state: any) => state.relatorios.selected_type_fisio
	);
	const selected_type_mult = useSelector(
		(state: any) => state.relatorios.selected_type_mult
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const anamneses: any = useSelector((state: any) => state.dashboard.anamneses);

	const [dataAnamnese, setDataAnamnese] = useState<any>([]);

	useEffect(() => {
		setDataAnamnese(
			anamneses.filter((item: any) => item.ID_USUARIO == paciente.ID_USUARIO)
		);
	}, [paciente, anamneses]);

	const [toastAut, setToastAut] = useState(false);

	const handleAnamnese = () => {
		if (selected_type_av && (selected_type_fisio || selected_type_mult)) {
			setToastAut(false);

			// dispatch(AppActions.set_continue_compartilhamento("EscolhaAnamnese"));
			dispatch(AppActions.set_continue_compartilhamento("EscolhaEmail"));
		} else {
			setToastAut(true);
			const message =
				"Selecione pelo menos um tipo de anamnese e uma avaliação";
			dispatch(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		}
	};

	const handleAnamneseVoltar = () => {
		if (
			is_relatorio_evolucao ||
			is_relatorio_assimetria ||
			is_relatorio_forca_tempo
		) {
			dispatch(AppActions.set_continue_compartilhamento("ExercicioList"));
		} else {
			dispatch(AppActions.set_continue_compartilhamento("EscolhaData"));
		}
	};

	const data = [
		{
			titles: current_language?.btn_multifatorial,
			type: TypesDataAnamnese.MULTIFATORIAL,
			visible: is_relatorio_multifatorial,
			options: dataAnamnese && dataAnamnese[0]?.anamneses_multifatorial,
		},
		{
			titles: current_language?.btn_fisioterapeutico,
			type: TypesDataAnamnese.FISIOTERAPEUTICO,
			visible: is_relatorio_fisioterapeutico,
			options: dataAnamnese && dataAnamnese[0]?.anamneses_fisioterapeutico,
		},
		{
			titles: current_language?.anamnese_avaliacao,
			type: TypesDataAnamnese.AVALIACAO,
			visible: is_relatorio_multifatorial || is_relatorio_fisioterapeutico,
			options: dataAnamnese && dataAnamnese[0]?.anamneses_avaliacao,
		},
		{
			titles: current_language?.qualidade_de_vida,
			type: TypesDataAnamnese.QUALIDADE_VIDA,
			visible: dataAnamnese && dataAnamnese[0]?.qualidades_vida.length > 0,
			options: dataAnamnese && dataAnamnese[0]?.qualidades_vida,
		},
	];

	return (
		<ContainerAnamnese>
			{toastAut == true && <ToastAlert />}
			<p className="text">{current_language?.anamnese_opcoes}</p>
			<div className="lista">
				<Accordion faqs={data} />
			</div>

			<div className="botoes">
				<button 
					className="continuar" 
					onClick={()=>{
						handleAnamnese()
						AmplitudeLog('opc_anamnese_selecionado')
					}}
				>
					{current_language?.continuar}
				</button>
				<button 
					className="voltar" 
					onClick={()=>{
						handleAnamneseVoltar()
						AmplitudeLog('voltar_selecionar_opc_anamnese')
					}}
				>
					{current_language?.voltar}
				</button>
			</div>
		</ContainerAnamnese>
	);
};

export default EscolhaAnamnese;
