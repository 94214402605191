import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { TypesSelectButton } from '../../../pages/MenueConta/Upgrade';
import history from '../../../routes/history';
import { AppActions } from "../../../store/ducks/app";
import { FinancialActions } from '../../../store/ducks/financial';
import { TypePacotes, TypesLanguage } from '../../../utils/types';
import { Container } from "./styles";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalUpgradeRelatorios: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	const is_modal_emissao_rel = useSelector(
		(state: any) => state.app.is_modal_emissao_rel
	);
	const set_quantidade_emissoes_relatorios = useSelector(
		(state: any) => state.configs.set_quantidade_emissoes_relatorios
	);

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_emissao_rel(false));
	};

	const itens_upsell = useSelector(
		(state: any) => state.financial.itens_upsell
	);

	const item_anamnese = itens_upsell?.itens_assinatura?.find((e: any) => e.ITEM_ASSINATURA_TIPO === TypePacotes.ANAMNESE)
	const language = useSelector((state: any) => state.configs.language);
	
	const upgrade = () => {
		if (
			config_servicos.SERVICO_PLANO_NOME !== "Free" &&
			config_servicos.SERVICO_PLANO_NOME !== "Básico"
		) {
			dispatch(FinancialActions.set_contrato(itens_upsell.link_contrato));
			dispatch(FinancialActions.set_plano_selecionado(item_anamnese));
			dispatch(FinancialActions.set_tipo(TypesSelectButton.PACOTES));
		}
		history.push("/confirmacao");
		dispatch(AppActions.set_modal_emissao_rel(false));
	}

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_modal_emissao_rel}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_modal_emissao_rel}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>

						{
							set_quantidade_emissoes_relatorios && 10 - (set_quantidade_emissoes_relatorios.fisioterapeutico +
								set_quantidade_emissoes_relatorios.multifatorial)
								<= 0 ?
								<svg width="105" height="145" viewBox="0 0 105 145" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M90.0794 48.7774H15.3992C11.9621 48.7774 9.17585 51.5637 9.17585 55.0008V129.681C9.17585 133.118 11.9621 135.904 15.3992 135.904H90.0794C93.5165 135.904 96.3028 133.118 96.3028 129.681V55.0008C96.3028 51.5637 93.5165 48.7774 90.0794 48.7774ZM15.3992 40.4796C7.37939 40.4796 0.878052 46.981 0.878052 55.0008V129.681C0.878052 137.701 7.37939 144.202 15.3992 144.202H90.0794C98.0992 144.202 104.601 137.701 104.601 129.681V55.0008C104.601 46.981 98.0992 40.4796 90.0794 40.4796H15.3992Z" fill="#5C5B5B" />
									<path d="M28.3634 80.4131C28.3634 78.1217 30.2209 76.2642 32.5123 76.2642C34.8037 76.2642 36.6612 78.1217 36.6612 80.4131V90.7853C36.6612 93.0767 34.8037 94.9342 32.5123 94.9342C30.2209 94.9342 28.3634 93.0767 28.3634 90.7853V80.4131Z" fill="#5C5B5B" />
									<path d="M70.3732 80.4131C70.3732 78.1217 72.2307 76.2642 74.5221 76.2642C76.8134 76.2642 78.671 78.1217 78.671 80.4131V90.7853C78.671 93.0767 76.8134 94.9342 74.5221 94.9342C72.2307 94.9342 70.3732 93.0767 70.3732 90.7853V80.4131Z" fill="#5C5B5B" />
									<path fillRule="evenodd" clipRule="evenodd" d="M48.2837 94.2826C52.6346 92.6409 57.4799 93.6088 59.2658 94.2826C60.3377 94.6871 61.5345 94.146 61.939 93.074C62.3434 92.0021 61.8024 90.8053 60.7304 90.4008C58.3674 89.5092 52.4255 88.2854 46.8191 90.4008C45.7472 90.8053 45.2061 92.0021 45.6105 93.074C46.015 94.146 47.2118 94.6871 48.2837 94.2826Z" fill="#5C5B5B" />
									<path d="M45.4789 30.626C45.4789 27.4754 48.033 24.9213 51.1836 24.9213C54.3343 24.9213 56.8884 27.4754 56.8884 30.626V43.0727C56.8884 46.2233 54.3343 48.7774 51.1836 48.7774C48.033 48.7774 45.4789 46.2233 45.4789 43.0727V30.626Z" fill="#5C5B5B" />
									<path d="M61.039 33.2191C62.1846 33.2191 63.1134 34.1479 63.1134 35.2936C63.1134 36.4393 62.1846 37.368 61.039 37.368L41.3317 37.368C40.186 37.368 39.2572 36.4393 39.2572 35.2936C39.2572 34.1479 40.186 33.2191 41.3317 33.2191L61.039 33.2191Z" fill="#5C5B5B" />
									<path fillRule="evenodd" clipRule="evenodd" d="M50.6662 8.3257C46.0834 8.3257 42.3684 12.0407 42.3684 16.6235V19.2121C42.3684 22.0993 43.5153 24.8683 45.5569 26.9099L48.9324 30.2854L43.0649 36.1528L39.6895 32.7774C36.0917 29.1796 34.0706 24.3001 34.0706 19.2121V16.6235C34.0706 7.458 41.5007 0.0278931 50.6662 0.0278931V8.3257Z" fill="#5C5B5B" />
									<path fillRule="evenodd" clipRule="evenodd" d="M50.6634 8.3257C55.2462 8.3257 58.9612 12.0407 58.9612 16.6235V19.2121C58.9612 22.0993 57.8143 24.8683 55.7727 26.9099L52.3972 30.2854L58.2647 36.1528L61.6401 32.7774C65.2378 29.1796 67.259 24.3001 67.259 19.2121V16.6235C67.259 7.458 59.8289 0.0278931 50.6634 0.0278931V8.3257Z" fill="#5C5B5B" />
									<path d="M93.7086 126.569V104.788C68.1483 111.263 41.4107 111.536 15.7235 105.584L12.2864 104.788V126.569L15.8236 126.009C41.6398 121.916 67.954 122.105 93.7086 126.569Z" fill="#000E4B" />
								</svg>
								:
								<svg width="104" height="145" viewBox="0 0 104 145" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M89.2014 48.7774H14.5212C11.0841 48.7774 8.2978 51.5637 8.2978 55.0008V129.681C8.2978 133.118 11.0841 135.904 14.5212 135.904H89.2014C92.6384 135.904 95.4247 133.118 95.4247 129.681V55.0008C95.4247 51.5637 92.6384 48.7774 89.2014 48.7774ZM14.5212 40.4796C6.50134 40.4796 0 46.981 0 55.0008V129.681C0 137.701 6.50134 144.202 14.5212 144.202H89.2014C97.2212 144.202 103.723 137.701 103.723 129.681V55.0008C103.723 46.981 97.2212 40.4796 89.2014 40.4796H14.5212Z" fill="#5C5B5B" />
									<path d="M27.4854 80.4131C27.4854 78.1217 29.3429 76.2642 31.6343 76.2642C33.9256 76.2642 35.7832 78.1217 35.7832 80.4131V90.7853C35.7832 93.0767 33.9256 94.9342 31.6343 94.9342C29.3429 94.9342 27.4854 93.0767 27.4854 90.7853V80.4131Z" fill="#5C5B5B" />
									<path d="M69.4951 80.4131C69.4951 78.1217 71.3526 76.2642 73.644 76.2642C75.9354 76.2642 77.7929 78.1217 77.7929 80.4131V90.7853C77.7929 93.0767 75.9354 94.9342 73.644 94.9342C71.3526 94.9342 69.4951 93.0767 69.4951 90.7853V80.4131Z" fill="#5C5B5B" />
									<path fillRule="evenodd" clipRule="evenodd" d="M58.3868 89.3639C54.0359 91.0055 49.1906 90.0377 47.4047 89.3639C46.3328 88.9594 45.136 89.5005 44.7315 90.5725C44.3271 91.6444 44.8682 92.8412 45.9401 93.2457C48.3032 94.1373 54.245 95.361 59.8514 93.2457C60.9234 92.8412 61.4645 91.6444 61.06 90.5725C60.6556 89.5005 59.4587 88.9594 58.3868 89.3639Z" fill="#5C5B5B" />
									<path d="M44.6008 30.626C44.6008 27.4754 47.1549 24.9213 50.3056 24.9213C53.4562 24.9213 56.0103 27.4754 56.0103 30.626V43.0727C56.0103 46.2233 53.4562 48.7774 50.3056 48.7774C47.1549 48.7774 44.6008 46.2233 44.6008 43.0727V30.626Z" fill="#5C5B5B" />
									<path d="M60.1609 33.2191C61.3066 33.2191 62.2354 34.1479 62.2354 35.2936C62.2354 36.4393 61.3066 37.368 60.1609 37.368L40.4536 37.368C39.3079 37.368 38.3792 36.4393 38.3792 35.2936C38.3792 34.1479 39.3079 33.2191 40.4536 33.2191L60.1609 33.2191Z" fill="#5C5B5B" />
									<path fillRule="evenodd" clipRule="evenodd" d="M49.7881 8.3257C45.2054 8.3257 41.4903 12.0407 41.4903 16.6235V19.2121C41.4903 22.0993 42.6373 24.8683 44.6789 26.9099L48.0543 30.2854L42.1869 36.1528L38.8114 32.7774C35.2137 29.1796 33.1925 24.3001 33.1925 19.2121V16.6235C33.1925 7.458 40.6226 0.0278931 49.7881 0.0278931V8.3257Z" fill="#5C5B5B" />
									<path fillRule="evenodd" clipRule="evenodd" d="M49.7855 8.3257C54.3682 8.3257 58.0833 12.0407 58.0833 16.6235V19.2121C58.0833 22.0993 56.9363 24.8683 54.8947 26.9099L51.5193 30.2854L57.3867 36.1528L60.7622 32.7774C64.3599 29.1796 66.3811 24.3001 66.3811 19.2121V16.6235C66.3811 7.458 58.951 0.0278931 49.7855 0.0278931V8.3257Z" fill="#5C5B5B" />
									<path d="M92.8305 126.569V104.788C67.2703 111.263 40.5326 111.536 14.8455 105.584L11.4083 104.788V126.569L14.9456 126.009C40.7617 121.916 67.0759 122.105 92.8305 126.569Z" fill="#000E4B" />
								</svg>
						}

						<div className="divText">
							{
								set_quantidade_emissoes_relatorios && 10 - (set_quantidade_emissoes_relatorios.fisioterapeutico +
									set_quantidade_emissoes_relatorios.multifatorial)
									<= 0 ?
									<>
										<p className="text4">
											{current_language?.relatorios_acabaram} :(
										</p>
										<p className="text5">
											{current_language?.adquira_novo_pacote_relatorio}.
										</p>
									</>
									:
									<>
										<p className="text">
											{current_language?.ainda_restam}
										</p>
										<p className="text3">
											<span className="num">{
												set_quantidade_emissoes_relatorios &&
												10 - (set_quantidade_emissoes_relatorios.fisioterapeutico +
													set_quantidade_emissoes_relatorios.multifatorial)
											}</span> de 10
										</p>
										<p className="text2">
											{current_language?.emissoes_gratis}
										</p>
									</>
							}
						</div>
						<button className="button upgrade" onClick={upgrade}>UPGRADE</button>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalUpgradeRelatorios);
