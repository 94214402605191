import styled from "styled-components";
import { Colors } from "../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	padding-left: 3.1rem;
	padding-right: 3.1rem;
	position: relative;
	z-index: 1;
	background-color: #fafafa;
`;

export const Title = styled.div`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 1.375rem;
	line-height: 120%;

	margin-bottom: 1.367rem;

	color: ${Colors.primary_blue_k_main};
`;

export const ContentType = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: flex-start;

	justify-content: center;
	align-items: center;

	.btns_type {
		display: flex;
		justify-content: center;
		button {
			margin: 4rem 1rem;
		}
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 0px;
		height: 0px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.head {
		display: flex;
		flex-direction: column;
	}

	.header {
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-bottom: 1rem;
	}

	.divFoto {
		width: 6.25rem;
		background-color: white;
	}

	.imgForInput {
		width: 6.25rem;
		height: 6.25rem;
		border-radius: 50%;
		object-fit: cover;
	}

	.dados {
		display: flex;
		flex-direction: column;
		width: 100%;
		background-color: white;
	}

	.divNome {
		display: flex;
		padding-left: 2.1rem;
		width: 100%;
		height: 50%;
		background-color: white;
		align-items: center;
	}

	.nome {
		color: #5c5b5b;
		font-size: 1.37rem;
		font-weight: 700;
	}

	.caracteristicas {
		display: flex;
		flex-direction: row;
		padding-left: 2.1rem;
		width: 100%;
		height: 50%;
		align-items: flex-start;
	}

	.containerEdit{
		display:flex;
		width: 190px;
		height:30px;
		flex-direction: row;
		justify-content: flex-end;
	}

	.containerIconEditUser{
		margin-right:70px;
		border: none;
		background-color: transparent;
	}

	.containerIconDeleteUser{
		margin-right:20px;
		border: none;
		background-color: transparent;
	}

	.headItem {
		margin-right: 3.688rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.crt {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
		line-height: 120%;

		color: #404040;
	}

	.value {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: .75rem;
		line-height: 120%;

		text-align: center;

		color: #404040;
	}

	.nav {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		height: 4.5rem;
		background-color: white;
		margin-bottom: 1.3rem;
		z-index: 20;
	}

	.selecao {
		height: 100%;
		font-size: 1.25rem;
		margin-right: 2.6rem;
		color: #001574;
		font-weight: 500;
		background-color: transparent;
		border-bottom: 3px solid #001574;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.selecao2 {
		height: 100%;
		font-size: 1.25rem;
		margin-right: 2.6rem;
		color: #5c5b5b;
		font-weight: 500;
		background-color: transparent;
		border-bottom: none;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.hr {
		margin-top: -1.3rem;
		width: 100%;
		border: none;
		height: 1px;
		background-color: #e0e0e0;
		z-index: 20;
	}
`;

export const HeaderContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;
export const ContainerGoBack = styled.div`
	min-width: 15%;
	align-self: flex-start;
	display: flex;
	align-items: center;
	flex-direction: row;
	cursor: pointer;
`;
export const PageName = styled.p`
	font-family: "NotoSans-regular";
	font-size: 1.125rem;
	font-weight: 400;
	color: ${Colors?.primary_blue_k_main};
	margin-left: 0.2rem;
`;
export const ContainerInfoPatient = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 1.97rem;
	position: relative;
`;
export const ContainerImage = styled.div`
	display: flex;
	width: 2.437rem;
	height: 2.437rem;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: ${Colors?.primary_blue_k_main};
`;
export const Image = styled.img`
	object-fit: cover;
	display: flex;
	width: 2.437rem;
	height: 2.437rem;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
`;
export const PatientName = styled.p`
	font-family: "NotoSans-regular";
	font-size: 1rem;
	font-weight: 700;
	color: ${Colors?.primary_blue_k_main};
	margin-left: 0.75rem;
`;
export const ContainerTags = styled.div`
	max-width: 80%;
	display: flex;
	flex-direction: row;
`;
export const Tag = styled.div`
	display: flex;
	padding: 0.25rem 0.625rem;
	justify-content: center;
	align-items: center;
	margin-left: 0.75rem;
	gap: 0.625rem;
	border-radius: 1.875rem;
	background: ${Colors?.secondary_green_k_light_2};
`;
export const TagName = styled.p`
	color: ${Colors?.primary_blue_k_main};
	text-align: center;
	font-family: "NotoSans-regular";
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; 
`;

export const ContainerDot = styled.div`
	display: flex;
	width: 2rem;
	height: 2rem;
	border-radius: 1rem;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;

	&:hover{
		background-color: ${Colors?.secondary_main_hover};
		.dot3 {
			color: ${Colors?.white};
		}
	}
	&:active{
		.dot3 {
			color: ${Colors?.white};
		}
		background-color: ${Colors?.primary_blue_k_main};
	}
`;
export const ContainerInfoEvaluator = styled.div`
	width: 100%;
	display: flex;
	margin-top: 1.31rem;
	align-items: flex-end;
	flex-direction: row;
`;
export const InfoEvaluator = styled.div`
	padding-right: 0.5rem;
	color: ${Colors?.neutral_grey_k_60};
	font-family: "NotoSans-regular";
	font-size:1rem;
	font-style: normal;
	font-weight: 400;
	border-right: 1px solid ${Colors?.neutral_grey_k_60}
`;

export const ShowInfo = styled.p`
	color: ${Colors?.neutral_grey_k_80};
	font-family: "NotoSans-regular";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 1.5rem */
	text-decoration-line: underline;
	margin-left: 0.34rem;
	cursor: pointer;
`;

export const ContainerOptions = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	margin-top: 3rem;
	min-height: 3rem;
`;

export const Option = styled.div<{OptionSelected:string, title: string}>`
	display: flex;
	width: 50%;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	border-bottom: 2px solid ${({OptionSelected, title})=> OptionSelected === title?.toLowerCase() 
		? Colors.secondary_green_k_main 
		:  Colors?.neutral_grey_k_10 
	};
	
	.selectTitle{
		font-weight:${({OptionSelected, title})=>  OptionSelected === title?.toLowerCase() ? 700 : 400}
	}
	
`;
export const TextOption = styled.p`
	text-align: center;
	font-family: "NotoSans-regular";
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	color: ${Colors?.primary_blue_k_main};
`;

export const ContainerOptionsButtonsQuestionari = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-around;
	margin-top: 5rem;
`;
export const ButtonOptionQuetionary = styled.div`
	display: flex;
	flex-direction: row;
	padding: 0.5rem;
	padding-left: 3rem;
	padding-right: 3rem;

	border-radius: 0.875rem;
	background-color: ${Colors?.neutral_grey_k_10};
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		background-color: ${Colors?.neutral_grey_k_20};
	}
	&:active {
		background-color: ${Colors?.neutral_grey_k_30};
	}

`;
export const TitleButton = styled.p`
	color: ${Colors?.primary_blue_k_main};
	font-family:  "NotoSans-regular";
	font-size: 1.0rem;
	font-style: normal;
	margin-left: 0.5rem;
	font-weight: 500;
	line-height: 150%; 
`;

export const ContainerOptionCard = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	
	justify-content: space-between;
	flex-direction: row;
	margin-bottom: 3rem;
`;
export const CardOption = styled.div<{index: number, width?: number | string, aling?: string}>`
	width: ${({width})=> width ? width : 50}%;
	display: flex;
	padding: 0rem 0.5rem;
	align-items: center;
	flex-direction: row;
	cursor: pointer;
	border-right: ${`1px solid ${Colors?.neutral_grey_k_20}`};
	margin-top: 3rem;

	&:hover {
		background-color: ${Colors?.neutral_grey_k_10}
	}
	
	&:active {
		background-color: ${Colors?.neutral_grey_k_20}
	}

`;
export const ContaierInfos = styled.div<{align?: 'center', width?: number| string}>`
	width: ${({width})=> width ?  width : 75}%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: ${({align})=> align ? align : 'flex-start'};
	margin-left: 0.5rem;

	.type{
		color: ${Colors?.neutral_grey_k_60};
		font-family:  "NotoSans-regular";
		font-size: 1.0rem;
		font-style: normal;
		font-weight: 400;
		line-height: 150%; 
		margin-top: 0.88rem;
		text-align: ${({align})=> align ? align : 'flex-start'};
	}
	.title {
		color: ${Colors?.primary_blue_k_main};
		font-family:  "NotoSans-regular";
		font-size: 1.3rem;
		font-style: normal;
		font-weight: 700;
		line-height: 150%; 
		text-align: ${({align})=> align ? align : 'flex-start'};
	}
	.subtitle {
		color: ${Colors?.neutral_grey_k_60};
		font-family: "NotoSans-regular";
		font-size: 1.0rem;
		font-style: normal;
		font-weight: 400;
		line-height: 150%; 
		text-align: ${({align})=> align ? align : 'flex-start'};
	}

`;
