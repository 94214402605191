import styled from "styled-components";

export const AreaText = styled.textarea`
	width: 22.875rem;
	height: 12.063rem;
	min-height: 12.063rem;
	min-width: 22.875rem;

	background-color: #EDEDED;

	padding: .4rem 0;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 120%;

	border: 0;

	border-bottom: 1px solid #404040;

	color: #606060;
	
  ::placeholder,
  ::-webkit-input-placeholder {
		color: #B3B3B3;
  }
  :-ms-input-placeholder {
		color: #B3B3B3;
  }
`;

export const Area = styled.div`
	display: flex;
	align-items: flex-end;
	.contador {
		margin-left: -4.5rem;
		margin-bottom: .5rem;
		color: #606060;
	}
`;