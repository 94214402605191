const handleMaskDate = (e: React.FormEvent<HTMLInputElement>) => {
	e.currentTarget.maxLength = 10;
	const inputVale = e.currentTarget.value;

	let dateMask = inputVale.replace(/\D/g, "");
	dateMask = dateMask.replace(/(\d{2})(\d)/, "$1/$2");
	dateMask = dateMask.replace(/(\d{2})(\d)/, "$1/$2");

	e.currentTarget.value = dateMask;

	return e.currentTarget.value;
};

export default handleMaskDate;
