import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Menu from "../../../../components/Menu";
import ContentDash from "../../../DashPaciente/ContentDash";
import BarSuperior from "../../../../components/SuperiorBar";



const FaturasController: React.FC = () => {

	const is_open_inadimplente = useSelector(
		(state: any) => state.app.is_open_modal_inadimplente
	);

	return (
		<>
			<BarSuperior />
			<ViewPort>
					<Menu/>
				<ContentDash listagem={"fatura"} />
			</ViewPort>
		</>
	);
};

export const ViewPort = styled.div`
	display: flex;
	height: 90vh;
	width: 100%;
	overflow-y: hidden;

	@media only screen and (max-height: 650px) {
		overflow-y: auto;
	} 
`;

export default FaturasController;
