import styled from "styled-components";

export const AreaRadioButtons = styled.div`
	width: 359px;
`;

export const Radio = styled.div`
	width: 1.4rem;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 1.6rem;

	label {
		cursor: pointer;
		color: #404040;
	}

	.custom-checkbox {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.custom-checkbox input + .label2:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		background-color: #EDEDED;
		border: 2px solid #E4E3E3;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		display: flex;
		align-items: center;
		transition: all .05s linear;
	}

	.custom-checkbox input:checked + .label2:before {
		width: .5rem;
		height: .5rem;
		background-color: #EDEDED;
		border: .4rem solid #001574;
		cursor: pointer;
		display: flex;
		align-items: center;
	}
`;

export const RadioDiv = styled.div`
	margin: 0 .3rem;
	display: flex;
	justify-content: space-evenly;
`;