import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import UltimosAcessados from "./UltimosAcessados";
import ListagemPacientes from "./ListagemPacientes/ListagemPacientes";
import { TextsProps } from "../../database/txt.interface";
import { makeStyles } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import PrincipalAddButton from "./PrincipalAddButton/PrincipalAddButton";
import { MudarPagina } from "../../utils/funcs";
import { DashBoardActions } from "../../store/ducks/dashboard";
import InputPesquisaPaciente from "./InputPesquisaPaciente/InputPesquisaPaciente";
import { ContainerDash, DropDown, Icon, MenuOption } from "../../styles/global";
import { AtribuirActions } from "../../store/ducks/atribuir";
import { AppActions } from "../../store/ducks/app";
import Select from "../../assets/icons/select.svg";
import { P2Desk } from "../../styles/styles_text";
import Toogle from "../../components/Toogle";
import ToastAlert from "../../components/toast";
import Loading from "./Loading";

type typeOptions =
	| "excluir"
	| "condicao_fisica"
	| "avaliador"
	| "etiqueta"
	| "";

const Pacientes: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const search = useSelector((state: any) => state.dashboard.search);

	const option: typeOptions = useSelector(
		(state: any) => state.atribuir.menu_option_selected
	);

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);

	const pagina_atual = useSelector(
		(state: any) => state.dashboard.current_page_patient
	);

	const total_pacientes = useSelector(
		(state: any) => state.dashboard.total_pacientes
	);

	const numero_de_paginas = Math.ceil(total_pacientes / 6);

	const pacientes_selecionados = useSelector(
		(state: any) => state.app.lista_pacientes
	);

	const useStyles = makeStyles(() => ({
		root: {
			"& .MuiPagination-ul": {
				justifyContent: "left",
			},
			"& .MuiPaginationItem-root": {
				color: "black",
				"&:hover": {
					backgroundColor: "#000E4B",
					color: "white",
				},
			},
			"& .MuiPagination-outlined": {
				backgroundColor: "#000E4B",
			},
			"& .Mui-selected": {
				backgroundColor: "#000E4B",
				color: "#000E4B",
				"&:hover": {
					color: "black",
				},
			},
		},
	}));

	const classes = useStyles();

	const handleOptionsMenu = (option: typeOptions, event: any) => {
		event.stopPropagation();
		if (option == "etiqueta") {
			dispatch(AppActions.set_modal_atribuir_etiqueta(true));
		}
		if (option == "condicao_fisica") {
			dispatch(AppActions.set_modal_atribuir_fisica(true));
		}
		if (option == "avaliador") {
			dispatch(AppActions.set_modal_atribuir_avaliador(true));
		}
		if (option == "excluir") {
			dispatch(AppActions.set_modal_excluir_paciente(true));
		}
	};

	const traducoes_botoes: Record<typeOptions, string | undefined> = {
		excluir: current_language?.excluir_paciente,
		condicao_fisica: current_language?.atribuir_cond_fisica,
		avaliador: current_language?.atribuir_avaliador,
		etiqueta: current_language?.atribuir_etiqueta,
		"": "",
	};

	const [controler_menu, set_controler_menu] = useState(false);


	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	return (
		<ContainerDash>
			{is_open_toast && <ToastAlert />}
			<UltimosAcessados />
			<InputPesquisaPaciente />
		
			{pacientes?.length > 0 ? (
				<ListagemPacientes />
			) : pacientes == null ? (
				<div style={{ width: "60%" }}>
					<Loading />
				</div>
			) : (
				<NenhumPaci>{current_language?.adicione_para_comecar}</NenhumPaci>
			)}
			<PaginationAndAddButton>
				<Pagination
					onChange={(event, pagina_desejada) => {
						MudarPagina(
							pagina_desejada,
							numero_de_paginas,
							dispatch,
							[3],
							search
						);
						dispatch(DashBoardActions.set_number_page(pagina_desejada));
					}}
					page={pagina_atual}
					className={classes.root}
					size="large"
					count={numero_de_paginas}
				/>
				{option == "" && <PrincipalAddButton />}

				<div style={{ display: "flex", flexDirection: "row" }}>
					{option != "" && (
						<ButtomCancel
							onClick={() => {
								dispatch(AtribuirActions.set_menu_option_selected(""));
								dispatch(AppActions.set_lista_pacientes([]));
							}}
						>
							{current_language?.botao_cancelar}
						</ButtomCancel>
					)}

					{option != "" && (
						<Buttom
							active={pacientes_selecionados.length > 0 ? true : false}
							background={option == "excluir" ? "#DC8D7C" : "#AEA9B4"}
							backgroundActive={option == "excluir" ? "#D12600" : "#001574"}
							hover={option == "excluir" ? "#A81F00" : "#000E4B"}
							onClick={(event) =>
								pacientes_selecionados.length > 0
									? handleOptionsMenu(option, event)
									: false
							}
						>
							{traducoes_botoes[option]}
						</Buttom>
					)}
				</div>
			</PaginationAndAddButton>
		</ContainerDash>
	);
};

const NenhumPaci = styled.div`
	width: 60%;
	font-family: "NotoSans-Regular";
	font-size: 1rem;
	font-weight: 400;
	text-align: left;
	margin-top: 4vh;
	color: #797d8d;
`;

const PaginationAndAddButton = styled.div`
	position: fixed;
	bottom: 0;
	width: 56%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 1.5rem;
	padding-top: 0.5rem;
	background-color: #fafafa;
`;

interface ActiveProps {
	active: boolean;
	backgroundActive: string;
	background: string;
	hover: string;
}

const Buttom = styled.div<ActiveProps>`
	padding: 0.8rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	background-color: ${({ active, background, backgroundActive }) =>
		active ? backgroundActive : background};
	border-radius: 45px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: #fafafa;
	font-family: "NotoSans-regular";
	font-size: 1rem;
	font-weight: 700;
	text-align: left;
	cursor: pointer;

	&:hover {
		background-color: ${({ hover }) => hover};
	}
`;

const ButtomCancel = styled.div`
	padding: 0.8rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	background-color: #dedfe3;
	border-radius: 45px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: #3d4259;
	font-family: "NotoSans-regular";
	font-size: 1rem;
	font-weight: 700;
	text-align: left;
	cursor: pointer;
	border: 1px solid #3d4259;
	margin-right: 1rem;

	&:hover {
		background-color: #abadb7;
	}
`;

export default Pacientes;
