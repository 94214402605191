import styled from "styled-components";
import { Colors } from "../../styles/colors";

export const Container = styled.div`
	display: flex;
	width: 80%;
	height: 95%;
	flex-direction: column;

`;
export const Header = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`;

export const ContainerGoBack = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-top: 20px;
`;
export const ContainerTitle = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
`;

export const ContainerArrowIcon = styled.div`
	display: flex;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	align-items: center;
	justify-content: center;
	background-color: transparent;
`;

export const AddTags = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;

    &:hover ${ContainerArrowIcon} {
		.DeleteTagIcon {
			color: ${Colors.white}; 
		}
		background-color: ${ Colors?.tertirary_red_1_dark };
	}
    &:active ${ContainerArrowIcon} {
		.DeleteTagIcon {
			color: ${Colors.white}; 
		}
		background-color: ${ Colors?.primary_blue_k_main };
	}
	
`; 

export const Save = styled.button<{dataTagName: string, EditTag: string}>`
	cursor: ${({ EditTag, dataTagName }) => (EditTag === dataTagName ? 'not-allowed' : 'pointer')};
	opacity: ${({ EditTag, dataTagName }) => (EditTag === dataTagName ? 0.5 : 1)};
  	pointer-events: ${({ EditTag, dataTagName }) => (EditTag === dataTagName ? 'none' : 'auto')};
	color: ${({EditTag, dataTagName})=> EditTag != dataTagName ? Colors?.secondary_green_k_dark_2 : Colors?.neutral_grey_k_60};
	text-decoration: underline;
	text-decoration-color: ${({EditTag, dataTagName})=> EditTag != dataTagName ? Colors?.secondary_green_k_dark_2 : Colors?.neutral_grey_k_60};
	font-size: 16px;
	position: absolute;
	z-index: 10;
	right: 5px;
	bottom: 10px;
	font-family: "NotoSans-Regular";
	background: none;
  	border: none;
`;
export const ContainerCardUsers = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 380px;
	overflow-y: scroll;
	padding-bottom: 30px;
	
	::-webkit-scrollbar {
		width: 5px; 
	}
	::-webkit-scrollbar-track {
		background: transparent; 
	}
	::-webkit-scrollbar-thumb {
		background: transparent; 
		border-radius: 5px;
	}
	::-webkit-scrollbar-thumb{
		background: ${Colors?.neutral_grey_k_20};
	}

`;
export const CardUserInTag = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;
	position: relative;
`;

export const ContainerPhotoAndName = styled.div`
	display: flex;
	align-items: flex-start;
	width: 90%;
	height: 100%;
	
`;

export const Photo = styled.div`
	display: flex;
	height: 42px;
	width: 42px;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	background-color: ${Colors?.primary_blue_k_main};
`;

export const Img = styled.img`
	width: 42px;
	height: 42px;
	border-radius: 100%;
`;

export const ContainerTrash = styled.div`
	display: flex;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	position: absolute;
	align-items: center;
	justify-content: center;
	top: 0px;
	right: 0px;
	background-color: transparent;
	cursor: pointer;
	
	&:hover  {
		.DeleteTagInPatient {
			color: ${Colors.white}; 
		}
		background-color: ${ Colors?.tertirary_red_1_dark };
	}
    &:active  {
		.DeleteTagInPatient {
			color: ${Colors.white}; 
		}
		background-color: ${ Colors?.primary_blue_k_main };
	}

`;


