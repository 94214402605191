import React, { memo, useEffect, useState } from "react";
import { Radio, RadioCheck } from "./styles";
import { QuestionnaireProps } from '../../../../../interfaces/pacientes.interface';
import { useDispatch, useSelector } from 'react-redux';
import { PacAvalActions } from '../../../../../store/ducks/manage_pac_aval';
import TextInput from '../TextInput';
import { TextsProps } from '../../../../../database/txt.interface';

interface QuestionProps {
	item: QuestionnaireProps
}

const CheckBoxButton: React.FC<QuestionProps> = ({ item }) => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const array_checkbox = useSelector(
		(state: any) => state.pac_aval.array_checkbox
	);

	const array_respostas = useSelector(
		(state: any) => state.pac_aval.array_respostas
	);
	const array_resposta = useSelector(
		(state: any) => state.pac_aval.array_resposta
	);

	useEffect(() => {
		dispatch(PacAvalActions.set_array_checkbox([]));
		const new_array = array_respostas?.filter((e: any) => e.QUESTIONARIO_LABEL === item.QUESTIONARIO_LABEL);
		new_array.length > 0 && dispatch(PacAvalActions.set_array_checkbox(new_array[0]?.QUESTIONARIO_RESPOSTA));
	}, [])

	const [item2, setItem2] = useState("")

	const setItem = (id: any, item: QuestionnaireProps) => {
		const array = array_checkbox;
		if (!array?.includes(id)) {
			array?.push(id);
		} else if (array?.includes(id)) {
			array?.splice(array.indexOf(id), 1);
		}
		setItem2(id)
		dispatch(PacAvalActions.set_array_checkbox(array));

		const dataObj = {
			"QUESTIONARIO_LABEL": `${item.QUESTIONARIO_LABEL}`,
			"QUESTIONARIO_RESPOSTA": array_checkbox
		}
		const new_array = array_respostas.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
		dispatch(PacAvalActions.set_array_respostas([...new_array, dataObj]));
		const new_resposta = array_resposta.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
		dispatch(PacAvalActions.set_array_resposta([...new_resposta, dataObj]));
	}

	useEffect(() => {
		setItem2("")
	}, [item2])

	function render_radio_button(text: string, id: number) {
		return (
			<Radio key={id}>
				<div className="custom-checkbox">
					<input
						id={`checkbox${item?.QUESTIONARIO_ORDEM}${id}`}
						checked={array_checkbox?.includes(id)}
						type="checkbox"
						style={{ display: "none" }}
						onChange={() => setItem(id, item)}
					/>
					<label
						className={item?.QUESTIONARIO_PERGUNTA_TIPO === "checkbox" ? "label" : "label2"}
						htmlFor={`checkbox${item?.QUESTIONARIO_ORDEM}${id}`}
					></label>
				</div>
				<label htmlFor={`checkbox${item?.QUESTIONARIO_ORDEM}${id}`}>
					{text}
				</label>
			</Radio>
		)
	}

	return (
		<RadioCheck style={{ flexDirection: `${item.QUESTIONARIO_OPCOES_LAYOUT !== "LADO_A_LADO" ? 'column' : 'row'}` }}>
			{item.QUESTIONARIO_PERGUNTA_OPCOES.map((obj, i) => render_radio_button(obj, i))}
			{
				array_checkbox?.includes(item.QUESTIONARIO_PERGUNTA_OPCOES.indexOf(item.QUESTIONARIO_PERGUNTA_OPCOES[item.QUESTIONARIO_PERGUNTA_OPCOES.length - 1])) &&
				<>
					{current_language?.quais}
					{item.QUESTIONARIO_PERGUNTA_COMPLEMENTO?.map((e, i) => <TextInput autoFocus={true} key={i} item={e} />)}
				</>
			}
		</RadioCheck>
	);
};

export default memo(CheckBoxButton);