import React from "react";
import styled from "styled-components";
import { ButtonAdd, ContainerDash, Icon } from "../../styles/global";
import AddIcon from "../../assets/icons/adicionar_paci.svg";
import { useDispatch, useSelector } from "react-redux";
import { DashBoardActions } from "../../store/ducks/dashboard";
import ListagemExercicios from "./ListagemExercicios/ListagemExercicios";
import { ExerciciosActions } from "../../store/ducks/exer";
import InputPesquisaExercicio from "./InputPesquisaExercicio/InputPesquisaExercicio";
import { TextsProps } from "../../database/txt.interface";

const Exercicios: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	return (
		<ContainerDash>
			<ContainerOptions>
				<Option
					onClick={() =>
						dispatch(DashBoardActions.set_current_page("protocolos"))
					}
				>
					{current_language?.menu_lateral_protocolos}
				</Option>
				<OptionSelected>{current_language?.exercicios}</OptionSelected>
			</ContainerOptions>
			<InputPesquisaExercicio />
			<ListagemExercicios />
			<PaginationAndAddButton>
				<ButtonAdd
					active={false}
					onClick={() =>
						dispatch(ExerciciosActions.set_open_modal_adicionar_exercicio(true))
					}
				>
					<Icon style={{ width: "1.6rem", height: "1.6rem" }} src={AddIcon} />
					{current_language?.adicionar_exercicio}
				</ButtonAdd>
			</PaginationAndAddButton>
		</ContainerDash>
	);
};

const ContainerOptions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 60%;
`;

const Option = styled.text`
	font-family: "NotoSans-regular";
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 38.4px;
	border-bottom: 3px solid #dedfe3;
	color: #000e4b;
	padding-right: 1.5rem;
`;

const OptionSelected = styled.text`
	font-family: "NotoSans-regular";
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 38.4px;
	border-bottom: 3px solid #00d084;
	color: #000e4b;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
`;

const PaginationAndAddButton = styled.div`
	position: fixed;
	bottom: 0;
	width: 56%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding-bottom: 1.5rem;
	padding-top: 0.5rem;
	background-color: #fafafa;
`;

export default Exercicios;
