import styled from "styled-components";
import { Colors } from "../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;
	overflow-x: hidden;	

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 4px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 4px;
	}

	.header {
		display: flex;
		flex-direction: column !important;
		width: 100% !important;
		align-items: center;
		padding-top: 3.6rem;

		.divDropdown {
			display: flex;
			width: 100%;
			flex-direction: column !important;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 2.3rem;

			.divBtnAfterDrop {
				display: flex;
				flex-direction: row;

				.btnShare {
					font-family: Roboto;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 21px;
					display: flex;
					align-items: center;
					text-align: center;
					color: ${Colors.primary_blue_k_main};
					background-color: transparent;
					border: none;
					margin-right: 1.8rem;

					.share {
						margin-right: 5px;
					}
				}

				.btnCale {
					display: flex;
					padding: 0.37rem;
					font-family: Roboto;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 16px;
					color: #5c5b5b;
					align-items: flex-end;
					border: 1px solid #e0e0e0;
					box-sizing: border-box;
					border-radius: 4px;
					background-color: transparent;

					.calen {
						margin-right: 0.5rem;
					}
				}
			}
		}
	}

	.btns {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100% !important;

		.typeReport {
			font-family: Roboto;
			width: 11.18rem;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: #b3b3b3;
			border: 0.4px solid #b3b3b3;
			box-sizing: border-box;
			border-radius: 5px;
			padding: 0.5rem;
			margin-right: 0.75rem;
			background-color: transparent;
		}

		.typeReportActive {
			font-family: Roboto;
			width: 11.18rem;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: ${Colors.primary_blue_k_dark_2};
			border: 0.4px solid ${Colors.primary_blue_k_dark_2};
			box-sizing: border-box;
			border-radius: 5px;
			padding: 0.5rem;
			margin-right: 0.75rem;
			background-color: transparent;
		}
	}
`;

export const Content = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 2rem;

	position: relative;

	.bloqueio {
		border-radius: 1rem;

		z-index: 100000;

		width: 100%;

		position: absolute;
		left: 0;
		top: 0;
		backdrop-filter: blur(3px);
		background: rgba(0, 0, 0, 0.2);

		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
`;

export const MenuBtnRelatorios = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const MenuTopRelatorios = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 2.188rem;

	.svg {
		width: 40%;
	}

	h3 {
		width: 20%;

		text-align: center;

		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 25.4356px;
		line-height: 34px;

		color: ${Colors.primary_blue_k_main};
	}
`;

export const BtnAux = styled.div`
	display: flex;
	justify-content: flex-end;

	width: 40%;

	.btnShare {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		display: flex;
		align-items: center;
		text-align: center;
		color: ${Colors.primary_blue_k_main};
		background-color: transparent;
		border: none;
		margin-right: 1.8rem;

		.share {
			margin-right: 5px;
		}
	}
	.btnShareDisable {
		color: #b3b3b3;
		cursor: auto;
	}
`;
