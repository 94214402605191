import moment from "moment";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { TextsProps } from "../../database/txt.interface";
import { UserProps } from "../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "../../utils/types";
import { AnotationActions, Types } from "../ducks/anotacoes";
import { AppActions } from "../ducks/app";
import { DashBoardActions } from "../ducks/dashboard";
import * as Sentry from "@sentry/react";

function* saveAnotation({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const current_language: TextsProps = yield select(
		(state: any) => state.configs.current_language
	);

	async function apiCall() {
		try {
			const dataRequest = {
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				...payload,
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}anotacoes/anotacoes_adicionar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		let message: any = "";
		if (data.status == TypesErrorRequest.FAIL) {
			if (data.code == 1) {
				message = current_language?.adicao_falhou;
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			//msg de sucesso
			if (data.code == 0) {
				message = current_language?.adicao_concluida;
			}
			yield put(DashBoardActions.anotacoes_request());
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: message,
				})
			);
		}
	} catch (e) {
		Sentry.captureMessage("CAIU NO CATCH: anotacoes_adicionar.php", "error");
	} finally {
		yield put(AnotationActions.add_anotation_request_cancel());
	}
}

function* editAnotation({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const current_language: TextsProps = yield select(
		(state: any) => state.configs.current_language
	);

	async function apiCall() {
		try {
			const dataRequest = {
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				...payload,
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}anotacoes/anotacoes_editar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		let message: any = "";
		if (data.status == TypesErrorRequest.FAIL) {
			if (data.code == 1) {
				message = current_language?.edicao_falhou;
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			//msg de sucesso
			if (data.code == 0) {
				message = current_language?.edicao_completa;
			}
			yield put(DashBoardActions.anotacoes_request());
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: message,
				})
			);
		}
	} catch (e) {
		Sentry.captureMessage("CAIU NO CATCH: anotacoes_editar.php", "error");
	} finally {
		yield put(AnotationActions.edit_anotation_request_cancel());
	}
}

function* DeleteAnotation({ payload }: any): any {
	const current_language: TextsProps = yield select(
		(state: any) => state.configs.current_language
	);


	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	async function apiCall() {
		try {
			const dataRequest = {
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				...payload,
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}anotacoes/anotacoes_desativar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		let message: any = "";
		if (data.status == TypesErrorRequest.FAIL) {
			if (data.code == 1) {
				message = current_language?.desativacao_falhou;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			}
		} else {
			//msg de sucesso
			if (data.code == 0) {
				message = current_language?.desativacao_concluida;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
				yield put(DashBoardActions.anotacoes_request());
			}
		}
	} catch (e) {
		Sentry.captureMessage("CAIU NO CATCH: anotacoes_desativar.php", "error");
	} finally {
		yield put(AnotationActions.delete_anotation_request_cancel());
	}
}

export function* AnotationSaga(): any {
	return yield all([
		takeLatest(Types.ADD_ANOTATION_REQUEST, saveAnotation),
		takeLatest(Types.EDIT_ANOTATION_REQUEST, editAnotation),
		takeLatest(Types.DELETE_ANOTATION_REQUEST, DeleteAnotation),
	]);
}
