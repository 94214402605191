import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppActions } from "../../store/ducks/app";
import { sleep } from "../../utils/timer";
import { TypesErrorRequest } from "../../utils/types";
import { Toast } from "./styles";
import { loadStorage } from "../../utils/base_async_storage";
import { AddPacAvalActions } from "../../store/ducks/add_pac_aval";
import { DashBoardActions } from "../../store/ducks/dashboard";

const ToastAlert: React.FC = () => {
	// comunica com redux
	const dispatch = useDispatch();

	// função que abre a modal, mais sobre ela em store/ducks/app.ts
	const message_toast = useSelector((state: any) => state.app.message_toast);
	const type_toast = useSelector((state: any) => state.app.type_toast);
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);


	const [contagem, setContagem] = React.useState<any>(5);
	const [enter, setEnter] = React.useState<any>(false);
	const [undoPatientdeleted, setUndoPatientDeleted] = useState<any>()

	React.useEffect(() => {
		async function beep() {
			setContagem(5);
			for (let i = 1; i <= 6; i++) {
				await sleep(400);
				setContagem(6 - i);
				await sleep(600);
			}
		}
		if (is_open_toast) {
			beep();
		}
	}, [is_open_toast]);

	React.useEffect(() => {
		if (contagem === 5) {
			if (message_toast && message_toast?.includes("https") == false) {
				setEnter(true);
			}
		}
		if (contagem === 1) {
			setEnter(false);
		}
		if (contagem === 0) {
			setTimeout(() => {
				dispatch(AppActions.clear_toast_visible());
			}, 550);
		}
	}, [contagem]);


	async function getInfoUndopatientDeleted(){
		const undo_deleted_patient  = await loadStorage('undo_action_patient_delete')
		setUndoPatientDeleted(undo_deleted_patient)
	};

	useEffect(()=> {
		getInfoUndopatientDeleted()
	},[message_toast?.includes("DESFAZER")])

	const handleRequest = ()=> {
		dispatch(AddPacAvalActions.set_flag_filtro(false));
		dispatch(AddPacAvalActions.request_recuperar_paci(undoPatientdeleted)) 
		dispatch(DashBoardActions.paciente_request())
	};
	
	return (
		<Toast>
			<div
				onClick={()=> message_toast?.includes("DESFAZER") 
					? handleRequest()
					: {}
				}
				style={{
					cursor: message_toast?.includes("DESFAZER") ? 'pointer' : 'none'
				}}
				className={`${
					type_toast == TypesErrorRequest.SUCCESS
						? "success"
						: type_toast == TypesErrorRequest.FAIL && "fail"
				} ${enter ? `enter` : `down`}`}
			>
				{type_toast == TypesErrorRequest.SUCCESS ? (
					<svg
						width="18"
						height="14"
						viewBox="0 0 18 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6 11.17L1.83 6.99997L0.410004 8.40997L6 14L18 1.99997L16.59 0.589966L6 11.17Z"
							fill="white"
						/>
					</svg>
				) : (
					type_toast == TypesErrorRequest.FAIL && (
						<svg
							width="14"
							height="14"
							viewBox="0 0 14 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M11.6201 0.839966L6.99945 5.45997L2.37986 0.839966L0.839996 2.37997L5.45959 6.99997L0.839996 11.62L2.37986 13.16L6.99945 8.53996L11.6201 13.16L13.16 11.62L8.5404 6.99997L13.16 2.37997L11.6201 0.839966Z"
								fill="white"
							/>
						</svg>
					)
				)}
				{message_toast}
			</div>
		</Toast>
	);
};

export default ToastAlert;
