import moment from "moment";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { UserProps } from "../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "../../utils/types";
import { AppActions } from "../ducks/app";
import { SenhaActions, Types } from "../ducks/senha";
import * as Sentry from "@sentry/react";

function* editSenha({ payload }: any): any {
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			// let dataRequest:any = {};
			const dat = {
				...payload,
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}acesso/acesso_altera_senha.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.FAIL) {
			if (response.code == 1) {
				message = current_language?.senha_incorreta;
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			if (response.code == 0) {
				message = current_language?.alteracao_concluida;
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: message,
				})
			);
		}
	} catch (e) {
		Sentry.captureMessage("CAIU NO CATCH: acesso_cadastro.php", "error");
	} finally {
		yield put(SenhaActions.edit_senha_finally());
	}
}

export function* SenhaSaga(): any {
	return yield all([takeLatest(Types.EDIT_SENHA_PERFIL, editSenha)]);
}
