import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import bluetooth from "../../../assets/icons/bluetooth.svg";
import buttonClose from "../../../assets/icons/buttonClose.svg";
import dinamo from "../../../assets/icons/Dinamo.svg";
import lightConnect from "../../../assets/icons/lightConnect.svg";
import lightSearch from "../../../assets/icons/lightSearch.svg";
import localization from "../../../assets/icons/localiozation.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalConecta2: React.FC = () => {
	const dispatch = useDispatch();

	const classes = useStyles();
	const is_open_conecta2 = useSelector(
		(state: any) => state.app.is_open_conecta2
	);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_conecta2(false));
		window.history.back();
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_conecta2}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_conecta2}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<img src={dinamo} alt="Dinamometro" />
						<div className="divText">
							<p className="text">{current_language?.modal_conecta2}</p>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalConecta2;
