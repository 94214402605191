import { routerMiddleware } from "connected-react-router";
import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import history from "../routes/history";
import createRootReducer from "./ducks";
import sagas from "./sagas";

const middlewares = [routerMiddleware(history)];

const sagaMiddleware = createSagaMiddleware();

middlewares.push(sagaMiddleware);

const composer = compose(applyMiddleware(...middlewares));

const store = createStore(createRootReducer(history), composer);

sagaMiddleware.run(sagas);

export default store;
