import React from 'react';
import { TitleButton } from './style';
import { TitleButtonFormSubmiteProps } from './Models';
import { FieldError } from 'react-hook-form';

type Props = {
    textColor?: string;
    title?: string | Partial<FieldError> ;
};

const Text: React.FC<TitleButtonFormSubmiteProps & Props> = ({
    onClick,
    textColor,
    fontSize,
    textAlign,
    fontFamily,
    fontWeight,
    type,
    textDecoration,
    title,
    testID,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    cursor,
    width,
})=> {
    
    return (
        <TitleButton
            onClick={()=> {onClick && onClick()}}
            testID={testID}
            color={textColor}
            textDecoration={textDecoration}
            type={type}
            fontSize={fontSize}
            textAlign={textAlign}
            fontFamily={fontFamily}
            fontWeight={fontWeight}
            marginLeft={marginLeft}
            marginRight={marginRight}
            marginTop={marginTop}
            marginBottom={marginBottom}
            cursor={cursor}
            width={width}
        >
            {title}
        </TitleButton>
    )
};

export default Text;