import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Prancheta from "../../../assets/kinologyIcons/svg/prancheta.svg";
import { Icon } from "../../../styles/global";
import { P2Desk } from "../../../styles/styles_text";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { AnamneseActions } from "../../../store/ducks/anamnese";
import moment from "moment";

const AvaliacaoAnamnese: React.FC = () => {
	const dispatch = useDispatch();

	const relatorio_anamneses = useSelector(
		(state: any) => state.anamnese.relatorio_anamnese
	);

	interface TypeTopico {
		TITULO: string;
		VALOR: string;
	}
	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignContent: "center",
					width: "100%",
				}}
			>
				<UltimaAlteracao>{`Última alteração: ${moment(relatorio_anamneses.relatorio_anamnese.RELATORIO_ATUALIZADO).format("DD/MM/YYYY").toString()}` }</UltimaAlteracao>
				<VerHistorico
					onClick={() => {
						dispatch(
							DashBoardActions.set_current_page("lista_historico_anamnese")
						);
						dispatch(AnamneseActions.set_type_historico("avaliacao"));
					}}
				>
					Ver histórico
				</VerHistorico>
				{/* <AtualizarButtom>
					<Icon src={Prancheta} /> <text>Atualizar Informações</text>
				</AtualizarButtom> */}
			</div>
			{relatorio_anamneses.relatorio_anamnese.CONTEUDO.map(
				(item: TypeTopico, index: number) => (
					<div key={index} style={{ display: "flex", flexDirection: "column", width: "100%" }}>
						<Title>{item.TITULO}</Title>
						<Description>{item.VALOR}</Description>
					</div>
				)
			)}
		</>
	);
};

const AtualizarButtom = styled.div`
	padding: 0.5rem;
	border-radius: 0.875rem;
	background: var(--Neutras-Grey-K-10, #dedfe3);
	display: flex;
	flex-direction: row;
	align-items: center;

	text {
		color: var(--Primrias-Blue-K-Principal, var(--Color, #000e4b));
		font-family: "NotoSans-Regular";
		font-size: 0.6rem;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		margin-left: 0.5rem;
	}
`;

const Title = styled.text`
	color: var(--Primrias-Blue-K-Principal, var(--Color, #000e4b));
	/* P2-App */
	font-family: "NotoSans-Regular";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 1.6875rem */
	margin-top: 1rem;
`;

const Description = styled.text`
	color: var(--Neutras-Grey-K-80, #2a3048);
	font-family: "NotoSans-Regular";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 1.3125rem */
	margin-top: 1rem;
`;

const UltimaAlteracao = styled.text`
	color: var(--Neutras-Grey-K-50, #797d8d);
	text-align: center;
	/* Description-Desk */
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;
const VerHistorico = styled.text`
	color: var(--Neutras-Grey-K-80, #2a3048);
	text-align: center;
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 1.5rem */
	text-decoration-line: underline;
`;
export default AvaliacaoAnamnese;

