import React, { memo, useEffect, useState } from "react";
import { AreaText, Content, Radio } from "./styles";
import { QuestionnaireProps } from '../../../../../interfaces/pacientes.interface';
import { useDispatch, useSelector } from 'react-redux';
import { PacAvalActions } from '../../../../../store/ducks/manage_pac_aval';

interface questionProps {
	item: QuestionnaireProps,
}

const InputCheckbox: React.FC<questionProps> = ({ item }) => {
	const dispatch = useDispatch();
	const array_respostas = useSelector(
		(state: any) => state.pac_aval.array_respostas
	);
	const array_resposta = useSelector(
		(state: any) => state.pac_aval.array_resposta
	);

	const [value, setValue] = useState("N")

	useEffect(() => {
		const valor = array_respostas?.filter((e: any) => e?.QUESTIONARIO_LABEL === item?.QUESTIONARIO_LABEL);
		if (valor.length === 1) {
			setValue(valor[0].QUESTIONARIO_RESPOSTA[0])
		}
		handleTextInput(item, value)
	}, [])

	const handleTextInput = (item: QuestionnaireProps, text: string) => {
		if (text != "") {
			const dataObj = {
				"QUESTIONARIO_LABEL": `${item.QUESTIONARIO_LABEL}`,
				"QUESTIONARIO_RESPOSTA": [
					text
				]
			}
			const new_array = array_respostas.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
			const new_array_resposta = array_resposta.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
			dispatch(PacAvalActions.set_array_respostas([...new_array, dataObj]));
			dispatch(PacAvalActions.set_array_resposta([...new_array_resposta, dataObj]));
		} else {
			const new_array = array_respostas.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
			const new_array_resposta = array_resposta.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
			dispatch(PacAvalActions.set_array_respostas([...new_array]));
			dispatch(PacAvalActions.set_array_resposta([...new_array_resposta]));
		}
	}

	useEffect(() => {
		handleTextInput(item, value)
	}, [value])

	return (
		<Content>
			<p>{item?.QUESTIONARIO_PERGUNTA}</p>
			<Radio>
				<div className="custom-checkbox">
					<input
						id={`checkbox${item?.QUESTIONARIO_ORDEM}`}
						checked={value === 'S'}
						type="checkbox"
						style={{ display: "none" }}
						onChange={() => setValue(value !== "S" ? "S" : "N")}
					/>
					<label
						className={"label"}
						htmlFor={`checkbox${item?.QUESTIONARIO_ORDEM}`}
					></label>
				</div>
			</Radio>
		</Content>
	);
};

export default memo(InputCheckbox);