import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	width: 100%;
	height: 100%;
	padding-bottom: 5vh;
	align-items: center;
	padding-left: 3.6rem;
	padding-right: 3.6rem;
	background-color: white;
	border-radius: 1.25rem;
	overflow-y: auto;
	display: flex;
	flex-direction: column;

	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;
export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 40%;
`;
export const ContainerGoBack = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-top: 20px;
`;

export const ContainerPhoto = styled.div`
	display: flex;
	width: 128px;
	cursor: pointer;
	margin-top: 10px;
`;
export const IMG = styled.img`
	width: 100px;
	height: 100px;
	border-radius: 100%;
	cursor: pointer;
	object-fit: cover 
`;
export const ContainerTwoLabels = styled.div`
	display: flex ;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 32px;
`;

export const Header = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`;
export const ContainerIconText= styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	margin-top:42px;
	align-items: center;
	cursor: pointer;
`;






