import styled from "styled-components";
import { Colors } from "../../../styles/colors";
import { ReactElement } from "react";

export const Conteiner = styled.div`
	width: 100%;
	height: 100%;
	padding-bottom: 5vh;
	padding-left: 3.6rem;
	padding-right: 3.6rem;
	background-color: white;
	border-radius: 1.25rem;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;
export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
`;
export const Header = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`;
export const ContainerGoBack = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-top: 20px;
`;
export const ContainerInfoPlan = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	position: relative;
`;
export const ButtonUpgrade = styled.div`
	display: flex;
	position: absolute;
	right: 0px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 280px;
	height: 40px;
	padding: 12px 22px;
	margin-top: 10px;
	border-radius: 12px;
	background-color: ${Colors?.primary_blue_k_main};
	cursor: pointer;
`;
export const Item = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 12px;
	padding-bottom: 12px;
	width: 100%;
	border-bottom: 1px solid ${Colors?.neutral_grey_k_10};
	
`;
export const TitleItem = styled.p`
	font-family: "NotoSans-Medium";
	font-size: 18px;
	font-weight: 500;
	color: ${Colors?.neutral_grey_k_80};
`;
export const Subtitle = styled.p<{include: string |  ReactElement}>`
	font-family: "NotoSans-Medium";
	font-size: 18px;
	font-weight: 500;
	color: ${({include})=> typeof include == 'string' && include == 'Pacientes' ? Colors?.tertiary_orange_1_dark : Colors?.secondary_buttoon_green}; 
`;
