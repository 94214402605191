export const Types = {
	PACIENTE_REQUEST: "PACIENTE_REQUEST",
	PACIENTE_NOTIFICATION_REQUEST: "PACIENTE_NOTIFICATION_REQUEST",
	AVALIADORES_REQUEST: "AVALIADORES_REQUEST",
	PACIENTE_REQUEST_SUCCESS: "PACIENTE_REQUEST_SUCCESS",
	PACIENTE_NOTIFICATION_REQUEST_SUCCESS:
		"PACIENTE_NOTIFICATION_REQUEST_SUCCESS",
	PACIENTE_STOP_REQUEST: "PACIENTE_STOP_REQUEST",
	TOTAL_PACIENTES: "PACIENTES_TOTAIS",
	TOTAL_AVALIADORES: "TOTAL_AVALIADORES",
	PACIENTE_REQUEST_FILTROS: "PACIENTE_REQUEST_FILTROS",
	PACIENTE_NOTIFICATION_REQUEST_FILTROS:
		"PACIENTE_NOTIFICATION_REQUEST_FILTROS",
	AVALIADOR_REQUEST_FILTROS: "AVALIADOR_REQUEST_FILTROS",
	SER_AVALIADORES: "SER_AVALIADORES",

	PROTOCOLOS_REQUEST: "PROTOCOLOS_REQUEST",

	EXERCICES_REQUEST: "EXERCICES_REQUEST",

	EXECUCOES_REQUEST: "EXECUCOES_REQUEST",
	EXECUCOES_REQUEST_SUCCESS: "EXECUCOES_REQUEST_SUCCESS",
	EXECUCOES_REQUEST_LOADING: "EXECUCOES_REQUEST_LOADING",

	SET_USER_MASTER: "SET_USER_MASTER",

	PACIENTE_FILTERED: "PACIENTE_FILTERED",

	SET_DESATIVADOS: "SET_DESATIVADOS",
	SET_AGRUPAMENTOS: "SET_AGRUPAMENTOS",
	SET_SEARCH: "SET_SEARCH",

	SET_DROP_DOWN: "SET_DROP_DOWN",

	ETIQUETAS_REQUEST: "ETIQUETAS_REQUEST",
	ETIQUETAS_REQUEST_SUCCESS: "ETIQUETAS_REQUEST_SUCCESS",

	NOTIFICACOES_REQUEST: "NOTIFICACOES_REQUEST",
	NOTIFICACOES_REQUEST_SUCCESS: "NOTIFICACOES_REQUEST_SUCCESS",

	ASSIMETRIA_REQUEST: "ASSIMETRIA_REQUEST",
	DESEQUILIBRIO_REQUEST_SUCCESS: "DESEQUILIBRIO_REQUEST_SUCCESS",
	ASSIMETRIA_REQUEST_SUCCESS: "ASSIMETRIA_REQUEST_SUCCESS",
	ASSIMETRIA_REQUEST_LOADING: "ASSIMETRIA_REQUEST_LOADING",

	ANAMNESES_REQUEST: "ANAMNESES_REQUEST",
	ANAMNESES_REQUEST_SUCCESS: "ANAMNESES_REQUEST_SUCCESS",
	ANAMNESES_REQUEST_LOADING: "ANAMNESES_REQUEST_LOADING",

	ANOTACOES_REQUEST: "ANOTACOES_REQUEST",
	EXERCICIOS_AVALIADOS_REQUEST: "EXERCICIOS_AVALIADOS_REQUEST",
	ANOTACOES_REUQUEST_SUCCESS: "ANOTACOES_REUQUEST_SUCCESS",
	DINAMOS_REQUEST: "DINAMOS_REQUEST",
	DINAMOS_REQUEST_SUCCESS: "DINAMOS_REQUEST_SUCCESS",

	LOADING: "LOADING",

	BLOG: "BLOG",
	SET_BLOG: "SET_BLOG",

	PLANOS_REQUEST: "PLANOS_REQUEST",
	PLANOS_REQUEST_SUCCESS: "PLANOS_REQUEST_SUCCESS",

	SELECT_INFO: "SELECT_INFO",
	SET_BUTTON_SEARCH: "SET_BUTTON_SEARCH",
	SET_CURRENT_PAGE_PATIENT: "SET_CURRENT_PAGE_PATIENT",
	SET_CURRENT_PAGE_EVALUATOR: "SET_CURRENT_PAGE_EVALUATOR",
	SET_PACIENTES_MAIS_VISITADOS: "SET_PACIENTES_MAIS_VISITADOS",
	PACIENTES_MAIS_VISITADOS_REQUEST: "PACIENTES_MAIS_VISITADOS_REQUEST",
	CATEGORIAS_PROTOCOLOS: "CATEGORIAS_PROTOCOLOS",
	SET_CATEGORIAS_PROTOCOLOS: "SET_CATEGORIAS_PROTOCOLOS",
	ATRIBUIR_CATEGORIA_PROTOCOLOS: "ATRIBUIR_CATEGORIA_PROTOCOLOS",
	SEARCH_CATEGORIAS_ATRIBUIDAS: "SEARCH_CATEGORIAS_ATRIBUIDAS",
	GET_CATEGORIAS_ATRIBUIDAS: "GET_CATEGORIAS_ATRIBUIDAS",
	SEARCH_PROTOCOL_IN_STORAGE: "SEARCH_PROTOCOL_IN_STORAGE",
	SET_QUESTIONARIO_SATISFACAO_VERIFICAR:
		"SET_QUESTIONARIO_SATISFACAO_VERIFICAR",
	SET_INFO_VERIFICACAO_QUESTIONARIO_SATISFACAO:
		"SET_INFO_VERIFICACAO_QUESTIONARIO_SATISFACAO",
	SALVAR_QUESTIONARIO_SATISFACAO: "SALVAR_QUESTIONARIO_SATISFACAO",
	SET_QUANTIDADE_EXECUCOES: "SET_QUANTIDADE_EXECUCOES",
	GET_QUANTIDADE_EXECUCOES: "GET_QUANTIDADE_EXECUCOES",
	NUMBER_LISTAGEM_PAGE: "NUMBER_LISTAGEM_PAGE",
	SET_SEARCH_PROTOCOL: "SET_SEARCH_PROTOCOL",
	SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
	SET_MODAL_SATISFACTION_UNIFIED_REPORT: 'SET_MODAL_SATISFACTION_UNIFIED_REPORT',
};

const INITIAL_STATE = {
	pacientes: null,
	number_pages_listagem: 1,
	pacientes_notification: [],
	total_pacientes: 0,
	total_avaliadores: null,
	filtros_pacintes_request: {
		inicio: 0,
		fim: 6,
		filtros: [
			{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
			{ tipo: "USUARIO_NIVEL", valor: [3] },
		],
	},
	filtros_pacintes_notification_request: {
		inicio: 0,
		fim: 30,
		filtros: [
			{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
			{ tipo: "USUARIO_NIVEL", valor: [3] },
		],
	},
	filtros_avaliadores_request: {
		inicio: 0,
		fim: 200,
		filtros: [
			{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
			{ tipo: "USUARIO_NIVEL", valor: [1, 2] },
		],
	},
	avaliadores_request: false,
	execucoes: [],
	execucoes_excluidas: false,
	etiquetas: [],
	notificacoes: [],
	assimetria: [],
	desequilibrio: [],
	anamneses: [],
	anotacoes: [],
	dinamos: [],
	pacientes_loading: true,
	pacientes_notification_loading: true,
	avaliadores_loading: true,
	avaliadores: [],
	desativados: [],

	execucoes_loading: false,
	assimetria_loading: false,
	anamneses_loading: false,

	admin_master: null,
	pacientes_filtered: [],
	agrupamentos: [],
	search: "",
	search_protocol: [],
	dropdown: null,

	blog: false,
	set_blog: [],
	planos: [],

	select_info: "",
	button_search: false,
	current_page_patient: 1,
	current_page_evaluator: 1,
	pacientes_mais_visitados: [],
	pacientes_mais_visitados_request: false,
	categorias_protocolos: [],
	categorias_atribuidas: [],
	ids_storage_protocol: [],
	resposta_questionario_satisfacao: null,
	resposta_satisfacao_criado: null,
	data_salvar_questionario_satisfacao: {},
	set_quantidade_execucoes: null,
	current_page: "pacientes",
	modal_satisfaction_unified_report: false,
};

export default function dashboard(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.PACIENTE_REQUEST:
			return {
				...state,
				pacientes_loading: true,
			};
		case Types.PACIENTE_NOTIFICATION_REQUEST:
			return {
				...state,
				pacientes_notification_loading: true,
			};
		case Types.BLOG:
			return {
				...state,
				blog: true,
			};
		case Types.SET_BLOG:
			return {
				...state,
				set_blog: action.payload,
			};
		case Types.PACIENTE_REQUEST_SUCCESS:
			return {
				...state,
				pacientes: action.payload,
				pacientes_loading: false,
			};
		case Types.PACIENTE_NOTIFICATION_REQUEST_SUCCESS:
			return {
				...state,
				pacientes_notification: action.payload,
				pacientes_notification_loading: false,
			};
		case Types.PACIENTE_STOP_REQUEST:
			return {
				...state,
				pacientes_loading: false,
			};

		case Types.SER_AVALIADORES:
			return {
				...state,
				avaliadores: action.payload,
				avaliadores_loading: false,
			};
		case Types.SET_USER_MASTER:
			return {
				...state,
				admin_master: action.payload,
			};
		case Types.PACIENTE_FILTERED:
			return {
				...state,
				pacientes_filtered: action.payload,
			};
		case Types.SET_DESATIVADOS:
			return {
				...state,
				desativados: action.payload,
			};
		case Types.SET_AGRUPAMENTOS:
			return {
				...state,
				agrupamentos: action.payload,
			};
		case Types.SET_SEARCH:
			return {
				...state,
				search: action.payload,
			};
		case Types.SET_DROP_DOWN:
			return {
				...state,
				dropdown: action.payload,
			};
		case Types.EXECUCOES_REQUEST_SUCCESS:
			return {
				...state,
				execucoes: action.payload,
			};
		case Types.EXECUCOES_REQUEST:
			return {
				...state,
				execucoes_excluidas: action.payload,
			};
		case Types.EXECUCOES_REQUEST_LOADING:
			return {
				...state,
				execucoes_loading: action.payload,
			};
		case Types.ASSIMETRIA_REQUEST_SUCCESS:
			return {
				...state,
				assimetria: action.payload,
			};
		case Types.DESEQUILIBRIO_REQUEST_SUCCESS:
			return {
				...state,
				desequilibrio: action.payload,
			};
		case Types.ASSIMETRIA_REQUEST_LOADING:
			return {
				...state,
				assimetria_loading: action.payload,
			};
		case Types.ANAMNESES_REQUEST_SUCCESS:
			return {
				...state,
				anamneses: action.payload,
			};
		case Types.ANAMNESES_REQUEST_LOADING:
			return {
				...state,
				anamneses_loading: action.payload,
			};
		case Types.ANOTACOES_REUQUEST_SUCCESS:
			return {
				...state,
				anotacoes: action.payload,
			};
		case Types.ETIQUETAS_REQUEST_SUCCESS:
			return {
				...state,
				etiquetas: action.payload,
			};
		case Types.NOTIFICACOES_REQUEST_SUCCESS:
			return {
				...state,
				notificacoes: action.payload,
			};
		case Types.DINAMOS_REQUEST_SUCCESS:
			return {
				...state,
				dinamos: action.payload,
			};
		case Types.LOADING:
			return {
				...state,
				pacientes_loading: action.payload,
			};
		case Types.PLANOS_REQUEST_SUCCESS:
			return {
				...state,
				planos: action.payload,
			};
		case Types.SELECT_INFO:
			return {
				...state,
				select_info: action.payload,
			};
		case Types.TOTAL_PACIENTES:
			return {
				...state,
				total_pacientes: action.payload,
			};
		case Types.PACIENTE_REQUEST_FILTROS:
			return {
				...state,
				filtros_pacintes_request: action.payload,
			};
		case Types.PACIENTE_NOTIFICATION_REQUEST_FILTROS:
			return {
				...state,
				filtros_pacintes_notification_request: action.payload,
			};
		case Types.AVALIADOR_REQUEST_FILTROS:
			return {
				...state,
				filtros_avaliadores_request: action.payload,
			};
		case Types.SET_BUTTON_SEARCH:
			return {
				...state,
				button_search: action.payload,
			};
		case Types.AVALIADORES_REQUEST:
			return {
				...state,
				avaliadores_request: action.payload,
				avaliadores_loading: true,
			};
		case Types.SET_CURRENT_PAGE_PATIENT:
			return {
				...state,
				current_page_patient: action.payload,
			};
		case Types.SET_PACIENTES_MAIS_VISITADOS:
			return {
				...state,
				pacientes_mais_visitados: action.payload,
			};
		case Types.PACIENTES_MAIS_VISITADOS_REQUEST:
			return {
				...state,
				pacientes_mais_visitados_request: action.payload,
			};
		case Types.TOTAL_AVALIADORES:
			return {
				...state,
				total_avaliadores: action.payload,
			};
		case Types.SET_CATEGORIAS_PROTOCOLOS:
			return {
				...state,
				categorias_protocolos: action.payload,
			};
		case Types.GET_CATEGORIAS_ATRIBUIDAS:
			return {
				...state,
				categorias_atribuidas: action.payload,
			};
		case Types.SEARCH_PROTOCOL_IN_STORAGE:
			return {
				...state,
				ids_storage_protocol: action.payload,
			};
		case Types.SET_INFO_VERIFICACAO_QUESTIONARIO_SATISFACAO:
			return {
				...state,
				resposta_questionario_satisfacao: action.payload.respondido,
				resposta_satisfacao_criado: action.payload.resposta,
			};
		case Types.NUMBER_LISTAGEM_PAGE:
			return {
				...state,
				number_pages_listagem: action.payload,
			};
		case Types.SALVAR_QUESTIONARIO_SATISFACAO:
			return {
				...state,
				data_salvar_questionario_satisfacao: {
					txDataHora: action.payload.txDataHora,
					txTipo: action.payload.txTipo,
					RESPOSTA_ID_TIPO: action.payload.RESPOSTA_ID_TIPO,
					RESPOSTA_VALOR: action.payload.RESPOSTA_VALOR,
					RESPOSTA_COMPLEMENTO: action.payload.RESPOSTA_COMPLEMENTO,
					txCategorias: action.payload.txCategorias
				},
			};
		case Types.SET_QUANTIDADE_EXECUCOES:
			return {
				...state,
				set_quantidade_execucoes: action.payload,
			};
		case Types.SET_CURRENT_PAGE_EVALUATOR:
			return {
				...state,
				current_page_evaluator: action.payload,
			};
		case Types.SET_SEARCH_PROTOCOL:
			return {
				...state,
				search_protocol: action.payload,
			};
		case Types.SET_CURRENT_PAGE:
			return {
				...state,
				current_page: action.payload,
			};
		case Types.SET_MODAL_SATISFACTION_UNIFIED_REPORT:
			return{
				...state,
				modal_satisfaction_unified_report: action.payload,
			}
		default:
			return state;
	}
}

export const DashBoardActions = {
	paciente_request: () => ({
		type: Types.PACIENTE_REQUEST,
	}),
	paciente_notification_request: () => ({
		type: Types.PACIENTE_NOTIFICATION_REQUEST,
	}),
	paciente_success: (pacientes: any) => ({
		type: Types.PACIENTE_REQUEST_SUCCESS,
		payload: pacientes,
	}),
	set_number_page: (number_page: number) => ({
		type: Types.NUMBER_LISTAGEM_PAGE,
		payload: number_page,
	}),
	paciente_notification_success: (pacientes: any) => ({
		type: Types.PACIENTE_NOTIFICATION_REQUEST_SUCCESS,
		payload: pacientes,
	}),
	total_pacientes: (total_pacientes: any) => ({
		type: Types.TOTAL_PACIENTES,
		payload: total_pacientes,
	}),
	total_avaliadores: (total_avaliadores: any) => ({
		type: Types.TOTAL_AVALIADORES,
		payload: total_avaliadores,
	}),
	set_filtros_pacintes_request: (filtros_pacintes_request: any) => ({
		type: Types.PACIENTE_REQUEST_FILTROS,
		payload: filtros_pacintes_request,
	}),
	set_filtros_pacintes_notification_request: (
		filtros_pacintes_request: any
	) => ({
		type: Types.PACIENTE_NOTIFICATION_REQUEST_FILTROS,
		payload: filtros_pacintes_request,
	}),
	set_filtros_avaliadores_request: (filtros_avaliadores_request: any) => ({
		type: Types.AVALIADOR_REQUEST_FILTROS,
		payload: filtros_avaliadores_request,
	}),
	set_blog: (set_blog: any) => ({
		type: Types.SET_BLOG,
		payload: set_blog,
	}),
	blog_request: () => ({
		type: Types.BLOG,
	}),
	paciente_stop_request: () => ({
		type: Types.PACIENTE_STOP_REQUEST,
	}),
	set_avaliadores: (avaliadores: any) => ({
		type: Types.SER_AVALIADORES,
		payload: avaliadores,
	}),
	set_admin_master: (user_mas: any) => ({
		type: Types.SET_USER_MASTER,
		payload: user_mas,
	}),
	set_paciente_filtered: (pacientes: any) => ({
		type: Types.PACIENTE_FILTERED,
		payload: pacientes,
	}),
	set_desativados: (paciente: any) => ({
		type: Types.SET_DESATIVADOS,
		payload: paciente,
	}),
	protocolos_request: () => ({
		type: Types.PROTOCOLOS_REQUEST,
	}),
	set_agrupamentos: (agrupamentos: any) => ({
		type: Types.SET_AGRUPAMENTOS,
		payload: agrupamentos,
	}),
	exercices_request: () => ({
		type: Types.EXERCICES_REQUEST,
	}),
	set_search: (texto: any) => ({
		type: Types.SET_SEARCH,
		payload: texto,
	}),
	set_search_protocol: (texto: any) => ({
		type: Types.SET_SEARCH_PROTOCOL,
		payload: texto,
	}),
	execucoes_request: (execucoes_excluidas: boolean) => ({
		type: Types.EXECUCOES_REQUEST,
		payload: execucoes_excluidas,
	}),
	execucoes_request_sucess: (execucoes: any) => ({
		type: Types.EXECUCOES_REQUEST_SUCCESS,
		payload: execucoes,
	}),
	execucoes_request_loading: (loading: any) => ({
		type: Types.EXECUCOES_REQUEST_LOADING,
		payload: loading,
	}),
	set_dropdown: (texto: any) => ({
		type: Types.SET_DROP_DOWN,
		payload: texto,
	}),
	etiquetas_request: () => ({
		type: Types.ETIQUETAS_REQUEST,
	}),
	etiquetas_request_success: (etiquetas: any) => ({
		type: Types.ETIQUETAS_REQUEST_SUCCESS,
		payload: etiquetas,
	}),
	notificacoes_request: () => ({
		type: Types.NOTIFICACOES_REQUEST,
	}),
	notificacoes_request_success: (notificacoes: any) => ({
		type: Types.NOTIFICACOES_REQUEST_SUCCESS,
		payload: notificacoes,
	}),
	assimetria_request: () => ({
		type: Types.ASSIMETRIA_REQUEST,
	}),
	assimetria_request_success: (assimetrias: any) => ({
		type: Types.ASSIMETRIA_REQUEST_SUCCESS,
		payload: assimetrias,
	}),
	desequilibrios_request_success: (desequilibrios: any) => ({
		type: Types.DESEQUILIBRIO_REQUEST_SUCCESS,
		payload: desequilibrios,
	}),
	assimetria_request_loading: (loading: any) => ({
		type: Types.ASSIMETRIA_REQUEST_LOADING,
		payload: loading,
	}),
	anamneses_request: () => ({
		type: Types.ANAMNESES_REQUEST,
	}),
	anamneses_request_success: (anamneses: any) => ({
		type: Types.ANAMNESES_REQUEST_SUCCESS,
		payload: anamneses,
	}),
	anamneses_request_loading: (loading: any) => ({
		type: Types.ANAMNESES_REQUEST_LOADING,
		payload: loading,
	}),
	anotacoes_request: () => ({
		type: Types.ANOTACOES_REQUEST,
	}),
	exercicios_avaliados_request: () => ({
		type: Types.EXERCICIOS_AVALIADOS_REQUEST,
	}),
	anotacoes_request_success: (anotacoes: any) => ({
		type: Types.ANOTACOES_REUQUEST_SUCCESS,
		payload: anotacoes,
	}),
	dinamos_request: () => ({
		type: Types.DINAMOS_REQUEST,
	}),
	dinamos_request_success: (dinos: any) => ({
		type: Types.DINAMOS_REQUEST_SUCCESS,
		payload: dinos,
	}),
	set_loading: (dinos: any) => ({
		type: Types.LOADING,
		payload: dinos,
	}),
	planos_request: () => ({
		type: Types.PLANOS_REQUEST_SUCCESS,
	}),
	planos_request_success: (anotacoes: any) => ({
		type: Types.ANOTACOES_REUQUEST_SUCCESS,
		payload: anotacoes,
	}),
	set_select_info: (info: any) => ({
		type: Types.SELECT_INFO,
		payload: info,
	}),
	set_button_search: (payload: any) => ({
		type: Types.SET_BUTTON_SEARCH,
		payload: payload,
	}),
	avaliadores_request: () => ({
		type: Types.AVALIADORES_REQUEST,
	}),
	set_current_page_patient: (payload: any) => ({
		type: Types.SET_CURRENT_PAGE_PATIENT,
		payload: payload,
	}),
	set_current_page_evaluator: (payload: any) => ({
		type: Types.SET_CURRENT_PAGE_EVALUATOR,
		payload: payload,
	}),
	set_pacientes_mais_visitados: (payload: any) => ({
		type: Types.SET_PACIENTES_MAIS_VISITADOS,
		payload: payload,
	}),
	mais_visitados_request: () => ({
		type: Types.PACIENTES_MAIS_VISITADOS_REQUEST,
	}),
	call_categorias_protocolos: () => ({
		type: Types.CATEGORIAS_PROTOCOLOS,
	}),
	set_categorias_protocolos_in_modal_filter_protocol: (categorias: any) => ({
		type: Types.SET_CATEGORIAS_PROTOCOLOS,
		payload: categorias,
	}),
	set_atribuir_categorias_protocolos: (
		txDataHora: string,
		id_usuario: number,
		id_categorias: number[]
	) => ({
		type: Types.ATRIBUIR_CATEGORIA_PROTOCOLOS,
		payload: { txDataHora, id_usuario, id_categorias },
	}),
	search_categorias_atribuidas: (id_usuario: number) => ({
		type: Types.SEARCH_CATEGORIAS_ATRIBUIDAS,
		payload: id_usuario,
	}),
	get_categorias_atribuidas: (categorias_atribuidas: any) => ({
		type: Types.GET_CATEGORIAS_ATRIBUIDAS,
		payload: categorias_atribuidas,
	}),
	search_protocol_in_storage: (ids: number[]) => ({
		type: Types.SEARCH_PROTOCOL_IN_STORAGE,
		payload: ids,
	}),
	set_questionario_satisfacao_verificar: (
		resposta_id_tipo: any,
		txTipo: string
	) => ({
		type: Types.SET_QUESTIONARIO_SATISFACAO_VERIFICAR,
		payload: { resposta_id_tipo, txTipo },
	}),
	set_questionario_satisfacao_verificar_tipos_modal_satisfacao_exame: (
		txTipos: string[]
	) => ({
		type: Types.SET_QUESTIONARIO_SATISFACAO_VERIFICAR,
		payload: { txTipos },
	}),
	set_info_questionario_satisfacao: (respondido: any, resposta: any) => ({
		type: Types.SET_INFO_VERIFICACAO_QUESTIONARIO_SATISFACAO,
		payload: { respondido, resposta },
	}),
	set_salvar_questionario_satisfacao: (
		txDataHora: string,
		txTipo: string,
		RESPOSTA_VALOR?: string[] | number[],
		RESPOSTA_COMPLEMENTO?: string[],
		RESPOSTA_ID_TIPO?: number | string,
		txCategorias?: string[],
	) => ({
		type: Types.SALVAR_QUESTIONARIO_SATISFACAO,
		payload: {
			txDataHora,
			txTipo,
			RESPOSTA_ID_TIPO,
			RESPOSTA_VALOR,
			RESPOSTA_COMPLEMENTO,
			txCategorias
		},
	}),
	set_amount_executions: (amount: number | null) => ({
		type: Types.SET_QUANTIDADE_EXECUCOES,
		payload: amount,
	}),
	set_get_amount_executions: () => ({
		type: Types.GET_QUANTIDADE_EXECUCOES,
	}),
	set_current_page: (payload: any) => ({
		type: Types.SET_CURRENT_PAGE,
		payload: payload,
	}),
	set_modal_satisfaction_unified_report: ( respondido: boolean )=>({
		type: Types.SET_MODAL_SATISFACTION_UNIFIED_REPORT,
		payload: respondido,
	}),
};
