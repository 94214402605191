import {
	Backdrop,
	Fade,
	Modal,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../store/ducks/app";
import { PacAvalActions } from "../../store/ducks/manage_pac_aval";
import buttonClose from "../../assets/icons/buttonCloseBlack.svg";
import {
	Close,
	Container,
	ContainerOptions,
	Subtitle,
	Title,
	Option,
} from "../../styles/styles_modais_padroes";
import { ButtonSave, Checkbox, Tag } from "../../styles/global";
import { DashBoardActions } from "../../store/ducks/dashboard";
import { TextsProps } from "../../database/txt.interface";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

interface TagsProps {
	ID_ETIQUETA: number;
	ETIQUETA_NOME: string;
	ID_USUARIOS: [];
}

const ModalFiltro: React.FC = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const is_open_filtro_etiquetas = useSelector(
		(state: any) => state.app.is_open_filtro_etiquetas
	);

	const etiquetas = useSelector((state: any) => state.dashboard.etiquetas);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const [checkedItems, setCheckedItems] = useState<string[]>([]);

	const ModalClose = () => {
		dispatch(AppActions.set_lista_pacientes([]));
		dispatch(PacAvalActions.reset_patient());
		dispatch(AppActions.set_modal_filtro_etiquetas(false));
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = event.target;
		setCheckedItems((prevItems) => {
			if (checked) {
				return [...prevItems, name];
			} else {
				return prevItems.filter((item) => item !== name);
			}
		});
	};

	const Concluir = () => {
		const checkedItemsInt = checkedItems.map((str) => parseInt(str, 10));
		dispatch(AppActions.set_lista_filtro(checkedItemsInt));
		const filtros_pacintes_request = {
			inicio: 0,
			fim: 200,
			filtros: [
				{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
				{ tipo: "USUARIO_NIVEL", valor: [3] },
			],
		};
		if (checkedItems.length > 0) {
			filtros_pacintes_request.filtros.push({
				tipo: "ID_ETIQUETA",
				valor: checkedItemsInt,
			});
		}
		dispatch(
			DashBoardActions.set_filtros_pacintes_request(filtros_pacintes_request)
		);
		dispatch(DashBoardActions.paciente_request());
		ModalClose();
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open_filtro_etiquetas}
			onClose={ModalClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open_filtro_etiquetas}>
				<Container>
					<Close
						className="close"
						src={buttonClose}
						onClick={ModalClose}
						alt="Close"
					/>
					<Title>{current_language?.filtro}</Title>
					<Subtitle>{current_language?.escolha_as_etiquetas}</Subtitle>
					<ContainerOptions>
						{etiquetas.map((etiqueta: TagsProps) => {
							return (
								<Option key={etiqueta?.ID_ETIQUETA}>
									<Tag
										style={{ backgroundColor: "#CEF6E7", marginBottom: "1rem" }}
									>
										{etiqueta.ETIQUETA_NOME}
									</Tag>
									<Checkbox
										type="checkbox"
										name={etiqueta.ID_ETIQUETA.toString()}
										checked={checkedItems.includes(
											etiqueta.ID_ETIQUETA.toString()
										)}
										onChange={handleChange}
									/>
								</Option>
							);
						})}
					</ContainerOptions>
					<ButtonSave
						active={checkedItems.length > 0 ? true : false}
						onClick={
							checkedItems.length > 0
								? Concluir
								: () => {
										/* no-op */
								  }
						}
					>
						{current_language?.primeiro_acesso_concluir}
					</ButtonSave>
				</Container>
			</Fade>
		</Modal>
	);
};

export default ModalFiltro;
