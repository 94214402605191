import React, { memo, useEffect, useState } from "react";
import { InputText, Question } from "./styles";
import { QuestionnaireProps } from '../../../../../interfaces/pacientes.interface';
import { useDispatch, useSelector } from 'react-redux';
import { PacAvalActions } from '../../../../../store/ducks/manage_pac_aval';
import AdicionarCampo from '../AdicionarCampo';
import { TextsProps } from '../../../../../database/txt.interface';
import TermografiaAplicadaCampos from './_renderCampos';

interface questionProps {
	item: QuestionnaireProps,
}

interface camposProps {
	LOCAL: string,
	TEMPERATURA: number,
	MEDIDA_CALOR: string,
}

const TermografiaAplicada: React.FC<questionProps> = ({ item }) => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const array_respostas = useSelector(
		(state: any) => state.pac_aval.array_respostas
	);
	const array_resposta = useSelector(
		(state: any) => state.pac_aval.array_resposta
	);

	const [listFormData, setListFormData] = useState([1]);
	function handleAddForm() {
		setListFormData([...listFormData, listFormData.length + 1])
	}

	useEffect(() => {
		const valor = array_respostas?.filter((e: any) => e?.QUESTIONARIO_LABEL === item?.QUESTIONARIO_LABEL);
		if (valor.length === 1) {
			const sequencia: any = []
			valor[0].QUESTIONARIO_RESPOSTA.map((e: any, i: any) => {
				sequencia.push(i + 1)
			})
			setListFormData(sequencia)
		}
	}, [])

	const handleTextInput = (obj: any) => {
		const valor = array_respostas?.filter((e: any) => e?.QUESTIONARIO_LABEL === item?.QUESTIONARIO_LABEL);
		const array = [];

		if (!!valor && valor.length > 0) {
			const val = valor[0].QUESTIONARIO_RESPOSTA
			array.push(...val, obj)
		} else {
			array.push(obj)
		}
		if (obj != "") {
			const dataObj = {
				"QUESTIONARIO_LABEL": `${item.QUESTIONARIO_LABEL}`,
				"QUESTIONARIO_RESPOSTA": array
			}
			const new_array = array_respostas.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
			const new_array_resposta = array_resposta.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
			dispatch(PacAvalActions.set_array_respostas([...new_array, dataObj]));
			dispatch(PacAvalActions.set_array_resposta([...new_array_resposta, dataObj]));
		}
	}

	const lista = item?.QUESTIONARIO_PERGUNTA_OPCOES
	return (
		<>
			{
				listFormData.map((e: any, i: number) => {
					const valor = array_respostas?.filter((e: any) => e?.QUESTIONARIO_LABEL === item?.QUESTIONARIO_LABEL);
					return (
						<TermografiaAplicadaCampos lista={lista} texts={(!!valor && !!valor[0]?.QUESTIONARIO_RESPOSTA[i]) && valor[0]?.QUESTIONARIO_RESPOSTA[i]} funcao={handleTextInput} key={i} />
					)
				})
			}
			<AdicionarCampo funcao={() => handleAddForm()} item={current_language?.adicionar_campo} />
		</>
	);
};

export default memo(TermografiaAplicada);