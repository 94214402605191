import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonClose.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalConta: React.FC = () => {
	// comunica com redux
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	// função que abre a modal, mais sobre ela em store/ducks/app.ts
	const is_open_conta = useSelector((state: any) => state.app.is_open_conta);

	// função para fechar modal
	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_conta(false));
	};

	return (
		<div>
			{/* librarie para abrir o modal */}
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_conta}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_conta}>
					{/* modal com as infos, feito html e css */}
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<div className="divText">
							<p className="text">
								<b>{current_language?.modal_conta_usada}</b>
							</p>
							<p className="text">{current_language?.modal_conta_usada2}</p>
						</div>
						<div className="buttons">
							<button className="button confirmar">
								{current_language?.confirmar_button}
							</button>
							<button className="button cancelar" onClick={ModalCadastroClose}>
								{current_language?.pagamento_cancelar}
							</button>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalConta;
