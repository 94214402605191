import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	padding: 2rem;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 70vh;
	width: 50vw;
	@media only screen and (min-width: 1024px) {
		height: 80vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1280px) {
		height: 80vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1440px) {
		height: 80vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1680px) {
		height: 70vh;
		width: 42vw;
	}

	@media only screen and (min-width: 1920px) {
		height: 65vh;
		width: 42vw;
	}

	@media only screen and (min-width: 2342px) {
		height: 65vh;
		width: 45vw;
	}

`;

export const Content = styled.div`
	width: 100%;
	height: 80%;
	display: flex;
	padding: 2rem;
	flex-direction: column;

	@media only screen and (min-width: 2342px) {
		height: 60vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1920px) {
		height: 65vh;
		width: 38vw;
	}

	@media only screen and (min-width: 1680px) {
		height: 75vh;
		width: 38vw;
	}

	@media only screen and (min-width: 1440px) {
		height: 80vh;
		width: 42vw;
		padding: 5rem;
	}

	@media only screen and (min-width: 1024px) {
		height: 70vh;
		width: 42vw;
		padding: 0rem;
	}
`;

export const ContainerDino = styled.div`
	display: flex;
	width: 100%;
	min-height: 5rem;
	align-items: center;
	justify-content: center;

	@media only screen and (min-width: 1015px) {
		margin-top: -2rem;
	}
	@media only screen and (min-width: 1200px) {
		margin-top: 1rem;
	}
	@media only screen and (min-width: 1350px) {
		margin-top: 10px;
	}
	@media only screen and (min-width: 1920px) {
		margin-top: 52px;
	}
	@media only screen and (min-width: 2342px) {
		margin-top: 5rem;
	}
`;

export const Dino = styled.img``;

export const Close = styled.img`
	width: 1.738rem;
	position: absolute;
	right: 1.5rem;
	top: 1.5rem;
	cursor: pointer;
`;

export const Title = styled.h1`
	text-align: center;
	margin-bottom: 5rem;
	color: black;

	@media only screen and (min-width: 922px) {
		margin-bottom:2rem;
	}
	@media only screen and (min-width: 1015px) {
		font-size:30px;
		margin-top: 3rem;
		margin-bottom:0.5rem;
	}
	@media only screen and (min-width: 1200px) {
		margin-bottom: 1rem;
		margin-top: 3rem;
	}
	@media only screen and (min-width: 1350px) {
		margin-bottom: 2rem;
	}
	@media only screen and (min-width: 1440px) {
		margin-top: 8%;
	}
	@media only screen and (min-width: 2342px) {
		margin-top: 8%;
	}
`;

export const TextArea = styled.div`
	text-align: center;
	font-size: 30px;
	line-height: 2.5rem;
	color: black;

	@media only screen and (min-width: 1015px) {
		font-size: 15px;
		line-height: 1rem;
	}
	@media only screen and (min-width: 1024px) {
		font-size: 20px;
		line-height: 1.8rem;
	}
	@media only screen and (min-width: 1200px) {
		font-size: 22px;
		line-height: 1.5rem;
	}
	@media only screen and (min-width: 1350px) {
		font-size: 20px;
		line-height: 1.8rem;
	}
	@media only screen and (min-width: 1440px) {
		font-size: 30px;
		line-height: 2.2rem;
	}
	@media only screen and (min-width: 1680px) {
		font-size: 30px;
		margin-left: 32px;
		margin-right: 32px;
		margin-top: 20px;
		line-height: 1.8rem;
	}
	@media only screen and (min-width: 1920px) {
		padding-left: 32px;
		padding-right: 32px;
	}
	
	@media only screen and (min-width: 2342px) {
		font-size: 35px;
		line-height: 2.3rem;
	}
`;
export const Phrase = styled.p`
	color: #5C5B5B;
`;

export const ContainerButtons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	@media only screen and (min-width: 1015px) {
		margin-top: 5%;
	}
	@media only screen and (min-width: 1024px) {
		margin-top: 5%;
	}
	@media only screen and (min-width: 1440px) {
		margin-top: 5%;
		height: 50%;
	}
	@media only screen and (min-width: 1680px) {
		margin-top: 8%;
	}
	@media only screen and (min-width: 1920px) {
		margin-top: 5%;
	}
	@media only screen and (min-width: 2342px) {
		height: 30%;
		width: 100%;
		margin-top: 1.5%;
	}
`;

export const ButtonGerarNovo = styled.button`
	display: flex;
	justify-content: center;
	width: 15rem;
	height: 3.8rem;
	background: white;
	border-radius: 8px;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	align-items: center;
	text-align: center;
	border: 3px solid ${Colors.primary_blue_k_main};

	@media only screen and (min-width: 922px) {
		width: 12rem;
		height: 3.5rem;
	}
	@media only screen and (min-width: 2342px) {
		width: 18rem;
		height: 4.5rem;
	}
`;

export const ButtonContinuar = styled.button`
	display: flex;
	justify-content: center;
	width: 15rem;
	height: 3.8rem;
	border-radius: 8px;
	border: none;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 23px;
	align-items: center;
	text-align: center;
	color: #ffffff;

	@media only screen and (min-width: 922px) {
		width: 12rem;
		height: 3.5rem;
	}
	@media only screen and (min-width: 2342px) {
		width: 18rem;
		height: 4.5rem;
	}

`;
