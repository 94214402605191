import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import IconWarning from "../../../assets/icons/status_warning.svg";
import { TextsProps } from "../../../database/txt.interface";
import { ContainerModalExcluir } from "../../../styles/styles_modais_excluir";
import { AmplitudeLog } from "../../../utils/amplitude";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { AnotacaoProps } from "../../../interfaces/anotacoes.interface";
import { AnotationActions } from "../../../store/ducks/anotacoes";
import { AppActions } from "../../../store/ducks/app";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ExcluirAnotacoes: React.FC = () => {
	const dispatch = useDispatch();
	const classes = useStyles();

	// comunica com redux
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const is_open_apagar_anotacao: AnotacaoProps = useSelector(
		(state: any) => state.app.is_open_apagar_anotacao
	);

	const ModalClose = () => {
		dispatch(AppActions.set_modal_apagar_anotacao(false));
		dispatch(DashBoardActions.set_current_page("listagem_anotacoes"));
	};

	const Excluir = () => {
		const data = {
			ID_ANOTACAO: is_open_apagar_anotacao.ID_ANOTACAO,
		};
		AmplitudeLog("anotacao_excluida", { body: data });
		ModalClose();
		dispatch(AnotationActions.delete_anotation_request(data));
		ModalClose();
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={!!is_open_apagar_anotacao}
			onClose={ModalClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={!!is_open_apagar_anotacao}>
				<ContainerModalExcluir>
					<img
						className="close"
						src={buttonClose}
						onClick={ModalClose}
						alt="Close"
					/>
					<img className="icon_warning" src={IconWarning} alt="" />
					<div className="title">
						<div className="containerFrases">
							<p className="QtdPacientes">Deseja excluir essa anotação</p>
						</div>
					</div>
					<div className="buttons">
						<button onClick={Excluir} className="button excluir">
							{current_language?.modal_excluir}
						</button>
						<button className="button cancelar" onClick={ModalClose}>
							{current_language?.pagamento_cancelar}
						</button>
					</div>
				</ContainerModalExcluir>
			</Fade>
		</Modal>
	);
};

export default ExcluirAnotacoes;
