import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as V from "victory";

import { TextsProps } from "../../../../../database/txt.interface";
import {
	ExecucaoProp,
	ExerCucaoProp,
} from "../../../../../interfaces/assimetria.interface";
import { TypesCores } from "../../../../../utils/types";
import { Content } from "./styles";
import ReturnIcon from "../../../../../assets/icons/back_page.svg";
import ArrowOpen from "../../../../../assets/icons/arrow_forward_ios_open.svg";
import ArrowClosed from "../../../../../assets/icons/arrow_forward.svg";
import {
	DescriptionDesk,
	P2Desk,
	P3Desk,
	S1Desk,
	S2Desk,
	S3Desk,
} from "../../../../../styles/styles_text";
import {
	ContainerDash,
	Icon,
	ProfilePicture,
	Return,
} from "../../../../../styles/global";
import { DashBoardActions } from "../../../../../store/ducks/dashboard";
import Perfil from "../../../../../assets/icons/perfil.svg";
import { PacientesProps } from "../../../../../interfaces/pacientes.interface";
import styled from "styled-components";
import ArrowRight from "../../../../../assets/kinologyIcons/svg/arrow_forward_ios.svg";
import ArrowLeft from "../../../../../assets/kinologyIcons/svg/arrow_forward_ios_left.svg";

const HistoricoAssimetria: React.FC = () => {
	const dispatch = useDispatch();

	const [open_legends, set_open_legends] = useState(false);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const exer_historico: any = [];

	const current_exercise = useSelector(
		(state: any) => state.relatorios.current_page_from_history_asymmetry
	);

	const ladoDireito: any = [];
	const ladoEsquerdo: any = [];
	const legendas: any = [];

	current_exercise.EXECUCOES.map((e: any) => {
		ladoDireito.push({
			x:
				current_language?.idioma === "Português"
					? `${e?.DATA?.substr(0, 10).split("-").reverse().join("/")}`
					: current_language?.idioma === "English"
					? `${e?.DATA?.substr(0, 5)
							.split("/")
							.reverse()
							.join("/")}/${e?.DATA?.substr(6, 10)}`
					: `${e?.DATA?.substr(0, 10).split("/").join("-")}`,
			y: e?.MAXEXEC_D,
			position: "D",
			color: e.LEGENDA_ASSIMETRIA,
			maior: e.MAXEXEC_E > e.MAXEXEC_D ? e.MAXEXEC_E : e.MAXEXEC_D,
		});
		ladoEsquerdo.push({
			x:
				current_language?.idioma === "Português"
					? `${e?.DATA?.substr(0, 10).split("-").reverse().join("/")}`
					: current_language?.idioma === "English"
					? `${e?.DATA?.substr(0, 5)
							.split("/")
							.reverse()
							.join("/")}/${e?.DATA?.substr(6, 10)}`
					: `${e?.DATA?.substr(0, 10).split("/").join("-")}`,
			y: e?.MAXEXEC_E,
			position: "E",
			color: e.LEGENDA_ASSIMETRIA,
			maior: e.MAXEXEC_E > e.MAXEXEC_D ? e.MAXEXEC_E : e.MAXEXEC_D,
		});
		legendas.push({
			data:
				current_language?.idioma === "Português"
					? `${e?.DATA?.substr(0, 10).split("-").reverse().join("/")}`
					: current_language?.idioma === "English"
					? `${e?.DATA?.substr(0, 5)
							.split("/")
							.reverse()
							.join("/")}/${e?.DATA?.substr(6, 10)}`
					: `${e?.DATA?.substr(0, 10).split("/").join("-")}`,
			direito: e?.MAXEXEC_D,
			esquerdo: e?.MAXEXEC_E,
			assimetria: e?.ASSIMETRIA_VALOR,
		});
	});

	exer_historico.push({
		EXERCICIO_TITULO_PT: current_exercise?.EXERCICIO_TITULO_PT,
		DADOS_DIREITA: ladoDireito.reverse(),
		DADOS_ESQUERDA: ladoEsquerdo.reverse(),
		LEGENDAS: legendas,
	});

	//Pagination
	const itemsPerPage = 8;

	const [currentPage, setCurrentPage] = useState(0);

	const totalPages = Math.ceil(
		Math.max(
			exer_historico[0].DADOS_DIREITA.length,
			exer_historico[0].DADOS_ESQUERDA.length
		) / itemsPerPage
	);

	const handlePrevious = () => {
		setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
	};

	const handleNext = () => {
		setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
	};

	const startIndex = currentPage * itemsPerPage;
	const currentItemsDireita = exer_historico[0].DADOS_DIREITA.slice(
		startIndex,
		startIndex + itemsPerPage
	);
	const currentItemsEsquerda = exer_historico[0].DADOS_ESQUERDA.slice(
		startIndex,
		startIndex + itemsPerPage
	);

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("assimetria"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "1rem", height: "1rem" }} />
				<P3Desk>Evolução de Assimetria</P3Desk>
			</Return>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "1rem",
					marginBottom: "3rem",
					width: "60%",
				}}
			>
				<ProfilePicture
					src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
				/>
				<S1Desk style={{ marginLeft: "1rem" }}>{patient.USUARIO_NOME}</S1Desk>
			</div>
			<WhiteContainer>
				<P2Desk>Histórico de Assimetria</P2Desk>

				<Content>
					<div className="cards">
						{exer_historico.map((e: any, index: number) => {
							return (
								<div className="card" key={index}>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
										}}
									>
										<p className="title">
											{current_language?.idioma === "Português" && (
												<S3Desk>
													<strong>{e?.EXERCICIO_TITULO_PT}</strong>
												</S3Desk>
											)}
											{current_language?.idioma === "English" && (
												<S2Desk>
													<strong>{e?.EXERCICIO_TITULO_EN}</strong>
												</S2Desk>
											)}
											{current_language?.idioma === "Español" && (
												<S2Desk>
													<strong>{e?.EXERCICIO_TITULO_ES}</strong>
												</S2Desk>
											)}
										</p>
									</div>
									<div className="chart">
										<button
											onClick={handlePrevious}
											disabled={currentPage === 0}
										>
											<Icon
												style={
													currentPage === 0 ? { opacity: 0 } : { opacity: 1 }
												}
												src={ArrowLeft}
											/>
										</button>
										<V.VictoryChart width={2000}>
											<V.VictoryGroup offset={65}>
												<V.VictoryBar
													data={currentItemsEsquerda}
													labels={({ datum }) => [datum._y, datum.position]}
													style={{
														data: {
															fill: ({ datum }) => {
																let cor: any = null;
																if (datum.color == TypesCores.POSITIVO) {
																	cor = "#7FE179";
																} else if (datum.color == TypesCores.NEGATIVO) {
																	cor = "#D12600";
																} else if (
																	datum.color == TypesCores.INTERMEDIARIO
																) {
																	cor = "#FFC530";
																} else {
																	cor = "#797D8D";
																}
																if (datum.y < datum.maior) {
																} else if (
																	datum.color == TypesCores.POSITIVO &&
																	datum.y === datum.maior
																) {
																	cor = "#7FE179";
																} else {
																	cor = "#797D8D";
																}
																return cor;
															},
															width: "50",
														},
														labels: {
															fill: "white",
															fontSize: 20,
															fontFamily: "roboto",
														},
													}}
													labelComponent={
														<V.VictoryLabel
															dy={50}
															lineHeight={[3, 1]}
															style={[
																{ fill: "#000", fontSize: "25px" },
																{ fill: "white", fontSize: "25px" },
															]}
														/>
													}
												/>
												<V.VictoryBar
													data={currentItemsDireita}
													labels={({ datum }) => [datum._y, datum.position]}
													style={{
														data: {
															fill: ({ datum }) => {
																let cor: any = null;
																if (datum.color == TypesCores.POSITIVO) {
																	cor = "#7FE179";
																} else if (datum.color == TypesCores.NEGATIVO) {
																	cor = "#D12600";
																} else if (
																	datum.color == TypesCores.INTERMEDIARIO
																) {
																	cor = "#FFC530";
																} else {
																	cor = "#797D8D";
																}
																if (datum.y < datum.maior) {
																} else if (
																	datum.color == TypesCores.POSITIVO &&
																	datum.y === datum.maior
																) {
																	cor = "#7FE179";
																} else {
																	cor = "#797D8D";
																}

																return cor;
															},
															width: "50",
														},
														labels: {
															fill: "white",
															fontSize: 25,
															fontFamily: "roboto",
														},
													}}
													labelComponent={
														<V.VictoryLabel
															dy={50}
															lineHeight={[3, 1]}
															style={[
																{ fill: "#000", fontSize: "25px" },
																{ fill: "white", fontSize: "25px" },
															]}
														/>
													}
												/>
											</V.VictoryGroup>

											<V.VictoryAxis
												dependentAxis
												label={current_language?.forca_kg}
												style={{
													axisLabel: {
														fontSize: 25,
														fontFamily: "roboto",
													},

													tickLabels: {
														fontSize: 25,
														fill: "transparent",
													},
													axis: {
														color: "transparent",
													},
												}}
											/>

											<V.VictoryAxis
												style={{
													tickLabels: {
														fontSize: 25,
														fill: "black",
														fontFamily: "roboto",
													},
												}}
											/>
										</V.VictoryChart>
										<button
											onClick={handleNext}
											disabled={currentPage === totalPages - 1}
										>
											<Icon
												style={
													currentPage === totalPages - 1
														? { opacity: 0 }
														: { opacity: 1 }
												}
												src={ArrowRight}
											/>
										</button>
									</div>
									<table className="table">
										<tr style={{ backgroundColor: "#F1F2F3" }}>
											<td
												className="parameter"
												style={{ borderRadius: "0.625rem 0rem 0rem 0rem" }}
											>
												{current_language?.data}
											</td>
											<td className="parameter">
												{current_language?.hemisferio_direito} (D)
											</td>
											<td className="parameter">
												{current_language?.hemisferio_esquerdo} (E)
											</td>
											<td
												className="parameter"
												style={{ borderRadius: "0rem 0.625rem 0rem 0rem" }}
											>
												{current_language?.assimetria}
											</td>
										</tr>

										{e?.LEGENDAS.map((i: any, index: any) => {
											return (
												<tr key={index}>
													<td className="value">{i?.data}</td>
													<td className="value">{i?.direito} Kg</td>
													<td className="value">{i?.esquerdo} Kg</td>
													<td className={`value`}>{i?.assimetria}</td>
												</tr>
											);
										})}
									</table>
								</div>
							);
						})}
					</div>
				</Content>
				<ContainerLegenda>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
						onClick={() => set_open_legends(open_legends ? false : true)}
					>
						<Th>Legendas</Th>
						<Icon src={open_legends ? ArrowOpen : ArrowClosed} />
					</div>

					<div
						style={!open_legends ? { display: "none", marginTop: "1rem" } : {}}
					>
						<Th>Assimetria:</Th>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginBottom: "1rem",
							}}
						>
							<Retangle style={{ backgroundColor: "#f7db92" }}></Retangle>
							<DescriptionDesk>Entre 10% e 20%</DescriptionDesk>
							<Retangle style={{ backgroundColor: "#edbdb3" }}></Retangle>
							<DescriptionDesk>Acima de 20%</DescriptionDesk>
							<Retangle style={{ backgroundColor: "#7fe07a" }}></Retangle>
							<DescriptionDesk>Abaixo de 10%</DescriptionDesk>
							<Retangle style={{ backgroundColor: "#758d87" }}></Retangle>
							<DescriptionDesk>Abaixo de 10%</DescriptionDesk>
						</div>
						<DescriptionDesk
							style={{
								color: "#797D8D",
								paddingLeft: "0.5rem",
							}}
						>
							A assimetria é aferida em relação ao grupamento muscular mais
							forte medido. Resultados contabilizados a partir da últimas
							execuções dos lados direito e esquerdo realizadas no mesmo dia.
							Para confiabilidade dos dados, execuções feitas em dias diferentes
							não serão contabilizadas. No caso de mais de uma execução no mesmo
							dia, será contabilizada a última.
							<br />
							<br />
						</DescriptionDesk>
					</div>
				</ContainerLegenda>
			</WhiteContainer>
		</ContainerDash>
	);
};

const WhiteContainer = styled.div`
	background-color: white;
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
	position: relative;
	z-index: 2;

	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const ContainerLegenda = styled.div`
	background: var(--Neutras-Grey-K-5, #f1f2f3);
	width: 100%;
	padding: 0.5rem;
	color: var(--Neutras-Grey-K-70, #3d4259);
	font-family: "Noto Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	border-radius: 0rem 0rem 0.875rem 0.875rem;
`;

const Th = styled.th`
	border: 1.37px solid var(--Neutras-Grey-K-5, #f1f2f3);
	background: var(--Neutras-Grey-K-5, #f1f2f3);
	text-align: left;
	padding: 0.7rem;
	color: var(--Neutras-Grey-K-70, #3d4259);
	font-family: "NotoSans-Regular";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const Retangle = styled.div`
	border-radius: 3px;
	width: 1rem;
	height: 1rem;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
`;

export default HistoricoAssimetria;
