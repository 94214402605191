import React, { memo } from 'react';
import { CorpoFrente, Corpos, CorpoCostas } from './styles';
import EVA_frente from "../../../../../assets/EVA_frente.svg"
import EVA_costas from "../../../../../assets/EVA_costas.svg"
import RadioButtonCorpo from '../RadioButtonCorpo';
import { QuestionnaireProps } from "../../../../../interfaces/pacientes.interface";

interface RenderBodyProps {
	item: QuestionnaireProps,
	addMarcador: any,
	isActive: boolean,
	valueSlider: any,
}


const RenderBody: React.FC<RenderBodyProps> = ({
	item,
	addMarcador,
	isActive,
	valueSlider,
}) => {
	return (
		<Corpos>
			<CorpoFrente>
				<img src={EVA_frente} />
				<RadioButtonCorpo
					obj={{ "MUSCULO": 0, "LADO": "E" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[0]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 0, "LADO": "D" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[0]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 1, "LADO": "E" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[1]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 1, "LADO": "D" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[1]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 2, "LADO": "E" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[2]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 2, "LADO": "D" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[2]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 3, "LADO": "E" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[3]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 3, "LADO": "D" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[3]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 4, "LADO": "E" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[4]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 4, "LADO": "D" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[4]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 5, "LADO": "E" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[5]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 5, "LADO": "D" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[5]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>

			</CorpoFrente>

			<CorpoCostas>
				<img src={EVA_costas} />
				<RadioButtonCorpo
					obj={{ "MUSCULO": 6, "LADO": "S" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[6]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 7, "LADO": "S" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[7]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 8, "LADO": "S" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[8]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
				<RadioButtonCorpo
					obj={{ "MUSCULO": 9, "LADO": "S" }}
					musculo={item.QUESTIONARIO_PERGUNTA_OPCOES[9]}
					item={item}
					valueSlider={valueSlider}
					isActive={isActive}
					addMarcador={addMarcador}
				/>
			</CorpoCostas>
		</Corpos>

	);
}
export default memo(RenderBody)