import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
    display: flex;
    width: 31.625rem;
    padding: 1.375rem 2rem 2rem 2rem;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    border-radius: 1.875rem;
    background-color: ${Colors?.white};
`;

export const ContainerClose = styled.div`
    display: flex;
    position: absolute;
    top: 1.37rem;
    right: 2rem;

`;
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding-top:3.75rem;
`;
export const ContainerButtons = styled.div`
    width: 100%;
    justify-content: space-between;
    display: flex;
    margin-top: 3.75rem;
`;
export const Button = styled.div`
    width: 45%;
    display: flex;
    height: 3.625rem;
    padding: 0.75rem 2rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.75rem;
    border: 2px solid ;
    cursor: pointer;
`;
export const Title = styled.p`
    color: ${Colors?.primary_blue_k_main};
    text-align: center;
    font-family: "Kanit-Regular";
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
`;