import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 5rem;
	margin-bottom: 5rem;

	.texto {
		display: flex;
		font-size: 1.2rem;
		color: #5c5b5b;
		margin-top: 1.6rem;
		text-align: center;
	}

	.subtitle {
		font-size: 1rem;
		font-weight: 400;
	}
	.upgrade {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 80rem;
		height: 15rem;
	}
	.buttonUpgrade {
		background: ${Colors.primary_blue_k_main};
		width: 13rem;
		height: 4rem;
		margin-top: 1.5rem;
		border-radius: 1rem;
		align-items: center;
		color: white;
		font-weight: bold;
		border: none;
		transition: all 0.3s ease;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32);
	}
	.aviso {
		width: 25rem;
		
		font-weight: 400;
		font-size: 1.1rem;

		display: flex;
		justify-content: space-between;

		margin-bottom: 1rem;
		padding: .5rem;

		border-bottom: 1px solid #5c5b5b;
	}
`;
