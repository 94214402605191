import styled, {css} from 'styled-components';

import { Colors } from '../../../styles/colors';
// import fonts from '~/styles/fonts';
import { TitleButtonFormSubmiteProps } from './Models';
import { ContainerButtonFormSubmite } from '../../Buttons/FormSubmitButton/style';




export const TitleButton = styled.p<TitleButtonFormSubmiteProps>`
    color: ${({color, type})=> color ? color : Colors?.neutral_very_light } ;
    font-weight: ${({fontWeight})=> fontWeight ?  fontWeight : 400};
    text-align: ${({textAlign})=>  textAlign ? textAlign : 'center'};
    font-size: ${({fontSize})=> fontSize ? fontSize : 1.25}rem;
    font-family: ${({fontFamily})=> fontFamily ? fontFamily : ''};
    text-decoration:  ${({textDecoration})=> textDecoration ? textDecoration : 'none'};
    margin-left: ${({marginLeft})=> marginLeft ? marginLeft : 0}px;
    margin-right: ${({marginRight})=> marginRight ? marginRight : 0}px;
    margin-top: ${({marginTop})=> marginTop ? marginTop : 0 }px;
    margin-bottom: ${({marginBottom})=> marginBottom ? marginBottom : 0}px;
    cursor: ${({cursor})=> cursor ? cursor : 'default'};
    width: ${({width})=> width ? width : 'auto'};

`;
