import React, { memo } from "react";
import {
	Container,
	Preenchimento,
	ProgressBar,
	ProgressNumber,
} from "./styles";

interface iProgressProps {
	actualNumber: number;
	totalNumber: number;
	questionAsync: boolean;
}

const Progress: React.FC<iProgressProps> = ({
	actualNumber,
	totalNumber,
	questionAsync,
}) => {
	return (
		<Container questionAsync={questionAsync}>
			<ProgressNumber>
				{actualNumber}/{totalNumber}
			</ProgressNumber>
			<ProgressBar>
				<Preenchimento
					style={{ width: `${(actualNumber * 100) / totalNumber}%` }}
				/>
			</ProgressBar>
		</Container>
	);
};

export default memo(Progress);
