import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";
import DinoHappy from "../../../assets/dinoHappyAsync.svg";
import history from "../../../routes/history";
import moment from "moment";
import { persistStorage } from "../../../utils/base_async_storage";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalCadastroCartao: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const classes = useStyles();
	const visible_modal =  useSelector(
		(state: any) => state.app.visible_modal_aviso_cadastro_cartao
	);
	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);
   
	const ModalAvisoCartaoClose = () => {
		dispatch(AppActions.set_modal_aviso_cadastro_cartao(false));
		
	};

    async function handlerSetDateModalCadastroCartao (){
		const Today = moment(new Date()).format('YYYY-MM-DD').toString()

		await persistStorage({
			res: Today,
			tag: 'set_modal_card_worning_today'
		})
	};

	const handleHideCreditCardModal = async () => {
		await persistStorage({
			res: true,
			tag: 'set_hide_modal_credit_card'
		})
	}

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={visible_modal}
			>
				<Fade in={visible_modal}>
					<Container>
						<div className="divText">
							<div className="ContainerDino">
								<img
									src={DinoHappy}
									style={{
										width: 100,
										height: 100
									}}
								/>
							</div>
							<h1 className="title">
								{current_language?.precisamos_de_voce}
							</h1>
							<p className="text">
								{current_language?.cadastre_seu_cartao}
							</p>
							<div className="ContainerButtons">
								<div 
									className="buttonCadastrar"
									onClick={()=> 	{
										handlerSetDateModalCadastroCartao()
										history.push('/metodo_pagamento'),	
										dispatch(AppActions.set_modal_aviso_cadastro_cartao(false))
									}}
								>
								<p className="txtCadastrar">{current_language?.cadastrar}</p>
								</div>
								{!!config_servicos?.SERVICO_SUPERLOGICA && (
									<div 
										className="buttonCancelar"
										onClick={()=> {
										handleHideCreditCardModal()
										ModalAvisoCartaoClose()
									}}>
										<p className="txtCancel">{current_language?.nao_mostrar_novamente}</p>
								</div>
								) || (
									<div 
										className="buttonCancelar"
										onClick={()=> {
										handlerSetDateModalCadastroCartao()
										ModalAvisoCartaoClose()
									}}>
										<p className="txtCancel">{current_language?.entendi}</p>
								</div>
								)}
							</div>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalCadastroCartao;