import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Content = styled.div`

width: 100%;

	.divTable {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.legenda_descricao {
			font-size: 10px;
			width: 60%;
			align-self: center;
		}

		.divExerciceNumber {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 10%;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
			border-radius: 4px;
			background-color: white;
			padding-right: 0.5rem;
			padding-left: 0.5rem;
			padding-top: 0.2rem;
			padding-bottom: 0.2rem;
			margin-top: -105px;

			.exerciceNumber {
				font-family: Roboto;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: #000000;
			}
		}

		.table {
			width: 100%;
			height: 6rem;
			border: 1px solid #e5e5e5;
			box-sizing: border-box;
			border-radius: 0.6rem;
			margin-top: 1rem;
			border-collapse: collapse;

			thead {
				background-color: #F1F2F3;
			}
		}

		.data {
			display: flex;
			flex-direction: column;
			width: 18%;
			align-items: center;
		}

		.prop {
			color: var(--Neutras-Grey-K-70, #3D4259);
			text-align: center;
			font-family: "NotoSans-Regular";
			font-size: 0.65rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			padding: 0.2rem;
		}

		.value {
			font-family: "NotoSans-Regular";
			font-weight: normal;
			font-size: 11px;
			color: #5c5b5b;
			border-bottom: 1px solid #F1F2F3;
			padding: 0.3rem;

			.colorSuccess {
				color: #39e664;
			}

			.colorFail {
				color: ${Colors.tertirary_red_1_dark};
			}

			svg {
				margin-left: 0.5rem;
			}
		}
	}
`;

export const Exercice = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	.selectTitle {
		width: 100%;
		color: #c4c5cc;
		font-size: 0.8rem;
		border-bottom: "2px solid red";
	}

	.title {
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 23px;
		color: #5c5b5b;
	}

	.btnEditReport {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		align-items: center;
		text-align: right;
		color: #e5005b;
		background-color: transparent;
		border: 0;

		.selectTitle {
			box-shadow: 0px 2px 3px 0px #000000;
			border-radius: 6px;
			color: ${Colors.primary_blue_k_dark_2};
			padding-left: 5px;
		}

		.title {
			font-family: Roboto;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			line-height: 23px;
			color: #5c5b5b;
		}

		.btnEditReport {
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			display: flex;
			align-items: center;
			text-align: right;
			color: ${Colors.primary_blue_k_main};
			background-color: transparent;
			border: 0;

			.rmv {
				margin-left: 0.5rem;
			}
		}
	}

	.DivSubTitle {
		display: flex;
		flex-direction: row;
		margin-bottom: 2rem;

		.subTitleFirst {
			font-family: Roboto;
			font-style: italic;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #5c5b5b;
			margin-right: 42%;
		}

		.subTitle {
			font-family: Roboto;
			font-style: italic;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #5c5b5b;
			margin-bottom: 4rem;
		}
	}

	.hr {
		border: none;
		height: 1px;
		color: #e0e0e0;
	}
`;

export const Charts = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	height: 13rem;
	margin-bottom: 1rem;
`;

export const ForcaMaxima = styled.div`
	display: flex;
	flex-direction: row;
	width: 50%;
	align-items: center;

	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.25rem;
		height: 3.43rem;
		background: #ffffff;
		border: 0.4px solid #e5e5e5;
		box-sizing: border-box;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		margin-right: 0.87rem;
	}
`;

export const ForcaMed = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 3.43rem;
		width: 1.25rem;
		background: #ffffff;
		border: 0.4px solid #e5e5e5;
		box-sizing: border-box;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		margin-right: 0.87rem;
	}

	.divForca {
		width: 5%;
		height: 15%;
		transform: rotate(-90deg);

		.force {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 14px;
			color: #5c5b5b;
			white-space: nowrap;
			margin-left: -15px;
			padding: 0px;
		}
	}
`;
