import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const ContainerFirstStage =  styled.div`
	display: flex; 

	width: 100%; 
	flex-direction: row; 
	align-items: center;
	justify-content: center;

	.ContainerAvatar {
		display: flex; 
		width: 3.437rem;
		height: 3.437rem;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background-color: ${Colors?.primary_blue_k_main}
	}
	.UserImage {
		object-fit: cover;
		display: flex;
		width: 3.437rem;
		height: 3.437rem;
		border-radius: 50%;
		align-items: center;
		justify-content: center;
	}
	.UserName {
		font-family: "NotoSans-regular";
		font-size: 1rem;
		font-weight: 700;
		color: ${Colors?.primary_blue_k_main};
		margin-left: 0.75rem
	}
`;

export const ContainerOptionsFirstStage = styled.div`
	display: flex; 
	align-self: center;
	width: 70%;
	height: 40%;
	flex-direction: row; 
	align-items: center; 
	justify-content: space-around;
	margin-top:50px;
`;

export const ContainerSecondStageSecond = styled.div`
	display: flex; 
	width: 80%;
	justify-content: center;
	align-items: center;

	.textArea {
		width: 88%;
		height: 200px;
		border-radius: 12px;
		border-width: 1px;
		padding-top: 16px;
		padding-bottom: 20px;
		padding-left: 16px;
		padding-right: 21px;
		font-family: "NotoSans-Regular";
		border: 1px solid;
		border-color: ${Colors.neutral_grey_k_10};
		resize: none;
		overflow: auto;

		@media (max-width: 1200px) {
			width: 80%;
		}
		@media (max-width: 740px) and (max-height: 700px) {
			height: 140px;
		}
		@media (max-height: 610px) {
			height: 160px;
		}
		@media (max-height: 600px) {
			height: 140px;
		}
		@media (max-width: 800px) {
			width: 100%;
		}
	}
`;
export const ContainerHeader = styled.div`
	width: 100%;
	align-items: center;
	justify-content: center;
	margin-top: 25px
`;

export const ContainerFooter = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 10px;

	@media (max-height: 600px) {
		bottom: -5px;
	}

	.ButtonEnvi {
		width: 70%;
		padding: 8px 30px;
		align-items: center;	
		justify-content: center; 
		border-radius:12px;
		background-color: ${Colors.primary_blue_k_main};
		cursor: pointer;
	}
	.ButtonJump {
		width:150px;
		height: 50px;
		align-items: center;
		justify-content: center; 	
	}
`;
export const ModalContainer = styled.div`
	background-color: white;
	width: 45%; 
	height: 65%;
	border-radius: 15px;
	position: relative;

	@media (min-height: 775px) {
		height: 55%;
	}

	@media (min-height: 850px) {
		height: 50%;
	}
`;
export const ModalContainerComponent = styled.div`
	display: flex; 
	align-items: center; 
	margin-top:20px; 
	margin-bottom:20px;
	flex-direction: column;
`;