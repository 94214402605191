import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
    display: flex;
    border-radius: 24px;
    width: 556px;
    /* height: 435px; */
    padding: 67px;
    align-items: center;
    flex-direction: column;
    background-color: ${Colors.neutral_very_light};
    position: relative;

    /* .close {
        width: 1.738rem;

		position: absolute;
		right: 2rem;
		top: 2rem;

		cursor: pointer;
    } */
`;
export const Image = styled.img`
    margin-top: 40px;
    height: 95px;
    width: 95px;
`;
