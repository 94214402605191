import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RelatorioActions } from "../../../../store/ducks/relatorios";
import { AccordionStyled } from "./styles";

const Accordion = ({ faqs }: any) => {
	const dispatch = useDispatch();
	const [clicked, setClicked] = useState<any>("0");

	const selected_type_av = useSelector(
		(state: any) => state.relatorios.selected_type_av
	);
	const selected_type_qv = useSelector(
		(state: any) => state.relatorios.selected_type_qv
	);
	const selected_type_fisio = useSelector(
		(state: any) => state.relatorios.selected_type_fisio
	);
	const selected_type_mult = useSelector(
		(state: any) => state.relatorios.selected_type_mult
	);

	const handleToggle = (index: any, type: any) => {
		if (clicked === index) {
			return setClicked("0");
		}
		setClicked(index);
	};

	const AccordionItem = ({ faq, active, onToggle }: any) => {
		const { titles, options, type, visible } = faq;

		const RenderItem: any = ({ option, optionType }: any) => {
			if (optionType === "multifatorial" || optionType === "fisioterapeutico") {
				return <div className="options">{option?.RESPOSTA_TIPO}</div>;
			} else {
			}
			// return (
			//     <div className="item">
			//         <div className="item-title">
			//             <p>{option.RESPOSTA_TIPO}</p>
			//         </div>
			//         <div className="item-content">
			//             <p>{option.RESPOSTA_ANAMNESE_TIPO_ATUALIZADO}</p>
			//         </div>
			//     </div>
			// );
		};

		function checkType(type: any, option: any) {
			if (type === "multifatorial" || type === "fisioterapeutico") {
				if (type === "multifatorial") {
					dispatch(RelatorioActions.set_relatorio_mult_type(option));
				} else {
					dispatch(RelatorioActions.set_relatorio_fisio_type(option));
				}
			} else {
				return false;
			}
		}

		return (
			<AccordionStyled>
				<li 
					className={`accordion_item ${active ? "active" : ""} ${
						visible ? "" : "disabled"
					}`}
				>
					<button className="button" onClick={onToggle}>
						{titles}
						<span className="control">{active ? "-" : "+"} </span>
					</button>
					<div
						className="options_wrapper"
						style={active ? { height: "200px" } : { height: "0px" }}
					>
						{options?.map((option: any, index: number) => {
							if (type == "multifatorial") {
								return (
									<div key={index}
										className={`options ${
											selected_type_mult === option?.ID_RESPOSTA_ANAMNESE_TIPO
												? "active"
												: ""
										}`}
										onClick={() =>
											dispatch(
												RelatorioActions.set_relatorio_mult_type(
													option?.ID_RESPOSTA_ANAMNESE_TIPO
												)
											)
										}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
											}}
										>
											<div
												className={`radio ${
													selected_type_mult ===
													option?.ID_RESPOSTA_ANAMNESE_TIPO
														? "active"
														: ""
												}`}
											></div>
											<div className="item">
												<div>{option?.RESPOSTA_TIPO}</div>
												<div>
													{moment(
														new Date(option?.RESPOSTA_ANAMNESE_TIPO_ATUALIZADO)
													)
														.format("DD/MM/YYYY HH:mm")
														.toString() + "h"}
												</div>
											</div>
										</div>
									</div>
								);
							} else if (type == "fisioterapeutico") {
								return (
									<div key={index}
										className={`options ${
											selected_type_fisio === option?.ID_RESPOSTA_ANAMNESE_TIPO
												? "active"
												: ""
										}`}
										onClick={() =>
											dispatch(
												RelatorioActions.set_relatorio_fisio_type(
													option?.ID_RESPOSTA_ANAMNESE_TIPO
												)
											)
										}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
											}}
										>
											<div
												className={`radio ${
													selected_type_fisio ===
													option?.ID_RESPOSTA_ANAMNESE_TIPO
														? "active"
														: ""
												}`}
											></div>
											<div className="item">
												<div>{option?.RESPOSTA_TIPO}</div>
												<div>
													{moment(
														new Date(option?.RESPOSTA_ANAMNESE_TIPO_ATUALIZADO)
													)
														.format("DD/MM/YYYY HH:mm")
														.toString() + "h"}
												</div>
											</div>
										</div>
									</div>
								);
							} else if (type == "avalicao") {
								return (
									<div key={index}
										className={`options ${
											selected_type_av ===
											option?.ID_RESPOSTA_ANAMNESE_AVALIACAO
												? "active"
												: ""
										}`}
										onClick={() =>
											dispatch(
												RelatorioActions.set_relatorio_avaliacao_type(
													option?.ID_RESPOSTA_ANAMNESE_AVALIACAO
												)
											)
										}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
											}}
										>
											<div
												className={`radio ${
													selected_type_av ===
													option?.ID_RESPOSTA_ANAMNESE_AVALIACAO
														? "active"
														: ""
												}`}
											></div>
											<div className="item">
												{moment(
													new Date(
														option?.RESPOSTA_ANAMNESE_AVALIACAO_ATUALIZADO
													)
												)
													.format("DD/MM/YYYY HH:mm")
													.toString() + "h"}
											</div>
										</div>
									</div>
								);
							} else if (type == "qualidade_vida") {
								return (
									<div key={index}
										className={`options ${
											selected_type_qv === option?.ID_RESPOSTA_SF_QUALIDADE
												? "active"
												: ""
										}`}
										onClick={() =>
											dispatch(
												RelatorioActions.set_relatorio_qv_type(
													option?.ID_RESPOSTA_SF_QUALIDADE
												)
											)
										}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
											}}
										>
											<div
												className={`radio ${
													selected_type_qv === option?.ID_RESPOSTA_SF_QUALIDADE
														? "active"
														: ""
												}`}
											></div>
											<div className="item">
												{moment(
													new Date(option?.RESPOSTA_SF_QUALIDADE_ATUALIZADO)
												)
													.format("DD/MM/YYYY HH:mm")
													.toString() + "h"}
											</div>
										</div>
									</div>
								);
							}
							// <RenderItem option={option} optionType={type} key={index} />;
						})}
					</div>
				</li>
			</AccordionStyled>
		);
	};

	return (
		<AccordionStyled>
			<ul className="accordion">
				{faqs.map((faq: any, index: number) => (
					<AccordionItem
						onToggle={() => handleToggle(index, faq.type)}
						active={clicked === index}
						key={index}
						faq={faq}
					/>
				))}
			</ul>
		</AccordionStyled>
	);
};

export default Accordion;

