import React, { memo, useEffect, useState } from "react";
import { InputText } from "./styles";
import { QuestionnaireProps } from "../../../../../interfaces/pacientes.interface";
import { useDispatch, useSelector } from "react-redux";
import { PacAvalActions } from "../../../../../store/ducks/manage_pac_aval";

interface questionProps {
	item: QuestionnaireProps;
	autoFocus?: boolean;
	isDisabled?: boolean;
}

const TextInput: React.FC<questionProps> = ({
	item,
	autoFocus = false,
	isDisabled = false,
}) => {
	const dispatch = useDispatch();
	const array_respostas = useSelector(
		(state: any) => state.pac_aval.array_respostas
	);
	const array_resposta = useSelector(
		(state: any) => state.pac_aval.array_resposta
	);

	const [value, setValue]: any = useState("");

	useEffect(() => {
		const valor = array_respostas?.filter(
			(e: any) => e?.QUESTIONARIO_LABEL === item?.QUESTIONARIO_LABEL
		);
		const resp = valor[0]?.QUESTIONARIO_RESPOSTA;
		resp != undefined && setValue(resp);
	}, []);

	useEffect(() => {
		return () => {
			setValue("");
		};
	}, []);

	useEffect(() => {
		if (item.QUESTIONARIO_LABEL === "IMC") {
			const altura = array_respostas?.find(
				(e: any) => e?.QUESTIONARIO_LABEL === "ALTURA"
			)?.QUESTIONARIO_RESPOSTA[0];
			const peso = array_respostas?.find(
				(e: any) => e?.QUESTIONARIO_LABEL === "PESO"
			)?.QUESTIONARIO_RESPOSTA[0];
			const imc = peso / (altura * altura);
			if (imc.toString() !== "NaN" && altura > 0 && peso > 0) {
				setValue(imc.toFixed(2).toString());
			}
		}
	}, [array_resposta, array_respostas]);

	useEffect(() => {
		if (item.QUESTIONARIO_LABEL === "IMC") {
			handleTextInput(item, value);
		}
	}, [value]);

	const handleTextInput = (item: QuestionnaireProps, text: string) => {
		if (text != "") {
			const dataObj = {
				QUESTIONARIO_LABEL: `${item.QUESTIONARIO_LABEL}`,
				QUESTIONARIO_RESPOSTA: [text],
			};
			const new_array = array_respostas.filter(
				(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
			);
			const new_array_resposta = array_resposta.filter(
				(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
			);
			dispatch(PacAvalActions.set_array_respostas([...new_array, dataObj]));
			dispatch(
				PacAvalActions.set_array_resposta([...new_array_resposta, dataObj])
			);
		} else {
			const new_array = array_respostas.filter(
				(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
			);
			const new_array_resposta = array_resposta.filter(
				(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
			);
			dispatch(PacAvalActions.set_array_respostas([...new_array]));
			dispatch(PacAvalActions.set_array_resposta([...new_array_resposta]));
		}
	};

	const Mask = (palavra: string) => {
		if(item.QUESTIONARIO_LABEL === "HISTORICO_QUEDA_QUANTOS" ||
		item.QUESTIONARIO_LABEL === "TEMPO_CENTRO_TREINAMENTO"){
			return palavra.replace(/[^0-9]/g, '');
		}
		if (item.QUESTIONARIO_LABEL === "ALTURA") {
			return palavra
				.replace(/\D/g, "")
				.replace(/(\d{1})(\d)/, "$1.$2")
				.replace(/(\d{1})(\d)/, "$1$2");
		} else if (
			item.QUESTIONARIO_LABEL === "PESO" ||
			item.QUESTIONARIO_LABEL === "CIRCUNFERENCIA_CINTURA" ||
			item.QUESTIONARIO_LABEL === "CIRCUNFERENCIA_ALTURA"
		) {
			let v = palavra;
			const integer = v.split(".")[0];
			v = v.replace(/\D/, "");
			v = v.replace(/^[0]+/, "");

			if (v.length <= 2 || !integer) {
				if (v.length === 1) v = "0.0" + v;
				if (v.length === 2) v = "0." + v;
			} else {
				v = v.replace(/^(\d{1,})(\d{2})$/, "$1.$2");
			}
			return v;
		} else if (item.QUESTIONARIO_LABEL === "LAUDO_MEDICO") {
			return palavra
				.replace(/\D/g, "")
				.replace(/(\d{1})(\d)/, "$1-$2")
				.replace(/(\d{1})(\d)/, "$1$2")
				.replace(/(\d{1})(\d)/, "$1$2");
		} else {
			return palavra;
		}
	};

	return (
		<InputText
			onChange={(e) => setValue(Mask(e.target.value))}
			value={value}
			onBlur={() => handleTextInput(item, value)}
			placeholder={`${item.QUESTIONARIO_PERGUNTA_OPCOES}`}
			autoFocus={autoFocus}
			style={{ backgroundColor: `${isDisabled && "#EDEDED"}` }}
			disabled={isDisabled}
			maxLength={
				item.QUESTIONARIO_LABEL === "ALTURA" ||
				item.QUESTIONARIO_LABEL === "CIRCUNFERENCIA_CINTURA" ||
				item.QUESTIONARIO_LABEL === "CIRCUNFERENCIA_ALTURA"
					? 5
					: item.QUESTIONARIO_LABEL === "PESO"
					? 6
					: item.QUESTIONARIO_LABEL === "LAUDO_MEDICO"
					? 10
					: 100
			}
		/>
	);
};

export default memo(TextInput);
