import { Fade } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Celular from "../../../assets/icons/celular.svg";
import Datanasc from "../../../assets/icons/datanasc.svg";
import emailGray from "../../../assets/icons/emailGray.svg";
import Fechar from "../../../assets/icons/fechar.svg";
import cpfUser from "../../../assets/icons/cpfAvaliador.svg";
import user from "../../../assets/icons/user.svg";
import Cpf from "../../../assets/icons/user.svg";
import { TextsProps } from "../../../database/txt.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { AppActions } from "../../../store/ducks/app";
import { TypesErrorRequest } from "../../../utils/types";
import FotoPerfilMenor from "../../FotoPerfilMenor";
import ToastAlert from "../../toast";
import { AmplitudeLog } from "../../../utils/amplitude";
import handleMaskCPF from "../../MascaraCPF";
import handleMaskPhone from "../../MascaraTel";

import { Container, ContainerAdm, ContainerSwitck, ContainerTwoLabels, ConteinerToggle, Header } from "./styles";
import Text from '../../../components/Texts/Text';
import { Colors } from "../../../styles/colors";
import {ReactComponent as Close} from '../../../assets/kinologyIcons/svg/close.svg';
import Form from "../../Form";
import InputTextEntryForm from '../../../components/Inputs/inputForm';
import FormSubmiteButton from "../../Buttons/FormSubmitButton";

interface Usuario {
	celular: string;
	cpf: string;
}

const EditarAvaliador = () => {
	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			modal: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			paper: {
				backgroundColor: theme.palette.background.paper,
				border: "0",
				boxShadow: theme.shadows[5],
			},
		})
	);
	const dispatch = useDispatch();
	const classes = useStyles();

	const [us, setUs] = useState<Usuario>({} as Usuario);
	
	const paciente: PacientesProps = useSelector(	(state: any) => state.app.is_open_avaliador);
	const [adm, setAdm] = useState(paciente.USUARIO_NIVEL == 1 ? true : false);

	const current_language: TextsProps = useSelector((state: any) => state.configs.current_language);
	
	const is_open = useSelector(	(state: any) => state.app.is_open_editar_avaliador);
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const type_toast = useSelector((state: any) => state.app.type_toast);
	
	const hendleChange2 = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			setUs({
				...us,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		},
		[us]
	);
	
	const HandleCondition = () => {
		if (adm == true) {
			setAdm(false);
		} else {
			setAdm(true);
		}
	};

	const ModalEditarPacienteClose = () => {
		dispatch(AppActions.set_modal_editar_avaliador(false));
	};

	const methods = useForm();

	const [aprovado, setAprovado] = useState(false);

	const onSubmit = (data: any) => {
		setAprovado(true);
		const dat_request = {
			USUARIO_NIVEL: adm ? 1 : 2,
			USUARIO_NOME: data.nome,
			USUARIO_EMAIL: data.email,
			USUARIO_CONTATO: data.celular,
			USUARIO_CPF: data.cpf,
		};
		AmplitudeLog('edicao_concluida', {
			body: dat_request
		})
		dispatch(AddPacAvalActions.set_edit_paci(paciente));
		dispatch(AddPacAvalActions.request_add_evaluetor(dat_request));
	};
	
	useEffect(() => {
		methods.setValue("nome", paciente?.USUARIO_NOME);
		methods.setValue("email", paciente?.USUARIO_EMAIL);
		methods.setValue(
			"celular",
			paciente?.USUARIO_CONTATO?.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3")
		);
		methods.setValue(
			"cpf",
			paciente?.USUARIO_CPF?.replace(
				/(\d{3})(\d{3})(\d{3})(\d{2})/,
				"$1.$2.$3-$4"
			)
		);
	}, [paciente]);



	useEffect(() => {
		if (type_toast == TypesErrorRequest.SUCCESS) {
			ModalEditarPacienteClose();
		}
		if (is_open_toast) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open}
				closeAfterTransition
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open}>
					<Container>
						{is_open_toast && type_toast == TypesErrorRequest.FAIL ? (
							<ToastAlert />
						) : (
							""
						)}
						<Header>
							<Close  
								onClick={()=> {
									ModalEditarPacienteClose(), 
									AmplitudeLog('edicao_cancelada')
								}}
								color={Colors?.primary_blue_k_main} 
								style={{
									position: 'absolute', 
									right: 32,
									top:22,
									cursor: 'pointer'
								}}
							/>
							<Text 
								title={current_language?.menu_lateral_avaliadores}
								fontFamily="NotoSans-Bold"
								fontSize={1.37}
								fontWeight={700}
								textColor={Colors?.primary_blue_k_main}
								marginTop={15}
							/>
						</Header>
						<Form 	methods={methods} 	onSubmit={(data)=>{onSubmit(data)}}>
							<ContainerTwoLabels>
								<InputTextEntryForm 
									type="text"
									width={48.5}
									title={current_language?.nome_completo + '*'}
									placeholder={current_language?.placeholder_digite}
									fontFamily="NotoSans-SemiBold"
									fontWeight={600}
									typeInputName="nome" 
								/>
								<InputTextEntryForm 
									type="number"
									width={48.5}
									title={'CPF*'}
									placeholder={'__.__.__-_'}
									leftIcon="DigitalIcon"
									fontFamily="NotoSans-SemiBold"
									fontWeight={600}
									typeInputName="cpf" 
								/>
							</ContainerTwoLabels>
							<InputTextEntryForm 
								type="text"
								width={100}
								title={'E-mail*'}
								placeholder={current_language?.placeholder_digite}
								leftIcon="EmailIcon"
								fontFamily="NotoSans-SemiBold"
								fontWeight={600}
								marginTop={24}
								typeInputName="email" 
							/>
							<InputTextEntryForm 
								type="number"
								width={100}
								title={current_language?.primeiro_acesso_celular + '*'}
								placeholder={'( ) 00000-0000'}
								fontFamily="NotoSans-SemiBold"
								fontWeight={600}
								leftIcon="Phone"
								marginTop={24}
								typeInputName="celular" 
							/>
							<ContainerSwitck>
								<ConteinerToggle>
									<label className="switch">
										<input
											type="checkbox"
											checked={adm}
											onChange={HandleCondition}
										/>
										<span className="slider round"></span>
									</label>
								</ConteinerToggle>
								<ContainerAdm>
									<Text 
										title={'Administrador'}
										fontFamily="NotoSans-Bold"
										fontSize={1.25}
										fontWeight={700}
										textColor={Colors?.primary_blue_k_main}
										textAlign="left"
										marginLeft={12}
									/>
									<Text 
										title={current_language?.avaliador_adminstrador}
										fontFamily="NotoSans-Regular"
										fontSize={0.87}
										fontWeight={400}
										textAlign="left"
										marginLeft={12}
										textColor={Colors?.neutral_grey_k_80}
									/>
								</ContainerAdm>
							</ContainerSwitck>
							<div style={{display: 'flex', width: '100%',justifyContent: 'center'}}>
								<FormSubmiteButton
									type="submit"
									onClick={()=> methods.trigger()}
									disabled={Object.values(methods.watch()).every((item: string)=> item != undefined && item != '')
										? false
										: true
									}
									
									width={80}
									paddingTop={10}
									paddingBottom={10}
									marginTop={40}
									fontFamily="Kanit-Medium"
									typeButtonComponent="primary"
									activityIndicator={aprovado}
									title={current_language?.menu_lateral_botao_salvar}
								/>

							</div>
						</Form>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default EditarAvaliador;
