import React from "react";

type type_sexo = "masculino" | "feminino";
type type_posicao = "frontal" | "dorsal";
type type_agrupamento = "superior" | "inferior";
type type_cor = "vermelho" | "verde" | "amarelo" | "cinza" | "azul";

interface GetIconProps {
	sexo: type_sexo;
	posicao: type_posicao;
	agrupamento: type_agrupamento;
	musculo: string;
	cor_direito: type_cor;
	cor_esquerdo: type_cor;
	key: number;
	classe: string;
}

const GetMuscleIcon: React.FC<GetIconProps> = ({
	sexo,
	posicao,
	agrupamento,
	musculo,
	cor_direito,
	cor_esquerdo,
	key,
	classe,
}) => {
	const translateColors: any = {
		NEGATIVO: "vermelho",
		POSITIVO: "verde",
		NEUTRO: "cinza",
		INTERMEDIARIO: "amarelo",
		INFO: "azul",
	};



	const imagePathDireito = `/agrupamentos_unificado/${sexo}_${posicao}_${agrupamento}_${musculo}_direito_${translateColors[cor_direito]}.svg`;
	const imagePathEsquerdo = `/agrupamentos_unificado/${sexo}_${posicao}_${agrupamento}_${musculo}_esquerdo_${translateColors[cor_esquerdo]}.svg`;

	return (
		<>
			<img key={key} className={classe} src={imagePathDireito} />
			<img key={key} className={classe} src={imagePathEsquerdo} />
		</>
	);
};

export default GetMuscleIcon;
