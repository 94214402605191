import styled from "styled-components";
import { Colors } from "../../../styles/colors";
import  '../../../styles/fonts.css';

export const Conteiner = styled.div`
	height: 100vh;
	display: flex;
	color: rgba(140, 142, 134, 0, 8);
	justify-content: center;

	overflow: hidden;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	width: 50%;
	height: 100%;
	background-color: white;

	z-index: 10;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.logo {
		margin-top: 3rem;
		width: 121px;
	}

	.groupForm {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.interativos {
		width: 100%;
		height: 70%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 1500px) {
			height: 70%;
		}
	}

	.Textos {
		margin-bottom: 20px;
		width: 70%;
	}

	.h1 {
		font-size: 32px;
		color:${Colors?.primary_blue_k_main};
		margin-bottom: 1.5vh;
		font-family: 'NotoSans-Bold'
	}
	.subtitle {
		font-size: 24px;
		font-weight: 400;
		font-family: 'NotoSans-Regular'
	}

	form {
		width: 70%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.globalInput {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.input {
		width: 100%;
		margin-left: 0.5vw;
		background-color: white;
		color: #4d4d4d;
		font-weight: 500;
		border: none;
	}

	.icon {
		margin-bottom: 5px;
	}

	.eyeButton {
		background-color: white;
		border: none;
	}

	.divhr {
		width: 100%;
		margin-bottom: 7vh;
		margin-top: 0.5vh;
	}

	.hr {
		width: 100%;
		margin-top: 0.5vh;
		background-color: #e0e0e0;
		color: #e0e0e0;
		border: none;
		height: 2px;
	}

	.hrcor {
		background-color: ${Colors.tertirary_red_1_dark};
		color: ${Colors.tertirary_red_1_dark};
	}

	.error {
		margin-top: 0.5rem;
		color: ${Colors.tertirary_red_1_dark};
	}

	.divhr2 {
		width: 100%;
		margin-bottom: 3vh;
	}

	.hr2 {
		width: 100%;
		margin-top: 1vh;
		background-color: #e0e0e0;
		color: #e0e0e0;
		border: none;
		height: 2px;
	}

	.hr2cor {
		background-color: ${Colors.tertirary_red_1_dark};
		color: ${Colors.tertirary_red_1_dark};
	}

	.DivRL {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 7vh;
	}

	.divLembrarLogin {
		display: flexbox;
		margin-top: -1.4vh;
	}

	.check {
		margin-bottom: 1.6vh;
		padding: 0;
		padding-right: 0.5vw;
		margin-top: 7px;
	}

	.label {
		color: #5c5b5b;
		padding: 0;
	}

	.divForget {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	.forget {
		display: flex;
		text-decoration: underline;
		color: ${Colors.primary_blue_k_main};
		background-color: white;
		border: none;
		margin-top: 1rem;
	}

	.LoginCadastro {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.enviar {
		width: 100%;
		height: 50px;
		background-color: ${Colors?.primary_blue_k_main};
		color: white;
		font-weight: 700;
		border: none;
		border-radius: 8px;
		text-transform: uppercase;
		margin-bottom: 1rem;

		transition: all 0.2s ease;
	}

	.enviar:hover {
		background: ${Colors?.primary_blue_k_dark_2};
	}

	.link {
		width: 100%;
	}

	.cadastro {
		width: 100%;
		height: 50px;
		background-color: white;
		color: ${Colors.primary_blue_k_main};
		font-weight: 700;
		border: solid 3px;
		border-color: ${Colors.primary_blue_k_main};
		margin-bottom: 3vh;
		border-radius: 8px;
		text-align: center;
		place-content: center;
		text-transform: uppercase;

		transition: all 0.2s ease;
	}

	.cadastro:hover {
		border-color: ${Colors.primary_blue_k_dark_2};
		color: ${Colors.primary_blue_k_dark_2};
	}

	.global {
		width: 25%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 40px;

		button,
		img {
			cursor: pointer;
		}
	}

	.idiomaAjuda {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.imgIA {
		width: 32px;
		margin-bottom: 0.6rem;
	}

	.inputIA {
		background-color: white;
		border: none;
		margin-top: 1vh;
<<<<<<< HEAD
		font-family: 'NotoSans-Medium';
		font-weight: 500;
		color: ${Colors?.primary_blue_k_main};
=======
		color: #001574;
>>>>>>> origin/kinology
	}
`;

export const Background = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
<<<<<<< HEAD
	width: 50%;
=======
	justify-content: left;
	width: 67%;

>>>>>>> origin/kinology
`;
