import {
	Backdrop,
	Fade,
	Modal,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";

import {
	Close,
	Container,
	Subtitle,
} from "../../../styles/styles_modais_padroes";
import { AppActions } from "../../../store/ducks/app";
import styled from "styled-components";
import { Icon } from "../../../styles/global";
import FitaExtensora from "../../../assets/icons/fita_extensora.svg";
import AlcaMao from "../../../assets/icons/alca_mao.svg";
import SetaEsquerda from "../../../assets/icons/arrowLeft.svg";
import SetaDireita from "../../../assets/icons/arrowRight.svg";
import Tuto from "../../../assets/tutorial_default_2.png";
import Temp from "../../../assets/icons/temp_icon.svg";
import { DescriptionDesk } from "../../../styles/styles_text";
import VideoIframe from "./YoutubeIframe";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { TextsProps } from "../../../database/txt.interface";
import { linksForVideo } from "../../../database/tutoriais";
import { tutorialImages } from "../../../utils/tutorials";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

enum TypeView {
	VIDEO = 'Video',
	IMAGE = 'Image',
};
interface PropsLink  {
	id: number, 
	name: string, 
	link: string
};


const Tutorial: React.FC = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	type OptionSeleted = "video" | "imagem";
	const [option_selected, set_option_selected] =
		useState<OptionSeleted>("video");

	const is_open_tutorial = useSelector(
		(state: any) => state.app.is_open_tutorial.status
	);

	const current_tutorial: Exercicios2Props = useSelector(
		(state: any) => state.app.is_open_tutorial.exercicio
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_tutorial(false, null));
	};

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);

	const idExercicio: any = exercicios.find(
		(e: any) => current_tutorial == e.ID_EXERCICIO
	);

	const [position, setPosition] = useState(1);
	const [widthImage, setWidthImage] = useState(0);
	const [heightImage, setHeightImage] = useState(0);
	const [ OptionView, setOptionView] = useState(TypeView.VIDEO)
	const [loading, setLoading] = useState<boolean>(true);
	const [infoLink, setInfoLink] = useState<PropsLink>()

	const ReplacePosition = (way: string) => {
		if(way === 'foward') setPosition(position + 1);
		if(way === 'foward' && position === tutorialImages[current_tutorial?.EXERCICIO_TITULO_PT].length) setPosition(1);
		if(way === 'back') setPosition(position - 1);
		if(way === 'back' && position === 1) setPosition(tutorialImages[current_tutorial?.EXERCICIO_TITULO_PT].length);
	};

	useEffect(()=> {
		const GetInfoLink = ()=> {
			let dinamicLink
			dinamicLink = linksForVideo?.find((e:PropsLink)=> current_tutorial?.EXERCICIO_TITULO_PT ==  e.name  )
			if(!!dinamicLink){
				setInfoLink(dinamicLink)
			}else{
			setInfoLink(undefined)
			}
		};
		GetInfoLink()
	},[current_tutorial, idExercicio, loading])

	const onLoad = (imageUrl: any) => {
		const img = new Image();
		img.src = imageUrl;
		img.onload = () => {
			setHeightImage(img.height);
			setWidthImage(img.width);
		};
	};

	const GreenOne = () => {
		return	<CircleGreen/>
	}
	
	const GrayOne = () => {
		return	<CircleGray/>
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open_tutorial}
			onClose={ModalCadastroClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open_tutorial}>
				<Container style={{ height: "90vh" }}>
					<Close
						className="close"
						src={buttonClose}
						onClick={ModalCadastroClose}
						alt="Close"
					/>
					<Subtitle style={{ textAlign: "center" }}>
						{current_tutorial?.EXERCICIO_TITULO_PT}
					</Subtitle>
					<ContainerTab>
						<Option
							active={option_selected == "video" ? true : false}
							onClick={() => set_option_selected("video")}
						>
							Video
						</Option>
						<Option
							active={option_selected == "imagem" ? true : false}
							onClick={() => set_option_selected("imagem")}
						>
							{current_language?.imagem}
						</Option>
					</ContainerTab>
					{infoLink && option_selected == "video" ? (
						<>
							<VideoIframe videoId={infoLink?.link} title={infoLink?.name} />
							<Subtitle style={{ textAlign: "center", marginTop: "1rem" }}>
								{current_language?.acessorios_principais}
							</Subtitle>
							<DivAcessorio>
								<img style={{ marginRight: "1rem" }} src={FitaExtensora} />
								<text>Fita extensora</text>
							</DivAcessorio>
							<DivAcessorio>
								<img style={{ marginRight: "1rem" }} src={AlcaMao} />
								<text>Alça de mão</text>
							</DivAcessorio>
						</>
					) : (
						<>
							<ContainerTutorial>
								<PrimaryContainer>
									<Icon src={SetaEsquerda} onClick={() => ReplacePosition('back')}/>
									<DivTutorial>
										<DescriptionDesk style={{ marginTop: "1rem" }}>
										{current_tutorial && tutorialImages[current_tutorial?.EXERCICIO_TITULO_PT].length % 2 === 0 && (
											position % 2 && (
												current_language?.posicionamento_inicial
											) || (
												current_language?.posicionamento_final
											)
										)|| (current_language?.posicao)}
										</DescriptionDesk>
											{current_tutorial && position && tutorialImages[current_tutorial?.EXERCICIO_TITULO_PT]?.map((image: any, index: any) => {
													if (index + 1 === position) {
														return(
															<img
																onLoad={() => onLoad(image)}
																className="imgExer"
																src={image}
																style={{ height: "100%", width: "100%", objectFit: 'cover' }}
															/>
														)
													}
												})}
										{/* <img style={{ height: "100%", width: "100%" }} src={Tuto} /> */}
									</DivTutorial>
									<Icon src={SetaDireita} onClick={() => ReplacePosition('foward')}/>
								</PrimaryContainer>
								<ContainerSteps>
								{/* <Icon src={Temp} /> */}
									{current_tutorial && position && tutorialImages[current_tutorial?.EXERCICIO_TITULO_PT]?.map((image: any, index: any) => {
										return index + 1 === position ? (
													<GreenOne/>
											) : (
													<GrayOne/>
											)
									})}
								</ContainerSteps>
							</ContainerTutorial>
						</>
					)}
				</Container>
			</Fade>
		</Modal>
	);
};

const ContainerTab = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 1rem;
`;

interface ActiveProps {
	active: boolean;
}
const Option = styled.text<ActiveProps>`
	font-family: "NotoSans-regular";
	font-size: 1rem;
	line-height: 38.4px;
	width: 50%;
	text-align: center;
	cursor: pointer;

	border-bottom: ${({ active }) =>
		active ? "3px solid #00d084" : "3px solid #dedfe3"};
	font-weight: ${({ active }) => (active ? 700 : 400)};
`;

const DivAcessorio = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1rem;
`;

const PrimaryContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80%;
	width: 100%;
`;

const ContainerTutorial = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 80%;
	width: 100%;
`;

const DivTutorial = styled.div`
	height: 100%;
	width: 50%;
	background-color: #dedfe3;
	display: flex;
	align-items: center;
	flex-direction: column;
	border-radius: 12px;
`;

const ContainerSteps = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const CircleGray = styled.div`
	width: 10px;
	height:10px;
	border-radius: 50%;
	margin-top: 12px;
	margin-right: 8px;
	background-color: #C4C4C4;
`;
const CircleGreen = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-top: 12px;
	margin-right: 8px;
	background-color: #00A377;
`

export default Tutorial;
