import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import { Aviso, Body, Getphotos, InsetPhotos } from "./styles";
import { QuestionnaireProps } from '../../../../../interfaces/pacientes.interface';
import { useDispatch, useSelector } from 'react-redux';
import { PacAvalActions } from '../../../../../store/ducks/manage_pac_aval';
import { TextsProps } from '../../../../../database/txt.interface';

interface questionProps {
	item: QuestionnaireProps,
}

const InputPhoto: React.FC<questionProps> = ({ item }) => {

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const dispatch = useDispatch();
	const array_respostas = useSelector(
		(state: any) => state.pac_aval.array_respostas
	);
	const array_resposta = useSelector(
		(state: any) => state.pac_aval.array_resposta
	);

	const Img = useSelector(
		(state: any) => state.pac_aval.fotos_input
	);

	const [aviso, setAviso] = useState(false)
	
	useLayoutEffect(() => {
		const imgs = array_respostas?.filter((e: any) => e?.QUESTIONARIO_LABEL === item?.QUESTIONARIO_LABEL);
		if (imgs.length === 1) {
			dispatch(PacAvalActions.set_foto_input(imgs[0]?.QUESTIONARIO_RESPOSTA));
		}
	}, [])

	const handleTextInput = (item: QuestionnaireProps, array: any) => {
		if (array.length > 0) {
			const dataObj = {
				"QUESTIONARIO_LABEL": `${item.QUESTIONARIO_LABEL}`,
				"QUESTIONARIO_RESPOSTA": array
			}
			const new_array = array_respostas.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
			const new_array_resposta = array_resposta.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
			dispatch(PacAvalActions.set_array_respostas([...new_array, dataObj]));
			dispatch(PacAvalActions.set_array_resposta([...new_array_resposta, dataObj]));
		} else {
			const new_array = array_respostas.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
			const new_array_resposta = array_resposta.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
			dispatch(PacAvalActions.set_array_respostas([...new_array]));
			dispatch(PacAvalActions.set_array_resposta([...new_array_resposta]));
		}
	}

	const base64 = (e: any) => {
		const photo = e.target.files
		const arrayPhotos: any = []
		let array_cont = Img.length
		for (let i = 0; i < photo.length; i++) {
			if (
				photo[`${i}`].size >= 10485760 ||
				!(
					photo[`${i}`].name.indexOf(".png") !== -1 ||
					photo[`${i}`].name.indexOf(".jpg") !== -1 ||
					photo[`${i}`].name.indexOf(".jpeg") !== -1
				) ||
				array_cont >= 10
			) {
				setAviso(true)
				setTimeout(() => {
					setAviso(false)
				}, 2000)
			} else {
				const reader = new FileReader();
				reader.readAsDataURL(photo[`${i}`]);
				reader.onload = function () {
					arrayPhotos.push(reader.result)
					dispatch(PacAvalActions.set_foto_input([...Img, ...arrayPhotos]));
				};
				reader.onerror = function (error) {
					console.error("Error: ", error);
				};
				array_cont++
			}
		}
	}

	const excluirFoto = (img: any) => {
		const imgs = Img.filter((e: any) => e !== img)
		dispatch(PacAvalActions.set_foto_input(imgs));
	}

	useEffect(() => {
		handleTextInput(item, Img)
	}, [Img])

	return (
		<Body>
			<InsetPhotos>
				<input
					id="imgs"
					type="file"
					multiple
					size={10485760}
					onChange={(e) => base64(e)}
					accept=".png, .jpg, .jpeg"
				/>
				<label htmlFor="imgs" className="labelForInput">
					<div>
						<svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M19.2477 2.78787L0.302615 2.78787L0.302615 0.0541829L19.2477 0.0541845L19.2477 2.78787ZM0.302614 14.9941L9.77515 5.52155L19.2477 14.9941L13.8439 14.9941L13.8439 23.1316L5.70641 23.1316L5.70641 14.9941L0.302614 14.9941Z" fill="#000E4B" />
						</svg>
						<p>{item?.QUESTIONARIO_PERGUNTA}</p>
					</div>
					<svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M2.81881 0.584634L10.9563 8.72211L2.81881 16.8596L0.911584 14.9524L7.14184 8.72211L0.911584 2.49186L2.81881 0.584634Z" fill="#000E4B" />
					</svg>
				</label>
			</InsetPhotos>

			{
				Img.length > 0 &&
				<Getphotos>
					{Img.map((i: any, index: number) => {
						return (
							<div key={index}>
								<div className='foto'>
									<img src={i} />
								</div>
								<svg className='excluir' onClick={() => excluirFoto(i)} width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="7.2563" cy="6.31099" r="3.56392" fill="#FCFCFC" />
									<path fillRule="evenodd" clipRule="evenodd" d="M7.02461 0.123047C3.60678 0.123047 0.835938 2.89388 0.835938 6.31172C0.835938 9.72956 3.60678 12.5004 7.02461 12.5004C10.4424 12.5004 13.2133 9.72956 13.2133 6.31172C13.2133 2.89388 10.4424 0.123047 7.02461 0.123047ZM9.11019 5.02166C9.21268 4.91555 9.26939 4.77344 9.2681 4.62593C9.26682 4.47841 9.20765 4.3373 9.10334 4.23299C8.99903 4.12868 8.85792 4.06951 8.71041 4.06823C8.56289 4.06695 8.42078 4.12365 8.31467 4.22614L7.02461 5.51619L5.73455 4.22614C5.68266 4.1724 5.62057 4.12954 5.55193 4.10006C5.48329 4.07057 5.40947 4.05505 5.33477 4.0544C5.26006 4.05375 5.18598 4.06799 5.11684 4.09628C5.0477 4.12456 4.98488 4.16634 4.93205 4.21916C4.87923 4.27199 4.83745 4.3348 4.80917 4.40395C4.78088 4.47309 4.76664 4.54717 4.76729 4.62188C4.76794 4.69658 4.78346 4.7704 4.81295 4.83904C4.84243 4.90768 4.88529 4.96976 4.93903 5.02166L6.22909 6.31172L4.93903 7.60178C4.88529 7.65368 4.84243 7.71576 4.81295 7.7844C4.78346 7.85304 4.76794 7.92686 4.76729 8.00157C4.76664 8.07627 4.78088 8.15035 4.80917 8.21949C4.83745 8.28864 4.87923 8.35145 4.93205 8.40428C4.98488 8.4571 5.0477 8.49888 5.11684 8.52717C5.18598 8.55545 5.26006 8.56969 5.33477 8.56904C5.40947 8.56839 5.48329 8.55287 5.55193 8.52339C5.62057 8.4939 5.68266 8.45104 5.73455 8.3973L7.02461 7.10725L8.31467 8.3973C8.42078 8.49979 8.56289 8.55649 8.71041 8.55521C8.85792 8.55393 8.99903 8.49476 9.10334 8.39045C9.20765 8.28614 9.26682 8.14503 9.2681 7.99752C9.26939 7.85 9.21268 7.70789 9.11019 7.60178L7.82014 6.31172L9.11019 5.02166Z" fill="#DB0000" />
								</svg>
							</div>
						)
					})}
				</Getphotos>
			}
			<Aviso className={`${aviso ? 'aviso' : 'semAviso'}`}>*{current_language?.dez_imagens} | {current_language?.max_dez_mb}</Aviso>
		</Body>
	);
};

export default memo(InputPhoto);