import React, { useState } from 'react';

import { 
    ActivityIndicator,
    ContainerButtonActionModal,
} from './style';
import { ButtonActionModalProps } from './Models';
import {Colors} from '../../../styles/colors';
import Text from '../../Texts/Text';


const ButtonActionModal: React.FC<ButtonActionModalProps & Partial<HTMLButtonElement> > = ({
    onClick,
    disabled = true,
    typeButtonComponent,
    title,
    width,
    height,
    marginTop,
    margintBottom,
    marginLeft,
    marginRight,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    bgColor ,
    borderRadius,
    borderWidth,
    borderColor,
    justifyContent,
    alignItems,
    flexDirection,
    textColor = Colors?.neutral_very_light,
    fontSize,
    fontWeight,
    textAlign,
    fontFamily,
    testId,
    textDecoration,
    activityIndicator =  false,
    activityIndicatorColor,
    type,
    ...props
   
})=> {
    
    const [isPressed, setIsPressed] = useState(false);

    const handlePressIn = () => {
        if( typeButtonComponent == 'secondary' ){
            setIsPressed(true); 
        }
    };

    const handlePressOut = () => {
        if( typeButtonComponent == 'secondary' ){
            setIsPressed(false); 
        }
    };
    
    return (

        <ContainerButtonActionModal
            type={ type && type}
            onClick={()=> onClick && onClick()}
            testId={testId}
            disabled={disabled}
            typeButtonComponent={typeButtonComponent}
            width={width}
            height={height}
            bgColor={ typeButtonComponent == 'primary' && disabled
                ? Colors?.neutral_light_gray_3
                  : typeButtonComponent == 'primary' && !disabled
                     ? Colors?.secondary_buttoon_green
                        : bgColor
            }
            marginTop={marginTop}
            margintBottom={margintBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            borderRadius={borderRadius}
            borderWidth={borderWidth}
            borderColor={
                typeButtonComponent == 'secondary' && disabled
                ? Colors?.neutral_light_gray_3
                  : typeButtonComponent == 'secondary' && !disabled
                     ? Colors?.primary_blue_k_main
                        :borderColor
            }
            justifyContent={justifyContent}
            alignItems={alignItems}
            flexDirection={flexDirection}
            // {...props}
        >
            { activityIndicator 
                ? <ActivityIndicator activityIndicatorColor={activityIndicatorColor}/>
                : <Text
                    {...props}
                    textColor={ typeButtonComponent === 'secondary' && disabled
                        ? Colors?.neutral_light_gray_3
                        : typeButtonComponent === 'secondary' && !disabled
                            ? Colors?.primary_blue_k_main
                            : textColor
                    }
                    type={typeButtonComponent == 'primary' ? 'primary' : 'secondary'}
                    fontSize={fontSize}
                    textAlign={textAlign}
                    fontFamily={fontFamily}
                    fontWeight={fontWeight}
                    textDecoration={disabled ? 'none': textDecoration}
                    title={title}
                />
                
            }
            
        </ContainerButtonActionModal>
)
}

export default ButtonActionModal;