export const dataHelpers = [
	{
		category_pt: "Aplicativo",
		category_en: "Application",
		category_es: "Aplicación",
		helpers: [
			{
				id: 1,
				title_pt:
					"O que é um avaliador do app e como eu faço o cadastro de um?",
				title_en: "What is an app evaluator and how do I register one?",
				title_es: "¿Qué es un evaluador de la app y cómo se registra uno?",
				description_pt:
					'Avaliador do app são pessoas que vão estar junto com você nas avaliações dos pacientes. Cada avaliador tem o seu login próprio, porém todos vão compartilhar os mesmos pacientes e protocolos de treino. Todos vão ter acesso às mesmas funções do app. Para cadastrar um avaliador, clique na aba “Avaliador” localizada na topo da tela “inicio”. Logo em seguida, clique em "Adicionar avaliador", o app vai levá-lo para uma ficha de cadastro. Coloque os dados do seu avaliador. Quando finalizar, um email vai chegar para o seu avaliador concluir o cadastro. Basta seguir os passos do email e essa pessoa vai estar cadastrada como avaliador. O avaliador associado é o mesmo que está criando o cadastro, então não é mais necessário escolher. Após isso, o avaliador é o último que executou algo com o paciente.',
				description_en:
					'App assessors are people who will be with you during patient assessments. Each evaluator has their own login, but all will share the same patients and training protocols. They will all have access to the same app functions. To register an evaluator, click on the "Evaluator" tab located at the top of the "start" screen. Then click on "Add Evaluator", the app will take you to a registration form. Enter your evaluator\'s data. When you are done, an email will arrive to your evaluator to complete the registration. Just follow the steps in the email and this person will be registered as an evaluator. The associated evaluator is the same as the one who is creating the registration, so it is no longer necessary to choose one. After that, the evaluator is the last one who performed something with the patient.',
				description_es:
					'Los asesores de la aplicación son personas que le acompañarán durante las evaluaciones de los pacientes. Cada evaluador tiene su propio inicio de sesión, pero todos compartirán los mismos pacientes y protocolos de formación. Todos tendrán acceso a las mismas funciones de la aplicación. Para registrar a un evaluador, haga clic en la pestaña "Evaluador" situada en la parte superior de la pantalla de "inicio". A continuación, haga clic en "Añadir evaluador", la aplicación le llevará a un formulario de registro. Introduzca los datos de su evaluador. Cuando haya terminado, le llegará un correo electrónico a su tasador para que complete el registro. Sólo tiene que seguir los pasos del correo electrónico y esta persona se registrará como tasador. El evaluador asociado es el mismo que está creando el registro, por lo que ya no es necesario elegir. Después, el evaluador es el último que realizó algo con el paciente.',
			},
			{
				id: 2,
				title_pt: "Posso usar o app em mais de um dispositivo?",
				title_en: "Can I use the app on more than one device?",
				title_es: "¿Puedo utilizar la aplicación en más de un dispositivo?",
				description_pt:
					'Não, você só pode entrar em um dispositivo usando seu e-mail e senha cadastrados. Caso queira dar acesso a outros avaliadores, para que eles também acessem a mesma conta que você já utiliza, basta clicar na aba “Avaliador” localizada na topo da tela "início", logo em seguida, clicar em "Adicionar Avaliador", e realizar um cadastro com o email desse outro avaliador. O novo avaliador receberá um email com instruções para cadastrar uma senha e liberar o acesso ao app.',
				description_en:
					'No, you can only log into one device using your registered email and password. If you want to give access to other evaluators, so they can also access the same account you already use, just click on the "Evaluator" tab located at the top of the "start" screen, then click on "Add Evaluator", and register with this other evaluator\'s email. The new evaluator will receive an email with instructions to register a password and release access to the app.',
				description_es:
					'No, sólo puedes conectarte a un dispositivo utilizando tu correo electrónico y contraseña registrados. Si quieres dar acceso a otros evaluadores, para que también puedan acceder a la misma cuenta que tú ya utilizas, sólo tienes que hacer clic en la pestaña "Evaluador" situada en la parte superior de la pantalla de "inicio", luego hacer clic en "Añadir evaluador", y registrarte con el correo electrónico de este otro evaluador. El nuevo evaluador recibirá un correo electrónico con instrucciones para registrar una contraseña y liberar el acceso a la aplicación.',
			},
			{
				id: 3,
				title_pt: "Eu consigo colocar o Kinology na TV?",
				title_en: "Can I broadcast Kinology on TV?",
				title_es: "¿Puedo emitir Kinology en la televisión?",
				description_pt:
					"Consegue sim. Caso a sua TV seja smart é bem simples espelhar a tela do seu smartphone ou tablet para a TV (consultar manual do aparelho). Você também pode acessar o Kinology WEB pelo seu notebook e conectar ele via cabo à sua TV. Também é possível conectar seu smartphone ou tablet diretamente à TV, dependendo do modelo do seu aparelho.",
				description_en:
					"Yes, you can. If your TV is smart, it is very simple to mirror your smartphone or tablet's screen to the TV (see the manual of the device). You can also access Kinology WEB from your notebook and connect it via cable to your TV. You can also connect your smartphone or tablet directly to the TV, depending on the model of your device.",
				description_es:
					"Sí, puedes hacerlo. Si tu televisor es inteligente, es muy sencillo reflejar la pantalla de tu smartphone o tablet en el televisor (consulta el manual de tu dispositivo). También puedes acceder a Kinology WEB desde tu portátil y conectarlo por cable a tu televisor. También es posible conectar el smartphone o la tableta directamente al televisor, según el modelo del dispositivo.",
			},
			{
				id: 4,
				title_pt: "Posso cadastrar professores e colegas com quem eu trabalho?",
				title_en: "Can I register teachers and colleagues that I work with?",
				title_es:
					"¿Puedo registrar a los profesores y colegas con los que trabajo?",
				description_pt:
					"Pode e deve! Dentro da sua conta de usuário principal (administrador), você pode cadastrar os professores/fisioterapeutas que também usarão a tecnologia como avaliadores em “Início” na aba de “Avaliadores”. Assim, fica mais fácil de organizar os alunos/pacientes cadastrados e por qual profissional eles estão sendo avaliados. \nSaiba mais na pergunta “O que é um avaliador do app e como eu faço o cadastro de um?”",
				description_en:
					'Yes, you can! Within your main user account (administrator), you can register the teachers/physiotherapists who will also use the technology as evaluators under "Home" in the "Evaluators" tab. This makes it easy to organize the registered students/patients and by which professional they are being evaluated. \nLearn more in the question "What is an app rater and how do I register one?"',
				description_es:
					'Puedes y debes hacerlo. Dentro de su cuenta de usuario principal (administrador), puede registrar a los profesores/fisioterapeutas que también utilizarán la tecnología como evaluadores en "Inicio" en la pestaña "Evaluadores". Esto facilita la organización de los estudiantes/pacientes registrados y por qué profesional están siendo evaluados. \nObtenga más información en la pregunta "¿Qué es un calificador de aplicaciones y cómo lo registro?"',
			},
			{
				id: 5,
				title_pt:
					"O que é app premium? Antes não aparecia isso, o que isso quer dizer?",
				title_en:
					"What is premium app? This didn't appear before, what does it mean? ",
				title_es:
					"¿Qué es una app premium? Esto no aparecía antes, ¿qué significa?",
				description_pt:
					"App premium é o nosso serviço de assinatura. Se por algum motivo essa mensagem apareceu, é porque você não é um de nossos assinantes, para conseguir usar a função que você tinha tentado realizar, entre em contato com um de nossos consultores através do número (61) 3347-9291 e peça uma explicação dos planos. \nSe você já é nosso cliente e essa mensagem apareceu, entre em contato com o nosso suporte através do link http://wa.me/556185953414",
				description_en:
					"App premium is our subscription service. If for some reason this message appeared, it is because you are not one of our subscribers, to be able to use the function you had tried to perform, please contact one of our consultants at (61) 3347-9291 and ask for an explanation of the plans. \nIf you are already our customer and this message appeared, please contact our support through the link http://wa.me/556185953414",
				description_es:
					"App premium es nuestro servicio de suscripción. Si por alguna razón apareció este mensaje, es porque no eres uno de nuestros suscriptores, para poder utilizar la función que habías intentado realizar, ponte en contacto con uno de nuestros asesores a través del número (61) 3347-9291 y pide una explicación de los planes. \nSi ya es cliente nuestro y le ha aparecido este mensaje, póngase en contacto con nuestro servicio de asistencia a través del enlace http://wa.me/556185953414",
			},
		],
	},
	{
		category_pt: "Dinamometro",
		category_en: "Dynamometer",
		category_es: "Dinamómetro",
		helpers: [
			{
				id: 1,
				title_pt: "Não consigo conectar o dinamômetro no celular, o que fazer?",
				title_en:
					"I cannot connect the dynamometer to the cell phone, what to do?",
				title_es: "No puedo conectar el dinamómetro al móvil, ¿qué hacer?",
				description_pt:
					"O dinamômetro foi programado para conectar no dispositivo apenas quando o app estiver aberto na aba de executar um exercício. Caso esteja tentando conectar o dinamômetro através das configurações do celular, ele não vai conectar. \n\nCaso o dinamômetro não esteja conectado no seu dispositivo, tente primeiro conectar o dinamômetro em outro dispositivo. Se o dinamômetro conectar, o problema pode estar relacionado ao seu Computador. \n\nCaso tudo esteja certo com o seu dispositivo e mesmo assim não consegue conectar o dinamômetro ao dispositivo, entre em contato com a nossa equipe de sucesso do cliente através do link http://wa.me/556185953414",
				description_en:
					"The dynamometer has been programmed to connect to the device only when the app is open in the perform a workout tab. If you are trying to connect the dynamometer through the cell phone settings, it will not connect. \n\nIf the dynamometer does not connect to your device, try connecting the dynamometer to another device first. If the dynamometer connects, the problem may be related to your Computer. \n\nIf everything is right with your device and you still cannot connect the dynamometer to the device, please contact our customer success team at http://wa.me/556185953414",
				description_es:
					"El dinamómetro ha sido programado para conectarse al dispositivo sólo cuando la app está abierta en la pestaña para realizar un entrenamiento. Si intentas conectar el dinamómetro a través de los ajustes del móvil, no se conectará. \n\nSi el dinamómetro no se conecta en su dispositivo, intente conectarlo primero en otro dispositivo. Si el dinamómetro se conecta, el problema puede estar relacionado con su ordenador. \n\nSi todo es correcto con su dispositivo y todavía no puede conectar el dinamómetro al dispositivo, por favor, póngase en contacto con nuestro equipo de éxito del cliente en http://wa.me/556185953414",
			},
			{
				id: 2,
				title_pt:
					"Meu Bluetooth não está pareando. Quando tento parear aparece a mensagem de Bluetooth rejeitado. O que devo fazer?",
				title_en:
					"My Bluetooth is not pairing. When I try to pair it says Bluetooth rejected. What should I do?",
				title_es:
					"Mi Bluetooth no se empareja. Cuando intento emparejarme dice Bluetooth rechazado. ¿Qué debo hacer?",
				description_pt:
					"Não se preocupe! O aplicativo do Kinology fará a conexão com o dinamômetro automaticamente na hora de avaliar. Saiba mais na pergunta “Não consigo conectar o dinamômetro no celular, o que fazer?” \nCaso não resolva entre em contato com a nossa equipe de sucesso do cliente através do link http://wa.me/556185953414",
				description_en:
					"Don't worry, the Kinology application will connect to the dynamometer automatically at the time of evaluation. Learn more in the question \"I can't connect the dynamometer to the cell phone, what to do?\" \nIf it doesn't resolve contact our customer success team at http://wa.me/556185953414",
				description_es:
					'No te preocupes, la aplicación Kinology se conectará al dinamómetro automáticamente cuando llegue el momento de la evaluación. Más información sobre la pregunta "No puedo conectar el dinamómetro al teléfono móvil, ¿qué hacer?" \nSi no se resuelve, póngase en contacto con nuestro equipo de atención al cliente a través del enlace http://wa.me/556185953414',
			},

			{
				id: 3,
				title_pt: "Como funciona a garantia e manutenção?",
				title_en: "How does the warranty and maintenance work?",
				title_es: "¿Cómo funciona la garantía y el mantenimiento?",
				description_pt:
					"Somente Clientes assinantes têm a manutenção garantida. Conforme previsto no Código de Defesa ao Consumidor, a garantia de manutenção é de 90 dias corridos. O valor da taxa pode sofrer alterações.",
				description_en:
					"Only subscriber customers have guaranteed maintenance. As provided for in the Consumer Defense Code, the maintenance guarantee is 90 calendar days. The amount of the fee may change.",
				description_es:
					"Sólo los clientes abonados tienen garantizado el mantenimiento. Según lo dispuesto en el Código de Protección del Consumidor, la garantía de mantenimiento es de 90 días naturales. El importe de la tasa puede cambiar.",
			},

			{
				id: 4,
				title_pt:
					"Como sei quando é o momento de carregar a bateria e qual sua duração?",
				title_en:
					"How do I know when it is time to charge the battery and how long it will last?",
				title_es:
					"¿Cómo puedo saber cuándo es el momento de cargar la batería y cuánto durará?",
				description_pt:
					"Se a luz de LED indicativo da bateria piscar, saiba que seu Kinology encontra-se com apenas 20% de bateria. Sua bateria dura cerca de 40h continuamente. Caso esqueça o equipamento ligado, após 5 minutos de desuso, o equipamento desliga automaticamente.",
				description_en:
					"If the LED battery indicator light flashes, your Kinology has only 20% battery left. Its battery lasts about 40 hours continuously. If you forget to turn the equipment on, after 5 minutes of disuse, the equipment shuts down automatically.",
				description_es:
					"Si el indicador luminoso de la batería parpadea, a su Kinology le queda sólo un 20% de batería. Su batería dura unas 40 horas continuas. Si se olvida de encender el equipo, después de 5 minutos de desuso, el equipo se apaga automáticamente.",
			},
			{
				id: 5,
				title_pt: "O que faço caso meu dinamômetro não esteja ligando?",
				title_en: "What do I do if my dynamometer is not turning on?",
				title_es: "¿Qué hago si mi dinamómetro no se enciende?",
				description_pt:
					"Caso seu dinamômetro não acenda nenhum LED, você pode realizar alguns testes antes de entrar em contato com o nosso suporte. \n\n1º Verifique se algum LED do dinamômetro acende quando ele for ligado na tomada. Caso nenhum LED acenda, tente testar com outros cabos UsB. \n\n2º Deixe o dinamômetro na tomada por aproximadamente 4 horas e tente ligá-lo novamente. \n\nCaso os dois testes falhem, entre em contato como  nosso suporte através  do link http://wa.me/556185953414",
				description_en:
					"If your dynamometer does not light any LED, you can perform some tests before contacting our support. \n\n1º Check if any LED of the dynamometer lights up when it is plugged in. If no LED lights up, try testing with other UsB cables. \n\n2º Leave the dynamometer in the socket for approximately 4 hours and try to turn it on again. \n\nIf both tests fail, please contact our support through the link http://wa.me/556185953414",
				description_es:
					"Si su dinamómetro no enciende ningún LED, puede realizar algunas pruebas antes de ponerse en contacto con nuestro servicio de asistencia. \n\n1º Compruebe si algún LED del dinamómetro se enciende cuando está enchufado. Si no se enciende ningún LED, pruebe con otros cables UsB. \n\n2º Deje el dinamómetro en el enchufe durante aproximadamente 4 horas e intente encenderlo de nuevo. \n\nSi las dos pruebas fallan, póngase en contacto con nuestro soporte a través del enlace http://wa.me/556185953414",
			},
		],
	},

	{
		category_pt: "Criação & Gestão de Pacientes",
		category_en: "Creation & Management of Patients",
		category_es: "Creación y gestión de pacientes",
		helpers: [
			{
				id: 1,
				title_pt: "Como excluo um paciente?",
				title_en: "How do I delete a patient?",
				title_es: "¿Cómo puedo eliminar un paciente?",
				description_pt:
					"Basta ir para a lista de pacientes, aquele que mostra uma lista com todos os pacientes já cadastrados. Logo em seguida, vá até o paciente que você deseja excluir e clique nos três pontos localizados no canto direito do perfil do paciente, clique no ícone de lixeira na cor vermelha. Pacientes excluídos vão para lixeira, onde seus dados/avaliações ficam salvas por 60 dias antes de serem removidas do app.",
				description_en:
					"Just go to the patient list, the one that shows a list with all the patients already registered. Then go to the patient you wish to delete and click on the three dots located in the right corner of the patient's profile, click on the trash can icon in red. Deleted patients go to the trash can, where their data/assessments are saved for 60 days before being removed from the app.",
				description_es:
					"Sólo hay que ir a la lista de pacientes, la que muestra una lista con todos los pacientes ya registrados. A continuación, vaya al paciente que desea eliminar y haga clic en los tres puntos situados en la esquina derecha del perfil del paciente, haga clic en el icono de la papelera en rojo. Los pacientes eliminados van a la papelera, donde sus datos/evaluaciones se guardan durante 60 días antes de ser eliminados de la aplicación.",
			},
			{
				id: 2,
				title_pt: "Tem algum problema eu alterar os dados de um cliente?",
				title_en: "Is there any problem if I change a client's data?",
				title_es: "¿Hay algún problema si cambio los datos de un cliente?",
				description_pt:
					"Não tem nenhum problema! Para editar um paciente, basta ir para a lista de pacientes, aquele que mostra uma lista com todos os pacientes já cadastrados. Logo em seguida, vá até o paciente que você deseja ditar e clique nos três pontos localizados no canto direito do perfil do paciente. Logo em seguida clique no ícone de lápis na cor cinza. Clique para editar, Dados do Paciente e Anamnese, em seguida é só clicar em Salvar.",
				description_en:
					"No problem! To edit a patient, simply go to the patient list, the one that shows a list of all registered patients. Then go to the patient you want to dictate and click on the three dots located in the right corner of the patient's profile. Then click on the gray pencil icon. Click to edit, Patient Data and Anamnesis, then click Save.",
				description_es:
					"No hay problema. Para editar un paciente, basta con ir a la lista de pacientes, la que muestra una lista con todos los pacientes ya registrados. A continuación, vaya al paciente que desea dictar y haga clic en los tres puntos situados en la esquina derecha del perfil del paciente. A continuación, haga clic en el icono del lápiz gris. Haga clic para editar, Datos del paciente y Anamnesis, y luego haga clic en Guardar.",
			},
			{
				id: 3,
				title_pt: "É possível segmentar meus clientes?",
				title_en: "Is it possible to segment my clients?",
				title_es: "¿Es posible segmentar a mis clientes?",
				description_pt:
					"Sim! Hoje dentro do app temos uma opção de criação de etiquetas, você consegue criar diversas etiquetas para dividir seus clientes da melhor forma possível. Você pode criar uma etiqueta com os clientes de cada mês, por exemplo. Para fazer isso basta ir na lista de pacientes, abrir o perfil do paciente que deseja atribuir a etiqueta, e clicar em “Atribuir” na linha das “Etiquetas”, irá abrir uma aba com a opção de “pesquisar aqui uma etiqueta” é só colocar o nome que deseja atribuir a essa etiqueta e em seguida clicar em adicionar etiqueta e pronto já estará salvo. Quando quiser consultar todas as suas etiquetas é só entrar em Menu, Minha Conta, Minha Etiquetas para visualizar todas as suas etiquetas cadastradas.",
				description_en:
					'Yes! Today within the app we have an option to create labels, you can create several labels to divide your clients in the best possible way. You can create a label with the clients of each month, for example. To do this just go to the list of patients, open the profile of the patient you want to assign the label, and click on "Assign" in the "Labels" line, a tab will open with the option to "search for a label here", just put the name you want to assign to that label and then click on add label and it will be saved. When you want to see all your labels, just go to Menu, My Account, My Labels to see all your registered labels.',
				description_es:
					'Sí! Hoy dentro de la app tenemos una opción para crear etiquetas, puedes crear varias etiquetas para dividir a tus clientes de la mejor manera posible. Puede crear una etiqueta con los clientes de cada mes, por ejemplo. Para ello sólo tienes que ir a la lista de pacientes, abrir el perfil del paciente al que quieres asignar la etiqueta, y hacer clic en "Asignar" en la línea de "Etiquetas", se abrirá una pestaña con la opción de "buscar una etiqueta aquí", sólo tienes que poner el nombre que quieres asignar a esa etiqueta y luego hacer clic en añadir etiqueta y se guardará. Cuando quiera ver todas sus etiquetas, sólo tiene que ir a Menú, Mi cuenta, Mis etiquetas para ver todas sus etiquetas registradas.',
			},
			{
				id: 4,
				title_pt: "Por que não consigo cadastrar mais pacientes?",
				title_en: "Why can't I register more patients?",
				title_es: "¿Por qué no puedo registrar más pacientes?",
				description_pt:
					"Hoje a Kinology trabalha com planos de assinatura, onde cada plano dá diferentes funcionalidades e limitações no cadastro de pacientes. Caso apareça uma mensagem pedindo para obter o app premium, é porque você já alcançou o limite de cadastro de pacientes do seu plano ou porque você não é um assinante da Kinology. Caso precise cadastrar mais pacientes, entre em contato com um de nossos consultores através do número (61) 3347-9291 que ajudaremos.",
				description_en:
					"Today Kinology works with subscription plans, where each plan gives different functionalities and limitations in the registration of patients. If you get a message asking you to get the premium app, it is because you have already reached your plan's patient registration limit or because you are not an Kinology subscriber. If you need to register more patients, please contact one of our consultants at (61) 3347-9291 and we will help you.",
				description_es:
					"Hoy en día Kinology funciona con planes de suscripción, donde cada plan da diferentes funcionalidades y limitaciones en el registro de pacientes. Si recibe un mensaje pidiéndole que obtenga la aplicación premium, es porque ya ha alcanzado el límite de registro de pacientes de su plan o porque no está suscrito a Kinology. Si necesita registrar más pacientes, póngase en contacto con uno de nuestros asesores en el teléfono (61) 3347-9291 y le ayudaremos.",
			},
			{
				id: 5,
				title_pt: "Eu consigo recuperar os dados que foram excluídos?",
				title_en: "Can I recover the data that was deleted?",
				title_es: "¿Puedo recuperar los datos borrados?",
				description_pt:
					"Sim, caso você seja assinante é possível recuperar seus itens excluídos. Caso ainda não tenha completado 60 dias desde que o paciente foi desativado, você pode ir no menu em Lixeira e recuperar pacientes/avaliadores e exercícios. No item que você deseja recuperar é só clicar na setinha, abrirá uma aba para você confirmar o resgate. Na versão free do app não é possível recuperar dados excluídos.",
				description_en:
					"Yes, if you are a subscriber it is possible to recover your deleted items. If 60 days have not yet passed since the patient was deactivated, you can go to the Recycle Bin menu and retrieve patients/evaluators and exercises. In the item you want to retrieve, just click on the little arrow, and a tab will open for you to confirm the retrieval. In the free version of the app it is not possible to recover deleted data.",
				description_es:
					"Sí, si es abonado es posible recuperar sus datos borrados. Si aún no han pasado 60 días desde que el paciente fue desactivado, puede ir al menú de la papelera de reciclaje y recuperar pacientes/evaluadores y ejercicios. En el elemento que quieras recuperar, sólo tienes que pulsar en la flechita, y se abrirá una pestaña para que confirmes la recuperación. En la versión gratuita de la aplicación no es posible recuperar datos borrados.",
			},
			{
				id: 6,
				title_pt: 'Para que serve as "Anotações" na ficha dos pacientes?',
				title_en: 'What are the "Notes" in the patient\'s chart used for?',
				title_es: '¿Para qué sirven las "Notas" del expediente del paciente?',
				description_pt:
					'“Anotações” é um campo que você pode colocar observações relacionadas ao paciente, como por exemplo, "paciente possui uma lesão no joelho", "paciente sente dores no ombro". O que for colocado nesse campo, vai aparecer no cabeçalho dos relatórios que forem emitidos. Apenas assinantes podem editar anotações.',
				description_en:
					'"Notes" is a field where you can place observations related to the patient, for example, "patient has a knee injury", "patient has shoulder pain". What you put in this field will appear in the header of the reports that are issued. Only subscribers can edit notes.',
				description_es:
					'"Notas" es un campo en el que se pueden colocar observaciones relacionadas con el paciente, como, por ejemplo, "el paciente tiene una lesión en la rodilla", "el paciente tiene dolor en el hombro". Lo que ponga en este campo, aparecerá en la cabecera de los informes que se emitan. Sólo los abonados pueden editar las notas.',
			},
		],
	},

	{
		category_pt: "Criação & Gestão de Protocolos",
		category_en: "Protocol Creation & Management",
		category_es: "Creación y gestión de protocolos",
		helpers: [
			{
				id: 1,
				title_pt: "Qual é a diferença entre funcional e avaliação?",
				title_en: "What is the difference between functional and assessment?",
				title_es: "¿Cuál es la diferencia entre funcional y evaluación?",
				description_pt:
					"O app apresenta duas formas de medir força. A primeira é a avaliação, um modo na qual você vai conseguir medir com precisão a força exercida pelo paciente em cada exercício com exercícios isométricos. Para avaliações, recomenda-se utilizar a corrente que já vem no kit e realizar de 1 a 3 repetições. O modo funcional é um modo onde é possível acompanhar um treino, então aqui o uso do dinamômetro torna-se mais amplo. É possível usar diversos acessórios, como elásticos, molas, colocar o dinamômetro na polia ou pensar em outras formas de adaptar o dinamômetro.",
				description_en:
					"The app features two ways of measuring strength. The first is assessment, a way in which you will be able to accurately measure the force exerted by the patient in each exercise with isometric exercises. For evaluations, it is recommended to use the chain that comes with the kit and perform 1 to 3 repetitions. Functional mode is a mode where you can follow a workout, so here the use of the dynamometer becomes more extensive. You can use various accessories such as rubber bands, springs, put the dynamometer on the pulley, or think of other ways to adapt the dynamometer.",
				description_es:
					"La aplicación cuenta con dos formas de medir la fuerza. La primera es la evaluación, una forma en la que podrá medir con precisión la fuerza ejercida por el paciente en cada ejercicio con ejercicios isométricos. Para las evaluaciones, se recomienda utilizar la cadena que viene con el kit y realizar de 1 a 3 repeticiones. El modo funcional es un modo en el que se puede seguir un entrenamiento, por lo que aquí el uso del dinamómetro se hace más amplio. Puedes utilizar varios accesorios como bandas elásticas, muelles, colocar el dinamómetro en la polea o pensar en otras formas de adaptar el dinamómetro.",
			},
			{
				id: 2,
				title_pt:
					"Posso criar um protocolo de avaliação próprio e aplicar em todos os usuários cadastrados na minha conta?",
				title_en:
					"Can I create my own evaluation protocol and apply it to all users registered in my account?",
				title_es:
					"¿Puedo crear mi propio protocolo de evaluación y aplicarlo a todos los usuarios registrados en mi cuenta?",
				description_pt:
					"Sim! Agora ao criar um treino ou protocolo ele ficará disponível para todos os seus pacientes, não são feitos mais protocolos/treinos individuais para cada paciente.",
				description_en:
					"Yes! Now when you create a workout or protocol it will be available to all your patients, no more individual protocols/training for each patient.",
				description_es:
					"Sí, ahora cuando crees un entrenamiento o protocolo estará disponible para todos tus pacientes",
			},
			{
				id: 3,
				title_pt: "Como excluo um protocolo de treino?",
				title_en: "How do I delete a training protocol?",
				title_es: "¿Cómo puedo eliminar un protocolo de formación?",
				description_pt:
					"Para excluir um protocolo de treino, basta ir na lista de protocolos, localizar o protocolo de treino que deseja excluir, clicar nos três pontos localizados na extremidade direita do card de protocolo, clique no ícone de lixeira na cor vermelha, logo em seguida irá abrir uma aba confirmando a ação é só clicar em “Excluir”.",
				description_en:
					'To delete a training protocol, simply go to the protocol list, locate the training protocol you wish to delete, click on the three dots located at the right end of the protocol card, click on the trash can icon in red, then a tab will open confirming the action and click on "Delete".',
				description_es:
					'Para eliminar un protocolo de formación, sólo tiene que ir a la lista de protocolos, localizar el protocolo de formación que desea eliminar, hacer clic en los tres puntos situados en el extremo derecho de la tarjeta de protocolo, hacer clic en el icono de la papelera en rojo, entonces se abrirá una pestaña confirmando la acción y sólo tiene que hacer clic en "Eliminar".',
			},
			{
				id: 4,
				title_pt:
					"Posso criar um protocolo de avaliação próprio para um paciente?",
				title_en: "Can I create my own evaluation protocol for a patient?",
				title_es:
					"¿Puedo crear mi propio protocolo de evaluación para un paciente?",
				description_pt:
					"Não! Agora ao criar um treino ou protocolo ele ficará disponível para todos os seus pacientes, não são feitos mais protocolos/treinos individuais para cada paciente.",
				description_en:
					"No! Now when you create a workout or protocol it will be available for all your patients, no more individual protocols/training for each patient.",
				description_es:
					"Ahora, cuando crees un entrenamiento o protocolo, estará disponible para todos tus pacientes, no más protocolos/entrenamientos individuales para cada paciente.",
			},
		],
	},

	{
		category_pt: "Execução de exercícios",
		category_en: "Exercise execution",
		category_es: "Ejecución del ejercicio",
		helpers: [
			{
				id: 1,
				title_pt:
					"O que fazer se eu quiser realizar um exercício que não está listado?",
				title_en:
					"What do I do if I want to perform an exercise that is not listed?",
				title_es:
					"¿Qué hago si quiero realizar un ejercicio que no está en la lista?",
				description_pt:
					"Você pode adicionar um novo exercício além dos sugeridos pela Kinology. De início, temos alguns exercícios já criados, porém para criar os seus próprios exercícios, basta clicar na opção “protocolo” no menu lateral esquerdo, abrir a aba “Exercícios” e clicar no botão “+ Novo exercício”. Irá aparecer uma aba para incluir o nome do Exercício, em seguida clique em avançar, escolha o tipo de exercício e qual o músculo será afetado por esse exercício e pronto o seu exercício aparecerá na lista.",
				description_en:
					'You can add a new exercise in addition to the ones suggested by Kinology. At first, we have some exercises already created, but to create your own exercises, just click on the "protocol" option in the left side menu, open the "Exercises" tab and click on the "+ New exercise" button. A tab will appear to include the name of the exercise, then click on "Next", choose the type of exercise and which muscle will be affected by this exercise, and your exercise will appear on the list.',
				description_es:
					'Puedes añadir un nuevo ejercicio además de los propuestos por Kinology. Al principio, tenemos algunos ejercicios ya creados, pero para crear tus propios ejercicios, sólo tienes que hacer clic en la opción "protocolo" del menú lateral izquierdo, abrir la pestaña "Ejercicios" y hacer clic en el botón "+ Nuevo ejercicio". Aparecerá una pestaña para incluir el nombre del ejercicio, luego haga clic en continuar, elija el tipo de ejercicio y qué músculo se verá afectado por este ejercicio y su ejercicio aparecerá en la lista.',
			},
			{
				id: 2,
				title_pt: "Consigo avaliar MMII?",
				title_en: "Can I evaluate lower limbs?",
				title_es: "¿Puedo evaluar las extremidades inferiores?",
				description_pt:
					"Sim, é possível fazer várias montagens com o dinamômetro para executar exercícios como agachamento, flexão e extensão de joelho, flexão plantar e dorsiflexão por exemplo.",
				description_en:
					"Yes, you can make several assemblies with the dynamometer to perform exercises such as squatting, knee flexion and extension, plantar flexion and dorsiflexion for example.",
				description_es:
					"Sí, puede utilizar el dinamómetro para realizar varios ejercicios como la sentadilla, la flexión y extensión de la rodilla, la flexión plantar y la dorsiflexión.",
			},
			{
				id: 3,
				title_pt: "Como excluo o resultado de uma avaliação feita?",
				title_en: "How do I exclude the result of an evaluation done?",
				title_es:
					"¿Cómo puedo excluir el resultado de una evaluación realizada?",
				description_pt:
					"Ao concluir uma série de qualquer exercício, esse gráfico vai ser mostrado com o resultado da avaliação. Para excluir essa medição de força, entre na ficha do paciente, informações, em relatórios, no relatório de evolução, clique nos 3 pontinhos ao lado, irá abrir uma aba, para você selecionar os resultados que deseja excluir do relatório, pode selecionar todos ou só alguns. Em seguida clique no botão excluir.",
				description_en:
					"At the end of a series of any exercise, this graph will be shown with the result of the assessment. To delete this strength measurement, go into the patient's file, information, in reports, in the evolution report, click on the 3 little dots on the side, a tab will open for you to select the results that you wish to delete from the report, you can select all or only some. Then click on the delete button.",
				description_es:
					"Al final de una serie de cualquier ejercicio, este gráfico se mostrará con el resultado de la evaluación. Para eliminar esta medida de fuerza, entra en la ficha del paciente, información, en informes, en el informe de evolución, pincha en los 3 puntitos del lateral, se abrirá una pestaña para que selecciones los resultados que quieres eliminar del informe, puedes seleccionar todos o sólo algunos. A continuación, haga clic en el botón de eliminación.",
			},
			{
				id: 4,
				title_pt:
					"Como eu sei que está na hora de realizar uma nova repetição?",
				title_en: "How do I know it's time to perform a new repetition?",
				title_es:
					"¿Cómo sé que es el momento de realizar una nueva repetición?",
				description_pt:
					"Quando o paciente chega na sua força máxima e logo em seguida a sua força decai, o app entende que a repetição se encerrou, caso tenha outra repetição para ser feita na série, basta realizar a repetição restante normalmente que o app vai contabilizar. Quando todas as repetições da série forem finalizadas, um gráfico vai aparecer na tela com o resultado da avaliação. Agora você não precisa mais configurar as repetições e séries antes de executar o exercício.",
				description_en:
					"When the patient reaches maximum strength and then his strength decreases, the app understands that the repetition is over. If there is another repetition to be done in the series, just perform the remaining repetition normally and the app will count it. When all repetitions of the series are finished, a graph will appear on the screen with the result of the evaluation. Now you no longer need to set the repetitions and series before performing the exercise.",
				description_es:
					"Cuando el paciente alcanza la fuerza máxima y luego su fuerza disminuye, la aplicación entiende que la repetición ha terminado. Si queda otra repetición por hacer en la serie, basta con realizar la repetición restante normalmente y la app la contará. Cuando todas las repeticiones de la serie estén terminadas, aparecerá un gráfico en la pantalla con el resultado de la evaluación. Ahora ya no es necesario establecer las repeticiones y las series antes de realizar el ejercicio.",
			},
			{
				id: 5,
				title_pt:
					"Ao fazer três tentativas na força isométrica, devo usar a média ou escolher a melhor de 3?",
				title_en:
					"When making three attempts at isometric strength, should I use the average or choose the best of 3?",
				title_es:
					"Al realizar tres intentos de fuerza isométrica, ¿debo utilizar la media o elegir el mejor de los 3?",
				description_pt:
					"Recomendamos realizar uma primeira repetição para ajudar na aprendizagem do movimento que deverá ser realizado e evitar compensações (familiarização). Essa repetição deve ser descartada. Em seguida, realize até 3 repetições dependendo da disponibilidade de tempo e facilidade de aplicação do teste. Lembre-se que o objetivo da isometria é realmente obter o máximo de força do avaliado naquela condição física do dia.",
				description_en:
					"We recommend performing a first repetition to help you learn the movement to be performed and avoid compensations (familiarization). This repetition should be discarded. Then perform up to 3 repetitions depending on your time and ease of application of the test. Remember that the goal of isometrics is to really obtain the maximum strength of the subject in that day's physical condition.",
				description_es:
					"Se recomienda realizar una primera repetición para aprender el movimiento a realizar y evitar compensaciones (familiarización). Esta repetición debe descartarse. A continuación, realice hasta 3 repeticiones en función de su tiempo y de la facilidad de aplicación de la prueba. Recuerde que el objetivo de los isométricos es obtener realmente la fuerza máxima del sujeto en la condición física de ese día.",
			},
			{
				id: 6,
				title_pt: "Qual % de RM devo usar em um funcional?",
				title_en: "What % of RM should I use in a functional?",
				title_es: "¿Qué % de RM debo utilizar en una función?",
				description_pt:
					"O RM é uma função opcional, não é obrigatório escolher uma % de rendimento. Ela serve para você garantir que o paciente/atleta está desempenhando uma % mínima do seu potencial. Sobre qual %, depende muito do intuito do protocolo de treino e da metodologia que você deseja aplicar. Cada profissional tem o seu modo de trabalhar, alguns utilizam uma % acima de 50% para estimular os músculos dos pacientes, é algo que varia bastante de cada caso.",
				description_en:
					"The RM is an optional function, it is not mandatory to choose a % of performance. It is for you to ensure that the patient/athlete is performing at a minimum % of their potential. About what % depends a lot on the intention of the training protocol and the methodology you wish to apply. Each professional has their own way of working, some use a % above 50% to stimulate the patient's muscles, it's something that varies a lot from case to case.",
				description_es:
					"El RM es una función opcional, no es obligatorio elegir un % de rendimiento. Es para que usted se asegure de que el paciente/atleta está rindiendo a un % mínimo de su potencial. Sobre qué % depende mucho de la intención del protocolo de entrenamiento y de la metodología que quieras aplicar. Cada profesional tiene su propia forma de trabajar, algunos utilizan un % superior al 50% para estimular los músculos del paciente, es algo que varía mucho de un caso a otro.",
			},
			{
				id: 7,
				title_pt: "Qual o tempo em que o avaliado deve sustentar a isometria?",
				title_en:
					"How long should the person undergoing isometrics sustain the workout?",
				title_es:
					"¿Cuánto tiempo debe sostener el entrenamiento la persona que se somete a los isométricos?",
				description_pt:
					'Nós recomendamos sustentar entre 3 a 6 segundos, com incentivo verbal (força, força, vai, vai!"). A curva de força ideal deve se parecer com a forma geométrica de um trapézio, ou seja, poucas oscilações ou compensações durante a execução. <br>Lembrando que em para muitas pessoas o estímulo verbal correto faz muita diferença para atingir a capacidade máxima do avaliado."',
				description_en:
					"We recommend sustaining it between 3 to 6 seconds, with verbal encouragement (go, go, go!). The ideal force curve should resemble the geometric shape of a trapezium, i.e., few oscillations or compensations during execution. <br>Remembering that in for many people the correct verbal stimulus makes a lot of difference in reaching the rated maximal capacity.",
				description_es:
					"Se recomienda sostenerlo entre 3 y 6 segundos, con estímulos verbales (¡vamos, vamos, vamos!). La curva de fuerza ideal debe parecerse a la forma geométrica de un trapecio, es decir, pocas oscilaciones o compensaciones durante la ejecución. <br>Recordar que en muchas personas el estímulo verbal correcto hace mucha diferencia para alcanzar la capacidad máxima nominal.",
			},
			{
				id: 8,
				title_pt: "É possível utilizar outros extensores sem ser os elásticos?",
				title_en:
					"Is it possible to use other extenders other than the Kinology ones?",
				title_es:
					"¿Es posible utilizar otros extensores que no sean los elásticos?",
				description_pt:
					"Sim! Você pode utilizar qualquer resistência elástica ou outros acessórios que você já tenha no seu espaço. O kit do Kinology vai junto com vários mosquetões e acessórios justamente para isso, então basta que você consiga acoplar seu acessório aos pinos no dinamômetro que ele vai medir.",
				description_en:
					"Yes! You can use any Kinology resistance or other accessories you already have in your space. The Kinology kit comes along with several carabiners and accessories for just that, so you just need to be able to attach your accessory to the pins on the dynamometer and it will measure.",
				description_es:
					"Sí. Puede utilizar cualquier resistencia elástica u otros accesorios que ya tenga en su espacio. El kit Kinology viene con varios mosquetones y accesorios para ello, por lo que sólo tiene que poder acoplar su accesorio a las clavijas del dinamómetro y éste medirá.",
			},
			{
				id: 9,
				title_pt: "Como eu sei qual melhor elástico usar com o meu paciente?",
				title_en: "How do I know which Kinology to use with my patient?",
				title_es: "¿Cómo sé qué elástico debo utilizar con mi paciente?",
				description_pt:
					"Depende de cada paciente e de cada metodologia de avaliação. Os elásticos são usados em treinos funcionais, por mais que o elástico possua um constante elástica, a força que vai ser captada pelo dinamômetro é a mesma força executada pelo músculo. Caso o paciente seja mais debilitado, a recomendação é o uso de elásticos menos resistente, para atletas ou pessoas que não estão debilitadas, recomenda-se o uso de elásticos mais resistentes.",
				description_en:
					"It depends on each patient and each evaluation methodology. The Kinology bands are used in functional training; even though the Kinology band has an Kinology constant, the force that will be captured by the dynamometer is the same force executed by the muscle. If the patient is more debilitated, the recommendation is to use less resistant Kinologys; for athletes or people who are not debilitated, the use of more resistant Kinologys is recommended.",
				description_es:
					"Depende de cada paciente y de cada metodología de evaluación. Los elásticos se utilizan en el entrenamiento funcional; aunque la banda elástica tenga una constante elástica, la fuerza que captará el dinamómetro es la misma que ejecuta el músculo. Si el paciente está más debilitado, la recomendación es utilizar elásticos menos resistentes; para deportistas o personas no debilitadas, se recomienda el uso de elásticos más resistentes.",
			},
			{
				id: 10,
				title_pt: "Eu consigo definir um tempo de contração de cada repetição?",
				title_en: "Can I set a contraction time for each repetition?",
				title_es:
					"¿Puedo establecer un tiempo de contracción para cada repetición?",
				description_pt:
					'Sim! É possível definir um tempo de contração para cada repetição. Para isso, primeiro acessa: Menu lateral→ Configurações→ Exercícios → Tempo de contração \nAo chegar na opção "tempo de contração", você consegue definir um período de tempo de cada contração. Essa mudança vai ser válida para todos os protocolos de treino. \n\n- Vocês possuem alguma indicação de qual elástico comprar? \nSim! Atualmente indicamos os elásticos da Elastos: [https://elastos.com.br/](https://elastos.com.br/)',
				description_en:
					'Yes, you can set a contraction time for each repetition. To do so, first access: Side Menu→ Settings→ Exercises → Contraction Time \n When you get to the "contraction time" option, you can set a time period for each contraction. This change will be valid for all training protocols. \n\n - Do you have any indication of which Kinology band to buy? \nYes, we currently recommend the Elastos Kinologys: [https://elastos.com.br/](https://elastos.com.br/)',
				description_es:
					'Sí, es posible establecer un tiempo de contracción para cada repetición. Para ello, acceda primero a: Menú lateral→ Ajustes→ Ejercicios → Tiempo de contracción \nCuando llegue a la opción "tiempo de contracción", podrá establecer un período de tiempo para cada contracción. Este cambio será válido para todos los protocolos de formación. \n\n- ¿Tienes alguna indicación sobre qué banda elástica comprar? \nSí, actualmente recomendamos los elásticos Elastos: [https://elastos.com.br/](https://elastos.com.br/)',
			},
			{
				id: 11,
				title_pt: "Vocês possuem alguma indicação de qual elástico comprar?",
				title_en: "Do you have any indication of which Kinology band to buy?",
				title_es: "Tenéis alguna indicación sobre qué banda elástica comprar?",
				description_pt:
					"Sim! Atualmente indicamos os elásticos da Elastos: https://elastos.com.br/",
				description_en:
					"Yes! Currently we recommend the Elastos Kinologys: https://elastos.com.br/",
				description_es:
					"Sí. Actualmente recomendamos los elásticos Elastos: https://elastos.com.br/",
			},
		],
	},

	{
		category_pt: "Gráficos & Relatórios",
		category_en: "Graphs & Reports",
		category_es: "Gráficos e informes",
		helpers: [
			{
				id: 1,
				title_pt: "O que é índice I/Q?",
				title_en: "What is I/Q Index?",
				title_es: "¿Qué es el índice I/Q?",
				description_pt:
					'O índice I/Q é um indicador de equilíbrio muscular. Uma proporção entre o músculo antagonista e agonista da própria perna, no caso, o isquiotibiais e o quadríceps. O app vai fornecer duas porcentagens, uma com o índice da perna direita e outro da perna esquerda e indicar se a proporção é saudável ou não. Consideramos que uma proporção acima de 50% é uma proporção saudável e que uma proporção inferior a 50% pode contribuir para futuras lesões em pacientes/atletas. A proporção é feita com base na razão da força máxima do isquiotibial e a força máxima do quadríceps. Caso queira saber como calcular o índice I/Q do seu paciente, visite nossa pergunta "Como eu obtenho o índice I/Q dos meus pacientes" do nosso FAQ.',
				description_en:
					"The I/Q index is an indicator of muscle balance. It is a ratio between the antagonist and agonist muscle of one's own leg, in this case the hamstrings and quadriceps. The app will provide two percentages, one with the index for the right leg and one for the left leg, and indicate whether the ratio is healthy or not. We consider that a ratio above 50% is a healthy ratio and a ratio below 50% may contribute to future injuries in patients/athletes. The ratio is made based on the ratio of the maximal hamstring strength to the maximal quadriceps strength. If you want to know how to calculate your patient's I/Q ratio, please visit our FAQ question \"How do I get my patients' I/Q ratio\".",
				description_es:
					'La relación I/Q es un indicador del equilibrio muscular. Una relación entre el músculo antagonista y agonista de la propia pierna, en este caso los isquiotibiales y el cuádriceps. La aplicación proporcionará dos porcentajes, uno con el índice de la pierna derecha y otro de la izquierda, e indicará si la proporción es saludable o no. Consideramos que un ratio superior al 50% es un ratio saludable y un ratio inferior al 50% puede contribuir a futuras lesiones en los pacientes/deportistas. La relación se realiza a partir de la relación entre la fuerza máxima de los isquiotibiales y la fuerza máxima de los cuádriceps. Si desea saber cómo calcular el ratio I/Q de su paciente, visite nuestra pregunta de las FAQ "Cómo obtener el ratio I/Q de mis pacientes".',
			},
			{
				id: 2,
				title_pt:
					"Qual a diferença entre os 3 relatórios que é possível emitir?",
				title_en:
					"What is the difference between the 3 reports that can be issued?",
				title_es:
					"¿Cuál es la diferencia entre los 3 informes que se pueden emitir?",
				description_pt:
					'Existem 3 tipos de relatório, o de Evolução, o de força x tempo e o relatório de assimetria. \n\nRelatório de Evolução - Ele vai apresentar o resumo de cada série, indicando a força mínima e máxima de cada. (Agora tem também os valores de referência)\n\nRelatório de força x tempo - É um relatório para fazer a análise de fadiga/exaustão do paciente, nele você vai encontrar um gráfico de força x tempo "esticado" para ter uma análise mais profunda do desempenho do atleta. \n\nRelatório de assimetria - É um relatório que vai indicar a diferença entre lado direito e esquerdo, ele é o mais completo dos relatórios que é possível emitir.',
				description_en:
					'There are 3 types of reports, the Evolution report, the strength x time report, and the asymmetry report. \n\nEvolution Report - It will present the summary of each series, indicating the minimum and maximum strength of each. (Now it also has the reference values) \n\nStrength x Time Report - It is a report to do the fatigue/exhaustion analysis of the patient, in it you will find a "stretched" strength x time graph to have a deeper analysis of the athlete\'s performance. \n\nAsymmetry Report - This is a report that will indicate the difference between the right and left side, it is the most complete report that can be issued.',
				description_es:
					'Hay 3 tipos de informes, el informe de evolución, el informe de fuerza x tiempo y el informe de asimetría. \n\nInforme de Evolución - Se mostrará un resumen de cada serie, indicando la fuerza mínima y máxima de cada una. (Ahora también tiene valores de referencia) \n\nInforme de fuerza x tiempo - Es un informe para hacer el análisis de fatiga/agotamiento del paciente, en él encontrará un gráfico de fuerza x tiempo "estirado" para tener un análisis más profundo del rendimiento del deportista. \n\nInforme de asimetría - Es un informe que indicará la diferencia entre el lado derecho y el izquierdo, es el más completo de los informes que se pueden emitir.',
			},
			{
				id: 3,
				title_pt:
					"Não consigo baixar o PDF do relatório através do meu celular?",
				title_en:
					"I can't download the PDF of the report through my cell phone?",
				title_es:
					"¿No puedo descargar el PDF del informe a través de mi teléfono móvil?",
				description_pt:
					"Entre em contato com a nossa equipe de sucesso do cliente através do número (61) 3347-9291",
				description_en:
					"Please contact our customer success team at +55 (61) 3347-9291",
				description_es:
					"Por favor, póngase en contacto con nuestro equipo de éxito del cliente en el +55 (61) 3347-9291",
			},
			{
				id: 4,
				title_pt: "O email do relatório não chega, o que fazer?",
				title_en: "The email report does not arrive, what to do?",
				title_es: "El informe por correo electrónico no llega, ¿qué hacer?",
				description_pt:
					"Primeiro verifique na caixa de spam ou em outras se chegou algum email nosso. Caso nenhum email tenha chegado em outras caixas, entre em contato com a nossa equipe de sucesso do cliente através do número (61) 3347-9291",
				description_en:
					"First check your spam or other boxes to see if any of our emails have arrived. If no email has arrived in other boxes, please contact our customer success team at (61) 3347-9291",
				description_es:
					"En primer lugar, compruebe en su buzón de spam o en otros buzones si ha llegado algún correo electrónico nuestro. Si no ha llegado ningún correo electrónico a otras casillas, póngase en contacto con nuestro equipo de éxito de clientes en el (61) 3347-9291",
			},
			{
				id: 5,
				title_pt: "Como faço para inserir minha logomarca nos relatórios?",
				title_en: "How do I insert my logo in the reports?",
				title_es: "¿Cómo inserto mi logotipo en los informes?",
				description_pt:
					"Além da logomarca você também pode inserir dados da empresa/clínica, como o  telefone e email. Para realizar essa alteração, faça o seguinte: \n\nAcesse o menu lateral > Minha Conta > Personalizar relatório > Faça o upload da sua logo e coloque os seus dados. Somente assinantes possuem essa funcionalidade.",
				description_en:
					"In addition to the logo, you can also insert company/clinic data, such as telephone and email. To make this change, do the following: \n\nGo to side menu > My Account > Customize report > Upload your logo and put your data. Only subscribers have this functionality.",
				description_es:
					"Además del logotipo, también puede insertar los datos de la empresa/clínica, como el teléfono y el correo electrónico. Para realizar este cambio, haga lo siguiente: \n\nVaya al menú lateral > Mi cuenta > Personalizar informe > Cargue su logotipo y ponga sus datos. Sólo los abonados tienen esta funcionalidad.",
			},
			{
				id: 6,
				title_pt:
					"Qual é a angulação que o dinamômetro deve ficar na hora de realizar um exercício?",
				title_en:
					"What angle should the dynamometer be at when performing an exercise?",
				title_es:
					"¿En qué ángulo debe estar el dinamómetro al realizar un ejercicio?",
				description_pt:
					"O dinamômetro sempre deve ficar na mesma direção que o exercício em relação ao ponto de fixação. Se o exercício deve ser executado com uma angulação de 45º, o dinamômetro deve ser alocado de forma que fique a 45º do ponto de fixação.",
				description_en:
					"The dynamometer should always be in the same direction as the exercise in relation to the fixation point. If the exercise must be performed at a 45° angle, the dynamometer must be placed so that it is at 45° from the fixation point.",
				description_es:
					"El dinamómetro debe estar siempre en la misma dirección que el ejercicio en relación con el punto de fijación. Si el ejercicio debe realizarse en un ángulo de 45°, el dinamómetro debe colocarse de forma que esté a 45° del punto de fijación.",
			},
			{
				id: 7,
				title_pt:
					"O que é a porcentagem que aparece no relatório de assimetria?",
				title_en:
					"What is the percentage that appears in the asymmetry report?",
				title_es:
					"¿Qué es el porcentaje que aparece en el informe de asimetría?",
				description_pt:
					"É o resultado do cálculo da assimetria entre dois exercícios realizados em membros opostos. Por exemplo, para assimetria na rotação interna de ombro direito e esquerdo, calcula-se o percentual da divisão do desvio padrão pela média das medidas. Mais detalhes dessas fórmulas você pode ver na área de relatórios aqui no app ou na última página do próprio relatório em .pdf.",
				description_en:
					"It is the result of calculating the asymmetry between two exercises performed on opposite limbs. For example, for asymmetry in right and left shoulder internal rotation, the percentage is calculated by dividing the standard deviation by the average of the measurements. More details of these formulas you can see in the reports area here in the app or on the last page of the .pdf report itself.",
				description_es:
					"Es el resultado de calcular la asimetría entre dos ejercicios realizados en miembros opuestos. Por ejemplo, para la asimetría en la rotación interna del hombro derecho e izquierdo, el porcentaje se calcula dividiendo la desviación estándar por la media de las mediciones. Más detalles de estas fórmulas puedes verlos en la zona de informes aquí en la app o en la última página del propio informe en .pdf.",
			},
			{
				id: 8,
				title_pt: "Qual o ponto de corte para considerar o membro assimétrico?",
				title_en:
					"What is the cut-off point for considering the limb asymmetrical?",
				title_es:
					"¿Cuál es el punto de corte para considerar el miembro asimétrico?",
				description_pt:
					"Adota-se convencionalmente valores entre 10 a 20% como faixa intermediária  representam assimetria. Lembrando que há várias fórmulas para se calcular assimetria. Nós escolhemos uma delas, que é uma das que mais tem respaldo em estudos científicos e é a mais utilizada. ",
				description_en:
					"We conventionally adopt values between 10 to 20% as the intermediate range represent asymmetry. Remembering that there are several formulas to calculate asymmetry. We have chosen one of them, which is one that has the most support in scientific studies and is the most widely used.",
				description_es:
					"Convencionalmente se adoptan valores entre el 10 y el 20% como rango intermedio que representa la asimetría. Recordando que existen varias fórmulas para calcular la asimetría. Hemos elegido uno de ellos, que es el que tiene más apoyo en los estudios científicos y es el más utilizado.",
			},
			{
				id: 9,
				title_pt: "Como eu obtenho o índice I/Q dos meus pacientes?",
				title_en: "How do I obtain the I/Q index of my patients?",
				title_es: "¿Cómo puedo obtener el índice I/Q de mis pacientes?",
				description_pt:
					'Basta executar o protocolo de treino "Avaliação I/Q", Basta ir na lista de Pacientes, selecionar o paciente que deseja realizar avaliação, na ficha do paciente clicar em protocolos, em seguida em Avaliação I/Q (protocolos sugeridos). Execute todos os exercícios e vá em relatórios. O app vai mostrar qual é a relação I/Q do paciente. Caso queira saber mais sobre índice I/Q, visite a pergunta "O que é índice I/Q" do nosso FAQ.',
				description_en:
					'Just run the training protocol "I/Q Evaluation", Go to the Patient list, select the patient you want to evaluate, in the patient\'s file click on protocols, then on I/Q Evaluation (suggested protocols). Perform all the exercises and go to reports. The app will show you what the patient\'s I/Q ratio is. If you want to know more about I/Q ratio, visit the question "What is I/Q ratio" in our FAQ.',
				description_es:
					'Simplemente ejecute el protocolo de entrenamiento "Evaluación I/Q", vaya a la lista de pacientes, seleccione el paciente que desea evaluar, en la ficha del paciente haga clic en protocolos, luego en Evaluación I/Q (protocolos sugeridos). Realice todos los ejercicios y vaya a informes. La aplicación le mostrará cuál es el ratio I/Q del paciente. Si quieres saber más sobre el ratio I/Q, visita la pregunta "¿Qué es el ratio I/Q?" en nuestras FAQ.',
			},
			{
				id: 10,
				title_pt:
					"A força que o dinamômetro mede com o elástico é a mesma que a força  que o dinamômetro mede com a corrente?",
				title_en:
					"Is the force that the dynamometer measures with the rubber band the same as the force that the dynamometer measures with the chain?",
				title_es:
					"¿La fuerza que mide el dinamómetro con la banda elástica es la misma que la que mide el dinamómetro con la cadena? ",
				description_pt:
					"Não. A forma calculada com a corrente é a força isométrica, ou seja, a força absoluta feita pelo seu paciente. \n\n Já a força executada com o elástico, é o trabalho de força muscular, ou seja, a força exercida contra outra força, pode-se interpretar a força dos exercícios funcionais como Kg/segundo.",
				description_en:
					"No. The form calculated with the chain is the isometric force, that is, the absolute force made by your patient. \n\nThe force performed with the rubber band, on the other hand, is the work of muscular force, that is, the force exerted against another force, one can interpret the force of functional exercises as Kg/second.",
				description_es:
					"No. La forma calculada con la cadena es la fuerza isométrica, es decir, la fuerza absoluta realizada por su paciente. \n\nLa fuerza ejecutada con la banda elástica, en cambio, es el trabajo de fuerza muscular, es decir, la fuerza ejercida contra otra fuerza, se puede interpretar la fuerza de los ejercicios funcionales como Kg/segundo.",
			},
			{
				id: 11,
				title_pt: "Como o índice I/Q é calculado?",
				title_en: "How is the I/Q index calculated?",
				title_es: "¿Cómo se calcula el índice I/Q?",
				description_pt:
					'A proporção é feita com base na razão da força máxima do isquiotibial e a força máxima do quadríceps. Caso deseje saber mais sobre índice I/Q, visite a pergunta "O que é índice I/Q?" ou "Como eu obtenho o índice I/Q dos meus pacientes?" no nossa FAQ.',
				description_en:
					'The ratio is made based on the ratio of the maximal hamstring strength and the maximal quadriceps strength. If you want to learn more about the I/Q ratio, please visit the question "What is I/Q ratio?" or "How do I get my patients\' I/Q ratio?" in our FAQ.',
				description_es:
					'La relación se realiza a partir de la relación entre la fuerza máxima de los isquiotibiales y la fuerza máxima de los cuádriceps. Si quiere saber más sobre el ratio I/Q, visite la pregunta "¿Qué es el ratio I/Q?" o "¿Cómo obtengo el ratio I/Q de mis pacientes?" en nuestras preguntas frecuentes.',
			},
			{
				id: 12,
				title_pt:
					"Qual é a diferença entre o índice I/Q e o relatório de assimetria?",
				title_en:
					"What is the difference between the I/Q ratio and the asymmetry report?",
				title_es:
					"¿Cuál es la diferencia entre el índice I/Q y el informe de asimetría?",
				description_pt:
					"O app traz dois indicadores para os nossos usuários, o primeiro é um indicador de equilíbrio muscular, que é o índice I/Q. O segundo indicador que o app trás é o indicador de assimetria, ele vai trazer a % de diferença de força entre músculos correspondentes de cada lado do corpo, assim é possível realizar diagnósticos precisos sobre assimetria muscular.",
				description_en:
					"The app brings two indicators to our users, the first is an indicator of muscle balance, which is the I/Q index. The second indicator that the app brings is the asymmetry indicator, it will bring the % difference in strength between corresponding muscles on each side of the body, so it is possible to make accurate diagnoses about muscle asymmetry.",
				description_es:
					'El índice se realiza a partir de la relación entre la fuerza máxima de los isquiotibiales y la fuerza máxima de los cuádriceps. Si quiere saber más sobre el índice I/Q, visite la pregunta "¿Qué es el índice I/Q?" o "¿Cómo obtengo el índice I/Q de mis pacientes?" en nuestras preguntas frecuentes.',
			},
			{
				id: 13,
				title_pt: "Onde encontro as referências e a fórmula de assimetria?",
				title_en: "Where do I find the references and the asymmetry formula?",
				title_es:
					"¿Dónde puedo encontrar las referencias y la fórmula de la asimetría?",
				description_pt:
					'Você pode ver essa informação nos relatórios no próprio aplicativo no Rodapé você encontra as referências e as fórmulas. \n\n Atentar para que sejam exercícios da seguinte lista: "Abdução de ombro", "Adução de ombro", "Flexão de cotovelo", "Extensão de cotovelo", "Flexão de joelho", "Extensão de joelho", "Flexão de quadril" e "Dorsiflexão". \n\nDeve também ter gênero cadastrado, masculino ou femenino. \n\nDeve ser direita ou esqueda. \n\nDeve ter a idade cadastrada entre 20 e 70 anos.',
				description_en:
					'You can see this information in the reports in the application itself in the Footer you will find the references and the formulas. \n\n Make sure they are exercises from the following list: "Shoulder Abduction", "Shoulder Adduction", "Elbow Flexion", "Elbow Extension", "Knee Flexion", "Knee Extension", "Hip flexion" and "Dorsiflexion". \n\nIt must also have a registered gender, male or female. \n\nIt must be right or left. \n\nMust be of age between 20 and 70 years.',
				description_es:
					'Puedes ver esta información en los informes en la propia aplicación en el Pie de página encontrarás las referencias y fórmulas. Asegúrate de que sean ejercicios de la siguiente lista: "Abducción de hombro", "Aducción de hombro", "Flexión del codo", "Extensión de codo", "Flexión de rodilla", "Extensión de rodilla", "Flexión de cadera" y "dorsiflexión". \n\nTambién debe tener un género registrado, masculino o femenino. \n\nDebe ser derecha o izquierda. \n\nDebe ser mayor de edad entre20 y 70 años.',
			},
		],
	},

	{
		category_pt: "Faturas & Mensalidade",
		category_en: "Invoices & Monthly Fees",
		category_es: "Facturas y tarifas mensuales",
		helpers: [
			{
				id: 1,
				title_pt: "Quais as formas de pagamento da mensalidade?",
				title_en: "What are the payment methods for the monthly fee?",
				title_es: "¿Cuáles son las formas de pago de la cuota mensual?",
				description_pt:
					"Atualmente, trabalhamos com pagamentos apenas por cartão de crédito, que é considerada a forma mais segura de pagamento. \nAlém disso, trabalhamos com a modalidade de recorrência de pagamentos mensais, ou seja, o débito acontece de maneira programada e automática, prezando uma maior comodidade e praticidade para nossos assinantes. \nPara pagamentos no formato semestral e anual é possível utilizar além do cartão de crédito, PIX ou Boleto.",
				description_en:
					"Currently, we work with payments only by credit card, which is considered the safest form of payment. \nIn addition, we work with the recurring monthly payment modality, i.e., the debit happens in a scheduled and automatic manner, valuing greater convenience and practicality for our subscribers. \nFor payments in the semiannual and annual format, in addition to a credit card, you can use PIX or Boleto.",
				description_es:
					"Actualmente, trabajamos con pagos sólo con tarjeta de crédito, que se considera la forma de pago más segura. \nAdemás, trabajamos con la modalidad de pagos mensuales recurrentes, es decir, el débito se produce de forma programada y automática, valorando una mayor comodidad y practicidad para nuestros abonados. \nPara los pagos en formato semestral y anual, puede utilizar tarjetas de crédito, PIX o Boleto.",
			},
			{
				id: 2,
				title_pt: "Como recebo a fatura da minha mensalidade?",
				title_en: "How do I receive an invoice for my monthly fee?",
				title_es: "¿Cómo recibo la factura de mi cuota mensual?",
				description_pt:
					"As faturas são enviadas para o e-mail cadastrado em sua conta Kinology. Caso queira alterar o e-mail para recebimento das faturas, entre em contato com nosso suporte.",
				description_en:
					"Invoices are sent to the e-mail address registered in your Kinology account. If you wish to change the e-mail address for receiving invoices, please contact our support team.",
				description_es:
					"Las facturas se envían a la dirección de correo electrónico registrada en su cuenta Kinology. Si desea cambiar la dirección de correo electrónico para recibir las facturas, póngase en contacto con nuestro equipo de asistencia.",
			},
			{
				id: 3,
				title_pt: "Consigo consultar minhas faturas pendentes pelo aplicativo?",
				title_en: "Can I check my pending invoices through the app?",
				title_es:
					"¿Puedo consultar mis facturas pendientes a través de la aplicación?",
				description_pt:
					'Sim, dentro do aplicativo, vá para o menu lateral, no menu lateral, entre na opção "Minha Conta"; \nClique em "Pagamentos" em seguida em “Visualizar Faturas” \nNesta aba é possível ver suas faturas pendentes.',
				description_en:
					'Yes, inside the application, go to the side menu In the side menu, go to the option "My Account"; \nClick on "Payments" and then "View Invoices \nOn this tab you can see your pending invoices.',
				description_es:
					'Sí, dentro de la aplicación, ve al menú lateral En el menú lateral, ve a la opción "Mi cuenta"; \nHaga clic en "Pagos" y luego en "Ver facturas". \nEn esta pestaña puede ver sus facturas pendientes.',
			},
			{
				id: 4,
				title_pt: "Como faço para trocar meu cartão cadastrado?",
				title_en: "How do I change my registered card?",
				title_es: "¿Cómo puedo cambiar mi tarjeta registrada?",
				description_pt:
					'Você consegue trocar dentro do aplicativo! \nNo menu lateral, entre na opção "Minha Conta"; \nClique em "Pagamentos" em seguida em “Editar método de pagamento” \nNesta aba é possível cadastrar o cartão de crédito de preferência.',
				description_en:
					'You can change it within the application! \nOn the side menu, go to "My Account"; \nClick on "Payments" then "Edit payment method \nOn this tab you can register your preferred credit card.',
				description_es:
					'Puedes cambiarlo dentro de la aplicación. \nEn el menú lateral, vaya a "Mi cuenta"; \nHaga clic en "Pagos" y luego en "Editar método de pago". \nEn esta pestaña puede registrar su tarjeta de crédito preferida.',
			},
			{
				id: 5,
				title_pt: "A Kinology emite Nota Fiscal?",
				title_en: "Does Kinology issue Invoices?",
				title_es: "¿Emite Kinology facturas?",
				description_pt:
					"Não emitimos nota fiscal. Disponibilizamos apenas os recibos dos pagamentos.",
				description_en:
					"We do not issue invoices. We only provide payment receipts.",
				description_es:
					"No emitimos facturas. Sólo proporcionamos recibos de pago.",
			},
			{
				id: 6,
				title_pt: "Não consigo pagar minha fatura, o que faço?",
				title_en: "I can't pay my invoice, what should I do?",
				title_es: "No puedo pagar mi factura, ¿qué debo hacer?",
				description_pt:
					"Entre em contato com nosso suporte que lhe daremos mais informações sobre o ocorrido e soluções para regularização de seu pagamento.",
				description_en:
					"Contact our support team and we will give you more information about what happened and solutions to regularize your payment.",
				description_es:
					"Ponte en contacto con nuestro equipo de soporte y te daremos más información sobre lo ocurrido y soluciones para regularizar tu pago.",
			},
			{
				id: 7,
				title_pt:
					"Como faço para alterar a data de vencimento da minha fatura?",
				title_en: "How can I change the due date on my invoice?",
				title_es: "¿Cómo puedo cambiar la fecha de vencimiento de mi factura?",
				description_pt:
					"Você pode alterar sua data de vencimento entrando em contato com nosso suporte.",
				description_en:
					"You can change your expiration date by contacting our support.",
				description_es:
					"Puede cambiar la fecha de vencimiento poniéndose en contacto con nuestro soporte.",
			},
			{
				id: 8,
				title_pt: "Houve duplicidade no pagamento da minha fatura. O que faço?",
				title_en:
					"There was a duplicate payment on my invoice. What should I do?",
				title_es: "Hay un pago duplicado en mi factura. ¿Qué debo hacer?",
				description_pt:
					"Não se preocupe! O valor excedente entrará como crédito para a sua próxima fatura. Caso prefira que o valor seja reembolsado, entre em contato com nosso suporte.",
				description_en:
					"Don't worry, the excess amount will be credited to your next invoice. If you would prefer the amount to be refunded, please contact our support team.",
				description_es:
					"No se preocupe, el importe sobrante se abonará en su próxima factura. Si prefiere que se le devuelva el importe, póngase en contacto con nuestro equipo de asistencia.",
			},
			{
				id: 9,
				title_pt: "Recebi duas faturas em um mesmo mês, por quê?",
				title_en: "I received two invoices in the same month, why?",
				title_es: "He recibido dos facturas en el mismo mes, ¿por qué?",
				description_pt:
					"Caso tenha feito a alteração do vencimento da fatura para uma data fora do mês vigente, você receberá duas faturas no mês subsequente: uma fatura na data de vencimento atual e uma outra na nova data solicitada.",
				description_en:
					"If you have changed the invoice due date to a date outside the current month, you will receive two invoices in the following month: one invoice on the current due date and one on the new requested date.",
				description_es:
					"Si ha cambiado la fecha de vencimiento de la factura a una fecha fuera del mes actual, recibirá dos facturas en el mes siguiente: una factura en la fecha de vencimiento actual y otra en la nueva fecha solicitada.",
			},
			{
				id: 10,
				title_pt:
					"Paguei minha fatura, mas ainda consta como pagamento pendente. O que aconteceu?",
				title_en:
					"I paid my invoice, but it still shows as a pending payment. What happened?",
				title_es:
					"He pagado mi factura, pero sigue apareciendo como pendiente de pago. ¿Qué ha pasado?",
				description_pt:
					"Algumas operações bancárias apresentam um prazo de compensação de até 2 dias úteis, contando a partir do dia em que o pagamento foi realizado. \nCaso este prazo já tenha sido ultrapassado, entre em contato com nosso suporte.",
				description_en:
					"Some banking operations have a clearing period of up to 2 working days, starting from the day on which the payment was made. \nIf this deadline has already been exceeded, please contact our support.",
				description_es:
					"Algunas operaciones bancarias tienen un período de compensación de hasta 2 días laborables, a partir del día en que se realizó el pago. \nSi ya se ha superado este plazo, póngase en contacto con nuestro equipo de asistencia.",
			},
			{
				id: 11,
				title_pt:
					"Caso atrase o pagamento de minha mensalidade, o que acontece?",
				title_en: "If I am late with my monthly payment, what happens?",
				title_es: "¿Qué ocurre si me retraso en el pago mensual?",
				description_pt:
					"Caso não seja identificado o pagamento da sua fatura até a data de vencimento, a mensalidade constará como pendente e serão cobradas multas e juros por atraso. \nApós 10 dias de atraso, os serviços de seu aplicativo Kinology serão bloqueados por falta de pagamento e somente serão liberados mediante a identificação do mesmo. \nSe o atraso persistir, ao contabilizados 30 dias, seu nome será negativado junto a órgãos de proteção ao crédito e se persistir, em 45 dias, seguindo a política da empresa, o contrato de prestação de serviço será cancelado em definitivo, sendo necessária a devolução do equipamento dinamômetro. \nEm todas as situações, sempre comunicaremos com lembretes e notificações antes que qualquer ação seja tomada.",
				description_en:
					"If the payment of your invoice is not identified until the due date, the monthly fee will be recorded as pending and fines and interest will be charged for late payment. \nAfter 10 days of delay, the services of your Kinology application will be blocked for non-payment and will only be released when the payment is identified. \nIf the delay persists, after 30 days, your name will be negatived with the credit protection agencies and if it persists, in 45 days, following the company's policy, the service contract will be definitively cancelled and the dynamometer equipment will have to be returned. \nIn all situations, we will always communicate with reminders and notifications before any action is taken.",
				description_es:
					"Si el pago de su factura no se identifica hasta la fecha de vencimiento, la cuota mensual aparecerá como pendiente y se cobrarán multas e intereses de demora. \nDespués de 10 días de retraso, los servicios de su aplicación Kinology se bloquearán por falta de pago y sólo se liberarán cuando se identifique el pago. \nSi la demora persiste, después de 30 días, su nombre será negativizado ante las agencias de protección de crédito y si persiste, en 45 días, siguiendo la política de la empresa, el contrato de servicio será definitivamente cancelado, siendo necesaria la devolución del equipo dinamométrico. \nEn todas las situaciones, siempre nos comunicaremos con recordatorios y notificaciones antes de tomar cualquier medida.",
			},
			{
				id: 12,
				title_pt: "Parcelas em atraso podem gerar intimações extrajudiciais?",
				title_en: "Can overdue installments generate extrajudicial summonses?",
				title_es:
					"¿Las cuotas atrasadas pueden generar requerimientos extrajudiciales?",
				description_pt:
					"Sim. Após 45 dias, se os critérios de encerramento de contrato não forem cumpridos (devolução do equipamento e pagamento dos débitos vigentes), é iniciado o processo de notificações extrajudiciais.",
				description_en:
					"Yes, after 45 days, if the contract termination criteria are not met (return of the equipment and payment of current debts), the extrajudicial notification process is initiated.",
				description_es:
					"Sí, transcurridos 45 días, si no se cumplen los criterios de rescisión del contrato (devolución del equipo y pago de las deudas corrientes), se inicia el proceso de notificación extrajudicial.",
			},
		],
	},

	{
		category_pt: "Minha assinatura",
		category_en: "My signature",
		category_es: "Mi firma",
		helpers: [
			{
				id: 1,
				title_pt: "Qual é o valor do dinamômetro Kinology?",
				title_en: "How much does the Kinology dynamometer cost?",
				title_es: "¿Cuánto cuesta el dinamómetro Kinology?",
				description_pt:
					"Não vendemos somente o aparelho. O dinamômetro é parte de nossos planos de assinatura e sua utilização depende da sincronização com o app, uma vez que ele não tem interface. Logo, ao realizar sua assinatura conosco, disponibilizamos todo o kit necessário que utilizará em seu plano. Você não paga nada a mais por isso.",
				description_en:
					"We do not sell just the device. The dynamometer is part of our subscription plans and its use depends on the synchronization with the app, since it has no interface. Therefore, when you subscribe with us, we provide all the necessary kit that you will use with your plan. You pay nothing extra for this.",
				description_es:
					"No vendemos sólo el aparato. El dinamómetro forma parte de nuestros planes de suscripción y su uso depende de la sincronización con la app ya que no tiene interfaz. Por lo tanto, cuando se suscribe con nosotros, le proporcionamos todo el kit necesario que utilizará en su plan. No hay que pagar nada extra por ello.",
			},
			{
				id: 2,
				title_pt: "O que é o período de fidelidade?",
				title_en: "What is the loyalty period?",
				title_es: "¿Qué es el periodo de fidelización?",
				description_pt:
					"Período de fidelidade é o período mínimo de contrato de todos os planos de assinatura, em que o consumidor se compromete a utilizar os serviços Kinology.",
				description_en:
					"Fidelity period is the minimum contract period of all subscription plans, in which the consumer commits to use the Kinology services.",
				description_es:
					"El periodo de fidelidad es el periodo mínimo de contratación de todos los planes de suscripción, en el que el cliente se compromete a utilizar los servicios de Kinology.",
			},
			{
				id: 3,
				title_pt: "Quanto tempo tenho de plano?",
				title_en: "How long do I have a plan?",
				title_es: "¿Durante cuánto tiempo tengo un plan?",
				description_pt:
					"Os nossos planos atuais possuem um período de fidelidade de 24 meses. O plano se renova automaticamente, mas caso opte por não renovar o kit fica com o cliente e o uso do app se limita às funcionalidades básicas.",
				description_en:
					"Our current plans have a 24 month loyalty period. The plan automatically renews, but if you choose not to renew, the kit stays with the customer and the use of the app is limited to the basic functionalities.",
				description_es:
					"Nuestros planes actuales tienen un periodo de fidelidad de 24 meses. El plan se renueva automáticamente, pero si decides no renovar el kit se queda con el cliente y el uso de la app se limita a las funcionalidades básicas.",
			},
			{
				id: 4,
				title_pt: "Posso mudar de plano?",
				title_en: "Can I change plans?",
				title_es: "¿Puedo cambiar mi plan?",
				description_pt:
					'Sim, dentro do aplicativo, vá para o menu lateral, indicado pelas 3 barras no canto inferior direito; \nNo menu lateral, entre na opção "Minha Conta"; \nClique em "Meu plano" em seguida em “Upgrade” \nReceberemos o seu pedido e um consultor irá entrar em contato contigo muito em breve, nossos atendentes irão apresentar nossa variedade de planos e ajudá-lo a escolher o melhor que consiga suprir todas as suas necessidades.',
				description_en:
					'Yes, inside the app, go to the side menu, indicated by the 3 bars in the lower right corner; \nIn the side menu, go to the "My Account" option; \nClick on "My Plan" then "Upgrade \nWe will receive your request and a consultant will contact you very shortly, our representatives will present our variety of plans and help you choose the best one that can meet all your needs.',
				description_es:
					'Sí, dentro de la aplicación, ve al menú lateral, indicado por las 3 barras en la esquina inferior derecha; \nEn el menú lateral, vaya a la opción "Mi cuenta"; \nHaga clic en "Mi plan" y luego en "Actualizar". \nRecibiremos su solicitud y un asesor se pondrá en contacto con usted muy pronto, nuestros asistentes le presentarán nuestra variedad de planes y le ayudarán a elegir el mejor que pueda satisfacer todas sus necesidades.',
			},
			{
				id: 5,
				title_pt:
					"O que acontece quando chego no número máximo de cadastros do meu plano?",
				title_en:
					"What happens when I reach the maximum number of members for my plan?",
				title_es:
					"¿Qué ocurre cuando alcanzo el número máximo de afiliados a mi plan?",
				description_pt:
					'Você será notificado pelo próprio app e poderá pedir um upgrade do seu plano. No menu lateral, entre na opção "Minha Conta"; \nClique em "Meu plano" em seguida em “Upgrade” \nReceberemos o seu pedido e um consultor irá entrar em contato contigo muito em breve, nossos atendentes irão apresentar nossa variedade de planos e ajudá-lo a escolher o melhor que consiga suprir todas as suas necessidades.',
				description_en:
					'You will be notified by the app itself and will be able to request an upgrade to your plan. On the side menu, go to "My Account"; \nClick on "My Plan" and then on "Upgrade \nWe will receive your request and a consultant will contact you very shortly, our representatives will present our variety of plans and help you choose the best one that can meet all your needs.',
				description_es:
					'La propia aplicación te avisará y podrás solicitar una mejora de tu plan. En el menú lateral, vaya a "Mi cuenta"; \nHaga clic en "Mi plan" y luego en "Actualizar". \nRecibiremos su solicitud y un asesor se pondrá en contacto con usted muy pronto, nuestros asistentes le presentarán nuestra variedad de planes y le ayudarán a elegir el mejor que pueda satisfacer todas sus necesidades.',
			},
			{
				id: 6,
				title_pt: "Consigo mudar a titularidade de meu contrato?",
				title_en: "Can I change the ownership of my contract?",
				title_es: "¿Puedo cambiar el título de mi contrato?",
				description_pt:
					"Sim, entre em contato com nosso suporte para atualizar o cadastro com os dados do novo titular.",
				description_en:
					"Yes, please contact our support to update your registration with the new holder's data.",
				description_es:
					"Sí, póngase en contacto con nuestro equipo de asistencia para actualizar su registro con los datos del nuevo titular.",
			},
			{
				id: 7,
				title_pt: "Como funciona a garantia e manutenção?",
				title_en: "How does the warranty and maintenance work?",
				title_es: "¿Cómo funciona la garantía y el mantenimiento?",
				description_pt:
					"Somente Clientes assinantes têm a manutenção garantida. Conforme previsto no Código de Defesa ao Consumidor, a garantia de manutenção é de 90 dias corridos. O valor da taxa pode sofrer alterações.",
				description_en:
					"Only subscriber customers are guaranteed maintenance. As provided in the Consumer Defense Code, the maintenance guarantee is 90 calendar days. The amount of the fee may change.",
				description_es:
					"Sólo los clientes abonados tienen garantizado el mantenimiento. Según lo dispuesto en el Código de Protección del Consumidor, la garantía de mantenimiento es de 90 días naturales. El importe de la tasa puede cambiar.",
			},
			{
				id: 8,
				title_pt: "Sou assinante, como faço para cancelar o plano?",
				title_en: "I am a subscriber, how can I cancel my plan?",
				title_es: "Soy abonado, ¿cómo puedo cancelar mi plan?",
				description_pt:
					"Para solicitar o cancelamento de seu plano, basta entrar em contato com nosso suporte. Entre em contato com a nossa equipe de sucesso do cliente através do link http://wa.me/556185953414",
				description_en:
					"To request the cancellation of your plan, just contact our support. Contact our customer success team through the link http://wa.me/556185953414",
				description_es:
					"Para solicitar la cancelación de su plan, sólo tiene que ponerse en contacto con nuestro soporte. Póngase en contacto con nuestro equipo de atención al cliente a través del enlace http://wa.me/556185953414",
			},
			{
				id: 9,
				title_pt: "Posso cancelar o plano após o período de fidelidade?",
				title_en: "Can I cancel the plan after the loyalty period?",
				title_es:
					"¿Puedo cancelar el plan después del periodo de fidelización?",
				description_pt:
					"Sim. Após o período de fidelidade você consegue cancelar seu plano, basta entrar em contato conosco pelo nosso suporte no whatsapp.",
				description_en:
					"Yes. After the loyalty period you can cancel your plan, just contact us via our WhatsApp support.",
				description_es:
					"Sí. Después del período de fidelidad puedes cancelar tu plan, solo contáctanos a través de nuestro soporte de WhatsApp.",
			},
			{
				id: 10,
				title_pt:
					"O que acontece se eu cancelar o plano antes do período de fidelidade acabar?",
				title_en:
					"What happens if I cancel the plan before the loyalty period ends?",
				title_es:
					"¿Qué ocurre si cancelo el plan antes de que termine el periodo de fidelización?",
				description_pt:
					"O dinamômetro volta para a empresa e será aplicada uma multa de fidelidade pelo cancelamento antes do período mínimo de fidelização.",
				description_en:
					"The dynamometer goes back to the company and a loyalty penalty will be applied for cancellation before the minimum loyalty period.",
				description_es:
					"El dinamizador vuelve a la empresa y se aplicará una multa de fidelización por la cancelación antes del periodo mínimo de fidelización.",
			},
			{
				id: 11,
				title_pt: "Tenho acesso a algum treinamento?",
				title_en: "Do I have access to any training?",
				title_es: "¿Tengo acceso a alguna formación?",
				description_pt:
					"Sim! Oferecemos um treinamento para todos os nossos clientes, chamamos de implementação. Ele tem duração máxima de 1 hora e exploramos todas as funções do app, tiramos dúvidas sobre como adaptar o dinamômetro no seu espaço e várias outras dicas.",
				description_en:
					"Yes! We offer a training for all our customers, we call it implementation. It lasts up to 1 hour and we explore all the functions of the app, ask questions about how to adapt the dynamometer to your space, and several other tips.",
				description_es:
					"Sí, ofrecemos una formación para todos nuestros clientes, lo que llamamos implementación. Tiene una duración máxima de 1 hora y exploramos todas las funciones de la app, hacemos preguntas sobre cómo adaptar el dinamómetro en tu espacio y varios consejos más.",
			},
			{
				id: 12,
				title_pt: "O produto é validado cientificamente?",
				title_en: "Is the product scientifically validated?",
				title_es: "¿Está el producto validado científicamente?",
				description_pt:
					"Sim! Ele foi desenvolvido e validado na UnB. Nos solicite por e-mail que enviamos os artigos publicados. Além disso, o Kinology é utilizado em pesquisas por diversas universidades e faculdades nos estados do Pará, Rio Grande do Sul, Rio de Janeiro, São Paulo, Distrito Federal e outros. \n\nCaso queira saber mais sobre a validação do nosso produto, acesse o banco de artigos que usamos como base para validá-lo.",
				description_en:
					"Yes, it was developed and validated at UnB. Ask us by e-mail and we will send you the published articles. Moreover, Kinology is used in researches by several universities and colleges in the states of Pará, Rio Grande do Sul, Rio de Janeiro, São Paulo, Distrito Federal, and others. \n\nIf you want to know more about the validation of our product, please access the article bank that we use as a basis to validate it.",
				description_es:
					"Sí, fue desarrollado y validado en la UnB. Pídanos por correo electrónico y le enviaremos los artículos publicados. Además, el Kinology es utilizado en investigaciones por varias universidades y escuelas superiores de los estados de Pará, Rio Grande do Sul, Rio de Janeiro, São Paulo, Distrito Federal y otros. \n\nSi quiere saber más sobre la validación de nuestro producto, acceda al banco de artículos que utilizamos como base para validarlo.",
			},
			{
				id: 13,
				title_pt:
					"Existe algum material para eu me informar melhor sobre o uso do dinamômetro ou de como realizar avaliações?",
				title_en:
					"Is there any material for me to learn more about how to use the dynamometer or how to perform assessments?",
				title_es:
					"¿Existe algún material para aprender más sobre el uso del dinamómetro o cómo realizar las evaluaciones?",
				description_pt:
					"Sim! Temos vários materiais de leitura para aprimorarem o seu entendimento das avaliações. No próprio aplicativo na tela inicial, agora temos uma área dedicada para isso com conteúdos do nosso blog, os nossos cases de sucessos e muito mais.",
				description_en:
					"Yes, we have several reading materials to enhance your understanding of the assessments. In the application itself on the home screen, we now have an area dedicated to this with content from our blog, our success stories, and more.",
				description_es:
					"Sí, tenemos varios materiales de lectura para mejorar su comprensión de las evaluaciones. En la propia aplicación, en la pantalla de inicio, tenemos ahora un área dedicada a ello con contenidos de nuestro blog, nuestras historias de éxito y mucho más.",
			},
			{
				id: 14,
				title_pt:
					"O que é o Nível de Condicionamento dentro da Anamnese na Ficha do Paciente? E porque não consigo incluir outras opções?",
				title_en:
					"What is the Conditioning Level within the Anamnesis in the Patient Record? And why can't I include other options?",
				title_es:
					"¿Qué es el nivel de condicionamiento dentro de la anamnesis en el registro del paciente? ¿Y por qué no puedo incluir otras opciones?",
				description_pt:
					"O nível de condicionamento é o estado do seu paciente é a sua capacidade de realizar atividades diárias e esportes. Ou seja, não está relacionado a idade.",
				description_en:
					"The level of conditioning is the state of your patient's ability to perform daily activities and sports. In other words, it is not age-related.",
				description_es:
					"El nivel de acondicionamiento es el estado de la capacidad de su paciente para realizar actividades cotidianas y deportivas. En otras palabras, no está relacionado con la edad.",
			},
			{
				id: 15,
				title_pt:
					"Onde encontro os valores de referência do relatório de evolução?",
				title_en:
					"Where do I find the reference values in the progress report?",
				title_es:
					"¿Dónde encuentro los valores de referencia en el informe de progreso?",
				description_pt:
					"Você encontra a referência científica no rodapé no próprio relatório.",
				description_en:
					"You find the scientific reference in the footer of the report itself.",
				description_es:
					"Encontrará la referencia científica en la parte inferior del propio informe.",
			},
			{
				id: 16,
				title_pt:
					"Caso um exercício que eu queira cadastrar não esteja vinculado a nenhum dos grupamentos musculares disponíveis no app, o que devo fazer?",
				title_en:
					"If an exercise that I want to register is not linked to any of the muscle groupings available in the app, what should I do?",
				title_es:
					"Si un ejercicio que quiero registrar no está vinculado a ninguna de las agrupaciones musculares disponibles en la aplicación, ¿qué debo hacer?",
				description_pt:
					"Você pode entrar em contato com um de nossos consultores e sugerir o grupamento que você deseja. A nossa equipe técnica avalia e faz a validação, caso seja aprovado, irá aparecer no relatório de assimetria e se tornará um padrão para todos os clientes.",
				description_en:
					"You can contact one of our consultants and suggest the grouping you want. Our technical team evaluates and validates it, and if it is approved, it will appear in the asymmetry report and will become a standard for all customers.",
				description_es:
					"Puede ponerse en contacto con uno de nuestros asesores y proponerle la agrupación que desee. Nuestro equipo técnico lo evaluará y validará. Si se aprueba, aparecerá en el informe de asimetría y se convertirá en un estándar para todos los clientes.",
			},
			{
				id: 17,
				title_pt: "Porque alguns exercícios não tem valor de referência?",
				title_en: "Why do some exercises have no reference value?",
				title_es: "¿Por qué algunos ejercicios no tienen valor de referencia?",
				description_pt:
					"Alguns exercícios não tem valor de referência porque os estudos que temos atualmente não foi realizado em todos tipos de exercícios, exercícios personalizados cadastrados por usuários também não irá apresentar valores de referências.",
				description_en:
					"Some exercises do not have a reference value because the studies we currently have have not been performed on all types of exercises, and personalized exercises registered by users will not present reference values either.",
				description_es:
					"Algunos ejercicios no tienen un valor de referencia porque los estudios que tenemos actualmente no se han realizado sobre todos los tipos de ejercicios. Los ejercicios personalizados registrados por los usuarios tampoco presentarán valores de referencia.",
			},
			{
				id: 18,
				title_pt: "Qual carga mínima eu coloco?",
				title_en: "What is the minimum load I should put on?",
				title_es: "¿Qué carga mínima debo colocar?",
				description_pt:
					"A sugestão inicial é de 3kg, mas pode ser alterada, depois do primeiro uso o app sugere a configuração já utilizada anteriormente.",
				description_en:
					"The initial suggestion is 3kg, but it can be changed, after the first use the app suggests the configuration already used previously.",
				description_es:
					"La sugerencia inicial es de 3kg, pero se puede cambiar, después del primer uso la app sugiere la configuración ya utilizada anteriormente.",
			},
			{
				id: 19,
				title_pt: "Preciso utilizar sempre a carga máxima?",
				title_en: "Do I always have to use the maximum load?",
				title_es: "¿Tengo que utilizar siempre la carga máxima?",
				description_pt:
					"Não. Agora ela não é mais obrigatória, somente se configurar que deseja visualizar a  carga máxima que ela aparecerá.",
				description_en:
					"No. Now it is no longer mandatory, only if you set the maximum load that will appear.",
				description_es:
					"No. Ahora ya no es obligatorio, sólo si estableces que quieres ver la carga máxima que aparecerá.",
			},
		],
	},
];
