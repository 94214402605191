import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import background from "../../assets/background_question_async.svg";
import { TextsProps } from "../../database/txt.interface";
import {
	TypeButtonsQuestionnaire,
	TypesQuestionnaire,
} from "../../utils/types";
import { Conteiner, Content, Header, Container } from "./styles";
import Alerts from "./Components/_renderAlerts";
import Questionario_progress from "../Paciente/Questionario_progress";
import FlagLang from "./Components/_renderSetLang";
import { PacAvalActions } from "../../store/ducks/manage_pac_aval";
import Logo from '../../assets/kinologyIcons/svg/logo.svg'

const QuestionarioAsync: React.FC = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const questionario: any = useSelector(
		(state: any) => state.pac_aval.questionario
	);
	const questionario_array = useSelector(
		(state: any) => state.pac_aval.questionario_array
	);
	const questionario_resposta_concluido: any = useSelector(
		(state: any) => state.pac_aval.questionario_resposta_concluido
	);

	const [error, setError] = useState<boolean>(false);
	const [typeAlert, setTypeAlert] = useState<string>("error"); // success | error
	const [title, setTitle] = useState<string>("");
	const [subTitle, setSubTitle] = useState<string>("");

	function useQuery() {
		const { search } = useLocation();
		return useMemo(() => new URLSearchParams(search), [search]);
	}
	const queryParams = useQuery();
	const token: string | any = queryParams.get("t") || false;

	useEffect(() => {
		if (token) {
			dispatch(PacAvalActions.set_questionario_async(true));
			dispatch(PacAvalActions.set_questionario_async_token(token));
			dispatch(
				PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE_TIPO)
			);
			dispatch(PacAvalActions.set_questionario_request(true));
		} else {
			setTitle(current_language?.link_nao_encontrado);
			setSubTitle(current_language?.question_description_async);
			setError(true);
		}
	}, [token, language]);

	useEffect(() => {
		if (questionario_array && questionario_array.status === "fail") {
			if (questionario_array.code === 1) {
				setTitle(current_language?.link_nao_encontrado);
				setSubTitle(current_language?.question_description_async);
			} else if (questionario_array.code === 2) {
				setTypeAlert("success");
				setTitle(current_language?.question_ja_respon);
				setSubTitle(current_language?.anamnese_resp_novo_link);
			}
			setError(true);
		}
		if (!questionario_array) {
			setTitle(current_language?.link_nao_encontrado);
			setSubTitle(current_language?.question_description_async);
			setError(true);
		}
		if (
			questionario_resposta_concluido &&
			questionario_resposta_concluido.CONCLUIDO === "S"
		) {
			if (
				questionario_resposta_concluido.TIPO ===
				TypeButtonsQuestionnaire.BT_FINALIZAR_ANAMNESE_TIPO
			) {
				setTypeAlert("success");
				setTitle("Anamnese respondida !");
				setSubTitle(current_language?.anamnese_resp_sair_pag);
				setError(true);
			} else if (
				questionario_resposta_concluido.TIPO ===
				TypeButtonsQuestionnaire.BT_FINALIZAR_SF_36
			) {
				setTypeAlert("success");
				setTitle(current_language?.qualidade_de_vida_resp);
				setSubTitle(current_language?.anamnese_resp_sair_pag);
				setError(true);
			}
		}
	}, [questionario_array, questionario_resposta_concluido, language]);

	return (
		<Conteiner background={background}>
			<Content>
				<Header>
					<img src={Logo} style={{width: 70, height: 70, marginTop: 5}} />
					<FlagLang />
				</Header>
				<Container>
					{error ? (
						<Alerts type={typeAlert} title={title} subTitle={subTitle} />
					) : questionario === TypesQuestionnaire.ANAMNESE_TIPO ? (
						<Questionario_progress
							key={TypesQuestionnaire.ANAMNESE}
							Type={TypesQuestionnaire.ANAMNESE}
						/>
					) : questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO ? (
						<Questionario_progress
							key={TypesQuestionnaire.ANAMNESE_AVALIACAO}
							Type={TypesQuestionnaire.ANAMNESE_AVALIACAO}
						/>
					) : questionario === TypesQuestionnaire.SF_36 ? (
						<Questionario_progress
							key={TypesQuestionnaire.SF_36}
							Type={TypesQuestionnaire.SF_36}
						/>
					) : (
						<></>
					)}
				</Container>
			</Content>
		</Conteiner>
	);
};

export default QuestionarioAsync;
