import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ConfigsActions } from "../../store/ducks/configs";
import { Conteiner } from "./styles";

interface ToogleProps {
	isCheck: boolean;
	action: any;
}

const Toogle: React.FC<ToogleProps> = ({ isCheck = false, action }: any) => {
	const dispatch = useDispatch();
	const tempoContracao = useSelector(
		(state: any) => state.configs.tempo_contracao
	);
	const intervaloDescanso = useSelector(
		(state: any) => state.configs.intervalo_descanso
	);

	const tempo_contracao_flag = useSelector(
		(state: any) => state.configs.tempo_contracao_flag
	);
	const alerta_sonoro = useSelector(
		(state: any) => state.configs.alerta_sonoro
	);
	const alerta_vibratorio = useSelector(
		(state: any) => state.configs.alerta_vibratorio
	);
	const contagem_regressiva = useSelector(
		(state: any) => state.configs.contagem_regressiva
	);
	const intervalo_descanso_flag = useSelector(
		(state: any) => state.configs.intervalo_descanso_flag
	);
	const config_funcional = useSelector(
		(state: any) => state.configs.config_funcional,
	);
	const bluetooth = useSelector((state: any) => state.configs.bluetooth);
	const modo_demo = useSelector((state: any) => state.configs.modo_demo);

	enum typeAction {
		ATIVAR_SONORO = "ativarSonoro",
		ATIVAR_BLUETOOTH = "ativarBluetooth",
		ATIVAR_CONTAGEM = "ativarContagem",
		ATIVAR_DESCANSO = "ativarDescanco",
		ATIVAR_CONTRACAO = "ativarContracao",
		DEMO = "demo",
		ATIVAR_FUNCIONAL = "ativarFuncional",
	};

	const HandleCondition = () => {
		if (action === typeAction.ATIVAR_SONORO ) {
			dispatch(ConfigsActions.set_alerta_sonoro(!alerta_sonoro));
			dispatch(
				ConfigsActions.config_request_edit({
					CONFIG_EXER_SOM: !alerta_sonoro,
				})
			);
		}
		if (action === typeAction.ATIVAR_BLUETOOTH ) {
			dispatch(ConfigsActions.set_bluetooth(!bluetooth));
		}
		if (action === typeAction.ATIVAR_CONTAGEM) {
			dispatch(ConfigsActions.set_contagem_regresiva(!contagem_regressiva));
			dispatch(
				ConfigsActions.config_request_edit({
					CONFIG_EXER_321: !contagem_regressiva,
				})
			);
		}
		if (action === typeAction.ATIVAR_DESCANSO) {
			dispatch(ConfigsActions.set_intervalo_descanso(!intervalo_descanso_flag));
			dispatch(
				ConfigsActions.config_request_edit({
					CONFIG_EXER_DESCANSO_FLAG: !intervalo_descanso_flag,
					CONFIG_EXER_DESCANSO: intervaloDescanso,
				})
			);
		}
		if (action === typeAction.ATIVAR_CONTRACAO) {
			dispatch(ConfigsActions.set_tempo_contracao(!tempo_contracao_flag));
			dispatch(
				ConfigsActions.config_request_edit({
					CONFIG_EXER_CONTRACAO_FLAG: !tempo_contracao_flag,
					CONFIG_EXER_CONTRACAO: tempoContracao,
				})
			);
		}
		if (action === typeAction.DEMO) {
			//Disparar ação aqui
			dispatch(ConfigsActions.set_modo_demo(!modo_demo));
		}
		if (action === typeAction.ATIVAR_FUNCIONAL) {
			dispatch(ConfigsActions.set_config_funcional(!config_funcional));
			dispatch(
				ConfigsActions.config_request_edit({
					CONFIG_FUNCIONAL: !config_funcional
				})
			);
		}

	};

	return (
		<Conteiner>
			<label className="switch">
				<input type="checkbox" checked={isCheck} onChange={HandleCondition} />
				<span className="slider round"></span>
			</label>
		</Conteiner>
	);
};

export default memo(Toogle);
