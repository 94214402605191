//abdominal
import INI_ABDOMINAL_LATERAL from '../assets/tutoriais/INI_ABDOMINAL_LATERAL.jpg';
import FIN_ABDOMINAL_LATERAL from '../assets/tutoriais/FIN_ABDOMINAL_LATERAL.jpg';
//abdução de ombro
import INI_ABDUCAO_OMBRO_30_FRONTAL from '../assets/tutoriais/INI_ABDUCAO_OMBRO_30_FRONTAL.jpg';
import FIN_ABDUCAO_OMBRO_30_FRONTAL from '../assets/tutoriais/FIN_ABDUCAO_OMBRO_30_FRONTAL.jpg';
//Abdução de Quadril
import INI_ABDUCAO_QUADRIL_FRONTAL from '../assets/tutoriais/INI_ABDUCAO_QUADRIL_FRONTAL.jpg';
import FIN_ABDUCAO_QUADRIL_FRONTAL from '../assets/tutoriais/FIN_ABDUCAO_QUADRIL_FRONTAL.jpg';
import FIN_ABDUCAO_QUADRIL_LATERAL from '../assets/tutoriais/FIN_ABDUCAO_QUADRIL_LATERAL.jpg';
//Adução de Ombro
import INI_ADUCAO_OMBRO from '../assets/tutoriais/INI_ADUCAO_OMBRO.jpg';
import FIN_ADUCAO_OMBRO from '../assets/tutoriais/FIN_ADUCAO_OMBRO.jpg';
//Adução de Quadril
import INI_ADUCAO_QUADRIL_FRONTAL from '../assets/tutoriais/INI_ADUCAO_QUADRIL_FRONTAL.jpg';
import FIN_ADUCAO_QUADRIL_FRONTAL from '../assets/tutoriais/FIN_ADUCAO_QUADRIL_FRONTAL.jpg';
//Afundo
import INI_AFUNDO_LATERAL from '../assets/tutoriais/INI_AFUNDO_LATERAL.jpg';
import FIN_AFUNDO_LATERAL from '../assets/tutoriais/FIN_AFUNDO_LATERAL.jpg';
import INI_AFUNDO_DIAGONAL from '../assets/tutoriais/INI_AFUNDO_DIAGONAL.jpg';
//Agachamento
import INI_AGACHAMENTO_DIAGONAL from '../assets/tutoriais/INI_AGACHAMENTO_DIAGONAL.jpg';
import FIN_AGACHAMENTO_DIAGONAL from '../assets/tutoriais/FIN_AGACHAMENTO_DIAGONAL.jpg';
//Crucifixo
import FIN_CRUCIFIXO_FRONTAL from '../assets/tutoriais/FIN_CRUCIFIXO_FRONTAL.jpg';
import INI_CRUCIFIXO_LATERAL from '../assets/tutoriais/INI_CRUCIFIXO_LATERAL.jpg';
import FIN_CRUCIFIXO_LATERAL from '../assets/tutoriais/FIN_CRUCIFIXO_LATERAL.jpg';
//Desenvolvimento de Ombro 
import FIN_DESENVOLVIMENTO_DE_OMBRO_BILATERAL_DIAGONAL from '../assets/tutoriais/FIN_DESENVOLVIMENTO_DE_OMBRO_BILATERAL_DIAGONAL.jpg';

//Dorsiflexão
import INI_DORSIFLEXAO_LATERAL from '../assets/tutoriais/INI_DORSIFLEXAO_LATERAL.jpg';
import FIN_DORSIFLEXAO_LATERAL from '../assets/tutoriais/FIN_DORSIFLEXAO_LATERAL.jpg';
//Eversão
import INI_EVERSAO_DIAGONAL from '../assets/tutoriais/INI_EVERSAO_DIAGONAL.jpg';
import FIN_EVERSAO_DIAGONAL from '../assets/tutoriais/FIN_EVERSAO_DIAGONAL.jpg';
//Extensão de Cotovelo
import INI_EXTENSAO_DE_COTOVELO_LATERAL from '../assets/tutoriais/INI_EXTENSAO_DE_COTOVELO_LATERAL.jpg';
import FIN_EXTENSAO_DE_COTOVELO_LATERAL from '../assets/tutoriais/FIN_EXTENSAO_DE_COTOVELO_LATERAL.jpg';
//Extensão de Cotovelo Francês bilateral
import INI_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_VARIACAO1_LATERAL from '../assets/tutoriais/INI_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_VARIACAO1_LATERAL.jpg';
import FIN_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_VARIACAO1_LATERAL from '../assets/tutoriais/FIN_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_VARIACAO1_LATERAL.jpg';
//Extensão de Cotovelo Testa bilateral
import INI_EXTENSAO_DE_COTOVELO_TESTA_BILATERAL_LATERAL from '../assets/tutoriais/INI_EXTENSAO_DE_COTOVELO_TESTA_BILATERAL_LATERAL.jpg';
import FIN_EXTENSAO_DE_COTOVELO_TESTA_BILATERAL_LATERAL from '../assets/tutoriais/FIN_EXTENSAO_DE_COTOVELO_TESTA_BILATERAL_LATERAL.jpg';
import INI_EXTENSAO_DE_COTOVELO_TESTA_BILATERAL_FRONTAL from '../assets/tutoriais/INI_EXTENSAO_DE_COTOVELO_TESTA_BILATERAL_FRONTAL.jpg';
//Extensão de Joelho
import INI_EXTENSAO_JOELHO_LATERAL from '../assets/tutoriais/INI_EXTENSAO_JOELHO_LATERAL.jpg';
import FIN_EXTENSAO_JOELHO_LATERAL from '../assets/tutoriais/FIN_EXTENSAO_JOELHO_LATERAL.jpg';
//Extensão de Ombro
import INI_EXTENSAO_OMBRO_LATERAL from '../assets/tutoriais/INI_EXTENSAO_OMBRO_LATERAL.jpg';
import FIN_EXTENSAO_OMBRO_LATERAL from '../assets/tutoriais/FIN_EXTENSAO_OMBRO_LATERAL.jpg';
//Extensão de Quadril
import INI_EXTENSAO_QUADRIL_LATERAL from '../assets/tutoriais/INI_EXTENSAO_QUADRIL_LATERAL.jpg';
import FIN_EXTENSAO_QUADRIL_LATERAL from '../assets/tutoriais/FIN_EXTENSAO_QUADRIL_LATERAL.jpg';
//Flexão de Cotovelo
import INI_FLEXAO_COTOVELO_LATERAL from '../assets/tutoriais/INI_FLEXAO_COTOVELO_LATERAL.jpg';
import FIN_FLEXAO_COTOVELO_LATERAL from '../assets/tutoriais/FIN_FLEXAO_COTOVELO_LATERAL.jpg';
//Flexão de Joelho em dv
import INI_FLEXAO_JOELHO_DV_LATERAL from '../assets/tutoriais/INI_FLEXAO_JOELHO_DV_LATERAL.jpg';
import FIN_FLEXAO_JOELHO_DV_LATERAL from '../assets/tutoriais/FIN_FLEXAO_JOELHO_DV_LATERAL.jpg';
//Flexão de Ombro 
import INI_FLEXAO_OMBRO_LATERAL from '../assets/tutoriais/INI_FLEXAO_OMBRO_LATERAL.jpg';
import FIN_FLEXAO_OMBRO_LATERAL from '../assets/tutoriais/FIN_FLEXAO_OMBRO_LATERAL.jpg';
//Flexão do Pescoço
import INI_FLEXAO_PESCOCO_LATERAL from '../assets/tutoriais/INI_FLEXAO_PESCOCO_LATERAL.jpg';
import FIN_FLEXAO_PESCOCO_LATERAL from '../assets/tutoriais/FIN_FLEXAO_PESCOCO_LATERAL.jpg';
//Flexão de punho
import INI_FLEXAO_PUNHO_DIAGONAL from '../assets/tutoriais/INI_FLEXAO_PUNHO_DIAGONAL.jpg';
import FIN_FLEXAO_PUNHO_DIAGONAL from '../assets/tutoriais/FIN_FLEXAO_PUNHO_DIAGONAL.jpg';
//Flexão de quadril
import INI_FLEXAO_QUADRIL_LATERAL from '../assets/tutoriais/INI_FLEXAO_QUADRIL_LATERAL.jpg';
import FIN_FLEXAO_QUADRIL_LATERAL from '../assets/tutoriais/FIN_FLEXAO_QUADRIL_LATERAL.jpg';
//Flexão lateral de pescoço
import INI_FLEXAO_LATERAL_PESCOCO_FRONTAL from '../assets/tutoriais/INI_FLEXAO_LATERAL_PESCOCO_FRONTAL.jpg';
import FIN_FLEXAO_LATERAL_PESCOCO_FRONTAL from '../assets/tutoriais/FIN_FLEXAO_LATERAL_PESCOCO_FRONTAL.jpg';
//Flexão lateral de tronco
import INI_FLEXAO_LATERAL_TRONCO_FRONTAL from '../assets/tutoriais/INI_FLEXAO_LATERAL_TRONCO_FRONTAL.jpg';
import FIN_FLEXAO_LATERAL_TRONCO_FRONTAL from '../assets/tutoriais/FIN_FLEXAO_LATERAL_TRONCO_FRONTAL.jpg';
//Inversão
import INI_INVERSAO_SUPERIOR from '../assets/tutoriais/INI_INVERSAO_SUPERIOR.jpg';
import FIN_INVERSAO_SUPERIOR from '../assets/tutoriais/FIN_INVERSAO_SUPERIOR.jpg';
//Remada Aberta Bilateral
import INI_REMADA_ABERTA_UNILATERAL_LATERAL from '../assets/tutoriais/INI_REMADA_ABERTA_UNILATERAL_LATERAL.jpg';
import FIN_REMADA_ABERTA_UNILATERAL_LATERAL from '../assets/tutoriais/FIN_REMADA_ABERTA_UNILATERAL_LATERAL.jpg';
//Remada Alta Bilateral
// INI_REMADA_ALTA_BILATERAL_VISTA_DIAGONAL
import INI_REMADA_ALTA_BILATERAL_VISTA_DIAGONAL from '../assets/tutoriais/INI_REMADA_ALTA_BILATERAL_VISTA_DIAGONAL.jpg';
import FIN_REMADA_ALTA_BILATERAL_VISTA_DIAGONAL from '../assets/tutoriais/FIN_REMADA_ALTA_BILATERAL_VISTA_DIAGONAL.jpg';
//Remada bilateral
import INI_REMADA_UNILATERAL_LATERAL from '../assets/tutoriais/INI_REMADA_UNILATERAL_LATERAL.jpg';
import FIN_REMADA_UNILATERAL_LATERAL from '../assets/tutoriais/FIN_REMADA_UNILATERAL_LATERAL.jpg';
//Rotação Externa de Ombro
import INI_ROTACAO_EXTERNA_OMBRO_FRONTAL from '../assets/tutoriais/INI_ROTACAO_EXTERNA_OMBRO_FRONTAL.jpg';
import FIN_ROTACAO_EXTERNA_OMBRO_FRONTAL from '../assets/tutoriais/FIN_ROTACAO_EXTERNA_OMBRO_FRONTAL.jpg';
//Rotação Externa de Quadril
import INI_ROTACAO_EXTERNA_QUADRIL_SUPERIOR from '../assets/tutoriais/INI_ROTACAO_EXTERNA_QUADRIL_SUPERIOR.jpg';
import FIN_ROTACAO_EXTERNA_QUADRIL_SUPERIOR from '../assets/tutoriais/FIN_ROTACAO_EXTERNA_QUADRIL_SUPERIOR.jpg';
//Rotação Interna de Ombro
import FIN_ROTACAO_INTERNA_OMBRO_VISTA_DIAGONAL from '../assets/tutoriais/FIN_ROTACAO_INTERNA_OMBRO_VISTA_DIAGONAL.jpg';
import INI_ROTACAO_INTERNA_OMBRO_VISTA_FRONTAL from '../assets/tutoriais/INI_ROTACAO_INTERNA_OMBRO_VISTA_FRONTAL.jpg';
import FIN_ROTACAO_INTERNA_OMBRO_VISTA_FRONTAL from '../assets/tutoriais/FIN_ROTACAO_INTERNA_OMBRO_VISTA_FRONTAL.jpg';
//Rotação Interna de Quadril
import INI_ROTACAO_INTERNA_QUADRIL_SUPERIOR from '../assets/tutoriais/INI_ROTACAO_INTERNA_QUADRIL_SUPERIOR.jpg';
import FIN_ROTACAO_INTERNA_QUADRIL_SUPERIOR from '../assets/tutoriais/FIN_ROTACAO_INTERNA_QUADRIL_SUPERIOR.jpg';
//Supino Bilateral
import INI_SUPINO_UNILATERAL_VISTA_LATERAL from '../assets/tutoriais/INI_SUPINO_UNILATERAL_VISTA_LATERAL.jpg';
import FIN_SUPINO_BILATERAL_VISTA_LATERAL from '../assets/tutoriais/FIN_SUPINO_BILATERAL_VISTA_LATERAL.jpg';
import FIN_SUPINO_BILATERAL_VISTA_FRONTAL from '../assets/tutoriais/FIN_SUPINO_BILATERAL_VISTA_FRONTAL.jpg';


export const tutorialImages: any = {
  Agachamento: [INI_AGACHAMENTO_DIAGONAL, FIN_AGACHAMENTO_DIAGONAL],
  'Flexão de cotovelo': [INI_FLEXAO_COTOVELO_LATERAL, FIN_FLEXAO_COTOVELO_LATERAL],
  'Extensão de cotovelo': [INI_EXTENSAO_DE_COTOVELO_LATERAL, FIN_EXTENSAO_DE_COTOVELO_LATERAL],
  'Extensão de cotovelo francês': [
    INI_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_VARIACAO1_LATERAL, FIN_EXTENSAO_DE_COTOVELO_FRANCES_BILATERAL_VARIACAO1_LATERAL
  ],
  'Extensão de cotovelo testa': [
    INI_EXTENSAO_DE_COTOVELO_TESTA_BILATERAL_LATERAL, FIN_EXTENSAO_DE_COTOVELO_TESTA_BILATERAL_LATERAL,
    INI_EXTENSAO_DE_COTOVELO_TESTA_BILATERAL_FRONTAL
  ],
  'Remada alta': [INI_REMADA_ALTA_BILATERAL_VISTA_DIAGONAL, FIN_REMADA_ALTA_BILATERAL_VISTA_DIAGONAL],
  Remada: [
    INI_REMADA_UNILATERAL_LATERAL, FIN_REMADA_UNILATERAL_LATERAL,
  ],
  'Remada aberta': [
    INI_REMADA_ABERTA_UNILATERAL_LATERAL, FIN_REMADA_ABERTA_UNILATERAL_LATERAL
  ],
  'Supino reto': [
    INI_SUPINO_UNILATERAL_VISTA_LATERAL, FIN_SUPINO_BILATERAL_VISTA_LATERAL,FIN_SUPINO_BILATERAL_VISTA_FRONTAL
  ],
  Crucifixo: [
    FIN_CRUCIFIXO_FRONTAL, INI_CRUCIFIXO_LATERAL, FIN_CRUCIFIXO_LATERAL
  ],
  'Flexão lateral de tronco': [INI_FLEXAO_LATERAL_TRONCO_FRONTAL, FIN_FLEXAO_LATERAL_TRONCO_FRONTAL],
  Abdominal: [INI_ABDOMINAL_LATERAL, FIN_ABDOMINAL_LATERAL],
  'Rotação interna': [
    FIN_ROTACAO_INTERNA_OMBRO_VISTA_DIAGONAL,
    INI_ROTACAO_INTERNA_OMBRO_VISTA_FRONTAL, FIN_ROTACAO_INTERNA_OMBRO_VISTA_FRONTAL
  ],
  'Rotação externa': [INI_ROTACAO_EXTERNA_OMBRO_FRONTAL, FIN_ROTACAO_EXTERNA_OMBRO_FRONTAL],
  'Flexão de ombro': [INI_FLEXAO_OMBRO_LATERAL, FIN_FLEXAO_OMBRO_LATERAL],
  'Extensão de ombro': [INI_EXTENSAO_OMBRO_LATERAL, FIN_EXTENSAO_OMBRO_LATERAL],
  'Abdução de ombro': [INI_ABDUCAO_OMBRO_30_FRONTAL, FIN_ABDUCAO_OMBRO_30_FRONTAL],
  'Adução de ombro': [INI_ADUCAO_OMBRO, FIN_ADUCAO_OMBRO],
  'Desenvolvimento de ombro': [FIN_DESENVOLVIMENTO_DE_OMBRO_BILATERAL_DIAGONAL],
  'Flexão plantar': [],
  Dorsiflexão: [INI_DORSIFLEXAO_LATERAL, FIN_DORSIFLEXAO_LATERAL],
  'Abdução de quadril': [
    INI_ABDUCAO_QUADRIL_FRONTAL, FIN_ABDUCAO_QUADRIL_FRONTAL,
    FIN_ABDUCAO_QUADRIL_LATERAL
  ],
  'Adução de quadril': [
    INI_ADUCAO_QUADRIL_FRONTAL, FIN_ADUCAO_QUADRIL_FRONTAL
  ],
  Afundo: [
    INI_AFUNDO_LATERAL, FIN_AFUNDO_LATERAL,
    INI_AFUNDO_DIAGONAL
  ],
  'Flexão de joelho': [
    INI_FLEXAO_JOELHO_DV_LATERAL, FIN_FLEXAO_JOELHO_DV_LATERAL,
  ],
  'Extensão de joelho': [INI_EXTENSAO_JOELHO_LATERAL, FIN_EXTENSAO_JOELHO_LATERAL],
  'Flexão de quadril': [INI_FLEXAO_QUADRIL_LATERAL, FIN_FLEXAO_QUADRIL_LATERAL],
  'Extensão de quadril': [INI_EXTENSAO_QUADRIL_LATERAL, FIN_EXTENSAO_QUADRIL_LATERAL],
  'Flexão do pescoço': [INI_FLEXAO_PESCOCO_LATERAL, FIN_FLEXAO_PESCOCO_LATERAL],
  'Extensão do pescoço': [],
  'Flexão lateral do pescoço': [INI_FLEXAO_LATERAL_PESCOCO_FRONTAL, FIN_FLEXAO_LATERAL_PESCOCO_FRONTAL],
  'Rotação do pescoço': [],
  'Pronação do antebraço': [],
  'Flexão de punho': [INI_FLEXAO_PUNHO_DIAGONAL, FIN_FLEXAO_PUNHO_DIAGONAL],
  'Desvio Ulnar do punho': [],
  'Desvio Radial do punho': [],
  'Supinação do antebraço': [],
  Plantiflexão: [],
  Inversão: [INI_INVERSAO_SUPERIOR, FIN_INVERSAO_SUPERIOR],
  Eversão: [
    INI_EVERSAO_DIAGONAL, FIN_EVERSAO_DIAGONAL,
  ],
  'Extensão de punho': [],
  'Inversão resistida': [],
  'Eversão resistida': [],
};