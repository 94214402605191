import {
	Backdrop,
	Fade,
	Modal,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../store/ducks/app";
import { PacAvalActions } from "../../store/ducks/manage_pac_aval";
import buttonClose from "../../assets/icons/buttonCloseBlack.svg";
import { PacientesProps } from "../../interfaces/pacientes.interface";
import Perfil from "../../assets/icons/perfil.svg";
import moment from "moment";
import { TagActions } from "../../store/ducks/tags";
import { ButtonSave, Tag } from "../../styles/global";
import { TextsProps } from "../../database/txt.interface";
import {
	Close,
	Container,
	ContainerOptions,
	ContainerPaci,
	Name,
	Photo,
	Subtitle,
	Title,
} from "../../styles/styles_modais_padroes";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

interface TagsProps {
	ID_ETIQUETA: number;
	ETIQUETA_NOME: string;
	ID_USUARIOS: [];
}

const ModalEtiqueta: React.FC = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const is_open_atribuir_etiqueta = useSelector(
		(state: any) => state.app.is_open_atribuir_etiqueta
	);

	const lista_pacientes = useSelector(
		(state: any) => state.app.lista_pacientes
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);
	const etiquetas = useSelector((state: any) => state.dashboard.etiquetas);
	const [identificador, setIdentificador] = useState<number[]>([]);

	const ModalClose = () => {
		dispatch(AppActions.set_lista_pacientes([]));
		dispatch(PacAvalActions.reset_patient());
		dispatch(AppActions.set_modal_atribuir_etiqueta(false));
		setIdentificador([]);
	};

	const handleActiveTag = (id: number) => () => {
		let newIdentificador: any = [];
		if (identificador?.includes(id)) {
			const filter = identificador.filter((e: any) => e !== id);
			newIdentificador = filter;
		} else {
			newIdentificador = [...identificador, id];
		}
		setIdentificador(newIdentificador);
	};

	const atribuir = () => {
		identificador?.map((tag: any) => {
			lista_pacientes?.map((e: PacientesProps) => {
				const request = {
					txDataHora: moment(new Date())
						.format("YYYY-MM-DD HH:mm:ss")
						.toString(),
					ID_ETIQUETA: tag,
					ID_USUARIO: e,
				};
				dispatch(TagActions.set_atribuir_tags(request));
			});
		});
		ModalClose();
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open_atribuir_etiqueta}
			onClose={ModalClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open_atribuir_etiqueta}>
				<Container>
					<Close
						className="close"
						src={buttonClose}
						onClick={ModalClose}
						alt="Close"
					/>
					<Title>{current_language?.tag}</Title>
					<Subtitle>{current_language?.pacientes_selecionados}:</Subtitle>
					<ContainerPaci>
						{lista_pacientes.length == 1 ? (
							<Photo
								src={
									pacientes.find(
										(user: PacientesProps) =>
											user.ID_USUARIO === lista_pacientes[0]
									)?.USUARIO_FOTO
										? pacientes.find(
												(user: PacientesProps) =>
													user.ID_USUARIO === lista_pacientes[0]
										  )?.USUARIO_FOTO
										: Perfil
								}
							/>
						) : (
							<Photo src={Perfil} />
						)}
						<Name>
							{lista_pacientes.length == 1
								? pacientes.find(
										(user: PacientesProps) =>
											user.ID_USUARIO === lista_pacientes[0]
								  )?.USUARIO_NOME
								: lista_pacientes.length +
								  " " +
								  current_language?.pacientes_selecionados}
						</Name>
					</ContainerPaci>
					<Subtitle>{current_language?.selecione_avaliador}:</Subtitle>
					<ContainerOptions>
						<ContainerWrap>
							{etiquetas.map((etiqueta: TagsProps) => (
								<Tag
									onClick={handleActiveTag(etiqueta.ID_ETIQUETA)}
									key={etiqueta.ID_ETIQUETA}
									style={
										identificador.find((e: any) => e === etiqueta.ID_ETIQUETA)
											? { backgroundColor: "#CEF6E7" }
											: { backgroundColor: "#C4C5CC" }
									}
								>
									{etiqueta.ETIQUETA_NOME}
								</Tag>
							))}
						</ContainerWrap>
					</ContainerOptions>
					<ButtonSave active={true} onClick={atribuir}>
						{current_language?.atribuir}
					</ButtonSave>
				</Container>
			</Fade>
		</Modal>
	);
};

export const ContainerWrap = styled.form`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	overflow-x: hidden;
	overflow-y: scroll;

	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

export default ModalEtiqueta;
