import { PacientesProps } from "../../interfaces/pacientes.interface";

export const Types = {
	SET_SELECTED_PATIENTS: "SET_SELECTED_PATIENTS",
	RESET_PATIENT: "RESET_PATIENT",
	SET_SELECTED_AVALIADOR: "SET_SELECTED_AVALIADOR",
	RESET_AVAL: "RESET_AVAL",

	SET_PERFIL_ANAMNESE_PATIENT: "SET_PERFIL_ANAMNESE_PATIENT",
	SET_PERFIL_ANAMNESE_ARRAY: "SET_PERFIL_ANAMNESE_ARRAY",

	SET_QUESTIONARIO: "SET_QUESTIONARIO",
	SET_QUESTIONARIO_ASYNC: "SET_QUESTIONARIO_ASYNC",
	SET_QUESTIONARIO_ASYNC_TOKEN: "SET_QUESTIONARIO_ASYNC_TOKEN",
	SET_QUESTIONARIO_ARRAY: "SET_QUESTIONARIO_ARRAY",
	SET_QUESTIONARIO_REQUEST: "SET_QUESTIONARIO_REQUEST",

	SET_ARRAY_CHECKBOX: "SET_ARRAY_CHECKBOX",

	SET_ARRAY_RESPOSTAS: "SET_ARRAY_RESPOSTAS",
	SET_ARRAY_RESPOSTA: "SET_ARRAY_RESPOSTA",
	SET_QUESTIONARIO_RESPOSTA: "SET_QUESTIONARIO_RESPOSTA",
	SET_QUESTIONARIO_RESPOSTA_CONCLUIDO: "SET_QUESTIONARIO_RESPOSTA_CONCLUIDO",
	SET_ID_RESPOSTA: "SET_ID_RESPOSTA",

	SET_TIPO_ANAMNESE: "SET_TIPO_ANAMNESE",
	RESET_QUESTIONARIO: "RESET_QUESTIONARIO",

	ARRAY_MARCADORES: "ARRAY_MARCADORES",

	SET_CONTINUAR_QUESTIONARIO: "SET_CONTINUAR_QUESTIONARIO",
	SET_USU_CONTINUAR_QUESTIONARIO: "SET_USU_CONTINUAR_QUESTIONARIO",
	SET_GERAR_NOVO: "SET_GERAR_NOVO",

	SET_FOTOS_INPUT: "SET_FOTOS_INPUT",
	SET_CHECKBOX_SF_36: "SET_CHECKBOX_SF_36",
};

const INITIAL_STATE = {
	selected_patients: [],
	selected_aval: null,

	perfil_anamnese_patient: "",
	perfil_anamnese_array: [],

	questionario: "",
	questionario_async: false,
	questionario_async_token: "",
	questionario_array: [],
	questionario_request: false,

	array_checkbox: [],

	array_respostas: [],
	array_resposta: [],
	questionario_resposta: [],
	questionario_resposta_concluido: [],
	id_resposta: "",

	tipo_anamnese: "",

	array_marcadores: [],

	continuar_questionario: [],
	usu_continuar_questionario: "",
	gerar_novo: false,

	fotos_input: [],
	checkbox_sf_36: false,
};

export default function pac_aval(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.SET_SELECTED_PATIENTS:
			let newLis: any = [];
			const exist: any = state.selected_patients.find(
				(pac: PacientesProps) => pac.ID_USUARIO == action.payload.ID_USUARIO
			);
			if (!!exist) {
				newLis = state.selected_patients.filter(
					(pac: PacientesProps) => pac.ID_USUARIO != action.payload.ID_USUARIO
				);
			} else {
				newLis = [...state.selected_patients, action.payload];
			}
			return {
				...state,
				selected_patients: [...newLis],
			};

		case Types.RESET_PATIENT:
			return {
				...state,
				selected_patients: [],
			};
		case Types.RESET_AVAL:
			return {
				...state,
				selected_aval: null,
			};
		case Types.SET_SELECTED_AVALIADOR:
			return {
				...state,
				selected_aval: action.payload,
			};
		case Types.SET_PERFIL_ANAMNESE_PATIENT:
			return {
				...state,
				perfil_anamnese_patient: action.payload,
			};
		case Types.SET_PERFIL_ANAMNESE_ARRAY:
			return {
				...state,
				perfil_anamnese_array: action.payload,
			};
		case Types.SET_QUESTIONARIO:
			return {
				...state,
				questionario: action.payload,
			};
		case Types.SET_QUESTIONARIO_ASYNC:
			return {
				...state,
				questionario_async: action.payload,
			};
		case Types.SET_QUESTIONARIO_ASYNC_TOKEN:
			return {
				...state,
				questionario_async_token: action.payload,
			};
		case Types.SET_QUESTIONARIO_ARRAY:
			return {
				...state,
				questionario_array: action.payload,
			};
		case Types.SET_QUESTIONARIO_REQUEST:
			return {
				...state,
				questionario_request: action.payload,
			};
		case Types.SET_ARRAY_CHECKBOX:
			return {
				...state,
				array_checkbox: action.payload,
			};
		case Types.SET_ARRAY_RESPOSTAS:
			return {
				...state,
				array_respostas: action.payload,
			};
		case Types.SET_ARRAY_RESPOSTA:
			return {
				...state,
				array_resposta: action.payload,
			};
		case Types.SET_QUESTIONARIO_RESPOSTA:
			return {
				...state,
				questionario_resposta: action.payload,
			};
		case Types.SET_QUESTIONARIO_RESPOSTA_CONCLUIDO:
			return {
				...state,
				questionario_resposta_concluido: action.payload,
			};
		case Types.SET_ID_RESPOSTA:
			return {
				...state,
				id_resposta: action.payload,
			};
		case Types.SET_TIPO_ANAMNESE:
			return {
				...state,
				tipo_anamnese: action.payload,
			};
		case Types.RESET_QUESTIONARIO:
			return {
				...state,
				questionario_array: [],
				questionario_request: false,

				array_checkbox: [],

				array_respostas: [],
				array_resposta: [],
				questionario_resposta: [],
				questionario_resposta_concluido: [],
				id_resposta: "",

				tipo_anamnese: "",

				array_marcadores: [],

				continuar_questionario: [],
				usu_continuar_questionario: "",
				gerar_novo: false,

				fotos_input: [],
			};
		case Types.ARRAY_MARCADORES:
			return {
				...state,
				array_marcadores: action.payload,
			};
		case Types.SET_CONTINUAR_QUESTIONARIO:
			return {
				...state,
				continuar_questionario: action.payload,
			};
		case Types.SET_USU_CONTINUAR_QUESTIONARIO:
			return {
				...state,
				usu_continuar_questionario: action.payload,
			};
		case Types.SET_GERAR_NOVO:
			return {
				...state,
				gerar_novo: action.payload,
			};
		case Types.SET_FOTOS_INPUT:
			return {
				...state,
				fotos_input: action.payload,
			};
		case Types.SET_CHECKBOX_SF_36:
			return {
				...state,
				checkbox_sf_36: action.payload,
			};
		default:
			return state;
	}
}

export const PacAvalActions = {
	set_add_selected_patient: (patient: any) => ({
		type: Types.SET_SELECTED_PATIENTS,
		payload: patient,
	}),

	set_add_selected_aval: (aval: any) => ({
		type: Types.SET_SELECTED_AVALIADOR,
		payload: aval,
	}),

	reset_patient: () => ({
		type: Types.RESET_PATIENT,
	}),

	reset_aval: () => ({
		type: Types.RESET_AVAL,
	}),

	set_perfil_anamnese_patient: (patient: any) => ({
		type: Types.SET_PERFIL_ANAMNESE_PATIENT,
		payload: patient,
	}),

	set_perfil_anamnese_array: (array: any) => ({
		type: Types.SET_PERFIL_ANAMNESE_ARRAY,
		payload: array,
	}),

	set_questionario: (questionario: any) => ({
		type: Types.SET_QUESTIONARIO,
		payload: questionario,
	}),
	set_questionario_async: (questionarioAsync: any) => ({
		type: Types.SET_QUESTIONARIO_ASYNC,
		payload: questionarioAsync,
	}),
	set_questionario_async_token: (questionarioAsyncToken: any) => ({
		type: Types.SET_QUESTIONARIO_ASYNC_TOKEN,
		payload: questionarioAsyncToken,
	}),
	set_questionario_array: (array: any) => ({
		type: Types.SET_QUESTIONARIO_ARRAY,
		payload: array,
	}),
	set_questionario_request: (request: any) => ({
		type: Types.SET_QUESTIONARIO_REQUEST,
		payload: request,
	}),
	set_array_checkbox: (array: any) => ({
		type: Types.SET_ARRAY_CHECKBOX,
		payload: array,
	}),
	set_array_respostas: (array: any) => ({
		type: Types.SET_ARRAY_RESPOSTAS,
		payload: array,
	}),
	set_array_resposta: (array: any) => ({
		type: Types.SET_ARRAY_RESPOSTA,
		payload: array,
	}),
	set_questionario_respostas: (resposta: any) => ({
		type: Types.SET_QUESTIONARIO_RESPOSTA,
		payload: resposta,
	}),
	set_questionario_respostas_concluido: (resposta: any) => ({
		type: Types.SET_QUESTIONARIO_RESPOSTA_CONCLUIDO,
		payload: resposta,
	}),
	set_id_resposta: (id: any) => ({
		type: Types.SET_ID_RESPOSTA,
		payload: id,
	}),
	set_tipo_anamnese: (tipo: any) => ({
		type: Types.SET_TIPO_ANAMNESE,
		payload: tipo,
	}),
	set_reset_questionario: () => ({
		type: Types.RESET_QUESTIONARIO,
	}),
	set_array_marcadores: (array: any) => ({
		type: Types.ARRAY_MARCADORES,
		payload: array,
	}),
	set_continuar_questionario: (array: any) => ({
		type: Types.SET_CONTINUAR_QUESTIONARIO,
		payload: array,
	}),
	set_usu_continuar_questionario: (id: any) => ({
		type: Types.SET_USU_CONTINUAR_QUESTIONARIO,
		payload: id,
	}),
	set_gerar_novo: (status: any) => ({
		type: Types.SET_GERAR_NOVO,
		payload: status,
	}),
	set_foto_input: (status: any) => ({
		type: Types.SET_FOTOS_INPUT,
		payload: status,
	}),
	set_checkbox_sf_36: (status: any) => ({
		type: Types.SET_CHECKBOX_SF_36,
		payload: status,
	}),
};
