import React from "react";
import { useSelector } from "react-redux";

import Mais from "../../../assets/icons/+.svg";
import DinoTriste from "../../../assets/icons/dinoTriste.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AssimetriaPros } from '../../../interfaces/assimetria.interface';
import history from "../../../routes/history";
import { TypesBtnRelatorio } from '../../../utils/types';
import { Container } from "./styles";

const NenhumRelatorio = ({ anamnese = false, tipo }: any) => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const anamneses: any = useSelector(
		(state: any) => state.dashboard.anamneses
	);
	const setTitleUpgrade: boolean = useSelector(
		(status: any)=> status.configs.set_title_modal_upgrade
	)

	const navigationUpgrade = ()=> {
		history.push("/upgrade")
	}
	return (
		<Container id="DivPrincipal">
			<div className="circulo">
				<img src={DinoTriste} alt="" />
			</div>
			<h1 className="texto">
				{anamnese
					?
					<div>
						<p>{current_language?.nao_ha_dados}</p>
						<p className='subtitle'>{current_language?.veja_oque_falta}</p>
						{
							tipo === TypesBtnRelatorio.BTN_MULTIFATORIAL ?
								<div style={{marginTop: '2rem'}}>
									{
										anamneses[0].relatorio_multifatorial.MOTIVO_NAO_GERAR.map((e: any, i: number) => {
											return (
												<div key={i} className="aviso">
													<p>
														{e.TITULO}
													</p>
													{
														e.VALOR === "N" ?
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M23.6406 2.70312L14.3438 12L23.6406 21.2969L21.2969 23.6406L12 14.3438L2.70312 23.6406L0.359375 21.2969L9.65625 12L0.359375 2.70312L2.70312 0.359375L12 9.65625L21.2969 0.359375L23.6406 2.70312Z" fill="#000E4B" />
															</svg>
															:
															<svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M10 17.9531L27.6562 0.296875L30 2.64062L10 22.6406L0.703125 13.3438L3.04688 11L10 17.9531Z" fill="#71CA81" />
															</svg>
													}
												</div>
											)
										})
									}
								</div>
								:
								tipo === TypesBtnRelatorio.BTN_FISIOTERAPEUTICO && 
								<div style={{marginTop: '2rem'}}>
									{
										anamneses[0].relatorio_fisioterapeutico.MOTIVO_NAO_GERAR.map((e: any, i: number) => {
											return (
												<div key={i} className="aviso">
													<p>
														{e.TITULO}
													</p>
													{
														e.VALOR === "N" ?
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M23.6406 2.70312L14.3438 12L23.6406 21.2969L21.2969 23.6406L12 14.3438L2.70312 23.6406L0.359375 21.2969L9.65625 12L0.359375 2.70312L2.70312 0.359375L12 9.65625L21.2969 0.359375L23.6406 2.70312Z" fill="#000E4B" />
															</svg>
															:
															<svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M10 17.9531L27.6562 0.296875L30 2.64062L10 22.6406L0.703125 13.3438L3.04688 11L10 17.9531Z" fill="#71CA81" />
															</svg>
													}
												</div>
											)
										})
									}
								</div>
						}

					</div>
					: setTitleUpgrade 
					 ? 
						<div className="upgrade" >
							<p>{current_language?.modal_upgrade_funcionalidade_indisponivel}</p>
							<p>{current_language?.modal_upgrade_funcionalidade_indisponivel2}</p>
							<button className="buttonUpgrade" onClick={navigationUpgrade}>{current_language?.menu_lateral_upgrade}</button> 	
						</div> 
					 : current_language?.sem_dados_suficientes_relatorio}
			</h1>
		</Container>
	);
};

export default NenhumRelatorio;
