import {
	Backdrop,
	Fade,
	Modal,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../store/ducks/app";
import buttonClose from "../../assets/icons/buttonCloseBlack.svg";
import Mail from "../../assets/icons/mail.svg";
import MailOpen from "../../assets/icons/mail_open.svg";

import { Icon } from "../../styles/global";
import styled from "styled-components";
import { PacAvalActions } from "../../store/ducks/manage_pac_aval";
import { AddPacAvalActions } from "../../store/ducks/add_pac_aval";
import { push } from "connected-react-router";
import { PacientesProps } from "../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "../../utils/types";
import { TextsProps } from "../../database/txt.interface";
import history from "../../routes/history";
import { persistStorage } from "../../utils/base_async_storage";
import { AmplitudeLog } from "../../utils/amplitude";
import {
	Close,
	Container,
	ContainerOptions,
	Title,
} from "../../styles/styles_modais_padroes";
import { AnamneseActions } from "../../store/ducks/anamnese";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

interface NotificationProps {
	ID_USUARIO: number;
	NOTIFICACAO_DESCRICAO: string;
	NOTIFICACAO_ONCLICK: string;
	NOTIFICACAO_TIPO: string;
	NOTIFICACAO_TITULO: string;
	NOTIFICACAO_CRIADO: string;
	IS_NEW: boolean;
}

const ModalNotificacoes: React.FC = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const is_open_notificacoes = useSelector(
		(state: any) => state.app.is_open_notificacoes
	);

	const notificacoes: Array<any> = useSelector(
		(state: any) => state.dashboard.notificacoes
	);

	const pacientes: Array<PacientesProps> = useSelector(
		(state: any) => state.dashboard.pacientes_notification
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const notification_view: Array<NotificationProps> = useSelector(
		(state: any) => state.app.notification_view
	);

	const ModalClose = () => {
		dispatch(AppActions.set_modal_notificacoes(false));
	};

	const handleAdicionarMetodoPagamento = () => {
		dispatch(AppActions.set_open_adicionar_metodo_pagamento(true));
		history.push("metodo_pagamento");
		ModalClose();
	};

	const handleNotificacoes = (notifi: any) => {
		if (notifi?.NOTIFICACAO_ONCLICK?.indexOf("http") >= 0)
			window.open(notifi?.NOTIFICACAO_ONCLICK, "_blank");
		if (notifi?.NOTIFICACAO_ONCLICK === "cadastro_cartao")
			handleAdicionarMetodoPagamento();
		if (notifi?.NOTIFICACAO_ONCLICK === "perfil_paciente") {
			const payload = {
				ID_USUARIO: notifi?.ID_USUARIO,
				txDataHora: moment().format("YYYY-MM-DD HH:mm:ss").toString(),
			};

			const paciente = pacientes.filter(
				(item: PacientesProps) => item?.ID_USUARIO == notifi?.ID_USUARIO
			);

			if (paciente.length > 0) {
				const obj = {
					name: paciente[0].USUARIO_NOME,
					value: "paciente",
				};
				dispatch(AppActions.set_open_barraSuperior(obj));
				dispatch(AppActions.set_open_paciente(paciente[0]));
				dispatch(
					PacAvalActions.set_perfil_anamnese_patient(notifi?.ID_USUARIO)
				);
				dispatch(AnamneseActions.set_relatorio_qualidade_vida_history(null));
				dispatch(AddPacAvalActions.set_recorrente(payload));
				dispatch(push("/paciente"));
				ModalClose();
			} else {
				dispatch(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: current_language?.notifi_paci_excluido,
					})
				);
			}
		}
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open_notificacoes}
			onClose={ModalClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open_notificacoes}>
				<Container>
					<Close
						className="close"
						src={buttonClose}
						onClick={ModalClose}
						alt="Close"
					/>
					<Title
						style={{
							marginBottom: "2rem",
						}}
					>
						Notificações
					</Title>
					<ContainerOptions>
						{notificacoes.map((notif: NotificationProps, index: number) => {
							return (
								<NotificationBox
									onClick={() => {
										async function storage(item: NotificationProps) {
											await persistStorage({
												tag: "notification_view",
												res: [...notification_view, item],
											});
										}
										if (
											!!!notification_view.find(
												(x: NotificationProps) =>
													x === notificacoes[index].NOTIFICACAO_CRIADO
											)
										) {
											dispatch(
												AppActions.set_notification_view(
													notificacoes[index].NOTIFICACAO_CRIADO
												)
											);
											storage(notificacoes[index].NOTIFICACAO_CRIADO);
										}
										AmplitudeLog("notificacao_acessada");
										handleNotificacoes(notif);
									}}
									key={index}
								>
									<IconAndDate>
										{notification_view.find(
											(x: NotificationProps) =>
												x === notificacoes[index].NOTIFICACAO_CRIADO
										) ? (
											<Icon
												src={MailOpen}
												style={{
													width: "1.5rem",
													height: "1.5rem",
													marginRight: "0.5rem",
												}}
											/>
										) : (
											<Icon
												src={Mail}
												style={{
													width: "1.5rem",
													height: "1.5rem",
													marginRight: "0.5rem",
												}}
											/>
										)}
										<Date>{notif.NOTIFICACAO_CRIADO}</Date>
									</IconAndDate>
									<TitleNoti>{notif.NOTIFICACAO_TITULO}</TitleNoti>
									<ContentNoti>{notif.NOTIFICACAO_DESCRICAO}</ContentNoti>
								</NotificationBox>
							);
						})}
					</ContainerOptions>
				</Container>
			</Fade>
		</Modal>
	);
};

export const NotificationBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 1.5rem;
`;

export const IconAndDate = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const Date = styled.text`
	font-family: "NotoSans-Regular";
	font-weight: 400;
	line-height: 19.2px;
	font-size: 1rem;
	color: #797d8d;
`;

export const TitleNoti = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 24px;
	color: #3d4259;
	margin-left: 2rem;
`;

export const ContentNoti = styled.text`
	font-family: "NotoSans-Regular";
	font-weight: 400;
	line-height: 19.2px;
	font-size: 1rem;
	color: #797d8d;
	margin-left: 2rem;
	cursor: pointer;
`;

export default ModalNotificacoes;
