import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ProtocolosProps } from "../../../interfaces/protocolo.interface";
import { Icon } from "../../../styles/global";
import arrow_forward from "../../../assets/icons/arrow_forward.svg";
import { TypesLanguage } from "../../../utils/types";
import { TextsProps } from "../../../database/txt.interface";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { AppActions } from "../../../store/ducks/app";
const ListagemProtocolos: React.FC = () => {
	const dispatch = useDispatch();

	const protocol_search = useSelector(
		(state: any) => state.dashboard.search_protocol
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const language = useSelector((state: any) => state.configs.language);

	const categorias_protocolo = useSelector(
		(state: any) => state.dashboard.categorias_protocolos
	);

	const handleCategoriaTitulo = (protocolo: ProtocolosProps) => {
		const categoria = categorias_protocolo?.categorias_protocolo?.filter(
			(item: any) =>
				item?.ID_CATEGORIA_PROTOCOLO == protocolo?.ID_CATEGORIA_PROTOCOLO
		);

		return language == TypesLanguage.PT
			? categoria[0]?.CATEGORIA_TITULO_PT
			: language == TypesLanguage.EN
			? categoria[0]?.CATEGORIA_TITULO_EN
			: categoria[0]?.CATEGORIA_TITULO_ES;
	};

	const OpenProtocol = (protocolo: ProtocolosProps) => {
		dispatch(DashBoardActions.set_current_page("exercises_in_protocol"));
		dispatch(AppActions.set_protocolo_list(protocolo));
	};

	function _RenderProtocolList(protocol_list: Array<ProtocolosProps>) {
		return protocol_list.map((protocolo: ProtocolosProps, index) => (
			<ProtocoloSection key={index} onClick={() => OpenProtocol(protocolo)}>
				<DivNameAndArrow>
					<Name>{protocolo?.PROTOCOLO_TITULO_PT}</Name>
					<Icon
						style={{ width: "1.5rem", height: "1.5rem" }}
						src={arrow_forward}
					/>
				</DivNameAndArrow>
				{protocolo?.PROTOCOLO_TEMPO_ESTIMADO && (
					<TempoEstimado>
						{current_language?.tempo_estimado}{" "}
						{protocolo?.PROTOCOLO_TEMPO_ESTIMADO}
					</TempoEstimado>
				)}
				{categorias_protocolo?.categorias_protocolo && (
					<Tag>{handleCategoriaTitulo(protocolo)}</Tag>
				)}
			</ProtocoloSection>
		));
	}

	return (
		<Container>
			<ListContainer>
				{protocol_search.length == 0 ? (
					<text>{current_language?.carregando_protocolos}...</text>
				) : (
					_RenderProtocolList(protocol_search)
				)}
			</ListContainer>
		</Container>
	);
};

const Container = styled.div`
	width: 60%;
	margin-top: 2vh;
	height: 100%;
`;

const ListContainer = styled.div`
	width: 100%;
	height: 65vh;
	margin-bottom: 1.5rem;
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const ProtocoloSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	border-radius: 12px;
	margin-bottom: 1rem;
	padding: 1rem;
	justify-content: space-between;
	background-color: ${(props) => props.color || "transparent"};
	cursor: pointer;
	border: 2px solid rgba(222, 223, 227, 1);
`;

const DivNameAndArrow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const Name = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1rem;
	font-weight: 500;
	text-align: left;
	color: #323d6e;
`;

const TempoEstimado = styled.div`
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 27px;
	text-align: left;
	color: #797d8d;
`;

const Tag = styled.div`
	width: 10rem;
	margin-top: 0.3rem;
	border-radius: 30px;
	font-size: 0.7rem;
	cursor: pointer;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #000e4b;
	font-family: "NotoSans-Regular";
	font-weight: 600;
	text-align: center;
	background-color: #cef6e7;
`;

export default ListagemProtocolos;
