import React from 'react';
import { ContainerOption, OptionPopup, PopupContainer } from './styles';
import { PopupComponentProps } from './Models';

interface requeredProps {
    visible: boolean;
    options: Array<{
        title: string;
        action: ()=> void;
    }>
    onPress?: ()=> void;
};

const Popup: React.FC<PopupComponentProps & requeredProps> = ({
    visible = false,
    options,
    width,
    height,
    top,
    right,
    borderRadius,
    zIndex,
    onPress,
})=> {


    return (
        <PopupContainer
            visible={visible}
            width={width}
            zIndex={zIndex}
            height={height}
            top={top}
            right={right}
            borderRadius={borderRadius}
        >
            {
                options?.map((item, index)=>(
                    <ContainerOption 
                        key={index}
                        onClick={()=>{ item?.action(), onPress?.()}}
                    >
                        <OptionPopup>
                            {item?.title}
                        </OptionPopup>
                    </ContainerOption>
                ))
            }
        </PopupContainer>
    )
}

export default Popup;