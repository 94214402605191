import styled from "styled-components";

export const Conteiner = styled.div<any>`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const ContentTitle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 18px;
	width: 98%;
`;

export const Title = styled.h1`
	font-size: 1.7rem;
	font-weight: bold;
	color: #404040;
`;

export const SubTitle = styled.h3`
	font-size: 1rem;
	padding-top: 6px;
	font-weight: normal;
	color: #404040;
`;

export const DinoImg = styled.img`
	width: 20%;
	min-width: 6rem;
	margin-right: 15px;
`;
