import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextsProps } from "../../database/txt.interface";
import { UserProps } from "../../interfaces/pacientes.interface";
import { ProtocolosProps } from "../../interfaces/protocolo.interface";
import { AppActions } from "../../store/ducks/app";
import { AvaliationActions } from "../../store/ducks/avaliation";
import { ManageProtocolActions } from "../../store/ducks/manage_protocols";
import {
	TypesAvalOrFunci,
	TypesErrorModal,
	TypesProLivre,
} from "../../utils/types";
import Descanso from "./descanso";
import GraficResult from "./graficResult";
import { Body } from "./styles";
import { AmplitudeLog } from "../../utils/amplitude";
import { getEvaluatedPatients } from "../../utils/funcs";
import styled from "styled-components";
import { P1Desk } from "../../styles/styles_text";
import TimerRelogio from "./timer";
import { ButtonSave } from "../../styles/global";
import { DashBoardActions } from "../../store/ducks/dashboard";

export interface ExerProtoPros {
	title: string;
	value: string;
	grupo?: any;
	exercice_id: any;
	id_protocolo_exer: any;
	name: any;
}

const ResultadoExecucao: React.FC = () => {
	const dispatch = useDispatch();

	const paciente = useSelector((state: any) => state.app.is_open_paciente);

	const current_exercise: ExerProtoPros = useSelector(
		(state: any) => state.avaliation.current_exercise
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const exercicio = useSelector((state: any) => state.app.exercicio);

	const pico_result = useSelector((state: any) => state.avaliation.pico_result);

	const [picoResult, setPicoResult] = React.useState<any>(0);

	const current_repeticoes = useSelector(
		(state: any) => state.avaliation.current_repeticoes
	);

	const tempo_contracao = useSelector(
		(state: any) => state.configs.tempo_contracao
	);

	const protocolo_list: ProtocolosProps = useSelector(
		(state: any) => state.app.protocolo_list
	);

	const is_selected_avaliacao = useSelector(
		(state: any) => state.app.is_selected_avaliacao
	);

	const force_option_min = useSelector(
		(state: any) => state.avaliation.force_option_min
	);

	const force_option_max = useSelector(
		(state: any) => state.avaliation.force_option_max
	);

	const max_force = useSelector((state: any) => state.avaliation.max_force);

	const slider_execucao = useSelector(
		(state: any) => state.app.slider_execucao
	);

	const values_result = useSelector(
		(state: any) => state.avaliation.values_result
	);
	const result_repe = useSelector((state: any) => state.avaliation.result);

	const is_selected_type_pro_livre = useSelector(
		(state: any) => state.app.is_selected_type_pro_livre
	);

	const lado_exercicio = useSelector((state: any) => state.app.lado_exercicio);

	const time_final = useSelector((state: any) => state.avaliation.time_final);

	const valorContador = tempo_contracao;
	const [contador, setContador] = React.useState<any>(valorContador);

	let resultMediaPico = 0;
	pico_result.map((i: any) => {
		if (i.valor > picoResult) {
			setPicoResult(i.valor);
		}
		resultMediaPico += i.valor;
	});
	resultMediaPico = resultMediaPico / pico_result.length;

	const dinamo_uuid: string = useSelector(
		(state: any) => state.configs.dinamo_uuid
	);

	const enviar = (e: any) => {
		const dat_request = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			ID_USUARIO: paciente?.ID_USUARIO,
			ID_PROTOCOLO:
				is_selected_type_pro_livre == TypesProLivre.LIVRE
					? 0
					: protocolo_list?.ID_PROTOCOLO,
			ID_EXERCICIO:
				is_selected_type_pro_livre == TypesProLivre.LIVRE
					? current_exercise?.exercice_id
					: current_exercise?.exercice_id,
			EXECUCAO_MODO:
				is_selected_type_pro_livre == TypesProLivre.LIVRE
					? lado_exercicio == "Sem Definicao"
						? "S"
						: lado_exercicio.substr(0, 1)
					: current_exercise?.value,
			EXECUCAO_TIPO: is_selected_avaliacao == "AVALIACAO" ? "a" : "f",
			EXECUCAO_PESOMIN: parseFloat(force_option_min).toFixed(1).toString(),
			EXECUCAO_PESOMAX: max_force
				? parseFloat(force_option_max).toFixed(1).toString()
				: 0,
			EXECUCAO_RM: slider_execucao,
			EXECUCAO_DADOS: values_result,
			EXECUCAO_ATRIBUIR_REPS: result_repe,
			EXECUCAO_TEMPO: time_final,
			EXECUCAO_DINAMO_UUID_APPLE: "0",
			//EXECUCAO_DINAMO_MAC: dinamo_mac_address ? dinamo_mac_address : " ",
			EXECUCAO_DINAMO_UUID: dinamo_uuid ? dinamo_uuid : " ",
			EXECUCAO_EXECUTADA: moment(new Date())
				.format("YYYY-MM-DD HH:mm:ss")
				.toString(),
			EXECUCAO_EXCLUIDA: e,
		};
		dispatch(AvaliationActions.add_execucao_request(dat_request));
	};
	const online = navigator.onLine;

	const CompleteExercises = (id: any) => {
		if (is_selected_type_pro_livre == TypesProLivre.LIVRE) {
			AmplitudeLog("concluir_exercicio", { body: id?.ID_EXERCICIO });
			getEvaluatedPatients(paciente);
		}
		if (is_selected_type_pro_livre == TypesProLivre.PROTOCOLO) {
			AmplitudeLog("concluir_protocolo", { body: id });
		}
		is_selected_type_pro_livre == TypesProLivre.LIVRE
			? dispatch(AvaliationActions.set_progress_exers_proto(id?.ID_EXERCICIO))
			: current_repeticoes > 0 &&
			  dispatch(AvaliationActions.set_progress_exers_proto(id));
		dispatch(AppActions.set_resultado_execucao(false));
		setContador(valorContador);
		if (is_selected_type_pro_livre == TypesProLivre.PROTOCOLO) {
			dispatch(ManageProtocolActions.set_button_goback(true));
		} else {
			dispatch(ManageProtocolActions.set_button_goback(false));
		}
		if (online) {
			dispatch(AvaliationActions.set_toast_after_completing(true));
		}
		current_repeticoes > 0
			? enviar("N")
			: toogle(current_language?.zero_repeticoes);
		dispatch(AvaliationActions.reset2());
		dispatch(AvaliationActions.reset());
		handleNavigation();
		is_selected_type_pro_livre == TypesProLivre.LIVRE &&
			dispatch(DashBoardActions.set_current_page("exercises_in_patient"));
	};

	const handleNavigation = () => {
		if (online) {
			dispatch(AppActions.set_checklist_execucao(true));
		} else {
			dispatch(AppActions.set_resultado_execucao(true));
		}
	};

	const avaliationInit = (exerc: any) => {
		dispatch(AvaliationActions.set_current_exercicise(exerc));
		if (is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO) {
			dispatch(AvaliationActions.set_in_execution_force_max_status(false));
		}
		dispatch(AppActions.set_checklist_execucao(false));
		dispatch(AppActions.set_resultado_execucao(false));
		setContador(valorContador);
		current_repeticoes > 0 && enviar("S");
		dispatch(AvaliationActions.reset2());
		dispatch(AvaliationActions.reset());
	};

	const toogle = (msg: string) => {
		if (online) {
			dispatch(
				AppActions.set_modal_visible({
					type: TypesErrorModal.FAIL,
					message: msg,
				})
			);
		} else {
			console.log(current_language?.erro_video);
		}
	};

	return (
		<>
			<Body>
				<GraficResult />

				<div className="legendaResultado">
					<TimersContainer>
						<Option>
							<P1Desk>{current_language?.config_aval_repeticoes}</P1Desk>
							<P1Desk>{current_repeticoes}</P1Desk>
						</Option>
						<Option>
							<P1Desk>{current_language?.execucao_force_media}</P1Desk>
							<P1Desk>
								{" "}
								{isNaN(resultMediaPico)
									? "0.00"
									: resultMediaPico.toFixed(2)}{" "}
								Kg
							</P1Desk>
						</Option>
						<Option>
							<P1Desk>{current_language?.execucao_force_max}</P1Desk>
							<P1Desk>{parseFloat(picoResult).toFixed(2)} Kg</P1Desk>
						</Option>
						<Option>
							<P1Desk>{current_language?.cronometro}</P1Desk>
							<P1Desk>
								<TimerRelogio />
							</P1Desk>
						</Option>
						<Option>
							<P1Desk>{current_language?.descanso}</P1Desk>
							<Descanso />
						</Option>
					</TimersContainer>
				</div>
				{is_selected_type_pro_livre == TypesProLivre.LIVRE ? (
					<ButtonSave
						active={true}
						className="botaoConcluir"
						onClick={() => CompleteExercises(exercicio)}
					>
						{current_language?.concluir}
					</ButtonSave>
				) : (
					<ButtonSave
						active={true}
						onClick={() => CompleteExercises(current_exercise)}
					>
						{current_language?.concluir}
					</ButtonSave>
				)}
				<ButtonSave
					active={true}
					style={{ backgroundColor: "transparent", color: "#000E4B" }}
					onClick={() => {
						avaliationInit(current_exercise);
						AmplitudeLog(
							is_selected_type_pro_livre == TypesProLivre.LIVRE
								? "descartar_execucao_exercicio"
								: "descartar_execucao_protocolo"
						);
					}}
				>
					Refazer
				</ButtonSave>
			</Body>
		</>
	);
};

const TimersContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 2rem;
	margin-bottom: 2rem;
`;

const Option = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 1.5rem;
	margin-left: 1.5rem;
`;

export default ResultadoExecucao;
