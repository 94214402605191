import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ProtocolosProps } from "../../../interfaces/protocolo.interface";
import { Icon } from "../../../styles/global";
import arrow_forward from "../../../assets/icons/arrow_forward.svg";
import { TypesLanguage, TypesProLivre } from "../../../utils/types";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { PacientesProps } from "../../../interfaces/pacientes.interface";

const ListagemProtocolosInPaciente: React.FC = () => {
	const dispatch = useDispatch();

	const protocol_search = useSelector(
		(state: any) => state.dashboard.search_protocol
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const language = useSelector((state: any) => state.configs.language);

	const categorias_protocolo = useSelector(
		(state: any) => state.dashboard.categorias_protocolos
	);
	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const pacientes = useSelector((state: any) => state.dashboard.pacientes);
	const patients_most_accessed = useSelector(
		(state: any) => state.dashboard.pacientes_mais_visitados.LISTA
	);
	const pacientes_loading = useSelector(
		(state: any) => state.dashboard.pacientes_loading
	);
	const categorias_atribuidas: number[] = useSelector(
		(state: any) => state.dashboard.categorias_atribuidas
	);
	const [lista_protocolos, set_lista_protocolos] = useState(protocol_search);
	const [current_patient, set_current_patient]: any = useState(null);

	useEffect(() => {
		set_lista_protocolos(
			protocol_search.filter((objeto: ProtocolosProps) =>
				categorias_atribuidas.includes(objeto.ID_CATEGORIA_PROTOCOLO)
			)
		);
	}, [protocol_search, categorias_atribuidas]);

	const handleCategoriaTitulo = (protocolo: ProtocolosProps) => {
		const categoria = categorias_protocolo?.categorias_protocolo?.filter(
			(item: any) =>
				item?.ID_CATEGORIA_PROTOCOLO == protocolo?.ID_CATEGORIA_PROTOCOLO
		);

		return language == TypesLanguage.PT
			? categoria[0]?.CATEGORIA_TITULO_PT
			: language == TypesLanguage.EN
			? categoria[0]?.CATEGORIA_TITULO_EN
			: categoria[0]?.CATEGORIA_TITULO_ES;
	};

	const GetPatientInOrigin = () => {
		const patient_is_on_the_main_list = pacientes.filter(
			(item: PacientesProps) => item?.ID_USUARIO == patient?.ID_USUARIO
		);
		let patient_is_on_the_most_accessed_list = undefined;
		if(patients_most_accessed){
			patient_is_on_the_most_accessed_list = patients_most_accessed.filter(
				(item: PacientesProps) => item?.ID_USUARIO == patient?.ID_USUARIO
			);
		}
		return patient_is_on_the_main_list[0] != undefined
			? patient_is_on_the_main_list[0]
			: patient_is_on_the_most_accessed_list[0];
	}

	useEffect(() => {
		set_current_patient(GetPatientInOrigin);
	}, [pacientes_loading, pacientes, patients_most_accessed, current_patient]);


	const OpenProtocol = (protocolo: ProtocolosProps) => {
		let hasHardwareType2 = false
		protocolo.PROTOCOLO_EXERCICIO.map((exercicio) => {
			if (exercicio.IDS_HARDWARES[0] === "2") hasHardwareType2 = true;
		})

		if(!!hasHardwareType2 && !current_patient.USUARIO_LADO_DOMINANTE) {
			dispatch(AppActions.set_modal_select_dominant_side(true))
			return;
		};

		if((!!hasHardwareType2 && !!current_patient.USUARIO_LADO_DOMINANTE) || !hasHardwareType2) {
			dispatch(AppActions.set_selected_type_pro_livre(TypesProLivre.PROTOCOLO));
			dispatch(AppActions.set_modal_select_avaliacao(true));
			dispatch(AppActions.set_protocolo_list(protocolo));
		}
	};

	function _RenderProtocolList(protocol_list: Array<ProtocolosProps>) {
		return protocol_list.map((protocolo: ProtocolosProps, index) => (
			<ProtocoloSection key={index} onClick={() => OpenProtocol(protocolo)}>
				<DivNameAndArrow>
					<Name>{protocolo?.PROTOCOLO_TITULO_PT}</Name>
					<Icon
						style={{ width: "1.5rem", height: "1.5rem" }}
						src={arrow_forward}
					/>
				</DivNameAndArrow>
				{protocolo?.PROTOCOLO_TEMPO_ESTIMADO && (
					<TempoEstimado>
						{current_language?.tempo_estimado}{" "}
						{protocolo?.PROTOCOLO_TEMPO_ESTIMADO}
					</TempoEstimado>
				)}
				{categorias_protocolo?.categorias_protocolo && (
					<Tag>{handleCategoriaTitulo(protocolo)}</Tag>
				)}
			</ProtocoloSection>
		));
	}

	return (
		<Container>
			<ListContainer>
				{lista_protocolos.length == 0 || pacientes_loading ? (
					<text>{current_language?.carregando_protocolos}...</text>
				) : (
					_RenderProtocolList(lista_protocolos)
				)}
			</ListContainer>
		</Container>
	);
};

const Container = styled.div`
	width: 60%;
	margin-top: 2vh;
	height: 100%;
`;

const ListContainer = styled.div`
	width: 100%;
	height: 65vh;
	margin-bottom: 1.5rem;
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const ProtocoloSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	border-radius: 12px;
	margin-bottom: 1rem;
	padding: 1rem;
	justify-content: space-between;
	background-color: ${(props) => props.color || "transparent"};
	cursor: pointer;
	border: 2px solid rgba(222, 223, 227, 1);
`;

const DivNameAndArrow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const Name = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1rem;
	font-weight: 500;
	text-align: left;
	color: #323d6e;
`;

const TempoEstimado = styled.div`
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 27px;
	text-align: left;
	color: #797d8d;
`;

const Tag = styled.div`
	width: 10rem;
	margin-top: 0.3rem;
	border-radius: 30px;
	font-size: 0.7rem;
	cursor: pointer;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #000e4b;
	font-family: "NotoSans-Regular";
	font-weight: 600;
	text-align: center;
	background-color: #cef6e7;
`;

export default ListagemProtocolosInPaciente;
