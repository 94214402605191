import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";
import DinoBad from "../../../assets/dinoBadAsync.svg";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalBloqueioDino: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const classes = useStyles();
	const is_open_upgrade = useSelector(
		(state: any) => state.app.is_open_modal_bloqueio_dino
	);
	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_bloqueio_dino(false));
		window.history.back();
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_upgrade}
				onClose={ModalCadastroClose}
			>
				<Fade in={is_open_upgrade}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<div className="divText">
							<div>
								<img
									src={DinoBad}
									style={{
										width: 100,
										height: 100
									}}
								/>
							</div>
							<h1 className="title">
								{current_language?.problema_pagamento}
							</h1>
							<p className="text">
								{current_language?.desbloqueio_regularizacao}
							</p>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalBloqueioDino;
