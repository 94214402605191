import styled from "styled-components";

export const Conteiner = styled.div<any>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #d9d9d9;
	border-radius: 5rem;
	height: 2.5rem;
	padding: 0.5rem;
	position: absolute;
	right: 0;
	margin-right: 1rem;
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const FlagLang = styled.img`
	width: 1.8rem;
	height: 1.9rem;
	border-radius: 1.4rem;
	cursor: pointer;
	&:hover {
		border: 2px solid #adadad;
	}
`;
