import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 60vh;
	width: 35vw;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	padding: 2% 0;

	.close {
		width: 1.738rem;

		position: absolute;
		right: 2rem;
		top: 2rem;

		cursor: pointer;
	}

	.alert {
		margin-bottom: 3rem;
		width: 12%;
	}

	.divText {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		width: 80%;
		height: 30%;
	}

	.text {
		color: #404040;
		font-weight: 400;
		font-family: Roboto;
		font-size: 1.3rem;
		text-align: center;
	}

	.text2 {
		color: #909090;
		font-weight: 400;
		font-family: Roboto;
		font-size: 1.1rem;
		text-align: center;
	}

	.text3 {
		color: #909090;
		font-weight: 400;
		font-family: Roboto;
		font-size: 1.6rem;
		text-align: center;
	}

	.num {
		color: ${Colors.primary_blue_k_main};
		font-weight: 400;
		font-family: Roboto;
		font-size: 2rem;
		text-align: center;
	}

	.text4 {
		color: #404040;
		font-weight: 600;
		font-family: Roboto;
		font-size: 1.8rem;
		text-align: center;
	}

	.text5 {
		color: #606060;
		font-weight: 400;
		font-family: Roboto;
		font-size: 1.4rem;
		text-align: center;
	}

	.button {
		font-family: Roboto;
		font-size: 1.3rem;
		font-weight: 600;
		line-height: 1.758rem;

		color: #ffffff;

		width: 35%;
		height: 3.3rem;

		border: 0;
		border-radius: 0.8rem;

		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}

	.upgrade {
		background: ${Colors.primary_blue_k_main};
		transition: all 0.3s ease;
		color: #ffffff;
	}

	.upgrade:hover {
		background: ${Colors.primary_blue_k_dark_2};
		color: #ffffff;
	}
`;
