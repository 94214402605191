import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import {
	TypesAvalOrFunci,
	TypesDivision,
	TypesProLivre,
} from "../../../utils/types";
import { Item } from "../styles";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { P3Desk } from "../../../styles/styles_text";
import Play from "../../../assets/icons/play_arrow.svg";
import { loadStorage } from "../../../utils/base_async_storage";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { ExerciciosActions } from "../../../store/ducks/exer";
import { AmplitudeLog } from "../../../utils/amplitude";
import { push } from "connected-react-router";
import { DashBoardActions } from "../../../store/ducks/dashboard";

interface RenderExerProtoProps {
	item: Exercicios2Props;
	index: number;
	region_selected: string;
}

export interface ExerProtoPros {
	title: string;
	value: string;
	grupo?: any;
	exercice_id: any;
	id_protocolo_exer: any;
	name: any;
}

const RenderExerProto: React.FC<RenderExerProtoProps> = ({ item, index, region_selected }) => {
	const dispatch = useDispatch();

	const [lista, setLista] = useState<any>([]);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);

	function getElementsExers(proto: Exercicios2Props, type: any) {
		const DEB = type == TypesDivision.DEB ? ["D", "E", "B"] : ["S"];

		const arr: any = [];

		DEB.forEach((e: any) => {
			if (DEB.includes(e)) {
				if(proto?.EXERCICIO_TITULO_PT == "Preensão Palmar" && proto?.ID_USUARIO == 0) {
					arr.push({
						title:
							e == "D" && current_language?.hemisferio_direito
							|| e == "E" && current_language?.hemisferio_esquerdo,
						value: e,
						exercice_id: proto?.ID_EXERCICIO,
						name: proto?.EXERCICIO_TITULO_PT,
					});
				} else {
					arr.push({
						title:
							e == "D" && current_language?.hemisferio_direito
								|| e == "E" && current_language?.hemisferio_esquerdo
								|| e == "B" && current_language?.hemisferio_bilateral
								|| current_language?.sem_hemisferio,
						value: e,
						exercice_id: proto?.ID_EXERCICIO,
						name: proto?.EXERCICIO_TITULO_PT,
					});
				}
			}
		});

		const filteredArray = arr.filter((item: any) => item.title !== false);
		return filteredArray;
	}

	useEffect(() => {
		if (item.EXERCICIO_TIPO == 0) {
			setLista([...getElementsExers(item, TypesDivision.DEB)]);
		} else {
			setLista([...getElementsExers(item, TypesDivision.S)]);
		}
	}, [current_language, region_selected]);

	const is_selected_avaliacao = useSelector(
		(state: any) => state.app.is_selected_avaliacao
	);

	const config_funcional_habilitado = useSelector(
		(state: any) => state.configs.config_funcional
	);

	const is_selected_type_pro_livre = useSelector(
		(state: any) => state.app.is_selected_type_pro_livre
	);

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);
	const patients_most_accessed = useSelector(
		(state: any) => state.dashboard.pacientes_mais_visitados.LISTA
	);
	const pacientes_loading = useSelector(
		(state: any) => state.dashboard.pacientes_loading
	);

	const [Evaluatedpatients, setEvaluatedPatients] = useState<number[]>([]);
	const [current_patient, set_current_patient]: any = useState(null);

	async function getEvaluatedPatients() {
		let evaluatedPatients: number[] | null = [];

		evaluatedPatients = await loadStorage("evaluated_patients");

		if (evaluatedPatients != undefined || evaluatedPatients != null) {
			setEvaluatedPatients(evaluatedPatients);
		}
	}

	useEffect(() => {
		getEvaluatedPatients();
	}, [patient]);

	const GetPatientInOrigin = () => {
		const patient_is_on_the_main_list = pacientes.filter(
			(item: PacientesProps) => item?.ID_USUARIO == patient?.ID_USUARIO
		);
		let patient_is_on_the_most_accessed_list = undefined;
		if(patients_most_accessed){
			patient_is_on_the_most_accessed_list = patients_most_accessed.filter(
				(item: PacientesProps) => item?.ID_USUARIO == patient?.ID_USUARIO
			);
		}
		return patient_is_on_the_main_list[0] != undefined
			? patient_is_on_the_main_list[0]
			: patient_is_on_the_most_accessed_list[0];
	}

	useEffect(() => {
		set_current_patient(GetPatientInOrigin);
	}, [pacientes_loading, pacientes, patients_most_accessed, current_patient]);

	const handleProtocoloList = (exercicio: any) => {
		dispatch(ExerciciosActions.set_id_proto(exercicio.ID_EXERCICIO));
		dispatch(AppActions.set_selected_type_pro_livre(TypesProLivre.LIVRE));
		dispatch(AppActions.set_exercicio(item));
		if(item.ID_USUARIO == 0 && item.EXERCICIO_TITULO_PT === 'Preensão Palmar'){
			if(!current_patient.USUARIO_LADO_DOMINANTE) {
				dispatch(AppActions.set_modal_select_dominant_side(true))
				return;
			};
			if(!!current_patient.USUARIO_LADO_DOMINANTE) {
				if (item.EXERCICIO_TIPO === 1) {
					dispatch(AppActions.set_lado_exercicio("Sem Definicao"));
					dispatch(AppActions.set_modal_select_avaliacao(true));
				}
			}
		} else {
			if (item.EXERCICIO_TIPO === 1) {
				dispatch(AppActions.set_lado_exercicio("Sem Definicao"));
				dispatch(AppActions.set_modal_select_avaliacao(true));
			}
		}
		AmplitudeLog("escolha_exercicio", {
			body: exercicio,
		});
	};

	const avaliationInit = (exerc: any) => {
		dispatch(AppActions.set_lado_exercicio(exerc.title));

		if (
			Evaluatedpatients.length < config_servicos?.SERVICO_PACIENTES_AVALIADOS ||
			Evaluatedpatients.includes(patient.ID_USUARIO)
		) {
			handleProtocoloList(exerc);
		} else {
			dispatch(AppActions.set_modal_upgrade(true, true));
		}

		if(item.ID_USUARIO == 0 && item.EXERCICIO_TITULO_PT === 'Preensão Palmar' && !current_patient.USUARIO_LADO_DOMINANTE){
			return
		}
		
		if (!!config_funcional_habilitado) {
			dispatch(AppActions.set_modal_select_avaliacao(true));
		} else {
			dispatch(AppActions.set_selected_avaliacao(TypesAvalOrFunci.AVALIACAO));

			if (is_selected_type_pro_livre === TypesProLivre.LIVRE) {
				dispatch(DashBoardActions.set_current_page("execucao"));
			}
		}

		dispatch(AvaliationActions.set_current_exercicise(exerc));
		if (is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO) {
			dispatch(AvaliationActions.set_in_execution_force_max_status(false));
		}
		dispatch(AppActions.set_checklist_execucao(false));
	};

	const progress_exers_proto = useSelector(
		(state: any) => state.avaliation.progress_exers_proto
	);

	return (
		<>
			{
				<Item>
					<div className={"hori"}>
						{lista.map((e: ExerProtoPros) => {
							return (
								<div
									className={`item ${
										progress_exers_proto.includes(e.exercice_id)
											? "borderVerde"
											: "borderCinza"
									}`}
									key={e.exercice_id}
									onClick={() => avaliationInit(e)}
								>
									<P3Desk
										className={`toPlay ${
											progress_exers_proto.includes(e.exercice_id)
												? "verde"
												: "cinza"
										}`}
										style={{ marginBottom: "0.2rem" }}
									>
										{e.title}
									</P3Desk>
									{progress_exers_proto.includes(e.exercice_id) ? (
										<img
											src={Play}
											alt=""
											style={{ width: 14, height: 16, borderRadius: 3 }}
										/>
									) : (
										<img
											src={Play}
											alt=""
											style={{ width: 14, height: 16, borderRadius: 3 }}
										/>
									)}
								</div>
							);
						})}
					</div>
				</Item>
			}
		</>
	);
};
export default RenderExerProto;
