import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonClose.svg";
import success from "../../../assets/icons/success.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalCadastroSuccess: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	const is_open_cadastro = useSelector(
		(state: any) => state.app.is_open_cadastro
	);

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_cadastro(false));
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_cadastro}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_cadastro}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<img className="alert" src={success} alt="Alerta" />
						<div className="divText">
							{current_language?.idioma === "Português" ? (
								<p className="text">
									Cadastro realizado com sucesso. Por favor, entre no seu e-mail
									para liberar o acesso do aplicativo
								</p>
							) : current_language?.idioma === "English" ? (
								<p className="text">
									Registration successful. Please enter your email to grant
									access to the app
								</p>
							) : (
								<p className="text">
									Registro exitoso. Ingrese su correo electrónico para otorgar
									acceso a la aplicación
								</p>
							)}
						</div>
						<button className="button" onClick={ModalCadastroClose}>
							OK
						</button>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalCadastroSuccess);
