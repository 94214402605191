import styled, {css, keyframes} from 'styled-components';

import {Colors} from '../../../styles/colors';
import { ButtonActionModalProps} from './Models';
import { TitleButtonFormSubmiteProps } from '../../Texts/Text/Models';
import { TitleButton } from '../../Texts/Text/style';




export const ContainerButtonActionModal = styled.button<ButtonActionModalProps>`
  width: ${({ width }) => width ? `${width}%` : `${100}%` };
  height: ${({ height }) => height && height}px;
  background-color: ${({bgColor})=> bgColor ? bgColor : Colors.secondary_button_default};
  border-radius: ${({borderRadius})=> borderRadius ? borderRadius : 12}px;
  border-width: ${({borderWidth})=> borderWidth ? borderWidth  : 0}px;
  border-color: ${({borderColor})=> borderColor ?  borderColor : 'transparent'};
  margin-top: ${({marginTop})=> marginTop ? marginTop : 0}px;
  margin-bottom: ${({margintBottom})=> margintBottom ? margintBottom : 0}px;
  margin-left: ${({marginLeft})=> marginLeft ? marginLeft : 0}px;
  margin-right: ${({marginRight})=> marginRight ? marginRight : 0}px;
  padding-top: ${({paddingTop})=> paddingTop ? paddingTop : 16}px;
  padding-bottom: ${({paddingBottom})=> paddingBottom ? paddingBottom : 16}px;
  padding-left: ${({paddingLeft})=> paddingLeft ? paddingLeft: 32 }px;
  padding-right: ${({paddingRight})=> paddingRight ? paddingRight : 32}px;
  justify-content: ${({justifyContent})=> justifyContent ? justifyContent : 'center'};
  align-items: ${({alignItems})=> alignItems ?  alignItems : 'center'};
  flex-direction: ${({flexDirection})=> flexDirection ?  flexDirection : 'column'};
  box-shadow: ${({ typeButtonComponent }) => typeButtonComponent === 'primary' &&  '0px 2px 4px 0px rgba(65, 77, 74, 0.60)'}; ;
  cursor: pointer;

  ${({ disabled }) => disabled && `
    background-color: ${Colors?.neutral_light_gray_3};
    pointer-events: none;
  `}
  &:hover {
    background-color: ${({ disabled }) => !disabled && Colors?.secondary_green_k_main};
  }
  &:active {
    background-color: ${Colors?.secondary_green_k_dark_1};
  }
  &:hover {
    ${TitleButton}[type='secondary'] {
        color: ${({ typeButtonComponent }) => typeButtonComponent === 'secondary' && Colors?.secondary_main_hover};
    }
  }
  &:active {
    ${TitleButton}[type='secondary'] {
        color: ${({ typeButtonComponent }) => typeButtonComponent === 'secondary' && Colors?.secondary_green_k_dark_1};
    }
  }
  ${({ typeButtonComponent }) => typeButtonComponent === 'secondary' &&  secondaryButtonStyles};
`;


const secondaryButtonStyles:any = css<ButtonActionModalProps>`
  background-color: transparent;
  border-width: 2px;
  border: solid;
  border-color: ${({disabled})=> disabled ? Colors?.neutral_light_gray_3 : Colors?.primary_blue_k_main};

  ${({ disabled }) => disabled && `
    pointer-events: none;
  `}
  
  &:hover {
    border-color: ${({ disabled }) => !disabled && Colors?.secondary_main_hover};
    background-color: transparent;
  }
  &:active {
    border-color: ${Colors?.secondary_main_darker};
    background-color: transparent;
  }
  
`;

export const ActivityIndicator = styled.div<Pick<ButtonActionModalProps, 'activityIndicatorColor'>>`
  width: 33px;
  height: 33px;
  border: 4px solid ${({activityIndicatorColor})=> activityIndicatorColor ? activityIndicatorColor : Colors?.neutral_light_gray_3};
  border-left-color: ${({activityIndicatorColor})=> activityIndicatorColor ? activityIndicatorColor + 40 : Colors?.neutral_light_gray_3 + 40};
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
