export const Types = {
	REGISTER_REQUEST: "REGISTER_REQUEST",
	REGISTER_REQUEST_FINALLY: "REGISTER_REQUEST_FINALLY",
	SET_PAIS: "SET_PAIS",
	EDIT_USUARIO_PERFIL_LOAD: 'EDIT_USUARIO_PERFIL_LOAD',
};

const INITIAL_STATE = {
	loading_register: false,
	pais: null,
	load: false,
};

export default function register(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.REGISTER_REQUEST:
			return {
				...state,
				loading_register: true,
			};
		case Types.REGISTER_REQUEST_FINALLY:
			return {
				...state,
				loading_register: false,
			};
		case Types.EDIT_USUARIO_PERFIL_LOAD:
			return {
				...state,
				load: action.payload,
			};
		case Types.SET_PAIS:
			return {
				...state,
				pais: action.payload,
			};

		default:
			return state;
	}
}

export const RegisterActions = {
	register_request: (data: any) => ({
		type: Types.REGISTER_REQUEST,
		payload: data,
	}),
	register_finally: () => ({
		type: Types.REGISTER_REQUEST_FINALLY,
	}),
	set_pais: (pais: any) => ({
		type: Types.SET_PAIS,
		payload: pais,
	}),
	edit_perfil_load: (load: boolean) => ({
		type: Types.EDIT_USUARIO_PERFIL_LOAD,
		payload: load,
	}),
};
