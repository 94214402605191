import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { TextsProps } from "../../database/txt.interface";
import { ExerProtoPros } from "../../interfaces/exercicio_protocolo";
import { PacientesProps, UserProps } from "../../interfaces/pacientes.interface";
import { TypesErrorRequest, TypesProLivre } from "../../utils/types";
import { AppActions } from "../ducks/app";
import { AvaliationActions, Types } from "../ducks/avaliation";
import { DashBoardActions } from "../ducks/dashboard";
import * as Sentry from "@sentry/react";
import { getEvaluatedPatients } from "../../utils/funcs";

function* saveExecucao({ payload }: any): any {
	const current_language: TextsProps = yield select(
		(state: any) => state.configs.current_language
	);
	const caunt: boolean = yield select(
		(state: any) => state.configs.number_evaluated_patient
	);

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const current_exercise: ExerProtoPros = yield select(
		(state: any) => state.avaliation.current_exercise
	);

	const is_selected_type_pro_livre: any = yield select(
		(state: any) => state.app.is_selected_type_pro_livre
	);
	const set_toast_after_completing: boolean = yield select(
		(state: any) => state.avaliation.set_toast_after_completing
	);
	const paciente: PacientesProps = yield select(
		(state: any) => state.app.is_open_paciente
	);

	async function apiCall() {
		try {
			const data_request = {
				TOKEN: user_logado.token,
				...payload,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data_request),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}execucoes/execucoes_adicionar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: execucoes_adicionar.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		let message: any = "";
		if (data.status == TypesErrorRequest.SUCCESS) {

			getEvaluatedPatients(paciente)
			
			yield put(AvaliationActions.set_id_execucao(data?.OBJ_EXECUCAO?.ID_EXECUCAO))
			message = current_language?.adicao_concluida;
			if(set_toast_after_completing){
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
			}
			yield put(DashBoardActions.execucoes_request(false));
			if (payload.EXECUCAO_EXCLUIDA == "N") {
			}
			if (is_selected_type_pro_livre == TypesProLivre.PROTOCOLO) {
				if (payload.EXECUCAO_EXCLUIDA == "S") {
					yield put(AvaliationActions.set_current_repe(0));
				} else {
					yield put(
						AvaliationActions.set_progress_exers_proto(
							current_exercise.exercice_id
						)
					);
					yield put(
						AvaliationActions.set_serie_concluida(is_selected_type_pro_livre)
					);
				}
			} else {
				yield put(
					AvaliationActions.set_serie_concluida(is_selected_type_pro_livre)
				);
			}

			if (data.code == 0 && payload.EXECUCAO_EXCLUIDA == "N") {
				message = current_language?.exercicio_concluido;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
			}
		} else {
			yield put(AvaliationActions.cancel_execucao_request());

			if (data.code == 1 && payload.EXECUCAO_EXCLUIDA == "N") {
				message = current_language?.erro_salvar_execucao;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
		yield put(
			AppActions.set_toast_visible({
				status_toast: true,
				type_toast: TypesErrorRequest.FAIL,
				message_toast: current_language?.adicao_falhou,
			})
		);
		yield put(AvaliationActions.cancel_execucao_request());
	} finally {
		yield put(AvaliationActions.cancel_execucao_request());
	}
}

function* excluirExecucao({ payload }: any): any {
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const dataRequest: any = {
				TOKEN: user_logado.token,
				...payload,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dataRequest),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}execucoes/execucoes_desativar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: execucoes_desativar.php", "error");
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.SUCCESS) {
			//msg de sucesso
			if (response.code == 0) {
				message = current_language?.desativacao_concluida;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
			}
		} else if (response.status == TypesErrorRequest.FAIL) {
			//msg de falha
			if (response.code == -1) {
				message = current_language?.desativacao_falhou;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
	} finally {
		yield put(AvaliationActions.excluir_execucao_cancel());
	}
}

function* Compartilhar({ payload }: any): any {
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const dat = {
				...payload,
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}questionarios/questionario_token.php`,
				requestOptions
			);
			const response: any = await res.json();
			navigator.clipboard.writeText(response.url);
			if (payload.txOutput === "download") {
			}

			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.message == "Bad Request") {
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: "Falha",
				})
			);
		} else {
			//msg de sucesso
			if (response.code == 0) {
				if (response.message == "Email enviado.") {
					message = current_language?.email_enviado;
				} else {
					message = "Link copiado com sucesso";
				}
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
				yield put(AppActions.set_open_modal_escolha_modo(false));
			}
		}
		yield put(AvaliationActions.set_shere_anamnese_url(response.url));
	} catch (e) {
	} finally {
		yield put(AvaliationActions.set_shere_anamnese_cancel());
	}
}

function* logsProtocolExecuted (action: { type: typeof Types.LOGS_PROTOCOLS_EXECUTED, payload: {
	txDataHora: string,
	ID_USUARIO: number,
	ID_PROTOCOLO: number,
	ID_EXERCICIO: number,
	IDS_EXECUCOES: number[]
}}){

	const current_language: TextsProps= yield select(
		(state) => state.configs.current_language
	);
	
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	
	const executionDataFinish = {
		TOKEN: user_logado?.token,
		txDataHora: action?.payload?.txDataHora,
		ID_USUARIO: action?.payload?.ID_USUARIO ,
		ID_PROTOCOLO: action?.payload?.ID_PROTOCOLO,
		ID_EXERCICIO: action?.payload?.ID_EXERCICIO,
		IDS_EXECUCOES: action?.payload?.IDS_EXECUCOES, 
	};
	const requestOptions: any = {
		method: "POST",
		body: JSON.stringify(executionDataFinish),
	};

	async function apiCall() {
		try {
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/execucoes/execucoes_finalizar.php`,
				requestOptions
			);
			const response = res.json()
			return response 
		} catch (error) {
			throw error;
		}
	};

	try{
		const response:{status: string} = yield call(apiCall);
		if(response?.status == TypesErrorRequest?.SUCCESS){
			yield put(AvaliationActions.set_id_execucao(null))
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast:current_language?.protocolo_concluido,
				})
			);

		}
	}catch(error){
		Sentry.captureMessage("CAIU NO CATCH:execucoes_finalizar.php ", "error");
	}
};

export function* AvaliationSaga(): any {
	return yield all([
		takeLatest(Types.ADD_EXECUCAO_REQUEST, saveExecucao),
		takeLatest(Types.SET_EXECUCAO_EXCLUIR, excluirExecucao),
		takeLatest(Types.SET_COMPARTILHAR_ANAMNESE, Compartilhar),
		takeLatest(Types.LOGS_PROTOCOLS_EXECUTED, logsProtocolExecuted)
	]);
}
