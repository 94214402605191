import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";
import DinoHappy from "../../../assets/dinoHappyAsync.svg";
import history from "../../../routes/history";
import moment from "moment";
import { persistStorage } from "../../../utils/base_async_storage";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalLadoDominante: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const classes = useStyles();
	const visible_modal =  useSelector(
		(state: any) => state.app.is_open_select_dominant_side
	);
	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const user_eval_avatar_64 =  useSelector((state: any) => state.add_pac_aval.user_eval_avatar_64);

  const editPatient = (side: string) => {
		const dat_request = {
			USUARIO_NIVEL: "3",
			USUARIO_NOME: patient.USUARIO_NOME,
			USUARIO_EMAIL: patient.USUARIO_EMAIL,
			USUARIO_CONTATO: patient.USUARIO_CONTATO,
			USUARIO_CPF: patient.USUARIO_CPF,
			USUARIO_ESTATURA: patient.USUARIO_ESTATURA,
			USUARIO_MASSA: patient.USUARIO_MASSA,
			USUARIO_NASCIMENTO:patient.USUARIO_NASCIMENTO,
			USUARIO_SEXO: patient?.USUARIO_SEXO,
			USUARIO_CONDICIONAMENTO: patient.USUARIO_CONDICIONAMENTO,
			USUARIO_LADO_DOMINANTE: side,
		};

		dispatch(AddPacAvalActions.set_avatar_eval_64(user_eval_avatar_64 != null ? user_eval_avatar_64 : patient?.USUARIO_FOTO));
		// AmplitudeLog('edit_perfil_paciente', {body: dat_request})
		dispatch(AddPacAvalActions.set_edit_paci(patient));
		dispatch(AddPacAvalActions.request_add_evaluetor(dat_request));
		dispatch(AppActions.set_modal_select_dominant_side(false));

	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={visible_modal}
			>
				<Fade in={visible_modal}>
					<Container>
						<div className="divText">
							<h1 className="title">
								{current_language?.canhoto_destro}
							</h1>
							<p className="text">
								{current_language?.sem_informacao_dominante}
							</p>
							<div className="ContainerButtons">
								<div 
									className="buttonSide"
									onClick={()=> editPatient('E')}
									>
									<p className="txtSide">{current_language?.canhoto}</p>
								</div>
								<div 
									className="buttonSide"
									onClick={()=> editPatient('D')}
									>
									<p className="txtSide">{current_language?.destro}</p>
								</div>
							</div>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalLadoDominante;