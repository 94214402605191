import React, { memo, useEffect, useState } from "react";
import { Area, AreaText } from "./styles";
import { QuestionnaireProps } from "../../../../../interfaces/pacientes.interface";
import { useDispatch, useSelector } from "react-redux";
import { PacAvalActions } from "../../../../../store/ducks/manage_pac_aval";

interface questionProps {
	item: QuestionnaireProps;
	autoFocus?: boolean;
}

const TextArea: React.FC<questionProps> = ({ item, autoFocus = false }) => {
	const dispatch = useDispatch();
	const array_respostas = useSelector(
		(state: any) => state.pac_aval.array_respostas
	);
	const array_resposta = useSelector(
		(state: any) => state.pac_aval.array_resposta
	);

	const [value, setValue] = useState("");

	useEffect(() => {
		const valor = array_respostas?.filter(
			(e: any) => e?.QUESTIONARIO_LABEL === item?.QUESTIONARIO_LABEL
		);
		if (valor.length === 1) {
			setValue(valor[0].QUESTIONARIO_RESPOSTA[0]);
		}
	}, []);

	const handleTextInput = (item: QuestionnaireProps, text: string) => {
		if (text != "") {
			const dataObj = {
				QUESTIONARIO_LABEL: `${item.QUESTIONARIO_LABEL}`,
				QUESTIONARIO_RESPOSTA: [text],
			};
			const new_array = array_respostas.filter(
				(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
			);
			const new_array_resposta = array_resposta.filter(
				(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
			);
			dispatch(PacAvalActions.set_array_respostas([...new_array, dataObj]));
			dispatch(
				PacAvalActions.set_array_resposta([...new_array_resposta, dataObj])
			);
		} else {
			const new_array = array_respostas.filter(
				(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
			);
			const new_array_resposta = array_resposta.filter(
				(e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL
			);
			dispatch(PacAvalActions.set_array_respostas([...new_array]));
			dispatch(PacAvalActions.set_array_resposta([...new_array_resposta]));
		}
	};
	return (
		<Area>
			<AreaText
				onChange={(e) => setValue(e.target.value)}
				value={value}
				onBlur={() => handleTextInput(item, value)}
				placeholder={`${item.QUESTIONARIO_PERGUNTA_OPCOES}`}
				autoFocus={autoFocus}
				maxLength={255}
			/>
			<div className="contador">{value.length}/255</div>
		</Area>
	);
};

export default memo(TextArea);
