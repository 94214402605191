import React from "react";

import { TextsProps } from "../../../database/txt.interface";
import { useDispatch, useSelector } from "react-redux";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import { ContainerDash, Icon, Return } from "../../../styles/global";
import { P1Desk, P3Desk } from "../../../styles/styles_text";
import InformacoesPaciente from "./InformacoesPaciente";

const DetailInfoPatient: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const dispatch = useDispatch();

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("perfil_paciente"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "1rem", height: "1rem" }} />
				<P3Desk>{current_language?.area_paciente}</P3Desk>
			</Return>

			<div style={{ width: "60%", marginTop: "1rem" }}>
				<P1Desk className="title"> {current_language?.informacoes}</P1Desk>
			</div>
			<div style={{width: "60%"}}>
				<InformacoesPaciente />
			</div>
		</ContainerDash>
	);
};

export default DetailInfoPatient;

