import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../assets/icons/buttonCloseBlack.svg";

import IconWarning from "../../assets/icons/status_warning.svg";
import { TextsProps } from "../../database/txt.interface";
import { ContainerModalExcluir } from "../../styles/styles_modais_excluir";
import { ExerciciosActions } from "../../store/ducks/exer";
import { Exercicios2Props } from "../../interfaces/exercicio.interface";
import { AmplitudeLog } from "../../utils/amplitude";
import { DashBoardActions } from "../../store/ducks/dashboard";
import { ManageProtocolActions } from "../../store/ducks/manage_protocols";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalExcluirExercicio: React.FC = () => {
	const dispatch = useDispatch();
	const classes = useStyles();

	// comunica com redux
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const is_open_modal_excluir_exercicio = useSelector(
		(state: any) => state.exercicios.is_open_modal_excluir_exercicio.status
	);

	const current_exer: Exercicios2Props = useSelector(
		(state: any) => state.exercicios.is_open_modal_excluir_exercicio.exercicio
	);

	const ModalClose = () => {
		dispatch(ExerciciosActions.set_open_modal_excluir_exercicio(false, false));
	};

	const Excluir = () => {
		AmplitudeLog("exercicio_excluido");
		dispatch(
			ManageProtocolActions.delete_exer_request({
				txDataHora: moment(new Date())
					.format("YYYY-MM-DD HH:mm:ss")
					.toString(),
				ID_EXERCICIO: [current_exer.ID_EXERCICIO],
			})
		);
		dispatch(DashBoardActions.set_current_page("exercicios"))
		ModalClose();
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open_modal_excluir_exercicio}
			onClose={ModalClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open_modal_excluir_exercicio}>
				<ContainerModalExcluir>
					<img
						className="close"
						src={buttonClose}
						onClick={ModalClose}
						alt="Close"
					/>
					<img className="icon_warning" src={IconWarning} alt="" />
					<div className="title">
						<div className="containerFrases">
							<p className="QtdPacientes">{current_language?.deseja_excluir_exercicio}</p>
						</div>
					</div>
					<div className="buttons">
						<button onClick={Excluir} className="button excluir">
							{current_language?.modal_excluir}
						</button>
						<button className="button cancelar" onClick={ModalClose}>
							{current_language?.pagamento_cancelar}
						</button>
					</div>
				</ContainerModalExcluir>
			</Fade>
		</Modal>
	);
};

export default ModalExcluirExercicio;
