import styled from "styled-components";
import { Colors } from "../../../styles/colors";


export const Conteiner = styled.div`
	width: 100%;
	height: 100%;
	padding-left: 3.6rem;
	padding-right: 3.6rem;
	background-color: white;
	border-radius: 1.25rem;
	overflow-y: none;
	display: flex;
	align-items: center;
	flex-direction: column;

	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;
export const Content = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	width: 50%;
`;

export const Header = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`;
export const ContainerGoBack = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-top: 20px;
`;

export const ContainerInput = styled.div`
	display: flex;
	margin-top: 40px;
	border-bottom: 1px solid  ${Colors?.neutral_grey_k_60};

	.pulse {
		display: block;
		width: 30px;
		height: 25px;
		border-radius: 50%;
		background: transparent;
		cursor: pointer;
		box-shadow: 0 0 0 ${Colors?.primary_blue_k_main};
		animation: pulse 2s infinite;
		border: none;
	}
	 	.pulse:hover {
		animation: none;
	}

	@-webkit-keyframes pulse {
		0% {
			-webkit-box-shadow: 0 0 0 0 ${Colors?.secondary_main_hover};
		}
		70% {
			-webkit-box-shadow: 0 0 0 10px ${Colors?.secondary_main_hover};
		}
		100% {
			-webkit-box-shadow: 0 0 0 0 ${Colors?.secondary_main_hover};
		}
	}
	@keyframes pulse {
		0% {
			-moz-box-shadow: 0 0 0 0 ${Colors?.secondary_main_hover};
			box-shadow: 0 0 0 0 ${Colors?.secondary_main_hover};
		}
		70% {
			-moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
			box-shadow: 0 0 0 15px rgba(204, 169, 44, 0);
		}
		100% {
			-moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
			box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
		}
	}
`;

export const Input = styled.input`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	padding: 0;
	margin: 0;
	background: none;
	outline: none;
	margin-left: 8px;
	width: 100%;
`;

export const EvaluatorCard = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 40px;
	position: relative;
`;
export const ContainerFoto = styled.div`
	width: 10%;
`;
export const UserName = styled.p`
	font-family: "NotoSans-Regular";
	color: #323D6E;
	font-size: 1rem;
	font-weight: 500;
`;
export const NonePhoto = styled.div`
	width: 32px;
	height: 32px;
	justify-content: center;
	align-items: center;
`;

export const InfoEvaluator = styled.div`
	display: flex;
	width: 90%;
	flex-direction: column;
`;
export const Tag = styled.div`
	display: flex;
	min-width: 91px;
	align-self: flex-start;
	margin-top: 6px;
	padding: 4px 10px;
	border-radius: 30px;
	align-items: center;
	justify-content: center;
	background-color: #C1FEE8;
	color:  #323D6E;
	font-size:0.87rem ;
`;
export const Photo = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 42px;
	height: 42px;
	border-radius: 100%;
`;
export const Image = styled.img`
	width: 42px;
	height: 42px;
	justify-content: center;
	align-items: center;
`;
export const ContainerEvaluators = styled.div`
	display: flex;
	flex-direction: column;
	height: 500px;
	width: 100%;
	overflow-y: auto;

	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;
export const ContainerIcon = styled.div`
	display: flex;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	justify-content: center;
	align-items: center;
	background-color: transparent;

	&:hover {
		background-color: ${Colors?.primary_blue_k_medium_1};

		.DotOptions {
			color: ${Colors?.white}
		}
	}
	&:active {
		background-color: ${Colors?.primary_blue_k_main}
	}
`;
export const NewEvalutor = styled.div`
	display: flex;
	height: 10%;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
`;
export const NoneEvaluator = styled.p`
	font-family: "NotoSans-Regular";
	font-size: 24px;
	color: ${Colors?.primary_blue_k_main};
	margin-top: 60px;
`;

export const Toggle = styled.div`
	display: flex;
	position: absolute;
	right: 25px;
	width: 186px;
	height: 100px;
	flex-direction: column;
	justify-content: space-between;
	padding: 0px 24px;
	border-radius: 12px;
	z-index: 10;
	background-color: white;
	box-shadow: 0px 4px 6px 0px rgba(20, 22, 34, 0.20);
`;



export const ContainerIconEditToggle = styled.div`
	display: flex;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	justify-content: center;
	align-items: center;
	background-color: transparent;
`;

export const Edit = styled.div`
	display: flex;
	width: 100%;
	height: 45%;
	align-items: center;

	&:hover ${ContainerIconEditToggle}{
		background-color: ${Colors?.primary_blue_k_medium_1};

		.EditIcon {
			color: ${Colors?.white}
		}
	}
	&:active ${ContainerIconEditToggle} {
		background-color: ${Colors?.primary_blue_k_main}
	}
	
`;

export const ContainerIconDeleteToggle = styled(ContainerIconEditToggle)``;

export const Delete = styled.div`
	display: flex;
	width: 100%;
	height: 50%;
	align-items: center;

	&:hover ${ContainerIconDeleteToggle} {
		background-color: ${Colors?.tertirary_red_1_dark};

		.DeleteIcon {
			color: ${Colors?.white}
		}
	}
	&:active  ${ContainerIconDeleteToggle}{
		background-color: ${Colors?.primary_blue_k_main}
	}
	
`;

export const TextToggle = styled.p`
	font-family: "NotoSans-Medium";
	font-size: 1rem;
	color: ${Colors.neutral_grey_k_70};
	font-weight: 500;
	margin-left:12px;
	cursor: pointer;
`;
