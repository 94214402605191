import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { ContainerModalExcluir } from "../../../styles/styles_modais_excluir";
import { AppActions } from "../../../store/ducks/app";
import { Icon } from "../../../styles/global";
import Alert from "../../../assets/kinologyIcons/svg/alert.svg";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import {
	TypeCategoriasAnamnese,
	TypesQuestionnaire,
	TypeTypeAnamnese,
} from "../../../utils/types";
import { PacientesProps } from "../../../interfaces/pacientes.interface";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalAnamneseAndamento: React.FC = () => {
	const dispatch = useDispatch();
	const classes = useStyles();

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const is_open_continuar_ou_gerar_anamnese = useSelector(
		(state: any) => state.app.is_open_continue_or_to_generate_anamnese
	);

	const historicos_questionarios = useSelector(
		(state: any) => state.pac_aval.perfil_anamnese_array
	);

	const historico_anamenses = useSelector(
		(state: any) => state.anamnese.historico_anamnese
	);

	const anamnese_type: TypeTypeAnamnese = useSelector(
		(state: any) => state.anamnese.anamnese_type
	);

	const anamnese_category: TypeCategoriasAnamnese = useSelector(
		(state: any) => state.anamnese.anamnese_category
	);

	const ModalClose = () => {
		dispatch(AppActions.set_modal_continue_or_to_generate_new_anamnese(false));
	};

	const continuarQuestionario = () => {
		if (anamnese_type == "ANAMNESE_TIPO") {
			dispatch(PacAvalActions.set_questionario_request(true));
			dispatch(
				PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE_TIPO)
			);
			dispatch(
				PacAvalActions.set_tipo_anamnese(
					historicos_questionarios?.historico_anamnese_tipo[0]?.QUESTIONARIO_RESPOSTA_TIPO.split(
						"_"
					)[2]
				)
			);
			dispatch(
				PacAvalActions.set_usu_continuar_questionario(paciente.ID_USUARIO)
			);
			dispatch(
				AppActions.set_modal_continue_or_to_generate_new_anamnese(false)
			);
		}

		if (anamnese_type == "ANAMNESE_AVALIACAO") {
			dispatch(PacAvalActions.set_questionario_request(true));
			dispatch(
				PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE_AVALIACAO)
			);
			dispatch(
				PacAvalActions.set_tipo_anamnese(
					historicos_questionarios?.historico_anamnese_avaliacao[0]?.QUESTIONARIO_RESPOSTA_TIPO.split(
						"_"
					)[2]
				)
			);

			dispatch(
				PacAvalActions.set_usu_continuar_questionario(paciente.ID_USUARIO)
			);
			dispatch(
				AppActions.set_modal_continue_or_to_generate_new_anamnese(false)
			);
		}

		if (anamnese_type == "ANAMNESE_V7") {
			dispatch(PacAvalActions.set_questionario_request(true));
			dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE_V7));
			dispatch(
				PacAvalActions.set_tipo_anamnese(
					historico_anamenses?.anamneses[0]?.RESPOSTA_TIPO
				)
			);
			dispatch(
				PacAvalActions.set_usu_continuar_questionario(paciente.ID_USUARIO)
			);
			dispatch(
				AppActions.set_modal_continue_or_to_generate_new_anamnese(false)
			);

			dispatch(
				PacAvalActions.set_id_resposta(
					historico_anamenses?.anamneses[0]?.ID_RESPOSTA
				)
			);
			dispatch(DashBoardActions.set_current_page("questionario_novo"));
		}

		if (anamnese_type == "ANAMNESE_CLINICO_V7") {
			dispatch(PacAvalActions.set_questionario_request(true));
			dispatch(
				PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE_CLINICO_V7)
			);
			dispatch(
				PacAvalActions.set_tipo_anamnese(
					historico_anamenses?.exames_fisio_clinico[0]?.RESPOSTA_TIPO
				)
			);
			dispatch(
				PacAvalActions.set_usu_continuar_questionario(paciente.ID_USUARIO)
			);
			dispatch(
				AppActions.set_modal_continue_or_to_generate_new_anamnese(false)
			);
			dispatch(
				PacAvalActions.set_id_resposta(
					historico_anamenses?.exames_fisio_clinico[0]?.ID_RESPOSTA
				)
			);
			dispatch(DashBoardActions.set_current_page("questionario_novo"));
		}

		if (anamnese_type == "SF_36") {
			dispatch(PacAvalActions.set_questionario_request(true));
			dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.SF_36));
			dispatch(PacAvalActions.set_tipo_anamnese("SF_36"));
			dispatch(
				PacAvalActions.set_usu_continuar_questionario(paciente.ID_USUARIO)
			);
			dispatch(
				AppActions.set_modal_continue_or_to_generate_new_anamnese(false)
			);
			dispatch(
				PacAvalActions.set_id_resposta(
					historico_anamenses?.sf36[0]?.ID_RESPOSTA
				)
			);
			dispatch(DashBoardActions.set_current_page("questionario_novo"));
		}
	};

	const GerarNova = () => {
		if (anamnese_type != "ANAMNESE_AVALIACAO") {
			dispatch(AppActions.set_open_modal_escolha_modo(true));
		} else {
			dispatch(DashBoardActions.set_current_page("questionario_novo"));
		}
		ModalClose();
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open_continuar_ou_gerar_anamnese}
			onClose={ModalClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open_continuar_ou_gerar_anamnese}>
				<ContainerModalExcluir>
					<img
						className="close"
						src={buttonClose}
						onClick={ModalClose}
						alt="Close"
					/>
					<Icon style={{ width: "4rem", height: "4rem" }} src={Alert} />
					<div className="title" style={{ marginBottom: "3.75rem" }}>
						<div className="containerFrases">
							<p className="QtdPacientes">
								Verificamos que existe uma anamnese em andamento.
							</p>
						</div>
						<p className="text">
							Deseja continuar ou gerar uma nova anamnese e perder o progresso?
						</p>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							width: "100%",
						}}
					>
						<button
							style={{
								width: "100%",
								backgroundColor: "#000E4B",
								color: "#FAFAFA",
							}}
							className="button cancelar"
							onClick={continuarQuestionario}
						>
							Atualizar
						</button>

						<button
							style={{
								width: "100%",
							}}
							onClick={GerarNova}
							className="button cancelar"
						>
							Gerar nova
						</button>
					</div>
				</ContainerModalExcluir>
			</Fade>
		</Modal>
	);
};

export default ModalAnamneseAndamento;
