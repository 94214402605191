import styled from "styled-components";

export const Container = styled.div`
	background-color: #ffffff;
	color: #b3b3b3;
	height: 18.313rem;
	width: 12.938rem;
	border-radius: 18px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 84%;
	margin-top: 2rem;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	.header {
		margin-top: 0.8rem;
		width: 80%;
		display: flex;
		justify-content: space-between;
	}
	.body {
		height: 90%;
		width: 60%;
	}

	.close {
		width: 0.75rem;

		cursor: pointer;
	}

	.orientadores {
		width: 100%;
		height: 100%;

		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.orientadorRow {
		width: 100%;

		margin-top: 1.5rem;

		display: flex;
		justify-content: space-between;
	}

	.orientadorItem {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.orientadorIcon {
		margin-bottom: 0.5rem;

		display: flex;
		justify-content: center;

		position: relative;
		height: 1rem;
		width: 1rem;
	}

	.orientadorConect {
		position: absolute;
		top: -0.2rem;
		right: -0.8rem;
	}

	.orientadorNome {
		font-size: 0.875rem;
		color: #b3b3b3;
	}

	.dinamo {
		margin-right: 0.35rem;

		.dino {
			margin-top: -0.4rem;
		}
	}

	.footer {
		width: 80%;
		margin-bottom: 1.5rem;
	}

	.legenda {
		margin-left: 0.5rem;
		color: #b3b3b3;

		font-size: 0.875rem;
	}

	.conectado {
		margin-bottom: 0.2rem;
	}
`;
