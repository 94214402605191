import styled from "styled-components";

export const SelectContent = styled.div`
	position: relative;
	
	width: 98%;
	height: 20rem;
`;

export const SelectBox = styled.button`
	position: absolute;
	top: 0;

	width: 98%;
	height: 2.625rem;


	padding: 0 0.521rem;

	display: flex;
	align-items: center;
	justify-content: space-between;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 120%;

	opacity: 0.6;

	border: 0;

	color: #848484;

	cursor: pointer;
`;

export const SelectOptions = styled.div`
	position: absolute;
	top: 0;

	display: flex;
	flex-direction: column;
	width: 100%;
	z-index: 100;
`;

export const SelectItem = styled.button`
	width: 98%;
	height: 2.625rem;

	background-color: #EDEDED;

	padding: 0 0.521rem;

	display: flex;
	align-items: center;
	justify-content: space-between;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 120%;

	opacity: 0.6;

	border: 0;

	color: #848484;
`;