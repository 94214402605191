import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DashBoardActions } from "../../store/ducks/dashboard";
import Loading from "./Loading";
import { Conteiner } from "./styles";
import { ExecucaoProps } from "../../interfaces/execucao.interface";
import { Icon } from "../../styles/global";
import { P2Desk } from "../../styles/styles_text";
import VerifiedIcon from "../../assets/icons/verified_icon.svg";
import ArrowForward from "../../assets/icons/arrow_forward_ios.svg";
import ArrowForwardClosed from "../../assets/icons/arrow_forward_ios_closed.svg";
import AssimetriaIcon from "../../assets/icons/assimetria_icon.svg";
import DesequilibrioIcon from "../../assets/kinologyIcons/svg/desequilibrio_icon.svg";
import BlockedIcon from "../../assets/icons/blocked_icon.svg";
import EvolutionIcon from "../../assets/kinologyIcons/svg/evolution_icon.svg";
import CurvasIcon from "../../assets/kinologyIcons/svg/curvas.svg";
import styled from "styled-components";
import PdfIcon from "../../assets/kinologyIcons/svg/pdf.svg";
import PdfIconEmpty from "../../assets/kinologyIcons/svg/pdf-empty.svg";
import { RelatorioActions } from "../../store/ducks/relatorios";
import { AssimetriaPros } from "../../interfaces/assimetria.interface";

const typeOfJoint = [
	'QUADRIL',
	'JOELHO',
	'CERVICAL',
	'OMBRO',
	'COTOVELO',
	'PUNHO',
	'TORNOZELO',
];

const Relatorios: React.FC = () => {
	const dispatch = useDispatch();

	const execucoes_loading: boolean = useSelector(
		(state: any) => state.dashboard.execucoes_loading
	);
	const anamneses_loading: boolean = useSelector(
		(state: any) => state.dashboard.anamneses_loading
	);
	const assimetria_loading: boolean = useSelector(
		(state: any) => state.dashboard.assimetria_loading
	);

	const relatorio_anamneses = useSelector(
		(state: any) => state.anamnese.relatorio_anamnese
	);
	const execucoes: ExecucaoProps[] = useSelector(
		(state: any) => state.dashboard.execucoes
	);

	const historico_anamenses = useSelector(
		(state: any) => state.anamnese.historico_anamnese
	);

	const assimetrias: AssimetriaPros[] = useSelector(
		(state: any) => state.dashboard.assimetria
	);

	const desequilibrios_object: any = useSelector(
		(state: any) => state.dashboard.desequilibrio
	);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (
			execucoes_loading ||
			anamneses_loading ||
			assimetria_loading ||
			!relatorio_anamneses
		) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [
		execucoes_loading,
		anamneses_loading,
		assimetria_loading,
		relatorio_anamneses,
	]);

	type EstadoPermitido =
		| "resultado_avaliacoes"
		| "escala_dor"
		| "qualidade_vida"
		| "anamnese"
		| "";
	const [resultado_selecionado, set_resultado_selecionado] =
		useState<EstadoPermitido>("");

	function ChangeAreaResultado(area: EstadoPermitido) {
		if (execucoes.length > 0) {
			area === resultado_selecionado
				? set_resultado_selecionado("")
				: set_resultado_selecionado(area);
		}
	}

	function verifyQualityOfLife() {
		return historico_anamenses?.sf36.length > 0 ? true : false;
	}

	function verifyAnamneses() {
		return historico_anamenses?.anamneses.length > 0 ||
			historico_anamenses?.exames_fisio_clinico.length > 0
			? true
			: false;
	}

	function verifyDesequilibrio() {
		let allowed = false;

		const desequilibrios_array: any = !!desequilibrios_object && Object.values(desequilibrios_object) || [];
		if(desequilibrios_array.length > 0) {
			typeOfJoint.map((item: any) => {
				desequilibrios_array.map((des: any) => {
					if (des.TITULO_TABELA.toUpperCase() === item) {
							if(des?.REFERENCIAS[0]?.EXECUCOES.length > 0) allowed = true
					}
				})
			})
		}
		return allowed;
	}

	function verifyAssimetrys() {
		let allowed = false;
		assimetrias.map((assi: any) => {
			if(assi.LISTAS?.INFERIOR_DORSAL.length) allowed = true;
			if(assi.LISTAS?.INFERIOR_FRONTAL.length) allowed = true;
			if(assi.LISTAS?.SUPERIOR_DORSAL.length) allowed = true;
			if(assi.LISTAS?.SUPERIOR_FRONTAL.length) allowed = true;
		})
		return allowed;
	}

	function verifyExecutions() {
		return execucoes.length > 0 ? true : false;
	}

	return (
		// <ContainerDash>
		<Conteiner>
			{loading ? (
				<P2Desk style={{ marginTop: "1rem" }}>Carregando...</P2Desk>
			) : (
				<>
					<div>
						{/* Avaliacoes de força  */}

						{execucoes.length > 0 && (
							<AreaResultadosButton
							style={{ backgroundColor: "transparent" }}
							onClick={() => {
								dispatch(
									DashBoardActions.set_current_page("emissao_relatorios")
								);
							}}
						>
							<div style={{ display: "flex", alignItems: "center" }}>
								<Icon src={PdfIcon} style={{ marginRight: "0.5rem" }} />
								<P2Desk
									style={{ textDecorationLine: "underline", color: "#00A377" }}
								>
									Gerar Relatório
								</P2Desk>
							</div>
						</AreaResultadosButton>
						) || (
							<AreaResultadosButton
							style={{ backgroundColor: "transparent" }}
						>
							<div style={{ display: "flex", alignItems: "center" }}>
								<Icon src={PdfIconEmpty} style={{ marginRight: "0.5rem" }} />
								<P2Desk
									style={{ textDecorationLine: "underline", color: "#9497A4" }}
								>
									Gerar Relatório
								</P2Desk>
							</div>
						</AreaResultadosButton>
						)}

						<AreaResultadosButton
							style={
								resultado_selecionado == "resultado_avaliacoes"
									? { backgroundColor: "#DEDFE3" }
									: { backgroundColor: "transparent" }
							}
							onClick={() => {
								verifyExecutions() &&
									ChangeAreaResultado("resultado_avaliacoes");
							}}
						>
							<div style={{ display: "flex", alignItems: "center" }}>
								<Icon
									src={verifyExecutions() ? VerifiedIcon : BlockedIcon}
									style={{ marginRight: "0.5rem" }}
								/>
								<P2Desk
									style={
										execucoes.length > 0
											? { color: "#000E4B" }
											: { color: "#ABADB7" }
									}
								>
									Resultado das avaliações
								</P2Desk>
							</div>
							<div style={{ display: "flex", alignItems: "center" }}>
								{!verifyExecutions() && (
									<P2Desk
										style={{
											marginRight: "1rem",
											textDecorationLine: "underline",
											cursor: 'pointer'
										}}
										onClick={() => dispatch(DashBoardActions.set_current_page("protocols_in_patient"))}
									>
										Avaliar
									</P2Desk>
								)}
								<Icon
									src={
										resultado_selecionado === "resultado_avaliacoes"
											? ArrowForward
											: ArrowForwardClosed
									}
								/>
							</div>
						</AreaResultadosButton>

						{resultado_selecionado == "resultado_avaliacoes" && (
							<>
								<AreaCadaResultado
									onClick={() =>
										verifyAssimetrys() &&
										dispatch(DashBoardActions.set_current_page("assimetria"))
									}
								>
									<div style={{ display: "flex", alignItems: "center" }}>
										<Icon
											src={verifyAssimetrys() ? AssimetriaIcon : BlockedIcon}
											style={{ marginRight: "0.5rem" }}
										/>
										<P2Desk>Assimetria</P2Desk>
									</div>
									{!verifyAssimetrys() && (
									<P2Desk
										style={{
											marginRight: "1rem",
											textDecorationLine: "underline",
											cursor: 'pointer'
										}}
										onClick={() => dispatch(DashBoardActions.set_current_page("assimetria"))}
									>
										Avaliar
									</P2Desk>
								) || (
									<Icon src={ArrowForward} />
								)}
								</AreaCadaResultado>

								<AreaCadaResultado
									onClick={() =>
										verifyDesequilibrio() &&
										dispatch(
											DashBoardActions.set_current_page(
												"desequilibrio_muscular"
											)
										)
									}
								>
									<div style={{ display: "flex", alignItems: "center" }}>
										<Icon
											src={
												verifyDesequilibrio() ? DesequilibrioIcon : BlockedIcon
											}
											style={{ marginRight: "0.5rem" }}
										/>
										<P2Desk>Desequilibrio muscular</P2Desk>
									</div>
									{!verifyDesequilibrio() && (
									<P2Desk
										style={{
											marginRight: "1rem",
											textDecorationLine: "underline",
											cursor: 'pointer'
										}}
										onClick={() => dispatch(DashBoardActions.set_current_page("assimetria"))}
									>
										Avaliar
									</P2Desk>
								) || (
									<Icon src={ArrowForward} />
								)}
								</AreaCadaResultado>

								<AreaCadaResultado
									onClick={() =>
										dispatch(
											DashBoardActions.set_current_page("relatorio_evolucao")
										)
									}
								>
									<div style={{ display: "flex", alignItems: "center" }}>
										<Icon
											src={EvolutionIcon}
											style={{ marginRight: "0.5rem" }}
										/>
										<P2Desk>Evolução de Força</P2Desk>
									</div>
									<Icon src={ArrowForward} />
								</AreaCadaResultado>

								<AreaCadaResultado
									onClick={() =>
										dispatch(
											DashBoardActions.set_current_page("relatorio_forca_tempo")
										)
									}
								>
									<div style={{ display: "flex", alignItems: "center" }}>
										<Icon src={CurvasIcon} style={{ marginRight: "0.5rem" }} />
										<P2Desk>Curvas de Força</P2Desk>
									</div>
									<Icon src={ArrowForward} />
								</AreaCadaResultado>
							</>
						)}

						{/* Anamnese  */}

						<AreaResultadosButton
							style={
								resultado_selecionado == "anamnese"
									? { backgroundColor: "#DEDFE3" }
									: { backgroundColor: "transparent" }
							}
							onClick={() => {
								if (verifyAnamneses()) {
									ChangeAreaResultado("anamnese");
									dispatch(
										DashBoardActions.set_current_page("relatorio_anamnese")
									);
								}
							}}
						>
							<div style={{ display: "flex", alignItems: "center" }}>
								<Icon
									src={verifyAnamneses() ? VerifiedIcon : BlockedIcon}
									style={{ marginRight: "0.5rem" }}
								/>
								<P2Desk
									style={
										execucoes.length > 0
											? { color: "#000E4B" }
											: { color: "#ABADB7" }
									}
								>
									Anamnese
								</P2Desk>
							</div>
							<div style={{ display: "flex", alignItems: "center" }}>
								<Icon src={ArrowForwardClosed} />
							</div>
						</AreaResultadosButton>

						{/* Anamnese  */}

						<AreaResultadosButton
							style={
								resultado_selecionado == "qualidade_vida"
									? { backgroundColor: "#DEDFE3" }
									: { backgroundColor: "transparent" }
							}
							onClick={() => {
								if (verifyQualityOfLife()) {
									ChangeAreaResultado("qualidade_vida");
									dispatch(
										DashBoardActions.set_current_page(
											"relatorio_qualidade_vida"
										)
									);
								}
							}}
						>
							<div style={{ display: "flex", alignItems: "center" }}>
								<Icon
									src={verifyQualityOfLife() ? VerifiedIcon : BlockedIcon}
									style={{ marginRight: "0.5rem" }}
								/>
								<P2Desk
									style={
										execucoes.length > 0
											? { color: "#000E4B" }
											: { color: "#ABADB7" }
									}
								>
									Qualidade de vida
								</P2Desk>
							</div>
							<div style={{ display: "flex", alignItems: "center" }}>
								<Icon src={ArrowForwardClosed} />
							</div>
						</AreaResultadosButton>
					</div>
				</>
			)}
		</Conteiner>
		// </ContainerDash>
	);
};

const AreaResultadosButton = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	background-color: #dedfe3;
	padding: 1rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-bottom: 1px solid var(--Neutras-Grey-K-10, #dedfe3);
`;

const AreaCadaResultado = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	padding-left: 2rem;
	border-bottom: 1px solid var(--Neutras-Grey-K-10, #dedfe3);
`;

export default Relatorios;
