import styled, { css } from 'styled-components';
import { Colors } from '../../styles/colors';
import { PopupComponentProps } from './Models';

export const PopupContainer = styled.div<PopupComponentProps>`
    display: ${({visible})=> visible ? 'flex' : 'none'};
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
	padding-top: ${({paddintTop})=> paddintTop ? paddintTop : 0.75}rem;
	padding-bottom: ${({paddingBottom})=> paddingBottom ? paddingBottom : 0.75}rem;
    border-radius: ${({borderRadius})=> borderRadius ?  borderRadius : 0.75}rem ;
    top: ${({top})=> top ? top : 1}rem;
    right: ${({right})=> right ? right : 2}rem;
    position: absolute;
    box-shadow: 0px 2px 4px 0px ${Colors?.neutral_grey_k_70+80};
    background-color: ${Colors?.white};
    
`;
export const ContainerOption = styled.div`
    display: flex;
    width: 100%;
    padding-left:  0.75rem;
	padding-right: 0.75rem;
    padding-top: 0.25rem;
	padding-bottom:  0.25rem;
    cursor: pointer;

    &:hover {
        background-color: ${Colors?.neutral_grey_k_10};
    }
    &:active {
        background-color: ${Colors?.neutral_grey_k_20};
    }
`;

export const OptionPopup = styled.p`
    color: ${Colors.neutral_grey_k_70};
    font-family: "NotoSans-Regular";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 1.5rem */
`;