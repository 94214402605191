import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Editar from "../../../assets/icons/editar.svg";
import Excluir from "../../../assets/icons/excluir.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import Pontos from "../../../assets/icons/pontos.svg";
import Limpar from "../../../assets/icons/limpar.svg";
import Lupa from "../../../assets/icons/lupa.svg";
import LupaFilterProtocol from "../../../assets/icons/lupaFilterProtocol.svg";
import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import {
	PacientesProps,
	UserProps,
} from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { TypesErrorRequest } from "../../../utils/types";
import { 
	ContainerFoto, 
	ContainerGoBack,
	Image, 
	ContainerIcon, 
	ContainerInput, 
	Conteiner, 
	EvaluatorCard, 
	Header, 
	InfoEvaluator, 
	Input, 
	NewEvalutor, 
	Photo, 
	Tag, 
	UserName, 
	ContainerEvaluators,
	NoneEvaluator, 
	Toggle, 
	Edit, 
	Delete, 
	ContainerIconEditToggle, 
	ContainerIconDeleteToggle, 
	TextToggle, 
	Content
} from "./styles";
import Loading from "../Loading";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core";
import { AmplitudeLog } from "../../../utils/amplitude";
import { MudarPagina } from "../../../utils/funcs";
import history from "../../../routes/history";

import {ReactComponent as ArrowBack} from '../../../assets/kinologyIcons/svg/arrow_back.svg';
import {ReactComponent as Search} from '../../../assets/kinologyIcons/svg/search.svg';
import {ReactComponent as Dot} from '../../../assets/kinologyIcons/svg/dotthree.svg';
import {ReactComponent as EditIcon} from '../../../assets/kinologyIcons/svg/edit.svg';
import {ReactComponent as TrashIcon} from '../../../assets/kinologyIcons/svg/trash.svg';

import Text from '../../../components/Texts/Text';
import { Colors } from "../../../styles/colors";

const ListagemAval: React.FC = () => {
	const dispatch = useDispatch();
	const toggleRef = useRef<HTMLDivElement | null>(null);
	const iconRef = useRef<HTMLDivElement | null>(null);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const avaliadores = useSelector((state: any) => state.dashboard.avaliadores);
	const total_avaliadores = useSelector(
		(state: any) => state.dashboard.total_avaliadores
	);

	const numero_de_paginas = Math.ceil(total_avaliadores / 6);

	const pagina_atual = useSelector(
		(state: any) => state.dashboard.current_page_evaluator
	);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const type_toast = useSelector((state: any) => state.app.type_toast);

	const [pulse, setPulse] = useState(false);

	const [inputValue, setInputValue] = useState("");
	const [toogleVisible, setToggleVisible]= useState<number| null>();
	

	const avaliadores_loading = useSelector(
		(state: any) => state.dashboard.avaliadores_loading
	);

	const resetInput = () => {
		dispatch(DashBoardActions.set_search(""));
		setInputValue("");
		setPulse(false);
		Submit("reset");
	};

	const Submit = (event: any) => {
		if (event.key == "Enter" || event.type == "click" || event == "reset") {
			setPulse(false);
			const filtros_paginacao = {
				inicio: 0,
				fim: 6,
				filtros: [
					{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
					{ tipo: "USUARIO_NIVEL", valor: [1, 2] },
				],
			};
			if (inputValue != "" && event != "reset") {
				filtros_paginacao.filtros.push({
					tipo: "USUARIO_NOME",
					valor: inputValue,
				});
			}

			dispatch(
				DashBoardActions.set_filtros_avaliadores_request(filtros_paginacao)
			);
			dispatch(DashBoardActions.avaliadores_request());
		}
	};

	const handleInputChange = (event: any) => {
		setInputValue(event.target.value);
		setPulse(true);
	};

	const handleAvaliadorEditar = (avaliador: any) => {
		dispatch(AppActions.set_open_avaliador(avaliador));
		dispatch(AppActions.set_open_paciente(avaliador));
		dispatch(AppActions.set_modal_editar_avaliador(true));
	};

	const handleAvaliadorExcluir = (parameter: any) => {
		dispatch(
			AppActions.set_modal_excluir({ status: true, parameter: parameter })
		);
	};

	

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (
				toggleRef.current && 
				!toggleRef.current.contains(event.target as Node) &&
				iconRef.current &&
				!iconRef.current.contains(event.target as Node)
			) {
				setToggleVisible(null);
			}
		}

		if (toogleVisible !== null) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [toogleVisible]);

	function _renderAvaliadores(lista: any) {
		return lista.map((row: PacientesProps, ) => (
			<EvaluatorCard key={row?.ID_USUARIO}>
				<ContainerFoto>
					<Photo>
						<Image 
							style={{
								borderRadius: '100%'
							}}
							src={
								row.USUARIO_FOTO != "" &&
								row.USUARIO_FOTO != null &&
								row.USUARIO_FOTO != "img/photo.png" 
								?
									row.USUARIO_FOTO 
								: Perfil
							}
						/>
					</Photo>
				</ContainerFoto>
				<InfoEvaluator>
					<UserName>
						{row.USUARIO_NOME}
					</UserName>
					<Tag>
						{
							row.ID_USUARIO == user_logado?.ID_USUARIO
								? current_language?.dash_voce
								: row.USUARIO_NIVEL == 1
									? "Administrador"
									: row.USUARIO_NIVEL == 2 && "Avaliador"
						}
					</Tag>
				</InfoEvaluator>
				
				<ContainerIcon 
					onClick={()=>setToggleVisible(toogleVisible === row.ID_USUARIO ? null : row.ID_USUARIO)}
					ref={iconRef}
				>
					<Dot 
						color={Colors?.primary_blue_k_main}
						className="DotOptions"
						style={{position: 'relative', zIndex:1}}
					/>
				</ContainerIcon>
				{  toogleVisible === row.ID_USUARIO &&
					<Toggle  ref={toggleRef}>
						<Edit
							onClick={()=> {
								handleAvaliadorEditar(row), 
								AmplitudeLog('editar_avaliador')
							}}
						>
							<ContainerIconEditToggle>
								<EditIcon 
									width={18}
									height={18}
									color={Colors.primary_blue_k_main} 
									className="EditIcon"
								/>
							</ContainerIconEditToggle>
							<TextToggle>{current_language?.editar}</TextToggle>
						</Edit>
						<Delete
							onClick={()=> {
								handleAvaliadorExcluir({ id: row, mode: "avaliador" })
								AmplitudeLog('excluir_avaliador')
							}}
						>
							<ContainerIconDeleteToggle>
								<TrashIcon 	
									width={25}
									height={25}
									color={Colors.primary_blue_k_main} 
									className="DeleteIcon" 
								/>
							</ContainerIconDeleteToggle>
							<TextToggle>{current_language?.excluir}</TextToggle>
						</Delete>
					</Toggle>
				}
				
			</EvaluatorCard>
		));
	}

	const useStyles = makeStyles(() => ({
		root: {
			"& .MuiPagination-ul": {
				justifyContent: "left",
			},
			"& .MuiPaginationItem-root": {
				color: "black",
				"&:hover": {
					backgroundColor: "#000E4B",
					color: "white",
				},
			},
			"& .MuiPagination-outlined": {
				backgroundColor: "#000E4B",
			},
			"& .Mui-selected": {
				backgroundColor: "#000E4B",
				color: "#000E4B",
				"&:hover": {
					color: "black",
				},
			},
		},
	}));

	const classes = useStyles();


	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("avaliador"));
	}, []);

	return (
			<Conteiner id="DivPrincipal">
				<Content>
					<Header>
						<ContainerGoBack 
							onClick={()=> {
								dispatch(DashBoardActions.set_current_page("minha_conta"))
								dispatch(AppActions.set_option_menu(false, current_language?.menu_lateral_minha_conta))
							}}
						>
							<ArrowBack />
							<Text 
								title={current_language.menu_lateral_minha_conta}
								fontFamily="NotoSans-Regular"
								fontSize={0.7}
								fontWeight={400}
								textColor={Colors?.primary_blue_k_main}
								marginLeft={6}
								cursor="pointer"
							/>
						</ContainerGoBack>
						<Text 
							title={current_language?.menu_lateral_avaliadores}
							fontFamily="NotoSans-Regular"
							fontSize={1.5}
							fontWeight={700}
							textColor={Colors?.primary_blue_k_main}
							textAlign="left"
							marginTop={15}
						/>
					</Header>
					<ContainerInput className="input">
						<Search  className={pulse ?  "pulse" : 'none'}/>
						<Input
							onKeyDown={Submit}
							value={inputValue}
							onChange={handleInputChange}
							className="inputPesquisa"
							type="text"
							placeholder={current_language?.dash_pesquisar_evalu}
						/>
					</ContainerInput>
					{is_open_toast && type_toast == TypesErrorRequest.SUCCESS ? (
						<ToastAlert />
					) : (
						""
					)}
					{avaliadores_loading ? (
						<ContainerEvaluators>
							<Loading />
						</ContainerEvaluators>
					) : (
						<ContainerEvaluators>
							{avaliadores.length > 0 ? (
								_renderAvaliadores(avaliadores)
							) : (
								<ContainerEvaluators>
									<NoneEvaluator className="text">
										Nenhum avaliador encontrado.
									</NoneEvaluator>
								</ContainerEvaluators>
							)}
						</ContainerEvaluators>
					)}
					
					<NewEvalutor>
						<Pagination
							onChange={(event, pagina_desejada)=> 
								MudarPagina(
									pagina_desejada,
									numero_de_paginas,
									dispatch,
									[1, 2],
									inputValue
								)
							}
							page={pagina_atual}
							className={classes.root}
							size="large"
							count={numero_de_paginas}
						/>
					</NewEvalutor>
				</Content>
			</Conteiner>
	);
};

export default ListagemAval;
