import styled from "styled-components";
import { Colors } from "../../styles/colors";

interface MenuListProps {
	hasDinoBAttery: boolean;
}

export const Container = styled.div<{menuOpen: boolean}>`
	display: flex;
	background-color:${Colors.white};
	justify-content: ${({menuOpen})=> !menuOpen ? 'center' : 'undefined'};
	padding-top:22px;
	padding-bottom:42px;
	padding-right: 32px;
	width: ${({menuOpen}) => (menuOpen ? '351px' : '130px')};
	transition: width 0.3s ease;
	position: relative;
	z-index: 11;
	height: 90vh;
`;

export const MenuHeader = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-right: 9px;
	position: relative;
`;

export const PerfilName = styled.p<{visible: boolean}>`
	font-family: 'NotoSans-SemiBold';
	font-size: 24px;
	font-weight: 600;
	padding-left: 32px;
	color: ${Colors.neutral_grey_k_80};
	transition: opacity 0.5s ease;
	opacity: ${({visible}) => (visible ? 1 : 0)};
	white-space: nowrap;

	@media screen and (max-width: 1420px) {
		font-size: 16px;
	}
	@media screen and (max-width: 1360px) {
		display: none;
	}
`;




export const LeftBar = styled.div<{index: number | null, HoverIndex: number, optionName: string, selected?: string[] | string }>`
	width: 2px;
	height: 100%;
	background-color: ${({index, HoverIndex, optionName, selected})=> 
		index == HoverIndex 
			?  Colors.primary_blue_k_medium_1 
			: optionName == selected
				? Colors?.primary_blue_k_main
				: 'transparent'
	};
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	transition: border-color 0.3s;
	cursor: pointer;
`;

export const Tooltip = styled.div`
	display: flex;
	min-height: 34px;
	min-width: 75px;
	padding: 5px 10px;
	background-color: ${Colors.primary_blue_k_medium_1};
	border-radius: 10px;
	position: absolute;
	z-index: 20;
	left: 130px;
	margin-top: 10px;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
`;

export const TooltipTitle = styled.p`
	font-family: "NotoSans-SemiBold";
	font-size: 20px;
	color: ${Colors.white};
	font-weight: 600;
`;

export const ContainerIcon = styled.div<{index: number | null, HoverIndex: number, optionName: string, selected?: string[] | string}>`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-left:6px;
	border-radius: 100px;
	background-color: ${({index, HoverIndex, optionName, selected})=> 
		index == HoverIndex 
			?  Colors.primary_blue_k_medium_1 
			: optionName == selected
				? Colors?.primary_blue_k_main
				: 'transparent'
	};
`;
export const ContainerMenuOptions = styled.div`
	padding-left: 20px;
	display: flex;
	flex-direction: column;
	margin-top: 12px;

	&:active ${LeftBar} {
        background-color: ${Colors.primary_blue_k_main};
		opacity: 1;
		visibility: visible ;
    }
	&:active ${ContainerIcon} {
        background-color: ${Colors.primary_blue_k_main};
    }
`;
export const MenuOption = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding-right: 12px;
	cursor: pointer;
`;


export const Icon = styled.img``;

export const TextOption = styled.p<{ menuOpen: boolean}>`
	font-family: "NotoSans-Medium";
	color: ${Colors?.neutral_grey_k_80};
	font-size: 20px;
	font-weight: 500;
	margin-left: 12px;
	display: ${({menuOpen})=> !menuOpen ?  'none' : 'flex' };

	@media screen and (max-width: 1420px) {
		font-size: 16px;
	}
	@media screen and (max-width: 1360px) {
		display: none;
	}
`;

export const ContainerVersion = styled.p<{ menuOpen: boolean}>`
	display: ${({menuOpen})=> !menuOpen ?  'none' : 'flex' };
	align-items: center;
	justify-content: center;
	margin-top: 36px;
	font-family: "NotoSans-Medium";
	color: ${Colors?.neutral_grey_k_80};
	font-size: 20px;
	font-weight: 500;
`;

export const Image = styled.div<{ menuOpen: boolean}>`
	cursor: pointer;
	display: flex;
	position: absolute;
	right: ${({menuOpen})=> !menuOpen ? '25px' : '0px'};
	top: 8px;
	padding: 8px 5px;
	align-items: center;
	justify-content: center;
	background-color:${({menuOpen})=> menuOpen ? Colors?.primary_blue_k_main : 'transparent'};
	border-radius: 20px;
	
`;

export const ContainerText = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 32px;
`;
export const ProgressBarContainer = styled.div`
	display: flex;
	width: 100%;
	height: 20px;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-left: 32px;
	
	@media screen and (max-width: 1360px) {
		display: none;
	}
`;
export const ProgressBar = styled.div`
	width: 100%;
 	height: 5px;
 	/* margin-top: 5px; */
 	border-radius: 20px;
 	background-color: ${Colors?.primary_blue_k_main}; 
`;

export const BackgroundProgress = styled.div<{fill: number | string}>`
	display: flex;
	width: ${({fill})=> fill && fill};
	height: 5px;
	border-radius: 20px;
	background-color: ${Colors?.secondary_green_k_main}; 
`;


export const Text = styled.p`
	font-size: 18px;
	font-family: "NotoSans-Medium" ;
	font-weight: 500;
	margin-top: 14px;
	color: ${Colors?.neutral_grey_k_80};

	@media screen and (max-width: 1420px) {
		font-size: 16px;
	}
	@media screen and (max-width: 1360px) {
		display: none;
	}
`;
export const EvaluatedPatient = styled(Text)`
	font-size: 16px;
`;

export const Transistion = styled.div<{visible?: boolean}>`
	display: ${({visible})=> visible ?  'flex' : 'none'};
	transition: opacity 0.5s ease;
	opacity: ${({visible}) => (visible ? 1 : 0)};
`;


export const Body = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	min-height: 600px;
	background-color: white;
`;

export const Usuario = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4.5px;
	position: relative;

	.imgForInput {
		border: 5px solid #e5e5e5;
		border-radius: 50%;
		height: 7rem;
		width: 7rem;
		object-fit: cover;

		@media only screen and (max-width: 1566px) {
			height: 5rem;
			width: 5rem;
		}
	}
`;

export const Perfil = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;

	height: 12%;

	margin-top: 3rem;

	p {
		color: #4d4d4d;
		font-size: 1.125rem;
		font-weight: 500;

		margin-top: 0.8rem;

		text-align: center;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.ContentEvaluatedPatient{
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100px;
	}
	
	.ContentDinoBattery{
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100px;
	}
	
	.ContentNameAndValue {
		display: flex;
		width: 100%;
		margin-top: 12px;
		flex-direction: row;
	}

	.ContentDinoValue {
		display: flex;
		width: 100%;
		margin-top: 12px;
		flex-direction: row;
	}

	.ServicoPaciente {
		width: 10%;
		font-size: 12px;
		color: #2C3A4B;
		margin-left: 10px;
	}
	.evaluatedPatient {
		width: 90%;
		font-size: 12px;
		color: #2C3A4B;
	}

	.phrase {
		display: flex;
		width: 60%;  
		font-size:12px; 
		height:20px; 	
		color: #2C3A4B;
		margin-left: 15px;
		justify-content: center;
		align-items: center;
		transition: color 0.3s;
		cursor: pointer;

		@media only screen and (max-width: 1567px) {
			display: none;
		}
	};
	.ContentNameAndValue:hover {
    	color: ${Colors.primary_blue_k_main}; 
	}
	
	.phraseDino {
		display: flex;
		width: 100%;  
		font-size:12px; 
		height:20px; 	
		color: #2C3A4B;
		padding-left: 0.5rem;
		justify-content: center;
		align-items: center;
		transition: color 0.3s;
		cursor: pointer;

		@media only screen and (max-width: 1567px) {
			display: none;
		}
	};
	.ContentDinoValue:hover {
    	color: ${Colors.primary_blue_k_main}; 
	}

	.ContentOnlyBattery{
		display: none;

		@media only screen and (max-width: 1567px) {
			cursor: pointer;
			display: flex;
			width: 100%;
			height: 20px;
			align-items: center;
			flex-direction: column;
		}
		
	}

	
	.tooltip {
		display: none; 
		position: absolute;
		background-color: ${Colors.primary_blue_k_dark_2};
		color: #fff;
		padding: 5px;
		border-radius: 5px;
		margin-left: 250px;
		text-align: center;
		width: 200px;
		margin-top: -25px;
		
	}

	.value {
		display: flex;
		width: 30%;
		font-size: 12px;
		color: #2C3A4B;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		transition: color 0.3s;
		cursor: pointer;

		@media only screen and (max-width: 1567px) {
			width: 100%;
		}
	}
	.phrase:hover,
	.value:hover,
	.ContentOnlyBattery:hover,
	.phraseDino:hover,
	.phraseDino.hovered,
	.phrase.hovered,
	.value.hovered,
	.ContentOnlyBattery.hovered {
		color: ${Colors?.primary_blue_k_dark_2};
	}
	
	.value:hover .tooltip {
		@media only screen and (max-width: 1567px) {
			display: block;
		}
	}
	
	.ContentOnlyBattery:hover .tooltip {
		@media only screen and (max-width: 1567px) {
			display: block;
			z-index: 99;
			font-size: 12px;
			font-weight: bold;
			cursor: pointer;
			margin-top: 0.1rem;
		}
	}
	
	.ContentProgressBar{
		display: flex;
		width: 100%;
		height: 20px;
		align-items: center;
		flex-direction: column;

		@media only screen and (max-width: 1567px) {
			width: 100%;
		}
		
	}

	.progressBar {
		width: 90%;
        height: 5px;
		margin-top: 5px;
		border-radius: 20px;
        background-color: #ccc; 
	}

	.background{
        height: 5px;
		border-radius: 20px;
        background-color: ${Colors.secondary_green_k_main}; 
	}

	a {
		text-decoration: none;
		color: ${Colors.primary_blue_k_main};
		font-weight: 500;
	}

	a:hover {
		text-decoration: none;
	}

	@media only screen and (max-height: 885px) {
		margin-top: 2rem;
	}

	@media only screen and (max-width: 1566px) {
		.p {
			display: none;
		}
	}

	@media only screen and (max-width: 1370px) {
		margin-top: 1rem;
	}
`;

export const MenuList = styled.div<MenuListProps>`
	width: 100%;
	height: 40%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
	${(props) =>
		props.hasDinoBAttery && `margin-top: 6.8rem;`}

	p {
		font-size: 1rem;
		font-weight: 500;

		display: flex;
		align-items: center;

		color: #5c5b5b;

		padding-left: 1.188rem;
		transition: all 0.2s linear;
	}

	p:hover {
		color: ${Colors.primary_blue_k_dark_2};
	}

	a {
		text-decoration: none;

		height: 3rem;

		width: 100%;

		transition: all 0.2s linear;

		display: flex;
		align-items: center;

		cursor: pointer;

		padding-left: 2rem;

		div {
			width: 2rem;

			display: flex;
			justify-content: center;
		}

		:hover {
			text-decoration: none;

			border-left: 0.2rem solid ${Colors.primary_blue_k_dark_2};
			color: ${Colors.primary_blue_k_dark_2};
		}

		:hover p {
			color: ${Colors.primary_blue_k_dark_2};
		}

		:hover svg path {
			fill: ${Colors.primary_blue_k_dark_2};
		}

		svg path {
			transition: all 0.2s linear;
		}

		.protocolos {
			display: flex;
			justify-content: flex-end;
		}
	}

	.active {
		p {
			color: ${Colors.primary_blue_k_dark_2};
		}

		svg path {
			fill: ${Colors.primary_blue_k_dark_2};
		}

		border-left: 0.2rem solid ${Colors.primary_blue_k_dark_2};
	}

	@media only screen and (max-height: 885px) {
	${(props) =>
		props.hasDinoBAttery && `margin-top: 6.8rem;`|| `margin-top: 2rem;`}
	}

	@media only screen and (max-height: 750px) {
		${(props) =>
			props.hasDinoBAttery && `margin-top: 6.8rem;`|| `margin-top: 2rem;`}
	}

	@media only screen and (max-width: 1566px) {
		.p {
			display: none;
		}

		a {
			justify-content: center;

			padding-left: 0rem;
		}
	}

	@media only screen and (max-width: 1290px) {
		${(props) =>
			props.hasDinoBAttery && `margin-top: 4.8rem;`|| `margin-top: 2rem;`}
	}

	@media only screen and (max-width: 1370px) and (min-width:1291px) {
		padding-top: 1rem;
		${(props) =>
			props.hasDinoBAttery && `margin-top: 3.8rem;`|| `margin-top: 2rem;`}

		height: 50%;

		a {
			justify-content: center;

			padding-left: 0rem;
		}
	}
`;

export const Footer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 70%;

	height: 23%;

	.bandeira {
		display: flex;
		align-items: center;

		width: 100%;

		margin-bottom: 1rem;

		p {
			margin-left: 0.5rem;

			font-weight: 500;
			font-size: 1.063rem;
		}
	}

	.plano {
		width: 100%;

		font-size: 0.875rem;

		color: #b3b3b3;

		margin-bottom: 1.688rem;
	}

	button {
		padding: 0.75rem 3.5rem;

		border-radius: 0.5rem;
		border: 0;

		border: 1.5px solid #ffffff;

		background: ${Colors.primary_blue_k_main};
		color: #ffffff;

		transition: all 0.2s linear;
	}

	button:hover {
		color: ${Colors.primary_blue_k_dark_2};
		border: 1.5px solid ${Colors.primary_blue_k_dark_2};

		background: #ffffff;
	}

	.sair {
		font-size: 1rem;
		font-weight: 500;
		cursor: pointer;
		color: ${Colors.primary_blue_k_main};
		margin-top: 1.7rem;
	}

	.versao {
		margin-top: 0.5rem;

		color: #b3b3b3;

		font-weight: 500;
		font-size: 0.938rem;
	}

	@media only screen and (max-height: 885px) {
		.bandeira {
			margin-bottom: 0.2rem;
		}

		.plano {
			margin-bottom: 1rem;
		}

		.sair {
			margin-top: 1.3rem;
		}

		.versao {
			margin-top: 0.4rem;
		}
	}

	@media only screen and (max-width: 1566px) {
		.p {
			display: none;
		}

		.sair {
			font-size: 12.5px;
		}
	}

	@media only screen and (max-width: 1370px) {
		.plano {
			font-size: 0.75rem;

			margin-bottom: 1rem;
		}

		.sair {
			font-size: 0.8rem;
			font-weight: 700;
			color: ${Colors.primary_blue_k_main};
			margin-top: 0rem;
		}
	}
`;

export const BatteryContainer = styled.div`
	display: flex;
	width: 100%;
	height: 20px;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	padding-left: 25px;
	
	@media screen and (max-width: 1360px) {
		display: none;
	}
`;

export const ContentBattery = styled.div`
	margin-top: 2rem;
	align-items: center;
	width: 100%;
	display: flex;
`;

export const DinoBatteryText = styled.span`
    width: 12rem;
    font-size: 14px;
`;
