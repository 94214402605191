import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { AvaliationActions } from "../../../store/ducks/avaliation";

import {
	Close,
	Container,
	Subtitle,
	Title,
} from "../../../styles/styles_modais_padroes";
import { P2Desk } from "../../../styles/styles_text";
import { ButtonSave, Icon } from "../../../styles/global";
import Remove from "../../../assets/icons/remove.svg";
import Add from "../../../assets/icons/add.svg";
import styled from "styled-components";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalCofiguracoesExecucaoForcaMin: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	const is_modal_config_forc_min = useSelector(
		(state: any) => state.app.is_modal_config_forc_min
	);

	const max_force = useSelector((state: any) => state.avaliation.max_force);
	const force_option_min = useSelector(
		(state: any) => state.avaliation.force_option_min
	);
	const force_option_min_str = useSelector(
		(state: any) => state.avaliation.force_option_min_str
	);

	const execucoes: any = useSelector((state: any) => state.dashboard.execucoes);

	const atualizacao_proto: any = [];
	execucoes.map((e: any) => {
		atualizacao_proto.push({
			ID_EXERCICIO: e.ID_EXERCICIO,
			EXECUCAO_ATUALIZADA: e.EXECUCAO_ATUALIZADA,
			EXECUCAO_EXECUTADA: e.EXECUCAO_EXECUTADA,
		});
	});

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_config_forc_min(false));
	};

	const aumentar = () => {
		let aumentei = force_option_min + 0.2;
		dispatch(AvaliationActions.set_force_option_min(aumentei));
		if (aumentei >= 199.8) {
			dispatch(AvaliationActions.set_force_option_min(0.2));
			aumentei = 0.2;
		}
		if (max_force) {
			const max = (aumentei * 20) / 100;
			const calc = aumentei >= 199.6 ? 200 : aumentei + max;
			dispatch(AvaliationActions.set_force_option_max(calc));

			let newValueMax = calc?.toFixed(2)?.toString();
			newValueMax = newValueMax?.replace(".", " . ");
			dispatch(AvaliationActions.set_force_option_max_str(newValueMax));
		}
		let newValueMin = aumentei?.toFixed(2)?.toString();
		newValueMin = newValueMin?.replace(".", " . ");
		dispatch(AvaliationActions.set_force_option_min_str(newValueMin));
	};

	const diminuir = () => {
		let diminui = force_option_min - 0.2;
		dispatch(AvaliationActions.set_force_option_min(diminui));
		if (diminui <= 0.2) {
			dispatch(AvaliationActions.set_force_option_min(199.8));
			diminui = 199.8;
		}
		if (max_force) {
			const max = (diminui * 20) / 100;
			const calc = diminui === 199.8 ? 200 : diminui + max;
			dispatch(AvaliationActions.set_force_option_max(calc));

			let newValueMax = calc?.toFixed(2)?.toString();
			newValueMax = newValueMax?.replace(".", " . ");
			dispatch(AvaliationActions.set_force_option_max_str(newValueMax));
		}
		let newValueMin = diminui?.toFixed(2)?.toString();
		newValueMin = newValueMin?.replace(".", " . ");
		dispatch(AvaliationActions.set_force_option_min_str(newValueMin));
	};

	const [valorMinimo, setValorMinimo] = useState(force_option_min);
	const [valorMinimoStr, setValorMinimoStr] = useState(force_option_min_str);

	useEffect(() => {
		setValorMinimo(force_option_min);
		setValorMinimoStr(force_option_min_str);

		if (max_force) {
			const max = (force_option_min * 20) / 100;
			const calc = force_option_min >= 199.6 ? 200 : force_option_min + max;
			dispatch(AvaliationActions.set_force_option_max(calc));

			let newValueMax = calc?.toFixed(2)?.toString();
			newValueMax = newValueMax?.replace(".", " . ");
			dispatch(AvaliationActions.set_force_option_max_str(newValueMax));
		}
	}, [force_option_min, force_option_min_str]);

	useEffect(() => {
		if (max_force === false) {
			dispatch(AvaliationActions.set_force_option_max(force_option_min * 2));
		}
	}, [max_force]);

	// Segurar botão Mínimo Diminui rápido
	const [pressionadoMinDim, setPressionadoMinDim] = useState(false);

	const segurarBotãoMinDim = () => {
		setPressionadoMinDim(true);
	};

	const soltarBotãoMinDim = () => {
		setPressionadoMinDim(false);
	};

	useEffect(() => {
		if (pressionadoMinDim === true) {
			setTimeout(() => {
				diminuir();
			}, 50);
		} else if (pressionadoMinDim === false) {
			return;
		}
	}, [pressionadoMinDim, force_option_min]);

	// Segurar botão Mínimo Aumenta rápido
	const [pressionadoMinAum, setPressionadoMinAum] = useState(false);

	const segurarBotãoMinAum = () => {
		setPressionadoMinAum(true);
	};

	const soltarBotãoMinAum = () => {
		setPressionadoMinAum(false);
	};

	useEffect(() => {
		if (pressionadoMinAum === true) {
			setTimeout(() => {
				aumentar();
			}, 50);
		} else if (pressionadoMinAum === false) {
			return;
		}
	}, [pressionadoMinAum, force_option_min]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_modal_config_forc_min}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_modal_config_forc_min}>
					<Container
						style={{
							justifyContent: "center",
							alignItems: "center",
							height: "25rem",
						}}
					>
						<Close src={buttonClose} onClick={ModalCadastroClose} alt="Close" />

						<Title>Referências de força</Title>
						<Subtitle>Escolha como definir a força mínima:</Subtitle>
						<P2Desk>Força mínima personalizada</P2Desk>

						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginTop: "1rem",
								marginBottom: "1rem"
							}}
						>
							<Botao
								className="buttons"
								onMouseUp={soltarBotãoMinDim}
								onMouseDown={segurarBotãoMinDim}
								onClick={diminuir}
							>
								<Icon src={Remove} />
							</Botao>

							<Span>
								{" "}
								{valorMinimo < 10
									? `00${valorMinimoStr}`
									: valorMinimo < 100
									? `0${valorMinimoStr}`
									: valorMinimoStr}
							</Span>
							<Botao
								className="buttons"
								onMouseUp={soltarBotãoMinAum}
								onMouseDown={segurarBotãoMinAum}
								onClick={aumentar}
							>
								<Icon src={Add} />
							</Botao>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export const Span = styled.div`
	background-color: #f1f2f3;
	padding: 1rem;
	border-radius: 0.5rem;
	align-items: center;
	justify-content: center;
	margin-left: 1rem;
	margin-right: 1rem;
`;

export const Botao = styled.span`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 1rem;
	border: 0;
	background: transparent;
`;

export default ModalCofiguracoesExecucaoForcaMin;
