import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import IconWarning from "../../../assets/icons/status_warning.svg";
import { ContainerModalExcluir } from "../../../styles/styles_modais_excluir";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Icon } from "../../../styles/global";
import Share from "../../../assets/kinologyIcons/svg/share.svg";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import {
	TypeCategoriasAnamnese,
	TypesPermission,
	TypesQuestionnaire,
	TypeTypeAnamnese,
} from "../../../utils/types";
import { getPermission } from "../../../utils/funcs";
import { ServicosProps } from "../../../interfaces/config.interface";
import moment from "moment";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import { ConfigsActions } from "../../../store/ducks/configs";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalEscolhaModo: React.FC = () => {
	const dispatch = useDispatch();
	const classes = useStyles();

	const anamnese_category: TypeCategoriasAnamnese = useSelector(
		(state: any) => state.anamnese.anamnese_category
	);

	const anamnese_type: TypeTypeAnamnese = useSelector(
		(state: any) => state.anamnese.anamnese_type
	);

	const is_open_modal_escolha_modo = useSelector(
		(state: any) => state.app.is_open_modal_escolha_modo
	);

	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const language: any = useSelector((state: any) => state.configs.language);

	const is_open_paciente = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const ModalClose = () => {
		dispatch(AppActions.set_open_modal_escolha_modo(false));
	};

	function shereLinkAnamnese() {
		const authData: any = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			ID_USUARIO: is_open_paciente?.ID_USUARIO,
			txTipo: anamnese_type,
			compartilhamento: "url",
			txIdioma: language?.toLowerCase(),
			//txCategoria: anamnese_category,
			txEmail: null,
		};
		if (authData) {
			dispatch(AvaliationActions.set_shere_anamnese_request(authData));
		}

		if (anamnese_type === TypesQuestionnaire.SF_36) {
			dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.MENU));
		}
		dispatch(AppActions.set_modal_compartilhamento(false, 0));
	}

	const Submit = () => {

		if (anamnese_type === TypesQuestionnaire.SF_36) {
			dispatch(PacAvalActions.set_questionario_request(true));
			dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.SF_36));
			dispatch(PacAvalActions.set_tipo_anamnese("SF_36"));
			dispatch(PacAvalActions.set_id_resposta(null));
		}

		dispatch(DashBoardActions.set_current_page("questionario_novo"));
		ModalClose();
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open_modal_escolha_modo}
			onClose={ModalClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open_modal_escolha_modo}>
				<ContainerModalExcluir>
					<img
						className="close"
						src={buttonClose}
						onClick={ModalClose}
						alt="Close"
					/>
					<div className="title" style={{ marginBottom: "3.75rem" }}>
						<div className="containerFrases">
							<p className="QtdPacientes">Como executar a anamnese?</p>
						</div>
						<p className="text">
							Você pode enviar para seu paciente responder sozinho ou realizar a
							anamnese junto ao paciente.
						</p>
					</div>
					<button
						style={{
							width: "100%",
							marginBottom: "1rem",
						}}
						onClick={shereLinkAnamnese}
						className="button cancelar"
					>
						Enviar para o paciente <Icon src={Share} />
					</button>
					<button
						style={{
							width: "100%",
							backgroundColor: "#000E4B",
							color: "#FAFAFA",
						}}
						className="button cancelar"
						onClick={Submit}
					>
						Fazer com o paciente
					</button>
				</ContainerModalExcluir>
			</Fade>
		</Modal>
	);
};

export default ModalEscolhaModo;
