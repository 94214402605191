import styled from "styled-components";

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.btns {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100% !important;

		.typeReport {
			font-family: Roboto;
			padding-left: 1.37rem;
			padding-right: 1.37rem;
			padding-top: 0.43rem;
			padding-bottom: 0.43rem;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: #b3b3b3;
			border: 0.4px solid #b3b3b3;
			box-sizing: border-box;
			border-radius: 5px;
			padding: 0.5rem;
			margin-right: 0.75rem;
			background-color: transparent;
		}

		.typeReportActive {
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: #00c4ff;
			border: 0.4px solid #00c4ff;
			box-sizing: border-box;
			border-radius: 5px;
			padding: 0.5rem;
			margin-right: 0.75rem;
			background-color: transparent;
		}
	}

	.cards {
		display: grid;
		grid-template-columns: auto auto;
		width: 100%;
		margin-top: 2.18rem;
		margin-bottom: 1rem;

		.card {
			width: 97%;
			padding: 1rem;

			.title {
				font-family: Roboto;
				font-style: italic;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				color: #5c5b5b;
			}

			.chart {
				height: 10rem;
				width: 100%;
			}

			.table {
				width: 100%;
				border-collapse: collapse;
				border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
	
				background:%20var(--Neutras-Grey-K-5,%20#F1F2F3);


				.parameter {
					color: var(--Neutras-Grey-K-70, #3D4259);
					font-family: "NotoSans-Regular";
					font-size: 0.8rem;
					font-style: normal;
					font-weight: 600;
					line-height: 2;
					padding-left: 0.5rem;
				}

				.assimetria {
					font-family: Roboto;
					font-style: normal;
					font-weight: bold;
					font-size: 12px;
					line-height: 14px;
					text-align: justify;
					padding-left: 0.5rem;
				}

				.verde {
					color: #39e664;
				}

				.amarelo {
					color: #ffc530;
				}

				.vermelho {
					color: #ff0000;
				}

				.value {
					font-family: "NotoSans-Regular";
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 14px;
					text-align: justify;
					color: #5c5b5b;
					padding-bottom: 0.3rem;
					padding-top: 0.3rem;
					padding-left: 0.5rem;
				}
			}
		}
	}
`;
