import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
	ContainerDash,
	HeaderProfile,
	Icon,
	ProfilePicture,
	Return,
} from "../../../styles/global";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import { TextsProps } from "../../../database/txt.interface";
import { P2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import ArrowForwardClosed from "../../../assets/icons/arrow_forward_ios_closed.svg";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import Perfil from "../../../assets/icons/perfil.svg";
import { AnamneseActions } from "../../../store/ducks/anamnese";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import moment from "moment";

interface typeObjHistorico {
	DESCRICAO_TIPO: string;
	ID_RESPOSTA: number;
	RESPOSTA_ATUALIZADO: string;
	RESPOSTA_CRIADO: string;
	RESPOSTA_TIPO: string;
}
const ListaHistoricoQualidadeVida: React.FC = () => {
	const relatorio = useSelector(
		(state: any) => state.anamnese.historico_anamnese
	);

	type typeHistorico = "avaliacao" | "exame_fisico";
	const type_historico: typeHistorico = useSelector(
		(state: any) => state.anamnese.type_historico
	);

	const historico = relatorio.sf36;

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const dispatch = useDispatch();

	return (
		<ContainerDash>
			<Return
				onClick={() => {
					dispatch(DashBoardActions.set_current_page("relatorio_anamnese"));
				}}
			>
				<Icon src={ReturnIcon} style={{ width: "1rem", height: "1rem" }} />
				<P3Desk>Anamnese Completa</P3Desk>
			</Return>
			<HeaderProfile>
				<ProfilePicture
					src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
				/>
				<S1Desk style={{ marginLeft: "1rem" }}>{patient.USUARIO_NOME}</S1Desk>
			</HeaderProfile>
			<ContainerList>
				{historico.map((item: typeObjHistorico, index: number) => (
					<AreaCadaResultado
						key={index}
						onClick={() => {
							dispatch(
								PacAvalActions.set_perfil_anamnese_patient(patient?.ID_USUARIO)
							);
							dispatch(AnamneseActions.set_relatorio_qualidade_vida_history(item.ID_RESPOSTA));
							dispatch(DashBoardActions.set_current_page("relatorio_qualidade_vida"));
						}}
					>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<P2Desk>{item.DESCRICAO_TIPO}</P2Desk>
							<Data>Data: {moment(item.RESPOSTA_CRIADO).format("DD/MM/YYYY HH:mm:ss").toString()}</Data>
						</div>
						<Icon src={ArrowForwardClosed} />
					</AreaCadaResultado>
				))}
			</ContainerList>
		</ContainerDash>
	);
};

const AreaCadaResultado = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	padding-left: 2rem;
	border-bottom: 1px solid var(--Neutras-Grey-K-10, #dedfe3);
`;

const Data = styled.div`
	color: var(--Neutras-Grey-K-60, #63677a);
	/* Description-Desk */
	font-family: "NotoSans-Regular";
	font-size: 0.7rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 1.5rem */
`;
const ContainerList = styled.div`
	width: 60%;
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 4px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 4px;
	}
`;

export default ListaHistoricoQualidadeVida;

