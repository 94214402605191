import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 2rem;
	padding-bottom: 5vh;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	border-radius: 1.25rem;

	overflow: hidden;

	.title {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 28px;
		line-height: 120%;
		color: ${Colors.primary_blue_k_dark_2};
		margin-bottom: 2.813rem;
	}
`;

export const Selection = styled.div`
	display: flex;
	justify-content: center;

	border-bottom: 1px solid #9A9A9A;

	.buttons {
		button {
			background-color: transparent;
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 500;
			font-size: 22.4726px;
			line-height: 150%;
			/* identical to box height, or 34px */

			text-align: center;

			color: #404040;

			border: 0;

			padding: 0 3rem;
			border-bottom: 4px solid transparent;

			transition: all .2s linear;
		}

		button.active {
			color: #A21C53;
			border-bottom: 4px solid #A21C53;
		}
	}
`;

export const Content = styled.div`
	width: 100%;
	height: 100%;

	overflow-x: hidden;

	overflow-y: scroll;

	padding-right: 1rem;

	padding-bottom: 3rem;
	padding-top: 3rem;

	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	.divPlano {
		width: 100%;
		background-color: #f8f8f8;
		padding: 1.5rem;
		border-radius: 12px;
		filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
		margin-left: 3px;
		display: flex;
		margin-bottom: 1.5rem;

		.divPlanoDetail {
			width: 80%;
			height: 100%;

			.titleAndPrice {
				display: flex;
				flex-direction: row;
				align-items: center;

				.rs {
					font-family: "Roboto";
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					color: #404040;
					margin-right: 4px;
					margin-top: 7px;
				}

				.titlePlan {
					font-family: "Roboto";
					font-style: normal;
					font-weight: 700;
					font-size: 28px;
					color: ${Colors.primary_blue_k_dark_2};
					margin-right: 0.688rem;
				}

				.price {
					font-family: "Roboto";
					font-style: normal;
					font-weight: 700;
					font-size: 28px;
					color: #404040;
				}
			}

			.detail {
				margin-top: 1.5rem;
				font-family: "Roboto";
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 150%;
				color: #404040;
			}
		}

		.divAssinar {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content:center;
			width: 80%;

			.button {
				width: 15rem;
				height: 55px;
				background-color: ${Colors?.primary_blue_k_main};
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				border: none;
				border-radius: 8px;
				color: #fcfcfc;
				margin-bottom: 1rem;
			}
			.button:hover{
				background-color: ${Colors.primary_blue_k_dark_2};
			}

			.termos {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 150%;
				text-align: center;
				color: #404040;
			}
		}
	}
`;
