import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { TagActions } from "../../../store/ducks/tags";
import { Container, Content } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

interface TagsProps {
	ID_ETIQUETA: number;
	ETIQUETA_NOME: string;
	ID_USUARIOS: [];
}

const ModalExcluirEtiqueta: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const classes = useStyles();
	const is_open_excluir_etiqueta = useSelector(
		(state: any) => state.app.is_open_excluir_etiqueta
	);
	const ModalAddEtiquetaClose = () => {
		dispatch(AppActions.set_modal_excluir_etiqueta(false));
		setAprovado(false);
	};

	const tags = useSelector((state: any) => state.tags.selecteds_tags);

	//Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const [aprovado, setAprovado] = useState(false);

	const excluir = () => {
		setAprovado(true);
		tags?.map((e: TagsProps) => {
			const request = {
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				ID_ETIQUETA: e.ID_ETIQUETA,
			};
			dispatch(TagActions.set_delete_tags(request));
			AmplitudeLog('etiqueta_excluida', {
				body: request
			})
		});
		ModalAddEtiquetaClose();
	};

	useEffect(() => {
		if (is_open_toast) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	useEffect(() => {
		setAprovado(false);
	}, []);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_excluir_etiqueta}
				onClose={ModalAddEtiquetaClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_excluir_etiqueta}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalAddEtiquetaClose}
							alt="Close"
						/>
						<Content>
							<div className="form">
								<div className="cabecalho">
									<p className="title">
										{current_language?.confirm_excluir_tags}
									</p>
									<div className="tags">
										{tags.map((e: TagsProps) => (
											<div key={e.ID_ETIQUETA} className="tag">
												{e.ETIQUETA_NOME}
											</div>
										))}
									</div>
								</div>
								<div className="divButtons">
									<button 
										className="salvar" 
										type="submit" 
										onClick={()=> excluir()}
									>
										{aprovado == true ? (
											<CircularProgress color="inherit" />
										) : (
											current_language?.modal_excluir
										)}
									</button>
									<button
										className="cancelar"
										type="button"
										onClick={()=> {
											ModalAddEtiquetaClose()
											AmplitudeLog('cancelar_delet_etiqueta')
										}}
									>
										{current_language?.botao_cancelar}
									</button>
								</div>
							</div>
						</Content>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalExcluirEtiqueta);
