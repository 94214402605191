import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	padding-right: 4.8rem;
	padding-left: 4.8rem;
	padding-top: 4.8rem;
	padding-bottom: 4.8rem;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 80vh;
	width: 50vw;

	@media only screen and (min-width: 1280px) {
		height: 80vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1440px) {
		height: 80vh;
		width: 45vw;
	}

	@media only screen and (min-width: 1680px) {
		height: 85vh;
		width: 42vw;
	}

	@media only screen and (max-width: 1440px) {
		padding: 3rem 4rem;
	}
	.close {
		width: 1.738rem;

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		cursor: pointer;
	}

	.scroll {
		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 0px;
			height: 0px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 0px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 0px;
		}
	}
`;

export const Content = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	.sup {
		width: 100%;
	}

	.item {
		display: flex;
		font-size: 1rem;
		margin-bottom: 0.8rem;
		color: #404040;

		.pergun {
			font-weight: 700;
			margin-right: 0.5rem;
		}

		.colorFalse {
			opacity: 0.4;
		}

		@media only screen and (max-width: 1440px) {
			margin-bottom: 0.5rem;
		}
	}

	.inputNome {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;

		p {
			margin-bottom: 0.6rem;
			font-size: 1rem;
			font-weight: 700;
		}

		input {
			border: 1px solid #d9d9d9;
			border-radius: 0.4rem;
			height: 2.2rem;
			background-color: #fcfcfc;
			padding: 0 0.6rem;
			font-size: 1rem;
		}
	}

	.radioButtons {
		width: 100%;
		display: flex;
	}

	.radio {
		height: 2rem;
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		width: 50%;

		@media only screen and (max-width: 1440px) {
			margin-bottom: 0.5rem;
		}
	}

	.radioText {
		font-size: 1rem;
	}

	.container {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-color: ${Colors.tertirary_red_1_dark};
	}

	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		top: -5px;
		left: 0;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		border: 2px solid #666666;
		transition: all 0.1s linear;

		font-size: 1rem;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.container input:checked ~ .checkmark {
		background-color: white;
		border: 2px solid ${Colors.secondary_green_k_medium_1};
	}

	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
		transition: all 0.1s linear;
	}

	.container input:checked ~ .checkmark:after {
		display: block;
		background-color: ${Colors.secondary_green_k_medium_1};
		transition: all 0.1s linear;
	}

	.container .checkmark:after {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: white;
		transition: all 0.1s linear;
	}

	.title {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 26px;
		line-height: 30px;
		color: ${Colors.primary_blue_k_dark_2};
		margin-bottom: 2rem;

		@media only screen and (max-width: 1440px) {
			margin-bottom: 0.5rem;
		}
	}

	.divAvaliador {
		display: flex;
		flex-direction: row;
		margin-bottom: 3.25rem;

		.avaliador {
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 23px;
			color: #8c8e86;
		}
	}

	.textArea {
		width: 100%;
		height: 65%;
		max-width: 100%;
		border: 1px solid #b3b3b3;
		box-sizing: border-box;
		border-radius: 5px;
		padding: 0.938rem;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		color: #5c5b5b;

		@media only screen and (min-width: 1280px) {
			height: 40%;
		}
	}

	.limite {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 23px;
		text-align: right;
		color: #5c5b5b;
	}

	.anotacao {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 23px;
		color: #5c5b5b;
		margin-bottom: 1.563rem;
	}

	.inf {
		margin-top: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
	}

	.salve {
		width: 23rem;
		height: 3.625rem;
		min-height: 3rem;

		background-color: ${Colors.primary_blue_k_main};
		color: white;

		font-weight: 700;
		font-size: 1.25rem;

		border: none;
		border-radius: 8px;

		transition: all 0.2s ease;
	}

	.salve:hover {
		background-color: ${Colors.primary_blue_k_dark_2};
	}

	.cancel {
		width: 23rem;
		height: 3.625rem;
		min-height: 3rem;

		background-color: transparent;
		color: ${Colors.primary_blue_k_main};

		font-weight: 700;
		font-size: 1.25rem;

		border: none;
		border-radius: 8px;

		transition: all 0.2s ease;
	}

	.entrarContato {
		display: flex;

		p {
			margin-left: 0.5rem;
			font-size: 0.875rem;
			color: #404040;
		}
	}

	.custom-checkbox input,
	.custom-radio input {
		display: none;
		cursor: pointer;
	}

	.custom-checkbox input + label:before {
		content: "";
		width: 0.8rem;
		height: 0.8rem;
		margin-top: -7px;
		border-radius: 20%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
	}

	.custom-checkbox input:checked + label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: ${Colors.secondary_green_k_medium_1};
		background-position: center;
		padding: 2px;
		border: none;
		cursor: pointer;
	}

	.divhr {
		width: 100%;
		margin-bottom: 1rem;
		margin-top: 0.5vh;

		@media only screen and (max-width: 1440px) {
			margin-bottom: 0.5rem;
		}
	}

	.hr {
		width: 100%;
		margin-top: 0.5vh;
		background-color: #e0e0e0;
		color: #e0e0e0;
		border: none;
		height: 2px;
	}

	.hrcor {
		background-color: ${Colors.tertirary_red_1_dark};
		color: ${Colors.tertirary_red_1_dark};
	}

	.error {
		margin-top: 0.5rem;
		color: ${Colors.tertirary_red_1_dark};
	}

	.divhr2 {
		width: 100%;
		margin-bottom: 3vh;
	}

	.hr2 {
		width: 100%;
		margin-top: 1vh;
		background-color: #e0e0e0;
		color: #e0e0e0;
		border: none;
		height: 2px;
	}

	.hr2cor {
		background-color: ${Colors.tertirary_red_1_dark};
		color: ${Colors.tertirary_red_1_dark};
	}
`;
