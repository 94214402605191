export const countries = [
    'Brasil',
    'Angola',
    'Argélia',
    'Argentina',
    'Austrália',
    'Áustria',
    'Bahamas',
    'Bangladesh',
    'Barbados',
    'Bélgica',
    'Bolívia',
    'Botswana',
    'Bulgária',
    'Cabo Verde',
    'Camboja',
    'Camarões',
    'Canadá',
    'Catar',
    'Chile',
    'China',
    'Chipre',
    'Colômbia',
    'Comores',
    'Coreia do Sul',
    'Costa do Marfim',
    'Costa Rica',
    'Croácia',
    'Cuba',
    'Dinamarca',
    'Dominica',
    'Egito',
    'El Salvador',
    'Eslováquia',
    'Eslovênia',
    'Espanha',
    'Estados Unidos',
    'Estônia',
    'Etiópia',
    'Filipinas',
    'Finlândia',
    'França',
    'Gabão',
    'Gana',
    'Geórgia',
    'Grécia',
    'Guatemala',
    'Guiana',
    'Guiné',
    'Guiné-Bissau',
    'Haiti',
    'Holanda',
    'Honduras',
    'Hungria',
    'Ilhas Maurício',
    'Índia',
    'Indonésia',
    'Irlanda',
    'Islândia',
    'Israel',
    'Itália',
    'Jamaica',
    'Japão',
    'Jordânia',
    'Laos',
    'Letônia',
    'Líbano',
    'Lituânia',
    'Luxemburgo',
    'Macedônia do Norte',
    'Madagascar',
    'Malásia',
    'Malawi',
    'Maldivas',
    'Mali',
    'Malta',
    'Marrocos',
    'Mauritânia',
    'México',
    'Moçambique',
    'Mônaco',
    'Mongólia',
    'Namíbia',
    'Nepal',
    'Nicarágua',
    'Nigéria',
    'Noruega',
    'Nova Zelândia',
    'Panamá',
    'Paquistão',
    'Paraguai',
    'Peru',
    'Polônia',
    'Portugal',
    'Quênia',
    'Reino Unido',
    'República Checa',
    'República Dominicana',
    'Romênia',
    'Ruanda',
    'Rússia',
    'Santa Lúcia',
    'Senegal',
    'Sérvia',
    'Singapura',
    'Síria',
    'Somália',
    'Sri Lanka',
    'Suazilândia',
    'Sudão',
    'Suécia',
    'Suíça',
    'Suriname',
    'Tailândia',
    'Taiwan',
    'Tanzânia',
    'Timor-Leste',
    'Togo',
    'Trinidad e Tobago',
    'Tunísia',
    'Turquia',
    'Ucrânia',
    'Uganda',
    'Uruguai',
    'Vanuatu',
    'Venezuela',
    'Vietnã',
    'Zâmbia',
    'Zimbábue'
];
