import styled from "styled-components";
import { Colors } from "../../../styles/colors";


export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 34px 20px;
    width: 450px;
    /* height: 214px; */
    border-radius: 30px;;
    position: relative;
    background-color: white;
`;

export const Title = styled.p`
    font-family: "NotoSans-SemiBold";
    color: ${Colors.primary_blue_k_main};
    font-size: 28px;
    font-weight: 600 ;
`;
export const ContainerInput = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
export const Option = styled.div`
    display: flex;
    width: 30%;
    flex-direction: column;
    justify-content: center;
    justify-content: center;
    align-items: center;
`;
export const Label = styled.label``;

export const ContainerAddSignature = styled.div<{imageName: string, img: string}>`
    display: flex;
    width: 100%;
    height: 140px;
    margin-top: 32px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    border: 2px solid ${
        ({imageName, img})=> imageName == 'HoverPhoto'
        ? Colors.primary_blue_k_medium_1
        : imageName == 'PressSetPhoto'
            ? Colors?.primary_blue_k_main
            :  imageName ==  'SucessSetPhoto' || img
                ? Colors?.secondary_green_k_medium_1
                : Colors?.neutral_grey_k_10
    } ;
    background-color: #DEDFE320 ;

    .labelForInput {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;