import React, { useEffect, useState } from "react";
import InputSelectSearch from "./InputSelectSearch";

import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../../../store/ducks/app";
import { TypesErrorRequest, TypesPermission } from "../../../../utils/types";
import { getPermission } from "../../../../utils/funcs";
import { ServicosProps } from "../../../../interfaces/config.interface";
import { RelatorioActions } from "../../../../store/ducks/relatorios";
import { TextsProps } from "../../../../database/txt.interface";
import { AssimetriaPros } from "../../../../interfaces/assimetria.interface";
import { PacientesProps } from "../../../../interfaces/pacientes.interface";
import GraficoEvolucaoEquilibrio from "./GraficoEvolucaoDesequilibrio";
import { DashBoardActions } from "../../../../store/ducks/dashboard";
import { Icon } from "../../../../styles/global";
import { P2Desk } from "../../../../styles/styles_text";
import Check from "../../../../assets/kinologyIcons/svg/check.svg";
import ArrowForward from "../../../../assets/icons/arrow_forward_ios.svg";
import ArrowForwardClosed from "../../../../assets/icons/arrow_forward_ios_closed.svg";
import BlockedIcon from "../../../../assets/icons/blocked_icon.svg";
import styled from "styled-components";
import VerifiedIcon from "../../../../assets/icons/verified_icon.svg";
import ArrowForwardDown from "../../../../assets/icons/arrow_forward_down.svg";

const EvolucaoEquilibrio: React.FC = ({}) => {
	const dispatch = useDispatch();
	const config_servicos_array: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const assimetrias: AssimetriaPros[] = useSelector(
		(state: any) => state.dashboard.assimetria
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const assimetria: any = assimetrias.filter(
		(ass) => ass.ID_USUARIO == patient.ID_USUARIO
	);

	const desequilibrios_object: any = useSelector(
		(state: any) => state.dashboard.desequilibrio
	);

	const desequilibrios_array: any = Object.values(desequilibrios_object);

	const LoadHistoricoAssimetria = () => {
		const objets_to_array = Object.values(assimetria[0]?.LISTAS);
		const arrayUnico = objets_to_array.flat();

		if (arrayUnico.length > 0) {
			!getPermission(
				config_servicos_array,
				TypesPermission.SERVICO_REL_ASSIMETRIA,
				dispatch
			)
				? dispatch(AppActions.set_modal_upgrade_funcionalidade(true))
				: dispatch(RelatorioActions.set_exercicios_historicos(arrayUnico));
		} else if (arrayUnico.length == 0) {
			dispatch(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: current_language?.sem_historico_assimetria_grupamento,
				})
			);
		}
	};

	useEffect(() => {
		LoadHistoricoAssimetria();
	}, []);

	const [regiao_selecionada, set_regiao_selecionada] = useState("");

	return (
		<>
			{desequilibrios_array.map((item: any, index: number) => (
				<>
					<AreaCadaResultado
						key={index}
						onClick={() =>
							item?.REFERENCIAS[0]?.EXECUCOES[0]?.DATA &&
							(regiao_selecionada === item.TITULO
								? set_regiao_selecionada("")
								: set_regiao_selecionada(item.TITULO))
						}
					>
						<div style={{ display: "flex", alignItems: "center" }}>
							<Icon
								src={
									item?.REFERENCIAS[0]?.EXECUCOES[0]?.DATA
										? VerifiedIcon
										: BlockedIcon
								}
								style={{ marginRight: "0.5rem" }}
							/>
							<P2Desk>{item?.TITULO}</P2Desk>
						</div>
						<Icon
							src={
								regiao_selecionada == item.TITULO
									? ArrowForward
									: ArrowForwardClosed
							}
						/>
					</AreaCadaResultado>
					<div
						style={{
							display: regiao_selecionada == item.TITULO ? "flex" : "none",
							width: "100%"
						}}
					>
						<GraficoEvolucaoEquilibrio desequilibrio={item} />
					</div>
				</>
			))}
		</>
	);
};

const AreaCadaResultado = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	padding-left: 2rem;
	border-bottom: 1px solid var(--Neutras-Grey-K-10, #dedfe3);
`;

export default EvolucaoEquilibrio;

