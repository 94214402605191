import React, { useState } from "react";
import {
	ButtonAdd,
	ContainerDash,
	DropDown,
	Icon,
	MenuOption,
} from "../../../styles/global";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ExpandIcon from "../../../assets/icons/expand.svg";
import TutorialDefault from "../../../assets/tutorial_default.png";
import AddIcon from "../../../assets/icons/adicionar_paci.svg";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import MenuIcon from "../../../assets/icons/3points.svg";
import DeleteIcon from "../../../assets/icons/delete.svg";
import EditIcon from "../../../assets/icons/editIcon.svg";
import { TextsProps } from "../../../database/txt.interface";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { ExerciciosActions } from "../../../store/ducks/exer";
import { AppActions } from "../../../store/ducks/app";
import { tutorialImages } from "../../../utils/tutorials";

const ListagemExercicios: React.FC = () => {
	const dispatch = useDispatch();

	const exercises_list: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.search_exercise
	);

	const [controler_menu, set_controler_menu] = useState(0);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	return (
		<ContainerDash>
			<ListContainer>
				{exercises_list.map((exercicio: Exercicios2Props, index: number) => (
					<ExerciseSection key={index}>
						<TitleAndDescription>
							<ExerciseName>{exercicio.EXERCICIO_TITULO_PT}</ExerciseName>
							<Description>
								{exercicio.ID_USUARIO == 0
									? current_language?.exercicio_kinology
									: current_language?.exercicio_personalizado}
							</Description>
						</TitleAndDescription>
						{exercicio.ID_USUARIO == 0 ? (

							<>
							<TutorialSection
								onClick={() =>
									dispatch(AppActions.set_modal_tutorial(true, exercicio))
								}
							>
								{tutorialImages[exercicio.EXERCICIO_TITULO_PT] && tutorialImages[exercicio.EXERCICIO_TITULO_PT][0] && (
									<>
										<Icon
											style={{ 
												width: "8rem",
												height: "100%",
												objectFit: "cover",
												borderTopRightRadius: "15px",
												borderBottomRightRadius: "15px",
												marginRight: "7px"
											}}
											src={tutorialImages[exercicio.EXERCICIO_TITULO_PT][0]}
										/>
										<Icon
											src={ExpandIcon}
											style={{
												width: "1.5rem",
												height: "1.5rem",
												marginLeft: "-2rem",
												marginTop: "0.5rem",
											}}
										/>
									</>
								)}
							</TutorialSection>
							</>
						) : (
							<DropDown
								style={{ margin: "1rem" }}
								active={controler_menu == exercicio.ID_EXERCICIO ? true : false}
							>
								<div className="dropdown">
									<Icon
										style={{ width: "1rem", height: "1rem" }}
										src={MenuIcon}
										onClick={(event) => {
											event.stopPropagation(),
												set_controler_menu(
													controler_menu != exercicio?.ID_EXERCICIO
														? exercicio?.ID_EXERCICIO
														: 0
												);
										}}
									/>
									<div className="dropdown-content">
										<MenuOption
											onClick={() =>
												dispatch(
													ExerciciosActions.set_open_modal_excluir_exercicio(
														true,
														exercicio
													)
												)
											}
										>
											<Icon src={DeleteIcon} /> {current_language?.excluir}
										</MenuOption>
										<MenuOption
											onClick={() => {
												dispatch(
													ExerciciosActions.set_open_modal_editar_exercicio(
														true,
														exercicio
													)
												);
											}}
										>
											<Icon src={EditIcon} /> {current_language?.editar}
										</MenuOption>
									</div>
								</div>
							</DropDown>
						)}
					</ExerciseSection>
				))}
			</ListContainer>
			<PaginationAndAddButton>
				<ButtonAdd
					onClick={() =>
						dispatch(ManageProtocolActions.set_open_modal_add_protocolo(true))
					}
					active={false}
				>
					<Icon style={{ width: "1.6rem", height: "1.6rem" }} src={AddIcon} />
					{current_language?.adicionar_protocolo}
				</ButtonAdd>
			</PaginationAndAddButton>
		</ContainerDash>
	);
};

const ListContainer = styled.div`
	width: 60%;
	height: 65vh;
	margin-bottom: 1.5rem;
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const ExerciseSection = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border: 2px solid #dedfe3;
	border-radius: 18px;
	margin-bottom: 1rem;
	height: 8rem;
`;

const TitleAndDescription = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1rem;
`;

const ExerciseName = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1.1rem;
	font-weight: 600;
	color: #323d6e;
`;

const Description = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-weight: 600;
	line-height: 42px;
	color: #00a377;
`;

const TutorialSection = styled.div`
	display: flex;
	max-height: 100%;
`;

const PaginationAndAddButton = styled.div`
	position: fixed;
	bottom: 0;
	width: 56%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding-bottom: 1.5rem;
	padding-top: 0.5rem;
	background-color: #fafafa;
`;

export default ListagemExercicios;
