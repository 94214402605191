import React from 'react';

import { useDispatch } from 'react-redux';
import { AppActions } from '../../store/ducks/app';

import KinologyLogo from '../../assets/kinologyIcons/svg/dashBoardLogo.svg';
import Notifications from '../../assets/kinologyIcons/svg/notifications.svg';

import {SuperiorBar, Image} from './styled';

const BarSuperior: React.FC = ()=> {
    const dispatch = useDispatch();
    return (
        <SuperiorBar>
            <Image src={KinologyLogo} alt="" />
            
            <Image 
                src={Notifications} 
                onClick={()=>{dispatch(AppActions.set_modal_notificacoes(true))}} 
                style={{
                    width: 25, 
                    height: 25,
										cursor: 'pointer'
                }}
            /> 
        </SuperiorBar>
    )
};

export default BarSuperior ;