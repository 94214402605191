import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BtnInformacao from "../../components/BtnInformacao";

import ToastAlert from "../../components/toast";
import { TextsProps } from "../../database/txt.interface";
import { PacientesProps } from "../../interfaces/pacientes.interface";
import { AvaliationActions } from "../../store/ducks/avaliation";
import { DashBoardActions } from "../../store/ducks/dashboard";
import { PacAvalActions } from "../../store/ducks/manage_pac_aval";
import { AppActions } from "../../store/ducks/app";
import {
	TypesBtnInfo,
	TypesMenuInfoProps,
	TypesQuestionnaire,
	TypesQuestionnaireAnamneseTipo,
} from "../../utils/types";

import Questionario_progress from "./Questionario_progress";
import {
	ContainerGoBack,
	ContainerInfoPatient,
	Conteiner,
	Content,
	ContentType,
	HeaderContent,
	PageName,
	Title,
	Image,
	ContainerImage,
	PatientName,
	ContainerTags,
	Tag,
	TagName,
	ContainerDot,
	ContainerInfoEvaluator,
	InfoEvaluator,
	ShowInfo,
	ContainerOptions,
	Option,
	TextOption,
	ContainerOptionsButtonsQuestionari,
	ButtonOptionQuetionary,
	TitleButton,
	ContainerOptionCard,
	CardOption,
	ContaierInfos,
} from "./styles";
import EditUser from "../../assets/icons/editUser.svg";
import DeletUser from "../../assets/icons/deletUser.svg";
import { ReactComponent as ArrowBack } from "../../assets/kinologyIcons/svg/arrow_back.svg";
import { ReactComponent as UserWithoutPhoto } from "../../assets/kinologyIcons/svg/patient.svg";
import { ReactComponent as Dot3 } from "../../assets/kinologyIcons/svg/dotthree.svg";
import { ReactComponent as Prancheta } from "../../assets/kinologyIcons/svg/prancheta.svg";
import { ReactComponent as Cardiology } from "../../assets/kinologyIcons/svg/cardiology.svg";
import { ReactComponent as UserRay } from "../../assets/kinologyIcons/svg/userRay.svg";
import { ReactComponent as Man } from "../../assets/kinologyIcons/svg/man.svg";
import { ReactComponent as Hand } from "../../assets/kinologyIcons/svg/hand.svg";
import { ReactComponent as Note } from "../../assets/kinologyIcons/svg/Edit_note.svg";
import { ReactComponent as Arrow } from "../../assets/kinologyIcons/svg/arrow_forward_ios.svg";
import { ReactComponent as Chart } from "../../assets/kinologyIcons/svg/Chart.svg";
import { ReactComponent as Search } from "../../assets/kinologyIcons/svg/SearchPlan.svg";
import { ReactComponent as EditAnote } from "../../assets/kinologyIcons/svg/Edit_note.svg";
import ReturnIcon from "../../assets/icons/back_page.svg";
import { ManageProtocolActions } from "../../store/ducks/manage_protocols";
import { AmplitudeLog } from "../../utils/amplitude";
import { Colors } from "../../styles/colors";
import { getEtiquetas } from "../../utils/etiquetas";
import { title } from "process";
import Popup from "../../components/PopupComponent";
import Relatorios from "../Relatorios";
import { ContainerDash, Icon, Return } from "../../styles/global";
import { P3Desk } from "../../styles/styles_text";
import { AnamneseActions } from "../../store/ducks/anamnese";
import ModalSatisfactionReport from "../../components/Modais/ModalSatisfactionReport";
import { RelatorioActions } from "../../store/ducks/relatorios";

enum typeSelected {
	information = "informacoes",
	superiors = "superiores",
	protocols = "protocolos",
	avaliacoes = "avaliações",
	resultados = "resultados",
}
interface QuestionaryOptionsProps {
	title: string;
	icon: ReactElement;
	novely: boolean;
	action: () => void;
}
interface CardOptionsProps extends Partial<QuestionaryOptionsProps> {
	subtitle: string;
	type: string;
}
type RenderComponentsType = {
	avaliações: React.FC;
	resultados: React.FC;
	informacoes?: React.FC;
	superiores?: React.FC;
	protocolos?: React.FC;
};

const Paciente: React.FC = () => {
	const dispatch = useDispatch();
	const botaoRef = useRef<HTMLButtonElement>(null);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const OptionSelected = useSelector(
		(state: any) => state.app.set_selected_options_page_pattient
	);

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);

	const patients_most_accessed = useSelector(
		(state: any) => state.dashboard.pacientes_mais_visitados.LISTA
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const pacientes_loading = useSelector(
		(state: any) => state.dashboard.pacientes_loading
	);

	const historicos_questionarios = useSelector(
		(state: any) => state.pac_aval.perfil_anamnese_array
	);

	const etis = useSelector((state: any) => state.dashboard.etiquetas);
	const lista_filtro = useSelector((state: any) => state.app.lista_filtro);

	const [current_patient, set_current_patient]: any = useState(null);
	const [popup, setPopup] = useState(false);

	const avaliadores = useSelector((state: any) => state.dashboard.avaliadores);

	const data_last_emission_report = useSelector((state:any)=> state.relatorios.last_issued_report)

	const avaliador = avaliadores.filter(
		(item: PacientesProps) => item?.ID_USUARIO === pacientes[0]?.USUARIO_AVALIADOR
	)[0];
	/**
	 *
	 * @returns Retorna os dados atualizados do paciente esteja ele na lista principal ou lista dos mais acessados
	 */
	function GetPatientInOrigin() {
		const patient_is_on_the_main_list = pacientes.filter(
			(item: PacientesProps) => item?.ID_USUARIO == paciente?.ID_USUARIO
		);
		let patient_is_on_the_most_accessed_list = undefined;
		if (patients_most_accessed) {
			patient_is_on_the_most_accessed_list = patients_most_accessed.filter(
				(item: PacientesProps) => item?.ID_USUARIO == paciente?.ID_USUARIO
			);
		}
		return patient_is_on_the_main_list[0] != undefined
			? patient_is_on_the_main_list[0]
			: patient_is_on_the_most_accessed_list[0];
	};

	useEffect(()=>{
		dispatch(RelatorioActions.sync_last_report_emission())
	},[])
	
	useEffect(()=>{
		if(data_last_emission_report?.ID_LOG_RELATORIO != undefined){
			dispatch(
				DashBoardActions.set_questionario_satisfacao_verificar(
					data_last_emission_report?.ID_LOG_RELATORIO,
					"RELATORIO_UNIFICADO"
				)
			);
		}
	},[data_last_emission_report])
	

	useEffect(() => {
		set_current_patient(GetPatientInOrigin);
	}, [pacientes_loading, pacientes, patients_most_accessed, current_patient]);

	useEffect(() => {
		const dataForAmplitude: Partial<PacientesProps> = {
			ID_USUARIO: current_patient?.ID_USUARIO,
			USUARIO_ATUALIZADO: current_patient?.USUARIO_ATUALIZADO,
			USUARIO_AVALIADOR: current_patient?.USUARIO_AVALIADOR,
			USUARIO_CONDICIONAMENTO: current_patient?.USUARIO_CONDICIONAMENTO,
			USUARIO_CRIADO: current_patient?.USUARIO_CRIADO,
			USUARIO_NOME: current_patient?.USUARIO_NOME,
			USUARIO_SEXO: current_patient?.USUARIO_SEXO,
			USUARIO_RESPONSAVEL: current_patient?.USUARIO_RESPONSAVEL,
		};
		if (OptionSelected === typeSelected.information) {
			setTimeout(() => {
				AmplitudeLog("info_pacientes");
			}, 200);
		}
		if (OptionSelected === typeSelected.protocols) {
			AmplitudeLog("executar_protocolo", {
				body: dataForAmplitude,
			});
		}
		if (OptionSelected === typeSelected.superiors) {
			AmplitudeLog("executar_exercicio", {
				body: dataForAmplitude,
			});
		}
	}, [OptionSelected]);

	const handleActiveClick = (active: string) => {
		dispatch(AppActions.set_selected_options_page_patients(active));
		dispatch(DashBoardActions.set_select_info(TypesMenuInfoProps.BTN_MENU));
		dispatch(ManageProtocolActions.set_button_goback(false));
	};

	const questionario = useSelector((state: any) => state.pac_aval.questionario);

	const handlePacienteEditar = (current_patient: any) => {
		// dispatch(AppActions.set_modal_editar_paciente(true));
		dispatch(AppActions.set_open_paciente(current_patient));
		dispatch(DashBoardActions.set_current_page("informacoes_paciente"));
	};

	const handleOpenExcluir = (current_patient: any) => {
		const obj = {
			mode: "paciente",
			id: current_patient,
		};

		dispatch(AppActions.set_modal_excluir({ status: true, parameter: obj }));
	};

	useEffect(() => {
		dispatch(DashBoardActions.execucoes_request(false));
		dispatch(DashBoardActions.assimetria_request());
		dispatch(DashBoardActions.anamneses_request());
		dispatch(AnamneseActions.get_historico_anamnese_request());
		dispatch(AvaliationActions.set_force_option_max(3.6));
		dispatch(AvaliationActions.set_force_option_max_str("3 . 60"));

		dispatch(AvaliationActions.set_force_option_min(3));
		dispatch(AvaliationActions.set_force_option_min_str("3 . 00"));
		dispatch(DashBoardActions.anotacoes_request());
		dispatch(DashBoardActions.exercicios_avaliados_request());
		dispatch(
			AnamneseActions.get_relatorio_anamnese_request({
				ANAMNESE: {
					ID_RESPOSTA: 0,
					RESPOSTA_TIPO: "",
				},
				ANAMNESE_CLINICO: {
					ID_RESPOSTA: 0,
					RESPOSTA_TIPO: "",
				},
			})
		);
		
	}, []);

	const [pacient, setPacient]: any = useState([]);

	//Faz calulo da idade
	const dataAtual = new Date();
	const anoAtual = dataAtual.getFullYear();
	const anoNascParts = current_patient?.USUARIO_NASCIMENTO?.split("-");

	let diaNasc = null;
	let mesNasc = null;
	let anoNasc = null;
	let idade = null;

	if (anoNascParts) {
		diaNasc = anoNascParts[2];
		mesNasc = anoNascParts[1];
		anoNasc = anoNascParts[0];
		idade = anoAtual - anoNasc;
		const mesAtual = dataAtual.getMonth() + 1;
		if (mesAtual < mesNasc) {
			idade--;
		} else {
			if (mesAtual === mesNasc) {
				if (new Date().getDate() < diaNasc) {
					idade--;
				}
			}
		}
	}

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	useEffect(() => {
		dispatch(DashBoardActions.exercices_request());
		dispatch(DashBoardActions.protocolos_request());
	}, []);

	useEffect(() => {
		AmplitudeLog("perfil_paciente", {
			body: {
				current_patient: current_patient?.ID_USUARIO,
			},
		});
	}, []);

	useEffect(() => {
		dispatch(PacAvalActions.set_questionario(""));
	}, []);

	const select_questionnaire = (quest: string) => {
		if (questionario === TypesQuestionnaire.ANAMNESE) {
			dispatch(PacAvalActions.set_tipo_anamnese(quest));
			dispatch(PacAvalActions.set_questionario_request(true));
		}
		if (questionario === TypesQuestionnaire.ANAMNESE_FISICA) {
			dispatch(PacAvalActions.set_tipo_anamnese(quest));
			dispatch(PacAvalActions.set_questionario_request(true));
			dispatch(
				PacAvalActions.set_questionario(TypesQuestionnaire.ANAMNESE_AVALIACAO)
			);
		}
	};

	const execucoes: any = useSelector((state: any) => state.dashboard.execucoes);

	const formatIMC = (weigth: string, heigth: string) => {
		return (
			parseFloat(weigth) /
			(parseFloat(heigth.replace(",", ".")) *
				parseFloat(heigth.replace(",", ".")))
		).toFixed(2);
	};

	const assimetria = useSelector((state: any) => state.dashboard.assimetria);

	const CALCULO_IQ = assimetria[0]?.CALCULO_IQ || [];

	function openModalShereAnamnese(typeAnamnese: string) {
		if (questionario != "ANAMNESE_FISICA") {
			dispatch(AppActions.set_modal_compartilhamento(true, 10));
			dispatch(AppActions.set_continue_compartilhamento("ShereAnamnese"));
			select_questionnaire(typeAnamnese);
		} else {
			select_questionnaire(typeAnamnese);
		}
	}

	useEffect(() => {
		botaoRef?.current?.focus();
	}, []);

	function GetLastAvaliation() {
		if (execucoes.length > 0) {
			return execucoes[0].EXECUCAO_EXECUTADA.slice(0, 10)
				.replace(/-/g, "/")
				.split("/")
				.reverse()
				.join("/");
		} else {
			return "-";
		}
	}


	function OpenSF36() {
		dispatch(AnamneseActions.set_anamnese_type("SF_36"));
		dispatch(AnamneseActions.set_anamnese_category("SF_36"));

		if(historicos_questionarios.length > 0 
			&& historicos_questionarios?.historico_sf_36[0] 
			&& historicos_questionarios?.historico_sf_36[0]?.QUESTIONARIO_RESPOSTA_CONCLUIDO) 
		{
			dispatch(
				AppActions.set_modal_continue_or_to_generate_new_anamnese(
					true,
					TypesQuestionnaire.ANAMNESE
				)
			)
		} else {
			dispatch(AppActions.set_open_modal_escolha_modo(true));
		}
	}

	const Options = [
		{
			title: current_language?.avaliacoes,
			action: () => {
				handleActiveClick(typeSelected.avaliacoes);
			},
		},
		{
			title: current_language?.resultados,
			action: () => {
				handleActiveClick(typeSelected.resultados);
			},
		},
	];

	const QuestionaryOptionsButtons: QuestionaryOptionsProps[] = [
		{
			title: current_language?.anamnese,
			icon: <Prancheta color={Colors?.primary_blue_k_main} />,
			novely: true,
			action: () => {
				dispatch(DashBoardActions.set_current_page("anamnese"));
			},
		},
		{
			title: "SF-36",
			icon: <UserRay color={Colors?.primary_blue_k_main} />,
			novely: true,
			action: () => {OpenSF36()},
		},
	];
	const CardOptions: CardOptionsProps[] = [
		{
			type: current_language?.avaliar,
			icon: <Man />,
			title: current_language?.forca_assimetria,
			subtitle: current_language?.avalie_corpo_todo_dinamometro,
			action: () =>
				dispatch(DashBoardActions.set_current_page("protocols_in_patient")),
		},
		// {
		// 	type: current_language?.avaliar,
		// 	icon: <Hand />,
		// 	title: current_language?.prensao_palmar,
		// 	subtitle: current_language?.avalie_prensao_palmar_hand_grip,
		// 	action: () => {},
		// },
		{
			type: current_language?.avaliar,
			icon: <Note />,
			title: "Evolução do paciente",
			subtitle: "Faça e consulte anotações sobre a evolução do paciente",
			action: () => dispatch(DashBoardActions.set_current_page("listagem_anotacoes")),
		},
	];
	const PopupOptions = [
		{
			title: current_language?.editar_paciente,
			action: () => {
				handlePacienteEditar(current_patient);
				AmplitudeLog("editar_paciente");
			},
		},
		{
			title: current_language?.editar_etiquetas,
			action: () => {
				dispatch(AppActions.set_modal_atribuir_etiqueta(true));
			},
		},
		{
			title: current_language?.atribuir_avaliador,
			action: () => {
				dispatch(AppActions.set_lista_pacientes([current_patient?.ID_USUARIO]));
				dispatch(AppActions.set_modal_atribuir_avaliador(true));
			},
		},
		{
			title: current_language?.excluir_paciente,
			action: () => {
				handleOpenExcluir(current_patient);
				AmplitudeLog("excluir_paciente");
			},
		},
	];
	const CardOptionsResults = [
		{
			icon: <Chart />,
			type: current_language?.visualizar_gerar,
			title: current_language?.relatorios_home,
			subtitle: current_language?.consulte_gere_relatorio,
			action: () => dispatch(DashBoardActions.set_current_page("relatorios")),
		},
		{
			icon: <Search />,
			type: current_language?.visualizar,
			title: current_language?.prontuario,
			subtitle: current_language?.informacoes_cadastro,
			action: () => {},
		},
		{
			icon: <EditAnote />,
			type: current_language?.anotar,
			title: current_language?.evolucao_paciente,
			subtitle: current_language?.consulte_anotacoes,
			action: () =>
				dispatch(DashBoardActions.set_current_page("listagem_anotacoes")),
		},
	];

	//UI
	return (
		<ContainerDash id="DivPrincipal">
			{is_open_toast && <ToastAlert />}
			{questionario === TypesQuestionnaire.ANAMNESE ||
			questionario === TypesQuestionnaire.ANAMNESE_FISICA ? (
				<>
					<ContentType>
						<Title>{current_language?.escolha_anamnese}</Title>
						<div className="btns_type">
							<BtnInformacao
								Icon={TypesBtnInfo.BTN_SAUDE}
								Texto={current_language?.saude}
								funcao={() => {
									openModalShereAnamnese(TypesQuestionnaireAnamneseTipo.SAUDE);
									AmplitudeLog(
										questionario === TypesQuestionnaire.ANAMNESE_FISICA
											? "anamnese_fisica_saude"
											: "anamnese_saude"
									);
								}}
							/>
							<BtnInformacao
								Icon={TypesBtnInfo.BTN_REABILITACAO}
								Texto={current_language?.reabilitacao}
								funcao={() => {
									openModalShereAnamnese(
										TypesQuestionnaireAnamneseTipo.REABILITACAO
									);
									AmplitudeLog(
										questionario === TypesQuestionnaire.ANAMNESE_FISICA
											? "anamnese_fisica_reabilitacao"
											: "anamnese_reabilitacao"
									);
								}}
							/>
							<BtnInformacao
								Icon={TypesBtnInfo.BTN_ESPORTIVA}
								Texto={current_language?.esportiva}
								funcao={() => {
									openModalShereAnamnese(
										TypesQuestionnaireAnamneseTipo.ESPORTIVO
									);
									AmplitudeLog(
										questionario === TypesQuestionnaire.ANAMNESE_FISICA
											? "anamnese_fisica_esportiva"
											: "anamnese_esportiva"
									);
								}}
							/>
							<BtnInformacao
								Icon={TypesBtnInfo.BTN_ESTETICA}
								Texto={current_language?.estetica}
								funcao={() => {
									openModalShereAnamnese(
										TypesQuestionnaireAnamneseTipo.ESTETICO
									);
									AmplitudeLog(
										questionario === TypesQuestionnaire.ANAMNESE_FISICA
											? "anamnese_fisica_estetica"
											: "anamnese_estetica"
									);
								}}
							/>
						</div>
					</ContentType>
				</>
			) : questionario === TypesQuestionnaire.ANAMNESE_TIPO ? (
				<Questionario_progress
					key={TypesQuestionnaire.ANAMNESE}
					Type={TypesQuestionnaire.ANAMNESE}
				/>
			) : questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO ? (
				<Questionario_progress
					key={TypesQuestionnaire.ANAMNESE_AVALIACAO}
					Type={TypesQuestionnaire.ANAMNESE_AVALIACAO}
				/>
			) : questionario === TypesQuestionnaire.SF_36 ? (
				<Questionario_progress
					key={TypesQuestionnaire.SF_36}
					Type={TypesQuestionnaire.SF_36}
				/>
			) : (
				<Content>
					<HeaderContent>
						<Return
							onClick={() =>
								dispatch(DashBoardActions.set_current_page("pacientes"))
							}
						>
							<Icon
								src={ReturnIcon}
								style={{ width: "1rem", height: "1rem" }}
							/>
							<P3Desk>{current_language?.area_paciente}</P3Desk>
						</Return>
						<ContainerInfoPatient>
							<ContainerImage>
								{current_patient?.USUARIO_FOTO != "" &&
								current_patient?.USUARIO_FOTO != null &&
								current_patient?.USUARIO_FOTO != "img/photo.png" ? (
									<Image src={current_patient?.USUARIO_FOTO} />
								) : (
									<UserWithoutPhoto color={Colors?.white} />
								)}
							</ContainerImage>
							<PatientName>
								{current_patient?.USUARIO_NOME?.length >= 25
									? current_patient?.USUARIO_NOME?.slice(0, 25) + "..."
									: current_patient?.USUARIO_NOME}
							</PatientName>
							<ContainerTags>
								{getEtiquetas(paciente, etis, lista_filtro).map(
									(e: any, index: number) =>
										index <= 2 ? (
											<Tag key={e.ID_ETIQUETA}>
												<TagName>
													{e.ETIQUETA_NOME?.length >= 13 &&
													current_patient?.USUARIO_NOME?.length >= 15 &&
													getEtiquetas(paciente, etis, lista_filtro)?.length > 2
														? e.ETIQUETA_NOME?.slice(0, 13) + "..."
														: e.ETIQUETA_NOME}
												</TagName>
											</Tag>
										) : (
											index === 3 && (
												<Tag key={e.ID_ETIQUETA}>
													<TagName>...</TagName>
												</Tag>
											)
										)
								)}
							</ContainerTags>
							<ContainerDot onClick={() => setPopup(!popup)}></ContainerDot>
							<Popup
								visible={popup}
								options={PopupOptions}
								onPress={() => setPopup(false)}
							/>
						</ContainerInfoPatient>
						<ContainerInfoEvaluator>
							<InfoEvaluator>{`${current_language?.pag_inicial_avaliador}: ${
						!!avaliador?.USUARIO_NOME ? avaliador?.USUARIO_NOME : "-"
					}`}</InfoEvaluator>
							<ShowInfo onClick={() => handlePacienteEditar(current_patient)}>
								{current_language?.ver_informacao}
							</ShowInfo>
						</ContainerInfoEvaluator>
					</HeaderContent>
					<ContainerOptions>
						{Options?.map(
							(item: { title: string; action: () => void }, index: number) => (
								<Option
									key={index}
									onClick={() => item?.action?.()}
									OptionSelected={OptionSelected}
									title={item?.title}
								>
									<TextOption className="selectTitle">{item?.title}</TextOption>
								</Option>
							)
						)}
					</ContainerOptions>
					{OptionSelected === typeSelected.avaliacoes && (
						<>
							<ContainerOptionsButtonsQuestionari>
								{QuestionaryOptionsButtons?.map((item, index) => (
									<ButtonOptionQuetionary onClick={item.action} key={index}>
										{item?.icon}
										<TitleButton>{item?.title}</TitleButton>
									</ButtonOptionQuetionary>
								))}
							</ContainerOptionsButtonsQuestionari>
							<ContainerOptionCard>
								{CardOptions?.map((item, index) => (
									<CardOption
										key={index}
										index={index}
										width={50}
										onClick={item.action}
									>
										{item?.icon}
										<ContaierInfos>
											<p className="type">{item?.type}</p>
											<p className="title">{item?.title}</p>
											<p className="subtitle">{item?.subtitle}</p>
										</ContaierInfos>
										<Arrow color={Colors?.neutral_grey_k_20} />
									</CardOption>
								))}
							</ContainerOptionCard>
						</>
					)}
					{OptionSelected === typeSelected.resultados && (
						<>
							<Relatorios />
							{/* <ContainerOptionCard>
								{CardOptionsResults?.map((item, index) => (
									<CardOption
										key={index}
										index={index}
										width={50}
										onClick={item.action}
									>
										<ContaierInfos align="center" width={100}>
											{item?.icon}
											<p className="type">{item?.type}</p>
											<p className="title">{item?.title}</p>
											<p className="subtitle">{item?.subtitle}</p>
										</ContaierInfos>
									</CardOption>
								))}
							</ContainerOptionCard> */}
						</>
					)}
				</Content>
			)}
			<ModalSatisfactionReport />
		</ContainerDash>
	);
};

export default Paciente;
