import React, { memo } from "react";
import styled from "styled-components";
import AlterarSenha from "../../MenueConta/AlterarSenha";
import CentralDeAjuda from "../../MenueConta/CentralDeAjuda";
import CentralDeAjudaItem from "../../MenueConta/CentralDeAjudaItem";
import EditarMetodoPagamento from "../../MenueConta/EditarMetodoPagamento";
import EditarPerfil from "../../MenueConta/EditarPerfil";
import Fatura from "../../MenueConta/Faturas";
import MetodoPagamento from "../../MenueConta/MetodoPagamento";
import Upgrade from "../../MenueConta/Upgrade";
import Confirmacao from "../../MenueConta/Upgrade/Confirmacao";
import Paciente from "../../Paciente";
import Listagens from "../Listagens";
import Assinatura from "../../MenueConta/Assinatura";
import { Content } from "./styles";
import EditTagPage from "../../EditTag";

interface iListagem {
	listagem: string;
}

const ContentDash: React.FC<iListagem> = (props: any) => {
	const Group = styled.div`
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	`;

	const Container = styled.div`
		display: flex;
		flex-direction: column;
		width: 100vw;
		/* width: 100%; */
		height: 90vh;
		position: relative;
		padding: 20px 20px;
		/* padding: 20px 20px; */
		/* background-color: aqua; */
	`;

	return (
		<>
			<Container>
				<Group>
					<Content>
						{props.listagem === "home" && <Listagens />}
						{props.listagem === "paciente" && <Paciente />}
						{props.listagem === "alterarSenha" && <AlterarSenha />}
						{props.listagem === "assinatura" && <Assinatura />}
						{props.listagem === "editarPerfil" && <EditarPerfil />}
						{props.listagem === "editarMetodoPagamento" && (<EditarMetodoPagamento />	)}
						{props.listagem === "metodoPagamento" && <MetodoPagamento />}
						{props.listagem === "fatura" && <Fatura />}
						{props.listagem === "edit_etiquetas" && <EditTagPage />}
						{props.listagem === "centralAjuda" && <CentralDeAjuda />}
						{props.listagem === "centralAjudaItem" && <CentralDeAjudaItem />}
						{props.listagem === "upgrade" && <Upgrade />}
						{props.listagem === "confirmacao" && <Confirmacao />}
					</Content>
				</Group>
			</Container>
		</>
	);
};

export default memo(ContentDash);
