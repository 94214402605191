import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonClose.svg";
import success from "../../../assets/icons/success.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalDuvidaExecucaoResultado: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	const is_open_duvida_execucao_resultado = useSelector(
		(state: any) => state.app.is_open_duvida_execucao_resultado
	);

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_open_duvida_execucao_resultado(false));
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_duvida_execucao_resultado}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_duvida_execucao_resultado}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<div className="card">
							<div className="divText">
								<p className="text">
									<svg
										width="18"
										height="18"
										viewBox="0 0 18 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M18 0L18 3L-1.31134e-07 3L0 -7.86805e-07L18 0ZM9 5.25L-7.86805e-07 18L18 18L9 5.25Z"
											fill="#5C5B5B"
										/>
									</svg>
									{current_language?.execucao_force_max}
								</p>
								<p className="subText">
									{current_language?.modal_duvida_execucao_resultado}
								</p>
							</div>
							<div className="divText">
								<p className="text">
									<svg
										width="26"
										height="28"
										viewBox="0 0 26 28"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M6.05556 7.16667H19.9444V11.3333L25.5 5.77778L19.9444 0.222229V4.3889H3.27778V12.7222H6.05556V7.16667ZM19.9444 21.0556H6.05556V16.8889L0.5 22.4445L6.05556 28V23.8333H22.7222V15.5H19.9444V21.0556Z"
											fill="#5C5B5B"
										/>
										<path
											d="M13.4069 12.0665L14.4128 10.3282H15.7311L14.1149 12.9307L15.7848 15.6114H14.4762L13.4215 13.8047L12.3717 15.6114H11.0534L12.7233 12.9307L11.112 10.3282H12.4206L13.4069 12.0665Z"
											fill="#5C5B5B"
										/>
									</svg>
									{current_language?.config_aval_repeticoes}
								</p>
								<p className="subText">
									{current_language?.modal_duvida_execucao_resultado2}
								</p>
							</div>
							<div className="divText">
								<p className="text">
									<svg
										width="20"
										height="18"
										viewBox="0 0 20 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M0 15.3387C0 15.3387 1.11087 4.08967 4.4435 0L9.99787 0.999918L8.88699 4.08967H6.66525V11.2491H7.77612C9.99787 8.17933 14.5969 7.05942 17.3741 8.17933C21.04 9.70921 20.7067 14.3188 17.3741 16.3587C14.708 17.9985 6.66525 19.4284 0 15.3387Z"
											fill="#5C5B5B"
										/>
									</svg>

									{current_language?.execucao_force_media}
								</p>
								<p className="subText">
									{current_language?.modal_duvida_execucao_resultado3}
								</p>
							</div>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalDuvidaExecucaoResultado;
