import React, { useEffect } from "react";
import {
	ButtonSave,
	Checkbox,
	ContainerDash,
	Icon,
	ProfilePicture,
	Return,
} from "../../../styles/global";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import CheckboxIcon from "../../../assets/icons/checkbox.svg";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { P2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import { useDispatch, useSelector } from "react-redux";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import styled from "styled-components";
import { RelatorioActions } from "../../../store/ducks/relatorios";

const SelecaoExercicios: React.FC = () => {
	const dispatch = useDispatch();

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const current_selected_exercises: any = useSelector(
		(state: any) => state.relatorios.current_selected_exercises
	);

	const exercicios_avaliados = useSelector(
		(state: any) => state.exercicios.exercicios_avaliados.exercicios_executados
	);

	const tipo_execucao = "a";

	const uniques_exercicios_avaliados: any = [];
	const ids = new Set();

	exercicios_avaliados.forEach((item: any) => {
			if (!ids.has(item.ID_EXERCICIO)) {
				ids.add(item.ID_EXERCICIO);
				uniques_exercicios_avaliados.push(item);
			}
	});

	function AddExercisesSelected(id_exercicio: number) {
		let aux_current_selected_exercises = current_selected_exercises;
		const exists = current_selected_exercises.includes(id_exercicio);

		if (exists) {
			aux_current_selected_exercises = aux_current_selected_exercises.filter(
				(key: number) => key != id_exercicio
			);
		} else {
			aux_current_selected_exercises = [
				...aux_current_selected_exercises,
				id_exercicio,
			];
		}
		dispatch(
			RelatorioActions.current_selected_exercises(
				aux_current_selected_exercises
			)
		);
	}

	function SelectAll() {
		const aux_current_selected_exercises: any = [];
		uniques_exercicios_avaliados.map((exercicio: any) => {
			aux_current_selected_exercises.push(exercicio.ID_EXERCICIO);
		});
		dispatch(
			RelatorioActions.current_selected_exercises(
				aux_current_selected_exercises
			)
		);
	}

	useEffect(() => {
		SelectAll();
	}, []);

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("perfil_paciente"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "1rem", height: "1rem" }} />
				<P3Desk>Informações do paciente</P3Desk>
			</Return>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "1rem",
					marginBottom: "3rem",
					width: "60%",
				}}
			>
				<ProfilePicture
					src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
				/>
				<S1Desk style={{ marginLeft: "1rem" }}>{patient.USUARIO_NOME}</S1Desk>
			</div>
			<div style={{ width: "60%" }}>
				<P2Desk style={{ marginBottom: "1rem" }}>
					Selecione os exercícios que deseja visualizar no relatório:
				</P2Desk>
				<div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
					{current_selected_exercises.length > 0 ? (
						<P2Desk
							style={{ marginBottom: "1rem", color: "#D12600" }}
							onClick={() => {
								dispatch(RelatorioActions.current_selected_exercises([]));
							}}
						>
							Cancelar seleção ({current_selected_exercises.length})
						</P2Desk>
					) : (
						<P2Desk
							onClick={() => SelectAll()}
							style={{ marginBottom: "1rem" }}
						>
							Selecionar tudo ({uniques_exercicios_avaliados.length})
						</P2Desk>
					)}
				</div>
			</div>
			<WhiteContainer>
				{uniques_exercicios_avaliados.map((exercicio: any, index: number) => (
					<Option key={index}>
						<P2Desk>{exercicio.EXERCICIO_TITULO_PT}</P2Desk>
						<Checkbox
							id={exercicio.ID_EXERCICIO}
							type="checkbox"
							src={CheckboxIcon}
							checked={current_selected_exercises.includes(
								exercicio.ID_EXERCICIO
							)}
							onClick={() => {
								AddExercisesSelected(exercicio.ID_EXERCICIO);
							}}
						/>
					</Option>
				))}
			</WhiteContainer>
			<div style={{ width: "60%" }}>
				<ButtonSave
					onClick={() =>
						current_selected_exercises.length > 0 &&
						dispatch(DashBoardActions.set_current_page("selecao_periodo"))
					}
					active={current_selected_exercises.length > 0 ? true : false}
				>
					Avançar
				</ButtonSave>
				<ButtonSave
					onClick={() =>
						dispatch(DashBoardActions.set_current_page("emissao_relatorios"))
					}
					style={{
						marginTop: "1rem",
						marginBottom: "1rem",
						backgroundColor: "transparent",
						border: "2px solid #000e4b",
						color: "#000e4b",
					}}
					active={true}
				>
					Voltar
				</ButtonSave>
			</div>
		</ContainerDash>
	);
};

const WhiteContainer = styled.div`
	width: 60%;
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;

	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const Option = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	border-bottom: 1px solid var(--Neutras-Grey-K-10, #dedfe3);
`;
export default SelecaoExercicios;

