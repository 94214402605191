import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { TextsProps } from "../../../database/txt.interface";
import { UserProps } from "../../../interfaces/pacientes.interface";
import history from "../../../routes/history";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { AppActions } from "../../../store/ducks/app";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { Container } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import { MudarPagina } from "../../../utils/funcs";
import { persistStorage } from "../../../utils/base_async_storage";
import IconWarning from "../../../assets/icons/status_warning.svg";
import { TagActions } from "../../../store/ducks/tags";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

enum excluir {
	patient = "paciente",
	evaluator = "avaliador",
	protocol = "protocolo",
	exercise = "exercicio",
	PaymentMethod = "MetodoPagamento",
}

const ModalExcluir: React.FC = () => {
	const dispatch = useDispatch();
	const classes = useStyles();

	// comunica com redux
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const selected_patients = useSelector(
		(state: any) => state.pac_aval.selected_patients
	);

	interface ExcluirProps {
		mode: string;
		id: any;
	}

	// função que abre a modal, mais sobre ela em store/ducks/app.ts
	const parametros = useSelector((state: any) => state.app.is_open_excluir);

	const status = parametros?.status;
	const parameter = parametros.parameter;

	// função para fechar modal
	const [aprovado, setAprovado] = useState(false);
	const ModalCadastroClose = () => {
		if (parameter?.mode === excluir.evaluator) {
			AmplitudeLog("fechar_modal_excluir_avaliador");
		}
		if (parameter?.mode === excluir.exercise) {
			AmplitudeLog("exclusao_exercicio_cancelada");
		}
		if (parameter?.mode === excluir.protocol) {
			AmplitudeLog("cancelar_excluir_protocolo");
		}
		if (parameter?.mode === excluir.patient) {
			AmplitudeLog("cancelar_excluir_perfil_paciente");
		}
		setAprovado(false);
		dispatch(AppActions.set_modal_excluir({ status: false, parameter: null }));
	};

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);
	//Funcão para excluir
	const total_pacientes = useSelector(
		(state: any) => state.dashboard.total_pacientes
	);
	const pagina_desejada = useSelector(
		(state: any) => state.dashboard.number_pages_listagem
	);

	const numero_de_paginas = Math.ceil(total_pacientes / 6);

	const Excluir = () => {
		MudarPagina(pagina_desejada, numero_de_paginas, dispatch, [3]);

		setAprovado(true);
		//Caso paciente
		if (parameter?.mode === "excluir_pacientes") {
			AmplitudeLog("perfil_paciente_excluido", {
				body: {
					txDataHora: moment(new Date())
						.format("YYYY-MM-DD HH:mm:ss")
						.toString(),
					ID_USUARIO: parameter?.data,
				},
			});

			dispatch(AddPacAvalActions.request_desativar_paci(parameter?.data));
		}
		if (parameter?.mode === "paciente") {
			AmplitudeLog("perfil_paciente_excluido", {
				body: {
					txDataHora: moment(new Date())
						.format("YYYY-MM-DD HH:mm:ss")
						.toString(),
					ID_USUARIO: parameter?.id.ID_USUARIO,
				},
			});
			dispatch(
				AddPacAvalActions.request_desativar_paci({
					"0": {
						TOKEN: user_logado.token,
					},
					"1": {
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						ID_USUARIO: parameter?.id.ID_USUARIO,
					},
				})
			);
			persistStorage({
				res: {
					"0": {
						TOKEN: user_logado.token,
					},
					"1": {
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						ID_USUARIO: parameter?.id.ID_USUARIO,
					},
				},
				tag: "undo_action_patient_delete",
			});
			setTimeout(() => {
				history.push("/dashboard");
			}, 200);
		}
		if (parameter?.mode === "avaliador") {
			AmplitudeLog("avaliador_excluido", {
				body: {
					txDataHora: moment(new Date())
						.format("YYYY-MM-DD HH:mm:ss")
						.toString(),
					ID_USUARIO: parameter?.id.ID_USUARIO,
				},
			});
			dispatch(
				AddPacAvalActions.request_desativar_paci({
					"0": {
						TOKEN: user_logado.token,
					},
					"1": {
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						ID_USUARIO: parameter?.id.ID_USUARIO,
					},
				})
			);
		}
		//Caso protocolo
		if (parameter?.mode === "protocolo") {
			AmplitudeLog("protocolo_excluido");
			dispatch(
				ManageProtocolActions.delete_proto_request({
					txDataHora: moment(new Date())
						.format("YYYY-MM-DD HH:mm:ss")
						.toString(),
					ID_PROTOCOLO: [parameter.id],
				})
			);
			history.push("/protocolos");
		}
		//Caso exercicio
		if (parameter?.mode === "exercicio") {
			AmplitudeLog("exercicio_excluido", {
				body: {
					txDataHora: moment(new Date())
						.format("YYYY-MM-DD HH:mm:ss")
						.toString(),
					ID_EXERCICIO: [parameter.id],
				},
			});
			dispatch(
				ManageProtocolActions.delete_exer_request({
					txDataHora: moment(new Date())
						.format("YYYY-MM-DD HH:mm:ss")
						.toString(),
					ID_EXERCICIO: [parameter.id],
				})
			);
		}
		//Caso metodo de pagamento
		if (parameter?.mode === "MetodoPagamento") {
			//Excluir MetodoPagamento aqui. Numero do cartão -> (parameter.id)
		}
		if(parameter?.mode ==='removePaciEtiqueta'){
			dispatch(TagActions.set_remove_paci_in_tag_request(parameter)) 
		}
		ModalCadastroClose();
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	useEffect(() => {
		if (is_open_toast) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	return (
		<div>
			{/* librarie para abrir o modal */}
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={!!status}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={!!status}>
					{/* modal com as infos, feito html e css */}
					<Container >
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<img className="icon_warning" src={IconWarning} alt="" />
						<div className="Content">
						{parameter?.mode === "removePaciEtiqueta" ? (
								<b>
									Deseja excluir a etiqueta?
								</b>
							) :
							parameter?.mode === "avaliador" ? (
								<b>
									{current_language?.excluir} {parameter?.id?.USUARIO_NOME}?
								</b>
							) : parameter?.mode === "paciente" ? (
								<div>
									<div className="containerFrases">
										<p className="DesejaRemover">
											{current_language?.deseja_remover}
										</p>
										<p className="QtdPacientes">
											{1} {current_language?.paciente} ?
										</p>
									</div>
									<b className="">
										{current_language?.se_desejar_voce_pode_recuperar}
									</b>
								</div>
							) : parameter?.mode === "protocolo" ? (
								<b>{current_language?.excluir_protocolo}</b>
							) : parameter?.mode ==='removePaciEtiqueta' ?(
								<div className="ContainerTitle">
									<b className="RemovePaciEtiqueta">{current_language?.remover_paciente_etiqueta}</b>
								</div>
							) : parameter?.mode === "exercicio" ? (
								<b>{current_language?.excluir_exercicio_pergunta}</b>
							) : parameter?.mode === "MetodoPagamento" ? (
								<b>{current_language?.excluir_metodo_pagamento}</b>
							) : (
								parameter?.mode == "excluir_pacientes" && (
									<>
										<div className="containerFrases">
											<p className="QtdPacientes">
												{current_language?.deseja_remover}{" "}
												{Object.keys(parameter?.data).length > 1
													? Object.keys(parameter?.data).length - 1
													: 1}{" "}
												{current_language?.paciente}(s) ?
											</p>
										</div>
										<p className="text">
											{current_language?.se_desejar_voce_pode_recuperar}
										</p>
									</>
								)
							)}
						</div>
						<div className="buttons">
							<button onClick={Excluir} className="button excluir">
								{aprovado == true ? (
									<CircularProgress color="inherit" />
								) : (
									current_language?.modal_excluir
								)}
							</button>
							<button className="button cancelar" onClick={ModalCadastroClose}>
								{current_language?.pagamento_cancelar}
							</button>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalExcluir;
