/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";

import { ServicosProps } from "../../../interfaces/config.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission } from "../../../utils/types";

import { ConfigsActions } from "../../../store/ducks/configs";
import history from "../../../routes/history";
import {
	ContainerDash,
	HeaderProfile,
	Icon,
	ProfilePicture,
	Return,
} from "../../../styles/global";
import { P1Desk, P2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import styled from "styled-components";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import GraficoOutrosAcpectos from "./GraficoOutrosAspectos";
import SpectrumLight from "./SpectrumLight";
import { Dot, Spectrum } from "./styles";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import PdfIcon from "../../../assets/kinologyIcons/svg/pdf.svg";
import { TextsProps } from "../../../database/txt.interface";

const QualidadeDeVida: React.FC = () => {
	const dispatch = useDispatch();
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const dor = useSelector(
		(state: any) =>
			state.pac_aval.perfil_anamnese_array.qualidade_vida?.FATORES[2]
	);

	const [permission, setPermission] = useState(true);

	const navigationUpgrade = () => {
		history.push("/upgrade");
	};

	useEffect(() => {
		const permissionAsymmetry: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_ASSIMETRIA
		);
		if (!permissionAsymmetry) {
			setPermission(false);
			dispatch(ConfigsActions.set_title_modal_upgrade(true));
		} else {
			dispatch(ConfigsActions.set_title_modal_upgrade(false));
		}
	}, []);

	const barras = [
		{ height: 1, backgroundColor: "#49DD40" },
		{ height: 2, backgroundColor: "#80F279" },
		{ height: 3, backgroundColor: "#80F279" },
		{ height: 4, backgroundColor: "#FFD580" },
		{ height: 5, backgroundColor: "#FFD580" },
		{ height: 6, backgroundColor: "#FFC530" },
		{ height: 7, backgroundColor: "#E59D45" },
		{ height: 8, backgroundColor: "#E58873" },
		{ height: 9, backgroundColor: "#E58873" },
		{ height: 10, backgroundColor: "#D12600" },
	];

	const _renderAspectosDor = () => {
		return (
			<div>
				<div
					style={{
						width: "100%",
						height: "6rem",
						marginTop: "1rem",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "end",
					}}
				>
					{barras.map((item: any, index: number) => (
						<Barra
							key={index}
							style={{
								height: item.height.toString() + "0%",
								backgroundColor:
									dor.INDICATIVO_VALOR >= item.height
										? item.backgroundColor
										: "#ABADB7",
							}}
						/>
					))}
				</div>

				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "end",
					}}
				>
					{barras.map((item: any, index: number) => (
						<NumberBarra
							style={{ width: "6%", textAlign: "center" }}
							key={index}
						>
							{item.height + "0"}
						</NumberBarra>
					))}
				</div>

				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "end",
						marginTop: "1rem",
					}}
				>
					<ComOuSemProblemas>
						Sem <br /> Problemas
					</ComOuSemProblemas>
					<ComOuSemProblemas>
						Problemas <br /> Incapacitantes
					</ComOuSemProblemas>
				</div>
			</div>
		);
	};

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("perfil_paciente"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "1rem", height: "1rem" }} />
				<P3Desk>{current_language?.area_paciente}</P3Desk>
			</Return>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "1rem",
					marginBottom: "3rem",
					width: "60%",
					justifyContent: "space-between",
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<ProfilePicture
						src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
					/>
					<S1Desk style={{ marginLeft: "1rem" }}>{patient.USUARIO_NOME}</S1Desk>
				</div>
				<Icon
					style={{ width: "2rem", height: "2rem" }}
					onClick={() => {
						dispatch(DashBoardActions.set_current_page("emissao_relatorios"));
						dispatch(
							RelatorioActions.set_current_selected_reports(["QUALIDADE_DE_VIDA"])
						);
					}}
					src={PdfIcon}
				/>
			</div>

			<div style={{ width: "60%", marginBottom: "1rem" }}>
				<UltimaAlteracao
					onClick={() =>
						dispatch(
							DashBoardActions.set_current_page(
								"lista_historico_qualidade_vida"
							)
						)
					}
				>
					Histórico de Questionários
				</UltimaAlteracao>
			</div>

			<WhiteContainer style={{}}>
				<P2Desk>Questionário SF-36</P2Desk>
				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						marginTop: "1rem",
						justifyContent: "space-between",
					}}
				>
					<div
						style={{
							width: "45%",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<P1Desk>Aspectos de Dor</P1Desk>
						<P2Desk style={{ marginTop: "1rem" }}>Resultado do SF-36</P2Desk>
						<Dor>Dor: {dor?.FATOR_VALOR}</Dor>

						{_renderAspectosDor()}
					</div>
					<div
						style={{
							width: "45%",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<P1Desk>Outros Aspectos</P1Desk>
						<Legenda style={{ marginTop: "1rem" }}>
							Legenda: Quanto mais próximo de 0, pior.
						</Legenda>

						<div
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								marginTop: "1rem",
								marginBottom: "0.5rem",
							}}
						>
							{barras.map((item: any, index: number) => (
								<NumberBarra
									style={{
										width: "10%",
										textAlign: "center",
										color: "#797D8D",
									}}
									key={index}
								>
									{item.height + "0"}
								</NumberBarra>
							))}
						</div>

						<Spectrum>
							{barras.map((item: any, index: number) => (
								<Dot
									key={index}
									style={{ backgroundColor: item.backgroundColor }}
								></Dot>
							))}
						</Spectrum>
						<div
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
							}}
						>
							<ComOuSemProblemas>
								Problemas <br /> Incapacitantes
							</ComOuSemProblemas>
							<ComOuSemProblemas>
								Sem <br /> Problemas
							</ComOuSemProblemas>
						</div>

						<div
							style={{
								backgroundColor: "#DEDFE3",
								height: "3px",
								marginTop: "1rem",
								marginBottom: "1rem",
							}}
						></div>
						<P1Desk>Outros Aspectos</P1Desk>
						<GraficoOutrosAcpectos />
					</div>
				</div>
			</WhiteContainer>
		</ContainerDash>
	);
};

const WhiteContainer = styled.div`
	background-color: white;
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
	position: relative;
	z-index: 2;

	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const UltimaAlteracao = styled.text`
	color: var(--Neutras-Grey-K-50, #797d8d);
	text-align: center;
	/* Description-Desk */
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: underline;
`;

const Dor = styled.text`
	color: var(--Neutras-Grey-K-70, #3d4259);
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	margin-top: 1rem;
`;

const Barra = styled.div`
	width: 6%;
	height: 100%;
	border-radius: 0.43188rem;
`;
const NumberBarra = styled.text`
	color: #353439;
	text-align: center;
	font-family: "NotoSans-Regular";
	font-size: 0.7rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.054rem;
`;

const ComOuSemProblemas = styled.text`
	color: var(--Neutras-Grey-K-60, #63677a);
	font-family: "NotoSans-Regular";
	font-size: 0.7rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 1.5rem */
`;

const Legenda = styled.text`
	color: var(--Neutras-Grey-K-50, #797d8d);
	font-family: "NotoSans-Regular";
	font-size: 0.7rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 1.5rem */
`;

export default QualidadeDeVida;

