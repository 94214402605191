import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	height: 100vh;
	display: flex;
	align-items: stretch;
	min-height: 800px;
	max-height: 100vh;
	color: rgba(140, 142, 134, 0, 8);
	justify-content: center;
	overflow: hidden;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 50%;
	background-color: white;

	.containerTitles {
		display: flex;
		margin-top: 60px;
		width: 70%;
		flex-direction: column;
	}
	.logo {
		margin-top: 80px;
		width: 300px;
	}

	h1 {
		color: #8c8e86;
		width: 60%;
		font-size: 26px;
		text-align: center;
		font-weight: 5;
		margin-bottom: 8vh;
	}
	.global {
		display: flex;
		flex-direction: row;
		margin-top: 70px;
	}
	.TextIcon{
		color: ${Colors?.primary_blue_k_main};
		font-family: "NotoSans-Medium";
		font-size: 22px
	}
	.inputIA {
		border: none;
		margin-top: 1vh;
		color: ${Colors?.primary_blue_k_main};
	}
	.idiomaAjuda {
		display: flex;
		flex-direction: column;
		color: ${Colors?.primary_blue_k_main};
	}


	form {
		width: 70%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.globalInput {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.input {
		width: 100%;
		margin-left: 0.5vw;
		background-color: white;
		color: #8c8e86;
		font-weight: 500;
		border: none;
	}

	.icon {
		margin-bottom: 5px;
	}

	.eyeButton {
		background-color: white;
		border: none;
	}

	.divhr {
		width: 100%;
		margin-bottom: 7vh;
		margin-top: 0.5vh;
	}

	.hr {
		width: 100%;
		margin-top: 0.5vh;
		background-color: #e0e0e0;
		color: #e0e0e0;
		border: none;
		height: 2px;
	}

	.hrcor {
		background-color: ${Colors.tertirary_red_1_dark};
		color: ${Colors.tertirary_red_1_dark};
	}

	.error {
		margin-top: 0.5rem;
		color: ${Colors.tertirary_red_1_dark};
	}

	.hr2 {
		width: 100%;
		margin-bottom: 5vh;
		margin-top: 1vh;
		background-color: #e0e0e0;
		color: #e0e0e0;
		border: none;
		height: 2px;
	}

	.forget {
		display: flex;
		margin-top: 10px;
		margin-bottom: 5vh;
		text-decoration: underline;
		color: ${Colors.primary_blue_k_main};
		background-color: white;
		border: none;
	}

	.LoginCadastro {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.enviar {
		width: 100%;
		height: 6vh;
		background-color: ${Colors.primary_blue_k_main};
		color: white;
		font-weight: 700;
		border: none;
		margin-bottom: 25px;
		border-radius: 8px;
		text-transform: uppercase;
	}
	.enviar:hover{
		background-color: ${Colors.primary_blue_k_dark_2};
	}

	.cadastro {
		width: 100%;
		height: 6vh;
		background-color: white;
		color: ${Colors.primary_blue_k_main};
		font-weight: 700;
		border: none;
		border-color: ${Colors.primary_blue_k_main};
		margin-bottom: 25px;
		border-radius: 8px;
	}

	.global {
		width: 30%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.idiomaAjuda {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 10%;
	}

	.inputIA {
		background-color: white;
		border: none;
		margin-top: 2vh;
	}
`;

export const Background = styled.div`
	flex: 1;
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
`;
