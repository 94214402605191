import React, { useEffect, useRef, useState } from "react";
import { FormHandles } from "@unform/core";

import { useForm } from "react-hook-form";

import { TextsProps } from "../../../database/txt.interface";
import { useDispatch, useSelector } from "react-redux";

import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import InputSelectForm from "../../../components/SelectComponent/InputSelectForm";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import FormSubmiteButton from "../../../components/Buttons/FormSubmitButton";
import { AmplitudeLog } from "../../../utils/amplitude";

import {
	ContainerGoBack,
	ContainerIcon,
	ContainerInfos,
	ContainerPageDetailPatient,
	Content,
	PageName,
	Title,
} from "./styles";

import Text from "../../../components/Texts/Text";
import Form from "../../../components/Form";
import InputTextEntryForm from "../../../components/Inputs/inputForm";
import ModalSaveChangeEditPatient from "../../../components/Modais/ModalSaveChangesPatientEdit";

import { ReactComponent as ArrowBack } from "../../../assets/kinologyIcons/svg/arrow_back.svg";
import { ReactComponent as Edit } from "../../../assets/kinologyIcons/svg/edit.svg";
import { ReactComponent as UserWhitoutPhoto } from "../../../assets/kinologyIcons/svg/patient.svg";
import { ReactComponent as Camera } from "../../../assets/kinologyIcons/svg/photo_camera.svg";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import { Colors } from "../../../styles/colors";
import { ContainerDash, Icon, Return } from "../../../styles/global";
import { P1Desk, P2Desk, P3Desk } from "../../../styles/styles_text";
import { formatDominant, formatDominantForSave } from "../../../utils/funcs";

interface PropsFildInputs {
	EditNascimento?: string;
	EditMassa?: string;
	EditAltura?: string;
	EditCelular?: string;
	EditCPF?: string;
	EditNome?: string;
	EditEmail?: string;
	EditSexo?: string;
	EditLadoDominante?: string;
}

const InformacoesPaciente: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const methods = useForm();
	const dispatch = useDispatch();
	const inputRef = useRef<HTMLInputElement>(null);
	const formRef = useRef<FormHandles>(null);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const user_eval_avatar_64 = useSelector(
		(state: any) => state.add_pac_aval.user_eval_avatar_64
	);

	const conditioning = [
		"",
		current_language?.menu_lateral_atleta,
		current_language?.menu_lateral_ativo,
		current_language?.menu_lateral_pos_operatorio,
		current_language?.menu_lateral_sedentario,
		current_language?.menu_lateral_idoso,
	];
	const Sex = [
		current_language?.options_sexo_mas,
		current_language?.options_sexo_fem,
	];
	const DominantSides = [
		current_language?.canhoto,
		current_language?.destro,
	];

	const [img, setImg] = useState<any>(paciente?.USUARIO_FOTO);
	const [disabled, setDisabled] = useState(true);
	const [condition, setCondition] = useState<string>("");
	const [load, setLoad] = useState(false);
	const [clickAddPhoto, setClickAddPhoto] = useState(false);
	const [submitForm, setSubmitForm] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [dominant, setDominant] = useState<string>("");

	const values = methods.getValues();

	const objForCompare = {
		EditNascimento: paciente?.USUARIO_NASCIMENTO
			? paciente?.USUARIO_NASCIMENTO == "0000-00-00"
				? ""
				: current_language?.idioma === "Português"
				? `${paciente?.USUARIO_NASCIMENTO?.substr(0, 10)
						.split("-")
						.reverse()
						.join("/")}`
				: current_language?.idioma === "English"
				? `${paciente?.USUARIO_NASCIMENTO?.substr(5, 10)
						.split("-")
						.join("/")}/${paciente?.USUARIO_NASCIMENTO?.substr(0, 4)}`
				: `${paciente?.USUARIO_NASCIMENTO?.substr(0, 10)
						.split("-")
						.reverse()
						.join("-")}`
			: "",
		EditCelular: paciente?.USUARIO_CONTATO ? paciente?.USUARIO_CONTATO : "",
		EditCPF: paciente?.USUARIO_CPF ? paciente?.USUARIO_CPF : "",
		EditEmail: paciente?.USUARIO_EMAIL ? paciente?.USUARIO_EMAIL : "",
		EditNome: paciente?.USUARIO_NOME ? paciente?.USUARIO_NOME : "",
		EditMassa: paciente?.USUARIO_MASSA ? paciente?.USUARIO_MASSA : "",
		EditAltura: paciente?.USUARIO_ESTATURA ? paciente?.USUARIO_ESTATURA : "",
		EditSexo: paciente?.USUARIO_SEXO ? paciente?.USUARIO_SEXO : "",
		EditLadoDominante: paciente?.USUARIO_LADO_DOMINANTE ? formatDominant(paciente?.USUARIO_LADO_DOMINANTE, current_language) : "",
	};

	function handleGoBack() {
		let changesDetected = false;
		for (const key in objForCompare) {
			if (
				values[key as keyof PropsFildInputs] !==
				objForCompare[key as keyof PropsFildInputs]
			) {
				changesDetected = true;
				break;
			}
		}
		if (changesDetected) {
			setModalVisible(true);
		} else {
			dispatch(DashBoardActions.set_current_page("perfil_paciente"));
		}
	}

	useEffect(() => {
		dispatch(AddPacAvalActions.set_photo(img));
	}, [img]);

	useEffect(() => {
		if (clickAddPhoto && inputRef.current) {
			inputRef.current.click();
			setClickAddPhoto(false);
		}
	}, [clickAddPhoto]);

	useEffect(() => {
		if (submitForm && !disabled) {
			methods.handleSubmit((data) => {
				onSubmit(data);
				setSubmitForm(false);
				setModalVisible(false);
			})();
		}
	}, [submitForm, methods]);

	function getBase64(event: any) {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			setImg(reader.result);
			dispatch(AddPacAvalActions.set_avatar_eval_64(reader.result));
		};
		reader.onerror = function (error) {
			console.error("Error: ", error);
		};
	}
	function deletePhoto(event: any) {
		event.target.value = null;
	}

	const formatIMC = (weigth: string, heigth: string) => {
		return (
			parseFloat(weigth) /
			(parseFloat(heigth?.replace(",", ".")) *
				parseFloat(heigth?.replace(",", ".")))
		).toFixed(2);
	};

	useEffect(() => {
		methods.setValue("EditNome", paciente?.USUARIO_NOME);
		methods.setValue("EditEmail", paciente?.USUARIO_EMAIL);
		methods.setValue("EditCelular", paciente?.USUARIO_CONTATO);
		methods.setValue(
			"EditCPF",
			paciente?.USUARIO_CPF?.replace(
				/(\d{3})(\d{3})(\d{3})(\d{2})/,
				"$1.$2.$3-$4"
			)
		);
		methods.setValue(
			"EditNascimento",
			paciente?.USUARIO_NASCIMENTO == "0000-00-00"
				? ""
				: current_language?.idioma === "Português"
				? `${paciente?.USUARIO_NASCIMENTO?.substr(0, 10)
						.split("-")
						.reverse()
						.join("/")}`
				: current_language?.idioma === "English"
				? `${paciente?.USUARIO_NASCIMENTO?.substr(5, 10)
						.split("-")
						.join("/")}/${paciente?.USUARIO_NASCIMENTO?.substr(0, 4)}`
				: `${paciente?.USUARIO_NASCIMENTO?.substr(0, 10)
						.split("-")
						.reverse()
						.join("-")}`
		);
		methods.setValue(
			"EditAltura",
			`${
				paciente?.USUARIO_ESTATURA != null && paciente?.USUARIO_ESTATURA != ""
					? paciente?.USUARIO_ESTATURA?.split(".").join(",")
					: ""
			}`
		);
		methods.setValue(
			"EditMassa",
			`${
				paciente?.USUARIO_MASSA != null && paciente?.USUARIO_MASSA != ""
					? paciente?.USUARIO_MASSA?.split(".").join(",")
					: ""
			}`
		);
		methods.setValue("EditSexo", paciente?.USUARIO_SEXO);
		methods.setValue(
			"EditIMC",
			paciente?.USUARIO_MASSA && paciente?.USUARIO_ESTATURA
				? `${formatIMC(
						paciente?.USUARIO_MASSA,
						paciente?.USUARIO_ESTATURA
				  )}0 kg/m²`
				: "-"
		);
		methods.setValue("EditLadoDominante", paciente?.USUARIO_LADO_DOMINANTE);
	}, [paciente]);

	const onSubmit = (data: any) => {
		const dat_request = {
			USUARIO_NIVEL: "3",
			USUARIO_NOME: data?.EditNome,
			USUARIO_EMAIL: data?.EditEmail,
			USUARIO_CONTATO: data?.EditCelular,
			USUARIO_CPF: data?.EditCPF,
			USUARIO_ESTATURA: data?.EditAltura?.replace("m", ""),
			USUARIO_MASSA: data?.EditMassa?.replace("kg", "").replace("k", ""),
			USUARIO_NASCIMENTO:
				current_language?.idioma === "Português"
					? data?.EditNascimento?.split("/").reverse().join("-")
					: current_language?.idioma === "English"
					? `${data?.EditNascimento?.substr(
							6,
							10
					  )}-${data?.EditNascimento?.substr(0, 5)
							.split("/")
							.reverse()
							.join("-")}`
					: data?.EditNascimento.split("-").reverse().join("-"),
			USUARIO_SEXO: data?.EditSexo?.split("")[0],
			USUARIO_CONDICIONAMENTO: condition
				? conditioning?.indexOf(condition)
				: "",
			USUARIO_LADO_DOMINANTE: 
				(!dominant || dominant === current_language?.lado_dominante) ? ""
				 : formatDominantForSave(dominant, current_language),
		};

		dispatch(
			AddPacAvalActions.set_avatar_eval_64(
				user_eval_avatar_64 != null
					? user_eval_avatar_64
					: paciente?.USUARIO_FOTO
			)
		);
		AmplitudeLog("edit_perfil_paciente", { body: dat_request });
		dispatch(AddPacAvalActions.set_edit_paci(paciente));
		dispatch(AddPacAvalActions.request_add_evaluetor(dat_request));
		setLoad(false);
	};
	return (
		<Content img={img} style={{ width: "100%" }}>
			<Form
				ref={formRef}
				methods={methods}
				onSubmit={(data) => {
					onSubmit(data), setLoad(true);
				}}
			>
				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<P2Desk></P2Desk>
					<div
						className="ContainerPhoto"
						onClick={() => (!disabled ? setClickAddPhoto(true) : {})}
					>
						<div
							className="Photo"
							style={{ cursor: !disabled ? "pointer" : "default"}}
						>
							<input
								id="img"
								type="file"
								ref={inputRef}
								onChange={(event) => (!disabled ? getBase64(event) : {})}
								onClick={(event) => (!disabled ? deletePhoto(event) : {})}
								style={{ display: "none" }}
								accept="image/*"
							/>
							{img ? (
								<img src={img} className="imgForInput" />
							) : (
								<UserWhitoutPhoto
									color={Colors.white}
									width={"3rem"}
									height={"3rem"}
								/>
							)}
							{img && !disabled && (
								<div className="Camera">
									<Camera color={Colors.white} />
								</div>
							)}
						</div>
					</div>
					<Title>
						<ContainerIcon onClick={() => setDisabled(!disabled)}>
							<Edit
								color={Colors.primary_blue_k_main}
								className="EditInformations"
							/>
						</ContainerIcon>
					</Title>
				</div>
				<ContainerInfos img={img}>
					<div className="ContainerFirstPart">
						<InputTextEntryForm
							type="text"
							width={90}
							title={current_language?.nome_completo + (disabled ? "" : "*")}
							placeholder={current_language?.placeholder_digite}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							fontSize={1}
							disabled={disabled}
							typeInputName="EditNome"
						/>
						<InputTextEntryForm
							type="text"
							width={90}
							marginTop={20}
							disabled={disabled}
							title={
								current_language?.menu_lateral_data_nascimento +
								(disabled ? "" : "*")
							}
							placeholder={current_language?.placeholder_digite}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							leftIcon="Calendar"
							fontSize={1}
							typeInputName="EditNascimento"
						/>
						<InputTextEntryForm
							type="text"
							width={90}
							marginTop={20}
							disabled={disabled}
							title={current_language?.primeiro_acesso_CPF}
							placeholder={current_language?.placeholder_digite}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							fontSize={1}
							leftIcon="DigitalIcon"
							typeInputName="EditCPF"
						/>
						<InputTextEntryForm
							type="text"
							width={90}
							marginTop={20}
							disabled={disabled}
							title={current_language?.primeiro_acesso_celular}
							placeholder={current_language?.placeholder_digite}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							fontSize={1}
							leftIcon="Phone"
							typeInputName="EditCelular"
						/>
						<InputTextEntryForm
							type="text"
							width={90}
							marginTop={20}
							title={"E-mail"}
							disabled={disabled}
							placeholder={current_language?.placeholder_digite}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							fontSize={1}
							leftIcon="EmailIcon"
							typeInputName="EditEmail"
						/>
					</div>
					<div className="ContainerSecondPart">
						<InputTextEntryForm
							type="text"
							disabled={disabled}
							title={current_language?.menu_lateral_massa}
							placeholder={current_language?.placeholder_digite}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							fontSize={1}
							leftIcon="Mass"
							typeInputName="EditMassa"
						/>
						<InputTextEntryForm
							type="text"
							marginTop={20}
							disabled={disabled}
							title={current_language?.menu_lateral_altura}
							placeholder={current_language?.placeholder_digite}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							fontSize={1}
							leftIcon="Height"
							typeInputName="EditAltura"
						/>
						<Text
							title={"IMC"}
							fontFamily="NotoSans-Regular"
							fontSize={1}
							fontWeight={600}
							textAlign="left"
							marginTop={20}
							textColor={Colors?.primary_blue_k_main}
						/>
						<Text
							title={
								methods.watch("EditMassa") != "" &&
								methods.watch("EditAltura") != ""
									? `${formatIMC(
											methods.watch("EditMassa")?.toString(),
											methods.watch("EditAltura")?.toString()
									  )}kg/m²`
									: "kg/m²"
							}
							fontFamily="NotoSans-Regular"
							fontSize={1}
							fontWeight={400}
							textAlign="left"
							marginTop={20}
							textColor={Colors?.neutral_grey_k_60}
						/>
					</div>
					<div className="ContainerThirtPart">
						<InputSelectForm
							type="select"
							disabled={disabled}
							title={current_language?.menu_lateral_sexo}
							placeholder={current_language?.select_country}
							rightIcon="ArrowSelect"
							arrayOptions={Sex}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							fontSize={1}
							typeInputName="EditSexo"
						/>
						<InputSelectForm
							type="select"
							disabled={disabled}
							title={current_language?.condicionamento}
							placeholder={current_language?.select_country}
							rightIcon="ArrowSelect"
							arrayOptions={conditioning}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							fontSize={1}
							dataChildToParent={(data) => {
								setCondition(data);
							}}
							marginTop={24}
							typeInputName="EditConditioning"
						/>
						<InputSelectForm
							type="select"
							disabled={disabled}
							title={current_language?.lado_dominante}
							placeholder={current_language?.lado_dominante}
							rightIcon="ArrowSelect"
							arrayOptions={DominantSides}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							fontSize={1}
							marginTop={33}
							typeInputName="EditLadoDominante"
							dataChildToParent={(data) => {
								setDominant(data);
							}}
						/>
					</div>
				</ContainerInfos>
				{!disabled && (
					<FormSubmiteButton
						type="submit"
						onClick={() => methods.trigger()}
						disabled={
							methods.watch("EditNome") != "" &&
							methods.watch("EditNascimento") != ""
								? false
								: true
						}
						marginTop={40}
						fontFamily="Kanit-Medium"
						typeButtonComponent="primary"
						activityIndicator={load}
						title={current_language?.menu_lateral_botao_salvar}
					/>
				)}
			</Form>
			<ModalSaveChangeEditPatient
				visible={modalVisible}
				save={(data: boolean) => setSubmitForm(data)}
				cancel={() => {
					dispatch(DashBoardActions.set_current_page("perfil_paciente")),
						setModalVisible(false);
				}}
			/>
		</Content>
	);
};

export default InformacoesPaciente;

