import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppActions } from "../../../store/ducks/app";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import { checkLanguage } from "../../../utils/texts";
import EscolhaData from "./escolhaData";
import EscolhaEmail from "./escolhaEmail";
import ShereAnamnese from "./shereAnamneseAsync";
import EscolhaAnamnese from "./escolhaAnamnese";
import ExercicioList from "./exercicioList";
import { Container } from "./styles";
import Sucesso from "./sucesso";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalCompartilhamento: React.FC = () => {
	const dispatch = useDispatch();

	const language = useSelector((state: any) => state.configs.language);
	useEffect(() => {
		checkLanguage(language, dispatch);
	}, [language]);

	const classes = useStyles();
	const is_open_compartilhamento = useSelector(
		(state: any) => state.app.is_open_compartilhamento
	);

	const ModalCadastroClose = () => {
		dispatch(RelatorioActions.set_marcar_todas(false));
		dispatch(ManageProtocolActions.set_selected_exercises_reset());
		dispatch(AppActions.set_modal_compartilhamento(false, 0));
	};

	const handleContinueCompartilhamento = (path: any) => {
		dispatch(AppActions.set_continue_compartilhamento(path));
	};

	const continue_compartilhamento = useSelector(
		(state: any) => state.app.continue_compartilhamento
	);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_compartilhamento}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_compartilhamento}>
					<Container>
						<svg
							className="close"
							onClick={ModalCadastroClose}
							width="28"
							height="28"
							viewBox="0 0 28 28"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M24.335 0L13.9043 10.4283L3.47608 0L0 3.47608L10.4283 13.9043L0 24.3326L3.47608 27.8087L13.9043 17.3804L24.335 27.8087L27.8111 24.3326L17.3829 13.9043L27.8111 3.47608L24.335 0Z"
								fill="#000E4B"
							/>
						</svg>
						{continue_compartilhamento === "EscolhaData" && <EscolhaData />}
						{continue_compartilhamento === "ExercicioList" && <ExercicioList />}
						{continue_compartilhamento === "EscolhaAnamnese" && (
							<EscolhaAnamnese />
						)}
						{continue_compartilhamento === "EscolhaEmail" && <EscolhaEmail />}
						{continue_compartilhamento === "ShereAnamnese" && <ShereAnamnese />}
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalCompartilhamento;
