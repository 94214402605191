import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/icons/buttonClose.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { ConfigsActions } from "../../../store/ducks/configs";
import { TypesLanguage } from "../../../utils/types";
import { Container } from "./styles";
import { Colors } from "../../../styles/colors";
import ButtonActionModal from "../../Buttons/ButtonActionModal";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalLinguagem: React.FC = () => {
	const dispatch = useDispatch();
	const [linguage, setLinguage]= useState('pt-BR')
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const language = useSelector((state: any) => state.configs.language);

	const classes = useStyles();
	const is_open_linguagem = useSelector(
		(state: any) => state.app.is_open_linguagem
	);

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_linguagem(false));
	};

	const ModalCadastroCloseSave = () => {
		dispatch(AppActions.set_modal_linguagem(false));
	};

	useEffect(()=> {

		if (linguage === "pt-BR") {
			dispatch(ConfigsActions.set_language(TypesLanguage.PT));
		} else if (linguage === "en-US") {
			dispatch(ConfigsActions.set_language(TypesLanguage.EN));
		} else {
			dispatch(ConfigsActions.set_language(TypesLanguage.ES));
		}

	},[linguage])
	

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_linguagem}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_linguagem}>
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<div className="divText">
							<p className="text">{current_language?.selecione_um_idioma}:</p>
						</div>
						<div className="lenguages">
							<div className="lenguage">
								<div 
									onClick={()=> setLinguage('pt-BR')}
									className={"groupRadio"}
									style={{
										borderColor: language == TypesLanguage.PT ? Colors.secondary_green_k_main : Colors.primary_blue_k_main
									}}
								>	
									{ language == TypesLanguage.PT &&
										<div className="bullet"/>
									}
								</div>
								<label
									className={"textButton"}
									htmlFor="portugues"
									style={{fontFamily: language == TypesLanguage.PT ? 'Kanit-SemiBold' : 'kanit-Regular' }}
								>
									Português
								</label>
							</div>

							<div className="lenguage">
								<div 
									className="groupRadio" 
									onClick={()=> setLinguage('en-US')}
									style={{
										borderColor: language == TypesLanguage.EN ? Colors.secondary_green_k_main : Colors.primary_blue_k_main
									}}
								>
									{ language == TypesLanguage.EN &&
										<div className="bullet"/>
									}
								</div>
								<label
									className={"textButton"}
									htmlFor="english"
									style={{fontFamily: language == TypesLanguage.EN ? 'Kanit-SemiBold' : 'kanit-Regular' }}
								>
									English
								</label>
							</div>

							<div className="lenguage">
								<div 
									className="groupRadio" 
									onClick={()=> setLinguage('español')}
									style={{
										borderColor: language == TypesLanguage.ES? Colors.secondary_green_k_main : Colors.primary_blue_k_main
									}}
								>
									{ language == TypesLanguage.ES &&
										<div className="bullet"/>
									}
								</div>
								<label
									className={"textButton"}
									htmlFor="español"
									style={{fontFamily: language == TypesLanguage.ES ? 'Kanit-SemiBold' : 'kanit-Regular' }}
								>
									Español
								</label>
							</div>
						</div>
						<ButtonActionModal 
							onClick={()=>ModalCadastroCloseSave()}
							disabled={false}
							typeButtonComponent="primary"
							title={current_language?.primeiro_acesso_concluir}
							width={80}
							fontFamily="NotoSans-Medium"
						/>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalLinguagem);
