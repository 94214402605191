import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;

`;
export const Overlay = styled.div<{isVisible: boolean}>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 10;
	display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;
export const ListagemEcards = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	padding-left: 42px;
	padding-right: 42px;
	padding-top: 42px;
	padding-bottom: 42px;
	background-color: #f5fbfc;
`;

export const Content = styled.div`
	/* display: flex; */
	width: 60%; //  LARGURA DO CONTAINER DO MINHA CONTA
	height: 90vh;
	/* justify-content: center;
	align-items: center;
	overflow-y: auto; */
	border-radius: 1.25rem;
`;

export const Group = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
`;

export const Cards = styled.div`
	display: flex;
	flex-direction: column; 
	background-color: red;
	width: 100%;
	height: 15%;
	margin-top: 2rem;
`;
