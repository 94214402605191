import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 430px;
	width: 533px;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	.close {
		width: 21px;
		position: absolute;
		right: 28px;
		top: 24px;
		cursor: pointer;
	}

	.alert {
		margin-bottom: 2rem;
		width: 12%;
	}

	.divText {
		width: 65%;
		
	}

	.text {
		color: #b3b3b3;
		font-family: 'NotoSans-bold';
		font-size: 28px;
		font-weight: bold;
		text-align: center;
		color: ${Colors.primary_blue_k_main};
	}

	.textButton {
		color: ${Colors.primary_blue_k_main};
		font-weight: 500;
		font-family: 'Kanit-SemiBold';
		font-size: 26px;
		margin-top: 5px;
		line-height: 30.47px;
		text-align: center;
		cursor: pointer;
	}

	

	.button {
		width: 60%;

		text-align: center;

		font-family: Roboto;
		font-weight: 700;
		font-size: 1.5rem;

		color: ${Colors.primary_blue_k_main};
		background: transparent;

		line-height: 1.758rem;
		padding: 0.7rem 3.3rem;

		border: 4px solid ${Colors.primary_blue_k_main};
		border-radius: 0.5rem;

		transition: all 0.3s ease;
	}

	.button:hover {
		background: ${Colors.primary_blue_k_main};
		color: #ffffff;
	}

	.lenguages {
		width: 60%;

		margin: 5rem 0;

		display: flex;
		justify-content: center;
	}

	.lenguage {
		position: relative;

		display: flex;
		flex-direction: column;
		align-items: center;

		margin: 0 1.5rem;
	}

	.select {
		display: flex;
		fill: #00c4ff;
	}

	.groupRadio {
		display: flex;
		width: 40px;
		height: 40px;
		justify-content: center;
		align-items: center;
		border-width:1px;
		border: solid;
		border-radius: 30px;
		cursor: pointer;
	
	}
	.bullet {
		display: flex;
		width: 20px;
		height: 20px;
		border-radius: 40px;
		background-color:  ${Colors.secondary_green_k_main}
	}


	.checkmark {
		position: absolute;
		height: 25px;
		width: 25px;
		background-color: #c4c4c4;
		border-radius: 50%;
		box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2);
	}
	.containerRadio {
		display: flex;
		background-color: #e5005b;
		width: 30px;;
		height: 30px;
	}
	.checkmarkSeleted {
		position: absolute;
		height: 25px;
		width: 25px;
		background-color:${Colors.secondary_green_k_main};
		border-radius: 50%;
		box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2);
	}
`;
