import styled from "styled-components";

const size = 21.875;

export const Container = styled.div`
	width: ${size}rem;
	min-height: 0;
	max-height: 1.246rem;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	position: relative;
	
`;

export const Slider = styled.input`
	all:unset;
  -webkit-appearance: none; 
  appearance: none;
  width: 91%;
  height: 0; 
  outline: none; 
  -webkit-transition: .2s;
  transition: opacity .2s;

	position: absolute;
	left: 49.6%;
	top: 0;

	transform: translateX(-50%);

	z-index: 10;

	::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 1.2rem; 
		height: 1.2rem;
		background: #001574;
		cursor: pointer; 

		border-radius: 50% 50% 0% 50%;

		transform: translateY(-1.5rem) rotate(45deg);
	}
`;

export const Niveis = styled.div`
	width: 95%;
	height: 1.246rem;

	display: flex;
	justify-content: space-between;

	position: relative;
	
	div {
		position: relative;
		width: 10%;
		border-right: 1px solid #fff;
	}

	.nivel {
		width: 100%;
		height: 100%;
	}
	
	.imgs {
		width: 100%;

		display: flex;
		justify-content: space-around;
		
		position: absolute;
		bottom: -2rem;

		img {
			width: 20px;
			height: 20px;
		}
	}

	span {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 14.5718px;
		line-height: 120%;

		width: 1.3rem;

		display: flex;
		justify-content: center;

		color: #404040;
		position: absolute;
		top: -1.5rem;

		z-index: 1;
	}

	.numero0 {
		left: -.5rem;

		::after {
			content: "";
			border: 1px solid #404040;
			height: .4rem;
			width: 0;
			transform: translateY(1rem) translateX(-.34rem) ;
		}
	}

	.semNumero0 {
		left: -.25rem;

		::after {
			content: "";
			border: 1px solid #404040;
			height: .4rem;
			width: 0;
			transform: translateY(1rem) translateX(-.34rem) ;
		}
	}

	.numero10 {
		right: -.5rem;
		
		::after {
			content: "";
			border: 1px solid #404040;
			height: .4rem;
			transform: translateY(1rem) translateX(-.55rem) ;
		}
	}

	.semNumero {
		right: -1rem;
		
		::after {
			content: "";
			border: 1px solid #404040;
			height: .4rem;
			transform: translateY(1rem) translateX(-.55rem) ;
		}
	}

	label {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 14.5718px;
		line-height: 120%;

		display: flex;
		justify-content: center;

		width: 10%;

		color: #404040;

		transform: translateX(-100%) ;

		position: absolute;
		top: -3.5rem;
	}
`;