import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const ContainerPageDetailPatient = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: ${Colors?.neutral_very_light};
    background-color: red;
`;

export const Content = styled.div<{img?: any}>`
    width: 100%;
    display: flex;
    flex-direction: column;

    .ContainerPhoto {
        width: 10%;
        .Photo {
            display: flex;
            width: 6.25rem;
            height: 6.25rem;
            align-items: center;
            justify-content: center;
            border-radius: 6.25rem;
            cursor: pointer;
            border:  ${ ({img})=> !img && '2px solid Colors.primary_blue_k_main'};
            background-color: ${Colors.primary_blue_k_main};

            .imgForInput{
                width: 100%;
                height: 100%;
                border-radius: 100%;
                object-fit: cover;
            }
           
            .Camera {
                display: flex;
                width:1.55rem ;
                height: 1.55rem;
                border-radius: 1.25rem;
                align-items: center;
                justify-content: center;
                bottom: 0.5rem;
                right: -0.3rem;
                background-color: ${Colors?.neutral_grey_k_70};
            }
        }

    }
    

`;

export const ContainerGoBack = styled.div`
	min-width: 15%;
	align-self: flex-start;
	display: flex;
	align-items: center;
	flex-direction: row;
    margin-top: 2rem;
	cursor: pointer;
`;

export const PageName = styled.p`
	font-family: "NotoSans-regular";
	font-size: 1rem;
	font-weight: 400;
	color: ${Colors?.primary_blue_k_main};
	margin-left: 0.2rem;
    
`;

export const Title = styled.div`
    display: flex;
`;
export const ContainerIcon = styled.div`
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    margin-left:1rem;

    &:hover {
        background-color: ${Colors.primary_blue_k_medium_1};
        .EditInformations {
            color: ${Colors.white};
        }
    }
    &:active {
        background-color: ${Colors.primary_blue_k_main};
        .EditInformations {
            color: ${Colors.white};
        }
    }
`;

export const ContainerInfos = styled.div<{img?: any}>`
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 4rem;

    .ContainerFirstPart {
        display: flex;
        flex-direction: column;
        width: 45%;
        padding: 0rem 1rem;
        border-right:  0.0625rem solid ${Colors.neutral_grey_k_10};
    }
    .ContainerSecondPart{
        display: flex;
        flex-direction: column;
        width: 22.5%;
        padding: 0rem 1rem;
        /* background-color: brown; */
    }
    .ContainerThirtPart{
        display: flex;
        flex-direction: column;
        width: 22.5%;
        padding: 0rem 1rem;
    }
`;