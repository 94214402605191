import {
	Backdrop,
	Fade,
	Modal,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../store/ducks/app";
import { PacAvalActions } from "../../store/ducks/manage_pac_aval";
import buttonClose from "../../assets/icons/buttonCloseBlack.svg";
import { Icon } from "../../styles/global";
import { Close, Container, Title } from "../../styles/styles_modais_padroes";
import { P2Desk } from "../../styles/styles_text";
import Info from "../../assets/icons/info.svg";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalOuro: React.FC = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const is_open = useSelector((state: any) => state.app.is_open_modal_ouro);

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_lista_pacientes([]));
		dispatch(PacAvalActions.reset_patient());
		dispatch(AppActions.set_open_modal_ouro(false));
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open}
			onClose={ModalCadastroClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open}>
				<Container>
					<Close
						className="close"
						src={buttonClose}
						onClick={ModalCadastroClose}
						alt="Close"
					/>
					<Title>
						{" "}
						<Icon src={Info} /> Informação
					</Title>
					<Title>Protocolo Padrão Ouro</Title>
					<P2Desk>
						Para as execuções dos exercícios recomendamos o uso do{" "}
						<strong>Protocolo Padrão Ouro</strong>, que consiste em:
						<br />* Fazer <strong>3 repetições</strong>, com contração
						isométrica de <strong>5 segundos cada</strong>;
						<br />* Com <strong>10 segundos</strong> de descanso entre as
						repetições;
						<br />* E <strong>60 segundos</strong> de descanso entre os
						exercícios.
					</P2Desk>
					<br />
					<a
						style={{ textAlign: "center", color: "#3D4259" }}
						className="saibaMais"
						href="https://base.materials2.kinology.com.br/e-lastic-padr%C3%A3o-ouro"
						target="_blank"
						rel="noreferrer"
					>
						Clique para saber mais
					</a>
				</Container>
			</Fade>
		</Modal>
	);
};

export default ModalOuro;
