import {
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container, ContainerInput, Title, Option } from "./styles";
import {ReactComponent as Close} from '../../../assets/kinologyIcons/svg/close.svg';
import InputTextEntryForm from '../../../components/Inputs/inputForm';
import FormSubmiteButton from "../../Buttons/FormSubmitButton";
import { useForm } from "react-hook-form";
import Form from "../../Form";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { SenhaActions } from "../../../store/ducks/senha";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalAlterarSenha: React.FC = () => {

	const dispatch = useDispatch();
	const methods = useForm()
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	const is_open_modal_alterar_senha = useSelector(
		(state: any) => state.app.is_open_modal_alterar_senha
	);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);
	
	const onSubmit = (data: any) => {
		const dat_request = {
			USUARIO_EMAIL: user_logado.USUARIO_EMAIL,
			txSenhaAtual: data.senha,
			txSenhaNova: data.confirmeSenha,
		};
		dispatch(SenhaActions.edit_senha(dat_request));
	};
	

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_modal_alterar_senha}
				closeAfterTransition
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_modal_alterar_senha}>
					<Container>
                        <Close 
                            onClick={()=> {dispatch(AppActions.set_modal_alter_senha(false))}} 
                            style={{
                                position: 'absolute', 
                                right: 32, 
                                top:22,
                                cursor: 'pointer'
                            }}
                        />
                        <Title>{current_language?.menu_lateral_alterar_senha}</Title>
						<Form 	
							methods={methods} 	
							onSubmit={(data)=>{onSubmit(data)}}
							style={{
								width: '100%', 
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}
						>
							<ContainerInput>

								<InputTextEntryForm 
									type="text"
									title={current_language?.alterar_senha_senha_atual+'*'}
									placeholder={'*********'}
									fontFamily="NotoSans-SemiBold"
									fontWeight={600}
									typeInputName="senha" 
									marginTop={32}
									leftIcon="KeyPassword"
									rightIcon="CloseEye"
									
								/>
								<InputTextEntryForm 
									type="text"
									title={current_language?.alterar_senha_nova_senha+'*'}
									placeholder={'*********'}
									fontFamily="NotoSans-SemiBold"
									fontWeight={600}
									marginTop={32}
									typeInputName="novaSenha" 
									leftIcon="KeyPassword"
									rightIcon="CloseEye"
								/>
								<InputTextEntryForm 
									type="text"
									title={current_language?.alterar_senha_confirme_senha+'*'}
									placeholder={'*********'}
									fontFamily="NotoSans-SemiBold"
									fontWeight={600}
									marginTop={32}
									typeInputName="confirmeSenha" 
									leftIcon="KeyPassword"
									rightIcon="CloseEye"
								/>
							</ContainerInput>

							<FormSubmiteButton
								type="submit"
								onClick={()=> methods.trigger()}
								disabled={Object.values(methods.watch()).every((item: string)=> item != '')
									? false
									: true
								}
								marginTop={40}
								fontFamily="Kanit-Medium"
								typeButtonComponent="primary"
								activityIndicator={false}
								title={current_language?.menu_lateral_botao_salvar}
							/>
						</Form>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalAlterarSenha;
