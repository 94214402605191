import styled from "styled-components";

export const Container = styled.div`
	background-color: #ffffff;
	height: 539px;
	width: 722px;
	border-radius: 30px;
	display: flex;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	.close {
		width: 1.738rem;
		position: absolute;
		right: 2rem;
		top: 2rem;
		cursor: pointer;
	}

	.divText {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center; 
		padding: 2rem;
		text-align: center;
	}
	
	.title {
		color: #5c5b5b;
		font-size: 1.4rem;
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
`;
