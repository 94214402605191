import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextsProps } from "../../../database/txt.interface";
import { beep_curto } from "../../../pages/Execucao/beep";
import { beep_long } from "../../../pages/Execucao/beep_long";
import { AppActions } from "../../../store/ducks/app";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import { sleep } from "../../../utils/timer";
import { Container } from "./styles";
import { H3Desk } from "../../../styles/styles_text";
import styled from "styled-components";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

// const TocarBeep = new UIfx(
//     beep,
//   {
//       volume: 1,
//   }
// )

// const TocarLongBeep = new UIfx(
//   long_beep,
//   {
//     volume: 1,
//   }
// )

const ModalContagemExercicio: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	const is_open_contagem_exercicio = useSelector(
		(state: any) => state.app.is_open_contagem_exercicio
	);

	const modalCadastroClose = () => {
		// dispatch(AppActions.set_modal_contagem_exercicio(false));
	};

	const [contagem, setContagem] = React.useState<any>(3);

	const handleIsPlay = () => {
		dispatch(AvaliationActions.set_start(true));
		dispatch(AppActions.set_timer_reset(true));
	};
	const alerta_sonoro = useSelector(
		(state: any) => state.configs.alerta_sonoro
	);

	React.useEffect(() => {
		async function beep() {
			setContagem(3);
			for (let i = 1; i <= 4; i++) {
				await sleep(400);
				setContagem(4 - i);
				if (i == 4) {
					if (alerta_sonoro) {
						beep_long();
					}
					setTimeout(() => {
						dispatch(AppActions.set_modal_contagem_exercicio(false));
					}, 550);
				} else {
					if (alerta_sonoro) {
						beep_curto();
					}
				}
				await sleep(600);
			}
		}
		if (is_open_contagem_exercicio) {
			beep();
		}
	}, [is_open_contagem_exercicio]);

	React.useEffect(() => {
		if (contagem === 0) {
			setTimeout(() => {
				dispatch(AppActions.set_modal_contagem_exercicio(false));
				handleIsPlay();
			}, 550);
		}
	}, [contagem]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_contagem_exercicio}
				onClose={modalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_contagem_exercicio}>
					<Container>
						{contagem > 0 ? (
							<>
								<H3Desk style={{ marginBottom: "1rem" }}>
									Início da execução em
								</H3Desk>
								<Tempo id="tempo">{contagem}</Tempo>
							</>
						) : (
							<>
								<div className="vai">
									<H3Desk style={{ marginBottom: "1rem" }}>
										Iniciar execução!
									</H3Desk>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="81"
										height="80"
										viewBox="0 0 81 80"
										fill="none"
									>
										<mask
											id="mask0_663_4704"
											maskUnits="userSpaceOnUse"
											x="0"
											y="0"
											width="81"
											height="80"
										>
											<rect
												x="0.75"
												width="79.5"
												height="79.5"
												fill="#D9D9D9"
											/>
										</mask>
										<g mask="url(#mask0_663_4704)">
											<path
												d="M17.3125 69.5625V13.25H47.125L48.45 19.875H67V53H43.8125L42.4875 46.375H23.9375V69.5625H17.3125Z"
												fill="#00A377"
											/>
										</g>
									</svg>
								</div>
							</>
						)}
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

const Tempo = styled.text`
	color: var(--Secundrias-Green-K-Medium-1, #00a377);
	text-align: center;
	font-family: "NotoSans-Regular";
	font-size: 4.375rem;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
`;

export default ModalContagemExercicio;
