import React, {
	ReactElement,
	forwardRef,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";

// import { SvgProps } from 'react-native-svg';
import { useDispatch, useSelector } from "react-redux";
import { useField } from "@unform/core";
import {
	ControllerFieldState,
	ControllerRenderProps,
	FieldValues,
	UseFormRegisterReturn,
	UseFormStateReturn,
	useForm,
	useFormContext,
} from "react-hook-form";

import {
	ContainerIcon,
	ContainerInputForm,
	ContentInput,
	HandleIcon,
	Input,
} from "./style";
import { InputTextEntryFormProps } from "./Models";
import { setMaskCPF, setMaskPhone } from "../../utils/funcs";

import { TextsProps } from "../../database/txt.interface";

import Text from "../../components/Texts/Text";

import { Colors } from "../../styles/colors";

import { AuthActions } from "../../store/ducks/auth";

import EmailIcon from "../../assets/kinologyIcons/svg/Email_icon.svg";
import KeyPassword from "../../assets/kinologyIcons/svg/key_password.svg";
import CloseEye from "../../assets/kinologyIcons/svg/security_eye_icon.svg";
import DigitalIcon from "../../assets/kinologyIcons/svg/Digital_icon.svg";
import PositionIcon from "../../assets/kinologyIcons/svg/Position_icon.svg";
import Phone from "../../assets/kinologyIcons/svg/Phone.svg";
import Tag from "../../assets/kinologyIcons/svg/TagForm.svg";
import Business from "../../assets/kinologyIcons/svg/Business.svg";
import CreditCard from "../../assets/kinologyIcons/svg/credit_card_Input.svg";
import Patient from "../../assets/kinologyIcons/svg/account_input.svg";
import Calendar from "../../assets/kinologyIcons/svg/calendar_month.svg";
import Mass from "../../assets/kinologyIcons/svg/IconMass.svg";
import Height from "../../assets/kinologyIcons/svg/heightIcon.svg";
import { P2Desk } from "../../styles/styles_text";
import { Icon } from "../../styles/global";

type IconType = ReactElement;
enum MaskProps {
	Phone = "cel-phone",
	CreditCard = "credit-card",
	CPF = "cpf",
	CNPJ = "cnpj",
	Money = "money",
	E_mail = "e-mail",
}

interface InputRef {
	focus(): void;
}
interface InputValueReference {
	value: string;
}

type Mask = Pick<InputTextEntryFormProps, "mask">;

interface inputProps extends Partial<HTMLInputElement> {
	leftIcon?:
		| "EmailIcon"
		| "KeyPassword"
		| "DigitalIcon"
		| "CloseEye"
		| "PositionIcon"
		| "Phone"
		| "Tag"
		| "Business"
		| "CreditCard"
		| "Patient"
		| "Calendar"
		| "Mass"
		| "Height";
	rightIcon?:
		| "EmailIcon"
		| "KeyPassword"
		| "DigitalIcon"
		| "CloseEye"
		| "PositionIcon"
		| "Tag"
		| "Business"
		| "CreditCard"
		| "Patient"
		| "Calendar"
		| "Mass"
		| "Height";
}

enum InputNameType {
	LOGIN_PASSWORD = "login_password",
	PASSWORD = "password",
	NEW_PASSWORD = "new_password",
	CONFIRM_PASSWORD = "confirm_password",
	USUARIO_SENHA = "USUARIO_SENHA",
	USUARIO_SENHA_CONFIRM = "USUARIO_SENHA_CONFIRM",
}

type InputRules = {
	[key: string]: {
		required?: string;
		pattern?: {
			value: RegExp;
			message: string;
		};
		minLength?: {
			value: number;
			message: string;
		};
		maxLength?: {
			value: number;
			message: string;
		};
		max?: number;
		min?: number;
		validate?: (value: any) => boolean | string | undefined;
		onChange?: (value: any) => void;
	};
};

const InputTextEntryForm: React.ForwardRefRenderFunction<
	InputRef,
	inputProps & InputTextEntryFormProps
> = (
	{
		type,
		typeInputName,
		testId,
		title,
		width,
		height,
		marginTop,
		margintBottom,
		marginLeft,
		marginRight,
		paddingLeft,
		paddingRight,
		paddingTop,
		paddingBottom,
		bgColor,
		borderRadius,
		borderWidth,
		borderBottomWidth,
		borderBottomColor,
		borderColor,
		justifyContent,
		alignItems,
		flexDirection,
		textColor = Colors?.primary_blue_k_main,
		fontSize,
		textAlign = "left",
		textDecoration,
		fontFamily,
		fontWeight,
		leftIcon,
		rightIcon,
		placeholder,
		mask,
		security = !!(type == "password") ? true : false,
		disabled = false,
		...props
	},
	ref
) => {
	const dispatch = useDispatch();
	const Context = useFormContext();

	const [isSecurity, setIsSecurity] = useState(security);
	const [value, setValue] = useState("");

	const eye = useSelector((state: any) => state.auth.eye);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const iconType = {
		EmailIcon: EmailIcon,
		KeyPassword: KeyPassword,
		CloseEye: CloseEye,
		DigitalIcon: DigitalIcon,
		PositionIcon: PositionIcon,
		Phone: Phone,
		Tag: Tag,
		Business: Business,
		CreditCard: CreditCard,
		Patient: Patient,
		Calendar: Calendar,
		Mass: Mass,
		Height: Height,
	};

	const commonRules = {
		onChange: (nativeEvent: { target: { value: string } }) =>
			setValue(nativeEvent?.target?.value),
	};

	const rules: InputRules = {
		email: {
			required: `${current_language?.login_input_senha}`,
			pattern: {
				value:
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				message: `${current_language?.register_email_valido}`,
			},
		},
		confirm_email: {
			validate: (value: string) => {
				const email = Context.watch("email");
				return value === email || current_language?.email_deve_corresponder;
			},
		},
		confirm_password: {
			required: `${current_language?.login_input_senha}`,
			minLength: {
				value: 8,
				message: current_language?.alterar_senha_minimo,
			},
			pattern: {
				value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
				message: current_language?.password_requiriments,
			},
			validate: (value: string) => {
				const password = Context.watch("password");
				return value === password || `${current_language?.confirmar_senha}`;
			},
		},
		password: {
			required: `${current_language?.login_input_senha}`,
			minLength: {
				value: 8,
				message: current_language?.register_aviso_min_8,
			},
		},
		nome: {
			required: `${current_language?.login_input_senha}`,
			pattern: {
				value: /\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú][A-Za-zÀ-ú]{2,}\b/gi,
				message: `${current_language?.pagamento_nome_invalido}`,
			},
		},
		cpf: {
			required: `${current_language?.login_input_senha}`,
			pattern: {
				value:
					/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/i,
				message: `${current_language?.cpf_invalido}`,
			},
			onChange: (nativeEvent: { target: { value: string } }) =>
				Context.setValue("cpf", setMaskCPF(nativeEvent?.target?.value)),
		},
		nacionalidade: {
			required: `${current_language?.login_input_senha}`,
		},
		confirm: {
			required: `${current_language?.login_input_senha}`,
			minLength: {
				value: 8,
				message: current_language?.alterar_senha_minimo,
			},
			pattern: {
				value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
				message: current_language?.password_requiriments,
			},
		},
		celular: {
			required: `${current_language?.login_input_senha}`,
			onChange: (nativeEvent: { target: { value: string } }) =>
				Context.setValue("celular", setMaskPhone(nativeEvent?.target?.value)),
		},
		etiqueta: {
			required: `${current_language?.login_input_senha}`,
			minLength: {
				value: 6,
				message: current_language?.min_6_characters,
			},
		},
		senha: {
			required: `${current_language?.login_input_senha}`,
			minLength: {
				value: 8,
				message: current_language?.alterar_senha_minimo,
			},
		},
		novaSenha: {
			required: `${current_language?.login_input_senha}`,
			minLength: {
				value: 8,
				message: current_language?.alterar_senha_minimo,
			},
			pattern: {
				value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
				message: current_language?.password_requiriments,
			},
		},
		confirmeSenha: {
			required: `${current_language?.login_input_senha}`,
			minLength: {
				value: 8,
				message: current_language?.alterar_senha_minimo,
			},
			pattern: {
				value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
				message: current_language?.password_requiriments,
			},
			validate: (value) =>
				value === Context.getValues("novaSenha") ||
				`${current_language?.confirmar_senha}`,
		},
		conselho: {
			required: `${current_language?.login_input_senha}`,
		},
		nConselho: {
			required: `${current_language?.login_input_senha}`,
			minLength: {
				value: 4,
				message: current_language?.min_4_characters,
			},
		},
		numeroCartao: {
			required: `${current_language?.login_input_senha}`,
			pattern: {
				value: /^[0-9]+$/i,
				message: `${current_language?.pagamento_somente_numero}`,
			},
		},
		mesCartao: {
			required: `${current_language?.login_input_senha}`,
			pattern: {
				value: /^(0[1-9]|1[012])$/,
				message: `${current_language?.pagamento_dois_numeros}`,
			},
		},
		anoCartao: {
			required: `${current_language?.login_input_senha}`,
			pattern: {
				value: /^(20[2-9]\d{1})$/,
				message: `${current_language?.pagamento_quatro_numeros}`,
			},
		},
		cvv: {
			required: `${current_language?.login_input_senha}`,
			pattern: {
				value: /^([0-9]\d{2})$/,
				message: `${current_language?.pagamento_tres_numeros}`,
			},
		},
		EditNome: {
			required: `${current_language?.login_input_senha}`,
			max: 35,
			min: 3,
			pattern: {
				value: /[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{3,35}/i,
				message: `${current_language?.pagamento_nome_invalido}`,
			},
		},
		EditNascimento: {
			required: `${current_language?.login_input_senha}`,
			pattern: {
				value:
					/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i,
				message: `${current_language?.data_invalida}`,
			},
		},
		EditCPF: {
			pattern: {
				value:
					/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/i,
				message: `${current_language?.cpf_invalido}`,
			},
		},
		EditEmail: {
			pattern: {
				value:
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				message: `${current_language?.email_invalido}`,
			},
		},
		EditMassa: {
			pattern: {
				value: /^[0-9]/g,
				message: `${current_language?.apenas_numeros}`,
			},
		},
		EditAltura: {
			pattern: {
				value: /^[0-9]/g,
				message: `${current_language?.apenas_numeros}`,
			},
		},
		EditSexo: {
			required: `${current_language?.login_input_senha}`,
		},
		EditCelular: {
			maxLength: {
				value: 15,
				message: "Maximo 15 números",
			},
			onChange: (nativeEvent: { target: { value: string } }) =>
				Context.setValue(
					"EditCelular",
					setMaskPhone(nativeEvent?.target?.value)
				),
		},
	};

	const handleIconAction = () => {
		switch (rightIcon) {
			case "CloseEye":
				setIsSecurity(!isSecurity);
				break;
			default:
				break;
		}
	};

	return (
		<ContainerInputForm
			typeInputName={typeInputName}
			width={width}
			height={height}
			bgColor={bgColor}
			marginTop={marginTop}
			marginLeft={marginLeft}
			marginRight={marginRight}
			paddingTop={paddingTop}
			paddingBottom={paddingBottom}
			paddingLeft={paddingLeft}
			paddingRight={paddingRight}
			borderRadius={borderRadius}
			borderWidth={borderWidth}
			borderBottomWidth={borderBottomWidth}
			borderBottomColor={borderBottomColor}
			borderColor={borderColor}
			justifyContent={justifyContent}
			alignItems={alignItems}
			flexDirection={flexDirection}
			textColor={textColor}
			fontSize={fontSize}
			textAlign={textAlign}
			textDecoration={textDecoration}
		>
			<P2Desk><strong>{title}</strong></P2Desk>
			<ContentInput
				borderBottomColor={
					disabled
						? "transparent"
						: Context?.formState?.errors?.[typeInputName] &&
						  Colors?.tertirary_red_1_dark
				}
			>
				{leftIcon && (
					<ContainerIcon onClick={() => {}}>
						<Icon style={{width: '1.5rem', height: '1.5rem'}} src={iconType[leftIcon!]} />
					</ContainerIcon>
				)}
				<Input
					placeholder={placeholder}
					onKeyDown={() => props?.onkeydown}
					type={isSecurity ? "password" : "text"}
					autoCapitalize={props?.autocapitalize}
					autoComplete="off"
					disabled={disabled}
					style={{
						color: textColor,
						paddingLeft: leftIcon ? 15 : 0,
						height: 40,
						backgroundColor: "#FAFAFA",
						border: "none",
						fontSize: "1rem",
						width:
							(leftIcon && !rightIcon) || (!leftIcon && rightIcon)
								? "90%"
								: "100%",
					}}
					{...Context?.register(typeInputName, rules[typeInputName])}
				/>
				{rightIcon && (
					<ContainerIcon onClick={() => handleIconAction()}>
						<HandleIcon
							src={iconType[rightIcon!]}
							style={{ marginRight: 20 }}
						/>
					</ContainerIcon>
				)}
			</ContentInput>
			{Context?.formState?.errors?.[typeInputName] && (
				<Text
					title={Context?.formState?.errors[typeInputName]?.message}
					textColor={Colors?.tertirary_red_1_dark}
					textAlign={"left"}
					fontSize={0.87}
					fontFamily={fontFamily}
					marginTop={8}
				/>
			)}
		</ContainerInputForm>
	);
};

export default forwardRef(InputTextEntryForm);
