export const Types = {
	FATURAS_REQUEST: "FATURAS_REQUEST",
	FATURAS_SUCCESS: "FATURAS_SUCCESS",
	FATURAS_FAILED: "FATURAS_FAILED",

	ADD_CARD_REQUEST: "ADD_CARD_REQUEST",
	ADD_CARD_CANCEL: "ADD_CARD_CANCEL",

	EDITAR_METODO_PAGAMEMTO: "EDITAR_METODO_PAGAMEMTO",

	PLANO_SELECIONADO: "PLANO_SELECIONADO",
	CONTRATO: "CONTRATO",
	TIPO: "TIPO",

	PLANOS_UPSELL: "PLANOS_UPSELL",
	ITENS_UPSELL: "ITENS_UPSELL",
	PLANOS_ITENS: "PLANOS_ITENS",
	LOADING_PLANOS_ITENS: "LOADING_PLANOS_ITENS",

	ASSINAR_PLANO: "ASSINAR_PLANO",
	ASSINAR_ITEM: "ASSINAR_ITEM",

	SELECT_ITEM: "SELECT_ITEM",
	SET_INFO_CARTAO_FALTANDO: 'SET_INFO_CARTAO_FALTANDO',
};

const INITIAL_STATE = {
	loading: false,
	loading_card: false,
	cartao: null,
	faturas: [],
	editarMetodoPagamento: null,
	plano: null,
	contrato: null,
	tipo: null,
	planos_upsell: [],
	itens_upsell: [],
	loading_upsell: false,

	assinar_plano: null,
	assinar_item: null,

	select_item: "",
	cartao_faltando: null,
};

export default function financial(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.FATURAS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case Types.ADD_CARD_REQUEST:
			return {
				...state,
				loading_card: true,
			};
		case Types.FATURAS_SUCCESS:
			return {
				...state,
				loading: false,
				cartao: action.payload.cartao,
				faturas: action.payload.faturas,
			};
		case Types.FATURAS_FAILED:
			return {
				...state,
				loading: false,
			};
		case Types.ADD_CARD_CANCEL:
			return {
				...state,
				loading_card: false,
			};
		case Types.EDITAR_METODO_PAGAMEMTO:
			return {
				...state,
				editarMetodoPagamento: action.payload,
			};
		case Types.PLANO_SELECIONADO:
			return {
				...state,
				plano: action.payload,
			};
		case Types.CONTRATO:
			return {
				...state,
				contrato: action.payload,
			};
		case Types.TIPO:
			return {
				...state,
				tipo: action.payload,
			};
		case Types.PLANOS_UPSELL:
			return {
				...state,
				planos_upsell: action.payload,
			};
		case Types.ITENS_UPSELL:
			return {
				...state,
				itens_upsell: action.payload,
			};
		case Types.PLANOS_ITENS:
			return {
				...state,
			};
		case Types.LOADING_PLANOS_ITENS:
			return {
				...state,
				loading_upsell: action.payload,
			};
		case Types.ASSINAR_PLANO:
			return {
				...state,
				assinar_plano: action.payload,
			};
		case Types.ASSINAR_ITEM:
			return {
				...state,
				assinar_item: action.payload,
			};
		case Types.SELECT_ITEM:
			return {
				...state,
				select_item: action.payload,
			};
		case Types.SET_INFO_CARTAO_FALTANDO:
			return {
				...state,
				cartao_faltando: action.payload
			};
		default:
			return state;
	}
}

export const FinancialActions = {
	financial_request: (SERVICO_IUGU: any, SERVICO_SUPERLOGICA: number | string) => ({
		type: Types.FATURAS_REQUEST,
		payload: {SERVICO_IUGU, SERVICO_SUPERLOGICA},
	}),
	financial_request_sucess: (cartao: any, faturas: any) => ({
		type: Types.FATURAS_SUCCESS,
		payload: { cartao, faturas },
	}),
	financial_request_failed: () => ({
		type: Types.FATURAS_FAILED,
	}),
	add_card_request: (data: any) => ({
		type: Types.ADD_CARD_REQUEST,
		payload: data,
	}),
	add_card_cancel: () => ({
		type: Types.ADD_CARD_CANCEL,
	}),
	set_editar_metodo_pagamento: (MetodoPagamento: any) => ({
		type: Types.EDITAR_METODO_PAGAMEMTO,
		payload: MetodoPagamento,
	}),
	set_plano_selecionado: (plano: any) => ({
		type: Types.PLANO_SELECIONADO,
		payload: plano,
	}),
	set_contrato: (contrato: any) => ({
		type: Types.CONTRATO,
		payload: contrato,
	}),
	set_tipo: (tipo: any) => ({
		type: Types.TIPO,
		payload: tipo,
	}),
	set_plano_upsell: (plano: any) => ({
		type: Types.PLANOS_UPSELL,
		payload: plano,
	}),
	set_item_upsell: (item: any) => ({
		type: Types.ITENS_UPSELL,
		payload: item,
	}),
	set_planos_itens: () => ({
		type: Types.PLANOS_ITENS,
	}),
	set_loading_upsell: (status: any) => ({
		type: Types.LOADING_PLANOS_ITENS,
		payload: status,
	}),
	set_assinar_plano: (plano: any) => ({
		type: Types.ASSINAR_PLANO,
		payload: plano,
	}),
	set_assinar_item: (item: any) => ({
		type: Types.ASSINAR_ITEM,
		payload: item,
	}),
	set_select_item: (item: any) => ({
		type: Types.SELECT_ITEM,
		payload: item,
	}),
	set_info_cartao_faltando: (status: boolean)=> ({
		type: Types.SET_INFO_CARTAO_FALTANDO,
		payload: status
	}),
};
