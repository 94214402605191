import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import moment from "moment";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../../assets/kinologyIcons/svg/close.svg";
import {ReactComponent as Worning} from "../../../assets/kinologyIcons/svg/warningForgot.svg";
import { TextsProps } from "../../../database/txt.interface";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { AppActions } from "../../../store/ducks/app";
import { ExerciciosActions } from "../../../store/ducks/exer";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { Button, Container } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import Text from '../../../components/Texts/Text';
import { Colors } from "../../../styles/colors";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

enum recoverDeleted {
	account = 'conta',
	accounts = 'contas',
	exercise = 'exercicio',
	exercises = 'exercicios',
};

const ModalRecuperar: React.FC = () => {
	const dispatch = useDispatch();
	const classes = useStyles();

	// comunica com redux
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	interface RecuperarProps {
		mode: string;
		id: any;
	}

	const selected_patients = useSelector(
		(state: any) => state.pac_aval.selected_patients
	);

	const lista_excluidos = useSelector(
		(state: any) => state.app.lista_excluidos
	);

	const exerciciosRecuperar = useSelector(
		(state: any) => state.exercicios.exerciciosRecuperar
	);

	const execucoesExcluidas = useSelector(
		(state: any) => state.exercicios.execucoesExcluidas
	);

	// função que abre a modal, mais sobre ela em store/ducks/app.ts
	const parameter: RecuperarProps = useSelector(
		(state: any) => state.app.is_open_modal_recuperar
	);

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	// função para fechar modal
	const ModalCadastroClose = () => {
		if(parameter.mode === recoverDeleted.account ){
			AmplitudeLog('recupe_paci_cancelado')
		}
		if(parameter.mode === recoverDeleted.accounts){
			AmplitudeLog('recupe_paci_cancelado')
		}
		if(parameter.mode === recoverDeleted.exercise){
			AmplitudeLog('recupe_exer_cancelado')
		}
		if(parameter.mode === recoverDeleted.exercises){
			AmplitudeLog('recupe_exer_cancelado')
		}
		dispatch(AppActions.set_modal_recuperar(false));
		dispatch(ExerciciosActions.set_reset_exercices());
	};

	//Funcão para excluir
	let request:any = {}
	const Recuperar = () => {
		if (parameter.mode === "conta") {

			dispatch(AddPacAvalActions.set_flag_filtro(true));

			request["0"] = {TOKEN: user_logado.token}
			request["1"] = {
				ID_USUARIO: parameter.id,
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			};
			dispatch(AddPacAvalActions.request_recuperar_paci(request));
	
			AmplitudeLog('paci_recuperado',{body:{request}})

		} else if (parameter.mode === "contas") {

			dispatch(AddPacAvalActions.set_flag_filtro(true));

			const formatObjet: any = {}
			
			formatObjet["0"] = {
				TOKEN: user_logado?.token,
			};

			selected_patients?.forEach((
				objeto: { ID_USUARIO: number; txDataHora: string }, 
				indice:  number
			) => {
					formatObjet[indice + 1] = {
						txDataHora: moment(new Date())
							.format('YYYY-MM-DD HH:mm:ss')
							.toString(),
						ID_USUARIO: objeto?.ID_USUARIO,
					};
			});

			dispatch(AddPacAvalActions.request_recuperar_paci(formatObjet))

		} else if (parameter.mode === "exercicio") {
			
			request = {
				ID_EXERCICIO: parameter.id,
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			};
			dispatch(ExerciciosActions.recuperar_exercicio_request(request));
			AmplitudeLog('exercicio_recuperado',{body:request})
		} else if (parameter.mode === "exercicios") {
		
			exerciciosRecuperar.map((e: any) => {
				request = {
					ID_EXERCICIO: e,
					txDataHora: moment(new Date())
						.format("YYYY-MM-DD HH:mm:ss")
						.toString(),
				};
				dispatch(ExerciciosActions.recuperar_exercicio_request(request));
				AmplitudeLog('exercicios_recuperados',{body:request})
			});
			dispatch(ExerciciosActions.set_reset_exercices());
			
		} else if (parameter.mode === "exec") {
			request = {
				ID_EXECUCAO: parameter.id,
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			};
			dispatch(ExerciciosActions.recuperar_execucao_request(request));
		} else if (parameter.mode === "execs") {
			execucoesExcluidas.map((e: any) => {
				request = {
					ID_EXECUCAO: e,
					txDataHora: moment(new Date())
						.format("YYYY-MM-DD HH:mm:ss")
						.toString(),
				};
				dispatch(ExerciciosActions.recuperar_execucao_request(request));
			});
			dispatch(ExerciciosActions.set_reset_exercices());
		}
		dispatch(PacAvalActions.reset_patient());
		dispatch(AppActions.set_lista_excluidos([]));
		dispatch(AppActions.set_modal_recuperar(false));
		dispatch(ExerciciosActions.set_reset_exercices());
	};

	return (
		<div>
			{/* librarie para abrir o modal */}
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={!!parameter}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={!!parameter}>
					{/* modal com as infos, feito html e css */}
					<Container>
						<img
							className="close"
							src={buttonClose}
							onClick={ModalCadastroClose}
							alt="Close"
						/>
						<Worning />
						<Text
							title={
								parameter.mode == "conta" || parameter.mode == "contas"
									? current_language?.deseja_recuperar_usuarios
									: parameter.mode == "exercicio" || parameter.mode == "exercicios"
										? current_language?.deseja_recuperar_exercicios
										:  parameter.mode == "exec" || parameter.mode == "execs" 
											? current_language?.deseja_recuperar_execucoes
											: ''
							}
							fontFamily="NotoSans-Bold"
							fontSize={1.85}
							fontWeight={700}
							textColor={Colors?.primary_blue_k_main}
							marginTop={32}
						/>
						<Text
							title={
								parameter.mode == "conta" || parameter.mode == "contas"
									? current_language?.recuperar_usuarios
									: parameter.mode == "exercicio" || parameter.mode == "exercicios"
										? current_language?.recuperar_exercicios
										:  parameter.mode == "exec" || parameter.mode == "execs" 
											? current_language?.recuperar_execucoes
											: ''
								
							}
							fontFamily="NotoSans-Regular"
							fontSize={1.3}
							fontWeight={400}
							textColor={Colors?.neutral_grey_k_60}
							marginTop={18}
						/>
						
						<div className="buttons">
							<Button onClick={Recuperar} >
								<Text
									title={current_language?.restaurar}
									fontFamily="Kanit-Regular"
									fontSize={1.37}
									fontWeight={600}
									textColor={Colors?.primary_blue_k_main}
									cursor="pointer"
								/>
							</Button>
							<Button  onClick={ModalCadastroClose}>
								<Text
									title={current_language?.botao_cancelar}
									fontFamily="Kanit-Regular"
									fontSize={1.37}
									fontWeight={600}
									textColor={Colors?.primary_blue_k_main}
									cursor="pointer"
								/>
							</Button>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default  memo(ModalRecuperar);
