import React from "react";
import styled from "styled-components";

import Menu from "../../../../components/Menu";
import ContentDash from "../../../DashPaciente/ContentDash";

const EditarMetodoPagamentoController: React.FC = () => {
	return (
		<ViewPort>
			<Menu></Menu>
			<ContentDash listagem={"editarMetodoPagamento"} />
		</ViewPort>
	);
};

export const ViewPort = styled.div`
	display: flex;
`;

export default EditarMetodoPagamentoController;
