import React, { useEffect, useState } from 'react';
import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import { Container,  } from './styles';

import  ImageBG from "../../../assets/icons/ImageBG.svg";
import  PositivoNegativo from "../../../assets/icons/PositivoNegativo.svg";
import  Positive from "../../../assets/icons/Positive.svg";
import { PacientesProps } from '../../../interfaces/pacientes.interface';
import { ProtocolosProps } from '../../../interfaces/protocolo.interface';
import { DashBoardActions } from '../../../store/ducks/dashboard';
import moment from 'moment';
import { TextsProps } from '../../../database/txt.interface';
import { AppActions } from '../../../store/ducks/app';


enum ModalUsage {
    PROTOCOLOS = 'PROTOCOLOS',
};

const ModalSatisfacao:React.FC = ()=> {

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            modal: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            paper: {
                backgroundColor: theme.palette.background.paper,
                border: "0",
                boxShadow: theme.shadows[5],
            },
        })
    );
    const dispatch = useDispatch();
    const classes = useStyles();
    const open_modal_satisfacao: boolean = useSelector((state:any)=> state.app.set_modal_satisfacao)
    const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
    const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
    const protocolo_list: ProtocolosProps = useSelector(
		(state: any) => state.app.protocolo_list
	);
    

    return (
        <div>
            <Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open_modal_satisfacao}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
                    <Fade in={open_modal_satisfacao}>
                        <Container>
                          <div className='containerBg'>
                            <div className='BG'>
                                <img src={ImageBG} alt="" />
                                <img src={PositivoNegativo} alt="" style={{position: 'absolute'}}/>
                            </div>
                          </div>
                          <div className='ContainerText'>
                            <text className='Text'>
                                { ModalUsage.PROTOCOLOS &&
                                    current_language?.voce_esta_satisfeito
                                }
                            </text>
                            <div className='containerNomePaci'>
                                <text className='namePaci'>
                                    {paciente?.USUARIO_NOME?.toUpperCase()}
                                </text>
                                <p className='interrogacao'>
                                    {" "} { "?"}
                                </p>
                            </div>
                          </div>
                          <div className='containerButtons'>
                            <div 
                                className='Positive'
                                onClick={()=> {
                                    dispatch(DashBoardActions.set_salvar_questionario_satisfacao(
                                        moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
                                        "PROTOCOLOS",
                                        ["S"],
                                        [""],
                                        protocolo_list?.ID_PROTOCOLO,
                                    ))
                                    dispatch(AppActions.set_modal_satisfacao(false))
                                }}
                            >
                                <img src={Positive} alt="" />
                            </div>
                            <div 
                                className='Negative'
                                onClick={()=> {
                                    dispatch(DashBoardActions.set_salvar_questionario_satisfacao(
                                        moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
                                        "PROTOCOLOS",
                                        ["N"],
                                        [""],
                                        protocolo_list?.ID_PROTOCOLO,
                                    ))
                                    dispatch(AppActions.set_modal_satisfacao(false))
                                }}
                            >
                             <img src={Positive} alt="" />
                            </div>
                          </div>
                          <div 
                             className='footer'
                             onClick={()=> {
                                dispatch(DashBoardActions.set_salvar_questionario_satisfacao(
                                    moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
                                    "PROTOCOLOS",
                                    ["PULOU"],
                                    [""],
                                    protocolo_list?.ID_PROTOCOLO,
                                ))
                                dispatch(AppActions.set_modal_satisfacao(false))
                            }}  
                          >
                            <text className='pular'>{current_language?.jump}</text>
                          </div>
                        </Container>
                    </Fade>
				
			</Modal>
        </div>
        
    )
}
export default ModalSatisfacao;