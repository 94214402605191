import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { P1Desk } from "../../styles/styles_text";
import { AppActions } from "../../store/ducks/app";

const Descanso: React.FC = () => {

	const dispatch = useDispatch();

	const intervalo_descanso = useSelector(
		(state: any) => state.configs.intervalo_descanso
	);

	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const [tempo, setTempo] = useState(intervalo_descanso);
	const resultado_execucao = useSelector(
		(state: any) => state.app.resultado_execucao
	);

	useEffect(() => {
		let tempoExec = false;
		if (resultado_execucao) {
			if (tempo >= 0) {
				tempoExec = true;
			}else{
				dispatch(AppActions.set_open_modal_fim_descanso(true))
			}
			setTimeout(() => {
				if (tempoExec) {
					setTempo(tempo - 1);
					setMinutes(Math.trunc(tempo / 60));
					setSeconds(tempo % 60);
				}
			}, 1000);
		} else if (resultado_execucao === false) {
			return;
		}
	}, [resultado_execucao, tempo]);
	return (
		<P1Desk style={{ color: "#D12600" }}>
			<i>
				{minutes <= 9 ? ` 0${minutes}` : minutes}:
				{seconds <= 9 ? `0${seconds}` : seconds}
			</i>
		</P1Desk>
	);
};

export default memo(Descanso);
