import React from "react";

type type_sexo = "masculino" | "feminino";
type type_posicao = "frontal" | "dorsal";
type type_agrupamento = "superior" | "inferior";

interface GetIconProps {
	classe: string;
	sexo: type_sexo;
	posicao: type_posicao;
	agrupamento: type_agrupamento;
	musculo: string;
	label_musculo: number;
}

const GetDotsNumber: React.FC<GetIconProps> = ({
	classe,
	sexo,
	posicao,
	agrupamento,
	musculo,
	label_musculo
}) => {

	function _render_dots_superiores_frontal_masculino() {
		const svgString = `
		<svg width="647" height="1180" viewBox="0 0 647 1180" fill="none" xmlns="http://www.w3.org/2000/svg">

		${musculo == 'pescoco' && `
			Esquerdo <ellipse cx="399.082" cy="157.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			Direito <ellipse cx="245.86" cy="157.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}

		${musculo == 'deltoides' && `
			Esquerdo <ellipse cx="508.24" cy="218.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			Direito	<ellipse cx="138.705" cy="218.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}

		${musculo == 'peitoral' && `
			Esquerdo <ellipse cx="374.677" cy="284.117" rx="22.537" ry="22.5043" fill="#414D4A"/>
			Direito <ellipse cx="261.992" cy="284.117" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}

		${musculo == 'biceps' && `
			Esquerdo <ellipse cx="474.026" cy="346.257" rx="22.537" ry="22.5043" fill="#414D4A"/>
			Direito <ellipse cx="171.844" cy="346.257" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}

		${musculo == 'antebraco' && `
			Esquerdo <ellipse cx="508.24" cy="473.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			Direito <ellipse cx="133.698" cy="473.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}

		${musculo == 'obliquos' && `
			Esquerdo <ellipse cx="416.934" cy="438.087" rx="22.537" ry="22.5043" fill="#414D4A"/>
			Direito <ellipse cx="216.918" cy="438.087" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}

		${musculo == 'abdomen' && `
			Esquerdo <ellipse cx="355.018" cy="496.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			Direito <ellipse cx="292.928" cy="496.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}

		${musculo == 'polegar' && `
			Esquerdo <ellipse cx="623.537" cy="598.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			Direito <ellipse cx="23.537" cy="598.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}

		${musculo == 'anelar' && `
			Esquerdo <ellipse cx="562.318" cy="643.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			Direito <ellipse cx="81.6224" cy="643.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}

		Numeros

		${musculo == 'pescoco' && `
			<text x="399.082" y="157.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="245.86" y="157.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		${musculo == 'deltoides' && `
			<text x="508.24" y="218.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="138.705" y="218.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		${musculo == 'peitoral' && `
			<text x="374.677" y="284.117" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="261.992" y="284.117" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		${musculo == 'biceps' && `
			<text x="474.026" y="346.257" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="171.844" y="346.257" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		${musculo == 'antebraco' && `
			<text x="508.24" y="473.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="133.698" y="473.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		${musculo == 'obliquos' && `
			<text x="416.934" y="438.087" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="216.918" y="438.087" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		${musculo == 'abdomen' && `
			<text x="355.018" y="496.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="292.928" y="496.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		${musculo == 'polegar' && `
			<text x="623.537" y="598.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="23.537" y="598.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		${musculo == 'anelar' && `
			<text x="562.318" y="643.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="81.6224" y="643.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		</svg>
		`;
		const svgBase64 = `data:image/svg+xml;base64,${btoa(svgString)}`;
		return svgBase64;
	}

	const _render_dots_superiores_dorsal_masculino = () => {
		const svgString = `
		<svg width="647" height="1179" viewBox="0 0 647 1179" fill="none" xmlns="http://www.w3.org/2000/svg">
			
		${musculo == 'trapezio' && `
			<ellipse cx="245.504" cy="157.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="398.504" cy="157.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>	
		`}
		${musculo == 'deltoides' && `
			<ellipse cx="507.504" cy="218.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="138.504" cy="218.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
		`}
		${musculo == 'infraespinhal' && `
			<ellipse cx="387.504" cy="359.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="260.504" cy="359.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
		`}

		${musculo == 'triceps' && `
			<ellipse cx="472.504" cy="327.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="176.504" cy="327.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
		`}

		${musculo == 'antebraco' && `
			<ellipse cx="515.504" cy="473.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="133.504" cy="473.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>	
		`}

		${musculo == 'dorsal' && `
			<ellipse cx="406.504" cy="257.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="240.504" cy="257.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
		`}

		${musculo == 'obliquo_externo' && `
			<ellipse cx="422.231" cy="449.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="222.504" cy="449.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>	
		`}
		${musculo == 'lombar' && `
			<ellipse cx="292.504" cy="492.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="354.504" cy="492.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>	
		`}
		${musculo == 'polegar' && `	
			<ellipse cx="23.537" cy="598.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="623.537" cy="598.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}

		${musculo == 'anelar' && `
			<ellipse cx="80.5043" cy="653.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="567.504" cy="653.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
		`}

		Numeros

		${musculo == 'trapezio' && `
			<text x="245.504" y="157.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="398.504" y="157.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}
		${musculo == 'deltoides' && `
			<text x="507.504" y="218.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="138.504" y="218.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}
		${musculo == 'infraespinhal' && `
			<text x="387.504" y="359.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="260.504" y="359.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		${musculo == 'triceps' && `
			<text x="472.504" y="327.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="176.504" y="327.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		${musculo == 'antebraco' && `
			<text x="515.504" y="473.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="133.504" y="473.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		${musculo == 'dorsal' && `
			<text x="406.504" y="257.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="240.504" y="257.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		${musculo == 'obliquo_externo' && `
			<text x="422.231" y="449.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="222.504" y="449.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}
		${musculo == 'lombar' && `
			<text x="292.504" y="492.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="354.504" y="492.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}
		${musculo == 'polegar' && `	
			<text x="23.537" y="598.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="623.537" y="598.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}

		${musculo == 'anelar' && `
			<text x="80.5043" y="653.504"  font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="567.504" y="653.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}
	
		</svg>
		`;
		const svgBase64 = `data:image/svg+xml;base64,${btoa(svgString)}`;
		return svgBase64;
	};

	const _render_dots_inferiores_frontal_masculino = () => {
		const svgString = `
		<svg width="647" height="1180" viewBox="0 0 647 1180" fill="none" xmlns="http://www.w3.org/2000/svg">


			${musculo == 'gluteo_medio' && `
				<ellipse cx="185.773" cy="523.494" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="460.171" cy="523.494" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'reto_femoral' && `
				<ellipse cx="398.537" cy="622.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="248.537" cy="622.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'quadriceps' && `
				<ellipse cx="261.993" cy="711.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="383.537" cy="711.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'joelho' && `
				<ellipse cx="376.049" cy="834.076" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="270.897" cy="834.076" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'tibial_anterior' && `
				<ellipse cx="370.04" cy="960.494" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="271.898" cy="960.494" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'pe_frontal' && `
				<ellipse cx="420.12" cy="1148.49" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="228.836" cy="1148.49" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'pe_interno' && `
				<ellipse cx="352.014" cy="1143.49" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="298.937" cy="1143.49" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}


			Numeros

			${musculo == 'gluteo_medio' && `
				<text x="185.773" y="523.494" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="460.171" y="523.494" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'reto_femoral' && `
				<text x="398.537" y="622.504"  font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="248.537" y="622.504"  font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'quadriceps' && `
				<text x="261.993" y="711.504"  font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="383.537" y="711.504"  font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'joelho' && `
				<text x="376.049" y="834.076"  font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="270.897" y="834.076"  font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'tibial_anterior' && `
				<text x="370.04" y="960.494"  font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="271.898" y="960.494"  font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'pe_frontal' && `
				<text x="420.12" y="1148.49" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="228.836" y="1148.49" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'pe_interno' && `
				<text x="352.014" y="1143.49" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="298.937" y="1143.49" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}

		</svg>
		`;
		const svgBase64 = `data:image/svg+xml;base64,${btoa(svgString)}`;
		return svgBase64;
	};

	const _render_dots_inferiores_dorsal_masculino = () => {
		const svgString = `

		<svg width="647" height="1179" viewBox="0 0 647 1179" fill="none" xmlns="http://www.w3.org/2000/svg">

			${musculo == 'gluteos' && `
				<ellipse cx="271.505" cy="566.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="377.505" cy="566.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'adutor' && `
				<ellipse cx="349.505" cy="678.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="297.505" cy="678.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'posteriores' && `
				<ellipse cx="384.135" cy="746.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="264.613" cy="746.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'panturrilha' && `
				<ellipse cx="375.505" cy="906.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="270.505" cy="906.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>	
			`}
			${musculo == 'pe' && `
				<ellipse cx="372.505" cy="1125.5" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="274.505" cy="1125.5" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}

			Numeros 

			${musculo == 'gluteos' && `
				<text x="271.505" y="566.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="377.505" y="566.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'adutor' && `
				<text x="349.505" y="678.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="297.505" y="678.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'posteriores' && `
				<text x="384.135" y="746.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="264.613" y="746.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'panturrilha' && `
				<text x="375.505" y="906.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="270.505" y="906.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'pe' && `
				<text x="372.505" y="1125.5" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="274.505" y="1125.5" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			
		</svg>
		`;

		const svgBase64 = `data:image/svg+xml;base64,${btoa(svgString)}`;
		return svgBase64;
	};

	//Feminino
	const _render_dots_superiores_frontal_feminino = () => {
		const svgString = `
	


		<svg width="647" height="1284" viewBox="0 0 647 1284" fill="none" xmlns="http://www.w3.org/2000/svg">

			${musculo == 'pescoco' && `	
				<ellipse cx="245.86" cy="157.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="399.082" cy="157.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'deltoides' && `		
				<ellipse cx="508.24" cy="218.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="138.705" cy="218.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'peitoral' && `
				<ellipse cx="374.677" cy="284.118" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="261.992" cy="284.118" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'biceps' && `
				<ellipse cx="474.025" cy="346.256" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="171.844" cy="346.256" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'antebraco' && `		
				<ellipse cx="508.24" cy="473.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="133.698" cy="473.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'obliquos' && `
				<ellipse cx="416.934" cy="438.087" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="216.918" cy="438.087" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'abdomen' && `
				<ellipse cx="292.928" cy="496.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="355.018" cy="496.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'polegar' && `
				<ellipse cx="24.5362" cy="613.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="620.536" cy="613.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'anelar' && `
				<ellipse cx="81.6224" cy="643.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="562.318" cy="643.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			`}



			${musculo == 'pescoco' && `	
				<text x="245.86" y="157.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="399.082" y="157.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'deltoides' && `		
				<text x="508.24" y="218.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="138.705" y="218.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'peitoral' && `
				<text x="374.677" y="284.118" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="261.992" y="284.118" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'biceps' && `
				<text  x="474.025" y="346.256" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="171.844" y="346.256" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'antebraco' && `		
				<text x="508.24" y="473.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="133.698" y="473.504 font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'obliquos' && `
				<text x="416.934" y="438.087" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="216.918" y="438.087" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'abdomen' && `
				<text x="292.928" y="496.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="355.018" y="496.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'polegar' && `
				<text x="24.5362" y="613.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="620.536" y="613.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'anelar' && `
				<text x="81.6224" y="643.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="562.318" y="643.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
		
		</svg>

		`;

		const svgBase64 = `data:image/svg+xml;base64,${btoa(svgString)}`;
		return svgBase64;
	};

	const _render_dots_superiores_dorsal_feminino = () => {
		const svgString = `

		<svg width="647" height="1284" viewBox="0 0 647 1284" fill="none" xmlns="http://www.w3.org/2000/svg">

			${musculo == 'trapezio' && `
				<ellipse cx="245.504" cy="157.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="398.504" cy="157.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'deltoides' && `
				<ellipse cx="507.504" cy="218.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="138.504" cy="218.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'infraespinhal' && `
				<ellipse cx="406.504" cy="257.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="240.504" cy="257.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'triceps' && `
				<ellipse cx="472.504" cy="327.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="176.504" cy="327.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'antebraco' && `
				<ellipse cx="515.504" cy="473.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="133.504" cy="473.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'dorsal' && `
				<ellipse cx="387.504" cy="359.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="260.504" cy="359.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'obliquo_externo' && `
				<ellipse cx="422.231" cy="449.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="222.504" cy="449.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'lombar' && `
				<ellipse cx="292.504" cy="492.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="354.504" cy="492.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'polegar' && `
				<ellipse cx="623.504" cy="613.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="23.5043" cy="613.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'anelar' && `
				<ellipse cx="80.5043" cy="653.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="567.504" cy="653.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}

			Numeros

			${musculo == 'trapezio' && `
				<text x="245.504" y="157.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="398.504" y="157.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'deltoides' && `
				<text x="507.504" y="218.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="138.504" y="218.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'infraespinhal' && `
				<text x="406.504" y="257.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="240.504" y="257.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'triceps' && `
				<text x="472.504" y="327.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="176.504" y="327.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'antebraco' && `
				<text x="515.504" y="473.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="133.504" y="473.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'dorsal' && `
				<text x="387.504" y="359.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="260.504" y="359.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'obliquo_externo' && `
				<text x="422.231" y="449.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="222.504" y="449.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'lombar' && `
				<text x="292.504" y="492.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="354.504" y="492.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'polegar' && `
				<text x="623.504" y="613.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="23.5043" y="613.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'anelar' && `
				<text x="80.5043" y="653.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="567.504" y="653.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
		
		</svg>
		`;

		const svgBase64 = `data:image/svg+xml;base64,${btoa(svgString)}`;
		return svgBase64;
	};

	const _render_dots_inferiores_frontal_feminino = () => {
		const svgString = `
		<svg width="643" height="1282" viewBox="0 0 643 1282" fill="none" xmlns="http://www.w3.org/2000/svg">

		${musculo == 'gluteo_medio' && `
			<ellipse cx="165.773" cy="523.494" rx="22.537" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="473.17" cy="523.494" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}
		${musculo == 'reto_femoral' && `
			<ellipse cx="398.537" cy="637.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="248.537" cy="637.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}
		${musculo == 'quadriceps' && `
			<ellipse cx="261.992" cy="726.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="383.537" cy="726.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}
		${musculo == 'joelho' && `
			<ellipse cx="376.049" cy="891.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="270.896" cy="891.504" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}
		${musculo == 'tibial_anterior' && `
			<ellipse cx="370.041" cy="1042.5" rx="22.537" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="271.898" cy="1042.5" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}
		${musculo == 'pe_frontal' && `
			<ellipse cx="417.119" cy="1247.49" rx="22.537" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="228.836" cy="1247.49" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}
		${musculo == 'pe_interno' && `
			<ellipse cx="352.014" cy="1242.49" rx="22.537" ry="22.5043" fill="#414D4A"/>
			<ellipse cx="298.937" cy="1242.49" rx="22.537" ry="22.5043" fill="#414D4A"/>
		`}


		Numeros 
		
		${musculo == 'gluteo_medio' && `
			<text x="165.773" y="523.494" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="473.17" y="523.494" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}
		${musculo == 'reto_femoral' && `
			<text x="398.537" y="637.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="248.537" y="637.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}
		${musculo == 'quadriceps' && `
			<text x="261.992" y="726.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="383.537" y="726.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}
		${musculo == 'joelho' && `
			<text x="376.049" y="891.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="270.896" y="891.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}
		${musculo == 'tibial_anterior' && `
			<text x="370.041" y="1042.5" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="271.898" y="1042.5" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}
		${musculo == 'pe_frontal' && `
			<text x="417.119" y="1247.49" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="228.836" y="1247.49" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}
		${musculo == 'pe_interno' && `
			<text x="352.014" y="1242.49" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			<text x="298.937" y="1242.49" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
		`}
			
		</svg>
		`;

		const svgBase64 = `data:image/svg+xml;base64,${btoa(svgString)}`;
		return svgBase64;
	};

	const _render_dots_inferiores_dorsal_feminino = () => {
		const svgString = `
		<svg width="647" height="1284" viewBox="0 0 647 1284" fill="none" xmlns="http://www.w3.org/2000/svg">

			${musculo == 'gluteos' && `
				<ellipse cx="271.504" cy="566.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="377.504" cy="566.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'adutor' && `
				<ellipse cx="349.504" cy="678.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="297.504" cy="678.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'posteriores' && `
				<ellipse cx="384.134" cy="746.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="264.613" cy="746.504" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'panturrilha' && `
				<ellipse cx="375.503" cy="1032.5" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="270.503" cy="1032.5" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}
			${musculo == 'pe' && `
				<ellipse cx="372.503" cy="1222.5" rx="22.5043" ry="22.5043" fill="#414D4A"/>
				<ellipse cx="274.503" cy="1222.5" rx="22.5043" ry="22.5043" fill="#414D4A"/>
			`}

			Numeros

			${musculo == 'gluteos' && `
				<text x="271.504" y="566.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="377.504" y="566.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'adutor' && `
				<text x="349.504" y="678.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="297.504" y="678.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'posteriores' && `
				<text x="384.134" y="746.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="264.613" y="746.504" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'panturrilha' && `
				<text x="375.503" y="1032.5" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="270.503" y="1032.5" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
			${musculo == 'pe' && `
				<text x="372.503" y="1222.5" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
				<text x="274.503" y="1222.5" font-family="Arial" font-size="20" fill="white" text-anchor="middle" dominant-baseline="middle">${label_musculo}</text>
			`}
	
		</svg>
		`;

		const svgBase64 = `data:image/svg+xml;base64,${btoa(svgString)}`;
		return svgBase64;
	};

	const renders_dots_functions = {
		superior: {
			frontal: {
				masculino: _render_dots_superiores_frontal_masculino,
				feminino: _render_dots_superiores_frontal_feminino,
			},
			dorsal: {
				masculino: _render_dots_superiores_dorsal_masculino,
				feminino: _render_dots_superiores_dorsal_feminino,
			},
		},
		inferior: {
			frontal: {
				masculino: _render_dots_inferiores_frontal_masculino,
				feminino: _render_dots_inferiores_frontal_feminino,
			},
			dorsal: {
				masculino: _render_dots_inferiores_dorsal_masculino,
				feminino: _render_dots_inferiores_dorsal_feminino,
			},
		},
	};

	return (
		<img
			className={classe}
			src={renders_dots_functions[agrupamento][posicao][sexo]()}
			alt=""
		/>
	);
};

export default GetDotsNumber;

