import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { push } from "connected-react-router";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { AppActions } from "../../../store/ducks/app";
import { getPermission } from "../../../utils/funcs";
import {
	TypesAvalOrFunci,
	TypesPermission,
	TypesProLivre,
} from "../../../utils/types";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";

import { AmplitudeLog } from "../../../utils/amplitude";
import { DescriptionDesk, H3Desk, S2Desk } from "../../../styles/styles_text";
import { Container } from "./styles";
import { ButtonSave, Icon } from "../../../styles/global";
import ModoAvaliacao from "../../../assets/icons/modo_avaliacao.svg";
import FuncinalModo from "../../../assets/icons/funcioanl_modo.svg";
import { DashBoardActions } from "../../../store/ducks/dashboard";
// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalSelectAvaliation: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();
	const is_open_select_avaliacao = useSelector(
		(state: any) => state.app.is_open_select_avaliacao
	);

	const is_selected_avaliacao = useSelector(
		(state: any) => state.app.is_selected_avaliacao
	);

	const ModalCadastroClose = () => {
		dispatch(AppActions.set_modal_select_avaliacao(false));
	};

	const [selected, setSelected] = useState(false);

	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const permissionAndEvaluationChoice = (type_avaliacao: string) => {
		const permissionServicoRM: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_PERCENTUAL_RM
		);
		if (permissionServicoRM) {
			setSelected(true);
			dispatch(AppActions.set_selected_avaliacao(type_avaliacao));
		} else {
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
			dispatch(AppActions.set_modal_select_avaliacao(false));
		}
	};

	const SelectedChoiceAvaliacao = (type_avaliacao: string) => {
		setSelected(true);
		dispatch(AppActions.set_selected_avaliacao(type_avaliacao));
	};

	const is_selected_type_pro_livre = useSelector(
		(state: any) => state.app.is_selected_type_pro_livre
	);

	const Navegar = () => {
		if (is_selected_type_pro_livre === TypesProLivre.LIVRE) {
			if (is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO && selected) {
				dispatch(DashBoardActions.set_current_page("execucao"));
			}
			if (is_selected_avaliacao === TypesAvalOrFunci.FUNCIONAL) {
				dispatch(AppActions.set_open_configuracoes_execucao(true));
			}
			dispatch(AppActions.set_modal_select_avaliacao(false));
		}
		if (is_selected_type_pro_livre === TypesProLivre.PROTOCOLO && selected) {
			dispatch(AppActions.set_checklist_execucao(true));
			dispatch(AppActions.set_modal_select_avaliacao(false));
			dispatch(ManageProtocolActions.set_remove_play(false));
			dispatch(ManageProtocolActions.set_button_goback(true));
			dispatch(DashBoardActions.set_current_page("execucao"));
		}
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_select_avaliacao}
				onClose={ModalCadastroClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_select_avaliacao}>
					<Container>
						<div className="divText">
							<H3Desk>Executar no modo</H3Desk>
						</div>
						<div className="choice">
							<div className="buttons">
								<div
									className={`card ${
										is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO
											? "bordaAzul"
											: "bordaNormal"
									}`}
								>
									<label htmlFor="avaliacao">
										<S2Desk>{current_language?.config_avaliacao}</S2Desk>
										<Icon
											style={{ width: "5rem", height: "5rem" }}
											src={ModoAvaliacao}
										/>
										<p className="textAvFunc">
											{current_language?.avaliacao_explicacao}
											<br />
										</p>
									</label>

									<div className="groupRadio">
										<label
											htmlFor="avaliacao"
											className={
												is_selected_avaliacao === TypesAvalOrFunci.AVALIACAO
													? "checkmarkSeleted"
													: "checkmark"
											}
										></label>
										<input
											className="select"
											type="radio"
											name="radio"
											id="avaliacao"
											onChange={() => {
												SelectedChoiceAvaliacao(TypesAvalOrFunci.AVALIACAO);
												AmplitudeLog(
													is_selected_type_pro_livre === TypesProLivre.LIVRE
														? "exercicio_modo_avaliacao"
														: "proto_modo_avaliacao"
												);
											}}
										/>
									</div>
								</div>

								<div
									className={`card ${
										is_selected_avaliacao === TypesAvalOrFunci.FUNCIONAL
											? "bordaAzul"
											: "bordaNormal"
									}`}
								>
									<label htmlFor="funcional">
										<S2Desk>{current_language?.config_funcional}</S2Desk>
										<Icon
											style={{ width: "5rem", height: "5rem" }}
											src={FuncinalModo}
										/>
										<p className="textAvFunc">
											{current_language?.modal_funcional_explicacao}
										</p>
									</label>

									<div className="groupRadio">
										<label
											htmlFor="funcional"
											className={
												is_selected_avaliacao === TypesAvalOrFunci.FUNCIONAL
													? "checkmarkSeleted"
													: "checkmark"
											}
										></label>
										<input
											className="select"
											type="radio"
											name="radio"
											id="funcional"
											onChange={() => {
												permissionAndEvaluationChoice(
													TypesAvalOrFunci.FUNCIONAL
												);
												AmplitudeLog(
													is_selected_type_pro_livre === TypesProLivre.LIVRE
														? "exercicio_modo_funcional"
														: "proto_modo_funcional"
												);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="botao">
							<ButtonSave
								active={true}
								className={selected ? "button" : "button_desligado"}
								onClick={() => {
									Navegar();
									AmplitudeLog("modo_selecionado");
								}}
							>
								Avançar
							</ButtonSave>
						</div>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalSelectAvaliation);
