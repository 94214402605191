const handleMaskCPF = (e: React.FormEvent<HTMLInputElement>) => {
	e.currentTarget.maxLength = 14;

	if (e.currentTarget.value.length < 14) {
		const inputValue = e.currentTarget.value;
		let maskCPF = inputValue.replace( /\D/g , "");
		maskCPF = maskCPF.replace( /(\d{3})(\d)/ , "$1.$2");
		maskCPF = maskCPF.replace( /(\d{3})(\d)/ , "$1.$2");
		maskCPF = maskCPF.replace( /(\d{3})(\d{1,2})$/ , "$1-$2");

		e.currentTarget.value = maskCPF;

		return e.currentTarget.value;
	}
};

export default handleMaskCPF;
