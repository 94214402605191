import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 18.188rem;
	width: 22.875rem;
	border-radius: 30px;
	place-content: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	.close {
		width: 21px;

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;

		cursor: pointer;
	}

	.alert {
		margin-bottom: 3rem;
		width: 12%;
	}

	.divText {
		width: 65%;
	}

	.text {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 300;
		font-size: 0.75rem;
		line-height: 11px;
		text-align: center;

		color: #404040;

		margin: 1rem 0;

		b {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 1rem;
			line-height: 120%;

			text-align: center;

			color: #404040;
		}
	}

	.buttons {
		width: 70%;
		display: flex;
		justify-content: space-between;
		margin-top: 1rem;
	}

	.button {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
		line-height: 120%;

		text-align: center;

		border-radius: 5.90345px;

		width: 6.702rem;
		height: 2.294rem;
	}

	.confirmar {
		border: 2px solid ${Colors.primary_blue_k_main};
		background: transparent;

		color: ${Colors.primary_blue_k_main};
	}

	.cancelar {
		border: 2px solid ${Colors.primary_blue_k_main};
		background: ${Colors.primary_blue_k_main};

		color: #ffffff;
	}
`;
