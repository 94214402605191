
import { texts } from "../database/texts";
import { ConfigsActions } from "../store/ducks/configs";
import { TypesLanguage } from "./types";

function tratarTextos(langauge: any) {
	const array_final: any = {};
	const aux_textos: any = [];

	for (const texto of texts) {
		if (langauge == TypesLanguage.PT) {
			aux_textos.push(texto.pt);
		}
		if (langauge == TypesLanguage.EN) {
			aux_textos.push(texto.en);
		}
		if (langauge == TypesLanguage.ES) {
			aux_textos.push(texto.es);
		}
	}

	aux_textos.map((e: any) => {
		for (const [key, value] of Object.entries(e)) {
			array_final[key] = value;
		}
	});
	return array_final;
}

export function checkLanguage(language: any, dispatch: any) {
	let textos_app: any;

	if (language == TypesLanguage.PT) {
		textos_app = tratarTextos(language);
	}
	if (language == TypesLanguage.ES) {
		textos_app = tratarTextos(language);
	}
	if (language == TypesLanguage.EN) {
		textos_app = tratarTextos(language);
	}

	dispatch(ConfigsActions.set_current_language(textos_app));
}
