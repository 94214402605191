import styled from "styled-components";

export const InputText = styled.input`
	width: 22.875rem;

	display: flex;
	align-items: center;
	padding: .4rem 0;
	margin-bottom: 1.6rem;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 120%;

	border: 0;

	border-bottom: 1px solid #404040;

	color: #606060;
	
  ::placeholder,
  ::-webkit-input-placeholder {
		color: #D0D0D0;
  }
  :-ms-input-placeholder {
		color: #D0D0D0;
  }
	::-webkit-inner-spin-button {
    -webkit-appearance: none;
	}
	::-webkit-outer-spin-button {
    -webkit-appearance: none;
	}
`;

export const Question = styled.div`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 120%;

	color: #404040;

	margin-bottom: 1.8rem;
`;