import {
	Backdrop,
	Fade,
	Modal,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../../store/ducks/app";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import buttonClose from "../../../assets/icons/close.svg";
import { ButtonSave, Checkbox, Icon } from "../../../styles/global";
import TutorialDefault from "../../../assets/tutorial_default.png";
import { TextsProps } from "../../../database/txt.interface";
import ExpandIcon from "../../../assets/icons/expand.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CheckboxIcon from "../../../assets/icons/checkbox.svg";
import {
	Close,
	Container,
	ContainerOptions,
	Title,
} from "../../../styles/styles_modais_padroes";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import styled from "styled-components";
import { P1Desk, P3Desk, S3Desk } from "../../../styles/styles_text";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import DragIndicator from "../../../assets/icons/drag_indicator.svg";
import BackIcon from "../../../assets/icons/arrow_back.svg";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalAdicionarProtocolo: React.FC = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const is_open_modal_adicionar_protocolo = useSelector(
		(state: any) => state.manage_protocol.is_open_modal_add_protocol
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const ModalClose = () => {
		dispatch(AppActions.set_lista_pacientes([]));
		dispatch(PacAvalActions.reset_patient());
		dispatch(ManageProtocolActions.set_open_modal_add_protocolo(false));
		setInputValue("");
		set_exers_request([]);
		set_exers_code_selected([]);
		set_current_section("nome_protocolo");
	};

	const [current_section, set_current_section] = useState("nome_protocolo");

	//______________Input section__________________
	const [inputValue, setInputValue] = useState("");

	const handleChange = (event: any) => {
		setInputValue(event.target.value);
	};

	function _renderInputNameSection() {
		return (
			<>
				<div
					style={{ height: "100%", display: "flex", flexDirection: "column" }}
				>
					<P1Desk style={{ marginBottom: "2rem" }}>
						Digite abaixo o nome do protocolo que deseja adicionar.
					</P1Desk>
					<Label>{current_language?.criacao_proto}*</Label>
					<InputProtocol
						placeholder="Digite"
						type="textfdf"
						value={inputValue}
						onChange={handleChange}
					/>
				</div>
				<ButtonSave
					type="submit"
					active={inputValue != "" ? true : false}
					onClick={() =>
						inputValue != "" ? set_current_section("selecao_exercicios") : ""
					}
				>
					{current_language?.menu_lateral_botao_avancar}
				</ButtonSave>
			</>
		);
	}

	//_____________Exercises List________________________

	interface ExersRequest {
		ID_EXERCICIO: string;
		PROTOCOLO_EXERCICIO_TIPO: string;
	}

	const Lados = ["D", "E", "B"];
	const Lados2 = ["D", "E"];
	const [group_selected, set_group_selected] = useState("SUPERIOR");
	const [exers_code_selected, set_exers_code_selected]: any = useState([]);
	const [exers_request, set_exers_request]: any = useState([]);

	const exercicios = useSelector((state: any) => state.exercicios.exercicios);

	const exercicios_sup = exercicios.filter((e: Exercicios2Props) => {
		return e.AGRUPAMENTO_TIPO == "SUPERIOR";
	});
	const exercicios_inf = exercicios.filter((e: Exercicios2Props) => {
		return e.AGRUPAMENTO_TIPO == "INFERIOR";
	});

	const prepareExercisesRequest = () => {
		const result = exers_code_selected.map((item: string) => {
			const number = item.slice(0, -1);
			const letter = item.slice(-1);

			return {
				ID_EXERCICIO: number,
				PROTOCOLO_EXERCICIO_TIPO: letter,
			};
		});

		const grouped: any = {};

		result.forEach((item: any) => {
			const id = item.ID_EXERCICIO;
			const type = item.PROTOCOLO_EXERCICIO_TIPO;

			if (!grouped[id]) {
				grouped[id] = { ID_EXERCICIO: id, PROTOCOLO_EXERCICIO_TIPO: type };
			} else {
				grouped[id].PROTOCOLO_EXERCICIO_TIPO += type;
			}
		});

		// Converte o objeto auxiliar de volta para um array
		set_exers_request(Object.values(grouped));

		set_current_section("drag");
	};

	const toggleExercise = (exercise_code: string) => {
		const exists = exers_code_selected.some((ex: any) => ex === exercise_code);
		if (exists) {
			set_exers_code_selected(() => {
				return exers_code_selected.filter((ex: any) => ex !== exercise_code);
			});
		} else {
			set_exers_code_selected([...exers_code_selected, exercise_code]);
		}
	};

	const _renderExercisesList = (exers: Exercicios2Props[]) => {
		return (
			<>
				<Back
					src={BackIcon}
					onClick={() => set_current_section("nome_protocolo")}
				/>
				<P1Desk>{current_language?.marque_os_exercicios}</P1Desk>
				<ContainerTab>
					<Option
						active={group_selected == "SUPERIOR" ? true : false}
						onClick={() => set_group_selected("SUPERIOR")}
					>
						Superiores
					</Option>
					<Option
						active={group_selected == "INFERIOR" ? true : false}
						onClick={() => set_group_selected("INFERIOR")}
					>
						Inferiores
					</Option>
				</ContainerTab>
				<ContainerOptions style={{ height: "100%" }}>
					{exers.map((exercicio: Exercicios2Props, index: number) => (
						<ExerciseSection key={index}>
							<TitleAndDescription>
								<S3Desk>{exercicio.EXERCICIO_TITULO_PT}</S3Desk>
								<div style={{ display: "flex", flexDirection: "row" }}>
									{
										exercicio.ID_USUARIO == 0 && exercicio.EXERCICIO_TITULO_PT == "Preensão Palmar" && (
											Lados2.map((lado, index) => (
												<ContainerCheckbox key={index}>
													<P3Desk>
														{
															lado == "D"
															&& "Direito"
															|| lado == "E"
															&& "Esquerdo"
														}
													</P3Desk>
													<Checkbox
														className="custom-checkbox"
														id={exercicio?.ID_EXERCICIO.toString() + lado}
														type="checkbox"
														src={CheckboxIcon}
														checked={exers_code_selected.some(
															(ex: any) =>
																ex === exercicio?.ID_EXERCICIO.toString() + lado
														)}
														onClick={() => {
															toggleExercise(
																exercicio?.ID_EXERCICIO.toString() + lado
															);
														}}
													/>
												</ContainerCheckbox>
											))
										) || exercicio.EXERCICIO_TIPO == 0 && (
										Lados.map((lado, index) => (
											<ContainerCheckbox key={index}>
												<P3Desk>
													{
														lado == "D"
														? "Direito"
														: lado == "E"
														? "Esquerdo"
														: "Bilateral"
													}
												</P3Desk>
												<Checkbox
													className="custom-checkbox"
													id={exercicio?.ID_EXERCICIO.toString() + lado}
													type="checkbox"
													src={CheckboxIcon}
													checked={exers_code_selected.some(
														(ex: any) =>
															ex === exercicio?.ID_EXERCICIO.toString() + lado
													)}
													onClick={() => {
														toggleExercise(
															exercicio?.ID_EXERCICIO.toString() + lado
														);
													}}
												/>
											</ContainerCheckbox>
										))
									) || (
										<ContainerCheckbox key={index}>
											<P3Desk>{current_language?.sem_hemisferio}</P3Desk>
											<Checkbox
												className="custom-checkbox"
												id={exercicio?.ID_EXERCICIO.toString() + "S"}
												type="checkbox"
												src={CheckboxIcon}
												checked={exers_code_selected.some(
													(ex: any) =>
														ex === exercicio?.ID_EXERCICIO.toString() + "S"
												)}
												onClick={() => {
													toggleExercise(
														exercicio?.ID_EXERCICIO.toString() + "S"
													);
												}}
											/>
										</ContainerCheckbox>
									)}
								</div>
							</TitleAndDescription>
						</ExerciseSection>
					))}
				</ContainerOptions>
				<ButtonSave
					type="submit"
					active={exers_code_selected.length > 0 ? true : false}
					onClick={() => {
						exers_code_selected.length > 0 ? prepareExercisesRequest() : "";
					}}
				>
					{current_language?.menu_lateral_botao_avancar}
				</ButtonSave>
			</>
		);
	};

	//___________Drag and drop_____________________________

	const handleOnDragEnd = (result: any) => {
		if (!result.destination) return;

		const newItems = Array.from(exers_request);
		const [reorderedItem] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, reorderedItem);

		set_exers_request(newItems);
	};

	const AddProtoRequest = () => {
		const data_request = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			PROTOCOLO_TITULO: inputValue,
			PROTOCOLO_EXERCICIO: exers_request,
		};
		dispatch(ManageProtocolActions.add_protocolo_request(data_request));
		ModalClose();
	};

	function _renderDragAndDrop() {
		return (
			<>
				<Back
					src={BackIcon}
					onClick={() => set_current_section("selecao_exercicios")}
				/>
				<P1Desk style={{ marginBottom: "1rem" }}>
					{current_language?.segure_arraste_exericios}
				</P1Desk>
				<ContainerOptions style={{ height: "100%" }}>
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId="droppable">
							{(provided) => (
								<ul {...provided.droppableProps} ref={provided.innerRef}>
									{exers_request.map((item: ExersRequest, index: number) => (
										<Draggable
											key={item.ID_EXERCICIO}
											draggableId={item.ID_EXERCICIO}
											index={index}
										>
											{(provided, snapshot) => (
												<ExerciseDrag
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={{
														userSelect: "none",
														padding: "8px",
														margin: "4px",
														borderColor: snapshot.isDragging
															? "#54DFAC"
															: "transparent",
														cursor: "move",
														...provided.draggableProps.style,
													}}
												>
													<S3Desk>
														{
															exercicios.find(
																(e: Exercicios2Props) =>
																	e.ID_EXERCICIO.toString() == item.ID_EXERCICIO
															).EXERCICIO_TITULO_PT
														}
													</S3Desk>
													<Icon
														src={DragIndicator}
														style={{
															width: "1.5rem",
															height: "1.5rem",
														}}
													/>
												</ExerciseDrag>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</ul>
							)}
						</Droppable>
					</DragDropContext>
				</ContainerOptions>
				<ButtonSave
					type="submit"
					active={exers_code_selected.length > 0 ? true : false}
					onClick={AddProtoRequest}
				>
					Salvar
				</ButtonSave>
			</>
		);
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open_modal_adicionar_protocolo}
			onClose={ModalClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open_modal_adicionar_protocolo}>
				<Container>
					<Close src={buttonClose} onClick={ModalClose} />
					<Title>{current_language?.adicionar_protocolo}</Title>
					{current_section == "nome_protocolo"
						? _renderInputNameSection()
						: current_section === "selecao_exercicios"
						? _renderExercisesList(
								group_selected == "SUPERIOR" ? exercicios_sup : exercicios_inf
						  )
						: _renderDragAndDrop()}
				</Container>
			</Fade>
		</Modal>
	);
};

interface ActiveProps {
	active: boolean;
}
const Option = styled.text<ActiveProps>`
	font-family: "NotoSans-regular";
	font-size: 1.2rem;
	line-height: 38.4px;
	width: 50%;
	text-align: center;
	cursor: pointer;

	border-bottom: ${({ active }) =>
		active ? "3px solid #00d084" : "3px solid #dedfe3"};
	font-weight: ${({ active }) => (active ? 700 : 400)};
`;

const ContainerTab = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 1rem;
`;

const ExerciseSection = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-radius: 18px;
	margin-bottom: 1rem;
`;

const TitleAndDescription = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const TutorialSection = styled.div`
	display: flex;
`;

const ContainerCheckbox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 1rem;
`;

const ExerciseDrag = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	cursor: "grab";
	border: 2px solid #e0e0e0;
	background-color: white;
	border-radius: 12px;

	.dragging {
		opacity: 1 !important;
		border: 2px dashed #000;
	}
`;

const InputProtocol = styled.input`
	background-color: transparent;
	border: none;
	width: 100%;
	font-size: 80%;
	border-bottom: 2px solid #e0e0e0;
	font-family: "NotoSans-Regular";
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 24px;
	padding-bottom: 0.3rem;
`;

const Label = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1rem;
	font-weight: 600;
	line-height: 26.4px;
`;

export const Back = styled.img`
	width: 1rem;
	height: 1rem;
	position: absolute;
	left: 2.5rem;
	top: 2.5rem;
	cursor: pointer;
`;

export default ModalAdicionarProtocolo;
