const handleMaskPhone = (e: React.FormEvent<HTMLInputElement>) => {
	e.currentTarget.maxLength = 15;
	let inputValue = e.currentTarget.value;
	inputValue = inputValue.replace(/\D/g,'')
	inputValue = inputValue.replace(/(\d{2})(\d)/,"($1) $2")
	inputValue = inputValue.replace(/(\d)(\d{4})$/,"$1-$2")
	e.currentTarget.value = inputValue

	return e.currentTarget.value;
};

export default handleMaskPhone;
