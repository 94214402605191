import {
	Backdrop,
	Fade,
	Modal,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../../store/ducks/app";
import { PacAvalActions } from "../../store/ducks/manage_pac_aval";
import buttonClose from "../../assets/icons/buttonCloseBlack.svg";
import { PacientesProps } from "../../interfaces/pacientes.interface";
import { DashBoardActions } from "../../store/ducks/dashboard";
import { AtribuirActions } from "../../store/ducks/atribuir";
import Perfil from "../../assets/icons/perfil.svg";
import { ButtonSave, RadioButton } from "../../styles/global";
import { TextsProps } from "../../database/txt.interface";
import {
	Close,
	Container,
	ContainerOptions,
	ContainerPaci,
	Name,
	NameOption,
	Photo,
	Subtitle,
	Title,
	Option,
} from "../../styles/styles_modais_padroes";
import { loadStorage, persistStorage } from "../../utils/base_async_storage";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalAvaliador: React.FC = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [optionChecked, setOptionChecked] = useState<string | null>("");
	const [persistCurrentEvaluator, setPersistCurrentEvaluator] = useState("");

	const is_open_atribuir_avaliador = useSelector(
		(state: any) => state.app.is_open_atribuir_avaliador
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const avaliadores = useSelector((state: any) => state.dashboard.avaliadores);

	const lista_pacientes = useSelector(
		(state: any) => state.app.lista_pacientes
	);

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const [aprovado, setAprovado] = useState(false);

	useEffect(() => {
		if (is_open_toast) {
			setAprovado(false);
		}
	}, [is_open_toast]);


	useEffect(()=>{
		async function GetCurrentEvaluator(){
			const currentEvaluator: string |  null = await loadStorage('evaluated_patients')
			setOptionChecked(currentEvaluator)

		};
		GetCurrentEvaluator()
	},[])

	async function setCurrentEvaluator (id:string){

		await persistStorage({
			res: id,
			tag: 'setCurrentEvaluatorInLocalStorage'
		})
	};

	const atribuir = () => {
		setAprovado(true);
		lista_pacientes.map((id: number) => {
			const request = {
				USUARIO_AVALIADOR: Number(optionChecked),
				ID_USUARIO: id,
				USUARIO_NOME: pacientes.find(
					(user: PacientesProps) => user.ID_USUARIO === id
				)?.USUARIO_NOME,
			};
			dispatch(AtribuirActions.edit_atribuir(request));
		});
		dispatch(PacAvalActions.reset_patient());
		dispatch(DashBoardActions.paciente_request());
		dispatch(AppActions.set_modal_atribuir_avaliador(false));
	};

	const ModalClose = () => {
		dispatch(AppActions.set_lista_pacientes([]));
		dispatch(PacAvalActions.reset_patient());
		dispatch(AppActions.set_modal_atribuir_avaliador(false));
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open_atribuir_avaliador}
			onClose={ModalClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open_atribuir_avaliador}>
				<Container>
					<Close
						className="close"
						src={buttonClose}
						onClick={ModalClose}
						alt="Close"
					/>
					<Title>{current_language?.pag_inicial_avaliador}</Title>
					<Subtitle>{current_language?.paciente_selecionado}:</Subtitle>
					<ContainerPaci>
						{lista_pacientes.length == 1 ? (
							<Photo
								src={
									pacientes.find(
										(user: PacientesProps) =>
											user.ID_USUARIO === lista_pacientes[0]
									)?.USUARIO_FOTO
										? pacientes.find(
												(user: PacientesProps) =>
													user.ID_USUARIO === lista_pacientes[0]
										  )?.USUARIO_FOTO
										: Perfil
								}
							/>
						) : (
							<Photo src={Perfil} />
						)}
						<Name>
							{lista_pacientes.length == 1
								? pacientes.find(
										(user: PacientesProps) =>
											user.ID_USUARIO === lista_pacientes[0]
								  )?.USUARIO_NOME
								: lista_pacientes.length +
								  " " +
								  current_language?.pacientes_selecionados}
						</Name>
					</ContainerPaci>
					<Subtitle>{current_language?.selecione_avaliador}:</Subtitle>
					<ContainerOptions>
						{avaliadores.map((avaliador: PacientesProps) => {
							return (
								<Option key={avaliador?.ID_USUARIO}>
									<NameOption>{avaliador?.USUARIO_NOME + (optionChecked === avaliador.ID_USUARIO.toString() ? '(Avaliador atual)' : '' )}</NameOption>
									<RadioButton
										type="radio"
										name={avaliador.ID_USUARIO.toString()}
										value={avaliador.ID_USUARIO.toString()}
										onChange={(e) =>{
											setOptionChecked(avaliador.ID_USUARIO.toString())
											setCurrentEvaluator(avaliador.ID_USUARIO.toString())
										}}
										checked={optionChecked === avaliador.ID_USUARIO.toString()}
									/>
								</Option>
							);
						})}
					</ContainerOptions>
					<ButtonSave
						active={optionChecked != "" ? true : false}
						onClick={
							optionChecked != ""
								? atribuir
								: () => {
										/* no-op */
								  }
						}
					>
						{current_language?.atribuir}
					</ButtonSave>
				</Container>
			</Fade>
		</Modal>
	);
};

export default ModalAvaliador;
