import React, { useEffect, useState } from "react";
import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ContainerOptions } from "./styles";

import buttonClose from "../../../assets/icons/iconClose.svg";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { ProtocolosProps } from "../../../interfaces/protocolo.interface";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { TypesLanguage } from "../../../utils/types";
import { PropsCategoriasProtocolos } from "../../../interfaces/categoriasProtocolos.interface";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import moment from "moment";
import { TextsProps } from "../../../database/txt.interface";
import { Close, Container } from "../../../styles/styles_modais_padroes";
import { P1Desk, P2Desk } from "../../../styles/styles_text";
import Filter from "../../../assets/icons/filter.svg";
import Info from "../../../assets/icons/info.svg";
import { ButtonSave, Checkbox, DropDown, Icon } from "../../../styles/global";

enum CategoriaType {
	ARTICULACAO = "ARTICULACAO",
	GLOBAL = "GLOBAL",
}

const ModalFilterProcol: React.FC = () => {
	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			modal: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			paper: {
				backgroundColor: theme.palette.background.paper,
				border: "0",
				boxShadow: theme.shadows[5],
			},
		})
	);
	const dispatch = useDispatch();
	const classes = useStyles();

	const [idCheck, setIdCheck] = useState<number[]>([]);
	const [dropDown, setDropDown] = useState<number[]>([]);
	const [categoriaArticulacao, setCategoriaArticulacao] = useState<any[]>([]);
	const [categoriaGlobal, setCategoriaGlobal] = useState<any[]>([]);
	const [lengthProtocol, setLengthprotocol] = useState<any>();

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const language = useSelector((state: any) => state.configs.language);
	const open_modal_filter_protcol = useSelector(
		(state: any) => state.manage_protocol.is_open_modal_filter_protocol
	);

	const CategoriasProtocolos: any[] = useSelector(
		(state: any) => state.dashboard.categorias_protocolos.categorias_protocolo
	);
	const protocolos: ProtocolosProps[] = useSelector(
		(state: any) => state.manage_protocol.protocolos
	);
	const categorias_atribuidas: number[] = useSelector(
		(state: any) => state.dashboard.categorias_atribuidas
	);
	const searchProtocolInStorage = useSelector(
		(state: any) => state.dashboard.ids_storage_protocol
	);

	useEffect(() => {
		if (open_modal_filter_protcol) {
			if (categorias_atribuidas?.length == 0) {
				setIdCheck([]);
			}
		}
	}, [!!open_modal_filter_protcol]);

	useEffect(() => {
		dispatch(DashBoardActions.search_protocol_in_storage(idCheck));
	}, [idCheck]);

	useEffect(() => {
		if (categorias_atribuidas?.length > 0) {
			setIdCheck(categorias_atribuidas);
			setDropDown(categorias_atribuidas);
		}
	}, [!!open_modal_filter_protcol]);

	useEffect(() => {
		const ProtocolosPorArticulacao = CategoriasProtocolos?.filter(
			(item: any) => item?.CATEGORIA_TIPO == CategoriaType.ARTICULACAO
		);
		setCategoriaArticulacao(ProtocolosPorArticulacao);

		const ProtocolosGlobais = CategoriasProtocolos?.filter(
			(item: any) => item?.CATEGORIA_TIPO == CategoriaType.GLOBAL
		);
		setCategoriaGlobal(ProtocolosGlobais);
	}, [CategoriasProtocolos]);

	const handleCheckBox = (id: number) => {
		if (idCheck?.includes(id)) {
			setIdCheck(idCheck?.filter((item) => item !== id));
			setDropDown(dropDown?.filter((item) => item !== id));
		} else {
			setIdCheck([...idCheck, id]);
			setDropDown([...dropDown, id]);
		}
	};

	useEffect(() => {
		const searchLengthProtocols = protocolos?.filter((item: any) =>
			searchProtocolInStorage.includes(item?.ID_CATEGORIA_PROTOCOLO)
		);
		setLengthprotocol(searchLengthProtocols?.length);
	}, [searchProtocolInStorage]);

	const SendInformation = () => {
		const defaultCategori: number[] = [];
		categoriaGlobal?.forEach((item: PropsCategoriasProtocolos) =>
			defaultCategori?.push(item?.ID_CATEGORIA_PROTOCOLO)
		);
		if (idCheck?.length > 0) {
			dispatch(
				DashBoardActions.set_atribuir_categorias_protocolos(
					moment(new Date()).format("DD/MM/YYYY HH:MM:SS"),
					paciente?.ID_USUARIO,
					idCheck
				)
			);
		}
		if (idCheck?.length == 0) {
			dispatch(
				DashBoardActions.set_atribuir_categorias_protocolos(
					moment(new Date()).format("DD/MM/YYYY HH:MM:SS"),
					paciente?.ID_USUARIO,
					defaultCategori
				)
			);
		}
		dispatch(ManageProtocolActions.set_modal_filter_protocol(false));
	};

	const HandleOptions = (item: PropsCategoriasProtocolos, index: number) => {
		return (
			<ContainerOptions key={index}>
				<div
					onClick={() => handleCheckBox(item?.ID_CATEGORIA_PROTOCOLO)}
					style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
				>
					<Checkbox
						type="checkbox"
						checked={idCheck.includes(item?.ID_CATEGORIA_PROTOCOLO)}
						style={{ marginRight: "0.5rem" }}
					></Checkbox>

					<P2Desk>
						{language == TypesLanguage.PT
							? item?.CATEGORIA_TITULO_PT
							: language == TypesLanguage.EN
							? item?.CATEGORIA_TITULO_EN
							: item?.CATEGORIA_TITULO_ES}
					</P2Desk>
				</div>
			</ContainerOptions>
		);
	};

	const [open_drop, set_open_drop] = useState("");
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open_modal_filter_protcol}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open_modal_filter_protcol}>
					<Container>
						<div className="Header">
							<P1Desk className="text">
								Selecione os protocolos para avaliar {paciente?.USUARIO_NOME}
							</P1Desk>
							<Close
								className="close"
								src={buttonClose}
								onClick={() => SendInformation()}
								alt="Close"
							/>

							<div style={{ marginBottom: "1rem" }}>
								<Icon
									src={Filter}
									alt=""
									style={{
										marginRight: "0.2rem",
									}}
								/>
								<P2Desk
									className="Length"
									style={{
										color: lengthProtocol > 0 ? "#2E5AAC" : "#798087",
										marginRight: "0.2rem",
									}}
								>
									{lengthProtocol + " "}
								</P2Desk>
								<P2Desk
									className="Disponiveis"
									style={{
										color: lengthProtocol > 0 ? "#2E5AAC" : "#798087",
									}}
								>
									{lengthProtocol > 0
										? "protocolos filtrados"
										: current_language?.nenhum_protocolo_selecionado_no_momento}
								</P2Desk>
							</div>
						</div>

						<div
							style={{
								display: "flex",
								flexDirection: "row",
								height: "100%",
							}}
						>
							<div style={{ marginRight: "2rem" }}>
								<div style={{ display: "flex", alignItems: "center" }}>
									<P2Desk>
										{current_language?.protocolos_tipo_categorias}
									</P2Desk>
									<DropDown active={open_drop == "articulacoes_info"}>
										<Icon
											style={{ marginLeft: "0.5rem" }}
											src={Info}
											onClick={() =>
												open_drop == "articulacoes_info"
													? set_open_drop("")
													: set_open_drop("articulacoes_info")
											}
										></Icon>
										<div
											style={{ backgroundColor: "#CEF6E7" }}
											className="dropdown-content"
										>
											<P2Desk>
												* Ombros: Examina rotação interna e externa, adução e
												abdução, flexão e extensão de ombro;
												<br />
												* Cotovelos: Examina flexão e extensão de cotovelo;
												<br />
												* Punhos: Examina extensão e flexão, desvio ulnar e
												desvio radial, pronação e supinação;
												<br />
												* Quadril: Examina extensão, flexão, abdução e adução do
												quadril;
												<br />
												* Joelhos: Examina extensão e flexão de joelho,
												fornecendo o resultado da relação IQ;
												<br />* Tornozelos: Examina inversão e eversão, flexão
												plantar e dorsiflexão.
											</P2Desk>
										</div>
									</DropDown>
								</div>

								<div>
									{categoriaArticulacao?.map((item: any, index) =>
										HandleOptions(item, index)
									)}
								</div>
							</div>

							<div>
								<div style={{ display: "flex", alignItems: "center" }}>
									<P2Desk>Protocolos Kinology</P2Desk>
									<DropDown active={open_drop == "protocolos_kinology"}>
										<Icon
											style={{ marginLeft: "0.5rem" }}
											src={Info}
											onClick={() =>
												open_drop == "protocolos_kinology"
													? set_open_drop("")
													: set_open_drop("protocolos_kinology")
											}
										></Icon>
										<div
											style={{ backgroundColor: "#CEF6E7" }}
											className="dropdown-content"
										>
											<P2Desk>
												* Corpo inteiro: Exame rápido ou detalhes dos membros
												inferiores e superiores;
												<br />
												* Membros superiores: Examina ombro, cotovelo e punho
												<br />* Membros inferiores: Examina quadril, joelho e
												tornozelo
											</P2Desk>
										</div>
									</DropDown>
								</div>
								{categoriaGlobal?.map((item: any, index) =>
									HandleOptions(item, index)
								)}
							</div>
						</div>

						<ButtonSave active={true} onClick={() => SendInformation()}>
							<div className="textSave">Filtrar</div>
						</ButtonSave>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};
export default ModalFilterProcol;
