import styled from "styled-components";

import HomeMobile from "../../assets/HomeMobile.png";

export const Container = styled.div`
	width: 100vw;
	height: 100vh;
	flex: 1;
	background: url(${HomeMobile}) no-repeat right top fixed;
	background-size: cover;
`;

export const Content = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10%;

	p {
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 120%;
		text-align: center;
		color: #323232;
		margin-top: 3rem;
		margin-bottom: 5rem;
	}

	div {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		img {
			margin-top: 1rem;
		}
	}
`;
