import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 522px;
    border-radius: 24px;
    padding-bottom: 60px;
    padding-top: 25px;
    background-color: white;

    .ContentClose {
        display: flex;
        height: 20px;
        justify-content: flex-end;
        padding-right:35px;
        cursor: pointer;
    }

    .containerBg {
        display: flex;
        width: 100%;
        height: 45%;
        align-items: center;
        justify-content: center;

        .BG {
            display: flex;
            width: 50%;
            height: 100%;
            align-items: center;
            justify-content: center;
        }
    }

    .ContainerText {
        display: flex;
        width: 100%;
        height: 15%;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        line-height: 34.5px;
        padding-left:20px;
        padding-right:20px;
        margin-top:35px;

        .Text {
            display: flex;
            text-align: center;
            font-size: 23px;
            font-weight: 400;
           
            color: #09101D;
        }
        .containerNomePaci {
            display: flex;
            flex-direction: row;

            .namePaci {
                display: flex;
                color: #E5085C;
                font-size: 23px;
                font-weight: 700;
    
            }
            .interrogacao {
                display: flex;
                font-size: 23px;
                font-weight: 400;
                color: #09101D;
            }
        }
        

    }

    .containerButtons {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .ContainerFaces {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-top: 18px;
            padding-bottom: 18px;
            justify-content: space-evenly;
            height: 76px;
            width: 100%;
        }

        .ContainerInput {
            display: flex;
            width: 459px;
            height:  113px;
            border-radius: 16px;
            border: solid;
            border-width: 1px;
            border-color: #EBEEF2;
            padding-left: 20px;
            padding-right: 14px;
            padding-top: 12px;
            padding-bottom: 12px;
            margin-top: 15px;

            .Sugestions {
                color: #141622;
                font-family: Roboto;
                border: none;
                width: 100% ;
            }
            
        }
        
    }

    .footer {
        display: flex;
        width: 100%;
        height: 18%;
        align-items: center;
        justify-content: center;

        .pular {
            display: flex;
            color: #141622;
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
            margin-top: 34px;

        }

        .Send {
            display: flex;
            width: 91px;
            height: 55px;
            background-color: #000E4B;
            margin-top: 35px;
            border-radius: 30px;
            align-items: center;
            padding-top: 14px;
            padding-bottom: 14px;
            padding-left: 18px;
            padding-right: 18px;
            justify-content: center;
            cursor: pointer;


            .TextSend {
                font-size: 18px;
                font-weight: 600;
                color: #ffff;
            }

        }

    }
`;

export const Faces = styled.div`
    display: flex;
    width: 40px;
    height:40px;
    border-radius: 48px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
`;

export const ComponentButtonOptions = styled.div`
    display: flex;
    width: 123px;
    height: 36px;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    padding-top: 6px; 
    padding-left: 16px; 
    padding-right: 16px;
    padding-bottom: 6px;
    cursor: pointer;

    .textOption{ 
        font-size: 16px;
        font-weight: 600;
        color: #000E4B;
    }
`;