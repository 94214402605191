import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { Conteiner, Header, ContainerGoBack, ContainerPhoto, ContainerTwoLabels, ContainerIconText, IMG, Content } from "./styles";
import ToastAlert from "../../../components/toast";
import { AmplitudeLog } from "../../../utils/amplitude";
import {ReactComponent as ArrowBack} from '../../../assets/kinologyIcons/svg/arrow_back.svg';
import { Colors } from "../../../styles/colors";

import Text from '../../../components/Texts/Text';
import Form from "../../../components/Form";
import { PerfilActions } from "../../../store/ducks/perfil";
import { useForm } from "react-hook-form";
import InputTextEntryForm from '../../../components/Inputs/inputForm';
import InputSelectForm from "../../../components/SelectComponent/InputSelectForm";
import { countries } from "../../../components/SelectComponent/Countries";
import FormSubmiteButton from "../../../components/Buttons/FormSubmitButton";
import { ReactComponent as Key} from '../../../assets/kinologyIcons/svg/key_password.svg';
import { ReactComponent as AddPhoto} from '../../../assets/kinologyIcons/svg/add_photo_alternate.svg';
import  {ReactComponent as AddPhotoIcon }from "../../../assets/kinologyIcons/svg/AddPhotoIcon.svg";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { RegisterActions } from "../../../store/ducks/register";

const Perfil: React.FC = () => {
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const perfil = user_logado;

	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const user_editation: UserProps = useSelector((state: any)=> state?.auth?.data_user)
	const loading = useSelector((state: any)=> state?.register?.load)
	


	const methods = useForm({defaultValues:{
		nome: user_editation ? user_editation?.USUARIO_NOME : '',
		email: user_editation ? user_editation?.USUARIO_EMAIL : '',
		celular: user_editation ? user_editation?.USUARIO_CONTATO?.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3") : '',
		cpf: user_editation ? user_editation?.USUARIO_CPF?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4") : '',
		data:  user_editation ? user_editation?.USUARIO_NASCIMENTO == undefined
			? ""
			: current_language?.idioma === "Português"
			? `${user_editation?.USUARIO_NASCIMENTO?.substr(0, 10)
					.split("-")
					.reverse()
					.join("/")}`
			: current_language?.idioma === "English"
			? `${user_editation?.USUARIO_NASCIMENTO?.substr(0, 5)
					.split("/")
					.reverse()
					.join("/")}/${user_editation?.USUARIO_NASCIMENTO?.substr(6, 10)}`
			: `${user_editation?.USUARIO_NASCIMENTO?.substr(0, 10).split("/").join("-")}`
			: '',
		altura:  user_editation ? user_editation?.USUARIO_ESTATURA?.split(".")?.join(",") : '',
		massa: user_editation ? user_editation?.USUARIO_MASSA?.split(".")?.join(",") : '',
		
	}});

	const handleConta = () => {
		dispatch(AppActions.set_modal_foto_edicao_perfil_assinatura(true, current_language?.edit_foto));
	};

	const nivel_usuario = perfil.USUARIO_NIVEL;
	let nivel = "";
	nivel_usuario == 1 || nivel_usuario == 0
		? (nivel = `${current_language?.add_avaliador}`)
		: nivel_usuario == 2
		? (nivel = `${current_language?.pag_inicial_avaliador}`)
		: (nivel = `${current_language?.paciente}`);


	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);
	const foto_perfil = useSelector((state: any) => state.app.foto_perfil);

	const onSubmit = (data: any) => {
		const novoEmail = perfil?.USUARIO_EMAIL !== data.email;
		const dat_request = {
			ID_USUARIO: perfil.ID_USUARIO,
			USUARIO_AVALIADOR: perfil.ID_USUARIO,
			USUARIO_EMAIL: data.email,
			USUARIO_NOME: data.nome,
			USUARIO_SEXO: "",
			USUARIO_ESTATURA: data.altura.replace("m", ""),
			USUARIO_CONTATO: data.celular,
			USUARIO_MASSA: data.massa.replace("kg", ""),
			USUARIO_CPF: data.cpf,
			USUARIO_NACIONALIDADE: data.nacionalidade,
			USUARIO_NASCIMENTO:
				current_language?.idioma === "Português"
					? `${data.data.substr(0, 10).split("/").reverse().join("-")}`
					: current_language?.idioma === "English"
					? `${data.data}`
					: `${data.data.substr(0, 10).split("-").reverse().join("-")}`,
			USUARIO_CONDICIONAMENTO: "",
			NOVO_EMAIL: novoEmail,
			USUARIO_FOTO: !!foto_perfil ? foto_perfil : "",
		};
		dispatch(PerfilActions.edit_perfil(dat_request));
		dispatch(RegisterActions.edit_perfil_load(true));
		AmplitudeLog('salvar_edic_perfil', {
			usuario:dat_request
		})
	};

	return (
		<Conteiner>
			{is_open_toast && <ToastAlert />}
			<Content>
				<Header>
					<ContainerGoBack 
						onClick={()=> {
							dispatch(DashBoardActions.set_current_page("minha_conta"))
							dispatch(AppActions.set_option_menu(false, current_language?.menu_lateral_minha_conta))
						}}
					>
						<ArrowBack />
						<Text 
							title={current_language.menu_lateral_minha_conta}
							fontFamily="NotoSans-Regular"
							fontSize={1}
							fontWeight={400}
							textColor={Colors?.primary_blue_k_main}
							marginLeft={6}
							cursor="pointer"
						/>
					</ContainerGoBack>
					<Text 
						title={current_language.pacientes_arrasta_para_o_lado_editar}
						fontFamily="NotoSans-Regular"
						fontSize={1.5}
						fontWeight={700}
						textColor={Colors?.primary_blue_k_main}
						marginTop={15}
					/>
				</Header>
				<ContainerPhoto
					onClick={()=> handleConta()}
				>
					{
						foto_perfil 
						?
							<IMG
								src={
									foto_perfil  
								}
							/>
						:  <AddPhotoIcon  width={100} height={100}/>
					}
				</ContainerPhoto>
				<Form 	methods={methods} 	onSubmit={(data)=>{onSubmit(data)}}>
					<ContainerTwoLabels>
						<InputTextEntryForm 
							type="text"
							width={48.5}
							title={current_language?.nome_completo + '*'}
							placeholder={current_language?.placeholder_digite}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							typeInputName="nome" 
						/>
						<InputTextEntryForm 
							type="number"
							width={48.5}
							title={'CPF*'}
							placeholder={'__.__.__-_'}
							leftIcon="DigitalIcon"
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							typeInputName="cpf" 
						/>
					</ContainerTwoLabels>
					<InputSelectForm 
						type="select"
						width={100}
						title={current_language?.primeiro_acesso_nacionalidade + '*'}
						placeholder={current_language?.select_country}
						leftIcon="PositionIcon"
						rightIcon="ArrowSelect"
						arrayOptions={countries}
						defaultValue={user_editation?.USUARIO_NACIONALIDADE}
						fontFamily="NotoSans-SemiBold"
						fontWeight={600}
						marginTop={24}
						typeInputName="nacionalidade" 
					/>
					<InputTextEntryForm 
						type="text"
						width={100}
						title={'E-mail*'}
						placeholder={current_language?.placeholder_digite}
						leftIcon="EmailIcon"
						fontFamily="NotoSans-SemiBold"
						fontWeight={600}
						marginTop={24}
						typeInputName="email" 
					/>
					<InputTextEntryForm 
						type="number"
						width={100}
						title={current_language?.primeiro_acesso_celular + '*'}
						placeholder={'( ) 00000-0000'}
						fontFamily="NotoSans-SemiBold"
						fontWeight={600}
						leftIcon="Phone"
						marginTop={24}
						typeInputName="celular" 
					/>
					<FormSubmiteButton
						type="submit"
						onClick={()=> methods.trigger()}
						disabled={Object.values(methods.watch()).every((item: string)=> item != '')
							? false
							: true
						}
						paddingTop={9}
						paddingBottom={9}
						marginTop={40}
						fontFamily="Kanit-Medium"
						typeButtonComponent="primary"
						activityIndicator={loading}
						title={current_language?.menu_lateral_botao_salvar}
					/>
				</Form>
				<ContainerIconText onClick={()=> dispatch(AppActions.set_modal_alter_senha(true))}>
					<Key />
					<Text 
						title={current_language?.menu_lateral_alterar_senha}
						fontFamily="NotoSans-Regular"
						fontSize={1.25}
						fontWeight={600}
						textColor={Colors?.primary_blue_k_main}
						textAlign="left"
						marginLeft={12}
						cursor="pointer"
					/>
				</ContainerIconText>

				<ContainerIconText onClick={()=> dispatch(AppActions.set_modal_adicionar_assinatura(true))}>
					<AddPhoto />
					<Text 
						title={current_language?.adicionar_assinatura}
						fontFamily="NotoSans-Regular"
						fontSize={1.25}
						fontWeight={600}
						textColor={Colors?.primary_blue_k_main}
						marginLeft={12}
						textAlign="left"
						cursor="pointer"
					/>
				</ContainerIconText>
			</Content>
			
		</Conteiner>
	);
};

export default Perfil;
