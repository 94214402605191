import React, { memo, useEffect, useState } from "react";
import { SelectBox, SelectContent, SelectItem, SelectOptions } from "./styles";
import { QuestionnaireProps } from '../../../../../interfaces/pacientes.interface';
import { useDispatch, useSelector } from 'react-redux';
import { PacAvalActions } from '../../../../../store/ducks/manage_pac_aval';

interface SelectProps {
	item: QuestionnaireProps,
}

const Select: React.FC<SelectProps> = ({ item }) => {
	const dispatch = useDispatch();
	const array_respostas = useSelector(
		(state: any) => state.pac_aval.array_respostas
	);
	const array_resposta = useSelector(
		(state: any) => state.pac_aval.array_resposta
	);

	const [value, setValue] = useState(item.QUESTIONARIO_PERGUNTA_OPCOES.indexOf(item.QUESTIONARIO_PERGUNTA_OPCOES[0]))
	const [open, setOpen] = useState(false)

	useEffect(() => {
		const valor = array_respostas?.filter((e: any) => e?.QUESTIONARIO_LABEL === item?.QUESTIONARIO_LABEL);
		if (valor.length === 1) {
			setValue(valor[0].QUESTIONARIO_RESPOSTA)
		}
	}, [])

	const handleSelectItem = (item: QuestionnaireProps, option: number) => {
		setValue(option)
		const dataObj = {
			"QUESTIONARIO_LABEL": `${item.QUESTIONARIO_LABEL}`,
			"QUESTIONARIO_RESPOSTA": [
				option
			]
		}
		const new_array = array_respostas.filter((e:any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
		const new_array_resposta = array_resposta.filter((e:any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
		dispatch(PacAvalActions.set_array_respostas([...new_array, dataObj]));
		dispatch(PacAvalActions.set_array_resposta([...new_array_resposta, dataObj]));	
	}

	const openSelect = () => {
		open ? setOpen(false) : setOpen(true)
	}

	return (
		<SelectContent>
			{
				open ?
					<SelectOptions
						onClick={openSelect}
					>
						{item.QUESTIONARIO_PERGUNTA_OPCOES.map((e: any, i: number) =>
							<SelectItem
								key={i}
								onClick={() => i !== 0 && handleSelectItem(item, i)}
							>
								<p>{e}</p>
								{i === 0 &&
									<svg width="7" height="4" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M3.61963 0.484375L6.69216 3.11824H0.547099L3.61963 0.484375Z" fill="#797979" />
									</svg>
								}
							</SelectItem>
						)}
					</SelectOptions>
					:
					<SelectBox
						onClick={openSelect}
					>
						{item.QUESTIONARIO_PERGUNTA_OPCOES[value]}
						<svg width="7" height="4" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M3.61963 3.37109L0.547099 0.737233H6.69216L3.61963 3.37109Z" fill="#797979" />
						</svg>
					</SelectBox>
			}

		</SelectContent>
	);
};

export default memo(Select);