import moment from "moment";
import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { AssimetriaPros } from "../../../interfaces/assimetria.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { ExecucaoProps } from "../../../interfaces/execucao.interface";
import { AppActions } from "../../../store/ducks/app";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import { ContainerData } from "./styles";
import CardItem from "./_renderCard";
import relAssimetria from "../../../assets/icons/reportAss.svg";
import relEvolucao from "../../../assets/icons/reportEv.svg";
import relForcaTempo from "../../../assets/icons/reportFt.svg";
import relMultifatorial from "../../../assets/icons/multifatorial.svg";
import relFisioterapeutico from "../../../assets/icons/fisioterapeutico.svg";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AmplitudeLog } from "../../../utils/amplitude";
import { TypesErrorModal} from "../../../utils/types";

interface IHandleDatesChange {
	startDate: moment.Moment | null;
	endDate: moment.Moment | null;
};

enum typeReports {
	physiotherapeutic_report = 'relatorio_fisioterapeutico',
	multifactorial_report = 'relatorio_multifatorial',
	asymmetry_report = 'relatorio_assimetria',
	report_force_time = 'relatorio_forca_tempo',
	evolution_report = 'relatorio_evolucao'
};

const EscolhaData: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const config_servicos: ServicosProps = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const pacientes = useSelector((state: any) => state.dashboard.pacientes);
	
	const [startDate, setStartDate] = useState<moment.Moment | null>(
		moment(paciente.USUARIO_CRIADO = pacientes[0]?.USUARIO_CRIADO)
	);

	const [endDate, setEndDate] = useState<moment.Moment | null>(
		current_language?.idioma === "Español"
			? moment().locale("es")
			: moment().locale(
				current_language?.idioma === "Português"
					? "pt-br"
					: current_language?.idioma === "English"
						? "en"
						: "es"
			)
	);

	const permiteDate = ()=> {
		const dateNow = moment().valueOf();
		const dataRef = endDate?.valueOf() != null ? endDate?.valueOf() : dateNow
		const Permit =  dataRef < dateNow;
		if(!!Permit){
			handleContinueCompartilhamento()
		}else{
			dispatch(
				AppActions.set_modal_visible({
					type: TypesErrorModal.FAIL,
					message: current_language?.data_final_maior_que_atual,
				})
			);
		}
	};


	const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
		null
	);


	const handleDatesChange = ({ startDate, endDate }: IHandleDatesChange) => {
		setStartDate(startDate);
		setEndDate(endDate);
	};

	const is_relatorio_evolucao = useSelector(
		(state: any) => state.relatorios.is_relatorio_evolucao
	);
	const is_relatorio_assimetria = useSelector(
		(state: any) => state.relatorios.is_relatorio_assimetria
	);
	const is_relatorio_forca_tempo = useSelector(
		(state: any) => state.relatorios.is_relatorio_forca_tempo
	);
	const is_relatorio_multifatorial = useSelector(
		(state: any) => state.relatorios.is_relatorio_multifatorial
	);
	const is_relatorio_fisioterapeutico = useSelector(
		(state: any) => state.relatorios.is_relatorio_fisioterapeutico
	);
	const execucoes: ExecucaoProps[] = useSelector(
		(state: any) => state.dashboard.execucoes
	);
	const assimetrias: AssimetriaPros[] = useSelector(
		(state: any) => state.dashboard.assimetria
	);
	const set_quantidade_emissoes_relatorios = useSelector(
		(state: any) => state.configs.set_quantidade_emissoes_relatorios
	);
	const anamneses: any = useSelector((state: any) => state.dashboard.anamneses);

	const handleContinueCompartilhamento = () => {
		if (
			!config_servicos.SERVICO_ANAMNESE && (!!is_relatorio_fisioterapeutico || !!is_relatorio_multifatorial) &&
			set_quantidade_emissoes_relatorios && 10 - (set_quantidade_emissoes_relatorios.fisioterapeutico +
				set_quantidade_emissoes_relatorios.multifatorial)
			<= 0
		) {
			dispatch(AppActions.set_modal_emissao_rel(true));
		} else if (
			!config_servicos.SERVICO_ANAMNESE &&
			!!is_relatorio_fisioterapeutico &&
			!!is_relatorio_multifatorial &&
			set_quantidade_emissoes_relatorios && (10 - (set_quantidade_emissoes_relatorios.fisioterapeutico +
				set_quantidade_emissoes_relatorios.multifatorial) === 1)
		) {
			dispatch(AppActions.set_modal_emissao_rel(true));
		} else {
			dispatch(
				RelatorioActions.set_data_compartilhar(
					`${startDate?.format("YYYY-MM-DD")}/${endDate?.format("YYYY-MM-DD")}`
				)
			);
			if (
				is_relatorio_evolucao ||
				is_relatorio_assimetria ||
				is_relatorio_forca_tempo
			) {
				dispatch(AppActions.set_continue_compartilhamento("ExercicioList"));
			} else if (is_relatorio_fisioterapeutico || is_relatorio_multifatorial) {
				dispatch(AppActions.set_continue_compartilhamento("EscolhaAnamnese"));
			}
		}
	};
	



	function handleChange(type: string) {
		if (type === typeReports.physiotherapeutic_report) {
			AmplitudeLog('compart_relat_fisioterapeutico')
			dispatch(
				RelatorioActions.set_relatorio_fisioterapeutico(
					!is_relatorio_fisioterapeutico
				)
			);
		} else if (type === typeReports.multifactorial_report) {
			AmplitudeLog('compart_relat_multifatorial')
			dispatch(
				RelatorioActions.set_relatorio_multifatorial(
					!is_relatorio_multifatorial
				)
			);
		} else if (type === typeReports.asymmetry_report) {
			AmplitudeLog('compart_relat_assimetria')
			dispatch(
				RelatorioActions.set_relatorio_assimetria(!is_relatorio_assimetria)
			);
		} else if (type === typeReports.report_force_time) {
			AmplitudeLog('compart_relat_forca_tempo')
			dispatch(
				RelatorioActions.set_relatorio_forca_tempo(!is_relatorio_forca_tempo)
			);
		} else if (type === typeReports.evolution_report) {
			AmplitudeLog('compart_relat_evolucao')
			dispatch(RelatorioActions.set_relatorio_evolucao(!is_relatorio_evolucao));
		}
	}
	
	return (
		<ContainerData>
			<p className="texto">{current_language?.modal_compartilhar_relatorio}</p>
			<div className="Body">
				<div className="tipo">
					<p>{current_language?.modal_compartilhar_relatorio2}</p>
					<div className="choice">
						<div className="buttons">
							{config_servicos?.SERVICO_REL_AVALIACAO &&
								execucoes.length > 0 && (
									<CardItem
										title={current_language?.evolucao}
										subTitle={current_language?.evolucao_explicacao}
										value={is_relatorio_evolucao}
										icon={relEvolucao}
										type="relatorio_evolucao"
										fncBtn={handleChange}
									/>
								)
							}
							{config_servicos?.SERVICO_REL_ASSIMETRIA &&
								assimetrias.length > 0 && (
									<CardItem
										key={1}
										title={current_language?.assimetria}
										subTitle={current_language?.assimetria_explicacao}
										value={is_relatorio_assimetria}
										icon={relAssimetria}
										type="relatorio_assimetria"
										fncBtn={handleChange}
									/>
								)}
							{config_servicos?.SERVICO_REL_FORCA && execucoes.length > 0 && (
								<CardItem
									title={current_language?.forca_tempo}
									subTitle={current_language?.forca_tempo_explicacao}
									value={is_relatorio_forca_tempo}
									icon={relForcaTempo}
									type="relatorio_forca_tempo"
									fncBtn={handleChange}
								/>
							)}
							{(config_servicos.SERVICO_ANAMNESE || (10 - (set_quantidade_emissoes_relatorios.fisioterapeutico +
								set_quantidade_emissoes_relatorios.multifatorial) >= 0)) &&
								anamneses[0].relatorio_multifatorial.POSSIVEL_GERAR === "S" && (
									<CardItem
										title={current_language?.btn_multifatorial}
										subTitle={current_language?.multifatorial_explicacao}
										value={is_relatorio_multifatorial}
										icon={relMultifatorial}
										type="relatorio_multifatorial"
										fncBtn={handleChange}
									/>
								)}
							{(config_servicos.SERVICO_ANAMNESE || (10 - (set_quantidade_emissoes_relatorios.fisioterapeutico +
								set_quantidade_emissoes_relatorios.multifatorial) >= 0)) &&
								anamneses[0].relatorio_fisioterapeutico.POSSIVEL_GERAR ===
								"S" && (
									<CardItem
										title={current_language?.btn_fisioterapeutico}
										subTitle={current_language?.fisioterapeutico_explicacao}
										value={is_relatorio_fisioterapeutico}
										icon={relFisioterapeutico}
										type="relatorio_fisioterapeutico"
										fncBtn={handleChange}
									/>
								)}
						</div>
					</div>
				</div>
				{(is_relatorio_assimetria ||
					is_relatorio_evolucao ||
					is_relatorio_forca_tempo) && (
						<div className="calendario">
							<p>{current_language?.modal_compartilhar_relatorio3}</p>
							<div className="botoesCalendarios">
								<DateRangePicker
									startDate={startDate}
									startDateId="tata-start-date"
									endDate={endDate}
									endDateId="tata-end-date"
									onDatesChange={handleDatesChange}
									focusedInput={focusedInput}
									onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // <== ERROR OCCURS
									isOutsideRange={() => false}
									displayFormat={() =>
										current_language?.idioma === "Português"
											? "DD/MM/YYYY"
											: current_language?.idioma === "English"
												? "MM/DD/YYYY"
												: "DD-MM-YYYY"
									}
									
									hideKeyboardShortcutsPanel={true}
									weekDayFormat={
										current_language?.idioma === "Português"
											? "ddd"
											: current_language?.idioma === "English"
												? "ddd"
												: "ddd"
									}
								/>
							</div>
						</div>
					)}
			</div>
			<div className="footer">
				<button
					onClick={()=>{
						AmplitudeLog('relat_seleciionado')
						permiteDate()
					}}
					disabled={
						!is_relatorio_assimetria &&
						!is_relatorio_evolucao &&
						!is_relatorio_fisioterapeutico &&
						!is_relatorio_forca_tempo &&
						!is_relatorio_multifatorial
					}
				>
					{current_language?.continuar}
				</button>
			</div>
		</ContainerData>
	);
};

export default EscolhaData;
