import { useHistory } from "react-router-dom";
import { ExecucaoProps } from "../../interfaces/execucao.interface";
import { TypesErrorRequest, TypesModalAviso } from "../../utils/types";
import { ProtocolosProps, ProtoExercicioProps } from "../../interfaces/protocolo.interface";
import { Exercicios2Props } from "../../interfaces/exercicio.interface";

export const Types = {
	SET_MODAL_VISIBLE: "SET_MODAL_VISIBLE",
	CLEAR_MODAL_VISIBLE: "CLEAR_MODAL_VISIBLE",
	SET_MODAL_CADASTRO: "SET_MODAL_CADASTRO",
	SET_MODAL_CONECTA: "SET_MODAL_CONECTA",
	SET_MODAL_CONECTA2: "SET_MODAL_CONECTA2",
	SET_MODAL_CONECTA3: "SET_MODAL_CONECTA3",
	SET_MODAL_CONECTA4: "SET_MODAL_CONECTA4",
	SET_MODAL_CONTA: "SET_MODAL_CONTA",
	SET_MODAL_LINGUAGEM: "SET_MODAL_LINGUAGEM",
	SET_MODAL_REDEFINIR_SENHA: "SET_MODAL_REDEFINIR_SENHA",
	SET_MODAL_FILTRO_ETIQUETAS: "SET_MODAL_FILTRO_ETIQUETAS",
	SET_MODAL_NOTIFICACOES: "SET_MODAL_NOTIFICACOES",
	SET_MODAL_EXCLUIR: "SET_MODAL_EXCLUIR",
	SET_MODAL_CONEXOES: "SET_MODAL_CONEXOES",
	SET_MODAL_UPGRADE: "SET_MODAL_UPGRADE",
	SET_MODAL_UPGRADE_FUNCIONALIDADE: "SET_MODAL_UPGRADE_FUNCIONALIDADE",
	SET_MODAL_UPGRADE_FUNCIONALIDADE_INDISPONIVEL:
		"SET_MODAL_UPGRADE_FUNCIONALIDADE_INDISPONIVEL",
	SET_MODAL_ATRIBUIR_AVALIADOR: "SET_MODAL_ATRIBUIR_AVALIADOR",
	SET_POPOVER: "SET_POPOVER",
	SET_MODAL_ADICIONAR_PACIENTE: "SET_MODAL_ADICIONAR_PACIENTE",
	SET_MODAL_ADICIONAR_AVALIADOR: "SET_MODAL_ADICIONAR_AVALIADOR",
	SET_MODAL_EDITAR_PACIENTE: "SET_MODAL_EDITAR_PACIENTE",
	SET_MODAL_EDITAR_AVALIADOR: "SET_MODAL_EDITAR_AVALIADOR",
	SET_LISTAGEM: "SET_LISTAGEM",
	SET_OPEN_PACIENTE: "SET_OPEN_PACIENTE",
	SET_OPEN_AVALIADOR: "SET_OPEN_AVALIADOR",
	SET_OPEN_EXERCICIO: "SET_OPEN_EXERCICIO",
	SET_OPEN_PROTOCOLO: "SET_OPEN_PROTOCOLO",
	SET_OPEN_EDITAR_PERFIL: "SET_OPEN_EDITAR_PERFIL",
	SET_MODAL_ATRIBUIR_ETIQUETA: "SET_MODAL_ATRIBUIR_ETIQUETA",
	SET_MODAL_ATRIBUIR_FISICA: "SET_MODAL_ATRIBUIR_FISICA",
	SET_MODAL_FOTO_PERFIL: "SET_MODAL_FOTO_PERFIL",
	SET_OPEN_ALTERAR_SENHA: "SET_OPEN_ALTERAR_SENHA",
	SET_OPEN_MEU_PLANO: "SET_OPEN_MEU_PLANO",
	SET_OPEN_PAGAMENTOS: "SET_OPEN_PAGAMENTOS",
	SET_OPEN_RELATORIO: "SET_OPEN_RELATORIO",
	SET_OPEN_FATURAS: "SET_OPEN_FATURAS",
	SET_OPEN_METODO_PAGAMENTO: "SET_OPEN_METODO_PAGAMENTO",
	SET_OPEN_BARRA_SUPERIOR: "SET_OPEN_BARRA_SUPERIOR",
	SET_MODAL_ADICIONAR_ETIQUETA: "SET_MODAL_ADICIONAR_ETIQUETA",
	SET_OPEN_VISUALIZAR_FATURA: "SET_OPEN_VISUALIZAR_FATURA",
	SET_OPEN_ADICIONAR_METODO_PAGAMENTO: "SET_OPEN_ADICIONAR_METODO_PAGAMENTO",
	SET_PROTOCOLO_LIST: "SET_OPEN_PROTOCOLO_LIST",
	SET_MODAL_CONTAGEM_EXERCICIO: "SET_MODAL_CONTAGEM_EXERCICIO",
	SET_MODAL_EXCLUIR_PROTOCOLO: "SET_MODAL_EXCLUIR_PROTOCOLO",
	SET_CONTADOR: "SET_CONTADOR",
	SET_PLAY_EXERCICIOS: "SET_PLAY_EXERCICIOS",
	SET_OPEN_CONFIGURACOES_EXERCICIOS: "SET_OPEN_CONFIGURACOES_EXERCICIOS",
	SET_MODAL_SELECT_AVALIACAO: "SET_MODAL_SELECT_AVALIACAO",
	SET_SELECTED_AVALIACAO: "SET_SELECTED_AVALIACAO",
	SET_SELECTED_TYPE_PRO_LIVRE: "SET_SELECTED_TYPE_PRO_LIVRE",
	SET_MODAL_CONFIGURACOES_EXECUCAO: "SET_MODAL_CONFIGURACOES_EXECUCAO",
	SET_FORCA_MINIMA: "SET_FORCA_MINIMA",
	SET_FORCA_MINIMA_STR: "SET_FORCA_MINIMA_STR",
	SET_MAX_FORCE: "SET_MAX_FORCE",
	SET_FORCA_MAXIMA: "SET_FORCA_MAXIMA",
	SET_FORCA_MAXIMA_STR: "SET_FORCA_MAXIMA_STR",
	SET_ULTIMA_AVALIACAO: "SET_ULTIMA_AVALIACAO",
	SET_SLIDER_EXECUCAO: "SET_SLIDER_EXECUCAO",
	SET_CHECKLIST_EXECUCAO: "SET_CHECKLIST_EXECUCAO",
	SET_MODAL_DUVIDA_EXECUCAO: "SET_MODAL_DUVIDA_EXECUCAO",
	SET_MODAL_DUVIDA_EXECUCAO_RESULTADO: "SET_MODAL_DUVIDA_EXECUCAO_RESULTADO",
	SET_RESULTADO_EXERCICIO: "SET_RESULTADO_EXERCICIO",
	SET_EXERCICIO: "SET_EXERCICIO",
	SET_OPEN_ADICIONAR_ANOTACAO: "SET_OPEN_ADICIONAR_ANOTACAO",
	SET_OPEN_APAGAR_ANOTACAO: "SET_OPEN_APAGAR_ANOTACAO",
	SET_OPEN_EDITAR_ANOTACAO: "SET_OPEN_EDITAR_ANOTACAO",
	SET_MODAL_COMPARTILHAMENTO: "SET_MODAL_COMPARTILHAMENTO",
	SET_CONTINUE_COMPARTILHAMENTO: "SET_CONTINUE_COMPARTILHAMENTO",
	SET_OPEN_REMOVER_DADOS: "SET_OPEN_REMOVER_DADOS",
	SET_TIMER_RESET: "SET_TIMER_RESET",
	SET_LISTA_FILTRO: "SET_LISTA_FILTRO",
	SET_FOTO_PERFIL: "SET_FOTO_PERFIL",
	SET_LISTA_PACIENTES: "SET_LISTA_PACIENTES",
	SET_LISTA_EXCLUIDOS: "SET_LISTA_EXCLUIDOS",
	SET_MODAL_MODO_EXERCICIO: "SET_MODAL_MODO_EXERCICIO",
	SET_MODAL_LADO_EXERCICIO: "SET_MODAL_LADO_EXERCICIO",
	SET_MODAL_LIXEIRA: "SET_MODAL_LIXEIRA",
	SET_MODAL_RECUPERAR: "SET_MODAL_RECUPERAR",
	SET_TOAST: "SET_TOAST",
	CLEAR_TOAST: "CLEAR_TOAST",
	SET_OPEN_EDITAR_ETIQUETA: "SET_OPEN_EDITAR_ETIQUETA",
	SET_OPEN_EXCLUIR_ETIQUETA: "SET_OPEN_EXCLUIR_ETIQUETA",
	SET_OPEN_BAIXAR_APP: "SET_OPEN_BAIXAR_APP",
	SET_MODAL_TUTORIAL: "SET_MODAL_TUTORIAL",
	SET_EXERCICIO_TUTORIAL: "SET_EXERCICIO_TUTORIAL",
	SET_OPEN_CENTRAL_AJUDA: "SET_OPEN_CENTRAL_AJUDA",
	SET_NUMBER_GRAFICS: "SET_NUMBER_GRAFICS",
	SET_EXCLUIR_EVA: "SET_EXCLUIR_EVA",
	SET_EXCLUIR_ANAMNESE: "SET_EXCLUIR_ANAMNESE",
	SET_MODAL_MIGRACAO: "SET_MODAL_MIGRACAO",
	SET_MODAL_EMISSAO_RELATORIOS: "SET_MODAL_EMISSAO_RELATORIOS",
	SET_MODAL_CONFIG_FORC_MIN: "SET_MODAL_CONFIG_FORC_MIN",
	SET_MODAL_SHERE_ANAMNESE: "SET_MODAL_SHERE_ANAMNESE",
	SET_TITLE_EXPORT_DATA: "SET_TITLE_EXPORT_DATA",
	SET_NOTIFICATION_VIEW: "SET_NOTIFICATION_VIEW",
	SET_NOTIFICATION_VIEW_ALL: "SET_NOTIFICATION_VIEW_ALL",
	SET_CONFIRM_CLOSE_MODAL_EDIT: "SET_CONFIRM_CLOSE_MODAL_EDIT",
	SET_MODAL_INADIMPLENTE: "SET_OPEN_MODAL_INADIMPLENTE",
	SET_MODAL_CONTINUAR_OR_GERAR_NEW_SF_36:
		"SET_MODAL_CONTINUAR_OR_GERAR_NEW_SF_36",
	SET_MODAL_CONTINUE_OR_TO_GENERATE_NEW_ANAMNESE:
		"SET_MODAL_CONTINUE_OR_TO_GENERATE_NEW_ANAMNESE",
	SET_MODAL_BLOQUEIO_DINO: "SET_MODAL_BLOQUEIO_DINO",
	SET_DINAMOS_BLOCKED: "SET_DINAMOS_BLOCKED",
	SET_MODAL_SATISFACAO: "SET_MODAL_SATISFACAO",
	SET_MODAL_SATISFACAO_EXAME: "SET_MODAL_SATISFACAO_EXAME",
	SET_SELECTED_OPTIONS_IN_PAGE_PATIENT: "SET_SELECTED_OPTIONS_IN_PAGE_PATIENT",
	SET_MODAL_AVISO_CADASTRO_CARTAO: "SET_MODAL_AVISO_CADASTRO_CARTAO",
	IS_FIRST_LOAD: "IS_FIRST_LOAD",
	SET_MODAL_REGISTER_SUCCESS: "SET_MODAL_REGISTER_SUCCESS",
	SET_OPTION_MENU: "SET_OPTION_MENU",
	SET_INFO_PAGE_EDITAR_ETIQUETA: "SET_INFO_PAGE_EDITAR_ETIQUETA",
	SET_MODAL_FOTO_EDICAO_PERFIL_ASSINATURA:
		"SET_MODAL_FOTO_EDICAO_PERFIL_ASSINATURA",
	SET_MODAL_ALTERAR_SENHA: "SET_MODAL_ALTERAR_SENHA",
	SET_MODAL_ADICIONAR_ASSINATURA: "SET_MODAL_ADICIONAR_ASSINATURA",
	SET_MODAL_EXCLUIR_PACIENTE: "SET_MODAL_EXCLUIR_PACIENTE",
	SET_PATIENT_ETIQUETA: "SET_PATIENT_ETIQUETA",
	SET_OPEN_MODAL_EXCLUIR_PROTOCOLO: "SET_OPEN_MODAL_EXCLUIR_PROTOCOLO",
	SET_OPEN_MODAL_OURO: "SET_OPEN_MODAL_OURO",
	SET_OPEN_MODAL_FIM_DESCANSO: "SET_OPEN_MODAL_FIM_DESCANSO",
	SET_OPEN_MODAL_ESCOLHA_MODO: "SET_OPEN_MODAL_ESCOLHA_MODO",
	SET_MODAL_CONECTA_HAND_GRIP: 'SET_MODAL_CONECTA_HAND_GRIP',
	SET_MODAL_CONECTA_HAND_GRIP_PASSOS: 'SET_MODAL_CONECTA_HAND_GRIP_PASSOS',
	SET_MODAL_SELECT_DOMINANT_SIDE: 'SET_MODAL_SELECT_DOMINANT_SIDE',
};

const INITIAL_STATE = {
	is_open: false,
	message: "",
	type: TypesModalAviso.SUCCESS,
	is_open_cadastro: false,
	is_open_conecta: false,
	is_open_conecta2: false,
	is_open_conecta3: false,
	is_open_conecta4: false,
	is_open_conta: false,
	is_open_linguagem: false,
	is_open_redefinir_senha: false,
	is_open_filtro_etiquetas: false,
	is_open_notificacoes: false,
	is_open_excluir: false,
	is_open_conexoes: false,
	is_open_upgrade: false,
	is_open_upgrade_funcionalidade: false,
	is_open_upgrade_funcionalidade_indisponivel: false,
	is_open_atribuir_avaliador: false,
	is_open_adicionar_paciente: false,
	is_open_adicionar_avaliador: false,
	is_open_editar_paciente: false,
	is_open_editar_avaliador: false,
	is_open_listagem: true,
	is_open_paciente: false,
	is_open_avaliador: false,
	is_open_exercicio: false,
	is_open_protocolo: false,
	is_open_editar_perfil: false,
	is_open_atribuir_etiqueta: false,
	is_open_atribuir_fisica: false,
	is_open_alterar_senha: false,
	is_open_foto_perfil: false,
	is_open_meu_plano: false,
	is_open_pagamentos: false,
	is_open_relatorio: false,
	is_open_faturas: false,
	is_open_metodo_pagamento: false,
	is_open_barra_superior: false,
	is_open_adicionar_etiqueta: false,
	is_open_visualizar_fatura: false,
	is_open_adicionar_metodo_pagamento: false,
	protocolo_list: false,
	is_open_contagem_exercicio: false,
	is_open_excluir_protocolo: false,
	contador: false,
	is_play_exercicios: false,
	is_open_configuracoes_exercicios: false,
	is_open_select_avaliacao: false,
	is_selected_avaliacao: "",
	is_selected_type_pro_livre: "",
	is_open_configuracoes_execucao: false,
	forca_minima: 3,
	forca_minima_str: "3 . 00",
	max_force: false,
	forca_maxima: 3.6,
	forca_maxima_str: "3 . 60",
	ultima_avaliacao: 0,
	slider_execucao: 30,
	checklist_execucao: false,
	is_open_duvida_execucao: false,
	is_open_duvida_execucao_resultado: false,
	resultado_execucao: false,
	exercicio: false,
	is_open_adicionar_anotacao: false,
	is_open_apagar_anotacao: false,
	is_open_editar_anotacao: false,
	is_open_compartilhamento: false,
	continue_compartilhamento: "",
	is_open_remover_dados: false,
	timer_reset: false,
	lista_filtro: [],
	foto_perfil: null,
	lista_pacientes: [],
	lista_excluidos: [],
	is_open_modo_exercicio: false,
	lado_exercicio: "",
	modal_lixeira: false,
	is_open_modal_recuperar: false,
	message_toast: "",
	type_toast: TypesErrorRequest.SUCCESS,
	is_open_toast: false,
	is_open_editar_etiqueta: false,
	is_open_excluir_etiqueta: false,
	is_open_baixar_app: false,
	is_open_tutorial: false,
	exercicio_tutorial: "",
	is_open_central_ajuda: false,
	number_grafics: false,
	is_age: 0,
	excluir_eva: false,
	excluir_anamnese: false,
	is_open_migracao: false,
	is_modal_emissao_rel: false,
	is_modal_config_forc_min: false,
	is_open_shere_anamnese: false,
	title_export_data: false,
	notification_view: [],
	confirm_close_modal_edit: false,
	is_open_modal_inadimplente: false,
	is_inadimplente: false,
	is_open_continuar_ou_gerar_sf_36: false,
	is_open_continue_or_to_generate_anamnese: false,
	continue_anamnese_type: "",
	is_open_modal_bloqueio_dino: false,
	dinamos_blocked: [],
	redirect: "",
	set_modal_satisfacao: false,
	type_modal_satisfacao: "",
	set_modal_satisfacao_exame: false,
	type_modal_satisfacao_exame: null,
	block_evaluated_patients: false,
	set_selected_options_page_pattient: "avaliações",
	visible_modal_aviso_cadastro_cartao: false,
	isFirstLoad: true,
	open_modal_register_sucess: false,
	title_modal_register_sucess: "",
	subtitle_modal_register_sucess: "",
	set_option_menu: "Início",
	openMenu: false,
	data_edit_tag: {},
	open_modal_edicao_foto_perfil_assinatura: false,
	titulo_modal_edicao_foto_perfil_assinatura: "",
	is_open_modal_alterar_senha: false,
	is_open_modal_adicionar_assinatura: false,
	is_open_modal_excluir_paciente: false,
	set_patient_in_etiqueta: false,
	set_array_patient_etiquetas: [],
	is_open_modal_excluir_protocolo: false,
	is_open_modal_ouro: false,
	is_open_modal_fim_descanso: false,
	is_open_modal_escolha_modo: false,
	is_open_conecta_hand_grip: false,
	is_open_conecta_hand_grip_passos: false,
	is_open_select_dominant_side: false,
};

export default function app(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.SET_MODAL_VISIBLE:
			return {
				...state,
				is_open: action.payload.status,
				type: action.payload.type,
				message: action.payload.message,
				redirect: action.payload.redirect,
			};
		case Types.SET_NOTIFICATION_VIEW:
			return {
				...state,
				notification_view: [...state.notification_view, action.payload],
			};
		case Types.SET_NOTIFICATION_VIEW_ALL:
			return {
				...state,
				notification_view: action.payload,
			};
		case Types.CLEAR_MODAL_VISIBLE: {
			return {
				...state,
				is_open: false,
				type: "",
				message: "",
			};
		}
		case Types.SET_MODAL_CADASTRO: {
			return {
				...state,
				is_open_cadastro: action.payload,
			};
		}
		case Types.SET_MODAL_CONECTA: {
			return {
				...state,
				is_open_conecta: action.payload,
			};
		}
		case Types.SET_MODAL_CONECTA2: {
			return {
				...state,
				is_open_conecta2: action.payload,
			};
		}
		case Types.SET_MODAL_CONECTA3: {
			return {
				...state,
				is_open_conecta3: action.payload,
			};
		}
		case Types.SET_MODAL_CONECTA4: {
			return {
				...state,
				is_open_conecta4: action.payload,
			};
		}
		case Types.SET_MODAL_CONTA: {
			return {
				...state,
				is_open_conta: action.payload,
			};
		}
		case Types.SET_MODAL_LINGUAGEM: {
			return {
				...state,
				is_open_linguagem: action.payload,
			};
		}
		case Types.SET_MODAL_REDEFINIR_SENHA: {
			return {
				...state,
				is_open_redefinir_senha: action.payload,
			};
		}
		case Types.SET_MODAL_FILTRO_ETIQUETAS: {
			return {
				...state,
				is_open_filtro_etiquetas: action.payload,
			};
		}
		case Types.SET_MODAL_NOTIFICACOES: {
			return {
				...state,
				is_open_notificacoes: action.payload,
			};
		}
		case Types.SET_MODAL_EXCLUIR: {
			return {
				...state,
				is_open_excluir: action.payload,
			};
		}
		case Types.SET_MODAL_CONEXOES: {
			return {
				...state,
				is_open_conexoes: action.payload,
			};
		}
		case Types.SET_MODAL_UPGRADE: {
			return {
				...state,
				is_open_upgrade: action.payload.status,
				block_evaluated_patients: action.payload.evaluatedPatients,
			};
		}
		case Types.SET_MODAL_UPGRADE_FUNCIONALIDADE: {
			return {
				...state,
				is_open_upgrade_funcionalidade: action.payload,
			};
		}
		case Types.SET_MODAL_UPGRADE_FUNCIONALIDADE_INDISPONIVEL: {
			return {
				...state,
				is_open_upgrade_funcionalidade_indisponivel: action.payload,
			};
		}
		case Types.SET_MODAL_ATRIBUIR_AVALIADOR: {
			return {
				...state,
				is_open_atribuir_avaliador: action.payload,
			};
		}
		case Types.SET_POPOVER: {
			return {
				...state,
				is_open_redefinir_senha: action.payload,
			};
		}
		case Types.SET_MODAL_ADICIONAR_PACIENTE: {
			return {
				...state,
				is_open_adicionar_paciente: action.payload,
			};
		}
		case Types.SET_MODAL_ADICIONAR_AVALIADOR: {
			return {
				...state,
				is_open_adicionar_avaliador: action.payload,
			};
		}
		case Types.SET_MODAL_EDITAR_PACIENTE: {
			return {
				...state,
				is_open_editar_paciente: action.payload,
			};
		}
		case Types.SET_MODAL_EDITAR_AVALIADOR: {
			return {
				...state,
				is_open_editar_avaliador: action.payload,
			};
		}
		case Types.SET_LISTAGEM: {
			return {
				...state,
				is_open_listagem: action.payload,
			};
		}
		case Types.SET_OPEN_PACIENTE: {
			return {
				...state,
				is_open_paciente: action.payload,
			};
		}
		case Types.SET_OPEN_AVALIADOR: {
			return {
				...state,
				is_open_avaliador: action.payload,
			};
		}
		case Types.SET_OPEN_EXERCICIO: {
			return {
				...state,
				is_open_exercicio: action.payload,
			};
		}
		case Types.SET_OPEN_PROTOCOLO: {
			return {
				...state,
				is_open_protocolo: action.payload,
			};
		}
		case Types.SET_OPEN_EDITAR_PERFIL: {
			return {
				...state,
				is_open_editar_perfil: action.payload,
			};
		}
		case Types.SET_MODAL_ATRIBUIR_ETIQUETA: {
			return {
				...state,
				is_open_atribuir_etiqueta: action.payload,
			};
		}
		case Types.SET_MODAL_ATRIBUIR_FISICA: {
			return {
				...state,
				is_open_atribuir_fisica: action.payload,
			};
		}
		case Types.SET_OPEN_ALTERAR_SENHA: {
			return {
				...state,
				is_open_alterar_senha: action.payload,
			};
		}
		case Types.SET_MODAL_FOTO_PERFIL: {
			return {
				...state,
				is_open_foto_perfil: action.payload,
			};
		}
		case Types.SET_OPEN_MEU_PLANO: {
			return {
				...state,
				is_open_meu_plano: action.payload,
			};
		}
		case Types.SET_OPEN_PAGAMENTOS: {
			return {
				...state,
				is_open_pagamentos: action.payload,
			};
		}
		case Types.SET_OPEN_RELATORIO: {
			return {
				...state,
				is_open_relatorio: action.payload,
			};
		}
		case Types.SET_OPEN_FATURAS: {
			return {
				...state,
				is_open_faturas: action.payload,
			};
		}
		case Types.SET_OPEN_METODO_PAGAMENTO: {
			return {
				...state,
				is_open_metodo_pagamento: action.payload,
			};
		}
		case Types.SET_OPEN_BARRA_SUPERIOR: {
			return {
				...state,
				is_open_barra_superior: action.payload,
			};
		}
		case Types.SET_MODAL_ADICIONAR_ETIQUETA: {
			return {
				...state,
				is_open_adicionar_etiqueta: action.payload,
			};
		}
		case Types.SET_OPEN_VISUALIZAR_FATURA: {
			return {
				...state,
				is_open_visualizar_fatura: action.payload,
			};
		}
		case Types.SET_OPEN_ADICIONAR_METODO_PAGAMENTO: {
			return {
				...state,
				is_open_adicionar_metodo_pagamento: action.payload,
			};
		}
		case Types.SET_PROTOCOLO_LIST: {
			return {
				...state,
				protocolo_list: action.payload,
			};
		}
		case Types.SET_MODAL_CONTAGEM_EXERCICIO: {
			return {
				...state,
				is_open_contagem_exercicio: action.payload,
			};
		}
		case Types.SET_MODAL_EXCLUIR_PROTOCOLO: {
			return {
				...state,
				is_open_excluir_protocolo: action.payload,
			};
		}
		case Types.SET_CONTADOR: {
			return {
				...state,
				contador: action.payload,
			};
		}
		case Types.SET_OPEN_CONFIGURACOES_EXERCICIOS: {
			return {
				...state,
				is_open_configuracoes_exercicios: action.payload,
			};
		}
		case Types.SET_PLAY_EXERCICIOS: {
			return {
				...state,
				is_play_exercicios: action.payload,
			};
		}
		case Types.SET_MODAL_SELECT_AVALIACAO: {
			return {
				...state,
				is_open_select_avaliacao: action.payload,
			};
		}
		case Types.SET_SELECTED_AVALIACAO: {
			return {
				...state,
				is_selected_avaliacao: action.payload,
			};
		}
		case Types.SET_SELECTED_TYPE_PRO_LIVRE: {
			return {
				...state,
				is_selected_type_pro_livre: action.payload,
			};
		}
		case Types.SET_MODAL_CONFIGURACOES_EXECUCAO: {
			return {
				...state,
				is_open_configuracoes_execucao: action.payload,
			};
		}
		case Types.SET_FORCA_MINIMA: {
			return {
				...state,
				forca_minima: action.payload,
			};
		}
		case Types.SET_FORCA_MINIMA_STR: {
			return {
				...state,
				forca_minima_str: action.payload,
			};
		}
		case Types.SET_MAX_FORCE: {
			return {
				...state,
				max_force: action.payload,
			};
		}
		case Types.SET_FORCA_MAXIMA: {
			return {
				...state,
				forca_maxima: action.payload,
			};
		}
		case Types.SET_FORCA_MAXIMA_STR: {
			return {
				...state,
				forca_maxima_str: action.payload,
			};
		}
		case Types.SET_ULTIMA_AVALIACAO: {
			return {
				...state,
				ultima_avaliacao: action.payload,
			};
		}
		case Types.SET_SLIDER_EXECUCAO: {
			return {
				...state,
				slider_execucao: action.payload,
			};
		}
		case Types.SET_CHECKLIST_EXECUCAO: {
			return {
				...state,
				checklist_execucao: action.payload,
			};
		}
		case Types.SET_MODAL_DUVIDA_EXECUCAO: {
			return {
				...state,
				is_open_duvida_execucao: action.payload,
			};
		}
		case Types.SET_MODAL_DUVIDA_EXECUCAO_RESULTADO: {
			return {
				...state,
				is_open_duvida_execucao_resultado: action.payload,
			};
		}
		case Types.SET_RESULTADO_EXERCICIO: {
			return {
				...state,
				resultado_execucao: action.payload,
			};
		}
		case Types.SET_EXERCICIO: {
			return {
				...state,
				exercicio: action.payload,
			};
		}
		case Types.SET_OPEN_ADICIONAR_ANOTACAO: {
			return {
				...state,
				is_open_adicionar_anotacao: action.payload,
			};
		}
		case Types.SET_OPEN_APAGAR_ANOTACAO: {
			return {
				...state,
				is_open_apagar_anotacao: action.payload,
			};
		}
		case Types.SET_OPEN_EDITAR_ANOTACAO: {
			return {
				...state,
				is_open_editar_anotacao: action.payload,
			};
		}
		case Types.SET_MODAL_COMPARTILHAMENTO: {
			return {
				...state,
				is_open_compartilhamento: action.payload.status,
				is_age: action.payload.age,
			};
		}
		case Types.SET_CONTINUE_COMPARTILHAMENTO: {
			return {
				...state,
				continue_compartilhamento: action.payload,
			};
		}

		case Types.SET_OPEN_REMOVER_DADOS: {
			return {
				...state,
				is_open_remover_dados: action.payload,
			};
		}
		case Types.SET_TIMER_RESET: {
			return {
				...state,
				timer_reset: action.payload,
			};
		}
		case Types.SET_LISTA_FILTRO: {
			return {
				...state,
				lista_filtro: action.payload,
			};
		}
		case Types.SET_FOTO_PERFIL: {
			return {
				...state,
				foto_perfil: action.payload,
			};
		}
		case Types.SET_LISTA_PACIENTES: {
			return {
				...state,
				lista_pacientes: action.payload,
			};
		}
		case Types.SET_LISTA_EXCLUIDOS: {
			return {
				...state,
				lista_excluidos: action.payload,
			};
		}
		case Types.SET_MODAL_MODO_EXERCICIO: {
			return {
				...state,
				is_open_modo_exercicio: action.payload,
			};
		}
		case Types.SET_MODAL_LADO_EXERCICIO: {
			return {
				...state,
				lado_exercicio: action.payload,
			};
		}
		case Types.SET_MODAL_LIXEIRA: {
			return {
				...state,
				modal_lixeira: action.payload,
			};
		}
		case Types.SET_MODAL_RECUPERAR: {
			return {
				...state,
				is_open_modal_recuperar: action.payload,
			};
		}
		case Types.SET_OPEN_EDITAR_ETIQUETA: {
			return {
				...state,
				is_open_editar_etiqueta: action.payload,
			};
		}
		case Types.SET_OPEN_EXCLUIR_ETIQUETA: {
			return {
				...state,
				is_open_excluir_etiqueta: action.payload,
			};
		}
		case Types.SET_OPEN_BAIXAR_APP: {
			return {
				...state,
				is_open_baixar_app: action.payload,
			};
		}
		case Types.SET_MODAL_TUTORIAL: {
			return {
				...state,
				is_open_tutorial: action.payload,
			};
		}
		case Types.SET_EXERCICIO_TUTORIAL: {
			return {
				...state,
				exercicio_tutorial: action.payload,
			};
		}
		case Types.SET_TOAST:
			return {
				...state,
				is_open_toast: action.payload.status_toast,
				type_toast: action.payload.type_toast,
				message_toast: action.payload.message_toast,
			};
		case Types.CLEAR_TOAST: {
			return {
				...state,
				is_open_toast: false,
				type_toast: "",
				message_toast: "",
			};
		}
		case Types.SET_OPEN_CENTRAL_AJUDA: {
			return {
				...state,
				is_open_central_ajuda: action.payload,
			};
		}
		case Types.SET_NUMBER_GRAFICS: {
			return {
				...state,
				number_grafics: action.payload,
			};
		}
		case Types.SET_EXCLUIR_EVA: {
			return {
				...state,
				excluir_eva: action.payload,
			};
		}
		case Types.SET_EXCLUIR_ANAMNESE: {
			return {
				...state,
				excluir_anamnese: action.payload,
			};
		}
		case Types.SET_MODAL_MIGRACAO: {
			return {
				...state,
				is_open_migracao: action.payload,
			};
		}
		case Types.SET_MODAL_EMISSAO_RELATORIOS: {
			return {
				...state,
				is_modal_emissao_rel: action.payload,
			};
		}
		case Types.SET_MODAL_CONFIG_FORC_MIN: {
			return {
				...state,
				is_modal_config_forc_min: action.payload,
			};
		}
		case Types.SET_MODAL_SHERE_ANAMNESE: {
			return {
				...state,
				is_open_shere_anamnese: action.payload,
			};
		}
		case Types.SET_TITLE_EXPORT_DATA: {
			return {
				...state,
				title_export_data: action.payload,
			};
		}
		case Types.SET_CONFIRM_CLOSE_MODAL_EDIT: {
			return {
				...state,
				confirm_close_modal_edit: action.payload,
			};
		}
		case Types.SET_MODAL_INADIMPLENTE: {
			return {
				...state,
				is_open_modal_inadimplente: action.payload.status,
				is_inadimplente: action.payload.is_inadimplente,
			};
		}
		case Types.SET_MODAL_CONTINUAR_OR_GERAR_NEW_SF_36: {
			return {
				...state,
				is_open_continuar_ou_gerar_sf_36: action.payload,
			};
		}
		case Types.SET_MODAL_CONTINUE_OR_TO_GENERATE_NEW_ANAMNESE: {
			return {
				...state,
				is_open_continue_or_to_generate_anamnese: action.payload.status,
				continue_anamnese_type: action.payload.type,
			};
		}
		case Types.SET_MODAL_BLOQUEIO_DINO: {
			return {
				...state,
				is_open_modal_bloqueio_dino: action.payload,
			};
		}
		case Types.SET_DINAMOS_BLOCKED: {
			return {
				...state,
				dinamos_blocked: action.payload,
			};
		}
		case Types.SET_MODAL_SATISFACAO_EXAME:
			return {
				...state,
				set_modal_satisfacao_exame: action.payload.status,
				type_modal_satisfacao_exame: action.payload.type_modal_satisfacao_exame,
			};
		case Types.SET_SELECTED_OPTIONS_IN_PAGE_PATIENT:
			return {
				...state,
				set_selected_options_page_pattient: action.payload,
			};
		case Types.SET_MODAL_SATISFACAO:
			return {
				...state,
				set_modal_satisfacao: action.payload.status,
				type_modal_satisfacao: action.payload.type,
			};
		case Types.IS_FIRST_LOAD:
			return {
				...state,
				isFirstLoad: action.payload,
			};
		case Types.SET_MODAL_AVISO_CADASTRO_CARTAO:
			return {
				...state,
				visible_modal_aviso_cadastro_cartao: action.payload,
			};
		case Types.SET_MODAL_REGISTER_SUCCESS:
			return {
				...state,
				open_modal_register_sucess: action.payload.open,
				title_modal_register_sucess: action.payload.title,
				subtitle_modal_register_sucess: action.payload.subtitle,
			};
		case Types.SET_INFO_PAGE_EDITAR_ETIQUETA:
			return {
				...state,
				data_edit_tag: action.payload,
			};
		case Types.SET_MODAL_FOTO_EDICAO_PERFIL_ASSINATURA:
			return {
				...state,
				open_modal_edicao_foto_perfil_assinatura: action.payload.status,
				titulo_modal_edicao_foto_perfil_assinatura: action.payload.type,
			};
		case Types.SET_OPTION_MENU:
			return {
				...state,
				openMenu: action.payload.openMenu,
				set_option_menu: action.payload.option,
			};
		case Types.SET_MODAL_ALTERAR_SENHA:
			return {
				...state,
				is_open_modal_alterar_senha: action.payload,
			};
		case Types.SET_MODAL_ADICIONAR_ASSINATURA:
			return {
				...state,
				is_open_modal_adicionar_assinatura: action.payload,
			};
		case Types.SET_MODAL_EXCLUIR_PACIENTE:
			return {
				...state,
				is_open_modal_excluir_paciente: action.payload,
			};
		case Types.SET_PATIENT_ETIQUETA:
			return {
				...state,
				set_patient_in_etiqueta: action.payload.status,
				set_array_patient_etiquetas: action.payload.patients,
			};
		case Types.SET_OPEN_MODAL_EXCLUIR_PROTOCOLO:
			return {
				...state,
				is_open_modal_excluir_protocolo: action.payload,
			};
		case Types.SET_OPEN_MODAL_OURO:
			return {
				...state,
				is_open_modal_ouro: action.payload,
			};
		case Types.SET_OPEN_MODAL_FIM_DESCANSO:
			return {
				...state,
				is_open_modal_fim_descanso: action.payload,
			};
		case Types.SET_OPEN_MODAL_ESCOLHA_MODO:
			return {
				...state,
				is_open_modal_escolha_modo: action.payload,
			};
		case Types.SET_MODAL_CONECTA_HAND_GRIP: {
			return {
				...state,
				is_open_conecta_hand_grip: action.payload,
			};
		}
		case Types.SET_MODAL_CONECTA_HAND_GRIP_PASSOS: {
			return {
				...state,
				is_open_conecta_hand_grip_passos: action.payload,
			};
		}
		case Types.SET_MODAL_SELECT_DOMINANT_SIDE: {
			return {
				...state,
				is_open_select_dominant_side: action.payload,
			};
		}	
		default:
			return state;
	}
}

export const AppActions = {
	set_modal_visible: ({ status = true, type, message, redirect }: any) => ({
		type: Types.SET_MODAL_VISIBLE,
		payload: { status, type, message, redirect },
	}),
	set_notification_view: (id_notification: any) => ({
		type: Types.SET_NOTIFICATION_VIEW,
		payload: id_notification,
	}),
	set_notification_view_all: (notification: any) => ({
		type: Types.SET_NOTIFICATION_VIEW_ALL,
		payload: notification,
	}),
	clear_modal_visible: () => ({
		type: Types.CLEAR_MODAL_VISIBLE,
	}),
	set_modal_cadastro: (status: any) => ({
		type: Types.SET_MODAL_CADASTRO,
		payload: status,
	}),
	set_modal_conecta: (status: any) => ({
		type: Types.SET_MODAL_CONECTA,
		payload: status,
	}),
	set_modal_conecta2: (status: any) => ({
		type: Types.SET_MODAL_CONECTA2,
		payload: status,
	}),
	set_modal_conecta3: (status: any) => ({
		type: Types.SET_MODAL_CONECTA3,
		payload: status,
	}),
	set_modal_conecta4: (status: any) => ({
		type: Types.SET_MODAL_CONECTA4,
		payload: status,
	}),
	set_modal_conta: (status: any) => ({
		type: Types.SET_MODAL_CONTA,
		payload: status,
	}),
	set_modal_linguagem: (status: any) => ({
		type: Types.SET_MODAL_LINGUAGEM,
		payload: status,
	}),
	set_modal_redefinir_senha: (status: any) => ({
		type: Types.SET_MODAL_REDEFINIR_SENHA,
		payload: status,
	}),
	set_modal_filtro_etiquetas: (status: any) => ({
		type: Types.SET_MODAL_FILTRO_ETIQUETAS,
		payload: status,
	}),
	set_modal_notificacoes: (status: any) => ({
		type: Types.SET_MODAL_NOTIFICACOES,
		payload: status,
	}),
	set_modal_excluir: ({ status, parameter }: any) => ({
		type: Types.SET_MODAL_EXCLUIR,
		payload: { status, parameter },
	}),
	set_modal_conexoes: (status: any) => ({
		type: Types.SET_MODAL_CONEXOES,
		payload: status,
	}),
	set_modal_upgrade: (status: boolean, evaluatedPatients?: boolean) => ({
		type: Types.SET_MODAL_UPGRADE,
		payload: { status, evaluatedPatients },
	}),
	set_modal_upgrade_funcionalidade: (status: any) => ({
		type: Types.SET_MODAL_UPGRADE_FUNCIONALIDADE,
		payload: status,
	}),
	set_modal_upgrade_funcionalidade_indisponivel: (status: any) => ({
		type: Types.SET_MODAL_UPGRADE_FUNCIONALIDADE_INDISPONIVEL,
		payload: status,
	}),
	set_modal_atribuir_avaliador: (status: any) => ({
		type: Types.SET_MODAL_ATRIBUIR_AVALIADOR,
		payload: status,
	}),
	set_modal_adicionar_paciente: (status: any) => ({
		type: Types.SET_MODAL_ADICIONAR_PACIENTE,
		payload: status,
	}),
	set_modal_adicionar_avaliador: (status: any) => ({
		type: Types.SET_MODAL_ADICIONAR_AVALIADOR,
		payload: status,
	}),
	set_modal_editar_paciente: (status: any) => ({
		type: Types.SET_MODAL_EDITAR_PACIENTE,
		payload: status,
	}),
	set_modal_editar_avaliador: (status: any) => ({
		type: Types.SET_MODAL_EDITAR_AVALIADOR,
		payload: status,
	}),
	set_listagem: (status: any) => ({
		type: Types.SET_LISTAGEM,
		payload: status,
	}),
	set_open_paciente: (status: any) => ({
		type: Types.SET_OPEN_PACIENTE,
		payload: status,
	}),
	set_open_avaliador: (status: any) => ({
		type: Types.SET_OPEN_AVALIADOR,
		payload: status,
	}),
	set_open_exercicio: (status: any) => ({
		type: Types.SET_OPEN_EXERCICIO,
		payload: status,
	}),
	set_open_protocolo: (status: any, edit: any = false) => ({
		type: Types.SET_OPEN_PROTOCOLO,
		payload: { status, edit },
	}),
	set_open_editar_perfil: (status: any) => ({
		type: Types.SET_OPEN_EDITAR_PERFIL,
		payload: status,
	}),
	set_modal_atribuir_etiqueta: (status: any) => ({
		type: Types.SET_MODAL_ATRIBUIR_ETIQUETA,
		payload: status,
	}),
	set_modal_atribuir_fisica: (status: any) => ({
		type: Types.SET_MODAL_ATRIBUIR_FISICA,
		payload: status,
	}),
	set_open_alterar_senha: (status: any) => ({
		type: Types.SET_OPEN_ALTERAR_SENHA,
		payload: status,
	}),
	set_modal_foto_perfil: (status: any) => ({
		type: Types.SET_MODAL_FOTO_PERFIL,
		payload: status,
	}),
	set_modal_foto_edicao_perfil_assinatura: (status: any, type: string) => ({
		type: Types.SET_MODAL_FOTO_EDICAO_PERFIL_ASSINATURA,
		payload: { status, type },
	}),
	set_open_meu_plano: (status: any) => ({
		type: Types.SET_OPEN_MEU_PLANO,
		payload: status,
	}),
	set_open_pagamentos: (status: any) => ({
		type: Types.SET_OPEN_PAGAMENTOS,
		payload: status,
	}),
	set_open_relatorio: (status: any) => ({
		type: Types.SET_OPEN_RELATORIO,
		payload: status,
	}),
	set_open_faturas: (status: any) => ({
		type: Types.SET_OPEN_FATURAS,
		payload: status,
	}),
	set_open_metodo_pagamento: (status: any) => ({
		type: Types.SET_OPEN_METODO_PAGAMENTO,
		payload: status,
	}),
	set_open_barraSuperior: (status: any) => ({
		type: Types.SET_OPEN_BARRA_SUPERIOR,
		payload: status,
	}),
	set_modal_adicionar_etiqueta: (status: any) => ({
		type: Types.SET_MODAL_ADICIONAR_ETIQUETA,
		payload: status,
	}),
	set_open_visualizar_fatura: (status: any) => ({
		type: Types.SET_OPEN_VISUALIZAR_FATURA,
		payload: status,
	}),
	set_open_adicionar_metodo_pagamento: (status: any) => ({
		type: Types.SET_OPEN_VISUALIZAR_FATURA,
		payload: status,
	}),
	set_protocolo_list: (status: any) => ({
		type: Types.SET_PROTOCOLO_LIST,
		payload: status,
	}),
	set_modal_contagem_exercicio: (status: any) => ({
		type: Types.SET_MODAL_CONTAGEM_EXERCICIO,
		payload: status,
	}),
	set_modal_excluir_protocolo: (status: any) => ({
		type: Types.SET_MODAL_EXCLUIR_PROTOCOLO,
		payload: status,
	}),
	set_contador: (status: any) => ({
		type: Types.SET_CONTADOR,
		payload: status,
	}),
	set_open_configuracoes_exercicios: (status: any) => ({
		type: Types.SET_OPEN_CONFIGURACOES_EXERCICIOS,
		payload: status,
	}),
	set_play_exercicios: (status: any) => ({
		type: Types.SET_PLAY_EXERCICIOS,
		payload: status,
	}),
	set_modal_select_avaliacao: (status: any) => ({
		type: Types.SET_MODAL_SELECT_AVALIACAO,
		payload: status,
	}),
	set_selected_avaliacao: (status: any) => ({
		type: Types.SET_SELECTED_AVALIACAO,
		payload: status,
	}),
	set_selected_type_pro_livre: (status: any) => ({
		type: Types.SET_SELECTED_TYPE_PRO_LIVRE,
		payload: status,
	}),
	set_open_configuracoes_execucao: (status: any) => ({
		type: Types.SET_MODAL_CONFIGURACOES_EXECUCAO,
		payload: status,
	}),
	set_forca_minima: (status: any) => ({
		type: Types.SET_FORCA_MINIMA,
		payload: status,
	}),
	set_forca_minima_str: (status: any) => ({
		type: Types.SET_FORCA_MINIMA_STR,
		payload: status,
	}),
	set_max_force: (status: any) => ({
		type: Types.SET_MAX_FORCE,
		payload: status,
	}),
	set_forca_maxima: (status: any) => ({
		type: Types.SET_FORCA_MAXIMA,
		payload: status,
	}),
	set_forca_maxima_str: (status: any) => ({
		type: Types.SET_FORCA_MAXIMA_STR,
		payload: status,
	}),
	set_ultima_avaliacao: (status: any) => ({
		type: Types.SET_ULTIMA_AVALIACAO,
		payload: status,
	}),
	set_slider_execucao: (status: any) => ({
		type: Types.SET_SLIDER_EXECUCAO,
		payload: status,
	}),
	set_checklist_execucao: (status: any) => ({
		type: Types.SET_CHECKLIST_EXECUCAO,
		payload: status,
	}),
	set_open_duvida_execucao: (status: any) => ({
		type: Types.SET_MODAL_DUVIDA_EXECUCAO,
		payload: status,
	}),
	set_open_duvida_execucao_resultado: (status: any) => ({
		type: Types.SET_MODAL_DUVIDA_EXECUCAO_RESULTADO,
		payload: status,
	}),
	set_resultado_execucao: (status: any) => ({
		type: Types.SET_RESULTADO_EXERCICIO,
		payload: status,
	}),
	set_exercicio: (status: any) => ({
		type: Types.SET_EXERCICIO,
		payload: status,
	}),
	set_modal_adicionar_anotacao: (status: any) => ({
		type: Types.SET_OPEN_ADICIONAR_ANOTACAO,
		payload: status,
	}),
	set_modal_apagar_anotacao: (status: any) => ({
		type: Types.SET_OPEN_APAGAR_ANOTACAO,
		payload: status,
	}),
	set_modal_editar_anotacao: (status: any) => ({
		type: Types.SET_OPEN_EDITAR_ANOTACAO,
		payload: status,
	}),
	set_modal_compartilhamento: (status: any, age: any) => ({
		type: Types.SET_MODAL_COMPARTILHAMENTO,
		payload: { status, age },
	}),
	set_continue_compartilhamento: (status: any) => ({
		type: Types.SET_CONTINUE_COMPARTILHAMENTO,
		payload: status,
	}),

	set_timer_reset: (status: any) => ({
		type: Types.SET_TIMER_RESET,
		payload: status,
	}),
	set_lista_filtro: (status: any) => ({
		type: Types.SET_LISTA_FILTRO,
		payload: status,
	}),
	set_foto_perfil: (status: any) => ({
		type: Types.SET_FOTO_PERFIL,
		payload: status,
	}),
	set_lista_pacientes: (status: any) => ({
		type: Types.SET_LISTA_PACIENTES,
		payload: status,
	}),
	set_lista_excluidos: (status: any) => ({
		type: Types.SET_LISTA_EXCLUIDOS,
		payload: status,
	}),
	set_modal_modo_exercicio: (status: any) => ({
		type: Types.SET_MODAL_MODO_EXERCICIO,
		payload: status,
	}),
	set_lado_exercicio: (status: any) => ({
		type: Types.SET_MODAL_LADO_EXERCICIO,
		payload: status,
	}),
	set_modal_lixeira: (status: any) => ({
		type: Types.SET_MODAL_LIXEIRA,
		payload: status,
	}),
	set_modal_recuperar: (status: any) => ({
		type: Types.SET_MODAL_RECUPERAR,
		payload: status,
	}),
	set_toast_visible: ({ status_toast, type_toast, message_toast }: any) => ({
		type: Types.SET_TOAST,
		payload: { status_toast, type_toast, message_toast },
	}),
	clear_toast_visible: () => ({
		type: Types.CLEAR_TOAST,
	}),
	set_modal_editar_etiqueta: (status: any) => ({
		type: Types.SET_OPEN_EDITAR_ETIQUETA,
		payload: status,
	}),
	set_info_page_editar_etiqueta: (data: any) => ({
		type: Types.SET_INFO_PAGE_EDITAR_ETIQUETA,
		payload: data,
	}),
	set_modal_excluir_etiqueta: (status: any) => ({
		type: Types.SET_OPEN_EXCLUIR_ETIQUETA,
		payload: status,
	}),
	set_modal_baixar_app: (status: any) => ({
		type: Types.SET_OPEN_BAIXAR_APP,
		payload: status,
	}),
	set_modal_tutorial: (status: any, exercicio: Exercicios2Props | ProtoExercicioProps | null) => ({
		type: Types.SET_MODAL_TUTORIAL,
		payload: { status, exercicio },
	}),
	set_exercicio_tutorial: (status: any) => ({
		type: Types.SET_EXERCICIO_TUTORIAL,
		payload: status,
	}),
	set_modal_central_ajuda: (status: any) => ({
		type: Types.SET_OPEN_CENTRAL_AJUDA,
		payload: status,
	}),
	set_number_grafics: (status: any) => ({
		type: Types.SET_NUMBER_GRAFICS,
		payload: status,
	}),
	set_excluir_eva: (status: any) => ({
		type: Types.SET_EXCLUIR_EVA,
		payload: status,
	}),
	set_excluir_anamnese: (status: any) => ({
		type: Types.SET_EXCLUIR_ANAMNESE,
		payload: status,
	}),
	set_modal_migracao: (status: any, execucao: any) => ({
		type: Types.SET_MODAL_MIGRACAO,
		payload: { status, execucao },
	}),
	set_modal_emissao_rel: (status: any) => ({
		type: Types.SET_MODAL_EMISSAO_RELATORIOS,
		payload: status,
	}),
	set_modal_config_forc_min: (status: any) => ({
		type: Types.SET_MODAL_CONFIG_FORC_MIN,
		payload: status,
	}),
	set_modal_shere_anamnese: (status: any, type: any) => ({
		type: Types.SET_MODAL_SHERE_ANAMNESE,
		payload: { status, type },
	}),
	set_title_export_data: (status: boolean) => ({
		type: Types.SET_TITLE_EXPORT_DATA,
		payload: status,
	}),
	set_confirm_close_modal_edit: (status: boolean) => ({
		type: Types.SET_CONFIRM_CLOSE_MODAL_EDIT,
		payload: status,
	}),
	set_modal_inadimplente: (status: any, is_inadimplente?: boolean) => ({
		type: Types.SET_MODAL_INADIMPLENTE,
		payload: { status, is_inadimplente },
	}),
	set_modal_continuar_or_gerar_new_sf_36: (status: boolean) => ({
		type: Types.SET_MODAL_CONTINUAR_OR_GERAR_NEW_SF_36,
		payload: status,
	}),
	set_modal_continue_or_to_generate_new_anamnese: (
		status: boolean,
		type?: string
	) => ({
		type: Types.SET_MODAL_CONTINUE_OR_TO_GENERATE_NEW_ANAMNESE,
		payload: { status, type },
	}),
	set_modal_bloqueio_dino: (status: boolean) => ({
		type: Types.SET_MODAL_BLOQUEIO_DINO,
		payload: status,
	}),
	set_blocked_dinamos: (status: any) => ({
		type: Types.SET_DINAMOS_BLOCKED,
		payload: status,
	}),
	set_modal_satisfacao: (status: boolean, type?: string) => ({
		type: Types.SET_MODAL_SATISFACAO,
		payload: { status, type },
	}),
	set_modal_satisfacao_exame: (
		status: boolean,
		type_modal_satisfacao_exame: string | null
	) => ({
		type: Types.SET_MODAL_SATISFACAO_EXAME,
		payload: { status, type_modal_satisfacao_exame },
	}),
	set_selected_options_page_patients: (selected: string) => ({
		type: Types.SET_SELECTED_OPTIONS_IN_PAGE_PATIENT,
		payload: selected,
	}),
	set_modal_aviso_cadastro_cartao: (status: boolean) => ({
		type: Types.SET_MODAL_AVISO_CADASTRO_CARTAO,
		payload: status,
	}),
	set_is_first_load: (status: boolean) => ({
		type: Types.IS_FIRST_LOAD,
		payload: status,
	}),
	set_Modal_Register_Success: (
		open: boolean,
		title: string,
		subtitle: string
	) => ({
		type: Types.SET_MODAL_REGISTER_SUCCESS,
		payload: { open, title, subtitle },
	}),
	set_option_menu: (openMenu: boolean, option?: string) => ({
		type: Types.SET_OPTION_MENU,
		payload: { openMenu, option },
	}),
	set_modal_alter_senha: (status: boolean) => ({
		type: Types.SET_MODAL_ALTERAR_SENHA,
		payload: status,
	}),
	set_modal_adicionar_assinatura: (status: boolean) => ({
		type: Types.SET_MODAL_ADICIONAR_ASSINATURA,
		payload: status,
	}),
	set_modal_excluir_paciente: (status: boolean) => ({
		type: Types.SET_MODAL_EXCLUIR_PACIENTE,
		payload: status,
	}),
	set_get_patient_in_etiqueta: (status: boolean, patients?: any) => ({
		type: Types.SET_PATIENT_ETIQUETA,
		payload: { status, patients },
	}),
	set_open_modal_excluir_protocolo: (status: boolean) => ({
		type: Types.SET_OPEN_MODAL_EXCLUIR_PROTOCOLO,
		payload: status,
	}),
	set_open_modal_ouro: (status: boolean) => ({
		type: Types.SET_OPEN_MODAL_OURO,
		payload: status,
	}),
	set_open_modal_fim_descanso: (status: boolean) => ({
		type: Types.SET_OPEN_MODAL_FIM_DESCANSO,
		payload: status,
	}),
	set_open_modal_escolha_modo: (status: boolean) => ({
		type: Types.SET_OPEN_MODAL_ESCOLHA_MODO,
		payload: status,
	}),
	set_modal_conecta_hand_grip: (status: any) => ({
		type: Types.SET_MODAL_CONECTA_HAND_GRIP,
		payload: status,
	}),
	set_modal_conecta_hand_grip_passos: (status: any) => ({
		type: Types.SET_MODAL_CONECTA_HAND_GRIP_PASSOS,
		payload: status,
	}),
	set_modal_select_dominant_side: (status: any) => ({
		type: Types.SET_MODAL_SELECT_DOMINANT_SIDE,
		payload: status,
	}),	
};
