import React from "react";
import styled from "styled-components";
import Perfil from "../../../assets/icons/perfil.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { AmplitudeLog } from "../../../utils/amplitude";
import moment from "moment";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { getPermission } from "../../../utils/funcs";
import { ServicosProps } from "../../../interfaces/config.interface";
import { TypesPermission } from "../../../utils/types";
import { ConfigsActions } from "../../../store/ducks/configs";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import MenuRadial from "../../../assets/icons/menu_radial.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AnamneseActions } from "../../../store/ducks/anamnese";

const UltimosAcessados: React.FC = () => {
	const dispatch = useDispatch();

	const config_lista_usuarios = useSelector(
		(state: any) => state.dashboard.pacientes_mais_visitados
	);

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const handlePaciente = (paciente: any) => {
		const obj = {
			name: paciente?.USUARIO_NOME,
			value: "paciente",
		};
		const payload = {
			ID_USUARIO: paciente?.ID_USUARIO,
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
		};
		dispatch(AppActions.set_open_paciente(paciente));
		dispatch(PacAvalActions.set_perfil_anamnese_patient(paciente?.ID_USUARIO));
		dispatch(AnamneseActions.set_relatorio_qualidade_vida_history(null));
		dispatch(AppActions.set_open_barraSuperior(obj));
		dispatch(AppActions.set_confirm_close_modal_edit(true));
		dispatch(AddPacAvalActions.set_recorrente(payload));
		AmplitudeLog("paci_mais_visitados", {
			body: {
				paciente: payload,
			},
		});
		const permissionAssessmentPhysical: any = getPermission(
			config_servicos as ServicosProps[],
			TypesPermission.SERVICO_ANAMNESE_FISICA
		);
		if (!permissionAssessmentPhysical) {
			dispatch(ConfigsActions.set_close_modal_upgrade_and_goback(true));
			dispatch(
				ConfigsActions.set_physical_anamnesis_verification_by_the_most_visited_patients(
					permissionAssessmentPhysical
				)
			);
		}
	};

	const getPatientCarrossel = (paciente: PacientesProps) => {
		const filtros_paginacao = {
			inicio: 0,
			fim: 1,
			filtros: [
				{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
				{ tipo: "USUARIO_NIVEL", valor: [3] },
				{ tipo: "ID_USUARIO", valor: [paciente?.ID_USUARIO] },
			],
		};

		dispatch(DashBoardActions.set_filtros_pacintes_request(filtros_paginacao));
		dispatch(DashBoardActions.paciente_request());
		dispatch(AppActions.set_open_paciente(paciente));
		dispatch(DashBoardActions.set_current_page("perfil_paciente"));
	};

	const subarray = config_lista_usuarios?.LISTA?.slice(0, 9);

	return (
		<Container>
			<Titles>{current_language?.pacientes_mais_acessados}</Titles>
			<DivCarrosel>
				{subarray?.length > 0 ? (
					subarray?.map((e: any, i: any) => (
						<CarroselPacientes key={i} data-value={i}>
							<div
						
								onClick={() => {
									handlePaciente(e), getPatientCarrossel(e);
								}}
								style={{
									textDecoration: "none",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
								}}
							>
								{e?.USUARIO_FOTO != "" &&
								e?.USUARIO_FOTO != null &&
								e?.USUARIO_FOTO != "img/photo.png" ? (
									<CarrosselFoto draggable="false" src={e?.USUARIO_FOTO} />
								) : (
									<CarrosselFoto draggable="false" src={Perfil} />
								)}

								<CarrosselNome draggable="false">
									{e?.USUARIO_NOME.substring(0, 15)}
									{e?.USUARIO_NOME.length > 15 && "..."}
								</CarrosselNome>
							</div>
						</CarroselPacientes>
					))
				) : (
					<CarroselPacientes>
						<div
							onClick={() => {
								//
							}}
							style={{
								display: "flex",
								justifyContent: "flex-start",
								flexDirection: "column",
							}}
						>
							<CarrosselFoto draggable="false" src={MenuRadial} />
							<CarrosselNome style={{textAlign: "start"}} draggable="false">
								Adicionar <br />
								paciente
							</CarrosselNome>
						</div>
					</CarroselPacientes>
				)}
			</DivCarrosel>
		</Container>
	);
};

const Container = styled.div`
	width: 60%;
`;

const Titles = styled.text`
	font-family: "NotoSans-Medium";
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 48px;
	color: #000e4b;
`;

const DivCarrosel = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: "space-between";
	margin-top: 15px;
`;

const CarroselPacientes = styled.div`
	text-decoration: none;
	cursor: pointer;
	transition: all 0.2s linear;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const CarrosselNome = styled.div`
	font-size: 0.75rem;
	font-weight: 500;
	color: #404040;
	text-align: center;
	margin-top: 0.5rem;
`;

const CarrosselFoto = styled.img`
	object-fit: cover;
	width: 2.7rem;
	height: 2.7rem;
	background-color: #ededed;
	border: 0;
	border-radius: 100%;
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
`;

export default UltimosAcessados;
