export const Types = {
	SET_CATEGORIA: "SET_CATEGORIA",
	SET_HELP: "SET_HELP",
	CENTRAL_AJUDA_ENVIAR: "CENTRAL_AJUDA_ENVIAR",
	CENTRAL_AJUDA_ENVIAR_FINALLY: "CENTRAL_AJUDA_ENVIAR_FINALLY",
};

const INITIAL_STATE = {
	categoria: 0,
	help: 0,
	loading: false,
};

export default function central(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.SET_CATEGORIA: {
			return {
				...state,
				categoria: action.payload,
			};
		}
		case Types.SET_HELP: {
			return {
				...state,
				help: action.payload,
			};
		}
		case Types.CENTRAL_AJUDA_ENVIAR:
			return {
				...state,
				loading: true,
			};
		case Types.CENTRAL_AJUDA_ENVIAR_FINALLY:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}

export const CentralAjuda = {
	set_categoria: (status: any) => ({
		type: Types.SET_CATEGORIA,
		payload: status,
	}),
	set_help: (status: any) => ({
		type: Types.SET_HELP,
		payload: status,
	}),
	central_ajuda_enviar: (data: any) => ({
		type: Types.CENTRAL_AJUDA_ENVIAR,
		payload: data,
	}),
	central_ajuda_enviar_finally: () => ({
		type: Types.CENTRAL_AJUDA_ENVIAR_FINALLY,
	}),
};
