import styled from "styled-components";
import SetFotoHover from '../../assets/kinologyIcons/svg/HoverSetFoto.svg';

export const Container = styled.div`
	width: 100%;
	display: flex;
	height: 174px;
	padding: 12px 24px;
	align-items: center;
	justify-content: center;
	border: solid;
	border-width: 2px;
	border-radius: 20px;
	background-color: #E8E9EE13;

	.labelForInput {
		display: flex;
		align-items: center;
		height: 72px; 
		flex-direction: column;
		cursor: pointer;
	}
	

	.conteinerImg {
		background-color: #e5e5e5;
		width: 125px;
		height: 125px;
		border-radius: 50%;
		padding: 7px;
	}

	.imgForInput {
		width: 72px;
		height: 72px;
		border-radius: 50%;
		object-fit: cover;
	}

	.camera {
		display: flex;
		margin-left: 75px;
		width: 45px;
		height: 45px;
		margin-top: -40px;
	}
`;
