import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SetFoto from '../../assets/kinologyIcons/svg/setFoto.svg';
import HoverPhoto from '../../assets/kinologyIcons/svg/hoverSetFoto.svg';
import PressSetPhoto from '../../assets/kinologyIcons/svg/pressSetFoto.svg';
import SucessSetPhoto from '../../assets/kinologyIcons/svg/SetFotoSucesso.svg';

import { Container } from "./styles";
import Text from "../Texts/Text";
import { Colors } from "../../styles/colors";

import { AddPacAvalActions } from "../../store/ducks/add_pac_aval";
import { TextsProps } from "../../database/txt.interface";

const FotoPerfil: React.FC = () => {
	const dispatch = useDispatch();

	const [img, setImg] = useState<any>();
	const [image, setImage]= useState<string>(SetFoto)
	const [imageName, setImageName]= useState<string>('')
	
	const current_language: TextsProps = useSelector((state: any) => state.configs.current_language);

	useEffect(() => {
		dispatch(AddPacAvalActions.set_photo(img));
	}, [img]);

	function getBase64(event: any) {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			setImg(reader.result);
		};
		reader.onerror = function (error) {
			console.error("Error: ", error);
		};
	}

	type IconProps =  {
			SetPhoto: string;
			HoverPhoto:  string;
			PressSetPhoto: string;
			SucessSetPhoto: string
			
	}

	const handleImageProfile = (imageName:string)=> {
		const typeImages: any = {
			SetPhoto: SetFoto,
			HoverPhoto: HoverPhoto,
			PressSetPhoto: PressSetPhoto,
			SucessSetPhoto: SucessSetPhoto
		};
		setImage(typeImages[imageName])
		setImageName(imageName)
		
	};

	return (
		<Container 
			className="DivInput"
			style={{
				borderColor: imageName == 'HoverPhoto' 
				? Colors.primary_blue_k_medium_1  
				: imageName == 'PressSetPhoto'
					? Colors.primary_blue_k_main
					: img
						? Colors.secondary_green_k_medium_1
						: Colors.neutral_grey_k_10
			}}
		>
			<input
				id="img"
				type="file"
				onChange={getBase64 }
				style={{ display: "none" }}
				accept="image/*"
			/>
			<label 
				htmlFor="img" 
				className="labelForInput"
				onMouseEnter={()=>handleImageProfile('HoverPhoto')}
				onMouseLeave={()=>handleImageProfile('SetPhoto')}
				onMouseDown={()=> handleImageProfile('PressSetPhoto')}
			>	
				<img src={img ? SucessSetPhoto : image}  className="imgForInput" />
				<Text
					title={img ?  current_language?.foto_inserida : current_language?.Insira_foto}
					fontFamily="NotoSans-Regular" 
					fontSize={2}
					textColor={Colors.primary_blue_k_main}
					marginTop={8}
				/>
			</label>
		</Container>
	);
};

export default FotoPerfil;
