import React, { memo, useEffect, useState } from "react";
import { AreaRadioButtons, Radio, RadioDiv } from "./styles";
import { QuestionnaireProps } from '../../../../../interfaces/pacientes.interface';
import { useDispatch, useSelector } from 'react-redux';
import { PacAvalActions } from '../../../../../store/ducks/manage_pac_aval';
import tipoCorpos from '../../../../../assets/tipoCorpos.png';
import DOMParserReact from 'dom-parser-react';

interface RadioButtonProps {
	item: QuestionnaireProps,
}

const RadioButtonTipoCorpo: React.FC<RadioButtonProps> = ({ item }) => {
	const dispatch = useDispatch();
	const array_respostas = useSelector(
		(state: any) => state.pac_aval.array_respostas
	);
	const array_resposta = useSelector(
		(state: any) => state.pac_aval.array_resposta
	);

	const handleRadioButton = (item: QuestionnaireProps, tipo: string) => {
		if (tipo != "") {
			const dataObj = {
				"QUESTIONARIO_LABEL": `${item.QUESTIONARIO_LABEL}`,
				"QUESTIONARIO_RESPOSTA": [
					tipo
				]
			}
			const new_array = array_respostas.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
			const new_array_resposta = array_resposta.filter((e: any) => e.QUESTIONARIO_LABEL !== item.QUESTIONARIO_LABEL);
			dispatch(PacAvalActions.set_array_respostas([...new_array, dataObj]));
			dispatch(PacAvalActions.set_array_resposta([...new_array_resposta, dataObj]));
		}
	}

	function render_radio_button(tipo: string, id: string) {
		const check = array_respostas?.find((e: any) => e?.QUESTIONARIO_LABEL === item?.QUESTIONARIO_LABEL)?.QUESTIONARIO_RESPOSTA

		return (
			<Radio key={`checkbox${item?.QUESTIONARIO_ORDEM}${id}`}>
				<div className="custom-checkbox">
					<input
						id={`checkbox${item?.QUESTIONARIO_ORDEM}${id}`}
						checked={check != undefined && id == check[0]}
						type="checkbox"
						style={{ display: "none" }}
						onChange={() => handleRadioButton(item, id)}
					/>
					<label
						className={"label2"}
						htmlFor={`checkbox${item?.QUESTIONARIO_ORDEM}${id}`}
					></label>
				</div>
				<label htmlFor={`checkbox${item?.QUESTIONARIO_ORDEM}${id}`}>
					<DOMParserReact source={tipo} />
				</label>
			</Radio>
		)
	}

	return (
		<AreaRadioButtons>
			<img src={tipoCorpos} />
			<RadioDiv>
				{
					item.QUESTIONARIO_PERGUNTA_OPCOES.map((e: string) => {
						return render_radio_button((parseInt(e) + 1).toString(), e)
					})
				}
			</RadioDiv>
		</AreaRadioButtons>
	);
};

export default memo(RadioButtonTipoCorpo);