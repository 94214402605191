import React, { memo, useState } from "react";
import { Container, Niveis, Slider } from './styles';
import emojiVerdeSlider from '../../../../../assets/icons/emojiVerdeSlider.svg'
import emojiLimaoSlider from '../../../../../assets/icons/emojiLimaoSlider.svg'
import emojiAmareloSlider from '../../../../../assets/icons/emojiAmareloSlider.svg'
import emojiMostardaSlider from '../../../../../assets/icons/emojiMostardaSlider.svg'
import emojiLaranjaSlider from '../../../../../assets/icons/emojiLaranjaSlider.svg'
import emojiVermelhoSlider from '../../../../../assets/icons/emojiVermelhoSlider.svg'

interface Musculo {
	MUSCULO: number,
	LADO: "E" | "D" | "S",
	FUNCAO: (obj: FuncaoMusculo) => void,
	VALOR?: number
}

export interface FuncaoMusculo {
	MUSCULO: number,
	LADO: "E" | "D" | "S",
	VALOR: number
}

const SliderCorpo: React.FC<Musculo> = ({ MUSCULO, LADO, FUNCAO, VALOR = 1 }) => {
	const colors = [
		'#4CC71F',
		'#66C722',
		'#7EC90D',
		'#BFCB06',
		'#FFCC01',
		'#FFB200',
		'#FE9900',
		'#FC8108',
		'#FB6713',
		'#F94A1A',
	]

	const [obj, setObj]: any = useState(
		{
			"MUSCULO": MUSCULO,
			"LADO": LADO,
			"VALOR": VALOR
		}
	)

	const [value, setValue] = useState(VALOR)
	const handleSlider = (e: any) => {
		setValue(parseInt(e.target.value))
		setObj({
			"MUSCULO": MUSCULO,
			"LADO": LADO,
			"VALOR": parseInt(e.target.value)
		})
	}

	const setSlider = () => {
		FUNCAO(obj);
	}

	return (
		<Container>
			<Slider 
				value={value} 
				onMouseUp={() => setSlider()} 
				onChange={(e) => handleSlider(e)} 
				type="range" 
				min="1" 
				max="10" 
				step="1" 
			/>
			
			<Niveis>

				{
					colors.map((e: string, i: number) => {
						return (
							<div key={i}>
								<div className='nivel' style={{ backgroundColor: e }}></div>
							</div>
						)
					})
				}
				<div className='imgs'>
					<img src={emojiVerdeSlider} />
					<img src={emojiLimaoSlider} />
					<img src={emojiAmareloSlider} />
					<img src={emojiMostardaSlider} />
					<img src={emojiLaranjaSlider} />
					<img src={emojiVermelhoSlider} />
				</div>

				<label style={{ left: `calc(${value} * 10%)` }}>{value}</label>
			</Niveis>
		</Container>
	);
};

export default memo(SliderCorpo);