export const Colors = {
	primary_blue_k_main:'#000E4B',
	primary_blue_k_main_opacity:'#000E4B40', // Não tem na palleta de cores
	primary_blue_k_dark_2: '#001574',
	primary_blue_k_medium_1: '#0023BD',
	primary_blue_k_medium_2: '#0937FF',
	primary_blue_k_light_1: '#4585FF',
	blue_k_light_2: '#6B9DFF',
	blue_k_light_3: '#BAD2FF',
	blue_k_light_4: '#D1E0FF',
	secondary_button_default: '#009973',
	secondary_buttoon_green: '#007168',
	secondary_green_k_dark_1: '#005760',
	secondary_green_k_dark_2: '#007C6B',
	secondary_green_k_medium_1: '#00A377',
	secondary_green_k_main: '#00D084',
	secondary_green_k_light_1: '#5FDFAC',
	secondary_green_k_light_2: '#CEF6E7',
	tertiary_blue_1_dark: '#0094D1',
	tertiary_blue_1_light: '#00D1CB',
	tertiary_green_1_dark: '#0CD100',
	tertiary_green_1_light: '#80F279',
	tertiary_yellow_1_dark: '#FFC530',
	tertiary_yellow_1_light: '#FFD580',
	tertiary_orange_1_dark: '#E57E00',
	tertiary_orange_1_light: '#E59D45',
	tertirary_red_1_dark: '#D12600',
	tertirary_red_2_dark: '#A81F00',
	tertirary_red_1_light: '#E58873',
	default_gold_dark: '#99731F',
	default_gold_medium: '#D19400',
	default_gold_light: '#FFB000',
	neutral_grey_k_90: '#141622',
	neutral_grey_k_80: '#2A3048',
	neutral_grey_k_70: '#3D4259',
	neutral_grey_k_60: '#63677A',
	neutral_grey_k_50: '#797D8D',
	neutral_grey_k_40: '#9497A4',
	neutral_grey_k_30: '#ABADB7',
	neutral_grey_k_20: '#C4C5CC',
	neutral_grey_k_10: '#DEDFE3',
	neutral_grey_k_5: '#FAFAF5',

	secondary_main_darker: '#000727',
	secondary_main_hover: '#001C9B',
	neutral_light_gray_3: '#AEA9B4',
	neutral_very_light: '#FAFAFA',
	
	
	primary: '#000E4B',
	primary2: '#A21C53',
	white: '#FFF',
	bottom: '#F0F0F0',

	whiter: '#FFF',
	white2: '#FCFCFC',

	black: '#030303',
	black2: '#454545',
	black3: '#323232',
	black4: '#999898',
	black5: 'rgba(21, 21,21,0.6)',
	black6: '#666666',
	black7: '#404040',

	whiter_6: 'rgba(255,253,253,0.65)',
	whiter_7: '#D1D1D1',

	lighter: '#FFFDFD',

	icon_gray: '#B0B0B0',
	background_image: '#DED8D8',

	silver: '#EBEBEB',
	silver_2: '#E5E5E5',
	silver_3: '#eaebeb',
	silver_4: '#EAEBEB',
	silver_5: 'rgba(209,209,209,.8)',
	silver_6: '#F2F2F2',
	silver_7: '#D9D9D9',

	gray_0: '#696868',
	gray: '#b3b3b3',
	gray_secondary: '#7F7F7F',
	gray_5: '#C4C4C4',
	gray_6: '#DED8D8',
	gray_7: '#8D8D8D',
	gray_8: 'rgba(141,141,141, 0.7)',
	gray_9: '#B3B3B3',
	gray_10: '#7F7F7F',
	gray_11: '#9B9A9A',
	gray_12: '#4D4D4D',
	gray_disable: '#BFBFBF',

	background_gray: 'rgba(92,91,91, 0.5)',

	danger: '#FF0000',
	danger2: '#E50000',
	danger3: '#D13E3E',
	danger4: '#D9534F',

	success: '#39E664',
	info: '#001574',
	info2: '#2972fe',

	green: '#2BD449',
	yellow: '#EBB909',
	alert: '#FFC530',

	darker: '#5C5B5B',

};