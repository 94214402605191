import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 33.6rem;
	width: 45.1rem;
	border-radius: 30px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	padding: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;

	img {
		width: 6.25rem;
		height: 6.25rem;
	}

	.title {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 25px;
		line-height: 120%;
		/* or 26px */
		color: #323232;
		margin-top: 1rem;
	}

	.subtitle {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 300;
		font-size: 25px;
		line-height: 150%;
		color: #404040;
		margin-top: 1rem;
	}

	.zap_group {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 10px;
		.zap_text {
			text-decoration: underline;
			color: blue;
		}
		.zap_icon {
			width: 30px;
			height: 30px;
		}
	}

	.buttom {
		margin-top: 30px;
		font-family: Roboto;
		font-size: 1.3rem;
		line-height: 1.758rem;
		background: ${Colors.primary_blue_k_main};
		color: #ffffff;
		width: 8.5rem;
		height: 2.5rem;
		border: 0;
		border-radius: 0.5rem;
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
	}
	.buttomLogout {
		margin-top: 30px;
		font-family: Roboto;
		font-size: 1.3rem;
		line-height: 1.758rem;
		background-color:  #ffffff;
		color: ${Colors.primary_blue_k_main};
		border-color: ${Colors.primary_blue_k_main};
		border: solid 1px;
		width: 8.5rem;
		height: 2.5rem;
		border-radius: 0.5rem;
	}
`;
