import styled from "styled-components";

// Principal
export const Container = styled.div`
	background-color: #fafafa;
	height: 100vh;
	color: #8c8e86;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	overflow-x: hidden;

	button {
		width: 10rem;
		margin-top: 0.5rem;
	}
`;
