import React from "react";
import ReactDOM from "react-dom";
import Rollbar from "rollbar";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


import App from "./App";
import GlobalStyle from "./styles/global";

const firebaseConfig = {
  apiKey: "AIzaSyBdqL2Z1y6VgvB1WNJf2qArGizjzFeLg60",
  authDomain: "elastic-web-f7558.firebaseapp.com",
  databaseURL: "https://elastic-web-f7558-default-rtdb.firebaseio.com",
  projectId: "elastic-web-f7558",
  storageBucket: "elastic-web-f7558.appspot.com",
  messagingSenderId: "603585167044",
  appId: "1:603585167044:web:7dcd9e91cb7a78ce775391",
  measurementId: "G-02NYSL26E0"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const analytics = getAnalytics(app);
Sentry.init({
	dsn: "https://72fea83867474ae0ada69ce3a53616f5@o491381.ingest.sentry.io/4504008031338496",
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
});

const url_atual = window.location.href;
let environment = "";
url_atual.includes("https://web.elastic.fit/")
	? (environment = "production")
	: (environment = "development");

new Rollbar({
	accessToken: "73824dc29e7b41bd920570e2cf590413",
	environment,
	captureUncaught: true,
	captureUnhandledRejections: true,
});

ReactDOM.render(
	<React.StrictMode>
		<>
			<App />
			<GlobalStyle />
		</>
	</React.StrictMode>,
	document.getElementById("root")
);
