import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import email from "../../../assets/icons/email.svg";
import {
	PacientesProps,
	UserProps,
} from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import { getTimeExecFunction, getTimeout } from "../../../utils/funcs";
import { TypesErrorRequest } from "../../../utils/types";
import ToastAlert from "../../toast";
import { ContainerEmail } from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import { TextsProps } from "../../../database/txt.interface";

const EscolhaEmail: React.FC = () => {
	const dispatch = useDispatch();
	const language: any = useSelector((state: any) => state.configs.language);

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const exercicios_compartilhar = useSelector(
		(state: any) => state.relatorios.exercicios_compartilhar
	);

	const data_compartilhar = useSelector(
		(state: any) => state.relatorios.data_compartilhar
	);

	const is_open_paciente = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const seletec_hemisferios = useSelector(
		(state: any) => state.manage_protocol.seletec_hemisferios
	);

	const is_relatorio_evolucao = useSelector(
		(state: any) => state.relatorios.is_relatorio_evolucao
	);
	const is_relatorio_assimetria = useSelector(
		(state: any) => state.relatorios.is_relatorio_assimetria
	);
	const is_relatorio_forca_tempo = useSelector(
		(state: any) => state.relatorios.is_relatorio_forca_tempo
	);
	const is_relatorio_multifatorial = useSelector(
		(state: any) => state.relatorios.is_relatorio_multifatorial
	);
	const is_relatorio_fisioterapeutico = useSelector(
		(state: any) => state.relatorios.is_relatorio_fisioterapeutico
	);

	const selected_type_av = useSelector(
		(state: any) => state.relatorios.selected_type_av
	);
	const selected_type_qv = useSelector(
		(state: any) => state.relatorios.selected_type_qv
	);
	const selected_type_fisio = useSelector(
		(state: any) => state.relatorios.selected_type_fisio
	);
	const selected_type_mult = useSelector(
		(state: any) => state.relatorios.selected_type_mult
	);

	const [bloquAgosto, setBloqAgosto] = useState(false);
	const Type_envio = {
		EMAIL: "EMAIL",
		DOWNLOAD: "DOWNLOAD",
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();
	const [aprovadoEmail, setAprovadoEmail] = useState(false);
	const [aprovadoDownload, setAprovadoDownload] = useState(false);
	const [dataEmissao, setDataEmissao] = useState<any>([]);

	useEffect(() => {
		setDataEmissao([
			{
				id: 1,
				name: "evolucao",
				build: is_relatorio_evolucao,
			},
			{
				id: 2,
				name: "assimetria",
				build: is_relatorio_assimetria,
			},
			{
				id: 3,
				name: "forca_tempo",
				build: is_relatorio_forca_tempo,
			},
			{
				id: 4,
				name: "multifatorial",
				build: is_relatorio_multifatorial,
			},
			{
				id: 5,
				name: "fisioterapeutico",
				build: is_relatorio_fisioterapeutico,
			},
		]);
	}, [
		is_relatorio_assimetria,
		is_relatorio_evolucao,
		is_relatorio_forca_tempo,
		is_relatorio_multifatorial,
		is_relatorio_fisioterapeutico,
	]);

	const onSubmit = async (data: any) => {
		AmplitudeLog('compartilhamento_relat_via_email')
		const TimeInitial = new Date().getTime();
		setAprovadoEmail(true);
		exportarRelatorio(Type_envio.EMAIL, data.email);

		const TimeFinal = new Date().getTime();

		getTimeExecFunction(
			TimeInitial,
			TimeFinal,
			"Function_OnSubmit",
			user_logado?.ID_USUARIO
		);
		getTimeout(
			TimeInitial,
			TimeFinal,
			"TIMEOUT_Function_OnSubmit",
			user_logado?.ID_USUARIO
		);
	};

	const exportarRelatorio = (tipo: string, email?: string) => {
		if (tipo === Type_envio.DOWNLOAD) {
			setAprovadoDownload(true);
		} else if (tipo === Type_envio.EMAIL) {
			setAprovadoEmail(true);
		}
		let authData: any;
		dataEmissao.map((item: any) => {
			if (item.build) {
				authData = !email
					? {
							txDataHora: moment(new Date())
								.format("YYYY-MM-DD HH:mm:ss")
								.toString(),
							ID_USUARIO: is_open_paciente?.ID_USUARIO,
							txIdioma: language?.toLowerCase(),
							txTipo: item.name,
							txOutput: "download",
					  }
					: {
							txDataHora: moment(new Date())
								.format("YYYY-MM-DD HH:mm:ss")
								.toString(),
							ID_USUARIO: is_open_paciente?.ID_USUARIO,
							txIdioma: language?.toLowerCase(),
							txTipo: item.name,
							txOutput: "email",
							txEmail: email,
					  };
				if (
					item.name === "evolucao" ||
					item.name === "assimetria" ||
					item.name === "forca_tempo"
				) {
					authData = {
						...authData,
						txPeriodo: data_compartilhar,
						txExercicios: exercicios_compartilhar,
					};
				} else {
					if (item.name === "fisioterapeutico") {
						setBloqAgosto(true);
						authData = {
							...authData,
							txQuestionarios: {
								ID_SF_36: selected_type_qv,
								ID_ANAMNESE_AVALIACAO: selected_type_av,
								ID_ANAMNESE_TIPO: selected_type_fisio,
							},
						};
					}
					if (item.name === "multifatorial") {
						setBloqAgosto(true);
						authData = {
							...authData,
							txQuestionarios: {
								ID_SF_36: selected_type_qv,
								ID_ANAMNESE_AVALIACAO: selected_type_av,
								ID_ANAMNESE_TIPO: selected_type_mult,
							},
						};
					}
				}
				if (authData) {
					AmplitudeLog('compartilhamento_relat_via_download')
					dispatch(RelatorioActions.set_atribuir_tags(authData));
				}
			}
		});
	};

	const handleListExerciciosVoltar = () => {
		if (is_relatorio_multifatorial || is_relatorio_fisioterapeutico) {
			dispatch(AppActions.set_continue_compartilhamento("EscolhaAnamnese"));
		} else {
			dispatch(AppActions.set_continue_compartilhamento("ExercicioList"));
		}
	};

	const arrayDatas = data_compartilhar.split("/");

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const type_toast = useSelector((state: any) => state.app.type_toast);

	const continue_compartilhamento = useSelector(
		(state: any) => state.app.continue_compartilhamento
	);

	useEffect(() => {
		if (is_open_toast) {
			let message = "";
			if (aprovadoEmail) {
				message = current_language?.modal_compartilhamento_sucesso;
			} else if (aprovadoDownload) {
				message = current_language?.download_compartilhar;
			}
			if (type_toast == TypesErrorRequest.SUCCESS) {
				dispatch(
					RelatorioActions.set_message_compartilhar(message)
				);
				dispatch(AppActions.set_continue_compartilhamento("Sucesso"));
			}
			setAprovadoDownload(false);
			setAprovadoEmail(false);
		}
	}, [is_open_toast]);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	useEffect(() => {
		setValue(
			"email",
			paciente.USUARIO_EMAIL
				? paciente.USUARIO_EMAIL
				: user_logado.USUARIO_EMAIL
		);
	}, [continue_compartilhamento]);

	return (
		<ContainerEmail>
			{is_open_toast && type_toast == TypesErrorRequest.FAIL ? (
				<ToastAlert />
			) : (
				""
			)}

			<div className="divText">
				<p className="text">
					Compartilhar Relatórios
					{(is_relatorio_evolucao ||
						is_relatorio_assimetria ||
						is_relatorio_forca_tempo) &&
						` - ${seletec_hemisferios.length} ${current_language?.exercicios}`}
				</p>
			</div>
			<span className="subText">
				{current_language?.idioma === "Português"
					? `${arrayDatas[0]?.substr(0, 10).split("-").reverse().join("/")}`
					: current_language?.idioma === "English"
					? `${arrayDatas[0]
							?.substr(0, 5)
							.split("/")
							.reverse()
							.join("/")}/${arrayDatas[0]?.substr(6, 10)}`
					: `${arrayDatas[0]?.substr(0, 10).split("/").join("-")}`}{" "}
				-{" "}
				{current_language?.idioma === "Português"
					? `${arrayDatas[1]?.substr(0, 10).split("-").reverse().join("/")}`
					: current_language?.idioma === "English"
					? `${arrayDatas[1]
							?.substr(0, 5)
							.split("/")
							.reverse()
							.join("/")}/${arrayDatas[1]?.substr(6, 10)}`
					: `${arrayDatas[1]?.substr(0, 10).split("/").join("-")}`}
			</span>

			<form className="form" action="" onSubmit={handleSubmit(onSubmit)}>
				<p>{current_language?.modal_compartilhamento}</p>
				<div className="globalInput">
					<img src={email} alt="email" className="icon" />
					<input
						className="input"
						type="text"
						id="email"
						placeholder={current_language?.login_email}
						{...register("email", {
							// required: `${current_language?.login_input_senha}`,
							pattern: {
								value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: `${current_language?.email_invalido}`,
							},
						})}
					/>
				</div>
				<div className="divhr">
					<hr className={errors.email ? "hr hrcor" : "hr"} />
					{errors.email && <div className="error">{errors.email.message}</div>}
				</div>
				<button 
					className="enviar" 
				>
					{aprovadoEmail == true ? (
						<CircularProgress color="inherit" />
					) : (
						current_language?.enviar
					)}
				</button>
			</form>

			<p className="ou">{current_language?.modal_compartilhamento_ou}</p>

			<button
				className="baixar"
				onClick={() => exportarRelatorio(Type_envio.DOWNLOAD)}
			>
				{!aprovadoDownload && (
					<svg
						width="17"
						height="16"
						viewBox="0 0 17 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M14.5 11V14H2.5V11H0.5V14C0.5 15.1 1.4 16 2.5 16H14.5C15.6 16 16.5 15.1 16.5 14V11H14.5ZM13.5 7L12.09 5.59L9.5 8.17V0H7.5V8.17L4.91 5.59L3.5 7L8.5 12L13.5 7Z"
							fill="#000E4B"
						/>
					</svg>
				)}
				{aprovadoDownload ? (
					<CircularProgress color="inherit" />
				) : (
					current_language?.modal_compartilhamento_baixar
				)}
			</button>
			<button 
				className="voltar" 
				onClick={()=>{ 
					handleListExerciciosVoltar() 
					AmplitudeLog('voltar_selecionar_opc_anamnese')
				}}
			>
				{current_language?.voltar}
			</button>
		</ContainerEmail>
	);
};

export default memo(EscolhaEmail);
