import { useSelector } from "react-redux";

import AppStore from "../../assets/appStore.png";
import googlePlay from "../../assets/googlePlay.png";
import { TextsProps } from "../../database/txt.interface";
import { Container, Content } from "./styles";

const BaixarApp: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	return (
		<Container>
			<Content>
				<svg
					width="150"
					height="111"
					viewBox="0 0 150 111"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M105.465 32.9205C104.592 29.3115 103.237 28.3406 99.4138 28.3406C96.1655 28.3223 92.9173 28.3223 89.6506 28.3223H66.7274C63.1822 28.3223 59.6556 28.3223 56.0918 28.3406C54.2914 28.3223 52.4352 28.3406 50.6348 28.3406C46.7926 28.3406 45.4748 29.3115 44.621 32.9205C43.4702 37.6104 42.3379 41.311 41.2985 46.0559C40.9644 47.5581 41.8182 50.1412 43.3588 50.1412C47.6836 50.1229 51.8784 50.1229 56.0733 50.1229C62.2727 50.1229 68.3979 50.1412 74.8016 50.1412H75.2285C80.1472 50.1412 84.8989 50.1412 89.6691 50.1229C95.2746 50.1229 100.88 50.1046 106.708 50.1412C108.23 50.1412 109.121 47.5581 108.787 46.0559C107.748 41.311 106.578 37.6104 105.465 32.9205Z"
						fill="#000E4B"
					/>
					<path
						d="M149.604 27.5347V50.4712H142.587C137.706 50.4529 133.437 50.4529 127.701 50.4712C124.713 50.4895 123.469 48.4194 123.117 46.8805C121.39 39.2228 120.425 34.936 117.957 28.4141C116.342 24.0723 113.298 21.0678 108.695 19.7122C106.282 19.016 104.24 18.5947 101.307 18.5763C84.1195 18.4664 66.7276 18.4664 49.4471 18.558C47.5353 18.558 45.6421 18.6863 43.7674 19.016C38.0877 19.987 33.1689 22.3136 31.3499 28.121C28.8442 35.9619 26.8024 43.986 26.357 52.2482C25.8001 62.8554 28.1945 73.0596 31.3128 83.1355C32.5193 87.0559 35.2849 89.5474 39.3127 90.8115C43.1177 92.0206 47.0156 92.4603 50.9877 92.4603C68.064 92.4603 85.1589 92.4603 102.235 92.442C105.298 92.442 108.323 91.8924 111.219 90.8482C114.95 89.4925 117.047 87.1292 118.588 83.1355C119.609 80.4242 120.407 77.8411 121.038 74.8183C121.372 73.2794 122.022 72.51 120.555 72.5283C117.233 72.5466 113.576 72.4367 110.05 72.6199C107.841 72.7298 107.228 74.1588 107.228 74.1588C103.98 80.7356 103.906 82.7141 100.212 82.7691H90.5788C76.0825 82.7691 68.3982 82.7508 53.8647 82.7691C52.2684 82.7691 51.5445 82.8057 48.7975 82.7691C46.997 82.7325 46.0875 82.2195 45.2894 80.4425C43.9901 75.148 42.6352 71.7405 41.7628 66.3545C41.4844 64.5775 42.0969 62.2692 44.0458 62.2692C105.168 62.2142 147.228 62.2692 149.492 62.2692L149.474 62.2875H149.622V83.4836C149.622 98.6707 137.205 111 121.892 111H27.7305C12.4175 111 0 98.6707 0 83.4836V27.5347C0 12.3293 12.4175 0 27.7305 0H121.873C137.186 0 149.604 12.3293 149.604 27.5347Z"
						fill="#000E4B"
					/>
				</svg>

				<p>{current_language?.baixe_app}</p>

				<div>
					<img src={AppStore} alt="" />
					<img src={googlePlay} alt="" />
				</div>
			</Content>
		</Container>
	);
};

export default BaixarApp;
