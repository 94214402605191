import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { ExecucaoProps } from "../../../interfaces/execucao.interface";
import { ExerGrupoHemiPros } from "../../../interfaces/exercicio_protocolo";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { TypesDivision } from "../../../utils/types";

import LogoKinology from '../../../assets/kinologyIcons/svg/logo.svg'
interface RenderItemGroupProps {
	row: Exercicios2Props;
	index: any;
}

const RenderItemGroup: React.FC<RenderItemGroupProps> = ({ row }: any) => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const seletec_hemisferios = useSelector(
		(state: any) => state.manage_protocol.seletec_hemisferios
	);

	const is_open_paciente = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);

	const execucoes: ExecucaoProps[] = useSelector(
		(state: any) => state.dashboard.execucoes
	);

	const [finalExers, setFinalExers] = useState<Exercicios2Props[]>([]);

	const [exers, setExers] = useState<ExerGrupoHemiPros[]>([]);

	function isPresentInListSelecteds(exercise: ExerGrupoHemiPros) {
		const find = seletec_hemisferios?.find(
			(e: any) => e.ID_EXERCICIO == exercise.exercice_id
		);
		const exist_type = find?.PROTOCOLO_EXERCICIO_TIPO.find(
			(e: any) => e == `${exercise.value}${exercise.exercice_id}`
		);

		if (exist_type) {
			return true;
		}
		return false;
	}

	const ids: any = [];
	useEffect(() => {
		const modosForExer: any = {};
		execucoes.forEach((e) => {
			if (e.ID_USUARIO == is_open_paciente.ID_USUARIO) {
				ids.push(e.ID_EXERCICIO);
				modosForExer[`${e.ID_EXERCICIO}-${e.EXECUCAO_MODO}`] = e.EXECUCAO_MODO;
			}
		});

		const filter = exercicios.filter((e) => {
			return ids.includes(e.ID_EXERCICIO);
		});

		let exers: any = [];
		filter.forEach((exer) => {
			let pro: any;
			for (pro in modosForExer) {
				if (exer.ID_EXERCICIO == pro.split("-")[0]) {
					const filt: any = exers.find(
						(ex: any) => ex.ID_EXERCICIO == exer.ID_EXERCICIO
					);
					if (filt) {
						const add = {
							...exer,
							modo: [...filt.modo, modosForExer[pro]],
						};
						exers = exers.filter(
							(ex: any) => ex.ID_EXERCICIO != exer.ID_EXERCICIO
						);
						exers = exers.filter(
							(ex: any) => ex.ID_EXERCICIO != exer.ID_EXERCICIO
						);
						exers.push(add);
					} else {
						exers.push({
							...exer,
							modo: [modosForExer[pro]],
						});
					}
				}
			}
		});
		setFinalExers(exers);
	}, []);

	const exercicio: any = finalExers.filter(
		(e: any) => row.ID_EXERCICIO == e.ID_EXERCICIO
	);

	let contador = 0;
	const modos: any = [];
	if (exercicio[0]?.modo?.indexOf("D") >= 0) {
		modos.push({
			title: current_language?.hemisferio_direito,
			value: "D",
			grupo: row?.ID_AGRUPAMENTO,
			exercice_id: row?.ID_EXERCICIO,
			name:
				current_language?.idioma === "Português"
					? row?.EXERCICIO_TITULO_PT
					: current_language?.idioma === "English"
					? row?.EXERCICIO_TITULO_EN
					: row?.EXERCICIO_TITULO_ES,
		});
		contador++;
	}
	if (exercicio[0]?.modo?.indexOf("E") >= 0) {
		modos.push({
			title: current_language?.hemisferio_esquerdo,
			value: "E",
			grupo: row?.ID_AGRUPAMENTO,
			exercice_id: row?.ID_EXERCICIO,
			name:
				current_language?.idioma === "Português"
					? row?.EXERCICIO_TITULO_PT
					: current_language?.idioma === "English"
					? row?.EXERCICIO_TITULO_EN
					: row?.EXERCICIO_TITULO_ES,
		});
		contador++;
	}
	if (exercicio[0]?.modo?.indexOf("B") >= 0) {
		modos.push({
			title: current_language?.hemisferio_bilateral,
			value: "B",
			grupo: row?.ID_AGRUPAMENTO,
			exercice_id: row?.ID_EXERCICIO,
			name:
				current_language?.idioma === "Português"
					? row?.EXERCICIO_TITULO_PT
					: current_language?.idioma === "English"
					? row?.EXERCICIO_TITULO_EN
					: row?.EXERCICIO_TITULO_ES,
		});
		contador++;
	}
	useEffect(() => {
		setExers(
			row.EXERCICIO_TIPO == TypesDivision.DEB
				? modos
				: [
						{
							title: current_language?.sem_hemisferio,
							value: "S",
							grupo: row?.ID_AGRUPAMENTO,
							exercice_id: row?.ID_EXERCICIO,
							name:
								current_language?.idioma === "Português"
									? row?.EXERCICIO_TITULO_PT
									: current_language?.idioma === "English"
									? row?.EXERCICIO_TITULO_EN
									: row?.EXERCICIO_TITULO_ES,
						},
				  ]
		);
	}, [current_language, contador]);

	// ids.includes(row.ID_EXERCICIO)

	const marcarTodos = useSelector(
		(state: any) => state.relatorios.marcar_todas
	);

	useEffect(() => {
		if (!marcarTodos) {
			dispatch(ManageProtocolActions.set_selected_exercises_reset());
		} else {
			exers.map((exer) => {
				isPresentInListSelecteds(exer) &&
					dispatch(ManageProtocolActions.set_selected_exercises(exer));
			});
			exers.map((exer) => {
				dispatch(ManageProtocolActions.set_selected_exercises(exer));
			});
		}
	}, [marcarTodos]);
	return (
		<div className="mid">
			<div className="midSup">
				<img src={LogoKinology} />
				<p className="exercicio" >
					{
						current_language?.idioma === "Português"
						? row?.EXERCICIO_TITULO_PT
						: current_language?.idioma === "English"
						? row?.EXERCICIO_TITULO_EN
						: row?.EXERCICIO_TITULO_ES
					}
				</p>
			</div>
			<div className="midInf">
				{exers.map((exer) => (
					<>
						<span
							key={exer.exercice_id}
							onClick={() => {
								dispatch(ManageProtocolActions.set_selected_exercises(exer));
							}}
							className={
								isPresentInListSelecteds(exer) ? "sagitalSelected" : "sagital"
							}
						>
							{exer.title}
						</span>
					</>
				))}
			</div>
		</div>
	);
};

export default RenderItemGroup;
