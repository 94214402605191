import styled from "styled-components";
import { Colors } from "../../../styles/colors";


export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    width: 400px;
    height: 214px;
    border-radius: 30px;;
    position: relative;
    background-color: white;
`;

export const Title = styled.p`
    font-family: "NotoSans-Bold";
    color: ${Colors.primary_blue_k_main};
    font-size: 24px;
    font-weight: 700 ;
`;
export const ContainerOptions = styled.div`
    display: flex;
    margin-top: 32px;
    justify-content: space-around;
`;
export const Option = styled.div`
    display: flex;
    width: 30%;
    flex-direction: column;
    justify-content: center;
    justify-content: center;
    align-items: center;
`;