import React, { useState } from "react";
import styled from "styled-components";
import { ContainerDash, Icon } from "../../../styles/global";
import { useDispatch, useSelector } from "react-redux";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { TextsProps } from "../../../database/txt.interface";
import InputPesquisaExercicio from "../../DashProtocolos/InputPesquisaExercicio/InputPesquisaExercicio";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import TutorialDefault from "../../../assets/tutorial_default.png";
import ExpandIcon from "../../../assets/icons/expand.svg";
import { P2Desk } from "../../../styles/styles_text";
import RenderExerProto from "../../Execucao/Exercicio/renderExerProps";
import ToastAlert from "../../../components/toast";
import { AppActions } from "../../../store/ducks/app";
import { tutorialImages } from "../../../utils/tutorials";

const ExercisesInPatient: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const [region_selected, set_region_selected] = useState("SUPERIOR");

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const exercises_list: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.search_exercise
	);

	return (
		<ContainerDash>
					{is_open_toast && <ToastAlert />}
			<ContainerOptions>
				<Option
					onClick={() =>
						dispatch(DashBoardActions.set_current_page("protocols_in_patient"))
					}
				>
					{current_language?.menu_lateral_protocolos}
				</Option>
				<OptionSelected>{current_language?.exercicios}</OptionSelected>
			</ContainerOptions>
			<InputPesquisaExercicio />

			<InfeEsup>
				<OptionRegion
					style={{
						borderColor: region_selected == "SUPERIOR" ? "#00A377" : "#C4C5CC",
					}}
					onClick={() => set_region_selected("SUPERIOR")}
				>
					<P2Desk>Superiores</P2Desk>
				</OptionRegion>
				<OptionRegion
					style={{
						borderColor: region_selected == "INFERIOR" ? "#00A377" : "#C4C5CC",
					}}
					onClick={() => set_region_selected("INFERIOR")}
				>
					<P2Desk>Inferiores</P2Desk>
				</OptionRegion>
			</InfeEsup>

			<ListContainer>
				{exercises_list
					.filter((e: Exercicios2Props) => {
						return e.AGRUPAMENTO_TIPO == region_selected;
					})
					.map((exercicio: Exercicios2Props, index: number) => (
						<ExerciseSection key={exercicio.ID_EXERCICIO}>
							<TitleAndDescription>
								<ExerciseName>{exercicio.EXERCICIO_TITULO_PT}</ExerciseName>
								<RenderExerProto item={exercicio} index={index} region_selected={region_selected}/>
							</TitleAndDescription>
							<TutorialSection
								onClick={() =>
									dispatch(AppActions.set_modal_tutorial(true, exercicio))
								}>
								{tutorialImages[exercicio.EXERCICIO_TITULO_PT] && tutorialImages[exercicio.EXERCICIO_TITULO_PT][0] && (
									<>
										<Icon
											style={{ 
												width: "8rem",
												height: "100%",
												objectFit: "cover",
												borderTopRightRadius: "15px",
												borderBottomRightRadius: "15px",
												marginRight: "7px"
											}}
											src={tutorialImages[exercicio.EXERCICIO_TITULO_PT][0]}
										/>
										<Icon
											src={ExpandIcon}
											style={{
												width: "1.5rem",
												height: "1.5rem",
												marginLeft: "-2rem",
												marginTop: "0.5rem",
											}}
										/>
									</>
								)}
							</TutorialSection>
						</ExerciseSection>
					))}
			</ListContainer>
		</ContainerDash>
	);
};

const ContainerOptions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 60%;
`;

const Option = styled.text`
	font-family: "NotoSans-regular";
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 38.4px;
	border-bottom: 3px solid #dedfe3;
	color: #000e4b;
	padding-right: 1.5rem;
`;

const OptionSelected = styled.text`
	font-family: "NotoSans-regular";
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 38.4px;
	border-bottom: 3px solid #00d084;
	color: #000e4b;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
`;

const ListContainer = styled.div`
	width: 60%;
	height: 65vh;
	margin-bottom: 1.5rem;
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const ExerciseSection = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border: 2px solid #dedfe3;
	border-radius: 18px;
	margin-bottom: 1rem;
	height: 8rem;
`;

const TitleAndDescription = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1rem;
`;

const ExerciseName = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1.1rem;
	font-weight: 600;
	color: #323d6e;
`;

const TutorialSection = styled.div`
	display: flex;
	max-height: 100%;
`;

const InfeEsup = styled.div`
	display: flex;
	flex-direction: row;
	width: 60%;
	margin-top: 2em;
	margin-bottom: 2rem;
`;

const OptionRegion = styled.div`
	border-radius: 0.75rem;
	border: 2px solid var(--Neutras-Grey-K-20, #c4c5cc);
	width: 50%;
	height: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1rem;
	margin-right: 1rem;
`;

export default ExercisesInPatient;
