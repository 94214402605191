import UIfx from "uifx";

import beep from "../../assets/audio/beep.ogg";

export function beep_curto() {
	const Tocar = new UIfx(beep, {
		volume: 1,
	});
	Tocar.play();
}
