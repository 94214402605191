
import React, { memo, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AmplitudeLog } from "../../../utils/amplitude";
import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";

import buttonClose from "../../../assets/icons/fechar.svg";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import DinoHappy from "../../../assets/dinoHappyAsync.svg";
import { TypesQuestionnaire } from "../../../utils/types";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { 
	PerfilAnamneseProps, 
	PropsHistoricoSF36 
} from "../../../interfaces/perfilAnamnese";
import { 
	ButtonContinuar, 
	ButtonGerarNovo, 
	Close, 
	Container, 
	ContainerButtons, 
	ContainerDino, 
	Content, 
	Dino, 
	Phrase, 
	TextArea, 
	Title 
} from "./styles";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalContinuarSF36: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const classes = useStyles();
	const is_open_continuar_ou_gerar_sf_36 = useSelector(
		(state: any) => state.app.is_open_continuar_ou_gerar_sf_36
	);
	const ModalContinuarSF36Close = () => {
		dispatch(AppActions.set_modal_continuar_or_gerar_new_sf_36(false))
	};
	const historicos_questionarios: PerfilAnamneseProps = useSelector(
		(state: any) => state.pac_aval.perfil_anamnese_array
	);
	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const continuarQuestionario = () => {
		
		dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.SF_36));
		dispatch(
			PacAvalActions.set_usu_continuar_questionario(paciente.ID_USUARIO)
		);
		dispatch(AppActions.set_modal_continuar_or_gerar_new_sf_36(false))
	};
	const [ isDisable, setIsDisable ] = useState<boolean>(false)
	
	const first_sf_36: PropsHistoricoSF36[] = historicos_questionarios?.historico_sf_36 

	const filtered_sf_360 = historicos_questionarios?.historico_sf_36?.filter(
		(element: any) => element?.QUESTIONARIO_RESPOSTA_CONCLUIDO == "S"
	);

	let final_filtered_sf_360: PropsHistoricoSF36[] = [];
	
	if(!!first_sf_36 && first_sf_36[0]?.QUESTIONARIO_RESPOSTA_CONCLUIDO == "N") {
		final_filtered_sf_360 = [first_sf_36[0], ...filtered_sf_360]; 
	} else {
		final_filtered_sf_360 = filtered_sf_360;
	}

	useEffect(()=> {
		if(
			final_filtered_sf_360 != undefined 
				&& 
			final_filtered_sf_360[0]?.QUESTIONARIO_RESPOSTA_CONCLUIDO === "S"
		){
			setIsDisable(true)
		}else{
			setIsDisable(false)
		}
		
	},[final_filtered_sf_360])

	const openModalShereAnamnese = () => {
		dispatch(PacAvalActions.set_reset_questionario());
		dispatch(AppActions.set_modal_compartilhamento(true, 10));
		dispatch(AppActions.set_continue_compartilhamento("ShereAnamnese"));
		dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.SF_36));
		dispatch(AppActions.set_modal_continuar_or_gerar_new_sf_36(false));
	};


	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={is_open_continuar_ou_gerar_sf_36}
				onClose={ModalContinuarSF36Close}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={is_open_continuar_ou_gerar_sf_36}>
					<Container>
						<Close
							src={buttonClose}
							onClick={()=> dispatch(AppActions.set_modal_continuar_or_gerar_new_sf_36(false))}
							alt="Close"
						/>
						<Content>
							<ContainerDino >
								<Dino src={DinoHappy} alt="" />
							</ContainerDino>

							<Title>
								{current_language?.sf_andamento1}
							</Title>

							<TextArea>
								<Phrase>
									{current_language?.sf_andamento22}
								</Phrase>
								<br/>
								<Phrase>
									{current_language?.sf_andamento33}
								</Phrase>
							</TextArea>
							
							<ContainerButtons>
								<ButtonGerarNovo
									onClick={()=> {
										openModalShereAnamnese()
										AmplitudeLog('gerar_sf_36')
									}}
								>
									{current_language?.gerar_nova}
								</ButtonGerarNovo>

								<ButtonContinuar
									style={{
										backgroundColor: isDisable ? '#5C5B5B' :  '#000E4B'
									}}
									onClick={()=> 
										final_filtered_sf_360[0].QUESTIONARIO_RESPOSTA_CONCLUIDO !== "S" &&
										continuarQuestionario() 
									}
									disabled={isDisable}
								>
									{current_language?.continuar_anamnese}
								</ButtonContinuar>
							</ContainerButtons>
						</Content>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalContinuarSF36);
