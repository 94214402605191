export interface PlanosProps {
	SERVICO_MGM: string;
	SERVICO_IUGU: string;
	SERVICO_PLANO_NOME: string;
	SERVICO_PLANO_INICIO: string;
	SERVICO_PLANO_NOME_TRIAL: string;
	SERVICO_PLANO_TRIAL_FIM: string;
	SERVICO_AVALIADORES: number;
	SERVICO_PACIENTES: number;
	SERVICO_LOGOMARCA: boolean;
	SERVICO_PERCENTUALRM: boolean;
	SERVICO_FREE_TRAINING: boolean;
	SERVICO_RECYCLE: boolean;
	SERVICO_PROTOCOL_FAVORITO: boolean;
	SERVICO_AVALIACAO_IQ: boolean;
	SERVICO_ANOTACOES: boolean;
	SERVICO_FORCA: number;
	SERVICO_WEB: boolean;
	SERVICO_WEB_AVALIACAO: boolean;
	SERVICO_WEB_DIVIDIR_TELA: boolean;
	SERVICO_MOBILE_AVALIACAO: boolean;
	SERVICO_REL_AVALIACAO: boolean;
	SERVICO_REL_FORCA: boolean;
	SERVICO_REL_ASSIMETRIA: boolean;
	SERVICO_CSV: boolean;
	SERVICO_DEMO: boolean;
	SERVICO_FILTRO_50KG: boolean;
	SERVICO_CALIBRACAO: boolean;
}

export enum ServiceTypePatient {
	MENSAL = 'MENSAL',
	TOTAL = 'TOTAL',
};
