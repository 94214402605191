import styled from "styled-components";

export const Container = styled.div`
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;
	height: 70vh;
	width: 35vw;
	padding: 2rem;
	background-color: #FAFAFA;
`;

export const Close = styled.img`
	width: 1rem;
	position: absolute;
	right: 2.5rem;
	top: 2.5rem;
	cursor: pointer;
`;

export const Title = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1.3rem;
	font-weight: 700;
	line-height: 42px;
	text-align: center;
	color: #000e4b;
	margin-bottom: 1rem;
`;

export const Subtitle = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1rem;
	font-weight: 700;
	text-align: left;
	color: #000e4b;
	margin-bottom: 1rem;
	line-height: 42px;
`;

export const ContainerPaci = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const Photo = styled.img`
	height: 1.8rem;
	width: 1.8rem;
	border-radius: 50%;
`;

export const Name = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 50px;
	color: #323d6e;
	margin-left: 0.8rem;
`;

export const ContainerOptions = styled.form`
	height: 100%;
	width: 100%;
	margin-bottom: 2rem;
	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

export const Option = styled.div`
	width: 100%;
	font-family: Noto Sans;
	font-size: 24px;
	font-weight: 400;
	line-height: 36px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-right: 1.5rem;
`;

export const NameOption = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 45px;
	color: #000e4b;
`;
