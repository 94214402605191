/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DinoTriste from "../../../assets/icons/dinoTriste.svg";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import AssimetriaSupIcon from "../../../assets/icons/assimetria_icon.svg";
import AssimetriaInfIcon from "../../../assets/icons/assimetria_inferior.svg";
import EvolucaoIcon from "../../../assets/icons/evolucao_icon.svg";
import ArrowClosed from "../../../assets/icons/arrow_forward.svg";
import ArrowOpen from "../../../assets/icons/arrow_forward_ios_open.svg";
import PdfIcon from "../../../assets/kinologyIcons/svg/pdf.svg";
import VerifiedIcon from "../../../assets/icons/verified_icon.svg";
import AlertIcon from "../../../assets/kinologyIcons/svg/alert_icon.svg";

import SuperiorFrontalMasculino from "../../../assets/assimetria/anatomia_frontal_superior_masculino.svg";
import InferiorFrontalMasculino from "../../../assets/assimetria/anatomia_frontal_inferior_masculino.svg";
import InferiorDorsalMasculino from "../../../assets/assimetria/anatomia_dorsal_inferior_masculino.svg";
import SuperiorDorsalMasculino from "../../../assets/assimetria/anatomia_dorsal_superior_masculino.svg";

import SuperiorFrontalFeminino from "../../../assets/assimetria/anatomia_frontal_superior_feminino.svg";
import InferiorFrontalFeminino from "../../../assets/assimetria/anatomia_frontal_inferior_feminino.svg";
import InferiorDorsalFeminino from "../../../assets/assimetria/anatomia_dorsal_inferior_feminino.svg";
import SuperiorDorsalFeminino from "../../../assets/assimetria/anatomia_dorsal_superior_feminino.svg";

import DotsSuperioresFrontal from "../../../assets/desequilibrio/dots_superior_frontal.svg";
import DotsSuperioresDorsal from "../../../assets/desequilibrio/dots_superior_dorsal.svg";
import DotsInferioresFrontal from "../../../assets/desequilibrio/dots_inferior_frontal.svg";
import DotsInferioresDorsal from "../../../assets/desequilibrio/dots_inferior_dorsal.svg";

import { TextsProps } from "../../../database/txt.interface";
import { ExerCucaoProp } from "../../../interfaces/assimetria.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import { getPermission } from "../../../utils/funcs";
import { TypeAgrupamentoLado, TypesPermission } from "../../../utils/types";
import GetMuscleIcon from "../Assimetria/GetMuscleIcon";
import { Content } from "./styles";
import { ConfigsActions } from "../../../store/ducks/configs";
import history from "../../../routes/history";
import { Icon, ProfilePicture, Return } from "../../../styles/global";
import {
	DescriptionDesk,
	P2Desk,
	P3Desk,
	S1Desk,
} from "../../../styles/styles_text";
import styled from "styled-components";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import EvolucaoEquilibrio from "./EvolucaoDesequilibrio/EvolucaoEquilibrio";

const Desequilibrio: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const dispatch = useDispatch();
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const desequilibrios_object: any = useSelector(
		(state: any) => state.dashboard.desequilibrio
	);

	const desequilibrios_array: any = Object.values(desequilibrios_object);

	const desequilibrios_superiores = desequilibrios_array?.filter(
		(ass: any) => ass.AGRUPAMENTO === "SUPERIOR"
	);
	const desequilibrios_inferiores = desequilibrios_array?.filter(
		(ass: any) => ass.AGRUPAMENTO === "INFERIOR"
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const language = useSelector((state: any) => state.configs.language);

	const [permission, setPermission] = useState(true);

	const arr_options_superiores = [
		{
			id: 1,
			value: TypeAgrupamentoLado.SUPERIOR_FRONTAL,
			Agrupamento: current_language?.grupameto_superior,
		},
		{
			id: 2,
			value: TypeAgrupamentoLado.SUPERIOR_DORSAL,
			Agrupamento: current_language?.grupameto_superior,
		},
	];

	const arr_options_inferiores = [
		{
			id: 3,
			value: TypeAgrupamentoLado.INFERIOR_FRONTAL,
			Agrupamento: current_language?.grupameto_inferior,
		},
		{
			id: 4,
			value: TypeAgrupamentoLado.INFERIOR_DORSAL,
			Agrupamento: current_language?.grupameto_inferior,
		},
	];

	const lados = [
		{ key: "E", value: "Esquerdo" },
		{ key: "D", value: "Direito" },
	];

	const gabarito_numero_dots: any = {
		CERVICAL_D: 1,
		OMBRO_D: 2,
		OMBRO_E: 3,
		COTOVELO_D: 4,
		COTOVELO_E: 5,
		PUNHO_D: 6,
		PUNHO_E: 7,

		QUADRIL_D: 1,
		QUADRIL_E: 2,
		JOELHO_D: 3,
		JOELHO_E: 4,
		TORNOZELO_D: 5,
		TORNOZELO_E: 6,
	};

	function GetMusculos(desequilibrios_agrupamento: any, posicao: string) {
		const musculos_desequilibrios: any = [];

		desequilibrios_agrupamento?.map((desequilibrio: any) => {
			desequilibrio?.REFERENCIAS?.map((referencia: any) => {
				if (referencia.EXECUCOES.length > 0) {
					referencia.MUSCULOS?.map((musculo: any) => {
						if (musculo.POSICAO == posicao) {
							const posicao_agrupamento_musculo = musculo.LABEL.split("_");
							musculos_desequilibrios.push({
								posicao: posicao_agrupamento_musculo[0],
								agrupamento: posicao_agrupamento_musculo[1],
								musculo: posicao_agrupamento_musculo[2] + (posicao_agrupamento_musculo[3] != undefined ?  "_" + posicao_agrupamento_musculo[3] : ""), 
								LEGENDA_D: referencia.EXECUCOES[0].DESVIO_D,
								LEGENDA_E: referencia.EXECUCOES[0].DESVIO_E,
								LABEL_MUSCULO: desequilibrio.TITULO_TABELA.toUpperCase(),
							});
						}
					});
				}
			});
		});

		return musculos_desequilibrios;
	}

	function _renderSupeFrontal() {
		const musuculos_desequilibrios_superiores_frontal: any = GetMusculos(
			desequilibrios_superiores,
			"FRONTAL"
		);

		return (
			<div className="contentBody">
				<img
					src={
						patient.USUARIO_SEXO == "M"
							? SuperiorFrontalMasculino
							: SuperiorFrontalFeminino
					}
					alt=""
					className="imgBody"
				/>
				{musuculos_desequilibrios_superiores_frontal?.map(
					(item: any, index: number) => (
						<>
							<GetMuscleIcon
								key={index}
								agrupamento={item.agrupamento}
								musculo={item.musculo}
								posicao={item.posicao}
								sexo={patient.USUARIO_SEXO == "M" ? "masculino" : "feminino"}
								cor_direito={item.LEGENDA_D}
								cor_esquerdo={item.LEGENDA_E}
								classe="musculo"
							/>
						</>
					)
				)}
				<img src={DotsSuperioresFrontal} className="dots_sup" />
			</div>
		);
	}

	function _renderSuperiorDorsal() {
		const musuculos_desequilibrios_superiores_dorsal: any = GetMusculos(
			desequilibrios_superiores,
			"DORSAL"
		);

		return (
			<div className="contentBody">
				<img
					src={
						patient.USUARIO_SEXO == "M"
							? SuperiorDorsalMasculino
							: SuperiorDorsalFeminino
					}
					alt=""
					className="imgBody"
				/>
				{musuculos_desequilibrios_superiores_dorsal?.map(
					(item: any, index: number) => (
						<>
							<GetMuscleIcon
								key={index}
								agrupamento={item.agrupamento}
								musculo={item.musculo}
								posicao={item.posicao}
								sexo={patient.USUARIO_SEXO == "M" ? "masculino" : "feminino"}
								cor_direito={item.LEGENDA_D}
								cor_esquerdo={item.LEGENDA_E}
								classe={"musculo"}
							/>
						</>
					)
				)}
				<img src={DotsSuperioresDorsal} className="dots_sup" />
			</div>
		);
	}

	function _renderInferiorFrontal() {
		const musuculos_desequilibrios_inferiores_frontal: any = GetMusculos(
			desequilibrios_inferiores,
			"FRONTAL"
		);

		return (
			<div className="contentBody" style={{ paddingTop: 20 }}>
				<img
					src={
						patient.USUARIO_SEXO == "M"
							? InferiorFrontalMasculino
							: InferiorFrontalFeminino
					}
					alt=""
					className="imgBodyInf"
				/>

				{musuculos_desequilibrios_inferiores_frontal?.map(
					(item: any, index: number) => (
						<>
							<GetMuscleIcon
								key={index}
								agrupamento={item.agrupamento}
								musculo={item.musculo}
								posicao={item.posicao}
								sexo={patient.USUARIO_SEXO == "M" ? "masculino" : "feminino"}
								cor_direito={item.LEGENDA_D}
								cor_esquerdo={item.LEGENDA_E}
								classe={"musculoInf"}
							/>
						</>
					)
				)}
				<img src={DotsInferioresFrontal} className="dots_inf" />
			</div>
		);
	}

	function _renderInferiorDorsal() {
		const musuculos_desequilibrios_inferiores_dorsal: any = GetMusculos(
			desequilibrios_inferiores,
			"DORSAL"
		);

		return (
			<div className="contentBody">
				<img
					src={
						patient.USUARIO_SEXO == "M"
							? InferiorDorsalMasculino
							: InferiorDorsalFeminino
					}
					alt=""
					className="imgBodyInf"
				/>

				{musuculos_desequilibrios_inferiores_dorsal?.map(
					(item: any, index: number) => (
						<>
							<GetMuscleIcon
								key={index}
								agrupamento={item.agrupamento}
								musculo={item.musculo}
								posicao={item.posicao}
								sexo={patient.USUARIO_SEXO == "M" ? "masculino" : "feminino"}
								cor_direito={item.LEGENDA_D}
								cor_esquerdo={item.LEGENDA_E}
								classe={"musculoInf"}
							/>
						</>
					)
				)}
				<img src={DotsInferioresDorsal} className="dots_inf" />
			</div>
		);
	}

	const navigationUpgrade = () => {
		history.push("/upgrade");
	};

	useEffect(() => {
		const permissionAsymmetry: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_ASSIMETRIA
		);
		if (!permissionAsymmetry) {
			setPermission(false);
			dispatch(ConfigsActions.set_title_modal_upgrade(true));
		} else {
			dispatch(ConfigsActions.set_title_modal_upgrade(false));
		}
	}, []);

	type EstadoPermitido = "superiores" | "inferiores" | "evolucao";
	const [resultado_selecionado, set_resultado_selecionado] =
		useState<EstadoPermitido>("superiores");

	const [open_legends, set_open_legends] = useState(false);

	const renderTableIQ = () => {
		return (
			<TableExercises>
				<thead>
					<tr>
						<Th>Local</Th>
						<Th>Movimento</Th>
						<Th>Força máxima</Th>
						<Th>Razão</Th>
						<Th>Desvio</Th>
					</tr>
				</thead>
				{(resultado_selecionado == "inferiores"
					? desequilibrios_inferiores
					: desequilibrios_superiores
				)?.map((desequilibrio: any, index: number) => (
					<tbody key={index}>
						{desequilibrio?.REFERENCIAS?.map((referencias: any) => (
							<>
								{referencias?.EXECUCOES[0]?.DATA && (
									<>
										{(desequilibrio?.TITULO_TABELA == "Cervical"
											? [{ key: "D", value: "Direito" }]
											: lados
										)?.map((lado: any) => (
											<tr className="tr" key={index}>
												<Td
													style={{
														display: "flex",
														alignItems: "center",
														flexDirection: "row",
														padding: "1.1rem",
													}}
												>
													<NumberLabel style={{ marginRight: "1rem" }}>
														{
															gabarito_numero_dots[
																desequilibrio?.TITULO_TABELA.toUpperCase() +
																	"_" +
																	lado.key
															]
														}
													</NumberLabel>
													<text>
														{desequilibrio?.TITULO_TABELA} {lado.value}
													</text>
												</Td>
												<Td>{referencias?.TITULO}</Td>

												<Td>
													{lado?.key == "D"
														? referencias?.EXECUCOES[0]?.FORCAS_D
														: referencias?.EXECUCOES[0]?.FORCAS_E}
												</Td>
												<Td>
													{lado?.key == "D"
														? referencias?.EXECUCOES[0]?.RAZAO_D
														: referencias?.EXECUCOES[0]?.RAZAO_E}
												</Td>
												<Td>
													<Icon
														src={
															lado?.key == "D"
																? referencias?.EXECUCOES[0]?.DESVIO_D ==
																  "POSITIVO"
																	? VerifiedIcon
																	: AlertIcon
																: referencias?.EXECUCOES[0]?.DESVIO_E ==
																  "POSITIVO"
																? VerifiedIcon
																: AlertIcon
														}
													/>
												</Td>
											</tr>
										))}
									</>
								)}
							</>
						))}
					</tbody>
				))}
			</TableExercises>
		);
	};

	return (
		<Content>
			{permission ? (
				<>
					<Return
						onClick={() =>
							dispatch(DashBoardActions.set_current_page("perfil_paciente"))
						}
					>
						<Icon src={ReturnIcon} style={{ width: "1rem", height: "1rem" }} />
						<P3Desk>{current_language?.area_paciente}</P3Desk>
					</Return>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							marginTop: "1rem",
							marginBottom: "3rem",
							width: "60%",
							justifyContent: "space-between",
						}}
					>
						<div style={{ display: "flex", alignItems: "center" }}>
							<ProfilePicture
								src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
							/>
							<S1Desk style={{ marginLeft: "1rem" }}>
								{patient.USUARIO_NOME}
							</S1Desk>
						</div>
						<Icon
							style={{ width: "2rem", height: "2rem" }}
							onClick={() => {
								dispatch(
									DashBoardActions.set_current_page("emissao_relatorios")
								);
								dispatch(
									RelatorioActions.set_current_selected_reports(["EQUILIBRIO_MUSCULAR"])
								);
							}}
							src={PdfIcon}
						/>
					</div>
					<ContainerOptions>
						<Option
							onClick={() => set_resultado_selecionado("superiores")}
							style={
								resultado_selecionado == "superiores"
									? { borderBottom: "4px solid #00D084" }
									: {}
							}
						>
							<Icon style={{ marginRight: "1rem" }} src={AssimetriaSupIcon} />
							<div style={{ display: "flex", flexDirection: "column" }}>
								<text>Agrupamentos</text>
								<strong>Superiores</strong>
							</div>
						</Option>
						<Option
							onClick={() => set_resultado_selecionado("inferiores")}
							style={
								resultado_selecionado == "inferiores"
									? { borderBottom: "4px solid #00D084" }
									: {}
							}
						>
							<Icon style={{ marginRight: "1rem" }} src={AssimetriaInfIcon} />
							<div style={{ display: "flex", flexDirection: "column" }}>
								<text>Agrupamentos</text>
								<strong>Inferiores</strong>
							</div>
						</Option>
						<Option
							onClick={() => set_resultado_selecionado("evolucao")}
							style={
								resultado_selecionado == "evolucao"
									? { borderBottom: "4px solid #00D084" }
									: {}
							}
						>
							<Icon style={{ marginRight: "1rem" }} src={EvolucaoIcon} />
							<strong>Evolução</strong>
						</Option>
					</ContainerOptions>
					{(resultado_selecionado == "superiores" ||
						resultado_selecionado == "inferiores") && (
						<WhiteContainer>
							{resultado_selecionado == "inferiores" ? (
								<P2Desk>
									Equilíbrio Muscular e Valores de Referência de Membros
									Inferiores
								</P2Desk>
							) : (
								<P2Desk>
									Equilíbrio Muscular e Valores de Referência de Membros
									Superiores
								</P2Desk>
							)}

							<BodyContainer>
								{(resultado_selecionado == "inferiores"
									? arr_options_inferiores
									: arr_options_superiores
								)?.map((item, index) => (
									<>
										<div key={index} className="bodyAndTable">
											{item?.value === TypeAgrupamentoLado.SUPERIOR_DORSAL ? (
												<P3Desk className="title">Vista Dorsal</P3Desk>
											) : item?.value ===
											  TypeAgrupamentoLado.SUPERIOR_FRONTAL ? (
												<P3Desk className="title">Vista Frontal</P3Desk>
											) : item?.value ===
											  TypeAgrupamentoLado.INFERIOR_DORSAL ? (
												<P3Desk className="title">Vista Dorsal</P3Desk>
											) : (
												<P3Desk className="title">Vista Frontal</P3Desk>
											)}
											<div className="body">
												{item?.value === TypeAgrupamentoLado.SUPERIOR_DORSAL ? (
													_renderSuperiorDorsal()
												) : item?.value ===
												  TypeAgrupamentoLado.SUPERIOR_FRONTAL ? (
													_renderSupeFrontal()
												) : item?.value ===
												  TypeAgrupamentoLado.INFERIOR_DORSAL ? (
													_renderInferiorDorsal()
												) : item?.value ===
												  TypeAgrupamentoLado.INFERIOR_FRONTAL ? (
													_renderInferiorFrontal()
												) : (
													<></>
												)}
											</div>
										</div>
									</>
								))}
							</BodyContainer>
							{renderTableIQ()}
							<ContainerLegenda>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
									onClick={() => set_open_legends(open_legends ? false : true)}
								>
									<Th>Legendas</Th>
									<Icon src={open_legends ? ArrowOpen : ArrowClosed} />
								</div>

								<div
									style={
										!open_legends ? { display: "none", marginTop: "1rem" } : {}
									}
								>
									<Th>Chance de lesão por assimetria de movimento:</Th>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											marginBottom: "1rem",
										}}
									>
										<Icon style={{ marginLeft: "0.5rem" }} src={VerifiedIcon} />
										<DescriptionDesk>Baixa chance de lesão</DescriptionDesk>
										<Icon src={AlertIcon} style={{ marginLeft: "0.5rem" }} />
										<DescriptionDesk>Há chance de lesão</DescriptionDesk>
									</div>
									<DescriptionDesk
										style={{
											color: "#797D8D",
											paddingLeft: "0.5rem",
										}}
									>
										Resultados contabilizados a partir da últimas execuções dos
										lados direito e esquerdo realizadas no mesmo dia. Para
										confiabilidade dos dados, execuções feitas em dias
										diferentes não serão contabilizadas. No caso de mais de uma
										execução no mesmo dia, será contabilizada a última.
										<br />
										<br />
									</DescriptionDesk>
								</div>
							</ContainerLegenda>
							<ContainerLegenda>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										width: "100%",
									}}
								>
									<Th>Relações referenciais</Th>
									<Icon src={open_legends ? ArrowOpen : ArrowClosed} />
								</div>
							</ContainerLegenda>
							<TableExercises>
								<thead>
									<tr>
										<Th>Articulação</Th>
										<Th>Movimento</Th>
										<Th>Razão Referencial</Th>
									</tr>
								</thead>
								{(resultado_selecionado == "inferiores"
									? desequilibrios_inferiores
									: desequilibrios_superiores
								)?.map((items: any, index: number) => (
									<tbody key={index}>
										{items.REFERENCIAS.map((iq: any) => (
											<tr className="tr" key={index}>
												<Td
													style={{
														display: "flex",
														alignItems: "center",
														flexDirection: "row",
														padding: "1.1rem",
													}}
												>
													<text>{items?.TITULO}</text>
												</Td>
												<Td>{iq.TITULO}</Td>
												<Td>{iq.VALOR_REFERENCIA}</Td>
											</tr>
										))}
									</tbody>
								))}
							</TableExercises>
						</WhiteContainer>
					)}

					{resultado_selecionado == "evolucao" && (
						<WhiteContainer>
							<P2Desk>Evolução de Equilíbrio Muscular</P2Desk>
							<EvolucaoEquilibrio />
						</WhiteContainer>
					)}
				</>
			) : (
				<div className="upgrade">
					<img src={DinoTriste} alt="" />
					<p>{current_language?.modal_upgrade_funcionalidade_indisponivel}</p>
					<p>{current_language?.modal_upgrade_funcionalidade_indisponivel2}</p>
					<button className="buttonUpgrade" onClick={navigationUpgrade}>
						{current_language?.menu_lateral_upgrade}
					</button>
				</div>
			)}
		</Content>
	);
};

const ContainerOptions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 60%;
`;

const Option = styled.text`
	color: var(--Azul-Azul-kinology, var(--Color, #000e4b));
	text-align: center;
	font-family: "NotoSans-Regular";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	height: 4rem;
	border-bottom: 4px solid var(--Neutras-Grey-K-10, #dedfe3);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	width: 100%;
`;

const WhiteContainer = styled.div`
	background-color: white;
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
	position: relative;
	z-index: 2;

	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const BodyContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	margin-top: 2rem;
`;

const TableExercises = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const Th = styled.th`
	border: 1.37px solid var(--Neutras-Grey-K-5, #f1f2f3);
	background: var(--Neutras-Grey-K-5, #f1f2f3);
	text-align: left;
	padding: 0.7rem;
	color: var(--Neutras-Grey-K-70, #3d4259);
	font-family: "NotoSans-Regular";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const Td = styled.td`
	border-bottom: 1.37px solid var(--Neutras-Grey-K-5, #f1f2f3);
	background: #fff;
	padding: 0.5rem;
	padding-left: 0.7rem;
	color: var(--Neutras-Grey-K-70, #3d4259);
	font-family: "NotoSans-Regular";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const NumberLabel = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #4d4d4d;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	font-size: 9px;
	color: #ffffff;
`;

const ContainerLegenda = styled.div`
	background: var(--Neutras-Grey-K-5, #f1f2f3);
	width: 100%;
	padding: 0.5rem;
	color: var(--Neutras-Grey-K-70, #3d4259);
	font-family: "Noto Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	//	border-radius: 0rem 0rem 0.875rem 0.875rem;
`;

export default Desequilibrio;
