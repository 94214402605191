import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonClose from "../../assets/icons/buttonCloseBlack.svg";

import IconWarning from "../../assets/icons/status_warning.svg";
import { TextsProps } from "../../database/txt.interface";
import { AmplitudeLog } from "../../utils/amplitude";
import { AppActions } from "../../store/ducks/app";
import { AddPacAvalActions } from "../../store/ducks/add_pac_aval";
import { UserProps } from "../../interfaces/pacientes.interface";
import { ContainerModalExcluir } from "../../styles/styles_modais_excluir";
import { AtribuirActions } from "../../store/ducks/atribuir";

// fuunção que gera o efeito modal, com fundo escuro
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalExcluirPaciente: React.FC = () => {
	const dispatch = useDispatch();
	const classes = useStyles();

	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	// comunica com redux
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const lista_pacientes = useSelector(
		(state: any) => state.app.lista_pacientes
	);

	const is_open_modal_excluir_paciente = useSelector(
		(state: any) => state.app.is_open_modal_excluir_paciente
	);

	const ModalClose = () => {
		AmplitudeLog("cancelar_excluir_perfil_paciente");
		dispatch(AppActions.set_modal_excluir_paciente(false));
		dispatch(AppActions.set_lista_pacientes([]));
		dispatch(AtribuirActions.set_menu_option_selected(""));
	};

	const Excluir = () => {
		AmplitudeLog("perfil_paciente_excluido", {
			body: {
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				ID_USUARIO: lista_pacientes,
			},
		});

		let payload: any = {
			"0": {
				TOKEN: user_logado.token,
			},
		};

		lista_pacientes.map((paciente: any, index: number) => {
			payload = {
				...payload,
				[index + 1]: {
					txDataHora: moment(new Date())
						.format("YYYY-MM-DD HH:mm:ss")
						.toString(),
					ID_USUARIO: paciente,
				},
			};
		});

		dispatch(AddPacAvalActions.request_desativar_paci(payload));
		ModalClose();
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={is_open_modal_excluir_paciente}
			onClose={ModalClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={is_open_modal_excluir_paciente}>
				<ContainerModalExcluir>
					<img
						className="close"
						src={buttonClose}
						onClick={ModalClose}
						alt="Close"
					/>
					<img className="icon_warning" src={IconWarning} alt="" />
					<div className="title">
						<div className="containerFrases">
							<p className="QtdPacientes">
								{current_language?.deseja_remover} {lista_pacientes.length}{" "}
								{current_language?.paciente}(s) ?
							</p>
						</div>
						<p className="text">
							{current_language?.se_desejar_voce_pode_recuperar}
						</p>
					</div>
					<div className="buttons">
						<button onClick={Excluir} className="button excluir">
							{current_language?.modal_excluir}
						</button>
						<button className="button cancelar" onClick={ModalClose}>
							{current_language?.pagamento_cancelar}
						</button>
					</div>
				</ContainerModalExcluir>
			</Fade>
		</Modal>
	);
};

export default ModalExcluirPaciente;
