import moment from "moment";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { UserProps } from "../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "../../utils/types";
import { AppActions } from "../ducks/app";
import { AssinaturaActions, Types } from "../ducks/assinatura";
import * as Sentry from "@sentry/react";

function* editAssinatura({ payload }: any): any {
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);

	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	
	async function apiCall() {
		try {
			// let dataRequest:any = {};
			const dat = {
				...payload,
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}usuarios/usuarios_certificacoes.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: usuarios_certificacoes.php", "error");
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.FAIL) {
			message = "Erro ao enviar a assinatura";
			if (response.code == 1) {
				message = "Erro ao enviar a assinatura";
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			if (response.code == 0) {
				message = current_language?.alteracao_concluida;
			}
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: message,
				})
			);
		}
	} catch (e) {
	} finally {
		yield put(AssinaturaActions.edit_assinatura_finally());
	}
}

export function* AssinaturaSaga(): any {
	return yield all([takeLatest(Types.EDIT_ASSINATURA_PERFIL, editAssinatura)]);
}
