import { AddExercixeProps } from "../../interfaces/add.interface";
import { ProtocolosProps } from "../../interfaces/protocolo.interface";

export function isSuperior(exer: any) {
	if (
		exer.AUX_AGRUP == 1 ||
		exer.AUX_AGRUP == 2 ||
		exer.AUX_AGRUP == 3 ||
		exer.AUX_AGRUP == 4
	) {
		return true;
	} else {
		return false;
	}
}

export const Types = {
	ADD_PROTOCOLO_REQUEST: "ADD_PROTOCOLO_REQUEST",
	EDIT_PROTOCOLO_REQUEST: "EDIT_PROTOCOLO_REQUEST",
	ADD_PROTOCOLO_CANCEL: "ADD_PROTOCOLO_CANCEL",
	EDIT_PROTOCOLO_CANCEL: "EDIT_PROTOCOLO_CANCEL",

	ADD_EXER_REQUEST: "ADD_EXER_REQUEST",
	ADD_EXER_CANCEL: "ADD_EXER_CANCEL",

	SET_PROTOCOLOS: "SET_PROTOCOLOS",

	SET_REP_BASE_AVAL: "SET_REP_BASE_AVAL",
	SET_MIN_BASE_AVAL: "SET_MIN_BASE_AVAL",
	SET_SELECTED_HEMISFERIOS: "SET_SELECTED_HEMISFERIOS",
	SET_SELECTED_HEMISFERIOS_RESET: "SET_SELECTED_HEMISFERIOS_RESET",
	SET_NOME_PROTOCOL: "SET_NOME_PROTOCOL",
	SET_SELECTED_PROTOCOLS: "SET_SELECTED_PROTOCOLS",
	RESET_SELECTED_PROTOCOLS: "RESET_SELECTED_PROTOCOLS",
	SET_ADAPTE_FUNCIS: "SET_ADAPTE_FUNCIS",

	DELETE_PROTO_REQUEST: "DELETE_PROTO_REQUEST",
	DELETE_PROTO_REQUEST_CANCEL: "DELETE_PROTO_REQUEST_CANCEL",

	DELETE_EXER_REQUEST: "DELETE_EXER_REQUEST",
	DELETE_EXER_REQUEST_CANCEL: "DELETE_EXER_REQUEST_CANCEL",

	SET_CURRENT_PROTOCOL: "SET_CURRENT_PROTOCOL",

	SET_PROTOCOLOS_SELECTED: "SET_PROTOCOLOS_SELECTED",

	SET_EXERCICES: "SET_EXERCICES",

	SET_BEFORE_ADD_PROTOCOL: "SET_BEFORE_ADD_PROTOCOL",

	REMOVE_PLAY: "REMOVE_PLAY",
	SET_DISABLE_MODAL_CONEXAO_BLUETOOTH: "SET_DISABLE_MODAL_CONEXAO_BLUETOOTH",
	SET_BUTTON_GOBACK: "SET_BUTTON_GOBACK",
	SET_MODAL_FILTER_PROTOCOL: "SET_MODAL_FILTER_PROTOCOL",
	SET_OPEN_MODAL_ADD_PROTOCOLO: "SET_OPEN_MODAL_ADD_PROTOCOLO",
	SET_OPEN_MODAL_EDIT_PROTOCOLO: "SET_OPEN_MODAL_EDIT_PROTOCOLO",

	SET_OPEN_MODAL_FILTER_AGRUPAMENTO: "SET_OPEN_MODAL_FILTER_AGRUPAMENTO",
};

const INITIAL_STATE = {
	protocolos: [],
	exercicios: [],

	nome_protocol: "",
	current_exercise: null,
	is_open_funci_select: false,
	current_protocolo: null,

	seletec_hemisferios: [],
	final_drag_exers: [],
	superi: [],
	infe: [],
	type: null,
	selected_protocols: [],
	loading_protocolo_delete: false,
	loading_add_protocolo: false,
	loading_edit_protocolo: false,

	loading_exer: false,
	loading_exercicio_delete: false,

	exercices: [],

	berofe_add_protocol: null,
	remove_play: false,
	disable_modal: false,
	set_button_goback: null,
	is_open_modal_filter_protocol: false,
	is_open_modal_add_protocol: false,
	is_open_modal_edit_protocol: false,
	is_open_modal_filter_agrupamento: false,
};

export default function manage_protocol(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.ADD_EXER_REQUEST:
			return {
				...state,
				loading_exer: true,
			};
		case Types.ADD_EXER_CANCEL:
			return {
				...state,
				loading_exer: false,
			};
		case Types.ADD_PROTOCOLO_REQUEST:
			return {
				...state,
				loading_add_protocolo: true,
			};
		case Types.ADD_PROTOCOLO_CANCEL:
			return {
				...state,
				loading_add_protocolo: false,
			};
		case Types.EDIT_PROTOCOLO_REQUEST:
			return {
				...state,
				loading_edit_protocolo: true,
			};
		case Types.EDIT_PROTOCOLO_CANCEL:
			return {
				...state,
				loading_edit_protocolo: false,
			};
		case Types.SET_PROTOCOLOS:
			return {
				...state,
				protocolos: action.payload,
			};

		case Types.SET_SELECTED_HEMISFERIOS:
			let aux_final: any = [];
			let aux_hemi: any;

			const find: any = state.seletec_hemisferios.find(
				(e: any) => e.ID_EXERCICIO == action.payload.exercice_id
			);

			let auxTypes: any = [];
			if (find) {
				auxTypes = find.PROTOCOLO_EXERCICIO_TIPO;

				const findType = auxTypes.find(
					(e: any) =>
						e == `${action.payload.value}${action.payload.exercice_id}`
				);
				if (!findType) {
					auxTypes.push(`${action.payload.value}${action.payload.exercice_id}`);
				} else {
					const filterDeletes = find.PROTOCOLO_EXERCICIO_TIPO.filter(
						(e: any) =>
							e != `${action.payload.value}${action.payload.exercice_id}`
					);
					auxTypes = filterDeletes;
				}

				const d = state.seletec_hemisferios.filter(
					(e: any) => e.ID_EXERCICIO != find.ID_EXERCICIO
				);

				aux_hemi =
					auxTypes.length == 0
						? null
						: {
								...find,
								PROTOCOLO_EXERCICIO_TIPO: [...auxTypes],
						  };
				if (aux_hemi == null) {
					aux_final = [...d];
				} else {
					aux_final = [...d, aux_hemi];
				}
			} else {
				aux_hemi = {
					PROTOCOLO_EXERCICIO_ORDEM: "1",
					PROTOCOLO_EXERCICIO_TIPO: [
						`${action.payload.value}${action.payload.exercice_id}`,
					],
					ID_EXERCICIO: action.payload.exercice_id,
					EXERCICIO_TITULO_PT: action.payload.name,
					EXERCICIO_TITULO_EN: action.payload.name,
					EXERCICIO_TITULO_ES: action.payload.name,
					AUX_AGRUP: action.payload.grupo,
				};
				aux_final = [...state.seletec_hemisferios, aux_hemi];
			}

			const supe = aux_final.filter((e: any) => isSuperior(e));
			const infe = aux_final.filter((e: any) => !isSuperior(e));

			const aux_supe = supe.map((e: any) => e.PROTOCOLO_EXERCICIO_TIPO);
			const aux_infe = infe.map((e: any) => e.PROTOCOLO_EXERCICIO_TIPO);

			const final_qtd_superior: any = [];
			aux_supe.forEach((e: any) => {
				e.forEach((e: any) => final_qtd_superior.push(e));
			});

			const final_qtd_inferior: any = [];
			aux_infe.forEach((e: any) => {
				e.forEach((e: any) => final_qtd_inferior.push(e));
			});

			const ordem = aux_final.map((e: any, index: any) => {
				return {
					...e,
					PROTOCOLO_EXERCICIO_ORDEM: index + 1,
				};
			});

			return {
				...state,
				seletec_hemisferios: [...aux_final],
				final_drag_exers: [...ordem],
				superi: [...final_qtd_superior],
				infe: [...final_qtd_inferior],
			};

		case Types.SET_SELECTED_HEMISFERIOS_RESET:
			return {
				...state,
				seletec_hemisferios: [],
				final_drag_exers: [],
				superi: [],
				infe: [],
				exercices: [],
			};

		case Types.SET_NOME_PROTOCOL:
			return {
				...state,
				nome_protocol: action.payload,
			};

		case Types.SET_SELECTED_PROTOCOLS:
			let auxList: any = [];
			const find_protocol: any = state.selected_protocols.find(
				(pro: ProtocolosProps) =>
					pro.ID_PROTOCOLO == action.payload.ID_PROTOCOLO
			);
			if (!!find_protocol) {
				auxList = state.selected_protocols.filter(
					(pro: ProtocolosProps) =>
						pro.ID_PROTOCOLO != action.payload.ID_PROTOCOLO
				);
			} else {
				auxList = [...state.selected_protocols, action.payload];
			}
			return {
				...state,
				selected_protocols: [...auxList],
			};

		case Types.RESET_SELECTED_PROTOCOLS:
			return {
				...state,
				selected_protocols: [],
			};

		case Types.SET_REP_BASE_AVAL:
			return {
				...state,
				repeticoes_aval: action.payload,
			};
		case Types.SET_MIN_BASE_AVAL:
			return {
				...state,
				carga_min_aval: action.payload,
			};
		case Types.DELETE_PROTO_REQUEST:
			return {
				...state,
				loading_protocolo_delete: true,
			};
		case Types.DELETE_PROTO_REQUEST_CANCEL:
			return {
				...state,
				loading_protocolo_delete: false,
			};
		case Types.DELETE_EXER_REQUEST:
			return {
				...state,
				loading_exercicio_delete: true,
			};
		case Types.DELETE_EXER_REQUEST_CANCEL:
			return {
				...state,
				loading_exercicio_delete: false,
			};
		case Types.SET_CURRENT_PROTOCOL:
			return {
				...state,
				current_protocolo: action.payload,
			};
		case Types.SET_PROTOCOLOS_SELECTED:
			return {
				...state,
				selected_protocols: action.payload,
			};
		case Types.SET_BEFORE_ADD_PROTOCOL:
			return {
				...state,
				berofe_add_protocol: action.payload,
			};
		case Types.SET_EXERCICES:
			return {
				...state,
				exercices: action.payload,
			};
		case Types.SET_ADAPTE_FUNCIS:
			const ordem2: any[] = action.payload.map((e: any, index: any) => {
				return {
					...e,
					PROTOCOLO_EXERCICIO_ORDEM: index + 1,
				};
			});

			return {
				...state,
				final_drag_exers: [...ordem2],
			};
		case Types.REMOVE_PLAY:
			return {
				...state,
				remove_play: action.payload,
			};
		case Types.SET_DISABLE_MODAL_CONEXAO_BLUETOOTH:
			return {
				...state,
				disable_modal: action.payload,
			};
		case Types.SET_BUTTON_GOBACK:
			return {
				...state,
				set_button_goback: action.payload,
			};
		case Types.SET_MODAL_FILTER_PROTOCOL:
			return {
				...state,
				is_open_modal_filter_protocol: action.payload,
			};
		case Types.SET_OPEN_MODAL_ADD_PROTOCOLO:
			return {
				...state,
				is_open_modal_add_protocol: action.payload,
			};
		case Types.SET_OPEN_MODAL_EDIT_PROTOCOLO:
			return {
				...state,
				is_open_modal_edit_protocol: action.payload,
			};
		case Types.SET_OPEN_MODAL_FILTER_AGRUPAMENTO:
			return {
				...state,
				is_open_modal_filter_agrupamento: action.payload,
			};

		default:
			return state;
	}
}

export const ManageProtocolActions = {
	add_protocolo_request: (data: any) => ({
		type: Types.ADD_PROTOCOLO_REQUEST,
		payload: data,
	}),
	edit_protocolo_request: (data: any) => ({
		type: Types.EDIT_PROTOCOLO_REQUEST,
		payload: data,
	}),
	cancel_protocolo_request: () => ({
		type: Types.ADD_PROTOCOLO_CANCEL,
	}),
	edit_cancel_protocolo_request: () => ({
		type: Types.EDIT_PROTOCOLO_CANCEL,
	}),

	add_exer_request: (data: AddExercixeProps) => ({
		type: Types.ADD_EXER_REQUEST,
		payload: data,
	}),
	cancel_exer_request: () => ({
		type: Types.ADD_EXER_CANCEL,
	}),

	set_protocolos: (protocolos: any) => ({
		type: Types.SET_PROTOCOLOS,
		payload: protocolos,
	}),

	set_selected_exercises: (exercise: any) => ({
		type: Types.SET_SELECTED_HEMISFERIOS,
		payload: exercise,
	}),

	set_selected_exercises_reset: () => ({
		type: Types.SET_SELECTED_HEMISFERIOS_RESET,
	}),

	set_nome_protocol: (nome: any) => ({
		type: Types.SET_NOME_PROTOCOL,
		payload: nome,
	}),

	set_add_selected_protocol: (protocol: any) => ({
		type: Types.SET_SELECTED_PROTOCOLS,
		payload: protocol,
	}),

	reset_selected_protocols: () => ({
		type: Types.RESET_SELECTED_PROTOCOLS,
	}),

	set_rep_base_aval: (rep: any) => ({
		type: Types.SET_REP_BASE_AVAL,
		payload: rep,
	}),
	set_min_base_aval: (min: any) => ({
		type: Types.SET_MIN_BASE_AVAL,
		payload: min,
	}),
	delete_proto_request: (protocolos: any) => ({
		type: Types.DELETE_PROTO_REQUEST,
		payload: protocolos,
	}),
	delete_proto_request_cancel: () => ({
		type: Types.DELETE_PROTO_REQUEST_CANCEL,
	}),
	delete_exer_request: (exercicios: any) => ({
		type: Types.DELETE_EXER_REQUEST,
		payload: exercicios,
	}),
	delete_exer_request_cancel: () => ({
		type: Types.DELETE_EXER_REQUEST_CANCEL,
	}),
	set_current_protocol: (protocolo: any) => ({
		type: Types.SET_CURRENT_PROTOCOL,
		payload: protocolo,
	}),
	set_adapte_funcis: (exercises: any) => ({
		type: Types.SET_ADAPTE_FUNCIS,
		payload: exercises,
	}),
	set_protocolo_selected: (data: any) => ({
		type: Types.SET_PROTOCOLOS_SELECTED,
		payload: data,
	}),
	set_exercices: (data: any) => ({
		type: Types.SET_EXERCICES,
		payload: data,
	}),
	set_before_add_protocol: (obj: any) => ({
		type: Types.SET_BEFORE_ADD_PROTOCOL,
		payload: obj,
	}),
	set_remove_play: (status: boolean) => ({
		type: Types.REMOVE_PLAY,
		payload: status,
	}),
	set_disable_modal_conexao_bluetooth: (status: boolean) => ({
		type: Types.SET_DISABLE_MODAL_CONEXAO_BLUETOOTH,
		payload: status,
	}),
	set_button_goback: (status: boolean) => ({
		type: Types.SET_BUTTON_GOBACK,
		payload: status,
	}),
	set_modal_filter_protocol: (status: boolean) => ({
		type: Types.SET_MODAL_FILTER_PROTOCOL,
		payload: status,
	}),
	set_open_modal_add_protocolo: (status: boolean) => ({
		type: Types.SET_OPEN_MODAL_ADD_PROTOCOLO,
		payload: status,
	}),
	set_open_modal_edit_protocolo: (
		status: boolean,
		protocolo: ProtocolosProps | null
	) => ({
		type: Types.SET_OPEN_MODAL_EDIT_PROTOCOLO,
		payload: { status, protocolo },
	}),
	set_open_modal_filter_agrupamento: (status: boolean) => ({
		type: Types.SET_OPEN_MODAL_FILTER_AGRUPAMENTO,
		payload: status,
	}),
};
