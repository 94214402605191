import styled from "styled-components";
import { Colors } from "../../../styles/colors";


export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 64px 20px;
    width: 450px;
    /* height: 214px; */
    border-radius: 30px;;
    position: relative;
    background-color: white;
`;

export const Title = styled.p`
    font-family: "NotoSans-SemiBold";
    color: ${Colors.primary_blue_k_main};
    font-size: 28px;
    font-weight: 600 ;
`;
export const ContainerInput = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
`;
export const Option = styled.div`
    display: flex;
    width: 30%;
    flex-direction: column;
    justify-content: center;
    justify-content: center;
    align-items: center;
`;